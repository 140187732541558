<template>
   <div class="main-container">
      <div class="nav-bar js-nav-bar" @click="goBack">
         <div class="nav-bar__inner">
            <span class="nav-bar__icon"><i class="icon-fonts icon-fonts--arrow-left"></i></span>
            <h3 class="nav-bar__title">{{ $t('mobile.do_it_yourselt.title') }}</h3>
         </div>
      </div>
      <div class="main-content">
         <div class="your-self">
            <div class="data-balance">
               <ul class="data-balance__list">
                  <li class="data-balance__item">
                     <h5 class="data-balance__name">{{ $t('mobile.do_it_yourselt.remaining_balance') }}</h5>
                     <div class="data-balance__info">
                        <span class="data-balance__value"
                           >{{ Number(remainData.balance).toLocaleString('en-US', { maximumFractionDigits: 0 })
                           }}<span>{{ $t('unit.upper_kip') }}</span></span
                        >
                     </div>
                  </li>
                  <li class="data-balance__item">
                     <h5 class="data-balance__name">{{ $t('mobile.do_it_yourselt.remaining_data') }}</h5>
                     <div class="data-balance__info">
                        <span class="data-balance__value"
                           >{{ Number(remainData.dataVolume).toLocaleString('en-US', { maximumFractionDigits: 0 })
                           }}<span>{{ $t('unit.mb') }}</span></span
                        >
                     </div>
                  </li>
               </ul>
            </div>
            <h3 class="your-self__title">{{ $t('mobile.do_it_yourselt.design_your_package') }}</h3>
            <div class="process-pack">
               <ul class="process-pack__list">
                  <li class="process-pack__item">
                     <div class="process-pack__top">
                        <h5 class="process-pack__name">{{ $t('mobile.do_it_yourselt.data') }}</h5>
                        <span class="process-pack__data"
                           >{{ Number(dataChose).toLocaleString('en-US', { maximumFractionDigits: 0 }) }}
                           {{ $t('unit.mb') }}</span
                        >
                     </div>
                     <div>
                        <input
                           class="input-range"
                           type="range"
                           :min="minData"
                           :max="maxData"
                           value="0"
                           v-model="dataChose"
                        />
                     </div>
                     <div class="process-pack__info">
                        <span class="process-pack__value">{{
                           Number(minData).toLocaleString('en-US', { maximumFractionDigits: 0 })
                        }}</span
                        ><span class="process-pack__value">{{
                           Number(maxData).toLocaleString('en-US', { maximumFractionDigits: 0 })
                        }}</span>
                     </div>
                  </li>
                  <li class="process-pack__item">
                     <div class="process-pack__top">
                        <h5 class="process-pack__name">{{ $t('mobile.do_it_yourselt.minute_call') }}</h5>
                        <span class="process-pack__data"
                           >{{ Number(callChose).toLocaleString('en-US', { maximumFractionDigits: 0 }) }}
                           {{ $t('unit.min') }}</span
                        >
                     </div>
                     <div>
                        <p v-if="!isCheck" class="input-noti">{{ $t('mobile.do_it_yourselt.messenger_disable') }}</p>
                        <input
                           class="input-range"
                           type="range"
                           :min="minCall"
                           :max="maxCall"
                           value="0"
                           v-model="callChose"
                           :disabled="!isCheck"
                        />
                     </div>
                     <div class="process-pack__info">
                        <span class="process-pack__value">{{
                           Number(minCall).toLocaleString('en-US', { maximumFractionDigits: 0 })
                        }}</span
                        ><span class="process-pack__value">{{
                           Number(maxCall).toLocaleString('en-US', { maximumFractionDigits: 0 })
                        }}</span>
                     </div>
                  </li>
                  <li class="process-pack__item">
                     <div class="process-pack__top">
                        <h5 class="process-pack__name">{{ $t('mobile.do_it_yourselt.validity_date') }}</h5>
                        <span class="process-pack__data"
                           >{{ Number(valChose).toLocaleString('en-US', { maximumFractionDigits: 0 }) }}
                           {{ $t('unit.day') }}</span
                        >
                     </div>
                     <div>
                        <input
                           class="input-range"
                           type="range"
                           :min="minVal"
                           :max="maxVal"
                           value="0"
                           v-model="valChose"
                        />
                     </div>
                     <div class="process-pack__info">
                        <span class="process-pack__value">{{
                           Number(minVal).toLocaleString('en-US', { maximumFractionDigits: 0 })
                        }}</span
                        ><span class="process-pack__value">{{
                           Number(maxVal).toLocaleString('en-US', { maximumFractionDigits: 0 })
                        }}</span>
                     </div>
                  </li>
               </ul>
            </div>
            <div class="total-expense">
               <div class="total-expense__top">
                  <h5 class="total-expense__sub">{{ $t('mobile.do_it_yourselt.total_expense') }}</h5>
                  <span class="total-expense__kip"
                     >{{ Number(totalExpense).toLocaleString('en-US', { maximumFractionDigits: 0 }) }}
                     {{ $t('unit.upper_kip') }}</span
                  >
               </div>
               <ul class="total-expense__list">
                  <li class="total-expense__item">
                     <span class="total-expense__name">{{ $t('mobile.do_it_yourselt.added_data') }}</span
                     ><span class="total-expense__value"
                        >{{ Number(dataChose).toLocaleString('en-US', { maximumFractionDigits: 0 }) }}
                        {{ $t('unit.mb') }}</span
                     >
                  </li>
                  <li class="total-expense__item">
                     <span class="total-expense__name">{{ $t('mobile.do_it_yourselt.added_minutes') }}</span
                     ><span class="total-expense__value"
                        >{{ Number(callChose).toLocaleString('en-US', { maximumFractionDigits: 0 }) }}
                        {{ $t('unit.min') }}</span
                     >
                  </li>
                  <li class="total-expense__item">
                     <span class="total-expense__name">{{ $t('mobile.do_it_yourselt.added_validity_date') }}</span
                     ><span class="total-expense__value"
                        >{{ Number(extraChose).toLocaleString('en-US', { maximumFractionDigits: 0 }) }}
                        {{ $t('unit.day') }}</span
                     >
                  </li>
               </ul>
            </div>
            <div class="your-self__btn">
               <button class="button button--primary button--full" @click="openModalConfirm()" :disabled="isSubmit">
                  {{ $t('buttons.submit') }}
               </button>
            </div>
         </div>
      </div>

      <!-- Modal Select Method-->
      <button style="display: none" data-bs-toggle="modal" data-bs-target="#select-method" id="methodBtn" />
      <button style="display: none" data-bs-toggle="modal" data-bs-target="#input-information" id="infoBtn" />

      <div
         class="modal fade modal-custom"
         id="select-method"
         tabindex="-1"
         role="dialog"
         aria-labelledby="select-method"
         aria-hidden="true"
         data-bs-backdrop="static"
         data-bs-keyboard="false"
      >
         <div class="modal-dialog" role="document">
            <div class="modal-custom__content">
               <div class="modal-custom__header">
                  <span class="modal-custom__action" data-bs-dismiss="modal" @click="goBack"
                     ><i class="icon-fonts icon-fonts--close"></i
                  ></span>
               </div>
               <div class="modal-custom__body">
                  <h3 class="modal-custom__title">{{ $t('mobile.do_it_yourselt.select_method') }}</h3>
                  <p class="modal-custom__des">
                     {{ $t('mobile.do_it_yourselt.content_modal_method') }}
                  </p>
                  <div class="modal-custom__btn">
                     <button
                        class="button button--normal button--medium"
                        @click="openInfo()"
                     >
                        {{ $t('mobile.do_it_yourselt.present_it') }}
                     </button>
                     <button class="button button--primary button--medium" @click="loadData(1)" data-bs-dismiss="modal">
                        {{ $t('mobile.do_it_yourselt.buy_yourself') }}
                     </button>
                  </div>
               </div>
            </div>
         </div>
      </div>

      <confirmation-modal :data="dataPopupComfirm" :onConfirm="onConfirm" :onCancel="onCancel" />

      <!-- Modal Input information-->
      <div
         class="modal fade modal-custom"
         id="input-information"
         tabindex="-1"
         role="dialog"
         aria-labelledby="input-information"
         aria-hidden="true"
         data-bs-backdrop="static"
         data-bs-keyboard="false"
      >
         <div class="modal-dialog" role="document">
            <div class="modal-custom__content">
               <ValidationObserver v-slot="{ invalid, reset }">
                  <div class="modal-custom__header">
                     <span
                        class="modal-custom__action"
                        @click.prevent="reset"
                        data-bs-dismiss="modal"
                        id="closeInfo"
                        @click="closeInfo()"
                        ><i class="icon-fonts icon-fonts--close"></i
                     ></span>
                  </div>
                  <div class="modal-custom__body">
                     <h3 class="modal-custom__title">{{ $t('mobile.do_it_yourselt.input_information') }}</h3>

                     <form @submit.prevent="passes(onSubmit)" ref="observer" @reset.prevent="reset">
                        <div class="modal-custom__form">
                           <div class="form-customize">
                              <Input
                                 type="number"
                                 :label="$t('mobile.do_it_yourselt.receive_number')"
                                 :placeholder="$t('mobile.recharge_scratch_card.enter_phone_number')"
                                 :hasResetIcon="true"
                                 rules="required|max:13|phone"
                                 v-model="receiveNumber"
                              />
                           </div>
                           <div class="form-customize">
                              <Input
                                 type="text"
                                 :placeholder="$t('mobile.do_it_yourselt.description')"
                                 :label="$t('mobile.do_it_yourselt.description')"
                                 v-model="description"
                              />
                           </div>
                        </div>
                        <div class="modal-custom__btn">
                           <button
                              type="reset"
                              class="button button--normal button--medium"
                              data-bs-dismiss="modal"
                              @click="closeInfo"
                           >
                              {{ $t('buttons.close') }}
                           </button>
                           <button
                              class="button button--primary button--medium"
                              @click="loadData(2)"
                              :disabled="invalid"
                              data-bs-dismiss="modal"
                           >
                              {{ $t('buttons.confirm') }}
                           </button>
                        </div>
                     </form>
                  </div>
               </ValidationObserver>
            </div>
         </div>
      </div>

      <!-- Modal-->
      <button style="display: none" data-bs-toggle="modal" data-bs-target="#register-success" id="registerSuccessBtn" />
      <div
         class="modal fade modal-custom"
         id="register-success"
         tabindex="-1"
         role="dialog"
         aria-labelledby="register-success"
         aria-hidden="true"
         data-bs-backdrop="static"
         data-bs-keyboard="false"
      >
         <div class="modal-dialog" role="document">
            <div class="modal-custom__content">
               <div class="modal-custom__header">
                  <span class="modal-custom__action" data-bs-dismiss="modal"
                     ><i class="icon-fonts icon-fonts--close"></i
                  ></span>
               </div>
               <div class="modal-custom__body">
                  <div class="modal-custom__images"><img src="@/assets/images/ic-success.svg" alt="img" /></div>
                  <h3 class="modal-custom__title">{{ $t('mobile.do_it_yourselt.success') }}</h3>
                  <div class="modal-custom__btn modal-custom__btn--full">
                     <button class="button button--primary button--full" data-bs-dismiss="modal">
                        {{ $t('buttons.close') }}
                     </button>
                  </div>
               </div>
            </div>
         </div>
      </div>

      <button style="display: none" data-bs-toggle="modal" data-bs-target="#notificationP" id="notificationPBtn" />
      <div
         class="modal fade modal-custom"
         id="notificationP"
         tabindex="-1"
         role="dialog"
         aria-labelledby="notificationP"
         aria-hidden="true"
      >
         <div class="modal-dialog" role="document">
            <div class="modal-custom__content">
               <div class="modal-custom__header">
                  <span class="modal-custom__action" data-bs-dismiss="modal">
                     <i class="icon-fonts icon-fonts--close"></i>
                  </span>
               </div>
               <div class="modal-custom__body">
                  <div class="modal-custom__images"><img src="@/assets/images/ic-warning.svg" alt="img" /></div>
                  <h3 class="modal-custom__title">{{ $t('notification.title') }}</h3>
                  <p class="modal-custom__des" v-html="content"></p>
                  <div class="modal-custom__btn modal-custom__btn--full">
                     <button class="button button--primary button--full" @click="onClose">
                        {{ $t('buttons.close') }}
                     </button>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>
<script>
import BaseComponent from '@/mixins/BaseComponent'
import service from './../../services/MobileService'
import LocalStorage from '@/ultils/local-storage'
import $ from 'jquery'
import store from '@/plugins/vuex/store'
import ConfirmationModal from '@/components/modal/ConfirmationModal'
import { ValidationObserver } from 'vee-validate'
import Input from '@/components/form/Input'

export default {
   name: 'DoItYourSelf',
   mixins: [BaseComponent],
   components: {
      ConfirmationModal,
      ValidationObserver,
      Input
   },
   data() {
      return {
         subType: LocalStorage.getItem('subType'),
         remainData: {
            balance: 0,
            dataVolume: 0
         },
         phoneNumber: 0,
         receiveNumber: '',
         description: '',

         data: [],
         call: [],
         validate: [],
         packageDate: [],

         minData: 0,
         maxData: 0,
         minCall: 0,
         maxCall: 0,
         minVal: 0,
         maxVal: 0,

         dataChose: 0,
         callChose: 0,
         valChose: 0,
         extraChose: 0,

         totalData: 0,
         totalCal: 0,
         totalVal: 0,

         totalExpense: 0,
         packageDay: 0,
         dataPopupComfirm: {
            title: '',
            content: '',
            cancelButton: '',
            confirmButton: '',
            confirmedData: {}
         },
         isCheck: true,
         isSubmit: true,
         content: ''
      }
   },
   created() {
        this.setTitle(this.$i18n.t('mobile.do_it_yourselt.title'))
        if (!this.subType) {
            this.getSubType()
        } else {
            this.getData()
        }
      
      setTimeout(function () {
         $('#methodBtn').trigger('click')
      }, 50)
   },
   watch: {
      dataChose() {
         this.changeData()
      },
      callChose() {
         this.changeData()
      },
      valChose() {
         this.changeData()
      }
   },
   methods: {
      closeInfo() {
         this.receiveNumber = ''
         this.description = ''
         $('#methodBtn').trigger('click')
      },
      openInfo() {
         this.receiveNumber = ''
          this.description = ''
          this.$refs.observer.reset();
          $('#infoBtn').trigger('click')
      },
      getData() {
          this.showLoading()
         var dataRemain = {
            subType: this.subType
         }
         this.remainData.balance = 0
         service.getSubAccountInfo(dataRemain).then((response) => {
            this.remainData = response.wsResponse ?? []
            if (this.subType == 1) {
               this.remainData.balance = this.remainData.mainAcc ? this.remainData.mainAcc : 0
            }
            if (this.subType == 2) {
               this.remainData.balance = this.remainData.prePost ? this.remainData.prePost : 0
            }
            this.hideLoading()
         })
      },
      loadData(data) {
         this.showLoading()
         if (data == 1) {
            let userInfoStr = LocalStorage.getItem('user_info')
            if (userInfoStr) {
               let userInfo = JSON.parse(userInfoStr)
               this.phoneNumber = userInfo['isdn']
            }
         } else {
             this.receiveNumber = this.standalizePhoneNo(this.receiveNumber)
             console.log(this.receiveNumber)
            this.phoneNumber = this.receiveNumber
         }
         var dataRangeYourself = {
            receiverIsdn: this.phoneNumber
         }
         service
            .getRangeYourself(dataRangeYourself)
            .then((response) => {
               if (response.wsResponse) {
                  this.loadScript('js/range.js')
                  this.data = response.wsResponse.DATA_RANGE
                  this.call = response.wsResponse.VOICE_RANGE
                  this.validate = response.wsResponse.EXTRA_DATE_RANGE
                  this.packageDate = response.wsResponse.PACKAGE_PRICE_DATE_RANGE
                  if (this.data && this.data.length > 0) {
                     this.minData = this.data[0].minRange
                     this.maxData = this.data[this.data.length - 1].maxRange
                  }
                  if (this.call && this.call.length > 0) {
                     var checkExits = this.call.filter((x) => x.simType == 'M')
                     if (checkExits.length > 0) {
                        this.isCheck = true
                     } else {
                        this.isCheck = false
                     }
                     this.minCall = this.call[0].minRange
                     this.maxCall = this.call[this.call.length - 1].maxRange
                  } else {
                     this.isCheck = false
                  }
                  if (this.validate && this.validate.length > 0) {
                     this.minVal = this.validate[0].minRange
                     this.maxVal = this.validate[this.validate.length - 1].maxRange
                  }
               } else {
                  //this.dataPopupNotification = {
                  //    title: this.$i18n.t('errors.system'), content: response.message, closeButton: this.$i18n.t('buttons.close')
                  //}
                  this.content = response.message
                  $('#notificationPBtn').trigger('click')
               }
               this.hideLoading()
            })
            .finally(() => {
               this.hideLoading()
            })
      },
      changeData() {
         this.loadScript('js/range.js')
         if (parseInt(this.dataChose) || parseInt(this.callChose) || parseInt(this.valChose)) {
            this.isSubmit = false
         } else {
            this.isSubmit = true
         }
         this.totalData = 0
         for (var d = 0; d < this.data.length; d++) {
            if (this.dataChose > this.data[d].minRange && this.dataChose <= this.data[d].maxRange) {
               this.totalData = this.dataChose * this.data[d].value
               break
            }
         }
         this.totalCall = 0
         for (var c = 0; c < this.call.length; c++) {
            if (this.callChose > this.call[c].minRange && this.callChose <= this.call[c].maxRange) {
               this.totalCall = this.callChose * this.call[c].value
               break
            }
         }
         this.totalVal = 0
         for (var v = 0; v < this.validate.length; v++) {
            if (this.valChose > this.validate[v].minRange && this.valChose <= this.validate[v].maxRange) {
               this.totalVal = this.valChose * this.validate[v].value
               break
            }
         }

         var totalPackage = this.totalData + this.totalCall
         this.packageDay = 0
         for (var i = 0; i < this.packageDate.length; i++) {
            if (totalPackage > this.packageDate[i].minRange && totalPackage <= this.packageDate[i].maxRange) {
               this.packageDay = this.packageDate[i].value
               break
            }
         }
         this.extraChose = parseInt(this.valChose) + parseInt(this.packageDay)

         this.totalExpense = this.totalData + this.totalCall + this.totalVal
      },
      openModalConfirm() {
         if (this.totalExpense > this.remainData.balance) {
            store.dispatch('setNotification', {
               title: this.$i18n.t('errors.system'),
               content: this.$i18n.t('mobile.do_it_yourselt.request_fail'),
               closeButton: this.$i18n.t('buttons.close')
            })
         } else {
            this.dataInfo = {
               receiveNumber: '',
               description: ''
            }

            this.dataPopupComfirm = {
               title: this.$i18n.t('confirmation.title'),
               content:
                  this.$i18n.t('mobile.do_it_yourselt.confirm_1') +
                  Number(this.dataChose).toLocaleString('en-US', { maximumFractionDigits: 0 }) +
                  ' ' +
                  this.$i18n.t('unit.mb') +
                  this.$i18n.t('mobile.do_it_yourselt.confirm_2') +
                  Number(this.callChose).toLocaleString('en-US', { maximumFractionDigits: 0 }) +
                  ' ' +
                  this.$i18n.t('unit.min') +
                  this.$i18n.t('mobile.do_it_yourselt.confirm_3') +
                  Number(this.valChose).toLocaleString('en-US', { maximumFractionDigits: 0 }) +
                  this.$i18n.t('mobile.do_it_yourselt.confirm_4') +
                  Number(this.totalExpense).toLocaleString('en-US', { maximumFractionDigits: 0 }) +
                  ' ' +
                  this.$i18n.t('unit.upper_kip') +
                  this.$i18n.t('mobile.do_it_yourselt.confirm_5') +
                  this.phoneNumber,
               cancelButton: this.$i18n.t('buttons.cancel'),
               confirmButton: this.$i18n.t('buttons.confirm'),
               confirmedData: {
                  phoneNumber: this.phoneNumber
               }
            }
         }
      },
      onConfirm() {
         this.showLoading()
         var dataConfirm = {
            VOICE: {
               packageDay: this.packageDay,
               value: parseInt(this.callChose),
               packageFee: this.totalCall
            },
            receiverLanguage: 'en',
            DATA: {
               packageDay: this.packageDay,
               value: parseInt(this.dataChose),
               packageFee: this.totalData
            },
            receiverIsdn: this.phoneNumber,
            EXTRA_DAY: {
               extraDay: parseInt(this.valChose),
               extraDayFee: this.totalVal
            }
         }

         service
            .doYourSelf(dataConfirm)
            .then((response) => {
               if (response.errorCode == 0) {
                  $('#registerSuccessBtn').trigger('click')
               } else {
                  store.dispatch('setNotification', {
                     title: this.$i18n.t('errors.system'),
                     content: response.message,
                     closeButton: this.$i18n.t('buttons.close')
                  })
               }
               this.hideLoading()
            })
            .finally(() => {
               this.hideLoading()
            })
      },
      onCancel() {},
      onClose() {
         this.receiveNumber = ''
         this.description = ''
         $('#methodBtn').trigger('click')
      },
      backMonitor() {
         $('#closeInfo').trigger('click')
         this.$router.go(-1)
       },
       standalizePhoneNo(phoneNo) {
           var test = phoneNo;
           test = test.replaceAll(" ", "");
           var firstChar = test.charAt(0);
           if (firstChar == "+") {
               test = test.replace("+", "");
           }
           if (firstChar == "0") {
               test = test.replace("0", "");
           }
           if (test.length > 10) {
               test = test.substr(test.length - 10);
           }

           if (firstChar == "9") {
               test = "20" + test;
           }
           return test;
       },
       getSubType() {
           service
               .getUserInfo()
               .then((response) => {
                   LocalStorage.setItem('subType', response.wsResponse.subType)
                   this.subType = response.wsResponse.subType;
                   this.getData()
               })
               .catch(() => {
               })
       }
   }
}
</script>
