<template>
   <div>
      <!-- anhnt logic cu -->
      <!-- <button style="display: none" data-bs-toggle="modal" data-bs-target="#confirmMethod" id="registerPackBtn" />
      <div
         class="modal fade modal-custom"
         id="confirmMethod"
         tabindex="-1"
         role="dialog"
         aria-labelledby="confirmMethod"
         aria-hidden="true"
      >
         <div class="modal-dialog" role="document">
            <div class="modal-custom__content">
               <div class="modal-custom__header">
                  <span class="modal-custom__action" data-bs-dismiss="modal"
                     ><i class="icon-fonts icon-fonts--close"></i
                  ></span>
               </div>
               <div class="modal-custom__body">
                  <h3 class="modal-custom__title">{{ $t('mobile.data_packages.registration_confirmation') }}</h3>
                  <p class="modal-custom__des">{{ $t('mobile.data_packages.ask_registration') }}</p>
                  <div class="modal-custom__btn-full">
                     <button
                        data-bs-dismiss="modal"
                        class="button--full button button--primary"
                        @click="checkEwalletInfo"
                     >
                        <i class="icon-fonts icon-fonts--{{ $t('mobile.data_packages.u_money') }} button__icon"></i
                        >{{ $t('mobile.data_packages.register_by_umoney') }}
                     </button>
                     <button
                        data-bs-dismiss="modal"
                        class="button--full button button--normal"
                        @click="openConfirmFromOriginalAccountModal"
                     >
                        <i class="icon-fonts icon-fonts--calling button__icon"></i
                        >{{ $t('mobile.data_packages.register_by_original_account') }}
                     </button>
                  </div>
               </div>
            </div>
         </div>
      </div> -->
      <!-- anhnt and logic cu -->
      <!-- anhnt logic moi -->
      <button style="display: none" data-bs-toggle="modal" data-bs-target="#confirmMethod" id="registerPackBtn" />
      <!-- <div
         class="modal fade modal-custom"
         id="confirmMethod"
         tabindex="-1"
         role="dialog"
         aria-labelledby="confirmMethod"
         aria-hidden="true"
      >
         <div class="modal-dialog" role="document">
            <div class="modal-custom__content">
               <div class="modal-custom__header">
                  <span class="modal-custom__action" data-bs-dismiss="modal"
                     ><i class="icon-fonts icon-fonts--close"></i
                  ></span>
               </div>
               <div class="modal-custom__body">
                  <h3 class="modal-custom__title">{{ $t('mobile.data_packages.registration_confirmation') }}</h3>
                  <p class="modal-custom__des">{{ $t('mobile.data_packages.ask_registration') }}</p>
                  <div class="modal-custom__btn-full">
                     <button
                        data-bs-dismiss="modal"
                        class="button--full button button--primary"
                        @click="checkEwalletInfo"
                     >
                        <i class="icon-fonts icon-fonts--{{ $t('mobile.data_packages.u_money') }} button__icon"></i
                        >{{ $t('mobile.data_packages.register_by_umoney') }}
                     </button>
                     <button
                        data-bs-dismiss="modal"
                        class="button--full button button--normal"
                        @click="openConfirmFromOriginalAccountModal"
                     >
                        <i class="icon-fonts icon-fonts--calling button__icon"></i
                        >{{ $t('mobile.data_packages.register_by_original_account') }}
                     </button>
                  </div>
               </div>
            </div>
         </div>
      </div> -->

      <div class="main-body">
         <div class="container">
            <!-- Button trigger modal-->
            <div class="text-center mt-3 pt-3">
               <button
                  style="display: none"
                  data-bs-toggle="modal"
                  data-bs-target="#confirmMethod"
                  id="registerPackBtn"
               />
            </div>
            <!-- Modal-->
            <div
               class="modal fade modal-custom"
               id="confirmMethod"
               tabindex="-1"
               role="dialog"
               aria-labelledby="confirmMethod"
               aria-hidden="true"
            >
               <div class="modal-dialog" role="document">
                  <div class="modal-custom__content">
                     <div class="modal-custom__header">
                        <span class="modal-custom__action" data-bs-dismiss="modal"
                           ><i class="icon-fonts icon-fonts--close"></i
                        ></span>
                     </div>
                     <div class="modal-custom__body">
                        <h3 class="modal-custom__title">{{ $t('confirmation.title') }}</h3>
                        <p class="modal-custom__des">{{ $t('mobile.data_packages.title_confirm') }}</p>
                        <div class="payment-type">
                           <ul class="payment-type__list">
                              <template v-if="isErrUmoney || !isPaymentUmoney">
                                 <li class="payment-type__item">
                                    <template
                                       v-if="
                                          dataPackageDetail &&
                                          Object.keys(dataPackageDetail).length != 0 &&
                                          parseInt(dataPackageDetail.umoneyX2) == 1
                                       "
                                    >
                                       <span class="payment-type__badge"
                                          ><span>{{ $t('mobile.data_packages.x2_Data') }}</span></span
                                       >
                                    </template>
                                    <div class="payment-type__detail">
                                       <span class="payment-type__icon"
                                          ><img src="@/assets/images/ic-um.svg" alt="img"
                                       /></span>
                                       <div class="payment-type__info">
                                          <h5 class="payment-type__name">{{ $t('mobile.data_packages.u_money') }}</h5>
                                          <p v-if="!isErrUmoney" class="payment-type__des">
                                             {{ $t('mobile.data_packages.balance_not_enough') }}:<span>{{
                                                uMoney && Object.keys(uMoney).length != 0 && uMoney.eMoneyValue
                                                   ? uMoney.eMoneyValue
                                                   : 0
                                             }}</span>
                                             KIP
                                          </p>
                                          <p v-if="isErrUmoney" class="payment-type__des">
                                             {{ $t('buttons.please_register') }}
                                          </p>
                                       </div>
                                    </div>
                                    <div class="payment-type__btn">
                                       <button
                                          v-if="!isErrUmoney"
                                          @click="redirectRouter('/mobile/recharge')"
                                          class="button button--normal button--small"
                                       >
                                          {{ $t('mobile.data_packages.topup') }}
                                       </button>
                                       <button
                                          v-if="isErrUmoney"
                                          @click="openDeepLink('mocha://home/umoney')"
                                          class="button button--normal button--small"
                                       >
                                          {{ $t('buttons.register') }}
                                       </button>
                                    </div>
                                 </li>
                              </template>
                              <template v-else>
                                 <li class="payment-type__item">
                                    <template
                                       v-if="
                                          dataPackageDetail &&
                                          Object.keys(dataPackageDetail).length != 0 &&
                                          parseInt(dataPackageDetail.umoneyX2) == 1
                                       "
                                    >
                                       <span class="payment-type__badge"
                                          ><span>{{ $t('mobile.data_packages.x2_Data') }}</span></span
                                       >
                                    </template>
                                    <div class="radio-custom">
                                       <input
                                          class="radio-custom__input"
                                          id="radio-1"
                                          name="radio"
                                          type="radio"
                                          @click="checkEwalletInfo"
                                       />
                                       <label class="radio-custom__label" for="radio-1">
                                          <div class="payment-type__detail">
                                             <span class="payment-type__icon"
                                                ><img src="@/assets/images/ic-um.svg" alt="img"
                                             /></span>
                                             <div class="payment-type__info">
                                                <h5 class="payment-type__name">
                                                   {{ $t('mobile.data_packages.u_money') }}
                                                </h5>
                                                <p class="payment-type__des">
                                                   {{ $t('mobile.data_packages.remaining') }}:<span>{{
                                                      uMoney && Object.keys(uMoney).length != 0 && uMoney.eMoneyValue
                                                         ? uMoney.eMoneyValue
                                                         : 0
                                                   }}</span>
                                                   KIP
                                                </p>
                                             </div>
                                          </div>
                                       </label>
                                    </div>
                                 </li>
                              </template>
                              <template v-if="!isPaymentUpoint">
                                 <li class="payment-type__item">
                                    <div class="payment-type__detail">
                                       <span class="payment-type__icon"
                                          ><img src="@/assets/images/ic-upoint.svg" alt="img"
                                       /></span>
                                       <div class="payment-type__info">
                                          <h5 class="payment-type__name">{{ $t('mobile.data_packages.upoint') }}</h5>
                                          <p class="payment-type__des">
                                             {{ $t('mobile.data_packages.balance_not_enough') }}:<span>{{
                                                accountInfo &&
                                                Object.keys(accountInfo).length != 0 &&
                                                accountInfo.uPoint
                                                   ? Number(accountInfo.uPoint).toLocaleString('en-US', {
                                                        maximumFractionDigits: 0
                                                     })
                                                   : 0
                                             }}</span>
                                             {{ $t('unit.upoint') }}
                                          </p>
                                       </div>
                                    </div>
                                    <div class="payment-type__btn">
                                       <button
                                          @click="redirectRouter('/mobile/recharge')"
                                          class="button button--normal button--small"
                                       >
                                          {{ $t('mobile.data_packages.topup') }}
                                       </button>
                                    </div>
                                 </li>
                              </template>
                              <template v-else>
                                 <li class="payment-type__item">
                                    <div class="radio-custom">
                                       <input
                                          class="radio-custom__input"
                                          id="radio-2"
                                          name="radio"
                                          type="radio"
                                          @click="openConfirmFromOriginalAccountModal(3)"
                                       />
                                       <label class="radio-custom__label" for="radio-2">
                                          <div class="payment-type__detail">
                                             <span class="payment-type__icon"
                                                ><img src="@/assets/images/ic-mobile.svg" alt="img"
                                             /></span>
                                             <div class="payment-type__info">
                                                <h5 class="payment-type__name">
                                                   {{ $t('mobile.data_packages.upoint') }}
                                                </h5>
                                                <p class="payment-type__des">
                                                   {{ $t('mobile.data_packages.remaining') }}:<span>{{
                                                      accountInfo &&
                                                      Object.keys(accountInfo).length != 0 &&
                                                      accountInfo.uPoint
                                                         ? Number(accountInfo.uPoint).toLocaleString('en-US', {
                                                              maximumFractionDigits: 0
                                                           })
                                                         : 0
                                                   }}</span>
                                                   {{ $t('unit.upoint') }}
                                                </p>
                                             </div>
                                          </div>
                                       </label>
                                    </div>
                                 </li>
                              </template>
                              <template v-if="!isPaymentBalance">
                                 <li class="payment-type__item">
                                    <div class="payment-type__detail">
                                       <span class="payment-type__icon"
                                          ><img src="@/assets/images/ic-mobile.svg" alt="img"
                                       /></span>
                                       <div class="payment-type__info">
                                          <h5 class="payment-type__name">
                                             {{ $t('mobile.data_packages.mobile_balance') }}
                                          </h5>
                                          <p class="payment-type__des" v-if="subType == 1">
                                             {{ $t('mobile.data_packages.balance_not_enough') }}:<span>{{
                                                accountInfo &&
                                                Object.keys(accountInfo).length != 0 &&
                                                accountInfo.mainAcc
                                                   ? Number(accountInfo.mainAcc).toLocaleString('en-US', {
                                                        maximumFractionDigits: 0
                                                     })
                                                   : 0
                                             }}</span>
                                             KIP
                                          </p>
                                          <p class="payment-type__des" v-if="subType == 2">
                                             {{ $t('mobile.account_information.title_post') }}
                                          </p>
                                       </div>
                                    </div>
                                    <div class="payment-type__btn">
                                       <button
                                          @click="redirectRouter('/mobile/recharge')"
                                          class="button button--normal button--small"
                                       >
                                          {{ $t('mobile.data_packages.topup') }}
                                       </button>
                                    </div>
                                 </li>
                              </template>
                              <template v-else>
                                 <li class="payment-type__item">
                                    <div class="radio-custom">
                                       <input
                                          class="radio-custom__input"
                                          id="radio-3"
                                          name="radio"
                                          type="radio"
                                          @click="openConfirmFromOriginalAccountModal(0)"
                                       />
                                       <label class="radio-custom__label" for="radio-3">
                                          <div class="payment-type__detail">
                                             <span class="payment-type__icon"
                                                ><img src="@/assets/images/ic-mobile.svg" alt="img"
                                             /></span>
                                             <div class="payment-type__info">
                                                <h5 class="payment-type__name">
                                                   {{ $t('mobile.data_packages.mobile_balance') }}
                                                </h5>
                                                <p class="payment-type__des" v-if="subType == 1">
                                                   {{ $t('mobile.data_packages.remaining') }}:<span>{{
                                                      accountInfo &&
                                                      Object.keys(accountInfo).length != 0 &&
                                                      accountInfo.mainAcc
                                                         ? Number(accountInfo.mainAcc).toLocaleString('en-US', {
                                                              maximumFractionDigits: 0
                                                           })
                                                         : 0
                                                   }}</span>
                                                   KIP
                                                </p>
                                                <p class="payment-type__des" v-if="subType == 2">
                                                   {{ $t('mobile.account_information.title_post') }}
                                                </p>
                                             </div>
                                          </div>
                                       </label>
                                    </div>
                                 </li>
                              </template>
                           </ul>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
      <!-- anhnt end logic moi -->
      <confirmation-modal :data="confirmationData" :onConfirm="onConfirmFromOriginalAccount" />
      <button style="display: none" data-bs-toggle="modal" data-bs-target="#confirmOtpModal" id="confirmOtpBtn" />
      <div
         class="modal fade modal-custom"
         id="confirmOtpModal"
         tabindex="-1"
         role="dialog"
         aria-labelledby="confirm-otp"
         aria-hidden="true"
      >
         <div class="modal-dialog" role="document">
            <div class="modal-custom__content">
               <ValidationObserver v-slot="{ passes, reset }">
                  <div class="modal-custom__header">
                     <span class="modal-custom__action" data-bs-dismiss="modal" @click.prevent="reset"
                        ><i class="icon-fonts icon-fonts--close"></i
                     ></span>
                  </div>
                  <div class="modal-custom__body">
                     <h3 class="modal-custom__title">{{ $t('mobile.data_packages.registration_confirmation') }}</h3>
                     <p class="modal-custom__des">{{ $t('mobile.data_packages.otp_message') }}</p>
                     <form @submit.prevent="passes(onConfirmFromUmoney)" @reset.prevent="reset">
                        <div class="modal-custom__form">
                           <Input
                              type="number"
                              :name="$t('mobile.data_packages.otp')"
                              :placeholder="$t('mobile.data_packages.enter_otp')"
                              :hasResetIcon="true"
                              rules="required|max:6"
                              v-model="otp"
                           />
                           <div class="modal-custom__otp">
                              <span
                                 style="
                                    color: #979797;
                                    font-size: 12px;
                                    line-height: 20px;
                                    text-align: center;
                                    margin-right: 5px;
                                 "
                                 >{{ $t('mobile.data_packages.not_receive_otp') }}</span
                              >
                              <a class="modal-custom__otp-link" href="javascript:void(0)" @click="sendOtp">
                                 {{ $t('mobile.data_packages.resend_otp') }}</a
                              >
                           </div>
                        </div>
                        <div class="modal-custom__btn">
                           <button type="reset" class="button button--normal button--medium" data-bs-dismiss="modal">
                              {{ $t('buttons.close') }}
                           </button>
                           <button type="submit" class="button button--primary button--medium">
                              {{ $t('buttons.confirm') }}
                           </button>
                        </div>
                     </form>
                  </div>
               </ValidationObserver>
            </div>
         </div>
      </div>
      <view-modal :data="view" />
   </div>
</template>
<script>
import $ from 'jquery'
import BaseComponent from '@/mixins/BaseComponent'
import LocalStorage from '@/ultils/local-storage'
import service from '../../services/MobileService'
import ConfirmationModal from '@/components/modal/ConfirmationModal'
import ViewModal from '@/components/modal/ViewModal'
import { ValidationObserver } from 'vee-validate'
import Input from '@/components/form/Input'

export default {
   name: 'register-pack-flow',
   mixins: [BaseComponent],
   components: {
      ConfirmationModal,
      ViewModal,
      ValidationObserver,
      Input
   },
   props: {
      data: Object
   },
   data() {
      return {
         userInfo: '',
         confirmationData: {},
         otp: '',
         view: {
            name: '',
            url: ''
         },
         isErrUmoney: false,
         isPaymentBalance: false,
         isPaymentUmoney: false,
         isPaymentUpoint: false,
         uMoney: {},
         accountInfo: {},
         dataPackageDetail: {},
         subType: 0,
         payType: 0
      }
   },
   created() {
      this.userInfo = JSON.parse(LocalStorage.getItem('user_info'))
   },
   methods: {
      openRegisterModal() {
         $('#registerPackBtn').trigger('click')
      },
      openConfirmFromOriginalAccountModal(payType) {
         this.payType = payType
         this.confirmationData = {
            content: this.$i18n
               .t('mobile.data_packages.confirm_registration')
               .replace('{0}', this.data.shortDes ? this.data.shortDes : this.data.code)
         }
      },
      onConfirmFromOriginalAccount() {
         this.showLoading()
         let request = { actionType: 0, serviceCode: this.data.code }
         if (this.payType == 3) {
            request.payType = this.payType
         }
         service
            .doActionService(request)
            .then((response) => {
               this.$store.dispatch('setNotification', { content: response.message ?? response.userMsg })
            })
            .finally(() => {
               this.hideLoading()
            })
      },
      checkEwalletInfo() {
         this.showLoading()
         service
            .getEwalletInfo({ isdnEwallet: this.userInfo['isdn'] })
            .then((response) => {
               if (response.errorCode == '0') {
                  if (response.wsResponse.responseCode === '00000') {
                     this.sendOtp()
                  } else if (response.wsResponse.responseCode === '10114') {
                     this.$store.dispatch('setNotification', {
                        content: this.$i18n.t('errors.locked_account')
                     })
                  } else if (
                     response.wsResponse.responseCode === '10116' ||
                     response.wsResponse.responseCode === '10117'
                  ) {
                     this.setViewModal('', 'https://webview.unitel.com.la:9443/#/sign-up/startC')
                  } else {
                     this.$store.dispatch('setNotification', { content: this.$i18n.t('errors.system') })
                  }
               } else {
                  this.$store.dispatch('setNotification', { content: response.message ?? response.userMsg })
               }
            })
            .finally(() => {
               this.hideLoading()
            })
      },
      sendOtp() {
         this.otp = ''
         service.getOtpWithoutPassword().then((response) => {
            if (response.errorCode == '0') {
               this.otp = ''
               $('#confirmOtpBtn').trigger('click')
            } else {
               this.$store.dispatch('setNotification', { content: this.$i18n.t('mobile.data_packages.server_busy') })
            }
         })
      },
      onConfirmFromUmoney() {
         this.showLoading()
         service
            .doActionService({
               actionType: 0,
               payType: 1,
               serviceCode: this.data.code,
               channel: 'MyUnitel',
               otp: this.otp
            })
            .then((response) => {
               if (response.errorCode === '0') {
                  this.$store.dispatch('setNotification', {
                     content: response.message ?? response.userMsg,
                     icon: '@/assets/images/ic-success.svg'
                  })
               } else {
                  this.$store.dispatch('setNotification', {
                     content: response.message ?? response.userMsg
                  })
               }
            })
            .finally(() => {
               this.hideLoading()
            })
      },
      setViewModal(name, url) {
         this.view = { name, url }
      },
      convertNumber(val) {
         if (val) {
            let number = parseFloat(val.replaceAll(',', ''))
            return number
         }
         return 0
      },
      redirectRouter(url) {
         $('#registerPackBtn').trigger('click')
         this.$router.push(url)
      },
      openDeepLink(url) {
         window.open(url)
      }
      //xbox
      // xboxRegisterService(){
      //    this.showLoading()
      //    service.xboxRegisterService({
      //       serviceCode: this.data.code,
      //       isLaptop: 1,
      //       gameCode: 'XBOX_GAME'
      //       }).then((response)=>{
      //          if(response.errorCode == '0'){
      //             let result =  response.wsResponse.moId
      //             this.xboxGetGift(result)
      //          } else {
      //             this.$store.dispatch('setNotification', { content: response.message ?? response.userMsg })
      //             this.hideLoading()
      //          }
      //          // console.log(response)
      //       }).catch(()=>{
      //          this.hideLoading()
      //       })
      // },
      // xboxGetGift(moId){
      //    this.showLoading()
      //    service.xboxGetGift({
      //       moId: moId,
      //       gameCode: 'XBOX_GAME',
      //       isUmoney: '0'
      //    }).then((response)=>{
      //       if(response.errorCode == '0'){
      //          this.$store.dispatch('setNotification', { content: response.message ?? response.userMsg, icon:"./images/ic-success.svg" })
      //       }else {
      //          this.$store.dispatch('setNotification', { content: response.message ?? response.userMsg })
      //       }
      //    }).catch(()=>{
      //       this.hideLoading()
      //    }).finally(() => {
      //             this.hideLoading()
      //    })
      // }
   },
   watch: {
      async data() {
         this.isErrUmoney = false
         this.isPaymentBalance = false
         this.isPaymentUmoney = false
         this.isPaymentUpoint = false
         this.showLoading()
         let subType = 0
         let dataPackageDetail = {}
         let uMoney = {}
         let accountInfo = {}
         try {
            await service.getUserInfo().then((response) => {
               LocalStorage.setItem('subType', response.wsResponse.subType)
               subType = response.wsResponse.subType
               this.subType = subType
            })

            await service
               .getDataPackageDetailByCode({
                  packageCode: this.data.code
               })
               .then((response) => {
                  if (response.errorCode === '0') {
                     dataPackageDetail = response.wsResponse ?? {}
                     this.dataPackageDetail = dataPackageDetail
                     if (!response.wsResponse) {
                        service
                           .getDataPromotionDetail({ packageCode: this.data.code })
                           .then((response) => {
                              dataPackageDetail = response.wsResponse ?? {}
                              this.dataPackageDetail = response.wsResponse ?? {}
                           })
                           .finally(() => {
                              this.hideLoading()
                           })
                     }
                  }
               })

            try {
               await service.getUMoney().then((response) => {
                  if (response.errorCode === '0') {
                     uMoney = response.wsResponse ?? {}
                     this.uMoney = uMoney
                  } else {
                     this.isErrUmoney = true
                  }
               })
            } catch (error) {
               this.isErrUmoney = true
            }
            // == 1 trả trước, ==2 trả sau
            // let userDetail = JSON.parse(LocalStorage.getItem('user_info')) ?? {}
            // let isdn = userDetail.isdn
            // if(this.subType == 1){

            let wsGetSubAccountInfoData = JSON.parse(LocalStorage.getItem('wsGetSubAccountInfo')) ?? {}
            if (Object.keys(wsGetSubAccountInfoData).length == 0) {
               await service.getSubAccountInfo({ subType: parseInt(this.subType) }).then((response) => {
                  let data = response.wsResponse
                  accountInfo = data
                     ? {
                          prePost: data.prePost ?? 0,
                          debPost: data.debPost ?? 0,
                          mainAcc: data.mainAcc ?? 0,
                          proAcc: data.proAcc ?? 0,
                          uPoint: data.uPoint ?? 0,
                          dataPkgName: data.name,
                          dataVolume: data.dataVolume
                       }
                     : {}

                  this.accountInfo = accountInfo
               })
            } else {
               let data = wsGetSubAccountInfoData.data.wsResponse
               accountInfo = data
                  ? {
                       prePost: data.prePost ?? 0,
                       debPost: data.debPost ?? 0,
                       mainAcc: data.mainAcc ?? 0,
                       proAcc: data.proAcc ?? 0,
                       dataPkgName: data.name,
                       uPoint: data.uPoint ?? 0,
                       dataVolume: data.dataVolume
                    }
                  : {}

               this.accountInfo = accountInfo
            }
            // }
            // với trả sau luôn cho thang toán bằng balance
            // debugger; // eslint-disable-line no-debugger
            if (this.subType == 2) {
               this.isPaymentBalance = true
               if (uMoney && Object.keys(uMoney).length != 0) {
                  let eMoneyValue = uMoney.eMoneyValue ?? 0
                  let price = dataPackageDetail.price ?? 0
                  if (this.convertNumber(eMoneyValue) >= parseFloat(price)) {
                     this.isPaymentUmoney = true
                  }
               }
               if (accountInfo && Object.keys(accountInfo).length != 0) {
                  let price = dataPackageDetail.price ?? 0
                  if (accountInfo.uPoint >= parseFloat(price)) {
                     this.isPaymentUpoint = true
                     console.log('isPaymentUpoint 1')
                  }
               }
            } else if (this.subType == 1) {
               if (uMoney && Object.keys(uMoney).length != 0) {
                  let eMoneyValue = uMoney.eMoneyValue ?? 0
                  let price = dataPackageDetail.price ?? 0
                  if (this.convertNumber(eMoneyValue) >= parseFloat(price)) {
                     this.isPaymentUmoney = true
                  }
               }
               if (accountInfo && Object.keys(accountInfo).length != 0) {
                  // let eMoneyValue = uMoney.eMoneyValue ?? 0
                  let mainAcc = accountInfo.mainAcc ?? 0
                  let price = dataPackageDetail.price ?? 0
                  if (mainAcc >= parseFloat(price)) {
                     this.isPaymentBalance = true
                  }
                  if (accountInfo.uPoint >= parseFloat(price)) {
                     this.isPaymentUpoint = true
                     console.log('isPaymentUpoint 2')
                  }
               }
            }
         } catch (error) {
            this.hideLoading()
         }
         // console.log("this.subType",this.subType)
         // console.log("this.uMoney",this.uMoney)
         // console.log("this.dataPackageDetail",this.dataPackageDetail)
         // console.log("this.accountInfo",this.accountInfo)
         this.hideLoading()
         $('#registerPackBtn').trigger('click')
      }
   }
}
</script>
