import $ from 'jquery'
import moment from 'moment'
import LocalStorage from '@/ultils/local-storage'
import { callNative } from '@/ultils/call-native'

export default {
    name: 'base-component',
    data() {
        return {
            refreshConfig: {
                startLabel: "",
                readyLabel: "",
                loadingLabel: ""
            }
        }
    },
    mounted() {
        this.loadScript('js/scroll.js')
    },
    methods: {
        // Spinner loading
        showLoading() {
            this.$store.dispatch('setLoading', true)
        },
        hideLoading() {
            this.$store.dispatch('setLoading', false)
        },
        // Date
        getMiliseconds(dateStr, dateFormat) {
            return moment(dateStr, dateFormat).toDate().getTime()
        },
        getDateFromMiliseconds(miliseconds, dateFormat) {
            return moment(miliseconds, 'x').format(dateFormat)
        },
        setTitle(title) {
            document.title = title
        },
        goBack() {
            let userInfo = LocalStorage.getItem('user_info') ? JSON.parse(LocalStorage.getItem('user_info')) : null
            if (userInfo !== null) {
                if (userInfo.originalUrl === this.$router.history.current.path) {
                    callNative('close_webview', '')
                }
            }
            // case tich hop app MyUnitel
            if (this.$router.history.current.path === '/ftth/login' || this.$router.history.current.path === '/ucare/customer-service/intro') {
                let appCode = LocalStorage.getItem('appCode')
                if (appCode !== 'LaoApp') {
                    callNative('close_webview', '')
                }
            }
            if (this.$router.history.current.path === '/ftth/login') {
                LocalStorage.removeItem('ftth_user_info')
                callNative('logoutFtth', '')
            }
            this.$router.back()
        },
        loadScript(scriptUrl) {
            $.getScript(scriptUrl)
        },
        addScrollDownEvent(scrollDownEvent) {
            $(window).on('scroll', () => {
                if ($(window).scrollTop() + window.innerHeight > $(document).height() - 20) {
                    if (!this.isLoading && this.totalRecords > this.pageNum * this.pageSize) {
                        scrollDownEvent()
                    }
                }
            })
        }
    }
}