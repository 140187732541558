<template>
   <div>
      <div class="loading" v-if="isLoading" />
      <notification-modal :data="notification" />
      <router-view />
   </div>
</template>
<script>
import NotificationModal from '@/components/modal/NotificationModal'
import '@/assets/css/custom.css'

export default {
   components: {
      NotificationModal
   },
   computed: {
      isLoading() {
         return this.$store.state.isLoading
      },
      notification() {
         return this.$store.state.notification
      }
   }
}
</script>
