import i18n from '@/plugins/i18n'

export const ChargeType = {
   Both: 0,
   PrePaid: 1,
   PostPaid: 2
}

export const Category = {
   MobileFunction: i18n.t('home.categories.mobile_function'),
   DigitalService: i18n.t('home.categories.digital_service'),
   InternetService: i18n.t('home.categories.internet_service'),
   Game: i18n.t('home.categories.game'),
   CustomerService: i18n.t('home.categories.customer_service'),
   Miscellaneous: i18n.t('home.categories.miscellaneous')
}

export const AppList = [
   {
      name: i18n.t('home.apps.mobile_services'),
      icon: './images/ic-antenna.svg',
      url: '/mobile/home',
      // deepLink: 'mocha://webviewmyunitel?ref=https%3A%2F%2Fselfcare.unitel.com.la%3A8998%2F%23%2Fmobile%2Fhome',
      deepLink: 'mocha://webviewmyunitel?ref=https%3A%2F%2Fselfcare.unitel.com.la%3A8998%2F%23%2Fmobile%2Fhome',
      openViewModal: false
   },
   {
      name: i18n.t('home.apps.internet_services'),
      icon: './images/ic-speed.svg',
      url: '/ftth/login',
      deepLink: 'mocha://webviewmyunitel?ref=https%3A%2F%2Fselfcare.unitel.com.la%3A8998%2F%23%2Fftth%2Flogin',
      openViewModal: false
   },
   // {
   //    name: i18n.t('home.apps.digital_services'),
   //    icon: './images/ic-video.svg',
   //    url: 'mocha://digitalservice',
   //    deepLink: 'mocha://digitalservice',
   //    openViewModal: false,
   //    callNative:'open_digital_service'
   // },
   // {
   //    name: i18n.t('home.apps.unitel_news'),
   //    icon: './images/ic-news.svg',
   //    url: 'https://unitel.com.la/news',
   //    deepLink: 'mocha://webgamenew?ref=https%3A%2F%2Funitel.com.la%2Fnews',
   //    openViewModal: false,
   //    callNative:'open_unitel_news'
   // },
   // {
   //    name: i18n.t('home.apps.games'),
   //    icon: './images/ic-game.svg',
   //    url: 'mocha://gamecenter',
   //    deepLink: 'mocha://gamecenter',
   //    openViewModal: false,
   //    callNative:'open_game_center'
   // },
   // {
   //    name: i18n.t('home.apps.customer_care'),
   //    icon: './images/ic-care.svg',
   //    url: '/ucare/customer-service/home',
   //    deepLink: 'mocha://webviewmyunitel?ref=https%3A%2F%2Fselfcare.unitel.com.la%3A8998%2F%23%2Fucare%2Fcustomer-service%2Fhome',
   //    openViewModal: false
   // },
   // {
   //    name: i18n.t('home.apps.umoney_service'),
   //    icon: './images/ic-u-money.svg',
   //    deepLink: 'mocha://home/umoney',
   //    url: 'mocha://home/umoney',
   //    openViewModal: false,
   //    callNative:'open_umoney'
   // },
   // {
   //    name: i18n.t('home.apps.online_shop'),
   //    icon: './images/ic-cart.svg',
   //    deepLink: 'mocha://webgamenew?ref=https%3A%2F%2Funitel.com.la%2Fshop-unitel',
   //    url: 'https://unitel.com.la/shop-unitel',
   //    openViewModal: false,
   //    callNative:'open_shop_online'
   // }
]

export const FavouriteList = [
   {
      id: 5,
      name: i18n.t('home.functions.buy_data'),
      icon: './images/ic-utilitie.svg',
      url: '/mobile/data-package',
      category: Category.MobileFunction,
      openViewModal: false
   },
   {
      id: 4,
      name: i18n.t('home.functions.buy_topup'),
      icon: './images/ic-top-up.svg',
      url: '/mobile/recharge',
      category: Category.MobileFunction,
      openViewModal: false
   },
   {
      id: 41,
      name: i18n.t('home.functions.transfer'),
      icon: './images/ic-transfers.svg',
      url: '/mobile/ishare',
      category: Category.MobileFunction,
      openViewModal: false
   },
   {
      id: 46,
      name: i18n.t('home.functions.online_shopping'),
      icon: './images/ic-shop-online.svg',
      url: 'https://unitel.com.la/shop-unitel',
      deepLink: 'mocha://webgamenew?ref=https%3A%2F%2Funitel.com.la%2Fshop-unitel',
      category: Category.MobileFunction,
      openViewModal: false
   },
   {
      id: 2,
      name: i18n.t('home.functions.view_invoice'),
      icon: './images/ic-pos.svg',
      url: '/mobile/view_invoice',
      category: Category.MobileFunction,
      openViewModal: false
   },
   {
      id: 47,
      name: i18n.t('home.functions.ucare_loyalty'),
      icon: './images/ic-loyalty.svg',
      url: '/ucare/customer-service/home',
      deepLink: 'mocha://webviewmyunitel?ref=https%3A%2F%2Fselfcare.unitel.com.la%3A8998%2F%23%2Fucare%2Fcustomer-service%2Fhome',
      category: Category.MobileFunction,
      openViewModal: false
   },
   // {
   //    id: 48,
   //    name: i18n.t('home.functions.umoney_transfer'),
   //    icon: './images/ic-transfer.svg',
   //    url: 'javascript:void(0)',
   //    category: Category.MobileFunction,
   //    openViewModal: false
   // },
   // {
   //    id: 49,
   //    name: i18n.t('home.functions.muanlai'),
   //    icon: './images/ic-muanlai.svg',
   //    url: 'http://muanlai.com',
   //    category: Category.DigitalService,
   //    openViewModal: true
   // },
   // {
   //    id: 50,
   //    name: i18n.t('home.functions.playhd'),
   //    icon: './images/ic-play-hd.svg',
   //    url: 'http://playhd.la',
   //    category: Category.DigitalService,
   //    openViewModal: true
   // },
   // {
   //    id: 51,
   //    name: i18n.t('home.functions.unitel_shop'),
   //    icon: './images/ic-shop.svg',
   //    url: '/mobile/map/by-current-location',
   //    category: Category.Miscellaneous,
   //    openViewModal: false
   // }
]

export const DigitalServiceList = [
   {
      name: i18n.t('home.digital_service.mocha'),
      shortUrl: 'mocha.com.la',
      fullUrl: 'http://mocha.com.la',
      image: './images/img-digital-1.png'
   },
   {
      name: i18n.t('home.digital_service.uclip'),
      shortUrl: 'uclip.la',
      fullUrl: 'http://uclip.la',
      image: './images/img-digital-2.png'
   },
   {
      name: i18n.t('home.digital_service.muanlai'),
      shortUrl: 'muanlai.com',
      fullUrl: 'http://muanlai.com',
      image: './images/img-digital-3.png'
   },
   {
      name: i18n.t('home.digital_service.playhd'),
      shortUrl: 'playhd.la',
      fullUrl: 'http://playhd.la',
      image: './images/img-digital-4.png'
   },
   {
      name: i18n.t('home.digital_service.okara'),
      shortUrl: 'okara.la',
      fullUrl: 'http://okara.la',
      image: './images/img-digital-5.png'
   },
   {
      name: i18n.t('home.digital_service.ustudy'),
      shortUrl: 'ustudy.la',
      fullUrl: 'https://ustudy.la',
      image: './images/img-digital-6.png'
   },
   {
      name: i18n.t('home.digital_service.umusic'),
      shortUrl: 'umusic.la',
      fullUrl: 'http://umusic.la',
      image: './images/img-digital-9.png'
   },
   {
      name: i18n.t('home.digital_service.udating'),
      shortUrl: 'udating.la',
      fullUrl: 'http://udating.la',
      image: './images/img-digital-8.png'
   },
   {
      name: i18n.t('home.digital_service.unews'),
      shortUrl: 'unews.la',
      fullUrl: 'https://unews.la',
      image: './images/img-digital-7.png'
   }
]

export const FunctionList = [
   {
      id: 1,
      name: i18n.t('home.functions.user_info'),
      icon: './images/ic-user-info.svg',
      url: '/user-info',
      category: Category.MobileFunction,
      openViewModal: false
   },
   {
      id: 2,
      name: i18n.t('home.functions.recharge'),
      icon: './images/ic-recharge.svg',
      url: '/mobile/recharge',
      category: Category.MobileFunction,
      openViewModal: false
   },
   {
      id: 3,
      name: i18n.t('home.functions.airtime_credit'),
      icon: './images/ic-air.svg',
      url: '/mobile/credit-service',
      category: Category.MobileFunction,
      openViewModal: false
   },
   {
      id: 4,
      name: i18n.t('home.functions.i_share'),
      icon: './images/ic-i-share.svg',
      url: '/mobile/ishare',
      category: Category.MobileFunction,
      openViewModal: false
   },
   {
      id: 5,
      name: i18n.t('home.functions.data_packages'),
      icon: './images/ic-data.svg',
      url: '/mobile/data-package',
      category: Category.MobileFunction,
      openViewModal: false
   },
   // { id: 6, name: i18n.t("home.functions.change_esim"), icon: './images/ic-sim.svg', url: 'javascript:void(0)', category: Category.MobileFunction, openViewModal: false },
   // { id: 7, name: i18n.t("home.functions.change_pre_post"), icon: './images/ic-pre-post.svg', url: 'javascript:void(0)', category: Category.MobileFunction, openViewModal: false },
   // { id: 8, name: i18n.t("home.functions.change_sim_3g_4g"), icon: './images/ic-esim.svg', url: 'javascript:void(0)', category: Category.MobileFunction, openViewModal: false },
   {
      id: 9,
      name: i18n.t('home.functions.view_invoice'),
      icon: './images/ic-invoice.svg',
      url: 'javascript:void(0)',
      category: Category.MobileFunction,
      openViewModal: false
   },
   {
      id: 10,
      name: i18n.t('home.functions.charge_history'),
      icon: './images/ic-history.svg',
      url: '/mobile/charge-history/bill-deals',
      category: Category.MobileFunction,
      openViewModal: false
   },
   {
      id: 11,
      name: i18n.t('home.functions.laoapp'),
      icon: './images/ic-lao-app.svg',
      url: 'http://onelink.to/q9k35b',
      category: Category.DigitalService,
      openViewModal: false
   },
   {
      id: 12,
      name: i18n.t('home.functions.uclip'),
      icon: './images/ic-uclip.svg',
      url: 'http://uclip.la',
      category: Category.DigitalService,
      openViewModal: true
   },
   {
      id: 13,
      name: i18n.t('home.functions.ustudy'),
      icon: './images/ic-ustudy.svg',
      url: 'https://ustudy.la',
      category: Category.DigitalService,
      openViewModal: true
   },
   {
      id: 14,
      name: i18n.t('home.functions.udating'),
      icon: './images/ic-udating.svg',
      url: 'http://udating.la',
      category: Category.DigitalService,
      openViewModal: true
   },
   {
      id: 15,
      name: i18n.t('home.functions.unews'),
      icon: './images/ic-unews.svg',
      url: 'https://unews.la',
      category: Category.DigitalService,
      openViewModal: true
   },
   {
      id: 16,
      name: i18n.t('home.functions.okara'),
      icon: './images/ic-okara.svg',
      url: 'http://okara.la',
      category: Category.DigitalService,
      openViewModal: true
   },
   {
      id: 17,
      name: i18n.t('home.functions.umusic'),
      icon: './images/ic-umusic.svg',
      url: 'http://umusic.la',
      category: Category.DigitalService,
      openViewModal: true
   },
   {
      id: 18,
      name: i18n.t('home.functions.promotion_info'),
      icon: './images/ic-promotion.svg',
      url: 'javascript:void(0)',
      category: Category.InternetService,
      openViewModal: false
   },
   {
      id: 19,
      name: i18n.t('home.functions.pay_monthly_fee'),
      icon: './images/ic-pay-fee.svg',
      url: '/ftth/pay-monthly-fee',
      category: Category.InternetService,
      openViewModal: false
   },
   {
      id: 20,
      name: i18n.t('home.functions.change_package'),
      icon: './images/ic-package.svg',
      url: '/ftth/ftth-package',
      category: Category.InternetService,
      openViewModal: false
   },
   {
      id: 21,
      name: i18n.t('home.functions.view_invoice'),
      icon: './images/ic-invoice.svg',
      url: '/ftth/view-invoice',
      category: Category.InternetService,
      openViewModal: false
   },
   {
      id: 22,
      name: i18n.t('home.functions.report_error'),
      icon: './images/ic-report.svg',
      url: 'javascript:void(0)',
      category: Category.InternetService,
      openViewModal: false
   },
   {
      id: 23,
      name: i18n.t('home.functions.charge_history'),
      icon: './images/ic-history.svg',
      url: '/ftth/charge-history',
      category: Category.InternetService,
      openViewModal: false
   },
   {
      id: 24,
      name: i18n.t('home.functions.change_password'),
      icon: './images/ic-password.svg',
      url: '/ftth/change-pass',
      category: Category.InternetService,
      openViewModal: false
   },
   {
      id: 25,
      name: i18n.t('home.functions.speedtest'),
      icon: './images/ic-speed-test.svg',
      url: 'javascript:void(0)',
      category: Category.InternetService,
      openViewModal: false
   },
   {
      id: 26,
      name: i18n.t('home.functions.codashop'),
      icon: './images/ic-codashop.svg',
      url: 'javascript:void(0)',
      category: Category.Game,
      openViewModal: false
   },
   {
      id: 27,
      name: i18n.t('home.functions.elofun'),
      icon: './images/ic-elofun.svg',
      url: 'javascript:void(0)',
      category: Category.Game,
      openViewModal: false
   },
   {
      id: 28,
      name: i18n.t('home.functions.ugame'),
      icon: './images/ic-ugame.svg',
      url: 'javascript:void(0)',
      category: Category.Game,
      openViewModal: false
   },
   {
      id: 29,
      name: i18n.t('home.functions.gamezz'),
      icon: './images/ic-gamezz.svg',
      url: 'javascript:void(0)',
      category: Category.Game,
      openViewModal: false
   },
   {
      id: 30,
      name: i18n.t('home.functions.iwin'),
      icon: './images/ic-iwin.svg',
      url: 'javascript:void(0)',
      category: Category.Game,
      openViewModal: false
   },
   {
      id: 31,
      name: i18n.t('home.functions.ilucky'),
      icon: './images/ic-i-lucky.svg',
      url: 'javascript:void(0)',
      category: Category.Game,
      openViewModal: false
   },
   {
      id: 32,
      name: i18n.t('home.functions.minecaft'),
      icon: './images/ic-mine.svg',
      url: 'javascript:void(0)',
      category: Category.Game,
      openViewModal: false
   },
   {
      id: 52,
      name: i18n.t('home.functions.juicy_dash'),
      icon: './images/ic-juiky.svg',
      url: 'javascript:void(0)',
      category: Category.Game,
      openViewModal: false
   },
   {
      id: 53,
      name: i18n.t('home.functions.slot_fruit'),
      icon: './images/ic-fruit.svg',
      url: 'javascript:void(0)',
      category: Category.Game,
      openViewModal: false
   },
   {
      id: 33,
      name: i18n.t('home.functions.exchange_to_package'),
      icon: './images/ic-ex-pack.svg',
      url: '/ucare/package-deals',
      category: Category.CustomerService,
      openViewModal: false
   },
   {
      id: 34,
      name: i18n.t('home.functions.exchange_to_data'),
      icon: './images/ic-ex-data.svg',
      url: '/ucare/data-deals',
      category: Category.CustomerService,
      openViewModal: false
   },
   {
      id: 35,
      name: i18n.t('home.functions.exchange_to_gift'),
      icon: './images/ic-ex-gift.svg',
      url: '/ucare/gift-deals',
      category: Category.CustomerService,
      openViewModal: false
   },
   {
      id: 36,
      name: i18n.t('home.functions.partner_deals'),
      icon: './images/ic-partner.svg',
      url: '/ucare/partner-deals',
      category: Category.CustomerService,
      openViewModal: false
   },
   {
      id: 37,
      name: i18n.t('home.functions.chat_online'),
      icon: './images/ic-chat-online.svg',
      url: 'javascript:void(0)',
      category: Category.CustomerService,
      openViewModal: false
   },
   {
      id: 38,
      name: i18n.t('home.functions.video_call'),
      icon: './images/ic-video-call.svg',
      url: 'javascript:void(0)',
      category: Category.CustomerService,
      openViewModal: false
   },
   {
      id: 39,
      name: i18n.t('home.functions.voice_call'),
      icon: './images/ic-voice-call.svg',
      url: 'javascript:void(0)',
      category: Category.CustomerService,
      openViewModal: false
   },
   {
      id: 40,
      name: i18n.t('home.functions.contact_center'),
      icon: './images/ic-contact.svg',
      url: 'javascript:void(0)',
      category: Category.CustomerService,
      openViewModal: false
   },
   {
      id: 41,
      name: i18n.t('home.functions.email'),
      icon: './images/ic-email.svg',
      url: 'javascript:void(0)',
      category: Category.CustomerService,
      openViewModal: false
   },
   {
      id: 42,
      name: i18n.t('home.functions.qa'),
      icon: './images/ic-qa.svg',
      url: 'javascript:void(0)',
      category: Category.CustomerService,
      openViewModal: false
   },
   {
      id: 43,
      name: i18n.t('home.functions.community'),
      icon: './images/ic-community.svg',
      url: 'javascript:void(0)',
      category: Category.CustomerService,
      openViewModal: false
   },
   {
      id: 44,
      name: i18n.t('home.functions.unitel_news'),
      icon: './images/ic-unitel-news.svg',
      url: 'https://www.unitel.com.la/news',
      category: Category.Miscellaneous,
      openViewModal: false
   },
   {
      id: 45,
      name: i18n.t('home.functions.online_shopping'),
      icon: './images/ic-shopping.svg',
      url: 'https://unitel.com.la/shop-unitel',
      category: Category.Miscellaneous,
      openViewModal: false
   },
   {
      id: 46,
      name: i18n.t('home.functions.special_packages'),
      icon: './images/ic-special.svg',
      url: '/mobile/special-package',
      category: Category.MobileFunction,
      openViewModal: false
   },
   {
      id: 47,
      name: i18n.t('home.functions.do_it_yourself'),
      icon: './images/ic-yourself.svg',
      url: '/mobile/do-it-yourself',
      category: Category.MobileFunction,
      openViewModal: false
   },
   {
      id: 48,
      name: i18n.t('home.functions.umoney_transfer'),
      icon: './images/ic-transfer.svg',
      url: 'javascript:void(0)',
      category: Category.MobileFunction,
      openViewModal: false
   },
   {
      id: 49,
      name: i18n.t('home.functions.muanlai'),
      icon: './images/ic-muanlai.svg',
      url: 'http://muanlai.com',
      category: Category.DigitalService,
      openViewModal: true
   },
   {
      id: 50,
      name: i18n.t('home.functions.playhd'),
      icon: './images/ic-play-hd.svg',
      url: 'http://playhd.la',
      category: Category.DigitalService,
      openViewModal: true
   },
   {
      id: 51,
      name: i18n.t('home.functions.unitel_shop'),
      icon: './images/ic-shop.svg',
      url: '/mobile/map/by-current-location',
      category: Category.Miscellaneous,
      openViewModal: false
   }
]

export const CustomerType = [
   { code: 'GOV', name: i18n.t('home.user_information.GOV') },
   { code: 'PRV', name: i18n.t('home.user_information.PRV') },
   { code: 'DIS', name: i18n.t('home.user_information.DIS') },
   { code: 'MIL', name: i18n.t('home.user_information.MIL') },
   { code: 'POL', name: i18n.t('home.user_information.POL') },
   { code: 'ORG', name: i18n.t('home.user_information.ORG') },
   { code: 'COM', name: i18n.t('home.user_information.COM') },
   { code: 'NOR', name: i18n.t('home.user_information.NOR') },
   { code: 'INDN', name: i18n.t('home.user_information.INDN') },
   { code: 'VIP', name: i18n.t('home.user_information.VIP') },
   { code: 'ECUS', name: i18n.t('home.user_information.ECUS') },
   { code: 'GOV01', name: i18n.t('home.user_information.GOV01') },
   { code: 'EII', name: i18n.t('home.user_information.EII') },
   { code: 'PER', name: i18n.t('home.user_information.PER') },
   { code: 'PROV', name: i18n.t('home.user_information.PROV') },
   { code: 'MILI', name: i18n.t('home.user_information.MILI') },
   { code: 'POLI', name: i18n.t('home.user_information.POLI') },
   { code: 'EG', name: i18n.t('home.user_information.EG') },
   { code: 'E0', name: i18n.t('home.user_information.E0') },
   { code: 'VIP01', name: i18n.t('home.user_information.VIP01') },
   { code: 'VIP02', name: i18n.t('home.user_information.VIP02') },
   { code: 'VIP03', name: i18n.t('home.user_information.VIP03') },
   { code: 'VIP04', name: i18n.t('home.user_information.VIP04') },
   { code: 'NOR02', name: i18n.t('home.user_information.NOR02') },
   { code: 'NOR03', name: i18n.t('home.user_information.NOR03') },
   { code: 'NOR01', name: i18n.t('home.user_information.NOR01') },
   { code: 'SST02', name: i18n.t('home.user_information.SST02') },
   { code: 'ITN01', name: i18n.t('home.user_information.ITN01') },
   { code: 'ITN02', name: i18n.t('home.user_information.ITN02') },
   { code: 'ENG', name: i18n.t('home.user_information.ENG') },
   { code: '001', name: i18n.t('home.user_information.001') },
   { code: '002', name: i18n.t('home.user_information.002') },
   { code: 'SST01', name: i18n.t('home.user_information.SST01') },
   { code: 'GOV04', name: i18n.t('home.user_information.GOV04') },
   { code: 'EH', name: i18n.t('home.user_information.EH') },
   { code: 'EM', name: i18n.t('home.user_information.EM') },
   { code: 'EUS', name: i18n.t('home.user_information.EUS') },
   { code: 'GOV02', name: i18n.t('home.user_information.GOV02') },
   { code: 'GOV03', name: i18n.t('home.user_information.GOV03') },
   { code: 'NOR04', name: i18n.t('home.user_information.NOR04') },
   { code: 'MOD', name: i18n.t('home.user_information.MOD') }
]


export const CustomerTypePrepay = [
    { code: '', name: '' },
    { code: 'GOV', name: i18n.t('home.user_information.GOV') },
    { code: 'PRV', name: i18n.t('home.user_information.PRV') },
    { code: 'DIS', name: i18n.t('home.user_information.DIS') },
    { code: 'MIL', name: i18n.t('home.user_information.MIL') },
    { code: 'POL', name: i18n.t('home.user_information.POL') },
    { code: 'ORG', name: i18n.t('home.user_information.ORG') },
    { code: 'COM', name: i18n.t('home.user_information.COM') },
    { code: 'NOR', name: i18n.t('home.user_information.NOR') },
    { code: 'INDN', name: i18n.t('home.user_information.INDN') },
    { code: 'VIP', name: i18n.t('home.user_information.VIP') },
    { code: 'ECUS', name: i18n.t('home.user_information.ECUS') }
]
export const CustomerTypePostpaid = [
    { code: '', name: '' },
    { code: 'ECUS', name: i18n.t('home.user_information.ECUS') },
    { code: 'GOV01', name: i18n.t('home.user_information.GOV01') },
    { code: 'EII', name: i18n.t('home.user_information.EII') },
    { code: 'PER', name: i18n.t('home.user_information.PER') },
    { code: 'PROV', name: i18n.t('home.user_information.PROV') },
    { code: 'MILI', name: i18n.t('home.user_information.MILI') },
    { code: 'POLI', name: i18n.t('home.user_information.POLI') },
    { code: 'EG', name: i18n.t('home.user_information.EG') },
    { code: 'E0', name: i18n.t('home.user_information.E0') },
    { code: 'VIP01', name: i18n.t('home.user_information.VIP01') },
    { code: 'VIP02', name: i18n.t('home.user_information.VIP02') },
    { code: 'VIP03', name: i18n.t('home.user_information.VIP03') },
    { code: 'VIP04', name: i18n.t('home.user_information.VIP04') },
    { code: 'NOR02', name: i18n.t('home.user_information.NOR02') },
    { code: 'NOR03', name: i18n.t('home.user_information.NOR03') },
    { code: 'NOR01', name: i18n.t('home.user_information.NOR01') },
    { code: 'SST02', name: i18n.t('home.user_information.SST02') },
    { code: 'ITN01', name: i18n.t('home.user_information.ITN01') },
    { code: 'ITN02', name: i18n.t('home.user_information.ITN02') },
    { code: 'ENG', name: i18n.t('home.user_information.ENG') },
    { code: '001', name: i18n.t('home.user_information.001') },
    { code: '002', name: i18n.t('home.user_information.002') },
    { code: 'GOV', name: i18n.t('home.user_information.GOV') },
    { code: 'SST01', name: i18n.t('home.user_information.SST01') },
    { code: 'GOV04', name: i18n.t('home.user_information.GOV04') },
    { code: 'EH', name: i18n.t('home.user_information.EH') },
    { code: 'EM', name: i18n.t('home.user_information.EM') },
    { code: 'EUS', name: i18n.t('home.user_information.EUS') },
    { code: 'GOV02', name: i18n.t('home.user_information.GOV02') },
    { code: 'GOV03', name: i18n.t('home.user_information.GOV03') },
    { code: 'NOR04', name: i18n.t('home.user_information.NOR04') },
    { code: 'MOD', name: i18n.t('home.user_information.MOD') }
]

export const TypeId = [
   { code: 1, name: i18n.t('home.user_information.id_card') },
   { code: 3, name: i18n.t('home.user_information.passport3') },
   { code: 6, name: i18n.t('home.user_information.family_book6') }
   // { code: 5, name: i18n.t('home.registration_info.others') }
]

export const Genders = [
    { code: 'M', name: i18n.t('home.user_information.male') },
    { code: 'F', name: i18n.t('home.user_information.female') },
]
