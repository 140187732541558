import LocalStorage from '@/ultils/local-storage'
import router from '../router'
import axios from 'axios'

export function logout() {
    LocalStorage.removeItem('user_info')
    // ftth
    LocalStorage.removeItem('ftth_list_account')
    LocalStorage.removeItem('ftth_account')
    LocalStorage.removeItem('ftth_redirect_url')
    LocalStorage.removeItem('ftth_user_info')
}

export function isAuthenticated(path) {
    if (path.includes('/ftth') && path !== '/ftth/account-list') {
        if (LocalStorage.getItem('ftth_user_info') || LocalStorage.getItem('user_info')) {
            return true
        }else {
            logout()
            return false
        }
    } else if(LocalStorage.getItem('ftth_user_info') && path.includes('ucare')){
        return true
    } 

    if (!LocalStorage.getItem('user_info')) {
        logout()
        return false
    } else {
        return true
    }
}

export async function checkToSetAuthenticate(path, params) {
    if(params.accessToken && params.phone){
        await addTokenLogin(params.accessToken, params.phone)
    }
    if (params.appCode) {
        LocalStorage.setItem('appCode', params.appCode)
    }
    if (params.token && params.sessionId && params.isdn) {
        let newParams = {
            originalUrl: path,
            token: params.token,
            sessionId: params.sessionId,
            isdn: params.isdn
        }
        LocalStorage.setItem('user_info', JSON.stringify(newParams))
        if (path.includes("/ftth")) {
            if (params.ftthAccount) {
                LocalStorage.setItem('ftth_account', params.ftthAccount)
            } else {
                newParams.originalUrl = "/ftth/login"
                LocalStorage.setItem('user_info', JSON.stringify(newParams))
                if (path !== '/ftth/login') {
                    router.push("/ftth/login")
                }
            }
        }
        return true
    } else {
        return false
    }
}

export function isLoginFtth() {
    let userInfo = JSON.parse(LocalStorage.getItem('user_info')) ?? {}
    let ftthUserInfo = JSON.parse(LocalStorage.getItem('ftth_user_info')) ?? {}
    if (Object.keys(userInfo).length === 0 && Object.keys(ftthUserInfo).length !== 0) {
        return true
    } else {
        return false
    }
}

export async function addTokenLogin(accessToken, phone) {
    let apiConfig = {
        method: 'post',
        url: '',
        data: null,
        headers: { 'Content-Type': 'application/json' },
        dataType: 'json',
        contentType: 'application/json'
    }
    apiConfig['method'] = 'post'
    apiConfig['url'] = process.env.VUE_APP_ADD_TOKEN_LOGIN_API
    apiConfig['headers']['Authorization'] = `Bearer ${accessToken}`
    apiConfig['data'] = {
       username: phone,
       appCode: 'MyVTG',
       language: LocalStorage.getItem('lang')
    }

    await axios(apiConfig)
       .then(function (response) {
          if (response.data.username == phone) {
             let userInfo = {
                token: response.data.token,
                isdn: response.data.username,
                sessionId: response.data.sessionId
             }
             LocalStorage.setItem('user_info', JSON.stringify(userInfo))
             console.log('return addTokenLogin')
             return true
          } else {
            //  self.$store.dispatch('setNotification', { content: response.data.errorMessage })
             return false
          }
       })
       .catch(function () {
        //   self.$store.dispatch('setNotification', { content: self.$i18n.t('errors.system') })
          return false
       })
}