<template>
    <div>
        <div class="main-container" v-show="showMain">
            <div class="nav-bar js-nav-bar" @click="goBack">
                <div class="nav-bar__inner">
                    <span class="nav-bar__icon"><i class="icon-fonts icon-fonts--arrow-left"></i></span>
                    <h3 class="nav-bar__title">{{ $t('mobile.change_simcard.create_new_request') }}</h3>
                </div>
            </div>
            <div class="main-content">
                <ValidationObserver v-slot="{passes}">
                    <form @submit.prevent="passes(submit)">
                    <div class="register-info">
                        <div class="register-info__form">
                            <div class="form-customize">
                                <Input type="number"
                                    :label="$t('home.registration_info.phone_number')"
                                    :placeholder="$t('home.registration_info.enter_phone_number')"
                                    rules="required"
                                    :hasStar="true"
                                    disabled
                                    v-model="data.phone" />
                            </div>
                            <div class="form-customize">
                                <Input type="number"
                                    :label="$t('mobile.change_simcard.title_contact_1')"
                                    :placeholder="$t('mobile.change_simcard.contact_1')"
                                    rules="required"
                                    :hasStar="true"
                                    v-model="data.isdnContact" />
                            </div>
                            <div class="form-customize">
                                <Input type="number"
                                    :name="$t('mobile.change_simcard.contact_2')"
                                    :placeholder="$t('mobile.change_simcard.contact_2')"
                                    rules="required"
                                    v-model="data.isdnContact1" />
                            </div>
                            <div class="form-customize">
                                <Input type="number"
                                    :name="$t('mobile.change_simcard.contact_3')"
                                    :placeholder="$t('mobile.change_simcard.contact_3')"
                                    rules="required"
                                    v-model="data.isdnContact2" />
                            </div>
                            <div class="form-customize">
                                <Input type="number"
                                    :name="$t('mobile.change_simcard.contact_4')"
                                    :placeholder="$t('mobile.change_simcard.contact_4')"
                                    rules="required"
                                    v-model="data.isdnContact3" />
                            </div>
                            <div class="form-customize">
                                <Input type="number"
                                    :name="$t('mobile.change_simcard.contact_5')"
                                    :placeholder="$t('mobile.change_simcard.contact_5')"
                                    rules="required"
                                    v-model="data.isdnContact4" />
                            </div>
                            <div class="form-customize">
                                <Input type="text"
                                    :label="$t('mobile.change_simcard.Personal_information')"
                                    :name="$t('mobile.change_simcard.Personal_information')"
                                    :placeholder="$t('mobile.change_simcard.Personal_information')"
                                    rules="required"
                                    :hasStar="true"
                                    v-model="data.name" />
                            </div>
                            <!-- <div class="form-customize">
                                <Input type="date"
                                    :name="$t('mobile.change_simcard.Date_of_birth')"
                                    :placeholder="$t('mobile.change_simcard.Date_of_birth')"
                                    rules="required|date"
                                    :isLabel="false"
                                    rightIcon="icon-fonts icon-fonts--calendar"
                                    v-model="data.date_of_birth" />
                            </div>
                            <div class="form-customize">
                                <Select 
                                   :name="$t('mobile.change_simcard.Gender')"
                                    :placeholder="$t('mobile.change_simcard.Gender')"
                                    :dataSelect="lst_Genders"
                                    :isLabel="false"
                                    v-model="data.sex" />
                            </div>
                            <div class="form-customize">
                                <Select :label="$t('home.registration_info.document_type')"
                                    :placeholder="$t('home.registration_info.enter_document_type')"
                                    rules="required"
                                    :dataSelect="lst_TypeId"
                                    :hasStar="true"
                                    v-model="data.idType" />
                            </div> -->
                            <div class="form-customize">
                                <Input type="text"
                                :label="$t('mobile.change_simcard.Document_number')"
                                     :name="$t('mobile.change_simcard.Document_number')"
                                    :placeholder="$t('mobile.change_simcard.Document_number')"
                                    rules="required"
                                    :hasStar="true"
                                    v-model="data.idNum" />
                            </div>
                            <!-- <div class="form-customize">
                                <Input type="date"
                                    :name="$t('mobile.change_simcard.Issued_date')"
                                    :placeholder="$t('mobile.change_simcard.Issued_date')"
                                    rules="required|date"
                                    :isLabel="false"
                                    rightIcon="icon-fonts icon-fonts--calendar"
                                    v-model="data.id_issue_date" />
                            </div>
                            <div class="form-customize">
                                
                                <Input type="text"
                                    :name="$t('mobile.change_simcard.Issued_office')"
                                    :placeholder="$t('mobile.change_simcard.Issued_office')"
                                    rules="required"
                                    :isLabel="false"
                                    v-model="data.issued_office" />
                            </div> -->
                            <!-- <div class="form-customize">
                                <div class="register-upload">
                                <div class="register-upload__top">
                                    <label class="label-title">Image of document (*)</label>
                                    <button class="button button--upload" type="button"><i class="icon-fonts icon-fonts--camera"></i>
                                    </button>
                                    <button class="button button--upload" type="button">Browse</button>
                                </div>
                                <div class="register-upload__action"><span class="register-upload__delete">Delete</span></div>
                                <p class="register-upload__noti">No image of document</p>
                                <div class="register-upload__content"><img src="@/assets/images/img-register-upload-1.png" alt="img"></div>
                                </div>
                            </div>
                            <div class="form-customize">
                                <div class="register-upload">
                                <div class="register-upload__top">
                                    <label class="label-title">Image of customer (*)</label>
                                    <button class="button button--upload" type="button"><i class="icon-fonts icon-fonts--camera"></i>
                                    </button>
                                </div>
                                <div class="register-upload__action"><span class="register-upload__delete">Delete</span></div>
                                <p class="register-upload__noti">No image of customer</p>
                                <div class="register-upload__content"><img src="@/assets/images/img-register-upload-2.png" alt="img"></div>
                                </div>
                            </div> -->
                            <div class="form-customize">
                                <div class="register-upload">
                                    <div class="register-upload__top">
                                        <label class="label-title">{{$t('home.registration_info.image_document')}} <span style="color:red">(*)</span></label>

                                        <button class="button button--upload" type="button" @click="chooseCamera('cDoc1')"  id="image1">
                                            <i class="icon-fonts icon-fonts--camera"></i>
                                        </button>

                                        <button class="button button--upload" @click="chooseFiles('fDoc1')" type="button">{{$t('home.registration_info.browse')}}</button>
                                    </div>
                                    <br v-if="data.image1 == './images/idtype.png'" />
                                    <div class="register-upload__action" v-if="data.image1 && data.image1 != './images/idtype.png'" @click="onDeleteImage1"><span class="register-upload__delete"><a href="javascript:;" style="color:red">{{$t('home.registration_info.delete')}}</a></span></div>
                                    <p class="register-upload__noti" v-if="errorImg1">{{$t('home.registration_info.no_image')}}</p>
                                    <div class="register-upload__content"><img id="container1" :src="image1" alt="img" width="345" height=""></div>
                                </div>
                            </div>

                            <!--Cho nay la cho input file va camera-->
                            <input type="file" id="cameraUpload" accept="image/*" capture="camera" @change="onCameraChange" hidden />
                            <input id="fileUpload" type="file" accept="image/png, image/jpeg" @change="onFileChange" hidden>

                            <!--Cho nay la cho input file va camera-->
                            <input type="file" id="cameraUpload3" accept="image/*" capture="camera" @change="onCameraChange" hidden />
                            <input id="fileUploa3" type="file" accept="image/png, image/jpeg" @change="onFileChange" hidden>
                            <!-------------------------------------------------------------------------------------------------------------->

                            <div class="form-customize">
                                <div class="register-upload">
                                    <div class="register-upload__top">
                                        <label class="label-title">{{$t('home.registration_info.image_customer')}} <span style="color:red">(*)</span></label>
                                        <button class="button button--upload" type="button" @click="chooseCamera('cCus')">
                                            <i class="icon-fonts icon-fonts--camera"></i>
                                        </button>
                                    </div>
                                    <br v-if="data.image2 == './images/image_client1.png'" />
                                    <div class="register-upload__action" v-if="data.image2 && data.image2 != './images/image_client1.png'" @click="onDeleteImage2"><span class="register-upload__delete"><a href="javascript:;" style="color:red">{{$t('home.registration_info.delete')}}</a></span></div>
                                    <p class="register-upload__noti" v-if="errorImg2">{{$t('home.registration_info.no_image_customer')}}</p>
                                    <div class="register-upload__content"><img id="container2" :src="image2" alt="img" width="345" height="250"></div>
                                </div>
                            </div>
                            
                            <div class="form-customize">
                                <Input type="text"
                                    :label="$t('mobile.change_simcard.Contact_number')"
                                    :name="$t('mobile.change_simcard.Contact_number')"
                                    :placeholder="$t('mobile.change_simcard.Contact_number')"
                                    rules="required"
                                    :hasStar="true"
                                    v-model="data.contact_number" />
                            </div>
                            <div class="form-customize">

                                <Input type="number"
                                    :label="$t('mobile.change_simcard.Serial_of_New_SIMCARD')"
                                    :name="$t('mobile.change_simcard.Serial_of_New_SIMCARD')"
                                    :placeholder="$t('mobile.change_simcard.Serial_of_New_SIMCARD')"
                                    rules="required"
                                    :hasStar="true"
                                    v-model="data.serial_new_simcard" />
                            </div>
                            <!-- <div class="form-customize">
                                <div class="register-upload">
                                <div class="register-upload__top">
                                    <label class="label-title">Image of new SIMCARD (*)</label>
                                    <button class="button button--upload" type="button"><i class="icon-fonts icon-fonts--camera"></i>
                                    </button>
                                    <button class="button button--upload" type="button">Browse</button>
                                </div>
                                <div class="register-upload__action"><span class="register-upload__delete">Delete</span></div>
                                <div class="register-upload__content"><img src="@/assets/images/img-register-upload-3.png" alt="img"></div>
                                </div>
                            </div> -->
                             <div class="form-customize">
                                <div class="register-upload">
                                    <div class="register-upload__top">
                                        <label class="label-title">{{$t('mobile.change_simcard.Image_of_new_SIMCARD')}}<span style="color:red">(*)</span></label>

                                        <button class="button button--upload" type="button" @click="chooseCamera('cDoc3')"  id="image3">
                                            <i class="icon-fonts icon-fonts--camera"></i>
                                        </button>

                                        <button class="button button--upload" @click="chooseFiles('fDoc3')" type="button">{{$t('home.registration_info.browse')}}</button>
                                    </div>
                                    <br v-if="data.image3 == './images/img-register-upload-3.png'" />
                                    <div class="register-upload__action" v-if="data.image3 && data.image3 != './images/img-register-upload-3.png'" @click="onDeleteImage3">
                                        <span class="register-upload__delete">
                                            <a href="javascript:;" style="color:red">{{$t('home.registration_info.delete')}}</a>
                                        </span>
                                    </div>
                                    <p class="register-upload__noti" v-if="errorImg3">{{$t('mobile.change_simcard.no_Image_of_new_SIMCARD')}}</p>
                                    <div class="register-upload__content"><img id="container3" :src="image3" alt="img" width="345" height=""></div>
                                </div>
                            </div>
                            </div>
                            <div class="register-info__btn">
                            <button class="button button--primary button--full">{{$t('mobile.change_simcard.submit')}}
                            </button>
                            </div>
                        </div>
                    </form>
                </ValidationObserver>
            </div>
        </div>
        <!--Man crop-->
        <div class="main-container" v-show="!showMain">
            <div class="nav-bar js-nav-bar">
                <div class="nav-bar__inner">
                    <span class="nav-bar__icon" @click="backMain"><i class="icon-fonts icon-fonts--arrow-left"></i></span>
                    <h3 class="nav-bar__title">{{$t('mobile.change_to_postpaid.title')}}</h3><a @click="rotateImage" class="nav-bar__link" style="margin-right:150px; display: none" href="javascript:;">{{$t('home.registration_info.rotate')}}</a> <a @click="plipImage(2)" class="nav-bar__link" style="margin-right:100px; display: none" href="javascript:;">{{$t('home.registration_info.plipV')}}</a><a @click="plipImage(1)" class="nav-bar__link" style="margin-right:50px; display: none" href="javascript:;">{{$t('home.registration_info.plipH')}}</a> <a @click="getCropImage()" class="nav-bar__link" href="javascript:;">{{$t('home.registration_info.crop')}}</a>
                </div>
            </div>
            <div class="main-content">
                <div class="fullscreenImage">
                    <cropper :transitions="true"
                                :auto-zoom="true"
                                ref="cropper"
                                default-boundaries="fill"
                                class="cropper"
                                image-class="cropper__image"
                                :src="url"></cropper>
                </div>
            </div>

        </div>
    </div>
</template>
<script>
import BaseComponent from '@/mixins/BaseComponent'
import service from './../../services/MobileService'
import store from '@/plugins/vuex/store'
import { InvoiceType, DATE_FORMAT, MONTH_FORMAT } from './../../constants/data'
import moment from 'moment'
import LocalStorage from '@/ultils/local-storage'
import { ValidationObserver } from 'vee-validate'
import Input from '@/components/form/Input'
import {Genders,TypeId} from './../../constants/data'
// import Select from '@/components/form/Select'
import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css';

export default {
   name: 'CreateNewRequest',
   components: {
      ValidationObserver,
      Input,
    //   Select,
      Cropper
   },
   mixins: [BaseComponent],
   data() {
      return {
         data:{
            phone:'',
            isdnContact:'',
            isdnContact1:'',
            isdnContact2:'',
            isdnContact3:'',
            isdnContact4:'',
            portraitImg:'',
            idImg:'',
            newSerialImg:'',
            gender:0,
            sex:'M',
            date_of_birth:'',
            id_issued_date:'',
            issued_office:'',
            name:'',
            image1: "./images/idtype.png",
            image2: "./images/image_client1.png",
            image3:"./images/img-register-upload-3.png",
            idType: null,
            idNum:'',
            serial_new_simcard:'',
            contact_number:''
         },
         lst_Genders: Genders,
         lst_TypeId:TypeId,
         image1: "./images/idtype.png",
        image2: "./images/image_client1.png",
        image3:"./images/img-register-upload-3.png",
        errorImg1: false,
        errorImg2: false,
        errorImg3: false,
        imageType: null,
        showMain: true,
        url: null
      }
   },
   mounted() {
      this.setTitle(this.$i18n.t('mobile.change_simcard.change_simcard'))
      this.loadScript('js/date-picker.js')
      this.loadScript('js/select2.js')
      this.loadScript('js/scroll.js')
      var userInfo = JSON.parse(LocalStorage.getItem('user_info')) ?? {}
      this.data = {...store.state.change_simcard.step2}
      this.data.phone = userInfo['isdn']
      this.image1 = this.data.image1 ? this.data.image1 : ""
      this.image2 = this.data.image2 ? this.data.image2 : ""
      this.image3 = this.data.image3 ? this.data.image3 : ""
    //   if(this.data && this.data.name == ''){
    //     this.loadData()
    //   }
        this.loadData()
   },
   methods: {    
        async submit(){
            if (this.data.image1 == null || this.data.image1 == "./images/idtype.png") {
                this.errorImg1 = true;
            }

            if (this.data.image2 == null || this.data.image2 == "./images/image_client1.png" 
                || this.data.image2 == "./images/image_client2.png" 
                || this.data.image2 == "./images/image_client3.png") 
            {
                this.errorImg2 = true;
            }
            if (this.data.image3 == null || this.data.image3 == "./images/img-register-upload-3.png") {
                this.errorImg3 = true;
            }
            if (this.errorImg1 || this.errorImg2 || this.errorImg3) {
                return
            }
            let lstIsdn = [
                            this.data.isdnContact,
                            this.data.isdnContact1,
                            this.data.isdnContact2,
                            this.data.isdnContact3,
                            this.data.isdnContact4
                        ]
            let lstIdcontract = this.data.contact_number ? this.data.contact_number.split(',') : []
            let dataPost = {
                name:this.data.name,
                locate: LocalStorage.getItem('lang'),
                lstIsdnToCheck:lstIsdn,
                idNum:this.data.idNum,
                isdnContact:lstIdcontract.length != 0 ? lstIdcontract[0]:'',
                isdnContact1:lstIdcontract.length > 1 ? lstIdcontract[1]:lstIdcontract[0],
                isdnContact2:lstIdcontract.length > 2 ? lstIdcontract[2]:lstIdcontract[0],
                portraitImg : this.data.image1.replace("data:image/jpeg;base64,", "").replace("data:image/png;base64,", ""),
                idImg : this.data.image2.replace("data:image/jpeg;base64,", "").replace("data:image/png;base64,", ""),
                newSerialImg : this.data.image3.replace("data:image/jpeg;base64,", "").replace("data:image/png;base64,", "")
            }
            this.showLoading()
            let seft = this
            await service
                .changeSim(dataPost)
                .then((response) => {
                    if (response.result == 1 || response.result == 2) {
                        let dataCheck = {
                            id:response.id,
                            locate: LocalStorage.getItem('lang'),
                            serial:seft.data.serial_new_simcard,
                            lstIsdnToCheck:lstIsdn,
                            name:this.data.name,
                            idNum:this.data.idNum,
                            isdnContact:lstIdcontract.length != 0 ? lstIdcontract[0]:'',
                            isdnContact1:lstIdcontract.length > 1 ? lstIdcontract[1]:lstIdcontract[0],
                            isdnContact2:lstIdcontract.length > 2 ? lstIdcontract[2]:lstIdcontract[0],
                            portraitImg : this.data.image1.replace("data:image/jpeg;base64,", "").replace("data:image/png;base64,", ""),
                            idImg : this.data.image2.replace("data:image/jpeg;base64,", "").replace("data:image/png;base64,", ""),
                            newSerialImg : this.data.image3.replace("data:image/jpeg;base64,", "").replace("data:image/png;base64,", "")
                        }
                        this.showLoading()
                        service
                            .checkSerial(dataCheck)
                            .then((response) => {
                                if (response.result == 1) {
                                    // let payload = {
                                    //     step:"step2",
                                    //     value:this.data
                                    // }
                                    // store.commit('setChangeSimcard', payload)

                                    let payloadSuccess = {
                                        step:"step3",
                                        value:{
                                            "message":response.message
                                        }
                                    }
                                    store.commit('setChangeSimcard', payloadSuccess)
                                    this.$router.push('/mobile/request-success-change-simcard')
                                } else {
                                    store.dispatch('setNotification', { content: response.message ?? response.message })
                                }
                            })
                            .finally(() => {
                                this.hideLoading()
                            })
                    } else{
                        this.hideLoading()
                        store.dispatch('setNotification', { content: response.message ?? response.message })
                    }
                })
                .finally(() => {
                    console.log('hide changeSim')
                })
            // let payload = {
            //     step:"step2",
            //     value:this.data
            // }
            // store.commit('setChangeSimcard', payload)
            // this.$router.push('/mobile/request-success-change-simcard')
            
        },
        loadData() {
            this.showLoading()
            let dataPost ={
                "payType": 2
            }
            service
                .getCustInfoTransfer(dataPost)
                .then((response) => {
                    if (response.errorCode == "0") {                       
                        let data = response.object.customerInfo ?? {}
                        this.data.idNum = data.idNo
                        this.data.name = data.name
                        this.data.sex = data.sex

                        let content2 = data.lstCusProfile.filter(x=>x.imageLabel =='Font side')
                        if (content2) {
                           if (content2[0].content) {
                              this.data.image1 = 'data:image/jpeg;base64,' + content2[0].content
                              this.image1 = 'data:image/jpeg;base64,' + content2[0].content
                           } else {
                              this.data.image1 = "./images/idtype.png"
                              this.image1 = "./images/idtype.png"
                           }
                        } 
                        // else {
                        //    this.data.image1 = "./images/idtype.png"
                        //    this.image1 = "./images/idtype.png"
                        // }
                        let content3 = data.lstCusProfile.filter(x=>x.imageLabel =='Avatar')
                        if (content3) {
                           if (content3[0].content) {
                              this.data.image2 = 'data:image/jpeg;base64,' + content3[0].content
                              this.image2 = 'data:image/jpeg;base64,' + content3[0].content
                           } else {
                              this.data.image2 = "/images/image_client1.png"
                              this.image2 = "/images/image_client1.png"
                           }
                        }
                        // else {
                        //    this.data.image2 = "/images/image_client1.png"
                        //    this.image2 = "/images/image_client1.png"
                        // }
                    } 
                })
                .finally(() => {
                    this.hideLoading()
                })
        },
        redirectToDetail() {
            this.$router.push('/mobile/detail-view-invoice')
        },
        chooseFiles(type) {
            // if (type != "cCus") {
            //     if (!this.data.idType) {
            //         store.dispatch('setNotification', {
            //             title: this.$i18n.t('notification.title'),
            //             content: this.$i18n.t('home.registration_info.enter_document_type'),
            //             closeButton: this.$i18n.t('buttons.close')
            //         })
            //         return
            //     }
            // }

            document.getElementById("fileUpload").click()
            this.imageType = type;
        },
        onFileChange(e) {
            const file = e.target.files[0];
            this.url = URL.createObjectURL(file);
            this.showMain = false;
        },
        chooseCamera(type) {
            // if (type == "cCus") {
            //     if (this.data.image1 == null || this.data.image1 == "./images/idtype.png") {
            //         store.dispatch('setNotification', {
            //             title: this.$i18n.t('notification.title'),
            //             content: this.$i18n.t('home.registration_info.notiContent'),
            //             closeButton: this.$i18n.t('buttons.close')
            //         })
            //         return
            //     }
            // } else {
            //     if (!this.data.idType) {
            //         store.dispatch('setNotification', {
            //             title: this.$i18n.t('notification.title'),
            //             content: this.$i18n.t('home.registration_info.enter_document_type'),
            //             closeButton: this.$i18n.t('buttons.close')
            //         })
            //         return
            //     }
            // }
            document.getElementById("cameraUpload").click()
            this.imageType = type;
        },
        onCameraChange(e) {
            const file = e.target.files[0];
            this.url = URL.createObjectURL(file);
            this.showMain = false;
        },
        backMain() {
            this.showMain = true;
            if (this.imageType == "cDoc1" || this.imageType == "fDoc1") {
                this.image1 = "./images/idtype.png";
            }
            if (this.imageType == "cCus") {
                this.image2 = "./images/image_client1.png";
            }
            if (this.imageType == "cDoc3" || this.imageType == "fDoc3") {
                this.image3 = "./images/img-register-upload-3.png";
            }
            document.getElementById("fileUpload").value = null
            document.getElementById("cameraUpload").value = null
            document.getElementById("fileUpload3").value = null
            document.getElementById("cameraUpload3").value = null
        },
        rotateImage() {
            this.$refs.cropper.rotate(90);
        },
        plipImage(number) {
            if (number == 1) {
                this.$refs.cropper.flip(true, false);
            } else {
                this.$refs.cropper.flip(false, true);
            }

        },
        closeCrop() {
            this.$emit('close')
        },
        getCropImage() {
            var urlResult = this.$refs.cropper.getResult().canvas.toDataURL("image/jpeg");
            if (this.imageType == "cDoc1" || this.imageType == "fDoc1") {
                this.errorImg1 = false;
                this.image1 = urlResult ? urlResult : "./images/idtype.png";
                //this.getInfoAI(this.data.image1)
            }
            if (this.imageType == "cCus") {
                this.errorImg2 = false;
                this.image2 = urlResult ? urlResult : "./images/image_client1.png";
                //this.compareFace(this.data.image2)
            }
            if (this.imageType == "cDoc3" || this.imageType == "fDoc3") {
                this.errorImg3 = false;
                this.image3 = urlResult ? urlResult : "./images/img-register-upload-3.png";
                //this.getInfoAI(this.data.image1)
            }
            document.getElementById("fileUpload").value = null
            document.getElementById("cameraUpload").value = null
            document.getElementById("fileUploa3").value = null
            document.getElementById("cameraUpload3").value = null
            this.showMain = true;

        },
        onDeleteImage1() {
            this.image1 = "./images/idtype.png";
            // if (this.data.idType == 3) {
            //     this.image2 = "./images/image_client2.png";
            // } else if (this.data.idType == 6) {
            //     this.image2 = "./images/image_client3.png";
            // } else {
            //     this.image2 = "./images/image_client1.png";
            // }
        },
        onDeleteImage2() {
            this.image2 = "./images/image_client1.png";
            // if (this.data.idType == 3) {
            //     this.image2 = "./images/image_client2.png";
            // } else if (this.data.idType == 6) {
            //     this.image2 = "./images/image_client3.png";
            // } else {
            //     this.image2 = "./images/image_client1.png";
            // }
        },
        onDeleteImage3() {
            this.image3 = "./images/img-register-upload-3.png";
            // if (this.data.idType == 3) {
            //     this.image3 = "./images/img-register-upload-3.png";
            // } else if (this.data.idType == 6) {
            //     this.image3 = "./images/img-register-upload-3.png";
            // } else {
            //     this.image3 = "./images/img-register-upload-3.png";
            // }
        },
   },
   watch: {
        type(newVal) {
            this.data = []
            if (LocalStorage.getItem('invoice_detail_home') === null) {
                if (newVal == InvoiceType.InvoiceStatement) {
                //this.timeStart = new moment().subtract(2, 'months').format(MONTH_FORMAT)
                //this.timeEnd = ''
                this.endMonth = ''
                } 
                // else {
                //    this.timeStart = new moment().subtract(2, 'months').format(DATE_FORMAT)
                //    this.timeEnd = moment().format(DATE_FORMAT)
                // }
            }
        },
        startMonth(newVal, oldVal) {
            if (this.type != InvoiceType.InvoiceStatement) {
                let startNum = this.getMiliseconds(newVal, DATE_FORMAT)
                let endNum = this.getMiliseconds(this.timeEnd, DATE_FORMAT)
                if (startNum > endNum) {
                this.startMonth = oldVal
                this.$store.dispatch('setNotification', {
                    content: this.$i18n.t('errors.from_date_greater_than_to_date')
                })
                }
            } else {
                let startNum = this.getMiliseconds(newVal, MONTH_FORMAT)
                let currentNum = new Date().getTime()
                if (startNum > currentNum) {
                this.startMonth = oldVal
                this.$store.dispatch('setNotification', {
                    content: this.$i18n.t('errors.from_month_greater_than_current_month')
                })
                }
            }
        },
        timeStart(newVal, oldVal) {
            if (this.type != InvoiceType.InvoiceStatement) {
                let startNum = this.getMiliseconds(newVal, DATE_FORMAT)
                let endNum = this.getMiliseconds(this.timeEnd, DATE_FORMAT)
                if (startNum > endNum) {
                this.timeStart = oldVal
                this.$store.dispatch('setNotification', {
                    content: this.$i18n.t('errors.from_date_greater_than_to_date')
                })
                }
            } else {
                let startNum = this.getMiliseconds(newVal, MONTH_FORMAT)
                let currentNum = new Date().getTime()
                if (startNum > currentNum) {
                this.timeStart = oldVal
                this.$store.dispatch('setNotification', {
                    content: this.$i18n.t('errors.from_month_greater_than_current_month')
                })
                }
            }
        },
        timeEnd(newVal, oldVal) {
            let current = moment()
            let startNum = this.getMiliseconds(this.timeStart, DATE_FORMAT)
            let endNum = this.getMiliseconds(newVal, DATE_FORMAT)
            if (startNum > endNum) {
                this.timeEnd = oldVal
                this.$store.dispatch('setNotification', {
                content: this.$i18n.t('errors.from_date_greater_than_to_date')
                })
            } else if (endNum > current) {
                this.timeEnd = oldVal
                this.$store.dispatch('setNotification', {
                content: this.$i18n.t('errors.to_date_greater_than_current_day')
                })
            }
        },
        typeRadio(){
            this.loadScript('js/date-month-picker.js')
            this.loadScript('js/date-picker.js')
            this.loadScript('js/select2.js')
            this.loadScript('js/scroll.js')
            this.password = ''
            this.typePass = 'password'
            this.data =[]
            // == 1 trả trước, ==2 trả sau
            if(this.subType == 1)
            {
                this.type = InvoiceType.Receipt
            }
            if (LocalStorage.getItem('invoice_detail_home')) {
                let params = JSON.parse(LocalStorage.getItem('invoice_detail_home')).searchParams
                this.password = params.password
                this.type = params.type
            }
            
        },
        image1(val) {
            this.data.image1 = val;
        },
        image2(val) {
            this.data.image2 = val;
        },
        image3(val) {
            this.data.image3 = val;
        },
        showMain(val) {
                var vm = this;
                if (val) {
                    if (vm.imageType == "cDoc1" || vm.imageType == "fDoc1") {
                        setTimeout(function () {
                            const el = vm.$el.querySelector("#container1");
                            if (el) {
                                el.scrollIntoView();
                            }
                        }, 50)
                    }
                    if (vm.imageType == "cCus") {
                        setTimeout(function () {
                            const el = vm.$el.querySelector("#container2");
                            if (el) {
                                el.scrollIntoView();
                            }
                        }, 50)
                    }
                }
        },
        'data.date_of_birth'(newVal) {
            if(newVal && newVal.length == 10){
                let current = this.getMiliseconds(new Date(), DATE_FORMAT)
                let dateNum = this.getMiliseconds(newVal, DATE_FORMAT)
                if (dateNum > 0) {
                    if (dateNum > current) {
                        this.data.date_of_birth = null
                        store.dispatch('setNotification', {
                            content: this.$i18n.t('mobile.change_to_postpaid.error_birthday')
                        })
                    }
                }
                
                this.loadScript('js/date-picker.js')
                this.loadScript('js/date-month-picker.js')
            }
        },
        'data.id_issue_date'(newVal) {
            if(newVal && newVal.length == 10){
                let current = this.getMiliseconds(new Date(), DATE_FORMAT)
                let dateNum = this.getMiliseconds(newVal, DATE_FORMAT)
                if (dateNum > 0) {
                    if (dateNum > current) {
                        this.data.id_issue_date = null
                        store.dispatch('setNotification', {
                            content: this.$i18n.t('mobile.change_to_postpaid.error_birthday')
                        })
                    }
                }
                
                this.loadScript('js/date-picker.js')
                this.loadScript('js/date-month-picker.js')
            }
        }
   }
}
</script>
