<template>
   <div class="main-container">
        <div class="nav-bar js-nav-bar">
          <div class="nav-bar__inner"><span class="nav-bar__icon"><i class="icon-fonts icon-fonts--arrow-left"></i></span>
            <h3 class="nav-bar__title">Change to Postpaid</h3>
          </div>
        </div>
        <div class="main-content">
          <div class="change-postpaid">
            <div class="steps-bar">
              
            </div>
            <div class="request-success">
              <h3 class="request-success__title">Request Successfully</h3>
              <p class="request-success__des">You will get notification by SMS after we finish proccessing your request</p>
              <div class="request-success__content">
                <div class="request-success__box">
                  <div class="request-success__top">
                    <h5 class="request-success__sub">Order Information</h5>
                  </div>
                  <ul class="request-success__list">
                    <li class="request-success__item"><span class="request-success__name">Order code</span><span class="request-success__value">4EVER</span></li>
                    <li class="request-success__item"><span class="request-success__name">Date of order</span><span class="request-success__value">30/06/2022</span></li>
                    <li class="request-success__item"><span class="request-success__name">Status</span><span class="request-success__value">waiting for sale</span></li>
                  </ul>
                </div>
              </div>
              <div class="request-success__btn">
                <button class="button button--primary button--full" @click="backToHome">Back to homepage
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
</template>
<script>
import BaseComponent from '@/mixins/BaseComponent'
//import LocalStorage from '@/ultils/local-storage'

export default {
   name: 'ConvertService',
   mixins: [BaseComponent],
   components: {
   },
   data() {
      return {
         
      }
   },
   created() {
        this.loadScript('js/select2.js')
   },
   methods: {
       backToHome(){
           this.$router.push('/convert/customer-info')
       }
   },
}
</script>
