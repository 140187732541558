<template>
   <div class="main-container">
      <div class="nav-bar js-nav-bar" @click="goBack()">
         <div class="nav-bar__inner">
            <span class="nav-bar__icon"><i class="icon-fonts icon-fonts--arrow-left"></i></span>
            <h3 class="nav-bar__title">{{ $t('mobile.payment.title') }}</h3>
         </div>
      </div>
      <div class="main-content">
         <div class="recharge-basic">
            <div class="recharge-list">
               <ul class="recharge-list__detail">
                  <li class="recharge-list__item">
                     <a class="recharge-list__link" v-on:click="redirectScatchCards">
                        <span class="recharge-list__icon"
                           ><img src="@/assets/images/ic-scatch-cards.svg" alt="img"
                        /></span>
                        <h5 class="recharge-list__name">{{ $t('mobile.recharge.scatch_cards') }}</h5>
                     </a>
                  </li>
                  <li class="recharge-list__item" style="pointer-events: none; opacity: 0.6">
                     <a class="recharge-list__link" href="javascript:void(0)">
                        <span class="recharge-list__icon"><img src="@/assets/images/ic-bank.svg" alt="img" /></span>
                        <h5 class="recharge-list__name">{{ $t('mobile.recharge.bank') }}</h5>
                     </a>
                  </li>
                  <li class="recharge-list__item disabled">
                     <a class="recharge-list__link">
                        <span class="recharge-list__icon"><img src="@/assets/images/ic-air.svg" alt="img" /></span>
                        <h5 class="recharge-list__name">{{ $t('mobile.recharge.airtime') }}</h5>
                     </a>
                  </li>
               </ul>
            </div>
            <div class="recharge-list">
               <ul class="recharge-list__detail">
                  <li class="recharge-list__item" v-if="!isLaoApp">
                     <a class="recharge-list__link" v-on:click="redirectUMoney">
                        <span class="recharge-list__icon"><img src="@/assets/images/ic-umoney.svg" alt="img" /></span>
                        <h5 class="recharge-list__name">{{ $t('mobile.recharge.u_money') }}</h5>
                     </a>
                  </li>
                  <li class="recharge-list__item">
                     <a class="recharge-list__link" v-on:click="redirectScan">
                        <span class="recharge-list__icon"><img src="@/assets/images/ic-qr-code.svg" alt="img" /></span>
                        <h5 class="recharge-list__name">{{ $t('mobile.recharge.qr_code') }}</h5>
                     </a>
                  </li>
               </ul>
            </div>
            <div class="recharge-list">
               <ul class="recharge-list__detail">
                  <li class="recharge-list__item" style="pointer-events: none; opacity: 0.6">
                     <a class="recharge-list__link" href="javascript:void(0)">
                        <span class="recharge-list__icon"
                           ><img src="@/assets/images/ic-buy-scatch.svg" alt="img"
                        /></span>
                        <h5 class="recharge-list__name">{{ $t('mobile.recharge.buy_scatch_cards') }}</h5>
                     </a>
                  </li>
               </ul>
            </div>
            <div class="recharge-list">
               <ul class="recharge-list__detail">
                  <li class="recharge-list__item" style="pointer-events: none; opacity: 0.6">
                     <a class="recharge-list__link" href="javascript:void(0)">
                        <span class="recharge-list__icon"
                           ><img src="@/assets/images/ic-search-scatch.svg" alt="img"
                        /></span>
                        <h5 class="recharge-list__name">{{ $t('mobile.recharge.search_scatch_cards') }}</h5>
                     </a>
                  </li>
                  <li class="recharge-list__item" style="pointer-events: none; opacity: 0.6">
                     <a class="recharge-list__link" href="javascript:void(0)">
                        <span class="recharge-list__icon"
                           ><img src="@/assets/images/ic-clear-scatch.svg" alt="img"
                        /></span>
                        <h5 class="recharge-list__name">{{ $t('mobile.recharge.clear_scatch_cards') }}</h5>
                     </a>
                  </li>
               </ul>
            </div>
         </div>
      </div>
   </div>
</template>
<script>
import BaseComponent from '@/mixins/BaseComponent'
import store from '@/plugins/vuex/store'
// import service from './../../services/MobileService'
import LocalStorage from '@/ultils/local-storage'
import { callNative } from '@/ultils/call-native'

export default {
   name: 'Payment',
   mixins: [BaseComponent],
   data() {
      return {
         isLaoApp: false
      }
   },
   created() {
      this.setTitle(this.$i18n.t('mobile.payment.title'))
      let appCode = LocalStorage.getItem('appCode')
      if (appCode === 'LaoApp') {
         this.isLaoApp = true
      }
   },
   methods: {
      redirectScatchCards() {
         store.commit('setTextScan', '')
         store.commit('setPhoneScratchCard', '')
         this.$router.push('/mobile/recharge-scratch-card')
      },
      redirectScan() {
         store.commit('setTextScan', '')
         store.commit('setPhoneScratchCard', '')
         LocalStorage.setItem('monitor_redirect_scan', 'Recharge')
         this.$router.push('/mobile/scan')
      },
      redirectUMoney() {
         callNative('open_umoney', '')
         //this.showLoading()
         //validate
         // let userInfoStr = localStorage.getItem('user_info')
         // let lang = localStorage.getItem('lang')
         // let phoneNumber = ''
         // if (userInfoStr) {
         //    let userInfo = JSON.parse(userInfoStr)
         //    phoneNumber = userInfo['isdn']
         // }
         // var body = {
         //    isdnEwallet: phoneNumber
         // }

         // service
         //    .getEwalletInfo(body)
         //    .then((response) => {
         //       var data = response.wsResponse
         //       if (data) {
         //          if (data.responseCode == '00000') {
         //             window.location =
         //                'https://webview.unitel.com.la:9443/#/top-up/start?roleId=' +
         //                data.roleId +
         //                '&lang=' +
         //                lang +
         //                '&sender=' +
         //                data.accountName +
         //                '&phoneNumber=' +
         //                phoneNumber +
         //                '&token='
         //          } else if (data.responseCode === '10114') {
         //             this.$store.dispatch('setNotification', {
         //                content: this.$i18n.t('errors.locked_account')
         //             })
         //          } else if (data.responseCode == '10116' || data.responseCode == '10117') {
         //             var date = new Date()
         //             window.location =
         //                'https://webview.unitel.com.la:9443/#/sign-up/start?roleId=' +
         //                data.roleId +
         //                '&lang=' +
         //                lang +
         //                '&deviceId=' +
         //                date.getTime()
         //          } else {
         //             store.dispatch('setNotification', {
         //                title: this.$i18n.t('errors.system'),
         //                content: this.$i18n.t('mobile.recharge.error_u_money'),
         //                closeButton: this.$i18n.t('buttons.close')
         //             })
         //          }
         //       } else {
         //          store.dispatch('setNotification', {
         //             title: this.$i18n.t('errors.system'),
         //             content: this.$i18n.t('mobile.recharge.error_u_money'),
         //             closeButton: this.$i18n.t('buttons.close')
         //          })
         //       }
         //       this.hideLoading()
         //    })
         //    .catch(() => {
         //       this.hideLoading()
         //    })
      }
   }
}
</script>
