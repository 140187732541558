<template>
   <div class="main-container">
      <div class="nav-bar js-nav-bar" @click="goBack">
         <div class="nav-bar__inner">
            <span class="nav-bar__icon"><i class="icon-fonts icon-fonts--arrow-left"></i></span>
            <h3 class="nav-bar__title">{{ data.name }}</h3>
         </div>
      </div>
      <div class="ftth-banner"><img :src="imgDesUrl" alt="img" /></div>
      <div class="main-content">
         <div class="ftth-cable">
            <div class="ftth-cable__content">
               <ul class="ftth-cable__list">
                  <li class="ftth-cable__item">
                     <h5 class="ftth-cable__name">{{ $t('ftth.ftth_order_succsess.name') }}</h5>
                     <span class="ftth-cable__value">{{ data.fullName }}</span>
                  </li>
                  <li class="ftth-cable__item">
                     <h5 class="ftth-cable__name">{{ $t('ftth.ftth_order_succsess.birthday') }}</h5>
                     <span class="ftth-cable__value">{{ data.doB }}</span>
                  </li>
                  <li class="ftth-cable__item">
                     <h5 class="ftth-cable__name">{{ $t('ftth.ftth_order_succsess.phone_number') }}</h5>
                     <span class="ftth-cable__value">{{ data.phoneNumber }}</span>
                  </li>
                  <li class="ftth-cable__item">
                     <h5 class="ftth-cable__name">{{ $t('ftth.ftth_order_succsess.address') }}</h5>
                     <span class="ftth-cable__value" v-html="data.address"></span>
                  </li>
               </ul>
               <p class="ftth-cable__note">
                  {{ $t('ftth.ftth_order_succsess.thank') }} {{ data.name }}.<br />{{
                     $t('ftth.ftth_order_succsess.thank2')
                  }}
               </p>
            </div>
         </div>
      </div>
   </div>
</template>
<script>
import BaseComponent from '@/mixins/BaseComponent'
import store from '@/plugins/vuex/store'
import service from './../../services/FTTHService'

export default {
   name: 'FtthSuccess',
   mixins: [BaseComponent],
   components: {},
   data() {
      return {
         data: {},
         imgDesUrl: ''
      }
   },
   created() {
      this.showLoading()
      var dataPost = {
         serviceCode: store.state.ftthPackageCode
      }

      service
         .getFtthServiceDetailByServiceCode(dataPost)
         .then((response) => {
            this.imgDesUrl = response.wsResponse.imgDesUrl
            this.hideLoading()
         })
         .catch(() => {
            this.hideLoading()
         })
      this.setTitle(this.$i18n.t('ftth.ftth_package.title'))
      this.data = store.state.infoCusFtthPackage
      this.data.address = this.data.address.replace(', ', ',<br>')
   },
   methods: {}
}
</script>
