import i18n from '@/plugins/i18n'

export const DATE_FORMAT = 'DD/MM/yyyy'

export const MONTH_FORMAT = 'MM/yyyy'

export const FTTHFuctionList = [
    // { name: i18n.t('home.functions.promotion_info'), icon: './images/ic-promotion.svg', url: 'javascript:void(0)' },
    { name: i18n.t('home.functions.pay_monthly_fee'), icon: './images/ic-pay-fee.svg', url: 'javascript:void(0)' },
    // { name: i18n.t('home.functions.change_package'), icon: './images/ic-package.svg', url: '/ftth/ftth-package' },
    { name: i18n.t('home.functions.view_invoice'), icon: './images/ic-invoice.svg', url: '/ftth/view-invoice' },
    { name: i18n.t('home.functions.report_error'), icon: './images/ic-report.svg', url: '/ftth/error-report' },
    { name: i18n.t('home.functions.charge_history'), icon: './images/ic-history.svg', url: '/ftth/charge-history' },
    { name: i18n.t('home.functions.change_password'), icon: './images/ic-password.svg', url: '/ftth/change-pass' },
    { name: i18n.t('home.functions.add_100_call_minutes'), icon: './images/ic-promotion.svg', url: '/ftth/number-list' },
    // { name: i18n.t('ucare.title'), icon: './images/ic-care.svg', url: '/ucare/customer-service/home' },
    { name: i18n.t('home.functions.speedtest'), icon: './images/ic-speed-test.svg', url: 'javascript:void(0)' },
    // { name: i18n.t('home.functions.change_product'), icon: './images/change_ftth.svg', url: '/ftth/change-product-1' },
]

export const FTTHFuctionListLaoApp = [
    // { name: i18n.t('home.functions.promotion_info'), icon: './images/ic-promotion.svg', url: 'javascript:void(0)' },
    // { name: i18n.t('home.functions.pay_monthly_fee'), icon: './images/ic-pay-fee.svg', url: 'javascript:void(0)' },
    // { name: i18n.t('home.functions.change_package'), icon: './images/ic-package.svg', url: '/ftth/ftth-package' },
    { name: i18n.t('home.functions.view_invoice'), icon: './images/ic-invoice.svg', url: '/ftth/view-invoice' },
    { name: i18n.t('home.functions.report_error'), icon: './images/ic-report.svg', url: '/ftth/error-report' },
    { name: i18n.t('home.functions.charge_history'), icon: './images/ic-history.svg', url: '/ftth/charge-history' },
    { name: i18n.t('home.functions.change_password'), icon: './images/ic-password.svg', url: '/ftth/change-pass' },
    { name: i18n.t('home.functions.add_100_call_minutes'), icon: './images/ic-promotion.svg', url: '/ftth/number-list' },
    // { name: i18n.t('ucare.title'), icon: './images/ic-care.svg', url: '/ucare/customer-service/home' },
    // { name: i18n.t('home.functions.change_product'), icon: './images/change_ftth.svg', url: '/ftth/change-product-1' },
    // { name: i18n.t('home.functions.speedtest'), icon: './images/ic-speed-test.svg', url: 'javascript:void(0)' }
    
]

export const InvoiceType = {
    Receipt: 0,
    Invoice: 1,
    InvoiceStatement: 2
}