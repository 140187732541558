import i18n from '@/plugins/i18n'

export const ChargeType = {
   Both: 0,
   PrePaid: 1,
   PostPaid: 2
}

export const DATE_FORMAT = 'DD/MM/yyyy'

export const MONTH_FORMAT = 'MM/yyyy'

export const PackageType = {
   NormalPackage: 1,
   SpecialPackage: 2
}

export const CreditServiceTab = {
   AirtimeCredit: 1,
   AirtimeData: 2
}

export const MobileFuctionList = [
   // {
   //    name: i18n.t('home.functions.special_packages'),
   //    icon: './images/ic-special.svg',
   //    url: '/mobile/special-package',
   //    laoApp:true,
   //    chargeType: ChargeType.Both
   // },
   {
      name: i18n.t('home.functions.data_packages'),
      icon: './images/ic-data.svg',
      url: '/mobile/data-package',
      laoApp:true,
      chargeType: ChargeType.Both
   },
   {
      name: i18n.t('home.functions.do_it_yourself'),
      icon: './images/ic-yourself.svg',
      url: '/mobile/do-it-yourself',
      laoApp:true,
      chargeType: ChargeType.Both
   },
   {
      name: i18n.t('home.functions.airtime_credit'),
      icon: './images/ic-air.svg',
      url: '/mobile/credit-service',
      laoApp:true,
      chargeType: ChargeType.PrePaid
   },
   {
      name: i18n.t('home.functions.i_share'),
      icon: './images/ic-i-share.svg',
      url: '/mobile/ishare',
      laoApp:true,
      chargeType: ChargeType.PrePaid
   },
   {
      name: i18n.t('home.functions.change_sim_3g_4g'),
      icon: './images/ic-sim.svg',
      url: '/mobile/change_sim_3g_4g',
      laoApp:false,
      chargeType: ChargeType.Both
   },
   {
      name: i18n.t('home.functions.change_esim'),
      icon: './images/ic-esim.svg',
      url: '/mobile/change_esim',
      laoApp:false,
      chargeType: ChargeType.Both
   },
   {
      name: i18n.t('home.functions.change_pre_post'),
      icon: './images/ic-pre-post.svg',
      url: '/mobile/change_pre_post',
      laoApp:false,
      chargeType: ChargeType.Both
   },
   // {
   //    name: i18n.t('home.functions.change_post'),
   //    icon: './images/ic-pre-post.svg',
   //    url: '/mobile/change_to_postpaid',
   //    laoApp:true,
   //    chargeType: ChargeType.PrePaid
   // },
   // {
   //    name: i18n.t('home.functions.change_pre'),
   //    icon: './images/ic-pre-post.svg',
   //    url: '/mobile/change-to-prepaid/customer-info',
   //    laoApp:true,
   //    chargeType: ChargeType.PostPaid
   // },
   {
      name: i18n.t('home.functions.unitel_shop'),
      icon: './images/ic-shop.svg',
      url: '/mobile/map/by-current-location',
      laoApp:true,
      chargeType: ChargeType.Both
   },
   {
      name: i18n.t('home.functions.view_invoice'),
      icon: './images/ic-invoice.svg',
      url: '/mobile/view_invoice',
      laoApp:true,
      //chargeType: ChargeType.PostPaid
      chargeType: ChargeType.Both
   },
   {
      name: i18n.t('home.functions.change_simcard'),
      icon: './images/icon_change_sim.svg',
      url: '/mobile/change-simcard',
      laoApp:true,
      //chargeType: ChargeType.PostPaid
      chargeType: ChargeType.Both
   },
   {
      name: i18n.t('home.functions.change_scratch_card'),
      icon: './images/icon_change_card.svg',
      url: '/mobile/change-scratch-card',
      laoApp:true,
      //chargeType: ChargeType.PostPaid
      chargeType: ChargeType.Both
   }
]

export const TypeId = [
    { code: 1, name: i18n.t('home.user_information.id_card') },
    { code: 3, name: i18n.t('home.user_information.passport3') },
    { code: 6, name: i18n.t('home.user_information.family_book6') }
    // { code: 5, name: i18n.t('home.registration_info.others') }
]

export const Genders = [
    { code: 'M', name: i18n.t('home.user_information.male') },
    { code: 'F', name: i18n.t('home.user_information.female') },
]

export const InvoiceType = {
   Receipt: 0,
   Invoice: 1,
   InvoiceStatement: 2
}