<template>
   <div class="main-container">
      <div class="nav-bar js-nav-bar" @click="goBack">
         <div class="nav-bar__inner">
            <span class="nav-bar__icon"><i class="icon-fonts icon-fonts--arrow-left"></i></span>
            <h3 class="nav-bar__title">{{ $t('mobile.charge_history.data') }}</h3>
         </div>
      </div>
      <div class="main-content">
         <div class="total-charges">
            <p class="time-charge">{{ startDate }} - {{ endDate }}</p>
            <div class="total-charges__detail">
               <div class="total-charges__row">
                  <div class="total-charges__top">
                     <h5 class="total-charges__top-name">{{ $t('mobile.charge_history.total_data') }}</h5>
                     <span class="total-charges__top-value">{{ totalData }}</span>
                  </div>
                  <p class="total-charges__capacity">{{ $t('mobile.charge_history.data_capacity') }}</p>
                  <ul class="total-charges__list">
                     <li class="total-charges__item" v-for="data in listData" :key="data.start_time">
                        <div class="total-charges__item-detail">
                           <span class="total-charges__item-icon"><i class="icon-fonts icon-fonts--capacity"></i></span>
                           <div class="total-charges__item-info">
                              <h5 class="total-charges__item-name">{{ data.value }} {{ $t('unit.mb') }}</h5>
                           </div>
                        </div>
                        <div class="total-charges__item-fee">
                           <span class="total-charges__item-kip">{{ formatDate(data.start_time) }}</span
                           ><span class="total-charges__item-time">{{ formatTime(data.start_time) }}</span>
                        </div>
                     </li>
                  </ul>
                  <a class="total-charges__more" href="javascript:void(0)" v-if="isShowMore" @click="showMore">{{
                     $t('mobile.charge_history.show_more')
                  }}</a>
               </div>
               <div class="total-charges__row">
                  <div class="total-charges__top">
                     <h5 class="total-charges__top-name">{{ $t('mobile.charge_history.total_charges') }}</h5>
                  </div>
                  <div class="bill-chart" v-if="chartData.length > 1">
                     <GChart type="LineChart" :data="chartData" :options="chartOptions" />
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>
<script>
import BaseComponent from '@/mixins/BaseComponent'
import { DATE_FORMAT } from './../../constants/data'
import service from './../../services/MobileService'
import LocalStorage from '@/ultils/local-storage'
import moment from 'moment'
import { GChart } from 'vue-google-charts'

export default {
   name: 'Data',
   components: { GChart },
   mixins: [BaseComponent],
   data() {
      return {
         startDate: this.getDateFromMiliseconds(this.$route.params.startTime, DATE_FORMAT),
         endDate: this.getDateFromMiliseconds(this.$route.params.endTime, DATE_FORMAT),
         totalData: 0,
         listData: [],
         chartData: [],
         subType: 1,
         pageSize: 4,
         pageNum: 1,
         totalRecords: 0,
         chartOptions: {
            title: '(' + this.$i18n.t('unit.mb') + ')',
            titleTextStyle: {
               bold: false,
               fontSize: 14,
               color: '#34425B'
            },
            colors: ['#71D875'],
            legend: {
               position: 'none'
            },
            chartArea: {
               bottom: 20,
               top: 30,
               left: 50,
               right: 50
            }
         }
      }
   },
   created() {
      this.setTitle(this.$i18n.t('mobile.charge_history.data'))
      this.subType = parseInt(LocalStorage.getItem('subType'))
      this.totalRecords = this.$route.params.recordNumber
      this.loadData()
   },
   methods: {
      loadData() {
         let loadChartData = this.callChartServiceByType().then((response) => {
            let data = [['Month', 'Data']]
            for (let i = 0; i < response.wsResponse.length; i++) {
               data.push([
                  response.wsResponse[i]['time'].split('-').reverse().join('/'),
                  response.wsResponse[i]['totalCharge']
               ])
            }
            let total = data.slice(1).reduce(function (total, d) {
               return total + d[1]
            }, 0)

            if (total === 0) {
               this.chartOptions['vAxis'] = {
                  viewWindow: {
                     min: 0,
                     max: 1
                  }
               }
            }
            this.chartData = data
         })
         this.showLoading()
         Promise.allSettled([this.loadTotalCharges(), loadChartData]).finally(() => {
            this.hideLoading()
         })
      },
      callChartServiceByType() {
         var subType = parseInt(LocalStorage.getItem('subType'))
         if (subType === 1) {
            return service.getPrePostageForGraph({ postType: '3' })
         } else {
            return service.getPosPostageForGraph({ postType: '3' })
         }
      },
      loadTotalCharges() {
         return service
            .getPostageDetailInfo({
               postType: 3,
               pageSize: this.pageSize,
               subType: this.subType,
               sort: 0,
               pageNum: this.pageNum,
               startDate: this.$route.params.startTime,
               endDate: this.$route.params.endTime
            })
            .then((response) => {
               this.totalData = response.wsResponse.reduce(function (total, data) {
                  return total + data['value']
               }, 0)
               this.listData = this.listData.concat(response.wsResponse ?? [])
            })
      },
      formatDate(miliseconds) {
         return moment(miliseconds).format('DD/MM/yyyy')
      },
      formatTime(miliseconds) {
         return moment(miliseconds).format('HH:mm')
      },
      showMore() {
         if (this.pageNum * this.pageSize < this.totalRecords) {
            this.pageNum += 1
            this.showLoading()
            Promise.allSettled([this.loadTotalCharges()]).finally(() => {
               this.hideLoading()
            })
         }
      }
   },
   computed: {
      isShowMore() {
         return this.totalRecords > this.pageNum * this.pageSize
      }
   }
}
</script>
