<template>
   <div class="main-container">
      <div class="nav-bar js-nav-bar" @click="goBack">
         <div class="nav-bar__inner">
            <span class="nav-bar__icon"><i class="icon-fonts icon-fonts--arrow-left"></i></span>
            <h3 class="nav-bar__title">{{ $t('mobile.ishare.title') }}</h3>
         </div>
      </div>
      <div class="main-content">
         <ValidationObserver v-slot="{ passes, invalid }">
            <form @submit.prevent="passes(onSubmit)">
               <div class="recharge-basic">
                  <div class="recharge-form">
                     <Input
                        type="number"
                        :label="$t('mobile.ishare.receive_number')"
                        :placeholder="$t('mobile.ishare.enter_receive_number')"
                        :hasResetIcon="true"
                        rules="required|max:13|phone"
                        v-model="receiveNumber"
                     />
                     <Input
                        type="fmNumber"
                        :label="$t('mobile.ishare.amount')"
                        :placeholder="$t('mobile.ishare.maximum_amount')"
                        :hasResetIcon="true"
                        rules="required|max:11"
                        v-model="amount"
                     />
                     <div class="recharge-kip">
                        <ul class="recharge-kip__list">
                           <li
                              class="recharge-kip__item"
                              v-for="item in amountList"
                              :key="item"
                              @click="selectAmount(item)"
                           >
                              {{ Number(item).toLocaleString('en-US', { maximumFractionDigits: 0 }) }}
                              {{ $t('unit.kip') }}
                           </li>
                        </ul>
                     </div>
                     <Input
                        type="numPassword"
                        :label="$t('mobile.ishare.ishare_password')"
                        :placeholder="$t('mobile.ishare.enter_ishare_password')"
                        :hint="$t('mobile.ishare.service_charge_100kip_time')"
                        rules="required|equal:6"
                        v-model="isharePass"
                     />
                     <div class="recharge-link">
                        <ul class="recharge-link__list">
                           <li class="recharge-link__item">
                              <a
                                 class="recharge-link__action"
                                 href="javascript:void(0)"
                                 @click="openConfirmationModal('forget_password')"
                                 >{{ $t('mobile.ishare.forget_password') }}</a
                              >
                           </li>
                           <li class="recharge-link__item">
                              <a
                                 class="recharge-link__action"
                                 href="javascript:void(0)"
                                 @click="redirectToChangePass"
                                 >{{ $t('mobile.ishare.change_password') }}</a
                              >
                           </li>
                        </ul>
                     </div>
                  </div>
                  <div class="recharge-btn">
                     <button class="button button--primary button--full" :disabled="invalid">
                        {{ $t('mobile.ishare.share') }}
                     </button>
                  </div>
               </div>
            </form>
         </ValidationObserver>
      </div>
      <confirmation-modal :data="confirmationData" :onConfirm="onConfirm" />
   </div>
</template>
<script>
import { ValidationObserver } from 'vee-validate'
import Input from '@/components/form/Input'
import BaseComponent from '@/mixins/BaseComponent'
import ConfirmationModal from '@/components/modal/ConfirmationModal'
import service from './../../services/MobileService'
import store from '@/plugins/vuex/store'
import { encrypt } from '@/ultils/aes'

export default {
   name: 'IShare',
   mixins: [BaseComponent],
   components: {
      ValidationObserver,
      Input,
      ConfirmationModal
   },
   data() {
      return {
         receiveNumber: '',
         amount: '',
         isharePass: '',
         amountList: [1000, 2000, 5000, 10000, 20000, 50000],
         confirmationData: {},
         action: ''
      }
   },
   created() {
      this.setTitle(this.$i18n.t('mobile.ishare.title'))
   },
   methods: {
      selectAmount(val) {
         this.amount = val
      },
      openConfirmationModal(action) {
         this.action = action
         if (action === 'forget_password') {
            this.confirmationData = {
               cancelButton: this.$i18n.t('buttons.close'),
               content: this.$i18n.t('mobile.ishare.confirm_forget_password')
            }
         } else {
            this.confirmationData = {
               cancelButton: this.$i18n.t('buttons.close'),
               content: this.$i18n
                  .t('mobile.ishare.confirm_share')
                  .replace('{0}', Number(this.amount).toLocaleString('en-US', { maximumFractionDigits: 0 }))
                  .replace('{1}', this.receiveNumber),
               confirmedData: {
                  amount: this.amount.toString(),
                  receiveIsdn: this.receiveNumber,
                  password: encrypt(this.isharePass)
               }
            }
         }
      },
      onConfirm(data) {
         if (this.action === 'forget_password') {
            this.showLoading()
            service
               .resetPasswordIshare()
               .then((response) => {
                  store.dispatch('setNotification', { content: response.message ?? response.userMsg })
               })
               .finally(() => {
                  this.hideLoading()
               })
         } else {
            this.showLoading()
            service
               .share(data)
               .then((response) => {
                  store.dispatch('setNotification', { content: response.message ?? response.userMsg })
               })
               .finally(() => {
                  this.hideLoading()
               })
         }
      },
      onSubmit() {
         this.openConfirmationModal('share')
      },
      redirectToChangePass() {
         this.$router.push('/mobile/ishare/change-pass')
      }
   }
}
</script>
