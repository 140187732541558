<template>
   <div class="main-container">
      <div class="nav-bar js-nav-bar" @click="goBack">
         <div class="nav-bar__inner">
            <span class="nav-bar__icon"><i class="icon-fonts icon-fonts--arrow-left"></i></span>
            <h3 class="nav-bar__title">{{ $t('mobile.view_invoice.view_invoice_information') }}</h3>
         </div>
      </div>
      <div class="main-content">
         <ValidationObserver v-slot="{passes}">
            <form @submit.prevent="passes(search)">
               <div class="view-invoice">
                  <div class="view-invoice__form">
                     <div class="form-2column">
                        <div class="form-customize">
                           <template v-if="type != 2 || typeRadio == 0 || typeRadio == 1">
                              <label class="label-title">{{ $t('mobile.view_invoice.from_date') }} </label>
                           </template>
                           <template v-else>
                              <label class="label-title">{{ $t('mobile.view_invoice.from_month') }} </label>
                           </template>
                           <input
                              id="js-datepicker"
                              class="form-control js-datepicker"
                              type="text"
                              v-model="timeStart"
                              readonly
                              v-show="type != 2"
                           />
                           <input
                              id="js-month-year"
                              class="form-control js-month-year"
                              type="text"
                              v-model="startMonth"
                              readonly
                              v-show="type == 2"
                           />
                        </div>
                        <div class="form-customize">
                           <template v-if="type != 2 || typeRadio == 0 || typeRadio == 1"> 
                              <label class="label-title">{{ $t('mobile.view_invoice.to_date') }} </label>
                           </template>
                           <template v-else>
                              <label class="label-title">{{ $t('mobile.view_invoice.to_month') }} </label>
                           </template>
                           <template v-if="type != 2">
                              <input
                                 class="form-control js-datepicker"
                                 type="text"
                                 v-model="timeEnd"
                              />
                           </template>
                           <template v-else>
                              <input
                                 class="form-control js-datepicker"
                                 type="text"
                                 v-model="endMonth"
                                 :disabled="type == 2"
                                 readonly
                              />
                           </template>
                        </div>
                     </div>
                     <div class="form-full" v-if="subType == 2 || typeRadio == 2">
                        <div class="form-customize">
                           <label class="label-title">{{ $t('ftth.view_invoice.type') }}</label>
                           <div class="select-box js-select-box">
                              <select class="js-custom-select" :data-placeholder="$t('ftth.view_invoice.receipt')" v-model="type">
                                 <option value="0">{{ $t('ftth.view_invoice.receipt') }}</option>
                                 <option value="1">{{ $t('ftth.view_invoice.invoice') }}</option>
                                 <option value="2">{{ $t('ftth.view_invoice.invoice_statement') }}</option>
                              </select>
                           </div>
                        </div>
                     </div>
                     <div class="form-choose">
                        <div class="radio-custom" v-if="subType == 2">
                           <input class="radio-custom__input" type="radio" name="radio" id="radio-0" v-model="typeRadio" value="0" />
                           <label class="radio-custom__label" for="radio-0">{{ $t('mobile.view_invoice.postpaid_mobile') }}</label>
                        </div>
                     <div class="radio-custom" v-if="subType == 1">
                        <input class="radio-custom__input" type="radio" name="radio" id="radio-1" v-model="typeRadio" value="1" />
                        <label class="radio-custom__label" for="radio-1">{{ $t('mobile.view_invoice.prepaid_mobile') }}</label>
                     </div>
                     <div class="radio-custom">
                        <input class="radio-custom__input" type="radio" name="radio" v-model="typeRadio" id="radio-2" value="2" />
                        <label class="radio-custom__label" for="radio-2">{{ $t('mobile.view_invoice.FTTH') }}</label>
                     </div>
                  </div>
                  <div class="form-full" v-if="typeRadio == 2">
                     <div class="form-customize">
                        <Input
                           type="number"
                           class="form-custom"
                           :label="$t('ftth.ftth_account')"
                           rules="required"
                           v-model="account"
                           :styleLable="styleLable"
                        />
                     </div>
                  </div>
                  <div class="form-full" v-if="typeRadio == 2">
                     <div class="form-customize">
                        <Input
                           :type="typePass"
                           class="form-custom"
                           :rightIcon="rightIcon"
                           :onClickRightIcon="togglePasswordType"
                           :label="$t('ftth.password')"
                           rules="required"
                           v-model="password"
                           :styleLable="styleLable"
                        />
                     </div>
                  </div>
                  <div v-if="typeRadio == 2" class="form-link"><a class="form-link__name" href="javascript:void(0)" @click="changePass">{{$t('ftth.change_password.title')}}</a><a class="form-link__name" href="javascript:void(0)" @click="forgotPass">{{$t('ftth.forget_password')}}</a></div>
                  </div>
                  <div class="charge-box">
                     <ul class="charge-box__list">
                        <li
                           class="charge-box__item"
                           v-for="(item, index) in data"
                           :key="index"
                           @click="redirectToDetail(item)"
                        >
                           <span class="charge-box__icon"><i class="icon-fonts icon-fonts--bill"></i></span>
                           <div class="charge-box__info">
                              <h5 class="charge-box__name">{{ item.name }}: {{ item.no }}</h5>
                              <span class="charge-box__time">{{ item.date }}</span>
                           </div>
                        </li>
                     </ul>
                  </div>
                  <div class="view-invoice__btn">
                     <button class="button button--primary button--full">{{ $t('buttons.search') }}</button>
                  </div>
               </div>
            </form>
         </ValidationObserver>
      </div>
   </div>
</template>
<script>
import BaseComponent from '@/mixins/BaseComponent'
import service from './../../services/MobileService'
import { InvoiceType, DATE_FORMAT, MONTH_FORMAT } from './../../constants/data'
import moment from 'moment'
import LocalStorage from '@/ultils/local-storage'
import { ValidationObserver } from 'vee-validate'
import Input from '@/components/form/Input'

export default {
   name: 'ViewInvoice1',
   components: {
      ValidationObserver,
      Input
   },
   mixins: [BaseComponent],
   data() {
      return {
         styleLable:{
            'fontSize': '14px',
            'fontWeight':'100'
         },
         timeStart: '',
         timeEnd: '',
         startMonth: '',
         endMonth: '',
         type: InvoiceType.Receipt,
         data: [],
         // == 1 trả trước, ==2 trả sau
         subType:1,
         typeRadio:1,
         isdnLogin:"",
         rightIcon: 'icon-fonts icon-fonts--eye-off',
         account: '',
         password: '',
         typePass: 'password'
      }
   },
   mounted() {
      this.setTitle(this.$i18n.t('home.functions.view_invoice'))
      this.loadScript('js/date-month-picker.js')
      this.loadScript('js/date-picker.js')
      this.loadScript('js/select2.js')
      this.loadScript('js/scroll.js')

      // == 1 trả trước, ==2 trả sau
      let subType1 = LocalStorage.getItem('subType')
      this.subType = subType1
      if(this.subType == 2){
         this.typeRadio = 0
      }
      else if(this.subType == 1){
         this.typeRadio = 1
      }

      if (LocalStorage.getItem('invoice_detail_home')) {
         let params = JSON.parse(LocalStorage.getItem('invoice_detail_home')).searchParams
         this.timeStart = params.timeStart
         this.timeEnd = params.timeEnd
         this.startMonth = params.startMonth
         this.endMonth = params.endMonth
         this.type = params.type
         this.account = params.account
         this.password = params.password
         this.typeRadio = params.typeRadio
         
         if(params.isChangePass){
            if(params.password != ''){
               this.search()
            }
         }else{
            this.search()
         }
      } else {
         this.timeStart = new moment().subtract(2, 'months').format(DATE_FORMAT)
         this.timeEnd = moment().format(DATE_FORMAT)

         this.startMonth = new moment().subtract(2, 'months').format(MONTH_FORMAT)
         this.endMonth = moment().format(MONTH_FORMAT)
      }
      this.isdnLogin = LocalStorage.getItem('user_info').isdn
   },
   methods: {
      forgotPass() {
         if (this.account) {
            this.showLoading()
            service
               .forgotPass({ otp: '', username: this.account })
               .then((response) => {
                  if (response.errorCode === 'S200') {
                     this.$store.dispatch('setNotification', { content: this.$i18n.t('ftth.reset_pwd_ftth_done') })
                  } else {
                     this.$store.dispatch('setNotification', { content: response.errorMessage })
                  }
               })
               .finally(() => {
                  this.hideLoading()
               })
         } else {
            this.$store.dispatch('setNotification', { content: this.$i18n.t('ftth.please_input_account') })
         }
      },
      changePass(){
         if (this.account) {
            let data = {
               searchParams: {
                  timeStart: this.timeStart,
                  timeEnd: this.timeEnd,
                  startMonth:this.startMonth,
                  endMonth:this.endMonth,
                  type: this.type,
                  account: this.account,
                  password: this.password,
                  typeRadio : parseInt(this.typeRadio),
                  isChangePass:true
               }
            }
            LocalStorage.setItem('invoice_detail_home', JSON.stringify(data))
            LocalStorage.setItem('ftth_account', this.account)
            this.$router.push('/ftth/change-pass')
         } else {
            this.$store.dispatch('setNotification', { content: this.$i18n.t('ftth.please_input_account') })
         }
         
      },
      togglePasswordType() {
         if (this.typePass === 'password') {
            this.typePass = 'text'
            this.rightIcon = 'icon-fonts--eye'
         } else {
            this.typePass = 'password'
            this.rightIcon = 'icon-fonts--eye-off'
         }
      },
      // search() {
      //    this.showLoading()
      //    console.log('this.timeStart',this.timeStart)
      //    console.log('this.timeEnd',this.timeEnd)
      //    if(this.type == InvoiceType.InvoiceStatement){
      //       this.timeStart = this.startMonth
      //       this.timeEnd = this.endMonth
      //    }
      //    console.log('this.timeStart sau',this.timeStart)
      //    console.log('this.timeEnd sau',this.timeEnd)
      //    if(this.typeRadio == 2){
      //       // xử lý FTTH
      //       if (this.type == InvoiceType.Receipt) {
      //          let data = {
      //             fromDate: this.getMiliseconds(this.timeStart, DATE_FORMAT),
      //             serviceType: 1,
      //             ftthAccount: this.account,
      //             isdn:this.isdnLogin,
      //             toDate: this.getMiliseconds(this.timeEnd, DATE_FORMAT),
      //             accountType: '0',
      //             type: 0,
      //             ftthPassword:this.password
      //          }
      //          service
      //             .getListReceipt(data)
      //             .then((response) => {
      //                if (response.errorCode !== '1') {
      //                   let result = response.wsResponse ?? []
      //                   this.data = result.map((d) => {
      //                      return {
      //                         type: InvoiceType.Receipt,
      //                         name: this.$i18n.t('ftth.view_invoice.receipt_no'),
      //                         id: d.paymentId,
      //                         no: d.invoiceNo,
      //                         date: d.bankDate
      //                      }
      //                   })
      //                } else {
      //                   this.$store.dispatch('setNotification', { content: response.message })
      //                }
      //             })
      //             .finally(() => {
      //                LocalStorage.removeItem('invoice_detail_home')
      //                this.hideLoading()
      //             })
      //       } else if (this.type == InvoiceType.Invoice) {
      //          let data = {
      //             fromDate: this.getMiliseconds(this.timeStart, DATE_FORMAT),
      //             serviceType: 1,
      //             ftthAccount: this.account,
      //             isdn:this.isdnLogin,
      //             toDate: this.getMiliseconds(this.timeEnd, DATE_FORMAT),
      //             accountType: '0',
      //             type: 1,
      //             ftthPassword:this.password
      //          }
      //          service
      //             .getListInvoice(data)
      //             .then((response) => {
      //                if (response.errorCode !== '1') {
      //                   let result = response.wsResponse ?? []
      //                   this.data = result.map((d) => {
      //                      return {
      //                         type: InvoiceType.Invoice,
      //                         name: this.$i18n.t('ftth.view_invoice.invoice_no'),
      //                         id: d.paymentId,
      //                         no: d.invoiceNo,
      //                         date: d.bankDate
      //                      }
      //                   })
      //                } else {
      //                   this.$store.dispatch('setNotification', { content: response.message })
      //                }
      //             })
      //             .finally(() => {
      //                LocalStorage.removeItem('invoice_detail_home')
      //                this.hideLoading()
      //             })
      //       } else {
      //          let timeStartSplit = this.timeStart.split('/')
      //          timeStartSplit.unshift('01')
      //          let data = {
      //             serviceType: 1,
      //             ftthAccount: this.account,
      //             ftthPassword: this.password,
      //             isdn:this.isdnLogin,
      //             accountType: '0',
      //             billCycleDate: this.getMiliseconds(timeStartSplit.join('/'), DATE_FORMAT)
      //          }
      //          service
      //             .getListInvoiceStatement(data)
      //             .then((response) => {
      //                if (response.errorCode !== '1') {
      //                   let result = response.wsResponse ?? []
      //                   this.data = result.map((d) => {
      //                      return {
      //                         type: InvoiceType.InvoiceStatement,
      //                         name: this.$i18n.t('ftth.view_invoice.invoice_statement'),
      //                         id: d.chargeReportId,
      //                         subId: d.contractId,
      //                         no: d.name,
      //                         date: d.billCycle
      //                      }
      //                   })
      //                } else {
      //                   this.$store.dispatch('setNotification', { content: response.message })
      //                }
      //             })
      //             .finally(() => {
      //                LocalStorage.removeItem('invoice_detail_home')
      //                this.hideLoading()
      //             })
      //       }
      //    }
      //    else if(this.typeRadio == 1 && this.subType == 1){
      //       // xử lý trả trước
      //       let data = {
      //          serviceType: 1,
      //          ftthAccount: "",
      //          accountType: '0',
      //          fromDate: this.getMiliseconds(this.timeStart, DATE_FORMAT),
      //          toDate: this.getMiliseconds(this.timeEnd, DATE_FORMAT),
      //          type:0,
      //          isdn:this.isdnLogin
      //       }
      //       service
      //             .getListInvoicePrepaid(data)
      //             .then((response) => {
      //                if (response.errorCode !== '1') {
      //                   let result = response.wsResponse ?? []
      //                   this.data = result.map((d) => {
      //                      return {
      //                         type: "viewInvoicePrepaidInfo",
      //                         name: this.$i18n.t('mobile.view_invoice.prepaid_mobile'),
      //                         id: d.serialNo,
      //                         no: d.invoiceNo,
      //                         date: d.createDate,
      //                         invoiceUsedId: d.invoiceUsedId,
      //                         saleTransId: d.saleTransId,
      //                      }
      //                   })
      //                } else {
      //                   this.$store.dispatch('setNotification', { content: response.message })
      //                }
      //             })
      //             .finally(() => {
      //                LocalStorage.removeItem('invoice_detail_home')
      //                this.hideLoading()
      //             })
      //    }
      //    else if(this.typeRadio == 0 && this.subType == 2){
      //       if (this.type == InvoiceType.Receipt) {
      //          let data = {
      //             fromDate: this.getMiliseconds(this.timeStart, DATE_FORMAT),
      //             ftthAccount:'',
      //             isdn:this.isdnLogin,
      //             serviceType: 1,
      //             toDate: this.getMiliseconds(this.timeEnd, DATE_FORMAT),
      //             accountType: '0',
      //             type: 0
      //          }
      //          service
      //             .getListReceipt(data)
      //             .then((response) => {
      //                if (response.errorCode !== '1') {
      //                   let result = response.wsResponse ?? []
      //                   this.data = result.map((d) => {
      //                      return {
      //                         type: InvoiceType.Receipt,
      //                         name: this.$i18n.t('ftth.view_invoice.receipt_no'),
      //                         id: d.paymentId,
      //                         no: d.invoiceNo,
      //                         date: d.bankDate
      //                      }
      //                   })
      //                } else {
      //                   this.$store.dispatch('setNotification', { content: response.message })
      //                }
      //             })
      //             .finally(() => {
      //                LocalStorage.removeItem('invoice_detail_home')
      //                this.hideLoading()
      //             })
      //       } else if (this.type == InvoiceType.Invoice) {
      //          let data = {
      //             fromDate: this.getMiliseconds(this.timeStart, DATE_FORMAT),
      //             ftthAccount: '',
      //             isdn:this.isdnLogin,
      //             serviceType: 1,
      //             toDate: this.getMiliseconds(this.timeEnd, DATE_FORMAT),
      //             accountType: '0',
      //             type: 1
      //          }
      //          service
      //             .getListInvoice(data)
      //             .then((response) => {
      //                if (response.errorCode !== '1') {
      //                   let result = response.wsResponse ?? []
      //                   this.data = result.map((d) => {
      //                      return {
      //                         type: InvoiceType.Invoice,
      //                         name: this.$i18n.t('ftth.view_invoice.invoice_no'),
      //                         id: d.paymentId,
      //                         no: d.invoiceNo,
      //                         date: d.bankDate
      //                      }
      //                   })
      //                } else {
      //                   this.$store.dispatch('setNotification', { content: response.message })
      //                }
      //             })
      //             .finally(() => {
      //                LocalStorage.removeItem('invoice_detail_home')
      //                this.hideLoading()
      //             })
      //       } else {
      //          let timeStartSplit = this.timeStart.split('/')
      //          timeStartSplit.unshift('01')
      //          let data = {
      //             serviceType: 3,
      //             ftthAccount: '',
      //             isdn:this.isdnLogin,
      //             accountType: '0',
      //             billCycleDate: this.getMiliseconds(timeStartSplit.join('/'), DATE_FORMAT)
      //          }
      //          service
      //             .getListInvoiceStatement(data)
      //             .then((response) => {
      //                if (response.errorCode !== '1') {
      //                   let result = response.wsResponse ?? []
      //                   this.data = result.map((d) => {
      //                      return {
      //                         type: InvoiceType.InvoiceStatement,
      //                         name: this.$i18n.t('ftth.view_invoice.invoice_statement'),
      //                         id: d.chargeReportId,
      //                         subId: d.contractId,
      //                         no: d.name,
      //                         date: d.billCycle
      //                      }
      //                   })
      //                } else {
      //                   this.$store.dispatch('setNotification', { content: response.message })
      //                }
      //             })
      //             .finally(() => {
      //                LocalStorage.removeItem('invoice_detail_home')
      //                this.hideLoading()
      //             })
      //       }
      //    }
      // },
      search() {
         this.showLoading()
         let _timeStart = this.timeStart
         let _timeEnd = this.timeEnd

         if(this.type == InvoiceType.InvoiceStatement){
            _timeStart = this.startMonth
           _timeEnd = this.endMonth
         }
         if(this.typeRadio == 2){
            // xử lý FTTH
            if (this.type == InvoiceType.Receipt) {
               let data = {
                  fromDate: this.getMiliseconds(_timeStart, DATE_FORMAT),
                  serviceType: 1,
                  ftthAccount: this.account,
                  isdn:this.isdnLogin,
                  toDate: this.getMiliseconds(_timeEnd, DATE_FORMAT),
                  accountType: '0',
                  type: 0,
                  ftthPassword:this.password
               }
               service
                  .getListReceipt(data)
                  .then((response) => {
                     if (response.errorCode !== '1') {
                        let result = response.wsResponse ?? []
                        this.data = result.map((d) => {
                           return {
                              type: InvoiceType.Receipt,
                              name: this.$i18n.t('ftth.view_invoice.receipt_no'),
                              id: d.paymentId,
                              no: d.invoiceNo,
                              date: d.bankDate
                           }
                        })
                     } else {
                        this.$store.dispatch('setNotification', { content: response.message })
                     }
                  })
                  .finally(() => {
                     LocalStorage.removeItem('invoice_detail_home')
                     this.hideLoading()
                  })
            } else if (this.type == InvoiceType.Invoice) {
               let data = {
                  fromDate: this.getMiliseconds(_timeStart, DATE_FORMAT),
                  serviceType: 1,
                  ftthAccount: this.account,
                  isdn:this.isdnLogin,
                  toDate: this.getMiliseconds(_timeEnd, DATE_FORMAT),
                  accountType: '0',
                  type: 1,
                  ftthPassword:this.password
               }
               service
                  .getListInvoice(data)
                  .then((response) => {
                     if (response.errorCode !== '1') {
                        let result = response.wsResponse ?? []
                        this.data = result.map((d) => {
                           return {
                              type: InvoiceType.Invoice,
                              name: this.$i18n.t('ftth.view_invoice.invoice_no'),
                              id: d.paymentId,
                              no: d.invoiceNo,
                              date: d.bankDate
                           }
                        })
                     } else {
                        this.$store.dispatch('setNotification', { content: response.message })
                     }
                  })
                  .finally(() => {
                     LocalStorage.removeItem('invoice_detail_home')
                     this.hideLoading()
                  })
            } else {
               let timeStartSplit = _timeStart.split('/')
               timeStartSplit.unshift('01')
               let data = {
                  serviceType: 1,
                  ftthAccount: this.account,
                  ftthPassword: this.password,
                  isdn:this.isdnLogin,
                  accountType: '0',
                  billCycleDate: this.getMiliseconds(timeStartSplit.join('/'), DATE_FORMAT)
               }
               service
                  .getListInvoiceStatement(data)
                  .then((response) => {
                     if (response.errorCode !== '1') {
                        let result = response.wsResponse ?? []
                        this.data = result.map((d) => {
                           return {
                              type: InvoiceType.InvoiceStatement,
                              name: this.$i18n.t('ftth.view_invoice.invoice_statement'),
                              id: d.chargeReportId,
                              subId: d.contractId,
                              no: d.name,
                              date: d.billCycle
                           }
                        })
                     } else {
                        this.$store.dispatch('setNotification', { content: response.message })
                     }
                  })
                  .finally(() => {
                     LocalStorage.removeItem('invoice_detail_home')
                     this.hideLoading()
                  })
            }
         }
         else if(this.typeRadio == 1 && this.subType == 1){
            // xử lý trả trước
            let data = {
               serviceType: 1,
               ftthAccount: "",
               accountType: '0',
               fromDate: this.getMiliseconds(_timeStart, DATE_FORMAT),
               toDate: this.getMiliseconds(_timeEnd, DATE_FORMAT),
               type:0,
               isdn:this.isdnLogin
            }
            service
                  .getListInvoicePrepaid(data)
                  .then((response) => {
                     if (response.errorCode !== '1') {
                        let result = response.wsResponse ?? []
                        this.data = result.map((d) => {
                           return {
                              type: "viewInvoicePrepaidInfo",
                              name: this.$i18n.t('mobile.view_invoice.prepaid_mobile'),
                              id: d.serialNo,
                              no: d.invoiceNo,
                              date: d.createDate,
                              invoiceUsedId: d.invoiceUsedId,
                              saleTransId: d.saleTransId,
                           }
                        })
                     } else {
                        this.$store.dispatch('setNotification', { content: response.message })
                     }
                  })
                  .finally(() => {
                     LocalStorage.removeItem('invoice_detail_home')
                     this.hideLoading()
                  })
         }
         else if(this.typeRadio == 0 && this.subType == 2){
            if (this.type == InvoiceType.Receipt) {
               let data = {
                  fromDate: this.getMiliseconds(_timeStart, DATE_FORMAT),
                  ftthAccount:'',
                  isdn:this.isdnLogin,
                  serviceType: 1,
                  toDate: this.getMiliseconds(_timeEnd, DATE_FORMAT),
                  accountType: '0',
                  type: 0
               }
               service
                  .getListReceipt(data)
                  .then((response) => {
                     if (response.errorCode !== '1') {
                        let result = response.wsResponse ?? []
                        this.data = result.map((d) => {
                           return {
                              type: InvoiceType.Receipt,
                              name: this.$i18n.t('ftth.view_invoice.receipt_no'),
                              id: d.paymentId,
                              no: d.invoiceNo,
                              date: d.bankDate
                           }
                        })
                     } else {
                        this.$store.dispatch('setNotification', { content: response.message })
                     }
                  })
                  .finally(() => {
                     LocalStorage.removeItem('invoice_detail_home')
                     this.hideLoading()
                  })
            } else if (this.type == InvoiceType.Invoice) {
               let data = {
                  fromDate: this.getMiliseconds(_timeStart, DATE_FORMAT),
                  ftthAccount: '',
                  isdn:this.isdnLogin,
                  serviceType: 1,
                  toDate: this.getMiliseconds(_timeEnd, DATE_FORMAT),
                  accountType: '0',
                  type: 1
               }
               service
                  .getListInvoice(data)
                  .then((response) => {
                     if (response.errorCode !== '1') {
                        let result = response.wsResponse ?? []
                        this.data = result.map((d) => {
                           return {
                              type: InvoiceType.Invoice,
                              name: this.$i18n.t('ftth.view_invoice.invoice_no'),
                              id: d.paymentId,
                              no: d.invoiceNo,
                              date: d.bankDate
                           }
                        })
                     } else {
                        this.$store.dispatch('setNotification', { content: response.message })
                     }
                  })
                  .finally(() => {
                     LocalStorage.removeItem('invoice_detail_home')
                     this.hideLoading()
                  })
            } else {
               let timeStartSplit = _timeStart.split('/')
               timeStartSplit.unshift('01')
               let data = {
                  serviceType: 3,
                  ftthAccount: '',
                  isdn:this.isdnLogin,
                  accountType: '0',
                  billCycleDate: this.getMiliseconds(timeStartSplit.join('/'), DATE_FORMAT)
               }
               service
                  .getListInvoiceStatement(data)
                  .then((response) => {
                     if (response.errorCode !== '1') {
                        let result = response.wsResponse ?? []
                        this.data = result.map((d) => {
                           return {
                              type: InvoiceType.InvoiceStatement,
                              name: this.$i18n.t('ftth.view_invoice.invoice_statement'),
                              id: d.chargeReportId,
                              subId: d.contractId,
                              no: d.name,
                              date: d.billCycle
                           }
                        })
                     } else {
                        this.$store.dispatch('setNotification', { content: response.message })
                     }
                  })
                  .finally(() => {
                     LocalStorage.removeItem('invoice_detail_home')
                     this.hideLoading()
                  })
            }
         }
      },
      redirectToDetail(item) {
         let data = {
            searchParams: {
               timeStart: this.timeStart,
               timeEnd: this.timeEnd,
               startMonth:this.startMonth,
               endMonth:this.endMonth,
               type: this.type,
               account: this.account,
               password: this.password,
               typeRadio : parseInt(this.typeRadio)
            },
            detail: item
         }
         LocalStorage.setItem('invoice_detail_home', JSON.stringify(data))
         this.$router.push('/mobile/detail-view-invoice')
      }
   },
   watch: {
      type(newVal) {
         this.data = []
         if (LocalStorage.getItem('invoice_detail_home') === null) {
            if (newVal == InvoiceType.InvoiceStatement) {
               //this.timeStart = new moment().subtract(2, 'months').format(MONTH_FORMAT)
               //this.timeEnd = ''
               this.endMonth = ''
            } 
            // else {
            //    this.timeStart = new moment().subtract(2, 'months').format(DATE_FORMAT)
            //    this.timeEnd = moment().format(DATE_FORMAT)
            // }
         }
      },
      startMonth(newVal, oldVal) {
         if (this.type != InvoiceType.InvoiceStatement) {
            let startNum = this.getMiliseconds(newVal, DATE_FORMAT)
            let endNum = this.getMiliseconds(this.timeEnd, DATE_FORMAT)
            if (startNum > endNum) {
               this.startMonth = oldVal
               this.$store.dispatch('setNotification', {
                  content: this.$i18n.t('errors.from_date_greater_than_to_date')
               })
            }
         } else {
            let startNum = this.getMiliseconds(newVal, MONTH_FORMAT)
            let currentNum = new Date().getTime()
            if (startNum > currentNum) {
               this.startMonth = oldVal
               this.$store.dispatch('setNotification', {
                  content: this.$i18n.t('errors.from_month_greater_than_current_month')
               })
            }
         }
      },
      timeStart(newVal, oldVal) {
         if (this.type != InvoiceType.InvoiceStatement) {
            let startNum = this.getMiliseconds(newVal, DATE_FORMAT)
            let endNum = this.getMiliseconds(this.timeEnd, DATE_FORMAT)
            if (startNum > endNum) {
               this.timeStart = oldVal
               this.$store.dispatch('setNotification', {
                  content: this.$i18n.t('errors.from_date_greater_than_to_date')
               })
            }
         } else {
            let startNum = this.getMiliseconds(newVal, MONTH_FORMAT)
            let currentNum = new Date().getTime()
            if (startNum > currentNum) {
               this.timeStart = oldVal
               this.$store.dispatch('setNotification', {
                  content: this.$i18n.t('errors.from_month_greater_than_current_month')
               })
            }
         }
      },
      timeEnd(newVal, oldVal) {
         let current = moment()
         let startNum = this.getMiliseconds(this.timeStart, DATE_FORMAT)
         let endNum = this.getMiliseconds(newVal, DATE_FORMAT)
         if (startNum > endNum) {
            this.timeEnd = oldVal
            this.$store.dispatch('setNotification', {
               content: this.$i18n.t('errors.from_date_greater_than_to_date')
            })
         } else if (endNum > current) {
            this.timeEnd = oldVal
            this.$store.dispatch('setNotification', {
               content: this.$i18n.t('errors.to_date_greater_than_current_day')
            })
         }
      },
      typeRadio(){
         this.loadScript('js/date-month-picker.js')
         this.loadScript('js/date-picker.js')
         this.loadScript('js/select2.js')
         this.loadScript('js/scroll.js')
         this.password = ''
         this.typePass = 'password'
         this.data =[]
         // == 1 trả trước, ==2 trả sau
         if(this.subType == 1)
         {
            this.type = InvoiceType.Receipt
         }
         if (LocalStorage.getItem('invoice_detail_home')) {
            let params = JSON.parse(LocalStorage.getItem('invoice_detail_home')).searchParams
            this.password = params.password
            this.type = params.type
         }
         
      }
   }
}
</script>
