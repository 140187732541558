<template>
   <div class="main-container">
      <div class="nav-bar js-nav-bar" @click="goBack">
         <div class="nav-bar__inner">
            <span class="nav-bar__icon"><i class="icon-fonts icon-fonts--arrow-left"></i></span>
            <h3 class="nav-bar__title">{{ name }}</h3>
         </div>
      </div>
      <div class="ftth-banner"><img :src="data.imgDesUrl" alt="img" /></div>
      <div class="main-content">
         <div class="ftth-cable">
            <div class="ftth-cable__content">
               <p class="ftth-cable__des" v-html="data.fullDes"></p>
               <ul class="ftth-cable__list">
                  <li class="ftth-cable__item has-sub">
                     <ul class="ftth-cable__sub">
                        <li class="ftth-cable__sub-item">
                           <span class="ftth-cable__sub-name">{{ $t('ftth.ftth_package_detail.website_title') }}</span>
                        </li>
                     </ul>
                  </li>
                  <li class="ftth-cable__item">
                     <h5 class="ftth-cable__name">{{ $t('ftth.ftth_package_detail.website') }}</h5>
                     <span class="ftth-cable__value">{{ data.webLink }}</span>
                  </li>
               </ul>
            </div>
            <div class="ftth-cable__btn">
               <button class="button button--primary button--full" @click="onOrder">
                  {{ $t('ftth.ftth_package.order') }}
               </button>
            </div>
         </div>
      </div>
   </div>
</template>
<script>
import BaseComponent from '@/mixins/BaseComponent'
import service from './../../services/FTTHService'
import store from '@/plugins/vuex/store'
import { callNative } from '@/ultils/call-native'

export default {
   name: 'FtthPackageDetail',
   mixins: [BaseComponent],
   components: {},
   data() {
      return {
         name: '',
         data: {}
      }
   },
   created() {
      this.setTitle(this.$i18n.t('ftth.ftth_package.title'))
      this.name = store.state.ftthPackage
      this.getData()
   },
   methods: {
      getData() {
         this.showLoading()

         service
            .getFtthServiceDetailByServiceCode({ serviceCode: store.state.ftthPackageCode })
            .then((response) => {
               this.data = response.wsResponse
               this.hideLoading()
            })
            .catch(() => {
               this.hideLoading()
            })
      },
      onOrder() {
         callNative('open_shop','')
         // this.$router.push('/ftth/ftth-order')
      }
   }
}
</script>
