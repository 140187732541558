<template>
    <div class="main-container">
        <div class="nav-bar nav-bar--full">
            <div class="nav-bar__inner">
                <span class="nav-bar__icon"><i class="icon-fonts icon-fonts--arrow-left" @click="goBack"></i></span>
                <h3 class="nav-bar__title">{{ $t('ftth.number_list.title') }}</h3>
                <!-- <a v-if="isEdit" @click="onEdit()" class="nav-bar__link" href="javascript:;">Edit</a> -->
                <!-- <span v-if="!isEdit" @click="onAdd()" class="nav-bar__action"><i class="icon-fonts icon-fonts--add"></i></span> -->
            </div>
        </div>
        <div class="main-content">
            <!-- <div class="number-list" v-if="!nodata">
                <div class="number-list__row" v-for="item in numberList" :key="item.isdn">
                    <h5 class="number-list__sub">{{item.isdn}}</h5>
                    <div class="number-list__detail">
                        <ul class="number-list__list">
                            <li class="number-list__item"><span class="number-list__name">{{ $t('ftth.number_list.added_date') }}</span><span class="number-list__value">{{item.dateAddIsdn}}</span></li>
                            <li class="number-list__item"><span class="number-list__name">{{ $t('ftth.number_list.promotion_start') }}</span><span class="number-list__value">{{item.firstDatePromotion}}</span></li>
                            <li class="number-list__item"><span class="number-list__name">{{ $t('ftth.number_list.promotion_end') }}</span><span class="number-list__value">{{item.lastDatePromotion}}</span></li>
                        </ul>
                        <div v-if="!isEdit" class="number-list__action" @click="removeNewNumber(item.isdn)"><span class="number-list__icon"><i class="icon-fonts icon-fonts--minus"></i></span></div>
                    </div>
                </div>

            </div> -->
            <div class="number-list__content">
              <div class="number-list__box" v-for="item in numberList" :key="item.isdn">
                <div class="number-list__top">
                  <h5 class="number-list__sub">{{ $t('ftth.number_list.promotion_number_info') }}</h5>
                  <div class="number-list__action">
                    <button class="button button--orange button--small" @click="removeNewNumber(item.isdn)">{{ $t('buttons.delete')}}
                    </button>
                  </div>
                </div>
                <p class="number-list__id">{{item.isdn}}</p>
                <ul class="number-list__list">
                  <li class="number-list__item"><span class="number-list__name">{{ $t('ftth.number_list.added_date') }}</span>
                    <div class="number-list__detail"><span class="number-list__value">{{item.dateAddIsdn}}</span></div>
                  </li>
                  <li class="number-list__item"><span class="number-list__name">{{ $t('ftth.number_list.promotion_start') }}</span>
                    <div class="number-list__detail"><span class="number-list__value">{{item.firstDatePromotion}}</span></div>
                  </li>
                  <li class="number-list__item"><span class="number-list__name">{{ $t('ftth.number_list.promotion_end') }}</span>
                    <div class="number-list__detail"><span class="number-list__value">{{item.lastDatePromotion}}</span></div>
                  </li>
                </ul>
              </div>
            </div>
            <div class="number-list__btn">
              <button class="button button--primary button--full" @click="onAdd()">{{ $t('ftth.number_list.add_new_number') }}
              </button>
            </div>
            <p v-if="nodata" style="display: flex; justify-content: center; height: 50rem; align-items: center; ">{{$t('ftth.number_list.nodata')}}</p>
        </div>

        <button style="display: none" data-bs-toggle="modal" data-bs-target="#register-confirm" id="methodBtn" />
        <div class="modal fade modal-custom " id="register-confirm" tabindex="-1" role="dialog" aria-labelledby="register-confirm" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-custom__content">
                    <div class="modal-custom__header"><span class="modal-custom__action" data-bs-dismiss="modal"><i class="icon-fonts icon-fonts--close"></i></span></div>
                    <div class="modal-custom__body">
                        <h3 class="modal-custom__title">{{ $t('ftth.add_new_number.title_delete_modal') }}</h3>
                        <p class="modal-custom__des">{{ $t('ftth.add_new_number.body_modal') }} {{this.contactPhone}}</p>
                        <div class="modal-custom__form">
                            <div class="form-customize">
                                <div class="form-input">
                                    <input class="form-control" type="number" placeholder="OTP"
                                           pattern="[0-9]*"
                                           min="1"
                                           step="1"
                                           :disabled="hasDis"
                                           onkeypress="return event.keyCode === 8 || event.charCode >= 48 && event.charCode <= 57"
                                           v-model="otp" /><span class="input-time">00:{{timeSecond}}</span>
                                </div>
                            </div>
                        </div>
                        <p class="modal-custom__note">{{ $t('ftth.add_new_number.not_received') }}<a :style="styleDis" class="modal-custom__note-link" href="javascript:;" @click="resendOtp()">{{ $t('ftth.add_new_number.resend_otp') }}</a></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import BaseComponent from '@/mixins/BaseComponent'
import service from './../../services/FTTHService'
import LocalStorage from '@/ultils/local-storage'
import store from '@/plugins/vuex/store'
import $ from 'jquery'

export default {
   name: 'NumberList',
   mixins: [BaseComponent],
   components: {},
   data() {
      return {
          numberList: [],
          isEdit: true,
          timeSecond: 60,
          otp: "",
          phoneNumber: "",
          nodata: false,
          styleDis: "pointer-events: none",
          hasDis: false,
          contactPhone:""
      }
   },
   created() {
       this.setTitle(this.$i18n.t('ftth.number_list.title'))
       this.nodata = false;
       let ftthAccountInfo = JSON.parse(LocalStorage.getItem('ftth_account_info'))
       this.contactPhone = ftthAccountInfo.isdn ?? ''
       this.getData()
   },
   watch: {
       otp(val) {
           if (val.length == 6) {
               this.hasDis = true
               this.verifyOtp()
           } else {
               this.hasDis = false
           }
       }
   },
   methods: {
       getData() {
           this.showLoading()

           let ftthAccount = LocalStorage.getItem('ftth_account') //801119242
           var dataPost = {
               ftthAccount: ftthAccount
           }

           service
               .getInforPromotionFtth100(dataPost)
               .then((response) => {
                   if (response.wsResponse) {
                       this.numberList = response.wsResponse;
                       if (this.numberList.length == 0) {
                           this.nodata = true;
                           this.isEdit = false
                       }
                   }
                   this.hideLoading()
               })
               .catch(() => {
                   this.hideLoading()
               })
       },
       onEdit() {
           this.isEdit = false
       },
       onAdd() {
           this.$router.push('/ftth/add-number-list')
       },
       removeNewNumber(phoneNumber) {
           this.phoneNumber = phoneNumber;
           this.showLoading()

           let ftthAccount = LocalStorage.getItem('ftth_account')
           var dataPost = {
               ftthAccount: ftthAccount,
               paramCode: "1001",
               isdn: phoneNumber,
               username: ftthAccount
           }

           service
               .getPromotionUseFTTH(dataPost)
               .then((response) => {
                   if (response.wsResponse.responseCode != 0 || response.errorCode != 0) {
                       store.dispatch('setNotification', {
                           title: this.$i18n.t('errors.system'),
                           content: response.userMsg,
                           closeButton: this.$i18n.t('buttons.close')
                       })
                   } else {
                       this.otp = "";
                       $('#methodBtn').trigger('click')
                       this.timeSecond = 60
                       this.styleDis = "pointer-events: none";
                       this.countDownTimer()
                   }
                   this.hideLoading()
               })
               .catch(() => {
                   this.hideLoading()
               })
       },
       resendOtp() {
           this.showLoading()

           let ftthAccount = LocalStorage.getItem('ftth_account')
           var dataPost = {
               ftthAccount: ftthAccount,
               paramCode: "1001",
               isdn: this.phoneNumber,
               username: ftthAccount
           }

           service
               .getPromotionUseFTTH(dataPost)
               .then((response) => {
                   if (response.wsResponse.responseCode != 0 || response.errorCode != 0) {
                       store.dispatch('setNotification', {
                           title: this.$i18n.t('notification.title'),
                           content: response.userMsg,
                           closeButton: this.$i18n.t('buttons.close')
                       })
                   } else {
                       this.otp = "";
                       this.timeSecond = 60
                       this.styleDis = "pointer-events: none";
                       this.countDownTimer()
                   }
                   this.hideLoading()
               })
               .catch(() => {
                   this.hideLoading()
               })
       },
       countDownTimer() {
           if (this.timeSecond > 0) {
               setTimeout(() => {
                   this.timeSecond -= 1
                   if (this.timeSecond < 10) {
                       if (this.timeSecond != -1) {
                           this.timeSecond = "0" + this.timeSecond
                       } else {
                           this.timeSecond = "00"
                       }
                   }
                   this.countDownTimer()
               }, 1000)
           } else {
               this.styleDis = ""
           }
       },
       verifyOtp() {
           this.showLoading()

           let ftthAccount = LocalStorage.getItem('ftth_account')
           let ftthAccountInfo = JSON.parse(LocalStorage.getItem('ftth_account_info'))
           var dataPost = {
               otp: this.otp,
               isdn: ftthAccountInfo.isdn
           }

           service
               .getVerifyOtp(dataPost)
               .then((response) => {
                   if (response.errorCode != 0) {
                       this.otp = "";
                       store.dispatch('setNotification', {
                           title: this.$i18n.t('notification.title'),
                           content: response.userMsg,
                           closeButton: this.$i18n.t('buttons.close')
                       })
                       this.hideLoading()
                   } else {
                       var dataPost = {
                           ftthAccount: ftthAccount,
                           paramCode: "1002",
                           isdn: this.phoneNumber,
                           username: ftthAccount
                       }

                       service
                           .getPromotionUseFTTH(dataPost)
                           .then((response) => {
                               if (response.wsResponse.responseCode != 0) {
                                   store.dispatch('setNotification', {
                                       title: this.$i18n.t('notification.title'),
                                       content: response.userMsg,
                                       closeButton: this.$i18n.t('buttons.close')
                                   })
                               } else {
                                   store.dispatch('setNotification', {
                                       title: this.$i18n.t('notification.title'),
                                       content: response.userMsg,
                                       icon: './images/ic-success.svg',
                                       closeButton: this.$i18n.t('buttons.close')
                                   })

                                   this.getData();
                                   //this.isEdit = true;
                               }
                               this.hideLoading()
                           })
                           .catch(() => {
                               this.hideLoading()
                           })
                   }

               })
               .catch(() => {
                   this.hideLoading()
               })
       }
   }
}
</script>
