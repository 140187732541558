<template>
    <div class="main-container">
        <div class="nav-bar js-nav-bar"  @click="goBack()">
            <div class="nav-bar__inner">
                <span class="nav-bar__icon"><i class="icon-fonts icon-fonts--arrow-left"></i></span>
                <h3 class="nav-bar__title">{{$t('home.registration_info.title')}}</h3>
            </div>
        </div>
        <div class="main-content">
            <div class="">
                <cropper :transitions="true"
                         :auto-zoom="true"
                         ref="cropper"
                         default-boundaries="fill"
                         class="cropper"
                         image-class="cropper__image"
                         :src="img"
                         ></cropper>
                <!--:stencil-props="{aspectRatio: 12/12}"-->
            </div>
            <button  @click="rotateImage">Rotate image</button>
            <button @click="plipImage(1)">Flip image horizontal</button>
            <button @click="plipImage(2)">Flip image vertical</button>
            <button  @click="getCropImage">Save</button>
        </div>

    </div>
</template>
<script>
    import BaseComponent from '@/mixins/BaseComponent'
    import store from '@/plugins/vuex/store'
    import { Cropper } from 'vue-advanced-cropper'
    import 'vue-advanced-cropper/dist/style.css';

    export default {
        name: 'ImageCrop',
        mixins: [BaseComponent],
        components: {
            Cropper
        },
        data() {
            return {
                img: null
                
            }
        },
        created() {
            this.setTitle(this.$i18n.t('home.registration_info.title'))

            this.img = store.state.urlImage
            
        },
        methods: {
            
            rotateImage() {
                this.$refs.cropper.rotate(90);
            },
            plipImage(number) {
                if (number == 1) {
                    this.$refs.cropper.flip(true, false);
                } else {
                    this.$refs.cropper.flip(false, true);
                }
                
            },
            closeCrop() {
                this.$emit('close')
            },
            getCropImage() {
                store.commit('setUrlImageCrop', this.$refs.cropper.getResult().canvas.toDataURL("image/jpeg"))
                this.$router.go(-1)
            }
        }
    }
</script>
