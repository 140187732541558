<template>
   <div class="main-container">
      <div class="nav-bar js-nav-bar" @click="back">
         <div class="nav-bar__inner">
            <span class="nav-bar__icon"><i class="icon-fonts icon-fonts--arrow-left"></i></span>
            <h3 class="nav-bar__title">{{ $t('ftth.ftth_service') }}</h3>
         </div>
      </div>
      <ValidationObserver v-slot="{ passes }">
         <form @submit.prevent="passes(login)">
            <div class="box-login">
               <div class="box-login__inner">
                  <h3 class="box-login__title">{{ $t('ftth.login') }}</h3>
                  <div class="box-login__form">
                     <Input
                        type="number"
                        class="form-custom"
                        :label="$t('ftth.ftth_account')"
                        rules="required"
                        v-model="account"
                     />
                     <Input
                        :type="type"
                        class="form-custom"
                        :rightIcon="rightIcon"
                        :onClickRightIcon="togglePasswordType"
                        :label="$t('ftth.password')"
                        rules="required"
                        v-model="password"
                     />
                  </div>
                  <a class="box-login__link" href="javascript:void(0)" @click="forgotPass">{{
                     $t('ftth.forget_password')
                  }}</a>
               </div>
            </div>
            <div class="main-content">
               <div class="ftth-service">
                  <div class="ftth-service__btn">
                     <button class="button button--primary button--full">
                        {{ $t('ftth.signin') }}
                     </button>
                  </div>
                  <!-- <p class="ftth-service__noti">
                     {{ $t('ftth.dont_hava_an_account')
                     }}<a class="ftth-service__link" href="javascript:void(0)" @click="signUp">{{
                        $t('ftth.signup')
                     }}</a>
                  </p> -->
                  <div class="banner-slider" v-if="banners.length > 0">
                     <ul class="banner-slider__list js-slider-col1">
                        <li class="banner-slider__item" v-for="banner in banners" :key="banner.newsId">
                           <img :src="banner.advImgUrl" alt="img" />
                        </li>
                     </ul>
                  </div>
               </div>
            </div>
         </form>
      </ValidationObserver>
   </div>
</template>
<script>
import BaseComponent from '@/mixins/BaseComponent'
import service from './../../services/FTTHService'
import { ValidationObserver } from 'vee-validate'
import Input from '@/components/form/Input'
import LocalStorage from '@/ultils/local-storage'
import { callNative } from '@/ultils/call-native'
import $ from 'jquery'

export default {
   name: 'Login',
   components: {
      ValidationObserver,
      Input
   },
   mixins: [BaseComponent],
   data() {
      return {
         userInfo: null,
         banners: [],
         account: '',
         password: '',
         type: 'password',
         rightIcon: 'icon-fonts icon-fonts--eye-off'
      }
   },
   created() {
      this.userInfo = JSON.parse(LocalStorage.getItem('user_info'))
      this.loadBanners()
      if (this.userInfo && this.userInfo.originalUrl) {
         this.checkAccount()
      } else {
         this.setTitle(this.$i18n.t('ftth.ftth_service'))
      }
   },
   methods: {
      checkAccount() {
         this.showLoading()
         service
            .getListFtthAccountInfo()
            .then((response) => {
               let accounts = response.wsResponse ?? []
               if (accounts.length > 0) {
                  this.userInfo.originalUrl = '/ftth/account-list'
                  LocalStorage.setItem('user_info', JSON.stringify(this.userInfo))
                  LocalStorage.setItem('ftth_list_account', JSON.stringify(accounts))
                  let onConfirmLogout = LocalStorage.getItem('onConfirmLogout')
                  if (!onConfirmLogout) {
                     this.$router.push('/ftth/account-list')
                     LocalStorage.removeItem('onConfirmLogout')
                  }
               }
            })
            .finally(() => {
               this.hideLoading()
            })
      },
      loadBanners() {
         this.showLoading()
         service
            .getListBanners({ limit: 5 })
            .then((response) => {
               this.banners = response.wsResponse
               if (this.banners.length > 0) {
                  $('.js-slider-col1').css('display', 'none')
                  $.getScript('js/slider.js', () => {
                     $('.js-slider-col1').css('display', 'block')
                  })
               }
            })
            .finally(() => {
               this.hideLoading()
            })
      },
      login() {
         this.showLoading()
         service
            .login({ accountType: '1', username: this.account, password: this.password })
            .then((response) => {
               if (!response.errorMessage) {
                  let ftthUserInfo = {
                     token: response.token,
                     isdn: response.username,
                     sessionId: response.sessionId
                  }
                  LocalStorage.setItem('ftth_user_info', JSON.stringify(ftthUserInfo))

                  callNative('ftthAccount', this.account)
                  LocalStorage.setItem('ftth_account', this.account)
                  LocalStorage.setItem('ftth_account_login', this.account)
                  let redirectUrl = LocalStorage.getItem('ftth_redirect_url')
                  if (redirectUrl) {
                     this.hideLoading()
                     if (redirectUrl !== '/ftth/pay-monthly-fee') {
                        this.$router.push(redirectUrl)
                     } else {
                        this.getEwalletInfo()
                     }
                  } else {
                     this.$router.push('/ftth/service')
                  }
               } else {
                  this.$store.dispatch('setNotification', { content: response.errorMessage })
                  this.hideLoading()
               }
            })
            .catch(() => {
               this.hideLoading()
            })
      },
      forgotPass() {
         if (this.account) {
            this.showLoading()
            service
               .forgotPass({ otp: '', username: this.account })
               .then((response) => {
                  if (response.errorCode === 'S200') {
                     this.$store.dispatch('setNotification', { content: this.$i18n.t('ftth.reset_pwd_ftth_done') })
                  } else {
                     this.$store.dispatch('setNotification', { content: response.errorMessage })
                  }
               })
               .finally(() => {
                  this.hideLoading()
               })
         } else {
            this.$store.dispatch('setNotification', { content: this.$i18n.t('ftth.please_input_account') })
         }
      },
      getEwalletInfo() {
         this.showLoading()
         let userInfo = JSON.parse(LocalStorage.getItem('user_info'))
         service
            .getFtthAccountInfo({ ftthAccount: LocalStorage.getItem('ftth_account') })
            .then((response) => {
               let data = response.wsResponse
               LocalStorage.setItem('ftth_account_info', JSON.stringify(response.wsResponse))
               service
                  .getEwalletInfo({ isdnEwallet: userInfo.isdn })
                  .then((response) => {
                     if (response.errorCode == '0') {
                        if (response.wsResponse.responseCode === '00000') {
                           window.location.href = `https://webview.unitel.com.la:9443/#/internet-bill/start?roleId=${
                              data.roleId
                           }&lang=${LocalStorage.getItem('lang')}&sender=${data.custName}&phoneNumber=${
                              data.isdn
                           }&token=`
                        } else if (response.wsResponse.responseCode === '10114') {
                           this.$store.dispatch('setNotification', {
                              content: this.$i18n.t('errors.locked_account')
                           })
                        } else if (
                           response.wsResponse.responseCode === '10116' ||
                           response.wsResponse.responseCode === '10117'
                        ) {
                           window.location.href = 'https://webview.unitel.com.la:9443/#/sign-up/startC'
                        } else {
                           this.$store.dispatch('setNotification', {
                              content: this.$i18n.t('ftth.view_invoice.cannot_access_wallet')
                           })
                        }
                     } else {
                        this.$store.dispatch('setNotification', { content: response.message ?? response.userMsg })
                     }
                  })
                  .finally(() => {
                     this.hideLoading()
                  })
            })
            .catch(() => {
               this.hideLoading()
            })
      },
      togglePasswordType() {
         if (this.type === 'password') {
            this.type = 'text'
            this.rightIcon = 'icon-fonts--eye'
         } else {
            this.type = 'password'
            this.rightIcon = 'icon-fonts--eye-off'
         }
      },
      signUp() {
         // this.$router.push('/ftth/ftth-package')
         // callNative('open_shop','')
      },
      back() {
         LocalStorage.removeItem('ftth_redirect_url')
         this.goBack()
      }
   }
}
</script>
