<template>
   <div class="main-container">
        <div class="nav-bar js-nav-bar">
          <div class="nav-bar__inner"><span class="nav-bar__icon"><i class="icon-fonts icon-fonts--arrow-left" @click="goBack()"></i></span>
            <h3 class="nav-bar__title">{{$t('mobile.change_scratch_card.change_scratch_card_result')}}</h3>
          </div>
        </div>
        <div class="main-content">
          <div class="change-postpaid">
            <div class="steps-bar">
              
            </div>
            <div class="request-success">
              <h3 class="request-success__title">{{$t('mobile.change_scratch_card.request_successfully')}}</h3>
              <p class="request-success__des">{{data.message}}</p>
              <!-- <div class="request-success__content">
                <div class="request-success__box">
                  <div class="request-success__top">
                    <h5 class="request-success__sub">{{$t('mobile.change_scratch_card.request_information')}}</h5>
                  </div>
                  <ul class="request-success__list">
                    <li class="request-success__item"><span class="request-success__name">{{$t('mobile.change_scratch_card.order_code')}}</span><span class="request-success__value">4EVER</span></li>
                    <li class="request-success__item"><span class="request-success__name">{{$t('mobile.change_scratch_card.date_of_order')}}</span><span class="request-success__value">30/06/2022</span></li>
                    <li class="request-success__item"><span class="request-success__name">{{$t('mobile.change_scratch_card.Status')}}</span><span class="request-success__value">waiting for sale</span></li>
                  </ul>
                </div>
              </div> -->
              <div class="request-success__btn">
                <button class="button button--primary button--full" @click="backToHome">{{$t('mobile.change_to_prepaid.back_to_homepage')}}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
</template>
<script>
import BaseComponent from '@/mixins/BaseComponent'
import store from '@/plugins/vuex/store'
//import LocalStorage from '@/ultils/local-storage'

export default {
   name: 'ConvertChangeScartchCard',
   mixins: [BaseComponent],
   components: {
   },
   data() {
      return {
         data:{
          message:''
         }
      }
   },
   mounted() {

      this.data = {...store.state.change_scartch_card.step3}
   },
   created() {
        this.loadScript('js/select2.js')
   },
   methods: {
       backToHome(){
           this.$router.push('/mobile/home')
       }
   },
}
</script>
