<template>
   <div class="main-container">
      <div class="nav-bar js-nav-bar">
         <div class="nav-bar__inner">
            <span class="nav-bar__icon" @click="goBack"><i class="icon-fonts icon-fonts--arrow-left"></i></span>
            <h3 class="nav-bar__title" @click="goBack">{{ $t('mobile.map.title') }}</h3>
            <span class="nav-bar__action" @click="redirectToSearchByProvince"
               ><i class="icon-fonts icon-fonts--search"></i
            ></span>
         </div>
      </div>
      <div class="main-content">
         <div class="find-store">
            <div class="find-store__map" id="map" style="width: 100%; height: 300px"></div>
            <ul class="find-store__list">
               <li class="find-store__item" v-for="store in storesList" :key="store.name" @click="selectStore(store)">
                  <div class="find-store__detail">
                     <div class="find-store__images"><img src="@/assets/images/ic-store.svg" alt="img" /></div>
                     <div class="find-store__info">
                        <h5 class="find-store__name">{{ store.name }}</h5>
                        <p class="find-store__des">{{ store.addr }}</p>
                     </div>
                  </div>
                  <div class="find-store__location">
                     <span class="find-store__icon"><img src="@/assets/images/ic-location.svg" alt="img" /></span
                     ><span class="find-store__value"
                        >{{ Number(store.distance).toLocaleString('en-US', { maximumFractionDigits: 0 }) }}
                        {{ $t('unit.km') }}</span
                     >
                  </div>
               </li>
            </ul>
         </div>
      </div>
   </div>
</template>
<script>
import BaseComponent from '@/mixins/BaseComponent'
import service from '../../services/MobileService'
import LocalStorage from '@/ultils/local-storage'
import gmapsInit from '@/ultils/gmap'
import { getDevice } from '@/ultils/device'

export default {
   name: 'FindStoreByCurrentLocation',
   mixins: [BaseComponent],
   data() {
      return {
         location: { lat: 0, lng: 0 },
         storesList: [],
         google: null,
         map: null
      }
   },
   mounted() {
      if (getDevice() === 'IOS' && localStorage.getItem('skip_access_location') === null) {
         if (localStorage.getItem('reloaded')) {
            localStorage.removeItem('reloaded')
         } else {
            localStorage.setItem('reloaded', '1')
            location.reload()
         }
      }
      this.loadScript('js/scroll.js')
      this.setTitle(this.$i18n.t('mobile.map.title'))
      this.loadMap()
   },
   methods: {
      async loadMap() {
         let seft = this
         this.google = await gmapsInit()
         this.map = new this.google.maps.Map(document.getElementById('map'), {
            center: this.location,
            zoom: 12
         })

         if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
               (position) => {
                  seft.location = {
                     lat: position.coords.latitude,
                     lng: position.coords.longitude
                  }
                  seft.map.setCenter(seft.location)
                  seft.loadStoresByCurrentLocation(seft.location)
                  localStorage.setItem(
                     'current_location',
                     JSON.stringify({ lat: position.coords.latitude, lng: position.coords.longitude })
                  )
                  localStorage.setItem('skip_access_location', 'false')
               },
               () => {
                  localStorage.setItem('skip_access_location', 'true')
                  seft.$router.push('/mobile/map/by-province')
               }
            )
         } else {
            seft.$store.dispatch('setNotification', { content: seft.$i18n.t('errors.not_support_geolocation') })
            localStorage.setItem('skip_access_location', 'true')
            seft.$router.push('/mobile/map/by-province')
         }
      },
      loadStoresByCurrentLocation(location) {
         this.showLoading()
         service
            .getNearestStores({
               // provinceId: '',
               // districtId: '',
               latitude: location.lat,
               longitude: location.lng
            })
            .then((response) => {
               if (response.errorCode === '0') {
                  this.storesList = response.wsResponse
                  this.storesList?.forEach((store) => {
                     new this.google.maps.Marker({
                        title: store.name,
                        position: { lat: store.latitude, lng: store.longitude },
                        icon: './images/ic-tracker.svg',
                        map: this.map
                     })
                  })
               } else {
                  this.$router.dispatch('setNotification', { content: response.message ?? response.userMsg })
               }
            })
            .finally(() => {
               this.hideLoading()
            })
      },
      selectStore(store) {
         LocalStorage.setItem('store', JSON.stringify(store))
         this.$router.push('/mobile/map/detail')
      },
      redirectToSearchByProvince() {
         this.$router.push('/mobile/map/by-province')
      }
   }
}
</script>
