<template>
   <div class="main-container">
      <div class="nav-bar js-nav-bar" @click="goBack">
         <div class="nav-bar__inner">
            <span class="nav-bar__icon"><i class="icon-fonts icon-fonts--arrow-left"></i></span>
            <h3 class="nav-bar__title">{{ $t('ucare.gift_deals.title') }}</h3>
         </div>
      </div>
      <div class="main-content">
         <div class="credit-service credit-service--pack">
            <div class="exchange-point">
               <h4 class="exchange-point__name">{{ $t('ucare.gift_deals.available_upoint') }}</h4>
               <span class="exchange-point__value">{{
                  Number(exchangePoint).toLocaleString('en-US', { maximumFractionDigits: 0 })
               }}</span>
            </div>
            <ul class="credit-service__list">
               <li class="credit-service__item" v-for="gift in listGifts" :key="gift.giftId">
                  <div class="credit-service__item-detail" @click="redirectTo('/ucare/gift-deals/detail', gift)">
                     <div class="credit-service__item-images">
                        <img :src="gift.imageUrl" alt="img" /><span
                           class="credit-service__item-tag heart"
                           ><span>{{ gift.giftCode }}</span></span
                        >
                     </div>
                     <div class="credit-service__item-info">
                        <div class="credit-service__item-top">
                           <h5 class="credit-service__item-name">
                              <a class="credit-service__item-link" href="javascript:void(0)">{{ gift.giftName }}</a>
                           </h5>
                        </div>
                        <p class="credit-service__item-des">
                           {{ Number(gift.giftPoint).toLocaleString('en-US', { maximumFractionDigits: 0 }) }}
                           {{ $t('unit.upoint') }}
                        </p>
                        <p class="credit-service__item-des" v-html="gift.description"></p>
                     </div>
                  </div>
                  <div class="credit-service__item-btn" @click="redirectTo('/ucare/gift-deals/confirm/1/1', gift)">
                     <button class="button button--primary button--small">
                        {{ $t('ucare.exchange') }}
                     </button>
                  </div>
               </li>
            </ul>
         </div>
      </div>
   </div>
</template>
<script>
import BaseComponent from '@/mixins/BaseComponent'
import service from '../../services/UCareService'
import LocalStorage from '@/ultils/local-storage'

export default {
   name: 'GiftDeals',
   mixins: [BaseComponent],
   data() {
      return {
         exchangePoint: 0,
         listGifts: [],
         limit: 50,
         page: 1
      }
   },
   created() {
      this.setTitle(this.$i18n.t('ucare.gift_deals.title'))
      this.loadData()
   },
   methods: {
      loadData() {
         let getAccountPointInfo = service.getAccountPointInfo().then((response) => {
            if (response.code === '000') {
               let result = response.listAccountPoint ?? []
               this.exchangePoint = result.reduce((total, acc) => {
                  if (acc.pointType === 2) {
                     return total + acc.pointValue
                  } else {
                     return total
                  }
               }, 0)
            } else {
               // this.$store.dispatch('setNotification', {
               //    content: response.message
               // })
            }
         })

         let getGifts = service.getAllPartnerGift({ page: this.page, limit: this.limit }).then((response) => {
            let result = response.wsResponse.object ?? []
            if (result.length > 0) {
               this.listGifts = result[0].gifts
            }
         })
         this.showLoading()
         Promise.allSettled([getAccountPointInfo, getGifts]).finally(() => {
            this.hideLoading()
         })
      },
      redirectTo(url, gift) {
         let data = {
            gift: gift,
            exchangePoint: this.exchangePoint
         }
         LocalStorage.setItem('gift_deal', JSON.stringify(data))
         this.$router.push(url)
      }
   }
}
</script>
