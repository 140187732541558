<template>
   <div>
      <button style="display: none" data-bs-toggle="modal" data-bs-target="#viewModal" id="viewModalBtn" />
      <div class="modal fade" data-backdrop="static" id="viewModal" tabindex="-1" aria-labelledby="signInLabel">
         <div class="modal-dialog modal-fullscreen" style="margin: 0px">
            <div class="modal-content">
               <div class="web-view__nav">
                  <span class="web-view__icon" data-bs-dismiss="modal" @click="resetCountHistory"
                     ><i class="icon-fonts icon-fonts--close"></i
                  ></span>
                  <h3 class="web-view__name">{{ data.name }}</h3>
                  <div class="web-view__action">
                     <span class="web-view__arrow"><i class="icon-fonts icon-fonts--arrow-left" @click="back"></i></span
                     ><span class="web-view__arrow"
                        ><i class="icon-fonts icon-fonts--arrow-right" @click="forward"></i
                     ></span>
                  </div>
               </div>
               <div class="web-view__content">
                  <iframe
                     @load="load"
                     :src="data.url"
                     style="display: block; background: #000; border: none; height: 100vh; width: 100vw"
                  ></iframe>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>
<script>
import $ from 'jquery'

export default {
   name: 'view-modal',
   props: {
      data: Object // Type: {name: "", url: ""}
   },
   data() {
      return {
         countHistory: 0
      }
   },
   methods: {
      back() {
         if (this.countHistory >= 2) {
            window.history.back()
            this.countHistory = this.countHistory - 2
         }
      },
      forward() {
         window.history.forward()
      },
      load() {
         this.countHistory = this.countHistory + 1
      },
      resetCountHistory() {
         if (this.countHistory >= 2) {
            window.history.go(1 - this.countHistory)
         }
      }
   },
   watch: {
      data() {
         $('#viewModalBtn').trigger('click')
         this.countHistory = 0
      }
   }
}
</script>
