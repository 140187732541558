<template>
   <div class="main-container">
      <div class="nav-bar js-nav-bar" @click="goBack">
         <div class="nav-bar__inner">
            <span class="nav-bar__icon"><i class="icon-fonts icon-fonts--arrow-left"></i></span>
            <h3 class="nav-bar__title">{{ $t('home.simcard_registration.title') }}</h3>
         </div>
      </div>
      <div class="main-content">
         <div class="sim-card">
            <div class="sim-card__top">
               <div class="sim-card__detail">
                  <a class="sim-card__thumbnail"><img src="@/assets/images/img-lao-kyc.svg" alt="img" /></a>
                  <div class="sim-card__info">
                     <h5 class="sim-card__name">
                        <a class="sim-card__name-link" href="javascript:void(0)">{{
                           $t('home.simcard_registration.laoKYC')
                        }}</a>
                     </h5>
                     <p class="sim-card__sub">SB Lab 856 Co.,Ltd</p>
                  </div>
               </div>
               <div class="sim-card__btn">
                  <a class="sim-card__btn-link" href="http://onelink.to/q9k35b">{{
                     $t('home.simcard_registration.install')
                  }}</a>
               </div>
            </div>
            <div class="sim-card__content">
               <p class="sim-card__des">
                  Please hurry up to register your simcard at Unitel counters/agentsOR register online yourself before
                  30/09/2021.
               </p>
               <p class="sim-card__des">
                  Simcard registration is required by law and regulations of Lao PDR. All prepaid, postpaid, netsim must
                  register our simcard with service provider or mobile operator. Customer can register at counter
                  service by filling out forms and make copy of your supporting documents. Customer can also visit
                  outlets to register your simcard using 3Grab outlet applications.
               </p>
               <p class="sim-card__des">
                  In addition to register your simcard at the service center outlets where you have to physically visit,
                  now customer can perform simcard self-registrations at home or anywhere for yourself or your family
                  members.
               </p>
               <p class="sim-card__des">
                  LaoKYC allow users to easily register simcard with service providers such as LTC, Unitel, ETL, TPLus,
                  Plannet, LaoSAT, SKYTEL for all prepaid, postpaid, and netsim. It's simply 3 steps processes. It's
                  safe and secure with OTP to verify your number before registering.
               </p>
               <p class="sim-card__des">
                  Register your number today and never lost your number again. Stay home stay safe.
               </p>
               <p class="sim-card__des">
                  3 Easy steps<br />1. Log-in LaoKYC and proceed to 3Grab service<br />2. Verify your number with OTP,
                  take photos of your supporting document, and fillout your application<br />3. Review and submit your
                  application. You'll receive SMS Notification from your service provider of the result after they KYC
                  complete.
               </p>
               <p class="sim-card__des">
                  Features:<br />- Works over Wifi, 3G, 4G network<br />- Log-in with OTP for OpenID<br />- SMS OTP
                  confirmation of your simcard ownership<br />- can register for your friend and family with SMS OTP
                  confirming ownership<br />- Automatic detection of your ISDN after confirmation<br />- On screen
                  guideline on how to take correct photos of your supporting document<br />- review your information
                  before submitting application<br />- Check your KYC status by dialing *1021#
               </p>
            </div>
         </div>
      </div>
   </div>
</template>
<script>
import BaseComponent from '@/mixins/BaseComponent'

export default {
   name: 'LaoKYC',
   mixins: [BaseComponent],
   data() {
      return {}
   },
   created() {
      this.setTitle(this.$i18n.t('home.simcard_registration.title'))
   },
   methods: {},
   computed: {},
   watch: {}
}
</script>
