<template>
   <div class="main-container">
      <div class="nav-bar js-nav-bar" @click="goBack">
         <div class="nav-bar__inner">
            <span class="nav-bar__icon"><i class="icon-fonts icon-fonts--arrow-left"></i></span>
            <h3 class="nav-bar__title">{{ $t('home.user_information.title') }}</h3>
         </div>
      </div>
      <div class="main-content">
         <div class="user_info">
            <div class="user-info__top">
               <div class="user-info__avatar"><i class="icon-fonts icon-fonts--avatar"></i></div>
               <h5 class="user-info__sub">{{ data.fullName }}</h5>
               <span class="user-info__tel">{{ phone }}</span>
               <span class="user-info__tel" style="color:#f68b1f">{{ 
                  data.status != '1' ?
                  $t('home.user_information.info_wasnot_standard') : $t('home.user_information.info_was_standard') }}</span>
            </div>
            <ul class="user-info__list">
               <li class="user-info__item">
                  <span class="user-info__name">{{ $t('home.user_information.customer_type') }}</span
                  ><span class="user-info__value">{{ data.cusTypeName }}</span>
               </li>
               <li class="user-info__item">
                  <span class="user-info__name">{{ $t('home.user_information.type_id') }}</span
                  ><span class="user-info__value">{{ data.idTypeName }}</span>
               </li>
               <li class="user-info__item">
                  <span class="user-info__name">{{ $t('home.user_information.id') }}</span
                  ><span class="user-info__value">{{ data.idNo }}</span>
               </li>
               <li class="user-info__item">
                  <span class="user-info__name">{{ $t('home.user_information.birthday') }}</span
                  ><span class="user-info__value">{{ data.doB }}</span>
               </li>
               <li class="user-info__item">
                  <span class="user-info__name">{{ $t('home.user_information.gender') }}</span
                  ><span class="user-info__value">{{
                     data.gender == 'F'
                        ? $t('home.user_information.female')
                        : data.gender == 'M'
                        ? $t('home.user_information.male')
                        : ''
                  }}</span>
               </li>
               <li class="user-info__item">
                  <span class="user-info__name">{{ $t('home.user_information.nationality') }}</span
                  ><span class="user-info__value">{{ data.nationalities }}</span>
               </li>
               <li class="user-info__item">
                  <span class="user-info__name">{{ $t('home.user_information.province') }}</span
                  ><span class="user-info__value">{{ data.provinceName }}</span>
               </li>
            </ul>
            <div class="user-upload">
               <ul class="user-upload__list">
                  <li class="user-upload__item">
                     <div class="user-upload__images">
                        <!--@/assets/images/img-default-upload.png-->
                        <img :src="data.image3" alt="img" />
                     </div>
                     <div class="user-upload__info">
                        <h5 class="user-upload__name">{{ $t('home.user_information.number_image') }}</h5>
                     </div>
                  </li>
                  <li class="user-upload__item">
                     <div class="user-upload__images"><img :src="data.image2" alt="img" /></div>
                     <div class="user-upload__info">
                        <h5 class="user-upload__name">{{ $t('home.user_information.client_imagee') }}</h5>
                     </div>
                  </li>
                  <li class="user-upload__item">
                     <div class="user-upload__images"><img :src="data.image1" alt="img" /></div>
                     <div class="user-upload__info">
                        <h5 class="user-upload__name">{{ $t('home.user_information.id_image') }}</h5>
                     </div>
                  </li>
               </ul>
            </div>
         </div>
         <div class="recharge-btn">
            <button class="button button--primary button--full" v-on:click="register()">
               <!-- {{ $t('home.registration_info.register') }} -->
               {{ isEdit ? $t('home.registration_info.update') : $t('home.registration_info.register') }}
            </button>
         </div>
      </div>
   </div>
</template>
<script>
import BaseComponent from '@/mixins/BaseComponent'
import service from './../services/HomeService'
import { CustomerType, TypeId } from '../constants/data'
import LocalStorage from '@/ultils/local-storage'
import store from '@/plugins/vuex/store'

export default {
   name: 'UserInformation',
   mixins: [BaseComponent],
   data() {
      return {
         data: {
            standard: '',
            precinctName: '',
            image3: '',
            idType: '',
            gender: '',
            districtName: '',
            description: '',
            fullName: '',
            nationalities: '',
            image1: '',
            idNo: '',
            image2: '',
            responseCode: '',
            precinct: '',
            province: '',
            doB: '',
            cusType: '',
            district: '',
            subType: '',
            provinceName: '',
            address: '',
            isUmoney: '',
            status: -1,
         },
         phone: '',
         isEdit: true
      }
   },
   created() {
      this.getData()
      this.setTitle(this.$i18n.t('home.user_information.title'))
   },
   methods: {
      getData() {
         this.showLoading()
         var userInfo = JSON.parse(LocalStorage.getItem('user_info')) ?? {}
         this.phone = userInfo['isdn']

         service
            .getInfoCust()
            .then((response) => {
               if (response.wsResponse.responseCode == '60') {
                  this.isEdit = false
               } else {
                  this.isEdit = true
               }
               this.data = response.wsResponse
               //Nếu không có ảnh thì để ảnh mặc định
               this.data.image1 =
                  this.data.image1 == 'no image' || this.data.image1 == ''
                     ? './images/img-default-upload.png'
                     : 'data:image/jpeg;base64,' + this.data.image1
               this.data.image2 =
                  this.data.image2 == 'no image' || this.data.image2 == ''
                     ? './images/img-default-upload.png'
                     : 'data:image/jpeg;base64,' + this.data.image2
               this.data.image3 =
                  this.data.image3 == 'no image' || this.data.image3 == ''
                     ? './images/img-default-upload.png'
                     : 'data:image/jpeg;base64,' + this.data.image3

               //Chuyển đổi dữ liệu config
               //data.cusType
               var _cusType = CustomerType.filter((x) => x.code == this.data.cusType)
               if (_cusType) {
                  this.data.cusTypeName = _cusType[0].name
               }
               //data.idType
               var _idType = TypeId.filter((x) => x.code == this.data.idType)
               if (_idType) {
                  this.data.idTypeName = _idType[0].name
               }
               //Data trả về null thì chuyển thành rỗng
               this.data.nationalities = this.data.nationalities == 'null' ? '' : this.data.nationalities
               this.data.precinct = this.data.precinct == 'null' ? '' : this.data.precinct
               this.data.district = this.data.district == 'null' ? '' : this.data.district

               this.data.address =
                  (this.data.precinctName == '' ? '' : this.data.precinctName + ', ') +
                  (this.data.districtName == '' ? '' : this.data.districtName + ', ') +
                  (this.data.provinceName == '' ? '' : this.data.provinceName)
               this.hideLoading()
            })
            .catch(() => {
               this.hideLoading()
            })
         
         service.getUserInfo().then((response)=>{
               this.data.status = (response.wsResponse.standardStatus === '1' && response.wsResponse.standardSystem==='3GRAB') ? response.wsResponse.standardStatus : 0
         }).catch(() => {
               this.hideLoading()
         })
      },
      register() {
         if ('0' == this.data.subType) {
            store.dispatch('setNotification', {
               title: this.$i18n.t('notification.title'),
               content: this.$i18n.t('home.user_information.msg_when_sub_type_tra_sau'),
               closeButton: this.$i18n.t('buttons.close')
            })
         } else {
            if ('1' == this.data.status) {
               store.dispatch('setNotification', {
                  title: this.$i18n.t('notification.title'),
                  content: this.$i18n.t('home.user_information.msg_when_info_standarded'),
                  closeButton: this.$i18n.t('buttons.close')
               })
            } else {
               if ('1' == this.data.isUmoney || '2' == this.data.isUmoney) {
                  store.dispatch('setNotification', {
                     title: this.$i18n.t('notification.title'),
                     content: this.$i18n.t('home.user_information.msg_when_u_money'),
                     closeButton: this.$i18n.t('buttons.close')
                  })
               } else {
                  this.$router.push('/register-info')
               }
            }
         }
      }
   }
}
</script>
