var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main-container"},[_c('div',{staticClass:"nav-bar js-nav-bar",on:{"click":_vm.goBack}},[_c('div',{staticClass:"nav-bar__inner"},[_vm._m(0),_c('h3',{staticClass:"nav-bar__title"},[_vm._v(_vm._s(_vm.name))])])]),_c('div',{staticClass:"main-content"},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passes = ref.passes;
var invalid = ref.invalid;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return passes(_vm.onSubmit)}}},[_c('div',{staticClass:"ftth-order"},[_c('div',{staticClass:"ftth-order__form"},[_c('div',{staticClass:"form-customize form-customize--disabled"},[_c('input',{staticClass:"form-control",attrs:{"type":"text","disabled":"disabled"},domProps:{"value":_vm.fullName}})]),_c('div',{staticClass:"form-customize"},[_c('Input',{attrs:{"type":"number","label":_vm.$t('mobile.recharge_scratch_card.phone_number'),"placeholder":_vm.$t('mobile.recharge_scratch_card.enter_phone_number'),"rules":"required|phone"},model:{value:(_vm.phoneNumber),callback:function ($$v) {_vm.phoneNumber=$$v},expression:"phoneNumber"}})],1),_c('div',{staticClass:"form-customize"},[_c('ValidationProvider',{attrs:{"tag":"div","rules":"required","name":_vm.$t('ftth.ftth_order.province')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"label-title"},[_vm._v(_vm._s(_vm.$t('ftth.ftth_order.province')))]),_c('div',{staticClass:"select-box js-select-box"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.provinceId),expression:"provinceId"}],staticClass:"js-custom-select",attrs:{"id":_vm.$t('ftth.ftth_order.province'),"data-placeholder":_vm.$t('ftth.ftth_order.select_province')},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.provinceId=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[_c('option',{attrs:{"value":""}}),_vm._l((_vm.provinces),function(province){return _c('option',{key:province.name,domProps:{"value":province.id}},[_vm._v(" "+_vm._s(province.name)+" ")])})],2)]),(errors[0])?_c('p',{staticClass:"error-text"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1),_c('div',{staticClass:"form-customize"},[_c('ValidationProvider',{attrs:{"tag":"div","rules":"required","name":_vm.$t('ftth.ftth_order.district')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"label-title"},[_vm._v(_vm._s(_vm.$t('ftth.ftth_order.district')))]),_c('div',{staticClass:"select-box js-select-box"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.districtId),expression:"districtId"}],staticClass:"js-custom-select",attrs:{"id":_vm.$t('ftth.ftth_order.district'),"data-placeholder":_vm.$t('ftth.ftth_order.select_district')},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.districtId=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[_c('option',{attrs:{"value":""}}),_vm._l((_vm.districts),function(district){return _c('option',{key:district.name,domProps:{"value":district.id}},[_vm._v(" "+_vm._s(district.name)+" ")])})],2)]),(errors[0])?_c('p',{staticClass:"error-text"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1),_c('div',{staticClass:"form-customize"},[_c('ValidationProvider',{attrs:{"tag":"div","rules":"required","name":_vm.$t('ftth.ftth_order.precinct')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"label-title"},[_vm._v(_vm._s(_vm.$t('ftth.ftth_order.precinct')))]),_c('div',{staticClass:"select-box js-select-box"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.precinctId),expression:"precinctId"}],staticClass:"js-custom-select",attrs:{"id":_vm.$t('ftth.ftth_order.precinct'),"data-placeholder":_vm.$t('ftth.ftth_order.select_precinct')},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.precinctId=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[_c('option',{attrs:{"value":""}}),_vm._l((_vm.precincts),function(precinct){return _c('option',{key:precinct.name,domProps:{"value":precinct.id}},[_vm._v(" "+_vm._s(precinct.name)+" ")])})],2)]),(errors[0])?_c('p',{staticClass:"error-text"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1),_c('div',{staticClass:"form-customize"},[_c('label',{staticClass:"label-title"},[_vm._v(" "+_vm._s(_vm.$t('ftth.ftth_order.note'))+" ")]),_c('input',{staticClass:"form-control",attrs:{"type":"text","readonly":""},domProps:{"value":_vm.note}})])]),_c('div',{staticClass:"ftth-order__btn"},[_c('button',{staticClass:"button button--primary button--full",on:{"click":function($event){return _vm.onOrder(invalid)}}},[_vm._v(" "+_vm._s(_vm.$t('ftth.ftth_package.order'))+" ")])])])])]}}])})],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"nav-bar__icon"},[_c('i',{staticClass:"icon-fonts icon-fonts--arrow-left"})])}]

export { render, staticRenderFns }