<template>
   <div class="main-container">
      <div class="nav-bar js-nav-bar" @click="goBack">
         <div class="nav-bar__inner">
            <span class="nav-bar__icon"><i class="icon-fonts icon-fonts--arrow-left"></i></span>
            <h3 class="nav-bar__title">{{ $t('ucare.gift_deals.gift_detail') }}</h3>
         </div>
      </div>
      <div class="main-content">
         <div class="gift-confirm">
            <div class="exchange-point">
               <h4 class="exchange-point__name">{{ $t('ucare.gift_deals.available_upoint') }}</h4>
               <span class="exchange-point__value">{{
                  Number(exchangePoint).toLocaleString('en-US', { maximumFractionDigits: 0 })
               }}</span>
            </div>
            <div class="gift-confirm__content">
               <!-- <div class="gift-confirm__images"><img :src="gift.avatar" alt="img" /></div>
               <div class="gift-confirm__top">
                  <h4 class="gift-confirm__sub">{{ gift.giftName }}</h4>
                  <p class="gift-confirm__des">
                     {{ $t('ucare.gift_deals.exchange_by') }}
                     {{ Number(gift.giftPoint*amount).toLocaleString('en-US', { maximumFractionDigits: 0 }) }}
                     {{ $t('unit.upoint') }}
                  </p>
               </div> -->
               <div class="data-deals__top">
                  <div class="data-deals__images"><img :src="gift.avatar" alt="img" /></div>
                  <div class="data-deals__detail">
                     <h5 class="data-deals__name">{{ gift.giftName }}</h5>
                     <div class="data-deals__price">
                        <span class="data-deals__icon"><i class="icon-fonts icon-fonts--price"></i></span
                        ><span class="data-deals__value">
                           <!-- {{ $t('ucare.gift_deals.exchange_by') }} -->
                           {{ Number(gift.giftPoint * amount).toLocaleString('en-US', { maximumFractionDigits: 0 }) }}
                           {{ $t('unit.upoint') }}
                        </span>
                     </div>
                  </div>
               </div>
               <div class="gift-confirm__location">
                  <h4 class="gift-confirm__sub">{{ $t('ucare.gift_deals.pickup_location') }}</h4>
                  <div class="gift-confirm__form">
                     <div class="form-2column">
                        <div class="form-customize">
                           <div class="select-box js-select-box">
                              <select
                                 class="js-custom-select"
                                 :data-placeholder="$t('ucare.gift_deals.select_province')"
                                 v-model="provinceId"
                              >
                                 <option value=""></option>
                                 <option :value="province.id" v-for="province in provinces" :key="province.name">
                                    {{ province.name }}
                                 </option>
                              </select>
                           </div>
                        </div>
                        <div class="form-customize">
                           <div class="select-box js-select-box">
                              <select
                                 class="js-custom-select"
                                 :data-placeholder="$t('ucare.gift_deals.select_district')"
                                 v-model="districtId"
                              >
                                 <option value=""></option>
                                 <option :value="district.id" v-for="district in districts" :key="district.name">
                                    {{ district.name }}
                                 </option>
                              </select>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <ul class="find-store__list">
                  <li
                     :class="[
                        { 'find-store__item': true },
                        { selected: selectedStore && store.name === selectedStore.name }
                     ]"
                     v-for="store in storesList"
                     :key="store.name"
                     @click="setSelectedStore(store)"
                  >
                     <div class="find-store__detail">
                        <div class="find-store__images"><img src="@/assets/images/ic-store.svg" alt="img" /></div>
                        <div class="find-store__info">
                           <h5 class="find-store__name">{{ store.name }}</h5>
                           <p class="find-store__des">{{ store.addr }}</p>
                        </div>
                     </div>
                     <div class="find-store__location">
                        <span class="find-store__icon"><img src="@/assets/images/ic-location.svg" alt="img" /></span
                        ><span class="find-store__value"
                           >{{ Number(store.distance).toLocaleString('en-US', { maximumFractionDigits: 0 }) }}
                           {{ $t('unit.km') }}</span
                        >
                     </div>
                  </li>
               </ul>
               <div class="gift-confirm__btn">
                  <button
                     class="button button--primary button--full"
                     :disabled="selectedStore === null"
                     @click="exchange"
                  >
                     {{ $t('buttons.confirm') }}
                  </button>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>
<script>
import BaseComponent from '@/mixins/BaseComponent'
import service from '../../services/UCareService'
import LocalStorage from '@/ultils/local-storage'
import moment from 'moment'

export default {
   name: 'Confirmation',
   mixins: [BaseComponent],
   data() {
      return {
         exchangePoint: 0,
         gift: {},
         amount: this.$route.params.amount,
         provinces: [],
         districts: [],
         provinceId: null,
         districtId: null,
         storesList: [],
         selectedStore: null
      }
   },
   created() {
      this.setTitle(this.$i18n.t('ucare.gift_deals.gift_detail'))
      let data
      if (this.$route.params.type == 1) {
         data = JSON.parse(LocalStorage.getItem('gift_deal')) ?? {}
      } else {
         data = JSON.parse(LocalStorage.getItem('partner_deal')) ?? {}
      }
      this.gift = data.gift ?? {}
      this.exchangePoint = data.exchangePoint
      this.loadProvinces()
   },
   methods: {
      loadProvinces() {
         this.showLoading()
         service
            .getProvinces()
            .then((response) => {
               if (response.errorCode === '0') {
                  this.provinces = response.wsResponse
                  this.loadScript('js/select2.js')
               } else {
                  this.$store.dispatch('setNotification', { content: response.message ?? response.userMsg })
               }
            })
            .finally(() => {
               this.hideLoading()
            })
      },
      loadDistrictsByProvinceId() {
         this.showLoading()
         service
            .getDistricts({ provinceId: this.provinceId })
            .then((response) => {
               if (response.errorCode === '0') {
                  this.districts = response.wsResponse
                  this.loadScript('js/select2.js')
               } else {
                  this.$store.dispatch('setNotification', { content: response.message ?? response.userMsg })
               }
            })
            .finally(() => {
               this.hideLoading()
            })
      },
      loadStoresByArea() {
         this.showLoading()
         service
            .findStoreByAddr({
               provinceId: this.provinceId,
               districtId: this.districtId,
               latitude: 999,
               longitude: 999
            })
            .then((response) => {
               if (response.errorCode === '0') {
                  this.storesList = response.wsResponse ?? []
               } else {
                  this.$store.dispatch('setNotification', { content: response.message ?? response.userMsg })
               }
            })
            .finally(() => {
               this.hideLoading()
            })
      },
      setSelectedStore(store) {
         this.selectedStore = store
      },
      exchange() {
         this.showLoading()
         let userInfo = JSON.parse(LocalStorage.getItem('user_info'))
         let ucareFtth = LocalStorage.getItem('click_ucare_ftth')
         let data = {
            giftId: this.gift.giftId,
            receivePhone: userInfo ? userInfo['isdn'] : '',
            districtId: this.districtId ?? '',
            receiveAddress: this.selectedStore.addr,
            receiveDate: moment().format('yyyy-MM-DD HH:mm:ss'),
            cityId: this.provinceId,
            villageId: '',
            channel: 'MyUnitel',
            amount: this.$route.params.amount,
            accountType: ucareFtth ? '1' : '0'
         }
         service
            .redeemGiftAndReturnExchangeCode(data)
            .then((response) => {
               if (response.errorCode === '0') {
                  this.$store.dispatch('setNotification', {
                     title: this.$i18n.t('ucare.gift_deals.success'),
                     icon: './images/ic-success.svg',
                     content: this.$i18n
                        .t('ucare.gift_deals.success_notice')
                        .replace('{0}', this.gift.giftPoint * this.$route.params.amount)
                        .replace('{1}', this.gift.giftName)
                  })
               } else {
                  this.$store.dispatch('setNotification', {
                     title: this.$i18n.t('ucare.gift_deals.fail'),
                     content: response.message ?? response.userMsg
                  })
               }
            })
            .finally(() => {
               this.hideLoading()
            })
      }
   },
   watch: {
      provinceId() {
         this.districtId = null
         this.selectedStore = null
         this.loadStoresByArea()
         this.loadDistrictsByProvinceId()
      },
      districtId() {
         this.selectedStore = null
         this.loadStoresByArea()
      }
   }
}
</script>
