<template>
  <div class="main-container">
        <div class="nav-bar js-nav-bar">
          <div class="nav-bar__inner"><span class="nav-bar__icon" @click="goBack"><i class="icon-fonts icon-fonts--arrow-left"></i></span>
            <h3 class="nav-bar__title">{{$t('ftth.error_report.button1')}}</h3>
          </div>
        </div>
        <div class="main-content">
            <ValidationObserver v-slot="{passes}">
                <form @submit.prevent="passes(onSubmit)">
                    <div class="change-product">
                        <div class="change-product__content">
                        <div class="change-product__box">
                            <div class="change-product__top">
                            <h5 class="change-product__sub">{{$t('ftth.error_report.account_information')}}</h5>
                            </div>
                            <ul class="change-product__list">
                            <li class="change-product__item"><span class="change-product__name">{{$t('ftth.error_report.account_name')}}</span><span class="change-product__value">{{accountNo}}</span></li>
                            <li class="change-product__item"><span class="change-product__name">{{$t('ftth.error_report.contact_number')}}</span><span class="change-product__value">{{isdn}}</span></li>
                            </ul>
                        </div>
                        <div class="change-product__box">
                            <div class="change-product__top">
                            <h5 class="change-product__sub">{{$t('ftth.error_report.error_description')}}</h5>
                            </div>
                            <div class="change-product__form">
                                <div class="form-customize">
                                    <!-- <div class="select-box js-select-box">
                                        <Select :label="$t('ftth.error_report.select_category')"
                                            :placeholder="$t('ftth.error_report.select_category')"
                                            rules="required"
                                            :dataSelect="lstCategory"
                                            :isLabel="false"
                                            v-model="create_error_model.categoryCode" />
                                    </div> -->
                                    <ValidationProvider
                                        tag="div"
                                        rules="required"
                                        :name="$t('ftth.error_report.select_category')"
                                        v-slot="{ errors }"
                                    >
                                        <label class="label-title">{{ $t('ftth.error_report.select_category') }}</label>
                                        <div class="select-box js-select-box">
                                            <select
                                                class="js-custom-select"
                                                :id="$t('ftth.error_report.select_category')"
                                                :data-placeholder="$t('ftth.error_report.select_category')"
                                                v-model="create_error_model.categoryCode"
                                            >
                                                <option value=""></option>
                                                <option :value="category.code" v-for="category in lstCategory" :key="category.code">
                                                {{ category.name }}
                                                </option>
                                            </select>
                                        </div>
                                        <p class="error-text" v-if="errors[0]">{{ errors[0] }}</p>
                                    </ValidationProvider>
                                </div>
                                <div class="form-customize">
                                    <!-- <Select :label="$t('ftth.error_report.select_type_of_error')"
                                        :placeholder="$t('ftth.error_report.select_type_of_error')"
                                        rules="required"
                                        :dataSelect="lstTypeOfError"
                                        :isLabel="false"
                                        v-model="create_error_model.compTypeId" /> -->
                                        
                                    <ValidationProvider
                                        tag="div"
                                        rules="required"
                                        :name="$t('ftth.error_report.select_type_of_error')"
                                        v-slot="{ errors }"
                                    >
                                        <label class="label-title">{{ $t('ftth.error_report.select_type_of_error') }}</label>
                                        <div class="select-box js-select-box">
                                            <select
                                                class="js-custom-select"
                                                :id="$t('ftth.error_report.select_type_of_error')"
                                                :data-placeholder="$t('ftth.error_report.select_type_of_error')"
                                                v-model="create_error_model.compTypeId"
                                            >
                                                <option value=""></option>
                                                <option :value="compType.code" v-for="compType in lstTypeOfError" :key="compType.code">
                                                {{ compType.name }}
                                                </option>
                                            </select>
                                        </div>
                                        <p class="error-text" v-if="errors[0]">{{ errors[0] }}</p>
                                    </ValidationProvider>
                                </div>
                                <div class="form-customize">
                                    <Input
                                        type="textarea"
                                        :label="$t('ftth.error_report.decriptions')"
                                        :placeholder="$t('ftth.error_report.decriptions')"
                                        :isLabel="false"
                                        rules="required"
                                        :maxlength="500"
                                        v-model="create_error_model.compContent"
                                    />
                                </div>
                            </div>
                        </div>
                        </div>
                        <div class="change-product__btn">
                        <button class="button button--primary button--full">{{$t('ftth.error_report.submit')}}
                        </button>
                        </div>
                    </div>
                </form>
            </ValidationObserver>
        </div>
      </div>
</template>
<script>
import BaseComponent from '@/mixins/BaseComponent'
import service from './../../services/FTTHService'
import store from '@/plugins/vuex/store'
import { ValidationObserver } from 'vee-validate'
import Input from '@/components/form/Input'
// import Select from '@/components/form/Select'
import LocalStorage from '@/ultils/local-storage'

export default {
   name: 'CreateNewErrorReport',
   mixins: [BaseComponent],
   components: {
    Input,
    // Select,
    ValidationObserver
   },
   data() {
      return {
            create_error_model:{
                categoryCode:null,
                compContent:null,
                compTypeId:null,
                complainerPhone:null

            },
            accountNo:"",
            isdn:"",
            lstCategory:[],
            lstTypeOfError:[]

      }
   },
   async created() {
      let ftthAccountInfo = JSON.parse(LocalStorage.getItem('ftth_account_info'))
      this.accountNo = ftthAccountInfo.accountNo
      this.isdn = ftthAccountInfo.isdn
      await this.getData()
      this.loadScript('js/select2.js')
   },
   watch: {
      async 'create_error_model.categoryCode'(val){
        if(val && val != null){
            let dataPost = {
                compGroupId:parseInt(val)
            }
            await service
                .getComplainTypeByGroupId(dataPost)
                .then((response) => {
                if (response.errorCode === '0') {
                    this.lstTypeOfError = response.wsResponse.map(x=>({ code: x.compTypeId.toString(), name: x.compTypeName }))
                    this.create_error_model.compTypeId = null
                    this.loadScript('js/select2.js')
                }
                }).finally(() => {
                    this.hideLoading()
                })
        }
        
      }
   },
   methods: {
        async getData() {
            this.showLoading()
            // let ftthAccountInfo = JSON.parse(LocalStorage.getItem('ftth_user_info')) 
            // let account = ftthAccountInfo.isdn 
            // let userInfo = JSON.parse(LocalStorage.getItem('user_info')) 
            // let isdn = userInfo.isdn          
            await service
                .getComplainGroup()
                .then((response) => {
                if (response.errorCode === '0') {

                    this.lstCategory = response.wsResponse.map(x=>({ code: x.compGroupId.toString(), name: x.compGroupName }))
                    
                    console.log('this.lstCategory',this.lstCategory)
                    this.loadScript('js/select2.js')

                }
                }).finally(() => {
                    this.loadScript('js/select2.js')
                    this.hideLoading()
                })
        },
        async onSubmit(){
            this.showLoading()
            let userInfo = JSON.parse(LocalStorage.getItem('user_info'))     
            let dataPost = {...this.create_error_model}
            dataPost.ftthAccount = this.accountNo
            dataPost.complainerPhone = userInfo ? userInfo.isdn : this.accountNo
            delete dataPost.categoryCode

            await service
                .ftthAccountComplain(dataPost)
                .then((response) => {
                if (response.errorCode === '0') {
                    this.hideLoading()
                    store.dispatch('setNotification', {
                        content: response.userMsg,
                        icon:"./images/ic-success.svg"
                    })
                    // this.lstTypeOfError = []
                    // this.create_error_model = {
                    //     categoryCode:null,
                    //     compContent:null,
                    //     compTypeId:null,
                    //     complainerPhone:null,

                    // }
                    this.loadScript('js/select2.js')
                }
                else{
                    this.loadScript('js/select2.js')
                    this.hideLoading()
                    store.dispatch('setNotification', {
                        content: response.userMsg
                    })
                }
                }).finally(() => {
                    this.loadScript('js/select2.js')
                    this.hideLoading()
                })
                
        }
   }
}
</script>
