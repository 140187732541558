import CustomerService from '@/modules/ucare/components/customer-service/CustomerService'
import Introduction from '@/modules/ucare/components/customer-service/Introduction'
import DataDeals from '@/modules/ucare/components/data-deals/DataDeals'
import ServiceDeals from '@/modules/ucare/components/service-deals/ServiceDeals'
import DataDealDetail from '@/modules/ucare/components/data-deals/Detail'
import GiftDeals from '@/modules/ucare/components/gift-deals/GiftDeals'
import GiftDealDetail from '@/modules/ucare/components/gift-deals/Detail'
import GiftDealConfirm from '@/modules/ucare/components/gift-deals/Confirmation'
import PackageDeals from '@/modules/ucare/components/package-deals/PackageDeals'
import PackageDealDetail from '@/modules/ucare/components/package-deals/Detail'
import PartnerDeals from '@/modules/ucare/components/partner-deals/PartnerDeals'
import PartnerDealDetail from '@/modules/ucare/components/partner-deals/Detail'
import PointHistory from '@/modules/ucare/components/point-history/PointHistory'
import MemberInfo from '@/modules/ucare/components/member-info/MemberInfo'

export default [
   {
      path: '/ucare/customer-service/home',
      component: CustomerService
   },
   {
      path: '/ucare/customer-service/intro',
      component: Introduction
   },
   {
      path: '/ucare/data-deals',
      component: DataDeals
   },
   {
      path: '/ucare/service-deals',
      component: ServiceDeals
   },
   {
      path: '/ucare/data-deals/detail',
      component: DataDealDetail
   },
   {
      path: '/ucare/package-deals',
      component: PackageDeals
   },
   {
      path: '/ucare/package-deals/detail',
      component: PackageDealDetail
   },
   {
      path: '/ucare/gift-deals',
      component: GiftDeals
   },
   {
      path: '/ucare/gift-deals/detail',
      component: GiftDealDetail
   },
   {
      path: '/ucare/gift-deals/confirm/:type/:amount',
      component: GiftDealConfirm
   },
   {
      path: '/ucare/partner-deals',
      component: PartnerDeals
   },
   {
      path: '/ucare/partner-deals/detail',
      component: PartnerDealDetail
   },
   {
      path: '/ucare/point-history',
      component: PointHistory
   },
   {
      path: '/ucare/member-info',
      component: MemberInfo
   }
]
