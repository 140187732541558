<template>
    <div class="main-container">
        <div class="nav-bar js-nav-bar">
            <div class="nav-bar__inner">
                <span class="nav-bar__icon"  @click="goBack"><i class="icon-fonts icon-fonts--arrow-left"></i></span>
                <h3 class="nav-bar__title">{{ $t('mobile.change_to_postpaid.package_detail') }}</h3>
            </div>
        </div>
        <div class="main-content">
            <div class="change-postpaid">
                <div class="request-success">
                    <h3 class="request-success__title">{{ $t('mobile.change_to_postpaid.request_successfully') }}</h3>
                    <p class="request-success__des">{{ $t('mobile.change_to_postpaid.notification') }}</p>
                    <div class="request-success__content">
                        <div class="request-success__box">
                            <div class="request-success__top">
                                <h5 class="request-success__sub">{{ $t('mobile.change_to_postpaid.order_information') }}</h5>
                            </div>
                            <ul class="request-success__list">
                                <li class="request-success__item"><span class="request-success__name">{{ $t('mobile.change_to_postpaid.order_code') }}</span><span class="request-success__value">4EVER</span></li>
                                <li class="request-success__item"><span class="request-success__name">{{ $t('mobile.change_to_postpaid.date_of_order') }}</span><span class="request-success__value">30/06/2022</span></li>
                                <li class="request-success__item"><span class="request-success__name">{{ $t('mobile.change_to_postpaid.status') }}</span><span class="request-success__value">waiting for sale</span></li>
                            </ul>
                        </div>
                    </div>
                    <div class="request-success__btn">
                        <button class="button button--primary button--full">
                            {{ $t('mobile.change_to_postpaid.back_to_homepage') }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import BaseComponent from '@/mixins/BaseComponent'

    export default {
        name: 'ChangeInfo',
        mixins: [BaseComponent],
        components: {
        },
        data() {
            return {
                data: {},
                packageDetail: { productCode: "", description: ""},
                description: ""
            }
        },
        created() {
            this.setTitle(this.$i18n.t('mobile.change_to_postpaid.package_detail'))
        },
        methods: {
            redirectStep() {
                this.$router.push('/mobile/change_to_postpaid/customer-info')
            }
        }
    }
</script>
