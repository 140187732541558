<template>
   <div class="main-container">
      <div class="nav-bar js-nav-bar" @click="goBack">
         <div class="nav-bar__inner">
            <span class="nav-bar__icon"><i class="icon-fonts icon-fonts--arrow-left"></i></span>
            <h3 class="nav-bar__title">{{ $t('ucare.package_deals.charge_deals') }}</h3>
         </div>
      </div>
      <div class="main-content">
         <div class="data-deals">
            <div class="exchange-point">
               <h4 class="exchange-point__name">{{ $t('ucare.exchange_point') }}</h4>
               <span class="exchange-point__value">{{
                  Number(exchangePoint).toLocaleString('en-US', { maximumFractionDigits: 0 })
               }}</span>
            </div>
            <div class="package-detail__content">
               <div class="data-deals__top">
                  <div class="data-deals__images">
                     <img :src="'data:image/jpeg;base64,' + gift.imageBase64" alt="img" />
                  </div>
                  <!-- <div class="package-detail__info">
                     <h5 class="package-detail__name">{{ gift.giftName }}</h5>
                     <p class="package-detail__value">
                        {{ Number(gift.point).toLocaleString('en-US', { maximumFractionDigits: 0 }) }}
                        {{ $t('unit.upoint') }}
                     </p>
                     <p class="package-detail__value">{{ gift.description }}</p>
                  </div> -->
                  <div class="data-deals__detail">
                     <h5 class="data-deals__name">{{ gift.giftName }}</h5>
                  <div class="data-deals__price"><span class="data-deals__icon">
                     <i class="icon-fonts icon-fonts--price"></i></span>
                     <span class="data-deals__value">
                        {{ Number(gift.point).toLocaleString('en-US', { maximumFractionDigits: 0 }) }}
                        {{ $t('unit.upoint') }}
                     </span>
                  </div>
                </div>
               </div>
               <div class="package-detail__info">
                  <p class="package-detail__value">{{ gift.description }}</p>
               </div>
               <!-- <ul class="amount-total">
                  <li class="amount-total__item"><span class="amount-total__name">{{$t("ucare.amount")}}</span>
                     <div class="amount-total__info">
                        <button class="button-qty button-minus" title="Minus"><i class="icon-fonts icon-fonts--minus" @click="decreaseAmount"></i>
                        </button>
                        <input class="form-qty" type="number" step="1" min="1" :max="max" name="quantity" value="1" title="Qty" readonly v-model="amount">
                        <button class="button-qty button-plus" title="Plus"><i class="icon-fonts icon-fonts--add" @click="increaseAmount"></i>
                        </button>
                     </div>
                  </li>
                  <li class="amount-total__item"><span class="amount-total__name">{{$t("ucare.total_upoint")}}</span><span class="amount-total__value">
                     {{ Number(total).toLocaleString('en-US', { maximumFractionDigits: 0 }) }}
                     {{ $t('unit.upoint') }}</span>
                  </li>
                  <li class="amount-total__item" v-if="userFtth"><span class="amount-total__name">{{$t('mobile.ishare.receive_number')}}</span> <span style="color: red;">(*)</span>
                      <div class="amount-total__form" v-if="userFtth">
                        <input class="form-control" type="text" pattern="\d*" :placeholder="$t('mobile.ishare.receive_number')" rules="required|phone" v-model="receivePhone" maxlength="13">
                      </div>
                  </li>
               </ul> -->
               <div class="package-detail__btn">
                  <button class="button button--primary button--full" :disabled="max === 0 || max < amount" @click="openConfirmExchange">
                     {{ $t('ucare.exchange') }}
                  </button>
               </div>
            </div>
         </div>
      </div>
      <confirmation-modal :data="confirmationData" :onConfirm="onConfirm" />
   </div>
</template>
<script>
import BaseComponent from '@/mixins/BaseComponent'
import service from '../../services/UCareService'
import LocalStorage from '@/ultils/local-storage'
import ConfirmationModal from '@/components/modal/ConfirmationModal'

export default {
   name: 'Detail',
   components: {
      ConfirmationModal
   },
   mixins: [BaseComponent],
   data() {
      return {
         exchangePoint: 0,
         gift: {
            giftName: '',
            giftCode: '',
            imageBase64: '',
            point: 0
         },
         amount: 1,
         total: 0,
         max: 0,
         confirmationData: {},
         userFtth: false,
         receivePhone:''
      }
   },
   created() {
      this.setTitle(this.$i18n.t('ucare.package_deals.title'))
      let data = JSON.parse(LocalStorage.getItem('package_deal')) ?? {}
      this.gift = data.gift ?? {}
      this.exchangePoint = data.exchangePoint
      this.max = this.gift.point !== 0 ? Math.floor(this.exchangePoint/this.gift.point) : 0
      if(this.max === 0) this.amount = 0
      this.total = this.amount*this.gift.point
      this.userFtth = LocalStorage.getItem('click_ucare_ftth')
   },
   methods: {
      decreaseAmount(){
         if(this.amount > 1){
            this.amount -= 1
            this.total = this.amount*this.gift.point
         }
      },
      increaseAmount(){
         if(this.amount < this.max){
            this.amount += 1
            this.total = this.amount*this.gift.point
         } else {
            this.$store.dispatch('setNotification', {
                  title: this.$i18n.t('notification.title'),
                  content: this.$i18n.t('ucare.data_deals.point_not_enough'),
                  closeButton: this.$i18n.t('buttons.close')
               })
         }
      },
      openConfirmExchange() {
         // validate
         if(this.userFtth && this.receivePhone==''){
            this.$store.dispatch('setNotification', {
                  title: this.$i18n.t('notification.title'),
                  content: this.$i18n.t('ucare.data_deals.required_phone'),
                  closeButton: this.$i18n.t('buttons.close')
               })
         } else {
         this.confirmationData = {
            content: this.$i18n
               .t('ucare.data_deals.confirm')
               .replace('{0}', this.gift.giftName)
               .replace('{1}', Number(this.gift.point * this.amount).toLocaleString('en-US', { maximumFractionDigits: 0 }))
         }
         }
      },
      onConfirm() {
         let userInfo = JSON.parse(LocalStorage.getItem('user_info')) ?? {}
         let data = {
            giftId: this.gift.giftId.toString(),
            pointAmount: this.gift.point,
            pointId: this.gift.giftId.toString(),
            amountGift: this.gift.giftCost,
            datatype: this.gift.giftCode,
            transferType: 'POINT',
            staffUser: userInfo['isdn'],
            amount: this.amount,
            receivePhone: this.receivePhone
         }
         this.showLoading()
         service
            .redeemPoint(data)
            .then((response) => {
               if (response.errorCode === '0') {
                  this.$store.dispatch('setNotification', {
                     icon: './images/ic-success.svg',
                     content: this.$i18n
                        .t('ucare.exchange_message')
                        .replace('{0}', this.gift.giftName)
                        .replace('{1}', Number(this.gift.point * this.amount).toLocaleString('en-US', { maximumFractionDigits: 0 }))
                  })
               } else {
                  this.$store.dispatch('setNotification', { content: response.message ?? response.userMsg })
               }
            })
            .finally(() => {
               this.hideLoading()
            })
      }
   }
}
</script>
