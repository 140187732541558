<template>
   <div class="main-container">
      <div class="nav-bar js-nav-bar" @click="goBack">
         <div class="nav-bar__inner">
            <span class="nav-bar__icon"><i class="icon-fonts icon-fonts--arrow-left"></i></span>
            <h3 class="nav-bar__title">{{ $t('mobile.change_simcard.change_simcard') }}</h3>
         </div>
      </div>
      <div class="main-content">
         <div class="change-product">
            <div class="change-product__content">
              <div class="change-status">
                <ul class="change-status__list">
                  <li class="change-status__item total">
                    <div class="change-status__head">
                      <h5 class="change-status__name">{{ $t('mobile.change_scratch_card.All') }}</h5>
                    </div>
                    <div class="change-status__info"><span class="change-status__value">{{lstChangeSim.length}}</span></div>
                  </li>
                  <li class="change-status__item process">
                    <div class="change-status__head">
                      <h5 class="change-status__name">{{ $t('mobile.change_scratch_card.In_Process') }}</h5>
                    </div>
                    <div class="change-status__info"><span class="change-status__value">{{lstChangeSim.filter(x => x.statusId == 0).length}}</span></div>
                  </li>
                  <li class="change-status__item success">
                    <div class="change-status__head">
                      <h5 class="change-status__name">{{ $t('mobile.change_scratch_card.Complete') }}</h5>
                    </div>
                    <div class="change-status__info"><span class="change-status__value">{{lstChangeSim.filter(x => x.statusId == 1).length}}</span></div>
                  </li>
                  <!-- <li class="change-status__item error">
                    <div class="change-status__head">
                      <h5 class="change-status__name">{{ $t('mobile.change_scratch_card.Complete') }}</h5>
                    </div>
                    <div class="change-status__info"><span class="change-status__value">{{lstChangeSim.filter(x => x.statusId == 2).length}}</span></div>
                  </li> -->
                </ul>
              </div>
              <template>
                  <div v-for="item in lstChangeSim" :key="item.orderId" :class="item.statusId==0?['change-product__box','process']:item.statusId==1?['change-product__box','success']:['change-product__box','error']">
                     <div class="change-product__top">
                        <h5 class="change-product__sub">{{ $t('mobile.change_scratch_card.request_information') }}</h5>
                     </div>
                     <ul class="change-product__list">
                         <li class="change-product__item"><span class="change-product__name">{{ $t('mobile.change_scratch_card.order_code') }}</span><span class="change-product__value">{{item.orderId}}</span></li>
                        <li class="change-product__item"><span class="change-product__name">{{ $t('mobile.change_scratch_card.date_of_request') }}</span><span class="change-product__value">{{getUpdateDate(item.createDate)}}</span></li>
                        <li class="change-product__item"><span class="change-product__name">{{ $t('mobile.change_scratch_card.status') }}</span><span :class="item.statusId == 0? ['change-product__value','process']:item.statusId == 1?['change-product__value','success']:['change-product__value','error']">{{item.status}}</span></li>
                     </ul>
                  </div>
              </template>
              <!-- <div class="change-product__box success">
                <div class="change-product__top">
                  <h5 class="change-product__sub">Request Information</h5>
                </div>
                <ul class="change-product__list">
                  <li class="change-product__item"><span class="change-product__name">Order code</span><span class="change-product__value">4EVER</span></li>
                  <li class="change-product__item"><span class="change-product__name">Date of request</span><span class="change-product__value">20:30 30/06/2022</span></li>
                  <li class="change-product__item"><span class="change-product__name">Status</span><span class="change-product__value success">Complete</span></li>
                </ul>
              </div> -->
            </div>
            <div class="change-product__btn">
              <button class="button button--primary button--full" @click="redirectToDetail">{{ $t('mobile.change_simcard.create_new_request') }}
              </button>
            </div>
          </div>
      </div>
   </div>
</template>
<script>
import BaseComponent from '@/mixins/BaseComponent'
import service from './../../services/MobileService'
import LocalStorage from '@/ultils/local-storage'
import moment from 'moment'
import store from '@/plugins/vuex/store'
export default {
   name: 'ChangeSimCard',
   components: {
   },
   mixins: [BaseComponent],
   data() {
      return {
         lstChangeSim:[]
      }
   },
   mounted() {
      this.setTitle(this.$i18n.t('mobile.change_simcard.change_simcard'))
      this.lstChangeSim = store.state.change_simcard.step1.lstChangeSim
      if(this.lstChangeSim && this.lstChangeSim.length == 0){
         this.loadData()
      }
   },
   methods: {
      loadData() {
         this.showLoading()
         let dataPost = {
            "locate": LocalStorage.getItem('lang')
         }
         service
            .getInfoChangeSim(dataPost)
            .then((response) => {
               if (response.errorCode !== '1') {
                  let result = response.orders ?? []
                  this.lstChangeSim = result
               } 
            })
            .finally(() => {
               this.hideLoading()
            })
      },
      redirectToDetail() {
         let payload = {
               step:"step1",
               value:{
                  "lstChangeSim":this.lstChangeSim
               }
         }
         store.commit('setChangeSimcard', payload)
         this.$router.push('/mobile/create-new-request')
      },
      getUpdateDate(timestamp){
         if(timestamp){
            var date = new Date(timestamp)
            var _date = moment(date).format("hh:mm DD/MM/YYYY");
            return _date 
         }       
      },
   }
}
</script>
