<template>
   <div class="main-container">
      <!--<div class="nav-bar nav-bar--acc">
         <div class="nav-bar__inner" @click="goBack">
             <span class="nav-bar__icon"><i class="icon-fonts icon-fonts--arrow-left"></i></span> 
            <div class="nav-info">
               <p class="nav-info__des">{{ getHelloTitle() }}</p>
               <h5 class="nav-info__sub">{{ $t('home.login.title') }}</h5>
            </div>
         </div>
      </div>
      <div class="acc-auth">
         <div class="box-auth">
            <div class="box-auth__logo">
               <a class="box-auth__logo-link" href="javascript:void(0)"
                  ><img src="@/assets/images/logo.svg" alt="logo"
               /></a>
            </div>
            <div class="box-auth__2btn">
               <button class="button button--normal" @click="signUp">{{ $t('buttons.signup') }}</button
               ><button
                  class="button button--primary"
                  data-bs-toggle="modal"
                  data-bs-target="#signIn"
                  @click="resetData"
               >
                  {{ $t('buttons.signin') }}
               </button>
            </div>
         </div>
      </div> -->
      <div class="nav-bar">
          <div class="nav-home none">
            <div class="nav-home__btn">
              <button class="button button--transparent" 
                  data-bs-target="#signIn"
                  data-bs-toggle="modal"
                  >{{ $t('buttons.signin') }}
              </button>
            </div>
            <div class="nav-home__logo"><a class="nav-home__logo-link" href="#"><img src="@/assets/images/logo-new.svg" alt="img"></a></div>
          </div>
      </div>
      <div class="feature-main">
          <div class="feature-main__top">
            <h4 class="feature-main__sub">{{ $t('home.main_functions') }}</h4>
          </div>
          <ul class="feature-main__list">
            <li class="feature-main__item" v-for="favourite in favouriteList" :key="favourite.name"><a class="feature-main__link" @click="redirectToFunc(favourite)"><span class="feature-main__icon"><img :src="favourite.icon" alt="img"></span>
                <h5 class="feature-main__name">{{ favourite.name }}</h5>
              </a>
            </li>
          </ul>
      </div>
      <div class="banner-slider" v-if="lstslideShow && lstslideShow.length !=0">
         <ul class="banner-slider__list js-slider-col1">
            <li class="banner-slider__item" v-for="(banner,index) in lstslideShow[0].images" :key="index">
               <a class="service-pack__link" :href="banner.link">
                        <img :src="banner.url" alt="img" />
               </a>
            </li>
         </ul>
      </div>
      <div class="feature-basic">
         <div class="feature-basic__top">
            <h4 class="feature-basic__sub">{{ $t('home.main_services') }}</h4>
          </div>
         <ul class="feature-basic__list">
            <li class="feature-basic__item noti-custom" v-for="app in appList" :key="app.name">
               <a
                  class="feature-basic__link"
                  href="javascript:void(0)"
                  @click="redirectTo(app)"
                  v-if="!app.openViewModal"
                  ><span class="feature-basic__icon"><img :src="app.icon" alt="img" /></span>
                  <h5 class="feature-basic__name">{{ app.name }}</h5>
               </a>
               <a
                  class="feature-basic__link"
                  href="javascript:void(0)"
                  v-if="app.openViewModal"
                  @click="setViewModal(app.name, app.url)"
                  ><span class="feature-basic__icon"><img :src="app.icon" alt="img" /></span>
                  <h5 class="feature-basic__name">{{ app.name }}</h5>
               </a>
            </li>
         </ul>
      </div>
      <!-- Modal -->
      <div class="modal fade" data-backdrop="static" id="signIn" tabindex="-1" aria-labelledby="signInLabel">
         <div class="modal-dialog">
            <div class="modal-content">
               <div class="modal-header">
                  <h5 class="modal-title text-center" id="signInLabel">Sign In</h5>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
               </div>
               <div class="modal-body">
                  <div class="mb-3">
                     <label for="username" class="form-label">User name</label>
                     <input type="number" class="form-control" id="username" v-model="loginData.username" />
                  </div>
                  <div class="mb-3">
                     <label for="password" class="form-label">Password</label>
                     <input type="password" class="form-control" id="password" rows="3" v-model="loginData.password" />
                  </div>
               </div>
               <div class="modal-footer">
                  <button
                     type="button"
                     class="btn btn-primary"
                     data-bs-dismiss="modal"
                     :disabled="!hasValidData"
                     @click="submit"
                  >
                     Login
                  </button>
               </div>
            </div>
         </div>
      </div>
      <view-modal :data="view" />
   </div>
</template>
<script>
import BaseComponent from '@/mixins/BaseComponent'
import service from './../services/HomeService'
import { FavouriteList, AppList, Category } from '../constants/data'
import ViewModal from '@/components/modal/ViewModal'
import LocalStorage from '@/ultils/local-storage'
import store from '@/plugins/vuex/store'
import { callNative } from '@/ultils/call-native'
import $ from 'jquery'

export default {
   name: 'HomeUnauthenticated',
   mixins: [BaseComponent],
   components: {
      ViewModal
   },
   data() {
      return {
         loginData: {
            username: '',
            password: ''
         },
         banners: [],
         lstslideShow:[],
         favouriteList: [],
         appList: AppList,
         view: {
            name: '',
            url: ''
         }
      }
   },
   created() {
      this.setTitle(this.$i18n.t('home.login.tab_title'))
      this.loadBanners()
      this.favouriteList = JSON.parse(LocalStorage.getItem('favourite_list')) ?? FavouriteList
   },
   methods: {
      getHelloTitle() {
         let hour = new Date().getHours()
         if (0 <= hour && hour <= 12) {
            return this.$i18n.t('home.login.hello_title.good_morning')
         } else if (12 < hour && hour <= 18) {
            return this.$i18n.t('home.login.hello_title.good_afternoon')
         } else {
            return this.$i18n.t('home.login.hello_title.good_evening')
         }
      },
      loadBanners() {
         // this.showLoading()
         service
            .GetBannerWebview()
            .then((response) => {
               this.lstslideShow = response.object
               if (this.lstslideShow && this.lstslideShow.length != 0) {
                  $('.js-slider-col1').css('display', 'none')
                  $.getScript('js/slider.js', () => {
                     $('.js-slider-col1').css('display', 'block')
                  })
               }
            })
            .finally(() => {
               // this.hideLoading()
            })
      },
      resetData() {
         this.loginData = { username: '', password: '' }
         callNative('login', '')
      },
      hasValidData() {
         return this.loginData.username && this.loginData.password
      },
      async submit() {
         this.showLoading()
         try {
            await service
            .login(this.loginData)
            .then((response) => {
               if (!response.errorMessage) {
                  let userInfo = {
                     token: response.token,
                     isdn: response.username,
                     sessionId: response.sessionId
                  }
                  LocalStorage.setItem('user_info', JSON.stringify(userInfo))
                  this.$router.push('/home')
               } else {
                  store.dispatch('setNotification', { content: response.errorMessage })
                  this.hideLoading()
               }
            })
            .finally(() => {
               // this.hideLoading()
            })
         } catch (error) {
            this.hideLoading()
         }
      },
      setViewModal(name, url) {
         this.view = { name, url }
      },
      redirectTo(app) {
         let installLaoApp = false
         if(installLaoApp) {
            window.open(app.deepLink,'_blank')
          }else {
            if(app.url.includes('http') || app.url.includes('mocha:')){
            //   window.open(app.url)
              // only myunitel
              callNative(app.callNative,'')
            }else {
              if (app.name === this.$i18n.t('home.apps.internet_services')) {
                this.getListFtthAccountInfo()
              } else {
                this.$router.push(app.url)
              }
            }
         }
         // if (app.deepLink) {
         //    window.open(app.url)
         // } else {
         //    this.$router.push(app.url)
         // }
         // if (app.name === this.$i18n.t('home.apps.internet_services')) {
         //    if (LocalStorage.getItem('user_info')) {
         //       this.getListFtthAccountInfo()
         //    }
         // } else {
         //    this.$router.push(app.url)
         // }
      },
      getListFtthAccountInfo() {
         this.showLoading()
         service
            .getListFtthAccountInfo()
            .then((response) => {
               if (response.errorCode === '0') {
                  let result = response.wsResponse ?? []
                  if (result.length > 1) {
                     this.$router.push('/ftth/account-list')
                  } else {
                     this.$router.push('/ftth/login')
                  }
               } else {
                  this.$store.dispatch('setNotification', { content: response.message ?? response.userMsg })
                  this.hideLoading()
               }
            })
            .catch(() => {
               this.hideLoading()
            })
      },
      signUp() {
         callNative('sign_up', '')
      },
      authen(){
         let appCode = LocalStorage.getItem('appCode')
         if (appCode === 'LaoApp') {
            window.open('mocha://loginapp')
         } else {
            callNative('login', '')
            let redirectUri = encodeURIComponent(process.env.VUE_APP_REDIRECT_URL)
            let clientId = process.env.VUE_APP_CLIENT_ID
            let step = 'identifier'
            let isFromWeb = true
            let url = process.env.VUE_APP_LOGIN_URL
            window.location.href = `${url}?redirectUri=${redirectUri}&clientId=${clientId}&step=${step}&isFromWeb=${isFromWeb}`
         }
      },
      redirectToFunc(func) {
         if (func.category === Category.InternetService) {
            LocalStorage.setItem('ftth_redirect_url', func.url)
            this.$router.push('/ftth/login')
         } else if(func.url.includes('mocha:')||func.url.includes('laoapp.la')) {
            window.open(func.url)
         }
         else {
            this.$router.push(func.url)
         }
      },
   }
}
</script>
