<template>
    <ValidationProvider tag="div" :vid="vid" :rules="rules" :name="name || label" v-slot="{ errors }">
        
        <label class="label-title" v-if="isLabel">{{ label }} <span style="color:red" v-if="hasStar">(*)</span></label>
        <div class="select-box js-select-box">
            <select class="js-custom-select" :data-placeholder="placeholder"  v-model="innerValue"   @change="onChange($event)">
                <option :value="item.code" v-for="item in dataSelect" :key="item.code">{{item.name}}</option>
            </select>

        </div>
        <p class="notice-text" v-if="!errors[0] && hint">{{ hint }}</p>
        <p class="error-text" v-if="errors[0]">{{ errors[0] }}</p>
      
    </ValidationProvider>
</template>
<script>
import $ from 'jquery'
import { getDevice } from '@/ultils/device'

export default {
   name: 'Select',
   props: {
      vid: {
         type: String,
         default: undefined
      },
      name: {
         type: String,
         default: ''
      },
      id: {
         type: String,
         default: ''
      },
      label: {
         type: String,
         default: ''
      },
      rules: {
         type: [Object, String],
         default: ''
      },
      placeholder: {
         type: String,
         default: ''
      },
      hint: {
         type: String,
         default: ''
      },
      dataSelect: {
         type: Array,
         default: function () {
              return [{ code: '', name: '' }]
          }
      },
      className: {
         type: String,
         default: 'js-custom-select'
      },
      value: {
         type: null,
         default: ''
      },
      hasStar: {
          type: Boolean,
          default: false
      },
      isLabel: {
          type: Boolean,
          default: true
       },
       //onChange: {
       //    type: Function,
       //    default: () => { }
       //}
   },
   data: () => ({
      innerValue: '',
      oldInnerValue: ''
   }),
   methods: {
      blur() {
         if (getDevice() === 'Android') {
            $(`#${this.name || this.label}`).blur()
         }
      },
      focus() {
         if (getDevice() === 'Android') {
            $(`#${this.name || this.label}`).focus()
         }
      },
      checkAndUpdateVal(newVal) {
         this.blur()
         this.$emit('input', newVal)
         this.innerValue = newVal
         this.oldInnerValue = this.innerValue
         this.focus()
      },
       onInput(evt) {
         this.checkAndUpdateVal(evt.target.value)
      },
      onPaste(evt) {
         this.checkAndUpdateVal(evt.clipboardData.getData('text'))
      },
      reset() {
         this.$emit('input', '')
         this.innerValue = ''
       },
       onChange(event) {
           this.checkAndUpdateVal(event.target.value)
       }
   },
   watch: {
      //innerValue(val) {
      //   this.checkAndUpdateVal(val)
      //}
       value(val) {
           this.innerValue = val
           this.oldInnerValue = this.innerValue
           
       }
   },
   created() {
      this.innerValue = this.value
      this.oldInnerValue = this.innerValue
   }
}
</script>
