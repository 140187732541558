import LocalStorage from '@/ultils/local-storage'

export const CachedFunctions = {
      // { name: 'wsGetHotNews' }, // each function can have their own duration if needed. Ex: { name: "wsGetHotNews", duration: 1000 }. If not it will get from global duration
   functions: [
      // { name: 'wsGetSubAccountInfo', duration: 60 },
      // { name: 'wsGetSubMainInfo', duration: 60 }
   ],
   duration: 3600 // seconds, default 1h
}

export function setCachedData(func, data) {
   let cachedFunc = CachedFunctions.functions.find((f) => f.name === func)
   if (cachedFunc) {
      let duration = cachedFunc.duration ?? CachedFunctions.duration
      let currentDate = new Date()
      currentDate.setSeconds(currentDate.getSeconds() + duration)
      let cachedData = JSON.stringify({ data, duration, expiredTime: currentDate.getTime() })
      LocalStorage.setItem(func, cachedData)
   }
}

export function checkAndGetCachedData(func) {
   let duration = null,
      cachedData = null
   let cachedFunc = CachedFunctions.functions.find((f) => f.name === func)

   if (cachedFunc) {
      duration = cachedFunc.duration ?? CachedFunctions.duration
      cachedData = LocalStorage.getItem(func) ? JSON.parse(LocalStorage.getItem(func)) : null
   }

   if (cachedData) {
      let currentTime = new Date().getTime()
      if (currentTime <= cachedData.expiredTime && duration === cachedData.duration) {
         return cachedData.data
      } else {
         LocalStorage.removeItem(func)
         return null
      }
   }
   return null
}
