<template>
    <div>
        <div class="main-container">
            <div class="nav-bar js-nav-bar">
                <div class="nav-bar__inner">
                    <span class="nav-bar__icon" @click="goBack"><i class="icon-fonts icon-fonts--arrow-left"></i></span>
                    <h3 class="nav-bar__title">{{ $t('mobile.change_to_postpaid.title') }}</h3>
                </div>
            </div>
            <div class="main-content">
                <div class="change-postpaid">
                    <div class="steps-bar">
                        <ul class="steps-bar__list">
                            <li class="steps-bar__item done">
                                <span class="steps-bar__value">1</span>
                                <h5 class="steps-bar__name">{{ $t('mobile.change_to_postpaid.select') }}<br>{{ $t('mobile.change_to_postpaid.package') }}</h5>
                            </li>
                            <li class="steps-bar__item done">
                                <span class="steps-bar__value">2</span>
                                <h5 class="steps-bar__name">{{ $t('mobile.change_to_postpaid.customer') }}<br>{{ $t('mobile.change_to_postpaid.information') }}</h5>
                            </li>
                            <li class="steps-bar__item active">
                                <span class="steps-bar__value">3</span>
                                <h5 class="steps-bar__name">{{ $t('mobile.change_to_postpaid.contract') }}</h5>
                            </li>
                            <li class="steps-bar__item">
                                <span class="steps-bar__value">4</span>
                                <h5 class="steps-bar__name">{{ $t('mobile.change_to_postpaid.result') }}</h5>
                            </li>
                        </ul>
                    </div>
                    <div class="contract-info">
                        <div class="contract-info__content">
                            <div class="contract-info__box">
                                <div class="contract-info__top">
                                    <h5 class="contract-info__sub">{{ $t('mobile.change_to_postpaid.contract_information') }}</h5>
                                </div>
                                <ul class="contract-info__list">
                                    <li class="contract-info__item"><span class="contract-info__name">{{ $t('mobile.change_to_postpaid.payment_method') }}</span><span class="contract-info__value">u-money</span></li>
                                    <li class="contract-info__item"><span class="contract-info__name">{{ $t('mobile.change_to_postpaid.contract_type') }}</span><span class="contract-info__value">Online</span></li>
                                    <li class="contract-info__item"><span class="contract-info__name">{{ $t('mobile.change_to_postpaid.normal_mobile') }}</span><span class="contract-info__value">{{ Number(CH0).toLocaleString('en-US', { maximumFractionDigits: 0 })}} {{ $t('unit.upper_kip') }}</span></li>
                                    <li class="contract-info__item"><span class="contract-info__name">{{ $t('mobile.change_to_postpaid.service_charge') }}</span><span class="contract-info__value">{{ Number(PRE_POS).toLocaleString('en-US', { maximumFractionDigits: 0 })}} {{ $t('unit.upper_kip') }}</span></li>
                                    <li class="contract-info__item"><span class="contract-info__name">{{ $t('mobile.change_to_postpaid.grand_total') }}</span><span class="contract-info__value highlight">{{ Number(totalFee).toLocaleString('en-US', { maximumFractionDigits: 0 })}}<span>{{ $t('unit.upper_kip') }}</span></span></li>
                                </ul>
                            </div>
                            <div class="contract-info__box">
                                <div class="contract-info__top">
                                    <h5 class="contract-info__sub">{{ $t('mobile.change_to_postpaid.customer') }} {{ $t('mobile.change_to_postpaid.information') }}</h5>
                                </div>
                                <ul class="contract-info__list">
                                    <li class="contract-info__item"><span class="contract-info__name">{{ $t('mobile.change_to_postpaid.customer_name') }}</span><span class="contract-info__value">{{data.customerInfo.name}}</span></li>
                                    <li class="contract-info__item"><span class="contract-info__name">{{ $t('mobile.change_to_postpaid.date_of_bird') }}</span><span class="contract-info__value">{{data.customerInfo.birthDate}}</span></li>
                                    <li class="contract-info__item"><span class="contract-info__name">{{ $t('mobile.change_to_postpaid.mobile_number') }}</span><span class="contract-info__value">{{this.dataStore.step2.phone}}</span></li>
                                    <li class="contract-info__item"><span class="contract-info__name">{{ $t('mobile.change_to_postpaid.document_number') }}</span><span class="contract-info__value">{{data.customerInfo.idNo}}</span></li>
                                    <li class="contract-info__item"><span class="contract-info__name">{{ $t('mobile.change_to_postpaid.document_issued_date') }}</span><span class="contract-info__value">{{this.dataStore.step2.idIssueDate}}</span></li>
                                    <li class="contract-info__item"><span class="contract-info__name">{{ $t('mobile.change_to_postpaid.address') }}</span><span class="contract-info__value">{{data.customerInfo.address}}</span></li>
                                </ul>
                            </div>
                        </div>
                        <div class="contract-info__btn" @click="confirmChange">
                            <button class="button button--primary button--full">
                                {{$t('buttons.continue')}}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <button style="display: none" data-bs-toggle="modal" data-bs-target="#notificationP" id="notificationPBtn" />
        <div class="modal fade modal-custom"
             id="notificationP"
             tabindex="-1"
             role="dialog"
             aria-labelledby="notificationP"
             aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-custom__content">
                    <div class="modal-custom__header">
                        <span class="modal-custom__action" data-bs-dismiss="modal">
                            <i class="icon-fonts icon-fonts--close"></i>
                        </span>
                    </div>
                    <div class="modal-custom__body">
                        <div class="modal-custom__images"><img src="@/assets/images/ic-warning.svg" alt="img" /></div>
                        <h3 class="modal-custom__title">{{ $t('notification.title') }}</h3>
                        <p class="modal-custom__des">{{ $t('mobile.change_to_postpaid.confirm') }}</p>
                        <div class="modal-custom__btn">
                            <button class="button button--normal button--medium" data-bs-dismiss="modal">
                                {{$t('buttons.cancel')}}
                            </button>
                            <button class="button button--primary button--medium"
                                    data-bs-dismiss="modal"
                                    @click="redirectStep">
                                {{$t('buttons.ok')}}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
</template>
<script>
    import BaseComponent from '@/mixins/BaseComponent'
    import service from './../../services/MobileService'
    import LocalStorage from '@/ultils/local-storage'
    import store from '@/plugins/vuex/store'
    import $ from 'jquery'

    export default {
        name: 'ContractInfo',
        mixins: [BaseComponent],
        components: {
        },
        data() {
            return {
                dataStore: JSON.parse(LocalStorage.getItem('change-to-postpaid')),
                data: {
                    "customerInfo": {
                        "address": "",
                        "birthDate": "",
                        "busType": "",
                        "custId": "",
                        "idIssueDate": "",
                        "idIssuePlace": "",
                        "idNo": "",
                        "name": "",
                        "sex": "",
                        "phone": ""
                    },
                    "feeTrans": {
                        "lstFee": [
                            {
                                "feeCode": "CH0",
                                "feeName": "Price 0 KIP",
                                "amount": 0
                            },
                            {
                                "feeCode": "PRE_POS",
                                "feeName": "Service fee",
                                "amount": 0
                            }
                        ],
                        "totalFee": 0,
                        "methodChargeFee": null
                    }

                },
                confirmationData: {},
                CH0: 0,
                PRE_POS: 0,
                totalFee: 0
            }
        },
        created() {
            this.setTitle(this.$i18n.t('mobile.change_to_postpaid.title'))
            this.loadData()
        },
        methods: {
            loadData() {
                this.showLoading()
                let param = {
                    "contractDTO": {
                        "quota": this.dataStore.step3.usageLimitation, // người dùng chọn
                        "contractImage": this.dataStore.step3.detail.contractImage.replace("data:application/pdf;base64,", ""),
                        "contractNo": this.dataStore.step3.detail.contractNo
                    },
                    "customerDTO": {
                        "birthDate": this.dataStore.step2.dateOfBirthConvert,
                        "custName": this.dataStore.step2.name,
                        "fullAddress": this.dataStore.step2.address,
                        "email": this.dataStore.step2.email,
                        "signatureImage": this.dataStore.step3.signature.replace("data:image/jpeg;base64,", "").replace("data:image/png;base64,", "")
                    },
                    "subDTO": {
                        "isdn": this.dataStore.step2.phone,
                        "productId": this.dataStore.step1.productId, // productId chọn từ bước 1
                        "telecomServiceId": 1 // fix cứng
                    },
                    username: this.dataStore.step2.phone
                }
                service
                    .getFeeTransfer(param)
                    .then((response) => {
                        if (response.errorCode == "0") {
                            this.data = response.object ?? {
                                "customerInfo": {
                                    "address": "",
                                    "birthDate": "",
                                    "busType": "",
                                    "custId": "",
                                    "idIssueDate": "",
                                    "idIssuePlace": "",
                                    "idNo": "",
                                    "name": "",
                                    "sex": "",
                                    "phone": ""
                                },
                                "feeTrans": {
                                    "lstFee": [
                                        {
                                            "feeCode": "CH0",
                                            "feeName": "Price 0 KIP",
                                            "amount": 0
                                        },
                                        {
                                            "feeCode": "PRE_POS",
                                            "feeName": "Service fee",
                                            "amount": 0
                                        }
                                    ],
                                    "totalFee": 0,
                                    "methodChargeFee": null
                                }

                            }

                            if (this.data.customerInfo.birthDate) {
                                this.data.customerInfo.birthDate = convertDatetoString(this.data.customerInfo.birthDate)
                            }
                            if (this.data.customerInfo.idIssueDate) {
                                this.data.customerInfo.idIssueDate = convertDatetoString(this.data.customerInfo.idIssueDate)
                            }
                            this.totalFee = this.data.feeTrans.totalFee;
                            var feeMobile = this.data.feeTrans.lstFee.filter(x => x.feeCode == "CH0");
                            if (feeMobile) {
                                this.CH0 = feeMobile[0].amount
                            }

                            var chargeFee = this.data.feeTrans.lstFee.filter(x => x.feeCode == "PRE_POS");
                            if (chargeFee) {
                                this.PRE_POS = chargeFee[0].amount
                            }

                            this.data.customerInfo["phone"] = this.dataStore.step2.phone;
                        } else {
                        store.dispatch('setNotification', {
                            title: this.$i18n.t('notification.title'),
                            content: response.errorMessage,
                            closeButton: this.$i18n.t('buttons.close')
                        })
                        }
                    })
                    .finally(() => {
                        this.hideLoading()
                    })
            },
            confirmChange() {
                $('#notificationPBtn').trigger('click')
            },
            redirectStep() {
                this.showLoading()
                let param = {
                    // "addNew": true,
                    "contractDTO": {
                        "quota": this.dataStore.step3.usageLimitation, // người dùng chọn
                        "contractImage": this.dataStore.step3.detail.contractImage.replace("data:application/pdf;base64,", ""),
                        "contractNo": this.dataStore.step3.detail.contractNo
                    },
                    "customerDTO": {
                        "birthDate": this.dataStore.step2.dateOfBirthConvert,
                        "custName": this.dataStore.step2.name,
                        "fullAddress": this.dataStore.step2.address,
                        "email": this.dataStore.step2.email,
                        "signatureImage": this.dataStore.step3.signature.replace("data:image/jpeg;base64,", "").replace("data:image/png;base64,", "")
                    },
                    "subDTO": {
                        "isdn": this.dataStore.step2.phone,
                        "productId": this.dataStore.step1.productId, // productId chọn từ bước 1
                        "telecomServiceId": 1 // fix cứng
                    },
                    // "lstCusProfile": this.dataStore.step2.lstCusProfile,
                    "lstCusProfile": this.$store.state.change_to_postpaid.lstCusProfile,
                    username: this.dataStore.step2.phone,
                    // "provinceCode": this.dataStore.step2.provinceId,
                    // "districtCode": this.dataStore.step2.districtId,
                    // "precinctCode": this.dataStore.step2.precinctId
                }
                service
                    .createTransferOrderPrePos(param)
                    .then((response) => {
                        if (response.errorCode == "0") {
                            this.$router.push('/mobile/change_to_postpaid/request-success')
                        }else {
                        store.dispatch('setNotification', {
                            title: this.$i18n.t('notification.title'),
                            content: response.errorMessage,
                            closeButton: this.$i18n.t('buttons.close')
                        })
                        }
                    })
                    .finally(() => {
                        this.hideLoading()
                    })
                
            }
        }
    }

    function convertDatetoString(unix_timestamp) {
        var date = new Date(Number(unix_timestamp));
        const yyyy = date.getFullYear();
        let mm = date.getMonth() + 1; // Months start at 0!
        let dd = date.getDate();

        if (dd < 10) dd = '0' + dd;
        if (mm < 10) mm = '0' + mm;

        return dd + '/' + mm + '/' + yyyy
    }
</script>
