<template>
    <div class="main-container">
        <div class="nav-bar js-nav-bar">
            <div class="nav-bar__inner">
                <span class="nav-bar__icon" @click="goBack"><i class="icon-fonts icon-fonts--arrow-left"></i></span>
                <h3 class="nav-bar__title">{{ $t('mobile.change_to_postpaid.package_detail') }}</h3>
            </div>
        </div>
        <div class="main-content">
            <div class="change-postpaid">
                <div class="steps-bar">
                    <ul class="steps-bar__list">
                        <li class="steps-bar__item active">
                            <span class="steps-bar__value">1</span>
                            <h5 class="steps-bar__name">{{ $t('mobile.change_to_postpaid.select') }}<br>{{ $t('mobile.change_to_postpaid.package') }}</h5>
                        </li>
                        <li class="steps-bar__item">
                            <span class="steps-bar__value">2</span>
                            <h5 class="steps-bar__name">{{ $t('mobile.change_to_postpaid.customer') }}<br>{{ $t('mobile.change_to_postpaid.information') }}</h5>
                        </li>
                        <li class="steps-bar__item">
                            <span class="steps-bar__value">3</span>
                            <h5 class="steps-bar__name">{{ $t('mobile.change_to_postpaid.contract') }}</h5>
                        </li>
                        <li class="steps-bar__item">
                            <span class="steps-bar__value">4</span>
                            <h5 class="steps-bar__name">{{ $t('mobile.change_to_postpaid.result') }}</h5>
                        </li>
                    </ul>
                </div>
                <div class="data-deals">
                    <div class="data-deals__content">
                        <div class="data-deals__top">
                            <div class="data-deals__images"><img src="@/assets/images/img-package-detail.png" alt="img"></div>
                            <div class="data-deals__detail">
                                <h5 class="data-deals__name">{{packageDetail.productName}}</h5>
                            </div>
                        </div>
                        <ul class="data-deals__list">
                            <li class="data-deals__item">
                                <div class="data-deals__head">
                                    <h4 class="data-deals__sub">{{ $t('mobile.change_to_postpaid.details') }}</h4>
                                </div>
                                <div class="data-deals__info">
                                    <p class="data-deals__des" v-html="packageDetail.description">
                                    </p>
                                </div>
                            </li>
                        </ul>
                        <div class="data-deals__btn">
                            <button class="button button--primary button--full" @click="redirectStep">
                                {{ $t('mobile.change_to_postpaid.select') }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import BaseComponent from '@/mixins/BaseComponent'
    import LocalStorage from '@/ultils/local-storage'

    export default {
        name: 'PakageDetail',
        mixins: [BaseComponent],
        components: {
        },
        data() {
            return {
                data: {},
                packageDetail: { productCode: "", description: ""},
                description: "",
                dataStore: JSON.parse(LocalStorage.getItem('change-to-postpaid')),
            }
        },
        created() {
            this.setTitle(this.$i18n.t('mobile.change_to_postpaid.package_detail'))
            this.packageDetail = this.dataStore.step1;
            delete this.dataStore.step2
        },
        methods: {
            redirectStep() {
                LocalStorage.setItem('change-to-postpaid', JSON.stringify(this.dataStore))
                this.$router.push('/mobile/change_to_postpaid/customer-info')
            }
        }
    }
</script>
