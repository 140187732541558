<template>
   <div class="main-container">
      <div class="nav-bar js-nav-bar" @click="goBack">
         <div class="nav-bar__inner">
            <span class="nav-bar__icon"><i class="icon-fonts icon-fonts--arrow-left"></i></span>
            <h3 class="nav-bar__title">{{ $t('mobile.home.charge_history') }}</h3>
         </div>
      </div>
      <div class="main-content">
         <div class="bill-deals">
            <ul class="bill-deals__list">
               <div class="bill-deals__choose">
                  <div class="radio-custom">
                     <input
                        class="radio-custom__input"
                        type="radio"
                        name="radio"
                        id="radio-1"
                        value="0"
                        v-model="type"
                     />
                     <label class="radio-custom__label" for="radio-1">{{ $t('mobile.charge_history.range') }}</label>
                  </div>
                  <div class="bill-deals__form">
                     <div class="form-2column">
                        <div class="form-customize form-customize--error">
                           <label class="label-title">{{ $t('mobile.charge_history.time_start') }} </label>
                           <input
                              class="form-control js-datepicker"
                              type="text"
                              v-model="timeStart"
                              :disabled="type != 0"
                              readonly
                           />
                           <p class="error-text" v-if="!timeStart">
                              {{ $t('mobile.charge_history.empty_time_start') }}
                           </p>
                        </div>
                        <div class="form-customize">
                           <label class="label-title">{{ $t('mobile.charge_history.time_end') }} </label>
                           <input
                              class="form-control js-datepicker"
                              type="text"
                              v-model="timeEnd"
                              :disabled="type != 0"
                              readonly
                           />
                           <p class="error-text" v-if="!timeEnd">{{ $t('mobile.charge_history.empty_time_end') }}</p>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="bill-deals__choose">
                  <div class="radio-custom">
                     <input
                        class="radio-custom__input"
                        type="radio"
                        name="radio"
                        id="radio-2"
                        checked="checked"
                        value="1"
                        v-model="type"
                     />
                     <label class="radio-custom__label" for="radio-2">{{ $t('mobile.charge_history.today') }}</label>
                  </div>
               </div>
               <div class="bill-deals__choose">
                  <div class="radio-custom">
                     <input
                        class="radio-custom__input"
                        type="radio"
                        name="radio"
                        id="radio-3"
                        value="2"
                        v-model="type"
                     />
                     <label class="radio-custom__label" for="radio-3">{{
                        $t('mobile.charge_history.this_week')
                     }}</label>
                  </div>
               </div>
               <div class="bill-deals__choose">
                  <div class="radio-custom">
                     <input
                        class="radio-custom__input"
                        type="radio"
                        name="radio"
                        id="radio-4"
                        value="3"
                        v-model="type"
                     />
                     <label class="radio-custom__label" for="radio-4">{{
                        $t('mobile.charge_history.this_month')
                     }}</label>
                  </div>
               </div>
               <div class="bill-deals__choose">
                  <div class="radio-custom">
                     <input
                        class="radio-custom__input"
                        type="radio"
                        name="radio"
                        id="radio-5"
                        value="4"
                        v-model="type"
                     />
                     <label class="radio-custom__label" for="radio-5">{{
                        $t('mobile.charge_history.last_month')
                     }}</label>
                  </div>
               </div>
            </ul>
            <div class="bill-deals__btn">
               <button class="button button--primary button--full" @click="search" :disabled="!timeStart || !timeEnd">
                  {{ $t('buttons.search') }}
               </button>
            </div>
         </div>
      </div>
   </div>
</template>
<script>
import BaseComponent from '@/mixins/BaseComponent'
import moment from 'moment'
import { DATE_FORMAT } from './../../constants/data'
import LocalStorage from '@/ultils/local-storage'
import i18n from '@/plugins/i18n'
import store from '@/plugins/vuex/store'

const DateRangeType = {
   Range: 0,
   ToDay: 1,
   ThisWeek: 2,
   ThisMonth: 3,
   LastMonth: 4
}

export default {
   name: 'BillDeals',
   mixins: [BaseComponent],
   data() {
      return {
         type: DateRangeType.Range,
         timeStart: this.getTimeStartByRangeType(DateRangeType.ThisMonth),
         timeEnd: this.getTimeStartByRangeType(DateRangeType.ToDay),
         subType: 1
      }
   },
   created() {
      this.setTitle(this.$i18n.t('mobile.home.charge_history'))
      this.subType = parseInt(LocalStorage.getItem('subType'))
      this.initScript()
   },
   methods: {
      initScript() {
         setTimeout(() => {
            this.loadScript('js/date-picker.js')
         }, 500)
      },
      getTimeStartByRangeType(type) {
         let result = ''
         switch (parseInt(type)) {
            case DateRangeType.Range:
               result = moment().startOf('month').format(DATE_FORMAT)
               break
            case DateRangeType.ToDay:
               result = moment().format(DATE_FORMAT)
               break
            case DateRangeType.ThisWeek:
               result = moment().startOf('isoWeek').format(DATE_FORMAT)
               break
            case DateRangeType.ThisMonth:
               result = moment().startOf('month').format(DATE_FORMAT)
               break
            case DateRangeType.LastMonth:
               result = new moment().subtract(1, 'months').date(1).format(DATE_FORMAT)
               break
         }
         return result
      },
      getTimeEndByRangeType(type) {
         let result = ''
         switch (parseInt(type)) {
            case DateRangeType.Range:
               result = moment().format(DATE_FORMAT)
               break
            case DateRangeType.ToDay:
               result = moment().format(DATE_FORMAT)
               break
            case DateRangeType.ThisWeek:
               result = moment().format(DATE_FORMAT)
               break
            case DateRangeType.ThisMonth:
               result = moment().format(DATE_FORMAT)
               break
            case DateRangeType.LastMonth:
               result = new moment().subtract(1, 'months').endOf('month').format(DATE_FORMAT)
               break
         }
         return result
      },
      search() {
         if (this.subType == 1) {
            this.$router.push(
               `/mobile/charge-history/bill-prepaid/${this.getMiliseconds(
                  this.timeStart,
                  DATE_FORMAT
               )}/${this.getMiliseconds(this.timeEnd, DATE_FORMAT)}`
            )
         } else {
            this.$router.push(
               `/mobile/charge-history/bill-postpaid/${this.getMiliseconds(
                  this.timeStart,
                  DATE_FORMAT
               )}/${this.getMiliseconds(this.timeEnd, DATE_FORMAT)}`
            )
         }
      }
   },
   watch: {
      type(val) {
         this.timeStart = this.getTimeStartByRangeType(val)
         this.timeEnd = this.getTimeEndByRangeType(val)
      },
      timeStart(newVal, oldVal) {
         let startNum = this.getMiliseconds(newVal, DATE_FORMAT)
         let endNum = this.getMiliseconds(this.timeEnd, DATE_FORMAT)
         if (startNum > endNum) {
            this.timeStart = oldVal
            store.dispatch('setNotification', {
               content: i18n.t('errors.time_start_greater_than_time_end')
            })
         }
      },
      timeEnd(newVal, oldVal) {
         let current = moment()
         let startNum = this.getMiliseconds(this.timeStart, DATE_FORMAT)
         let endNum = this.getMiliseconds(newVal, DATE_FORMAT)
         if (startNum > endNum) {
            this.timeEnd = oldVal
            store.dispatch('setNotification', {
               content: i18n.t('errors.time_start_greater_than_time_end')
            })
         } else if (endNum > current) {
            this.timeEnd = oldVal
            store.dispatch('setNotification', {
               content: i18n.t('errors.time_end_greater_than_current_day')
            })
         }
      }
   }
}
</script>
