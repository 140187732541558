import i18n from '@/plugins/i18n'

export const Area = {
   First: 1,
   Second: 2,
   Third: 3
}

export const UCareServiceList = [
   {
      name: i18n.t('home.functions.exchange_to_package'),
      icon: './images/ic-ex-pack.svg',
      url: '/ucare/package-deals',
      area: Area.First,
      requireAuth: true,
      openViewModal: false
   },
   {
      name: i18n.t('home.functions.exchange_to_data'),
      icon: './images/ic-ex-data.svg',
      // url: '/ucare/data-deals',
      url: '/mobile/data-package',
      area: Area.First,
      requireAuth: true,
      openViewModal: false
   },
   {
      name: i18n.t('home.functions.exchange_to_service'),
      icon: './images/ic-ex-gift.svg',
      url: '/ucare/service-deals',
      area: Area.First,
      requireAuth: true,
      openViewModal: false
   },
   {
      // name: i18n.t('home.functions.partner_deals'),
      name: i18n.t('home.functions.exchange_to_gift'),
      icon: './images/ic-partner.svg',
      url: '/ucare/partner-deals',
      area: Area.First,
      requireAuth: true,
      openViewModal: false
   },
   {
      name: i18n.t('home.functions.chat_online'),
      icon: './images/ic-chat-online.svg',
      url: 'javascript:void(0)',
      area: Area.Second,
      href:'javascript:void(0)',
      laoApp:false,
      requireAuth: false,
      openViewModal: false
   },
   {
      name: i18n.t('home.functions.video_call'),
      icon: './images/ic-video-call.svg',
      url: 'javascript:void(0)',
      area: Area.Second,
      href:'javascript:void(0)',
      laoApp:false,
      requireAuth: false,
      openViewModal: false
   },
   {
      name: i18n.t('home.functions.voice_call'),
      icon: './images/ic-voice-call.svg',
      url: 'javascript:void(0)',
      href:'javascript:void(0)',
      area: Area.Second,
      laoApp:false,
      requireAuth: false,
      openViewModal: false
   },
   {
      name: i18n.t('home.functions.contact_center'),
      icon: './images/ic-contact.svg',
      href:'tel:109',
      url: 'javascript:void(0)',
      area: Area.Second,
      laoApp:true,
      requireAuth: false,
      openViewModal: false
   },
   {
      name: i18n.t('home.functions.unitel_shop'),
      icon: './images/ic-unitel-shop.svg',
      url: '/mobile/map/by-current-location',
      href:'javascript:void(0)',
      area: Area.Second,
      laoApp:true,
      requireAuth: false,
      openViewModal: false
   },
   {
      name: i18n.t('home.functions.email'),
      icon: './images/ic-email.svg',
      url: 'javascript:void(0)',
      href:'javascript:void(0)',
      area: Area.Second,
      laoApp:false,
      requireAuth: false,
      openViewModal: false
   },
   {
      name: i18n.t('home.functions.qa'),
      icon: './images/ic-qa.svg',
      url: 'https://www.unitel.com.la/support/faqs',
      href:'javascript:void(0)',
      laoApp:false,
      area: Area.Second,
      requireAuth: false,
      openViewModal: true
   },
   {
      name: i18n.t('home.functions.community'),
      icon: './images/ic-community.svg',
      url: 'https://www.facebook.com/Unitel.la',
      area: Area.Second,
      href:'javascript:void(0)',
      laoApp:false,
      requireAuth: false,
      openViewModal: false
   },
   {
      name: i18n.t('home.functions.change_sim_3g_4g'),
      icon: './images/ic-sim.svg',
      url: 'javascript:void(0)',
      area: Area.Third,
      laoApp:false,
      requireAuth: false,
      openViewModal: false
   },
   {
      name: i18n.t('home.functions.change_esim'),
      icon: './images/ic-esim.svg',
      url: 'javascript:void(0)',
      area: Area.Third,
      laoApp:false,
      requireAuth: false,
      openViewModal: false
   },
   {
      name: i18n.t('home.functions.change_pre_post'),
      icon: './images/ic-pre-post.svg',
      url: 'javascript:void(0)',
      area: Area.Third,
      laoApp:false,
      requireAuth: false,
      openViewModal: false
   },
   {
      name: i18n.t('home.functions.speedtest'),
      icon: './images/ic-speed-test.svg',
      url: 'https://www.speedtest.net/',
      area: Area.Third,
      laoApp:false,
      requireAuth: false,
      openViewModal: true
   }
]
