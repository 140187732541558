<template>
   <div>
      <button style="display: none" data-bs-toggle="modal" data-bs-target="#confirmation" id="confirmationBtn" />
      <div
         class="modal fade modal-custom"
         id="confirmation"
         tabindex="-1"
         role="dialog"
         aria-labelledby="confirmation-data"
         aria-hidden="true"
         data-backdrop="static" data-keyboard="false"
      >
         <div class="modal-dialog" role="document">
            <div class="modal-custom__content">
               <div class="modal-custom__header">
                  <span class="modal-custom__action" data-bs-dismiss="modal" @click="onClose"
                     ><i class="icon-fonts icon-fonts--close"></i
                  ></span>
               </div>
               <div class="modal-custom__body">
                  <h3 class="modal-custom__title">{{ showedData.title }}</h3>
                  <p class="modal-custom__des" v-html="showedData.content"></p>
                  <div class="modal-custom__btn">
                     <button class="button button--normal button--medium" data-bs-dismiss="modal" @click="onCancel">
                        {{ showedData.cancelButton }}
                     </button>
                     <button
                        class="button button--primary button--medium"
                        data-bs-dismiss="modal"
                        @click="onConfirm(showedData.confirmedData)"
                     >
                        {{ showedData.confirmButton }}
                     </button>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>
<script>
import $ from 'jquery'
import i18n from '@/plugins/i18n'

export default {
   name: 'confirmation-modal',
   props: {
      data: Object, // Type: {title: "", content: "", cancelButton: "", confirmButton: "", confirmedData: {}},
      onConfirm: Function,
      onCancel: {
         type: Function,
         default: () => {}
      },
      onClose: {
         type: Function,
         default: () => {}
      }
   },
   data() {
      return {
         showedData: {}
      }
   },
   watch: {
      data() {
         this.showedData = {
            title: this.data.title ?? i18n.t('confirmation.title'),
            content: this.data.content,
            cancelButton: this.data.cancelButton ?? i18n.t('buttons.cancel'),
            confirmButton: this.data.confirmButton ?? i18n.t('buttons.confirm'),
            confirmedData: this.data.confirmedData
         }
         $('#confirmationBtn').trigger('click')
      }
   }
}
</script>
