var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationProvider',{attrs:{"rules":_vm.rules,"name":_vm.name || _vm.label,"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var _obj;

var errors = ref.errors;return [_c('div',{class:[
         ( _obj = {}, _obj[_vm.className] = true, _obj ),
         { 'form-customize--error': errors[0] && _vm.className === 'form-customize' },
         ,
         { 'form-custom--error': errors[0] && _vm.className === 'form-custom' }
      ]},[(_vm.label && _vm.isLabel)?_c('label',{staticClass:"label-title"},[_vm._v(_vm._s(_vm.label)+" "),(_vm.hasStar)?_c('span',{staticStyle:{"color":"red"}},[_vm._v("(*)")]):_vm._e()]):_vm._e(),_c('div',{staticClass:"form-input"},[(_vm.type === 'date')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.innerValue),expression:"innerValue"}],staticClass:"form-control js-datepicker",attrs:{"id":_vm.id,"type":"text","placeholder":_vm.placeholder,"autocomplete":"off"},domProps:{"value":(_vm.innerValue)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.innerValue=$event.target.value},_vm.onInput]}}):_vm._e(),(_vm.rightIcon)?_c('span',{staticClass:"input-addon",on:{"click":_vm.onClickRightIcon}},[_c('i',{class:_vm.rightIcon})]):_vm._e(),(_vm.hasResetIcon && _vm.value != '')?_c('span',{staticClass:"input-close",on:{"click":_vm.reset}},[_c('i',{staticClass:"icon-fonts icon-fonts--close"})]):_vm._e()]),(!errors[0] && _vm.hint)?_c('p',{staticClass:"notice-text"},[_vm._v(_vm._s(_vm.hint))]):_vm._e(),(errors[0])?_c('p',{staticClass:"error-text"},[_vm._v(_vm._s(errors[0]))]):_vm._e()])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }