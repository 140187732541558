<template>
<vue-pull-refresh :on-refresh="onRefresh" :config="refreshConfig">
   <div class="main-container">
      <div class="nav-bar nav-bar--acc">
         <div :class="user.status == 1 ? ['nav-bar__inner'] :['nav-bar__inner','none']">
            <span class="nav-bar__icon" v-if="showBackIcon" @click="goBack"
               ><i class="icon-fonts icon-fonts--arrow-left"></i
            ></span>
            <div class="nav-account" v-show="isAuthenticated">
               <div class="nav-account__info">
                  <h5 class="nav-account__name">{{ user.name }}</h5>
                  <span class="nav-account__tel" style="color: #fff !important">{{ user.phone }}</span>
               </div>
               <!-- <div class="nav-account__avatar"><img :src="user.avatarUrl" alt="img" /></div> -->
               <div class="nav-account__avatar" @click="redirectToByVal(user.status)"><img :src="user.avatarUrl" alt="img">
                  <span :class="user.status == 1?['nav-account__status','success'] :['nav-account__status','danger']">
                     <i :class="user.status == 1 ? ['icon-fonts','icon-fonts--tick'] : ['icon-fonts','icon-fonts--priority']"></i>
                  </span>
               </div>
            </div>
         </div>
      </div>
      <template>
          <div :class="user.status == 1 ? ['acc-auth','acc-auth--data'] : ['acc-auth','acc-auth--data','none']">
                  
            <div class="" v-if="isAuthenticated">
               <div class="box-auth">
                  <ul class="box-auth__list" v-if="subType == 1">
                     <li class="box-auth__item" @click="onClickFunction('/mobile/account-info')">
                        <span class="box-auth__name">{{ $t('mobile.home.main_account') }}</span
                        ><span class="box-auth__number"
                           >{{ Number(subAccount.mainAcc).toLocaleString('en-US', { maximumFractionDigits: 0 })
                           }}<span>{{ $t('unit.upper_kip') }}</span></span
                        >
                     </li>
                     <li class="box-auth__item flex-end" @click="onClickFunction('/mobile/charge-history/bill-deals')">
                        <div class="box-auth__button">
                           <button class="button button--normal">{{ $t('mobile.home.charge_history') }}</button>
                        </div>
                     </li>
                     <li class="box-auth__item" @click="onClickFunction('/mobile/account-info')">
                        <span class="box-auth__name">{{ $t('mobile.home.promo_account') }}</span
                        ><span class="box-auth__number box-auth__number--small"
                           >{{ Number(subAccount.proAcc).toLocaleString('en-US', { maximumFractionDigits: 0 })
                           }}<span>{{ $t('unit.upper_kip') }}</span></span
                        >
                     </li>
                     <li class="box-auth__item flex-end">
                        <div class="box-auth__button">
                           <button class="button button--primary" @click="onClickFunction('/mobile/recharge')">
                              {{ $t('mobile.home.recharge') }}
                           </button>
                        </div>
                     </li>
                  </ul>
                  <ul class="box-auth__list" v-if="subType == 2">
                     <li class="box-auth__item" @click="onClickFunction('/mobile/account-info')">
                        <span class="box-auth__name">{{ $t('mobile.home.hot_change') }}</span
                        ><span class="box-auth__number"
                           >{{ Number(subAccount.prePost).toLocaleString('en-US', { maximumFractionDigits: 0 })
                           }}<span>{{ $t('unit.upper_kip') }}</span></span
                        >
                     </li>
                     <li class="box-auth__item flex-end" @click="onClickFunction('/mobile/charge-history/bill-deals')">
                        <div class="box-auth__button">
                           <button class="button button--normal">
                              {{ $t('mobile.home.charge_history') }}
                           </button>
                        </div>
                     </li>
                     <li class="box-auth__item" @click="onClickFunction('/mobile/account-info')">
                        <span class="box-auth__name">{{ $t('mobile.home.debt') }}</span
                        ><span class="box-auth__number box-auth__number--small"
                           >{{ Number(subAccount.debPost).toLocaleString('en-US', { maximumFractionDigits: 0 })
                           }}<span>{{ $t('unit.upper_kip') }}</span></span
                        >
                     </li>
                     <li class="box-auth__item flex-end">
                        <div class="box-auth__button">
                           <button class="button button--primary" @click="onClickFunction('/mobile/payment')">
                              {{ $t('mobile.home.payment') }}
                           </button>
                        </div>
                     </li>
                  </ul>
               </div>
            </div>
            
         </div>
        </template>
        <div v-for="item in lstNoti" :key="item.type">
          <!-- && item.isDeleteBalance -->
            <template v-if="item.type == 'BALANCE' && item.isDeleteBalance">
               <template>
                  <div class="noti-custom"><span class="noti-custom__action"><i class="icon-fonts icon-fonts--close" @click="deleteNoti(item.type)"></i></span>
                     <div class="noti-custom__detail"><span class="noti-custom__icon"><i class="icon-fonts icon-fonts--notice"></i></span>
                        <div class="noti-custom__info">
                        <h5 class="noti-custom__name">{{$t('home.noti.notice')}}: {{item.title}}</h5>
                        <p class="noti-custom__des">{{item.des}}</p>
                        <div class="noti-custom__btn">
                           <button class="button button--orange button--small" @click="redirectMobileRecharge()">
                              {{$t('home.noti.recharge')}}
                           </button>
                           <button class="button button--yellow button--small" style="cursor: pointer;" @click="redirectMobileBorrow()">
                              {{$t('home.noti.borrow')}}
                           </button>
                        </div>
                        </div>
                     </div>
                  </div>
               </template>
            </template>
          </div>
      
      <div class="" v-if="!isAuthenticated">
         <!-- <div class="box-auth">
            <div class="box-auth__logo">
               <a class="box-auth__logo-link" href="javascript:void(0)"
                  ><img src="@/assets/images/logo.svg" alt="logo"
               /></a>
            </div>
            <div class="box-auth__2btn">
               <button class="button button--normal" @click="signUp">{{ $t('buttons.signup') }}</button
               ><button class="button button--primary" @click="openSignInDialog">
                  {{ $t('buttons.signin') }}
               </button>
            </div>
         </div> -->
         <div class="nav-bar">
          <div class="nav-home none">
            <div class="nav-home__btn">
              <button class="button button--transparent"
                  @click="authen">{{ $t('buttons.signin') }}
              </button>
            </div>
            <div class="nav-home__logo"><a class="nav-home__logo-link" href="#">
               <img src="@/assets/images/logo-new.svg" alt="img"></a>
            </div>
          </div>
      </div>
      </div>
      <div class="process-data process-data--acc">
         <div class="process-data__detail" v-if="isAuthenticated">
            <div class="process-data__top">
               <div class="process-data__info">
                  <h4 class="process-data__name">{{ $t('mobile.account_information.data') }}</h4>
                  <!-- <span class="process-data__tag">{{ $t('mobile.home.data') }}</span> -->
               </div>
               <div class="process-data__capacity">
                  <span class="process-data__capacity-total"
                     >{{ Number(subAccount.dataVolume).toLocaleString('en-US', { maximumFractionDigits: 0 }) }}
                     {{ $t('unit.mb') }}</span
                  >
               </div>
            </div>
            <div class="process-bar">
               <p class="process-bar__percent" :style="processStyle"></p>
            </div>
         </div>
         <span class="process-data__action" v-if="isAuthenticated" @click="onClickFunction('/mobile/credit-service?tab=2')"
            ><i class="icon-fonts icon-fonts--add-2"></i
         ></span>
      </div>
      <div class="feature-favourite">
         <ul class="feature-favourite__list">
            <li
               class="feature-favourite__item"
               v-for="func in functions"
               :key="func.name"
               v-bind:style="
                  func.url == '/mobile/change_esim'
                     ? 'pointer-events: none; opacity: 0.6;'
                     : ''
               "
               @click="onClickFunction(func.url)"
            >
               <a class="feature-favourite__link" href="javascript:void(0)"
                  ><span class="feature-favourite__icon"><img :src="func.icon" alt="img" /></span>
                  <h5 class="feature-favourite__name">{{ func.name }}</h5>
               </a>
            </li>
         </ul>
      </div>
      <!-- Modal -->
      <button data-bs-toggle="modal" data-bs-target="#signIn" id="signinBtn" style="display: none" />
      <div class="modal fade" data-backdrop="static" id="signIn" tabindex="-1" aria-labelledby="signInLabel">
         <div class="modal-dialog">
            <div class="modal-content">
               <div class="modal-header">
                  <h5 class="modal-title text-center" id="signInLabel">Sign In</h5>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
               </div>
               <div class="modal-body">
                  <div class="mb-3">
                     <label for="username" class="form-label">User name</label>
                     <input type="number" class="form-control" id="username" v-model="loginData.username" />
                  </div>
                  <div class="mb-3">
                     <label for="password" class="form-label">Password</label>
                     <input type="password" class="form-control" id="password" rows="3" v-model="loginData.password" />
                  </div>
               </div>
               <div class="modal-footer">
                  <button
                     type="button"
                     class="btn btn-primary"
                     data-bs-dismiss="modal"
                     :disabled="!hasValidData"
                     @click="signIn"
                  >
                     Login
                  </button>
               </div>
            </div>
         </div>
      </div>
   </div>
</vue-pull-refresh>
</template>
<script>
import BaseComponent from '@/mixins/BaseComponent'
import service from './../../services/MobileService'
import { ChargeType, MobileFuctionList } from './../../constants/data'
import LocalStorage from '@/ultils/local-storage'
import { callNative } from '@/ultils/call-native'
import loginService from '@/modules/home/services/HomeService'
import VuePullRefresh from 'vue-pull-refresh'
import store from '@/plugins/vuex/store'
import $ from 'jquery'

export default {
   name: 'MobileHome',
   mixins: [BaseComponent],
   components: {
        'vue-pull-refresh': VuePullRefresh
   },
   data() {
      return {
         isAuthenticated: false,
         functions: [],
         subType: 1,
         user: {
            name: '',
            avatarUrl: './images/avatar-1.svg',
            phone: '',
            status:-1
         },
         subAccount: {
            prePost: 0,
            debPost: 0,
            mainAcc: 0,
            proAcc: 0,
            dataPkgName: '',
            dataVolume: 0
         },
         processStyle: 'width: 80%',
         showBackIcon: true,
         loginData: {
            username: '',
            password: ''
         },
         returnUrl: null,
         lstNoti:[],
         isCheckCH:false,
         isCheckData:false,
         isCheckBalance:false,
         accountInfo:{},
      }
   },
   created() {
      this.init()
   },
   methods: {
      deleteNoti(type){
        this.lstNoti.map(x=>{
          if(x.type == "DATA" && x.type == type){
            x.isDeleteData = false
          }
          if(x.type == "BALANCE" && x.type == type){
            x.isDeleteBalance = false
          }
          if(x.type == "3GRAB" && x.type == type){
            x.isDeleteCH = false
          }
        })
        console.log('this.lstNoti',this.lstNoti)
        this.$forceUpdate()
     },
      init(){
         let userInfo = JSON.parse(LocalStorage.getItem('user_info'))
         let appCode = LocalStorage.getItem('appCode')
         if (appCode === 'LaoApp') {
            this.functions = MobileFuctionList.filter((func) => {return func.chargeType === ChargeType.Both && func.laoApp})
         }else {
            this.functions = MobileFuctionList.filter((func) => func.chargeType === ChargeType.Both)
         }
         if (userInfo) {
            this.isAuthenticated = true
            this.loadData()
         }
      },
      async loadData() {
         let appCode = LocalStorage.getItem('appCode')
         if (appCode === 'LaoApp') {
            this.showBackIcon = true
         }
         this.showLoading()
         try {
            await service
            .getUserInfo()
            .then((response) => {
               let userInfo = JSON.parse(LocalStorage.getItem('user_info')) ?? {}
               // this.showBackIcon = !userInfo.originalUrl
               let data = response.wsResponse
               this.user = {
                  name: data.name,
                  phone: userInfo['isdn'] ?? '',
                  //avatarUrl: data.avatar_url?.trim() ? data.avatar_url : './images/avatar-1.svg',
                  avatarUrl: './images/avatar-1.svg',
                  status: (response.wsResponse.standardStatus === '1' && response.wsResponse.standardSystem==='3GRAB') ? response.wsResponse.standardStatus : 0
               }
               this.user.status = (response.wsResponse.standardStatus === '1' && response.wsResponse.standardSystem==='3GRAB') ? response.wsResponse.standardStatus : 0
               LocalStorage.setItem('user_detail', JSON.stringify(response.wsResponse))
               LocalStorage.setItem('subType', response.wsResponse.subType)
               this.subType = response.wsResponse.subType
               let appCode = LocalStorage.getItem('appCode')
               if (appCode === 'LaoApp') {
                  this.functions = MobileFuctionList.filter(
                     (func) => {return (func.chargeType === this.subType || func.chargeType === ChargeType.Both)&& func.laoApp}
                  )
               }else {
                  this.functions = MobileFuctionList.filter(
                     (func) => func.chargeType === this.subType || func.chargeType === ChargeType.Both
                   )

                   //check change paid
               }
               if (this.subType == 1) {
                  this.setTitle(this.$i18n.t('mobile.home.prepaid'))
               } else {
                  this.setTitle(this.$i18n.t('mobile.home.postpaid'))
               }
               // đóng 22.06.2022
               // await service
               //    .getSubAccountInfo({ subType: this.subType })
               //    .then((response) => {
               //       let data = response.wsResponse
               //       this.subAccount = {
               //          prePost: data.prePost ?? 0,
               //          debPost: data.debPost ?? 0,
               //          mainAcc: data.mainAcc ?? 0,
               //          proAcc: data.proAcc ?? 0,
               //          dataPkgName: data.name,
               //          dataVolume: data.dataVolume
               //       }
               //    })
               //    .finally(() => {
               //       this.hideLoading()
               //    })
               // end 22.06.2022
            })
            .catch(() => {
               // this.hideLoading()
            })
            .finally(() => {
                     // this.hideLoading()
                  })

            // this.functions = MobileFuctionList.filter(
            //          (func) => func.chargeType === this.subType || func.chargeType === ChargeType.Both
            //        )

            // 22.06.2022
            await service
               .getCustInfoTransfer()
               .then((response) => {
                  if (response.errorCode == "0") {
                        let data = response.object.customerInfo ?? []
                        let content2 = data.lstCusProfile.filter(x => x.imageLabel == "Avatar")
                        if (content2) {
                           if (content2[0].content) {
                              this.user.avatarUrl = 'data:image/jpeg;base64,' + content2[0].content
                           } else {
                              this.user.avatarUrl = "./images/avatar-1.svg"
                           }
                        } else {
                           this.user.avatarUrl = "./images/avatar-1.svg"
                        }
                  } 
               })
               .finally(() => {
                  //this.hideLoading()
               })
            let userDetail = JSON.parse(LocalStorage.getItem('user_info')) ?? {}
            let isdn = userDetail.isdn
            await service.GetNoticeWebview({"isdn":isdn}).then((response) => {
               this.lstNoti = response.object ?? []
            })
            // == 1 trả trước, ==2 trả sau
            let subType = LocalStorage.getItem('subType')
            await service.getSubAccountInfo({"isdn":isdn,"subType": subType}).then((response) => {
               // this.accountInfo = response.wsResponse ?? {}
               let data = response.wsResponse
               this.accountInfo = data ? {
               prePost: data.prePost ?? 0,
               debPost: data.debPost ?? 0,
               mainAcc: data.mainAcc ?? 0,
               proAcc: data.proAcc ?? 0,
               dataPkgName: data.name,
               dataVolume: data.dataVolume
               } : {}

               this.subAccount = data ? {
                  prePost: data.prePost ?? 0,
                  debPost: data.debPost ?? 0,
                  mainAcc: data.mainAcc ?? 0,
                  proAcc: data.proAcc ?? 0,
                  dataPkgName: data.name,
                  dataVolume: data.dataVolume
               } : {}
            })

            if(this.lstNoti && this.lstNoti.length != 0 && this.accountInfo){
               let dataVolume = (this.accountInfo.dataVolume) ?  parseInt(this.accountInfo.dataVolume): 0
               let mainAcc = (this.accountInfo.mainAcc) ?  parseInt(this.accountInfo.mainAcc): 0
               let debPost = (this.accountInfo.debPost) ?  parseInt(this.accountInfo.debPost): 0
               let _this = this
               this.lstNoti.map(x=>{
                  // binhvt
               x.isDeleteData = false,
               x.isDeleteBalance = false,
               x.isDeleteCH = false
               })
               this.lstNoti.map(x=>{
               if(x.type == "DATA" && (parseInt(x.value) > dataVolume)){
                  x.isDeleteData = true
               }
               if(x.type == "BALANCE"){
                  // == 1 trả trước, ==2 trả sau
                  if(subType == 1 && parseInt(x.value) > mainAcc){
                     x.isDeleteBalance = true
                  }
                  if(subType == 2 && parseInt(x.value) > debPost){
                     x.isDeleteBalance = true
                  }
               }
               if(x.type == "3GRAB" && parseInt(_this.user.status) != 1){
                  x.isDeleteCH = true
               }
               })
               console.log('this.lstNoti',this.lstNoti)
            }
            
            this.$forceUpdate()
            this.hideLoading()
            // end 22.06.2022
         } catch (error) {
            this.hideLoading()
         }
      },
      openSignInDialog() {
         callNative('login', '')
         $('#signinBtn').trigger('click')
         this.returnUrl = null
         this.loginData = { username: '', password: '' }
      },
      hasValidData() {
         return this.loginData.username && this.loginData.password
      },
      signIn() {
         this.showLoading()
         loginService
            .login(this.loginData)
            .then((response) => {
               this.hideLoading()
               if (!response.errorMessage) {
                  let userInfo = {
                     token: response.token,
                     isdn: response.username,
                     sessionId: response.sessionId
                  }
                  LocalStorage.setItem('user_info', JSON.stringify(userInfo))
                  this.isAuthenticated = true
                  if (this.returnUrl) {
                     this.$router.push(this.returnUrl)
                  } else {
                     console.log('login success')
                     this.loadData()
                  }
               } else {
                  console.log('login fail')
                  store.dispatch('setNotification', { content: response.errorMessage })
               }
            })
            .catch(() => {
               this.hideLoading()
            })
      },
      signUp() {
         callNative('sign_up', '')
      },
      async onClickFunction(url) {
         if(url == "/mobile/change-to-prepaid/customer-info"){
            this.showLoading()
            let change_to_prepaid={
               cusId:"",
               contactPhone:"",
               birthOfDay:"",
               cusName:"",
               fullAddress:"",
               provinceCode:"01",
               districtCode:"01",
               precinctCode:"01",
               sex:"M",
               appCode: "APP_UNITEL"
            }
            store.commit('setChangeToPrepaid', change_to_prepaid)
            let userDetail = JSON.parse(LocalStorage.getItem('user_detail')) ?? {}
            let cusId = userDetail['custId']
            let dataPost = {
               "cusId": cusId,
               "orderTypeCode": 4
            }
            
            await service
              .trackingOrder(dataPost)
              .then((response) => {
                  this.hideLoading()
                  if(response  && parseInt(response.errorCode) == 0){
                     let objectSuccess = response.object.sort(function (a, b) {
                        return b.index - a.index
                     })
                     localStorage.setItem("order_code",objectSuccess[0].orderCode)
                     localStorage.setItem("updateDate",objectSuccess[0].updateDate)
                     localStorage.setItem("statusName",objectSuccess[0].statusName)
                     if(objectSuccess[0].statusName !='Cancel Order') {
                        this.$router.push('/mobile/home/request-success')
                     } else {
                        this.$router.push(url)
                     }
                  }
                  else{
                     this.$router.push(url)
                  }
              })
              .finally(() => {
                   this.hideLoading()
                     // localStorage.setItem("order_code","xxxxx")
                     // localStorage.setItem("updateDate","yyyyy")
                     // localStorage.setItem("statusName","XXXXXXX")
                  //  this.$router.push('/mobile/home/request-success')
              })
         } else if(url == "/mobile/change_to_postpaid"){
            if(this.user.status != 1){
               this.$store.dispatch('setNotification', { content: this.$i18n.t('mobile.home.3grab') })
            }else {
               this.$router.push(url)
            }
         } else{
            let appCode = LocalStorage.getItem('appCode')
            if (this.isAuthenticated) {
               if (url !== 'javascript:void(0)') {
                  if (!url.includes('http')) {
                     if (this.subType == 2 && url === '/mobile/credit-service?tab=2') {
                        this.$router.push('/mobile/special-package')
                     } else if (url === '/mobile/map/by-current-location') {
                        if (appCode === 'LaoApp') {
                              this.$router.push(url)
                        }else {
                              callNative('open_unitel_shop', '')
                        }
                     } else if (url === '/mobile/change_sim_3g_4g') {
                        callNative('open_change_sim_3g_4g', '')
                     } else if (url === '/mobile/change_esim') {
                        callNative('open_change_esim', '')
                     } else if (url === '/mobile/change_pre_post') {
                        callNative('open_change_pre_post', '')
                     } 
                     else {
                        this.$router.push(url)
                     }
                  } else {
                     window.location.href = url
                  }
               }
            } else {
               this.returnUrl = url
               if (url !== '/mobile/map/by-current-location') {
                  callNative('login', '')
               } else {
                  callNative('open_unitel_shop', '')
               }
               // $('#signinBtn').trigger('click')
               // this.loginData = { username: '', password: '' }
            }
         }
      },
      onRefresh: function() {
         let self = this
         return new Promise(function (resolve) {
            self.init()
            resolve()
         })
      },
    redirectMobileRecharge(){
      this.$router.push('/mobile/recharge')
    },
    redirectMobileBorrow(){
      if(this.subType == 1) {
         this.$router.push('/mobile/credit-service')
      }else {
      this.$router.push('/mobile/data-package')}
    },
    redirectToByVal(status){
        if(parseInt(status) == 1){
          this.$router.push("/user-info")
        }
        else{
          this.$router.push("/laokyc")
        }
      },
      authen(){
         let appCode = LocalStorage.getItem('appCode')
         if (appCode === 'LaoApp') {
            window.open('mocha://loginapp')
         } else {
            callNative('login', '')
            let redirectUri = encodeURIComponent(process.env.VUE_APP_REDIRECT_URL_MOBILE)
            let clientId = process.env.VUE_APP_CLIENT_ID
            let step = 'identifier'
            let isFromWeb = true
            let url = process.env.VUE_APP_LOGIN_URL
            window.location.href = `${url}?redirectUri=${redirectUri}&clientId=${clientId}&step=${step}&isFromWeb=${isFromWeb}`
         }
      }
   }
}
</script>
