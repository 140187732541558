import Vue from 'vue'
import { required } from 'vee-validate/dist/rules'
import { extend, ValidationProvider } from 'vee-validate'
import i18n from '@/plugins/i18n'
import moment from 'moment'

Vue.component('ValidationProvider', ValidationProvider)

extend('required', {
   ...required,
   message: i18n.t('errors.required')
})

extend('min', {
   validate(value, { length }) {
      return value.length >= length
   },
   params: ['length'],
   message: i18n.t('errors.min')
})

extend('max', {
   validate(value, { length }) {
      return value.length <= length
   },
   params: ['length'],
   message: i18n.t('errors.max')
})

extend('equal', {
   validate(value, { length }) {
      return value.length == length
   },
   params: ['length'],
   message: i18n.t('errors.equal')
})

extend('confirmedBy', {
   params: ['target'],
   validate(value, { target }) {
      return value === target
   },
   message: i18n.t('errors.confirmed_by')
})

extend('phone', {
   validate(value) {
      return /^(9|0209|209|856209)+([0-9]{7})\b$/im.test(value) || /^(030|304|309)+([0-9]{6})\b$/im.test(value)
   },
   message: i18n.t('errors.phone')
})

extend('password', {
   validate(value) {
      return /^(?=.*[0-9])(?=.*[A-Z])(?=.*[@$!%*?&])([a-zA-Z0-9@$!%*?&#]{8,})$/.test(value)
   },
   message: i18n.t('errors.password')
})

//phone special
extend('phoneS', {
    validate(value) {
        return /^(9|0209|209|856209)+([0-9]{7})\b$/im.test(value)
    },
    message: i18n.t('errors.phoneS')
})

extend('date', {
    validate(value) {
        var date = moment(value, 'DD-MM-YYYY');
        if (!date.isValid()) {
            return false;
        }
        return /^([0-9]{2})\/([0-9]{2})\/([0-9]{4})$/.test(value)
    },
    message: i18n.t('errors.date_format')
})

extend('email', {
    validate(value) {
        return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value)
    },
    message: i18n.t('errors.email')
})