import axios from 'axios'
import { logout } from '@/ultils/user'
import { GatewayError, STAY_PAGE_ERRORS } from '@/constants/error-code'
import { checkAndGetCachedData, setCachedData, CachedFunctions } from '@/ultils/cache'
import i18n from '@/plugins/i18n'
import store from '@/plugins/vuex/store'
import router from './../router'
import LocalStorage from '@/ultils/local-storage'
import { callNative } from '@/ultils/call-native'
import { isLoginFtth } from './user'

export default (method, func, body = {}, needIsdn = true,changeSim = false) => {
   let data = {}
   let userInfo = {}
   let baseUrl = ''
   if (func === 'login') {
      baseUrl = process.env.VUE_APP_LOGIN_API
      data = {
         language: LocalStorage.getItem('lang'),
         prefix: '856',
         accountType: body['accountType'] ?? '0',
         clientOS: '1',
         appCode: 'MyVTG',
         isEncrypt: '0',
         username: body['username'],
         password: body['password']
      }
   } else if (func === 'forgotPass') {
      baseUrl = process.env.VUE_APP_FORGOT_PASS_API
      data = {
         language: LocalStorage.getItem('lang'),
         otp: body['otp'],
         username: body['username']
      }
   } else if (func === 'UserChangePassword') {
      baseUrl = process.env.VUE_APP_FORGOT_PASS_FTTH_API
      data = {...body}
   } 
   // else if(infoSim){
   //    baseUrl = process.env.VUE_APP_API
   //    userInfo = JSON.parse(LocalStorage.getItem('user_info')) ?? {}
   //    if (needIsdn) {
   //      body['isdn'] = body['isdn'] ? body['isdn'] : userInfo['isdn'] ?? ''
   //    }
   //    body['language'] = LocalStorage.getItem('lang')
   //    data = {
   //       username: LocalStorage.getItem('lang'),
   //       apiKey: userInfo['sessionId'] ?? '',
   //       token: userInfo['token'] ?? '',
   //       wsCode: func,
   //       wsRequest: body
   //    }
   // } 
   else {
      let lang = LocalStorage.getItem('lang')
      if((func === 'wsPromotionUseFTTH'|| func === "wsInforPromotionFtth100") && LocalStorage.getItem('lang') == "cn"){
         lang = "zh"
      }
      baseUrl = process.env.VUE_APP_API
      userInfo = JSON.parse(LocalStorage.getItem('user_info')) ?? {}
      if (needIsdn) {
        body['isdn'] = body['isdn'] ? body['isdn'] : userInfo['isdn'] ?? ''
      }
      body['language'] = lang
      if(changeSim){
         data = {
            isWrapReq:1,
            sessionId: userInfo['sessionId'] ?? '',
            token: userInfo['token'] ?? '',
            wsCode: func,
            wsRequest: body
         }
      }else{
         data = {
            language: lang,
            sessionId: userInfo['sessionId'] ?? '',
            token: userInfo['token'] ?? '',
            wsCode: func,
            wsRequest: body
         }
      }
      let ftthUserInfo = JSON.parse(LocalStorage.getItem('ftth_user_info')) ?? {}
      if (Object.keys(userInfo).length === 0 && Object.keys(ftthUserInfo).length !== 0) {
         if(needIsdn){
         body['isdn'] = body['isdn'] ? body['isdn'] : ftthUserInfo['isdn'] ?? ''
         }
         data = {
            language: lang,
            sessionId: ftthUserInfo['sessionId'] ?? '',
            token: ftthUserInfo['token'] ?? '',
            wsCode: func,
            wsRequest: body
         }
      }
      let ucareFtth = LocalStorage.getItem('click_ucare_ftth')
      let ftthAccountLogin = LocalStorage.getItem('ftth_account_login')
      if(ucareFtth && needIsdn){
         body['isdn'] = ftthUserInfo['isdn'] ?? ''
      }
      if(ftthAccountLogin && ucareFtth){
         console.log('ftthAccountLogin '+ ftthAccountLogin)
         data = { 
                     language: lang,
                     sessionId: ftthUserInfo['sessionId'] ?? '',
                     token: ftthUserInfo['token'] ?? '',
                     wsCode: func,
                     wsRequest: body
                  }
      }
   }

    //vinhvh them TH dac biet
    if (body['username']) {
        data.username = userInfo['isdn'] ?? body['username']
        delete body.username
    }

    let showErr = true;
    if (body['showErr']) {
        showErr = false;
    }

   let config = {
      method: method.toLowerCase(),
      url: baseUrl,
      data: data,
      headers: { 'Content-Type': 'application/json' },
      dataType: 'json',
      contentType: 'application/json'
   }

   return new Promise(function (resolve, reject) {
      let cachedData = checkAndGetCachedData(func)
      if (cachedData) {
         resolve(cachedData)
      } else {
         axios(config)
            .then(function (response) {
               if (func !== 'login' && func != 'forgotPass' && func != 'UserChangePassword') {
                  if (response.data.errorCode === GatewayError.SUCCESS) {
                     if (CachedFunctions.functions.some((f) => f.name === func)) {
                        setCachedData(func, response.data.result)
                     }
                     resolve(response.data.result)
                  } else if (response.data.errorCode === GatewayError.ACCEPTED) {
                     store.dispatch('setNotification', { content: response.data.errorMessage })
                  } else if (STAY_PAGE_ERRORS.includes(response.data.errorCode)) {
                     store.dispatch('setNotification', { content: response.data.errorMessage })
                     reject()
                  } else {
                     // neu dang nhap chi ftth day ra trang login ftth
                     console.log("islogin ftth "+ isLoginFtth())
                     if (isLoginFtth()) {
                        logout()
                        callNative('exprire_session', '')
                        router.push('/ftth/login')
                        // neu dang nhap mobile va ftth
                     } else {
                        logout()
                        // webview nhung app
                        if (userInfo.originalUrl) {
                           callNative('exprire_session', '')
                        } else { // vao truc tiep
                           store.dispatch('setNotification', {
                              content: response.data.errorMessage ?? i18n.t('errors.session_expired')
                           })
                           router.push('/login')
                        }
                     }
                     reject()
                  }
               } else {
                  resolve(response.data)
               }
            })
             .catch(function () {
                 if (showErr) {
                     store.dispatch('setNotification', { content: i18n.t('errors.system') })
                 }
                 reject()
            })
      }
   })
}
