<template>
   <div class="main-container">
      <div class="nav-bar js-nav-bar" @click="goBack()">
         <div class="nav-bar__inner">
            <span class="nav-bar__icon"><i class="icon-fonts icon-fonts--arrow-left"></i></span>
            <h3 class="nav-bar__title">Recharge u-money</h3>
         </div>
      </div>
      <div class="main-content">
         <div class="recharge-basic">
            <div class="recharge-form">
               <div class="form-customize form-customize--error">
                  <label class="label-title">Phone number </label>
                  <div class="form-input">
                     <input class="form-control" type="text" placeholder="0944894903" /><span class="input-addon"
                        ><i class="icon-fonts icon-fonts--contact"></i
                     ></span>
                  </div>
                  <p class="error-text">Please complete all information</p>
               </div>
               <div class="form-customize">
                  <label class="label-title">Amount </label>
                  <div class="form-input">
                     <input class="form-control" type="text" placeholder="Enter amount" /><span class="input-addon"
                        ><i class="icon-fonts icon-fonts--undefined"></i
                     ></span>
                  </div>
               </div>
            </div>
            <div class="recharge-kip">
               <ul class="recharge-kip__list">
                  <li class="recharge-kip__item">3.000 kip</li>
                  <li class="recharge-kip__item">4.000 kip</li>
                  <li class="recharge-kip__item">5.000 kip</li>
                  <li class="recharge-kip__item">10.000 kip</li>
                  <li class="recharge-kip__item">15.000 kip</li>
                  <li class="recharge-kip__item">20.000 kip</li>
                  <li class="recharge-kip__item">25.000 kip</li>
                  <li class="recharge-kip__item">30.000 kip</li>
                  <li class="recharge-kip__item">35.000 kip</li>
               </ul>
            </div>
            <div class="recharge-recent">
               <h4 class="recharge-recent__sub">Recent Activites</h4>
               <ul class="recharge-recent__list">
                  <li class="recharge-recent__item">
                     <h5 class="recharge-recent__name">12,664 kip</h5>
                     <span class="recharge-recent__time">14:21:31 12/08/2020</span>
                  </li>
                  <li class="recharge-recent__item">
                     <h5 class="recharge-recent__name">12,664 kip</h5>
                     <span class="recharge-recent__time">14:21:31 12/08/2020</span>
                  </li>
               </ul>
            </div>
            <div class="recharge-btn">
               <button class="button button--primary button--full">Recharge</button>
            </div>
         </div>
      </div>
   </div>
</template>
<script>
import BaseComponent from '@/mixins/BaseComponent'

export default {
   name: 'RẹchargeWithUMoney',
   mixins: [BaseComponent],
   components: {},
   data() {
      return {}
   },
   created() {
      this.setTitle(this.$i18n.t('mobile.credit_service.title'))
   },
   methods: {}
}
</script>
