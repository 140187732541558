<template>
   <div class="main-container">
      <div class="nav-bar js-nav-bar" @click="goBack">
         <div class="nav-bar__inner">
            <span class="nav-bar__icon"><i class="icon-fonts icon-fonts--arrow-left"></i></span>
            <h3 class="nav-bar__title">{{ $t('mobile.charge_history.bill_detail') }}</h3>
         </div>
      </div>
      <div class="main-content">
         <div class="bill-detail">
            <p class="time-charge">{{ startDate }} - {{ endDate }}</p>
            <div class="bill-chart" v-if="chartData.length > 1">
               <GChart type="ColumnChart" :data="chartData" :options="chartOptions" />
               <ul class="bill-chart__list">
                  <li class="bill-chart__item">
                     <span class="bill-chart__dot recharge"></span
                     ><span class="bill-chart__value">{{ $t('mobile.charge_history.recharge') }}</span>
                  </li>
                  <li class="bill-chart__item">
                     <span class="bill-chart__dot used"></span
                     ><span class="bill-chart__value">{{ $t('mobile.charge_history.used') }}</span>
                  </li>
               </ul>
            </div>
            <ul class="bill-detail__list">
               <li class="bill-detail__item top" @click="redirectTo('total-charge')">
                  <div class="bill-detail__detail">
                     <span class="bill-detail__icon"><i class="icon-fonts icon-fonts--charge"></i></span>
                     <div class="bill-detail__info">
                        <h5 class="bill-detail__name">
                           <a class="bill-detail__name-link" href="javascript:void(0)">{{
                              $t('mobile.charge_history.total_charges')
                           }}</a>
                        </h5>
                        <span class="bill-detail__kip"
                           >{{ Number(totalCharges).toLocaleString('en-US', { maximumFractionDigits: 0 }) }}
                           {{ $t('unit.kip') }}</span
                        >
                     </div>
                  </div>
                  <span class="bill-detail__action"><i class="icon-fonts icon-fonts--arrow-right"></i></span>
               </li>
               <li class="bill-detail__item">
                  <div class="bill-detail__detail">
                     <span class="bill-detail__icon"><i class="icon-fonts icon-fonts--call"></i></span>
                     <div class="bill-detail__info">
                        <h5 class="bill-detail__name">
                           <a class="bill-detail__name-link" href="javascript:void(0)">{{
                              $t('mobile.charge_history.main_account')
                           }}</a>
                        </h5>
                        <span class="bill-detail__kip"
                           >{{ Number(mainAccount).toLocaleString('en-US', { maximumFractionDigits: 0 }) }}
                           {{ $t('unit.kip') }}</span
                        >
                     </div>
                  </div>
               </li>
               <li class="bill-detail__item">
                  <div class="bill-detail__detail">
                     <span class="bill-detail__icon"><i class="icon-fonts icon-fonts--prize"></i></span>
                     <div class="bill-detail__info">
                        <h5 class="bill-detail__name">
                           <a class="bill-detail__name-link" href="javascript:void(0)">{{
                              $t('mobile.charge_history.promo_account')
                           }}</a>
                        </h5>
                        <span class="bill-detail__kip"
                           >{{ Number(promoAccount).toLocaleString('en-US', { maximumFractionDigits: 0 }) }}
                           {{ $t('unit.kip') }}</span
                        >
                     </div>
                  </div>
               </li>
               <li class="bill-detail__item" @click="redirectTo('calls')">
                  <div class="bill-detail__detail">
                     <span class="bill-detail__icon"><i class="icon-fonts icon-fonts--call"></i></span>
                     <div class="bill-detail__info">
                        <h5 class="bill-detail__name">
                           <a class="bill-detail__name-link" href="javascript:void(0)">{{
                              $t('mobile.charge_history.call')
                           }}</a>
                        </h5>
                        <span class="bill-detail__kip"
                           >{{ Number(callFee).toLocaleString('en-US', { maximumFractionDigits: 0 }) }}
                           {{ $t('unit.kip') }}</span
                        >
                     </div>
                  </div>
                  <span class="bill-detail__action"><i class="icon-fonts icon-fonts--arrow-right"></i></span>
               </li>
               <li class="bill-detail__item" @click="redirectTo('sms')">
                  <div class="bill-detail__detail">
                     <span class="bill-detail__icon"><i class="icon-fonts icon-fonts--sms"></i></span>
                     <div class="bill-detail__info">
                        <h5 class="bill-detail__name">
                           <a class="bill-detail__name-link" href="javascript:void(0)">{{
                              $t('mobile.charge_history.sms')
                           }}</a>
                        </h5>
                        <span class="bill-detail__kip"
                           >{{ Number(smsFee).toLocaleString('en-US', { maximumFractionDigits: 0 }) }}
                           {{ $t('unit.kip') }}</span
                        >
                     </div>
                  </div>
                  <span class="bill-detail__action"><i class="icon-fonts icon-fonts--arrow-right"></i></span>
               </li>
               <li class="bill-detail__item" @click="redirectTo('data')">
                  <div class="bill-detail__detail">
                     <span class="bill-detail__icon"><i class="icon-fonts icon-fonts--data"></i></span>
                     <div class="bill-detail__info">
                        <h5 class="bill-detail__name">
                           <a class="bill-detail__name-link" href="javascript:void(0)">{{
                              $t('mobile.charge_history.data')
                           }}</a>
                        </h5>
                        <span class="bill-detail__kip"
                           >{{ Number(dataFee).toLocaleString('en-US', { maximumFractionDigits: 0 }) }}
                           {{ $t('unit.kip') }}</span
                        >
                     </div>
                  </div>
                  <span class="bill-detail__action"><i class="icon-fonts icon-fonts--arrow-right"></i></span>
               </li>
               <li class="bill-detail__item" @click="redirectTo('vas-services')">
                  <div class="bill-detail__detail">
                     <span class="bill-detail__icon"><i class="icon-fonts icon-fonts--squares"></i></span>
                     <div class="bill-detail__info">
                        <h5 class="bill-detail__name">
                           <a class="bill-detail__name-link" href="javascript:void(0)">{{
                              $t('mobile.charge_history.other_vas_services')
                           }}</a>
                        </h5>
                        <span class="bill-detail__kip"
                           >{{ Number(vasFee).toLocaleString('en-US', { maximumFractionDigits: 0 }) }}
                           {{ $t('unit.kip') }}</span
                        >
                     </div>
                  </div>
                  <span class="bill-detail__action"><i class="icon-fonts icon-fonts--arrow-right"></i></span>
               </li>
            </ul>
         </div>
      </div>
   </div>
</template>
<script>
import { GChart } from 'vue-google-charts'
import BaseComponent from '@/mixins/BaseComponent'
import { DATE_FORMAT } from './../../constants/data'
import service from './../../services/MobileService'

export default {
   name: 'BillPrepaid',
   mixins: [BaseComponent],
   components: { GChart },
   data() {
      return {
         startDate: this.getDateFromMiliseconds(this.$route.params.startTime, DATE_FORMAT),
         endDate: this.getDateFromMiliseconds(this.$route.params.endTime, DATE_FORMAT),
         usedData: [],
         rechargeData: [],
         totalCharges: 0,
         mainAccount: 0,
         promoAccount: 0,
         callFee: 0,
         smsFee: 0,
         dataFee: 0,
         vasFee: 0,
         callRecords: 0,
         smsRecords: 0,
         dataRecords: 0,
         chartData: [['Month', 'Recharge', 'Used']],
         chartOptions: {
            title: '(' + this.$i18n.t('unit.upper_kip') + ')',
            titleTextStyle: {
               bold: false,
               fontSize: 14,
               color: '#34425B'
            },
            colors: ['#F68B1F', '#3F51B5'],
            legend: {
               position: 'none'
            },
            chartArea: {
               bottom: 20,
               top: 30,
               left: 50,
               right: 50
            },
            bar: { groupWidth: '30%' }
         }
      }
   },
   created() {
      this.setTitle(this.$i18n.t('mobile.charge_history.prepaid_information'))
      this.loadData()
   },
   methods: {
      loadData() {
         let loadUsedData = service.getPrePostageForGraph({ postType: '5' }).then((response) => {
            this.usedData = response.wsResponse
         })

         let loadRechargeData = service.getRefillReport().then((response) => {
            this.rechargeData = response.wsResponse
         })

         let loadPostageInfo = service
            .getPostageInfo({
               startDate: this.$route.params.startTime,
               endDate: this.$route.params.endTime,
               type: '',
               subType: 1
            })
            .then((response) => {
               this.totalCharges =
                  response.wsResponse.callFee +
                  response.wsResponse.smsFee +
                  response.wsResponse.dataFee +
                  response.wsResponse.otherFee
               this.mainAccount = response.wsResponse.basic
               this.promoAccount = response.wsResponse.prom
               this.callFee = response.wsResponse.callFee
               this.smsFee = response.wsResponse.smsFee
               this.dataFee = response.wsResponse.dataFee
               this.vasFee = response.wsResponse.vasFee
               this.callRecords = response.wsResponse.callRc
               this.smsRecords = response.wsResponse.smsRc
               this.dataRecords = response.wsResponse.dataRc
            })

         this.showLoading()
         Promise.allSettled([loadUsedData, loadRechargeData, loadPostageInfo]).finally(() => {
            let dataArr = []
            if (this.usedData.length > 0 && this.rechargeData.length > 0) {
               for (let i = 0; i < this.usedData.length; i++) {
                  dataArr.push([
                     this.usedData[i]['time'].split('-').reverse().join('/'),
                     this.rechargeData[i]['totalAmount'],
                     this.usedData[i]['totalCharge']
                  ])
               }
            }
            let total = dataArr.reduce(function (total, item) {
               return total + item[1] + item[2]
            }, 0)

            if (total === 0) {
               this.chartOptions['vAxis'] = {
                  viewWindow: {
                     min: 0,
                     max: 1
                  }
               }
            }

            this.chartData = this.chartData.concat(dataArr)
            this.hideLoading()
         })
      },
      redirectTo(path) {
         if (path == 'total-charge') {
            this.$router.push(
               `/mobile/charge-history/${path}/${this.$route.params.startTime}/${this.$route.params.endTime}`
            )
         } else {
            let numberOfRecords = 0
            switch (path) {
               case 'calls':
                  numberOfRecords = this.callRecords
                  break
               case 'data':
                  numberOfRecords = this.dataRecords
                  break
               case 'sms':
                  numberOfRecords = this.smsRecords
                  break
            }
            this.$router.push(
               `/mobile/charge-history/${path}/${this.$route.params.startTime}/${this.$route.params.endTime}/${numberOfRecords}`
            )
         }
      }
   }
}
</script>
