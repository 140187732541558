<template>
   <div class="main-container">
      <div class="main-content">
         <div class="recharge-done">
            <div class="recharge-prepaid__content">
               <!-- <div class="recharge-done__share"><a class="recharge-done__link" href="#">Share</a></div> -->
               <div class="recharge-done__top">
                  <p class="recharge-done__time">{{ now }}</p>
                  <h3 class="recharge-done__title">{{ $t('mobile.recharge_done.title') }}</h3>
                  <p class="recharge-done__price">
                     <!-- <span class="recharge-done__price-value">10,000.00</span -->
                     <!-- ><span class="recharge-done__price-kip">₭</span> -->
                  </p>
               </div>
               <div class="receiver-info">
                  <h4 class="receiver-info__sub">{{ $t('mobile.recharge_done.receiver') }}</h4>
                  <div class="receiver-info__content">
                     <div class="receiver-info__images">
                        <img src="@/assets/images/avatar-1.svg" alt="img" /><span class="receiver-info__status">
                           <!-- <img src="@/assets/images/ic-done.svg" alt="img"/> -->
                        </span>
                     </div>
                     <div class="receiver-info__detail">
                        <h5 class="receiver-info__name">{{ customerName }}</h5>
                        <p class="receiver-info__des">
                           <span class="receiver-info__value">{{ phoneNumber }}</span>
                           <!-- <span class="receiver-info__icon"><img src="@/assets/images/ic-tel.svg" alt="img" /></span -->
                           <!-- ><span class="receiver-info__value">{{ $t('mobile.recharge_done.mobile_balance') }}</span> -->
                        </p>
                     </div>
                  </div>
               </div>
               <ul class="recharge-done__list">
                  <!-- <li class="recharge-done__item">
                     <p class="recharge-done__name">{{ $t('mobile.recharge_done.service') }}</p>
                     <p class="recharge-done__value">{{ $t('mobile.recharge_done.prepaid_recharge') }}</p>
                  </li> -->
                  <li class="recharge-done__item">
                     <p class="recharge-done__name">{{ $t('mobile.recharge_done.carrier') }}</p>
                     <p class="recharge-done__value">Unitel</p>
                  </li>
                  <li class="recharge-done__item">
                     <p class="recharge-done__name">{{ $t('mobile.recharge_done.fee') }}</p>
                     <p class="recharge-done__value">{{ $t('mobile.recharge_done.free') }}</p>
                  </li>
                  <!-- <li class="recharge-done__item">
                     <p class="recharge-done__name">{{ $t('mobile.recharge_done.bill_number') }}</p>
                     <p class="recharge-done__value">CGA35J2UIO</p>
                  </li> -->
               </ul>
               <div class="recharge-footer">
                  <div class="recharge-footer__btn">
                     <button class="button button--primary button--full" @click="backToHome">
                        {{ $t('mobile.recharge_done.back_to_homepage') }}
                     </button>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>
<script>
import BaseComponent from '@/mixins/BaseComponent'
// import service from './../../services/MobileService'
import LocalStorage from '@/ultils/local-storage'
import moment from 'moment'
import store from '@/plugins/vuex/store'

export default {
   name: 'RechargeDone',
   mixins: [BaseComponent],
   data() {
      return {
         isLaoApp: false,
         customerName: 'CUSTOMER',
         phoneNumber: ''
      }
   },
   created() {
      this.setTitle(this.$i18n.t('mobile.recharge.title'))
      let appCode = LocalStorage.getItem('appCode')
      if (appCode === 'LaoApp') {
         this.isLaoApp = true
      }
      this.phoneNumber = store.state.phoneScratchCard
   },
   methods: {
      backToHome() {
         this.$router.push('/home')
      }
   },
   computed: {
      now() {
         const date = new Date()
         return moment(date).format('DD/MM/YYYY - HH:mm:ss')
      }
   }
}
</script>

<style scoped>
.recharge-done__value {
   font-weight: 600;
}
</style>
