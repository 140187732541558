<template>
   <div class="main-container">
      <div class="nav-bar js-nav-bar" @click="goBack">
         <div class="nav-bar__inner">
            <span class="nav-bar__icon"><i class="icon-fonts icon-fonts--arrow-left"></i></span>
            <h3 class="nav-bar__title">{{ $t('home.categories.digital_service') }}</h3>
         </div>
      </div>
      <div class="main-content">
         <div class="category-list">
            <ul class="category-list__content">
               <li
                  class="category-list__item"
                  v-for="service in digitalServiceList"
                  :key="service.name"
                  @click="setSelectedService(service)"
               >
                  <a class="category-list__link" href="javascript:void(0)">
                     <div class="category-list__images"><img :src="service.image" alt="img" /></div>
                     <div class="category-list__detail">
                        <div class="category-list__info">
                           <h5 class="category-list__name">{{ service.name }}</h5>
                           <p class="category-list__des">{{ service.shortUrl }}</p>
                        </div>
                        <span class="category-list__action"><i class="icon-fonts icon-fonts--arrow-right"></i></span>
                     </div>
                  </a>
               </li>
            </ul>
         </div>
      </div>
      <view-modal :data="view" />
   </div>
</template>
<script>
import BaseComponent from '@/mixins/BaseComponent'
import { DigitalServiceList } from '../constants/data'
import ViewModal from '@/components/modal/ViewModal'

export default {
   name: 'DigitalService',
   components: {
      ViewModal
   },
   data() {
      return {
         digitalServiceList: DigitalServiceList,
         selectedService: {},
         view: {
            name: '',
            url: ''
         }
      }
   },
   mixins: [BaseComponent],
   created() {
      this.setTitle(this.$i18n.t('home.categories.digital_service'))
   },
   methods: {
      setSelectedService(selectedService) {
         this.view = {
            name: selectedService.name,
            url: selectedService.fullUrl
         }
      }
   }
}
</script>
