<template>
   <div class="main-container">
      <div class="nav-bar js-nav-bar">
          <div class="nav-bar__inner"><span class="nav-bar__icon"><i class="icon-fonts icon-fonts--arrow-left"></i></span>
            <h3 class="nav-bar__title">Change to Postpaid</h3>
          </div>
        </div>
        <div class="main-content">
          <div class="change-postpaid">
            <h4 class="change-postpaid__title">Personal Information</h4>
            <ValidationObserver v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(btnSubmit)">
              <div class="register-info">
              <div class="register-info__form">
                <div class="form-customize">
                  <Input 
                        label="Phone number"
                        placeholder="Phone number"
                        :isLabel="true"
                        :hasStar="true"
                        rules="required|phone"
                        type="number"
                        v-model="customer_info.phone" />
                </div>
                <div class="form-customize">
                  <Input 
                        label="Personal information"
                        placeholder="Nguyen Van A"
                        :isLabel="true"
                        :hasStar="true"
                        rules="required"
                        type="text"
                        v-model="customer_info.full_name" />
                </div>
                <div class="form-customize">
                  <div class="form-input">
                    <Input 
                        placeholder="Ngày sinh"
                        :isLabel="false"
                        :hasStar="false"
                        type="date"
                        v-model="customer_info.date_of_birth" />
                  </div>
                </div>
                <div class="form-customize">
                  <div class="select-box js-select-box">
                    <select class="js-custom-select" data-placeholder="Gender">
                      <option value=""></option>
                      <option value="1">Male</option>
                      <option value="2">Female</option>
                    </select>
                  </div>
                </div>
                <div class="form-customize">
                  <Input 
                        label="Address"
                        placeholder="saythany, vientiane capital"
                        :isLabel="true"
                        :hasStar="false"
                        type="text"
                        v-model="customer_info.address" />
                </div>
              </div>
              <div class="register-info__btn">
                <button class="button button--primary button--full">Submit
                </button>
              </div>
            </div>
            </form>
          </ValidationObserver>
            
          </div>
        </div>
      <confirmation-modal :data="dataPopupComfirm" :onConfirm="onconfirmAirTime" :onCancel="onCancel" />
   </div>
</template>
<script>
import BaseComponent from '@/mixins/BaseComponent'
// import service from './../services/ConvertService'
import ConfirmationModal from '@/components/modal/ConfirmationModal'
//import store from '@/plugins/vuex/store'
import { ValidationObserver } from 'vee-validate'
import Input from '@/components/form/Input'
import LocalStorage from '@/ultils/local-storage'

export default {
   name: 'ConvertService',
   mixins: [BaseComponent],
   components: {
        ConfirmationModal,
        ValidationObserver,
        Input
   },
   data() {
      return {
         customer_info:{
             cusId:"",
             contactPhone:"",
             cusName:"",
             date_of_birth:"",
             fullAddress:"",
             provinceCode:"",
             districtCode:"",
             precinctCode:"",
             product:[
                 {
                     productId:"",
                     productName:"",
                     orderTypeName:"TRANSFER_PRE_TO_POS",
                     orderTypeCode:4
                 }
             ]
         },
         dataPopupComfirm: {
            title: '',
            content: '',
            cancelButton: '',
            confirmButton: '',
            confirmedData: {}
         },
      }
   },
   created() {
        this.loadScript('js/select2.js')
   },
   methods: {
       btnSubmit(){
           this.dataPopupComfirm = {
                title: this.$i18n.t('mobile.credit_service.airtime_data'),
                content:
                this.$i18n.t('mobile.credit_service.content1'),
                cancelButton: this.$i18n.t('buttons.cancel'),
                confirmButton: this.$i18n.t('buttons.confirm'),
                confirmedData: {

                }
            }
       },
       onCancel() {},
        onconfirmAirTime() {
            console.log('this.customer_info',this.customer_info)
            let userInfo = JSON.parse(LocalStorage.getItem('user_info')) ?? {}
            let dataPost = {...this.customer_info}
            dataPost.cusId = userInfo['isdn']
            dataPost.product[0].productId = userInfo['isdn']
            dataPost.product[0].productName = userInfo['isdn']
            
            // service
            //     .CreateOrderPost2Pre(dataPost)
            //     .then((response) => {
            //         console.log('response.object.listApDomain',response.object.listApDomain)
            //         this.loadScript('js/select2.js')
            //     })
            //     .finally(() => {
            //     // this.hideLoading()
            //     })
            
            this.$router.push('/convert/request-success')
        },
   },
}
</script>
