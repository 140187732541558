<template>
   <div class="main-container">
      <div class="nav-bar js-nav-bar" @click="goBack">
         <div class="nav-bar__inner">
            <span class="nav-bar__icon"><i class="icon-fonts icon-fonts--arrow-left"></i></span>
            <h3 class="nav-bar__title">{{ name }}</h3>
         </div>
      </div>
      <div class="main-content">
         <div class="debt-charges">
            <p class="time-charge">{{ startDate }} - {{ endDate }}</p>
            <vc-donut
               v-if="totalCharges"
               background="white"
               foreground="grey"
               :size="160"
               unit="px"
               :thickness="15"
               :sections="donutData"
               :total="1"
            >
               <p style="color: #f68b1f; font-size: 15px; font-weight: 500">
                  {{ Number(totalCharges).toLocaleString('en-US', { maximumFractionDigits: 0 }) }}
                  <span style="font-size: 13px; font-weight: 400">{{ $t('unit.kip') }}</span>
               </p>
               <p style="margin: 0; color: #3d5793; font-size: 13px">
                  {{ $t('mobile.charge_history.total_charges') }}
               </p>
            </vc-donut>
            <vc-donut v-if="!totalCharges" background="white" foreground="grey" :size="160" unit="px" :thickness="15">
               <p style="color: #f68b1f; font-size: 15px; font-weight: 500">
                  {{ Number(totalCharges).toLocaleString('en-US', { maximumFractionDigits: 0 }) }}
                  <span style="font-size: 13px; font-weight: 400">{{ $t('unit.kip') }}</span>
               </p>
               <p style="margin: 0; color: #3d5793; font-size: 13px">
                  {{ $t('mobile.charge_history.total_charges') }}
               </p>
            </vc-donut>
            <ul class="debt-charges__list">
               <li class="debt-charges__item" @click="redirectTo('calls')">
                  <div class="debt-charges__detail">
                     <span class="debt-charges__dot call"></span
                     ><span class="debt-charges__name">{{ $t('mobile.charge_history.call_fee') }}</span>
                  </div>
                  <div class="debt-charges__info">
                     <span class="debt-charges__skip"
                        >{{ Number(callFee).toLocaleString('en-US', { maximumFractionDigits: 0 }) }}
                        {{ $t('unit.kip') }}</span
                     ><span class="debt-charges__action"><i class="icon-fonts icon-fonts--arrow-right"></i></span>
                  </div>
               </li>
               <li class="debt-charges__item" @click="redirectTo('sms')">
                  <div class="debt-charges__detail">
                     <span class="debt-charges__dot sms"></span
                     ><span class="debt-charges__name">{{ $t('mobile.charge_history.sms_fee') }}</span>
                  </div>
                  <div class="debt-charges__info">
                     <span class="debt-charges__skip"
                        >{{ Number(smsFee).toLocaleString('en-US', { maximumFractionDigits: 0 }) }}
                        {{ $t('unit.kip') }}</span
                     ><span class="debt-charges__action"><i class="icon-fonts icon-fonts--arrow-right"></i></span>
                  </div>
               </li>
               <li class="debt-charges__item" @click="redirectTo('data')">
                  <div class="debt-charges__detail">
                     <span class="debt-charges__dot data"></span
                     ><span class="debt-charges__name">{{ $t('mobile.charge_history.data_fee') }}</span>
                  </div>
                  <div class="debt-charges__info">
                     <span class="debt-charges__skip"
                        >{{ Number(dataFee).toLocaleString('en-US', { maximumFractionDigits: 0 }) }}
                        {{ $t('unit.kip') }}</span
                     ><span class="debt-charges__action"><i class="icon-fonts icon-fonts--arrow-right"></i></span>
                  </div>
               </li>
               <li class="debt-charges__item" @click="redirectTo('vas-services')">
                  <div class="debt-charges__detail">
                     <span class="debt-charges__dot vas"></span
                     ><span class="debt-charges__name">{{ $t('mobile.charge_history.vas_services') }}</span>
                  </div>
                  <div class="debt-charges__info">
                     <span class="debt-charges__skip"
                        >{{ Number(vasFee).toLocaleString('en-US', { maximumFractionDigits: 0 }) }}
                        {{ $t('unit.kip') }}</span
                     ><span class="debt-charges__action"><i class="icon-fonts icon-fonts--arrow-right"></i></span>
                  </div>
               </li>
            </ul>
         </div>
      </div>
   </div>
</template>
<script>
import BaseComponent from '@/mixins/BaseComponent'
import { DATE_FORMAT } from './../../constants/data'
import service from './../../services/MobileService'
import Donut from 'vue-css-donut-chart'
import 'vue-css-donut-chart/dist/vcdonut.css'
import Vue from 'vue'
import LocalStorage from '@/ultils/local-storage'

Vue.use(Donut)

export default {
   name: 'TotalCharge',
   mixins: [BaseComponent],
   data() {
      return {
         name: '',
         startDate: this.getDateFromMiliseconds(this.$route.params.startTime, DATE_FORMAT),
         endDate: this.getDateFromMiliseconds(this.$route.params.endTime, DATE_FORMAT),
         callFee: 0,
         smsFee: 0,
         dataFee: 0,
         vasFee: 0,
         callRecords: 0,
         smsRecords: 0,
         dataRecords: 0,
         totalCharges: 0,
         donutData: []
      }
   },
   created() {
      this.setTitle(this.name)
      this.subType = parseInt(LocalStorage.getItem('subType'))
      this.name = this.getName()
      this.loadData()
   },
   methods: {
      getName() {
         if (this.subType === 1) {
            return this.$i18n.t('mobile.charge_history.total_charges')
         } else {
            return this.$i18n.t('mobile.charge_history.debt_charges')
         }
      },
      loadData() {
         this.showLoading()
         service
            .getPostageInfo({
               startDate: this.$route.params.startTime,
               endDate: this.$route.params.endTime,
               type: '',
               subType: this.subType
            })
            .then((response) => {
               this.totalCharges =
                  response.wsResponse.callFee +
                  response.wsResponse.smsFee +
                  response.wsResponse.dataFee +
                  response.wsResponse.vasFee
               this.callFee = response.wsResponse.callFee
               this.smsFee = response.wsResponse.smsFee
               this.dataFee = response.wsResponse.dataFee
               this.vasFee = response.wsResponse.vasFee
               this.callRecords = response.wsResponse.callRc
               this.smsRecords = response.wsResponse.smsRc
               this.dataRecords = response.wsResponse.dataRc
               this.donutData = [
                  { label: 'Call fee', value: this.callFee / this.totalCharges, color: '#3BC46A' },
                  { label: 'SMS fee', value: this.smsFee / this.totalCharges, color: '#FEC500' },
                  { label: 'Data fee', value: this.dataFee / this.totalCharges, color: '#00A2FF' },
                  { label: 'Vas services', value: this.vasFee / this.totalCharges, color: '#F47920' }
               ]
            })
            .finally(() => {
               this.hideLoading()
            })
      },
      redirectTo(path) {
         let numberOfRecords = 0
         switch (path) {
            case 'calls':
               numberOfRecords = this.callRecords
               break
            case 'data':
               numberOfRecords = this.dataRecords
               break
            case 'sms':
               numberOfRecords = this.smsRecords
               break
         }
         this.$router.push(
            `/mobile/charge-history/${path}/${this.$route.params.startTime}/${this.$route.params.endTime}/${numberOfRecords}`
         )
      }
   }
}
</script>
