<template>
   <div class="main-container">
        <div class="nav-bar">
          <div :class="parseInt(user.status) == 1 ? ['nav-home'] :['nav-home','none']">
            <div class="nav-home__detail"><span class="nav-home__icon"><img :src="user.avatarUrl" alt="img"></span>
              <div class="nav-home__info"  @click="redirectTo('/user-info')">
                <h5 class="nav-home__number">{{ user.phone }}</h5>
                <p class="nav-home__des">{{ user.name }}</p>
              </div>
            </div>
            <!-- <div class="nav-home__logo"><a class="nav-home__logo-link" href="#"><img src="@/assets/images/logo-new.svg" alt="img"></a></div> -->
            <div class="nav-home__action">
              <button class="button button--transparent" @click="onClickFunction('/mobile/account-info')">{{ $t('home.view_detail') }}
              </button>
            </div>
          </div>
        </div>
        <div :class="parseInt(user.status) == 1 ? ['acc-auth','acc-auth--data'] : ['acc-auth','acc-auth--data','none']">
          <div class="box-data">
            <div @click="redirectToByVal(user.status)" :class="parseInt(user.status) == 1 ? ['box-data__status','success'] : ['box-data__status', 'danger']"><span class="box-data__status-icon"><i :class="parseInt(user.status) == 1 ? ['icon-fonts','icon-fonts--tick'] : ['icon-fonts', 'icon-fonts--alert']"></i></span><span class="box-data__status-name">3Grab</span></div>
            <ul class="box-data__list" v-if="subType == 1">
              <li class="box-data__item" @click="onClickFunction('/mobile/account-info')">
                <h5 class="box-data__name">{{ $t('mobile.home.main_account') }}</h5><span class="box-data__value">{{Number(accountInfo.mainAcc).toLocaleString('en-US', { maximumFractionDigits: 0 })}}<span>{{ $t('unit.upper_kip') }}</span></span>
              </li>
            </ul>
            <ul class="box-data__list" v-if="subType == 2">
              <li class="box-data__item" @click="onClickFunction('/mobile/account-info')">
                <h5 class="box-data__name">{{ $t('mobile.home.hot_change') }}</h5><span class="box-data__value">{{Number(accountInfo.prePost).toLocaleString('en-US', { maximumFractionDigits: 0 })}}<span>{{ $t('unit.upper_kip') }}</span></span>
              </li>
            </ul>
            <!-- <div class="process-data process-data--full">
              <div class="process-data__top">
                <div class="process-data__info">
                  <h4 class="process-data__name">4ever</h4>
                </div>
                <div class="process-data__capacity"><span class="process-data__capacity-use">1.854 MB</span><span class="process-data__capacity-total">/4 GB</span></div>
              </div>
              <div class="process-bar">
                <p class="process-bar__percent" style="width: 60%"></p>
              </div>
            </div> -->
            <div class="process-data process-data--full">
                <div class="process-data__detail" @click="onClickFunction('/mobile/account-info')">
                    <div class="process-data__top">
                      <div class="process-data__info">
                          <h4 class="process-data__name">{{ $t('mobile.account_information.data') }}</h4>
                          <!-- <span class="process-data__tag">{{ $t('mobile.home.data') }}</span> -->
                      </div>
                      <div class="process-data__capacity">
                          <span class="process-data__capacity-total"
                            >{{ Number(accountInfo.dataVolume).toLocaleString('en-US', { maximumFractionDigits: 0 }) }}
                            {{ $t('unit.mb') }}</span
                          >
                      </div>
                    </div>
                    <div class="process-bar">
                      <p class="process-bar__percent" :style="processStyle"></p>
                    </div>
                </div>
                <!-- <span class="process-data__action"  @click="onClickFunction('/mobile/credit-service?tab=2')"
                    ><i class="icon-fonts icon-fonts--add-2"></i
                ></span> -->
              </div>
          </div>
        </div>
        <template>
          <div v-for="(item, i) in lstNoti" :key="i">
          <!-- && item.isDeleteCH -->
            <template v-if="item.type == '3GRAB' && item.isDeleteCH">
              <div class="noti-custom"><span class="noti-custom__action"><i class="icon-fonts icon-fonts--close" @click="deleteNoti(item.type)"></i></span>
                <div class="noti-custom__detail" @click="onClickFunction('/laokyc')"><span class="noti-custom__icon"><i class="icon-fonts icon-fonts--notice"></i></span>
                  <div class="noti-custom__info">
                    <p class="noti-custom__name">{{item.title}}</p>
                  </div>
                </div>
              </div>
            </template>
            <template v-if="item.type == 'BALANCE' && item.isDeleteBalance">
              <div class="noti-custom"><span class="noti-custom__action"><i class="icon-fonts icon-fonts--close" @click="deleteNoti(item.type)"></i></span>
                <div class="noti-custom__detail"><span class="noti-custom__icon"><i class="icon-fonts icon-fonts--notice"></i></span>
                  <div class="noti-custom__info">
                    <h5 class="noti-custom__name">{{$t('home.noti.notice')}}: {{item.title}}</h5>
                    <p class="noti-custom__des">{{item.des}}</p>
                    <div class="noti-custom__btn">
                      <button class="button button--orange button--small" @click="redirectMobileRecharge()">
                        {{$t('home.noti.recharge')}}
                        <!-- <a href="/mobile/recharge">{{$t('home.noti.recharge')}}</a> -->
                      </button>
                      <button class="button button--yellow button--small" style="cursor: pointer;" @click="redirectMobileBorrow()">
                        {{$t('home.noti.borrow')}}
                        <!-- <a href="/mobile/credit-service">{{$t('home.noti.borrow')}}</a> -->
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </template>
            <template v-if="item.type == 'DATA' && item.isDeleteData">
                <div class="noti-custom"><span class="noti-custom__action"><i class="icon-fonts icon-fonts--close" @click="deleteNoti(item.type)"></i></span>
                  <div class="noti-custom__detail"><span class="noti-custom__icon"><i class="icon-fonts icon-fonts--notice"></i></span>
                    <div class="noti-custom__info">
                      <h5 class="noti-custom__name">{{$t('home.noti.notice')}}: {{item.title}}</h5>
                      <p class="noti-custom__des">{{item.des}}</p>
                    </div>
                  </div>
                  <ul class="noti-list">
                    <template v-if="lstCurrentUsedSubServices && lstCurrentUsedSubServices.length != 0">
                      <li class="noti-list__item orange" @click="selectPack(lstCurrentUsedSubServices[0])"><span class="noti-list__badge"><span>{{$t('home.noti.recently')}}</span></span>
                        <h5 class="noti-list__name">{{lstCurrentUsedSubServices[0].name}}</h5>
                        <div class="noti-list__info">
                          <span class="noti-list__value">{{lstCurrentUsedSubServices[0].des.split("=")[0]}}</span>
                          <span class="noti-list__value">{{lstCurrentUsedSubServices[0].des.split("=")[1]}}</span>
                        </div>
                      </li>
                    </template>
                    <template v-if="lstServiceBestSeller && lstServiceBestSeller.length !=0">
                      <li class="noti-list__item yellow" @click="selectPack(lstServiceBestSeller[0])"><span class="noti-list__badge"><span>{{$t('home.noti.best_seller')}}</span></span>
                        <h5 class="noti-list__name">{{lstServiceBestSeller[0].name ?lstServiceBestSeller[0].name : lstServiceBestSeller[0].code}}</h5>
                        
                        <div class="noti-list__info"><span class="noti-list__value">{{ (lstServiceBestSeller[0].des && lstServiceBestSeller[0].des.split("=")[0]) ? lstServiceBestSeller[0].des.split("=")[0] : lstServiceBestSeller[0].des}}</span><span class="noti-list__value">{{(lstServiceBestSeller[0].des && lstServiceBestSeller[0].des.split("=")[1]) ? lstServiceBestSeller[0].des.split("=")[1] : lstServiceBestSeller[0].des}}</span></div>
                      </li>
                    </template>
                    <li class="noti-list__item brown" @click="borrowData"><span class="noti-list__action"><i class="icon-fonts icon-fonts--add"></i></span>
                      <div class="noti-list__info"><span class="noti-list__value">{{$t('home.noti.borrow_data')}}</span></div>
                    </li>
                  </ul>
                </div>
            </template>
          </div>
        </template>
        <div class="feature-main">
          <div class="feature-main__top">
            <h4 class="feature-main__sub">{{ $t('home.main_functions') }}</h4>
          </div>
          <ul class="feature-main__list">
            <li class="feature-main__item" v-for="favourite in favouriteList" :key="favourite.name"><a class="feature-main__link" @click="redirectToFunc(favourite)"><span class="feature-main__icon"><img :src="favourite.icon" alt="img"></span>
                <h5 class="feature-main__name">{{ favourite.name }}</h5>
              </a>
            </li>
          </ul>
        </div>
        <div class="banner-slider" v-if="lstslideShow && lstslideShow.length !=0">
         <ul class="banner-slider__list js-slider-col1">
            <li class="banner-slider__item" v-for="(banner,index) in lstslideShow[0].images" :key="index">
                <a class="service-pack__link" :href="banner.link">
                        <img :src="banner.url" alt="img" />
                </a>
            </li>
         </ul>
        </div>
        <div class="feature-basic">
         <div class="feature-basic__top">
            <h4 class="feature-basic__sub">{{ $t('home.main_services') }}</h4>
          </div>
         <ul class="feature-basic__list">
            <li class="feature-basic__item noti-custom" v-for="app in appList" :key="app.name">
               <a
                  class="feature-basic__link"
                  href="javascript:void(0)"
                  @click="redirectToApp(app)"
                  v-if="!app.openViewModal"
                  ><span class="feature-basic__icon"><img :src="app.icon" alt="img" /></span>
                  <h5 class="feature-basic__name">{{ app.name }}</h5>
               </a>
               <a
                  class="feature-basic__link"
                  href="javascript:void(0)"
                  v-if="app.openViewModal"
                  @click="setViewModal(app.name, app.url)"
                  ><span class="feature-basic__icon"><img :src="app.icon" alt="img" /></span>
                  <h5 class="feature-basic__name">{{ app.name }}</h5>
               </a>
            </li>
         </ul>
        </div>
        <!-- <div class="feature-favourite" v-if="true">
         <ul class="feature-favourite__list">
            <li class="feature-favourite__item" v-for="favourite in favouriteList" :key="favourite.name">
               <a class="feature-favourite__link" @click="redirectToFunc(favourite)" v-if="!favourite.openViewModal"
                  ><span class="feature-favourite__icon"><img :src="favourite.icon" alt="img" /></span>
                  <h5 class="feature-favourite__name">{{ favourite.name }}</h5>
               </a>
               <a
                  class="feature-favourite__link"
                  @click="setViewModal(favourite.name, favourite.url)"
                  v-if="favourite.openViewModal"
                  ><span class="feature-favourite__icon"><img :src="favourite.icon" alt="img" /></span>
                  <h5 class="feature-favourite__name">{{ favourite.name }}</h5>
               </a>
            </li>
            <li class="feature-favourite__item">
               <a class="feature-favourite__link" href="javascript:void(0)"
                  ><span class="feature-favourite__icon"
                     ><img src="@/assets/images/ic-notification.svg" alt="img"
                  /></span>
                  <h5 class="feature-favourite__name">{{ $t('home.functions.notification') }}</h5>
               </a>
            </li>
            <li class="feature-favourite__item">
               <a class="feature-favourite__link" href="#/favourite-list"
                  ><span class="feature-favourite__icon"><img src="@/assets/images/ic-more.svg" alt="img" /></span>
                  <h5 class="feature-favourite__name">{{ $t('home.functions.more') }}</h5>
               </a>
            </li> 
         </ul>
      </div> -->
        <template v-if="lstslideShow && lstslideShow.length > 1">
          <div v-for="item in lstslideShow.slice(1)" :key="item.id">
            <template v-if="item.type == 1 && item.images.length > 0">
              <div class="banner-slider">
                <ul class="banner-slider__list js-slider-col2">
                    <li class="banner-slider__item" v-for="(banner,index) in item.images" :key="index">
                      <a class="service-pack__link" :href="banner.link">
                        <img :src="banner.url" alt="img" />
                      </a>
                    </li>
                    <!-- <li class="banner-slider__item"><img src="@/assets/images/banner-home.png" alt="img"></li>
                    <li class="banner-slider__item"><img src="@/assets/images/banner-home.png" alt="img"></li>
                    <li class="banner-slider__item"><img src="@/assets/images/banner-home.png" alt="img"></li>
                    <li class="banner-slider__item"><img src="@/assets/images/banner-home.png" alt="img"></li>
                    <li class="banner-slider__item"><img src="@/assets/images/banner-home.png" alt="img"></li> -->
                </ul>
              </div>
            </template>
            <template v-if="item.type == 2 && item.images.length > 0">
              <div class="feature-service">
                <div class="feature-service__top">
                  <h4 class="feature-service__sub">{{item.title}}</h4><a class="feature-service__link" :href="item.linkSeeMore">{{$t('home.noti.see_more')}}</a>
                </div>
                <div class="service-pack">
                  <ul class="service-pack__list">
                    <li class="service-pack__item" v-for="(banner,index) in item.images" :key="index">
                      <a class="service-pack__link" :href="banner.link">
                          <div class="service-pack__images"><img :src="banner.url" alt="img"></div>
                          <div class="service-pack__detail">
                            <div class="service-pack__info">
                              <div class="service-pack__top">
                                <h5 class="service-pack__name">{{banner.title}}</h5>
                              </div>
                              <p class="service-pack__des">{{banner.des}}</p>
                            </div><span class="service-pack__action" @click="onClickFunction(banner.link)"><i class="icon-fonts icon-fonts--arrow-right"></i></span>
                          </div>
                        </a>
                    </li>
                  </ul>
                </div>
              </div>
            </template>
            <template v-if="item.type == 3 && item.images.length > 0">
              <div class="feature-service">
                <div class="feature-service__top">
                  <h4 class="feature-service__sub">{{item.title}}</h4><a class="feature-service__link" :href="item.linkSeeMore">{{$t('home.noti.see_more')}}</a>
                </div>
                <div class="service-pack service-pack--style3">
                  <ul class="service-pack__list">
                    <li class="service-pack__item" v-for="(banner,index) in item.images" :key="index">
                      <a class="service-pack__link" :href="banner.link">
                        <div class="service-pack__images"><img :src="banner.url" alt="img"></div>
                        <div class="service-pack__detail">
                          <div class="service-pack__info">
                            <div class="service-pack__top">
                              <h5 class="service-pack__name">{{banner.title}}</h5>
                            </div>
                            <p class="service-pack__des">{{banner.des}}</p>
                          </div><span class="service-pack__action" @click="onClickFunction(banner.link)"><i class="icon-fonts icon-fonts--arrow-right"></i></span>
                        </div>
                      </a>
                      </li>
                  </ul>
                </div>
              </div>
            </template>
            <template v-if="item.type == 4 && item.images.length > 0">
              <div class="feature-service">
                <div class="feature-service__top">
                  <h4 class="feature-service__sub">{{item.title}}</h4><a class="feature-service__link" :href="item.linkSeeMore">{{$t('home.noti.see_more')}}</a>
                </div>
                <div class="service-pack">
                  <ul class="service-pack__list">
                    <li class="service-pack__item" v-for="(banner,index) in item.images" :key="index">
                      <a class="service-pack__link" :href="banner.link">
                          <div class="service-pack__images"><img :src="banner.url" alt="img"></div>
                          <div class="service-pack__detail">
                            <div class="service-pack__info">
                              <div class="service-pack__top">
                                <h5 class="service-pack__name">{{banner.title}}</h5>
                              </div>
                              <p class="service-pack__des">{{banner.des}}</p>
                            </div><span class="service-pack__action" @click="onClickFunction(banner.link)"><i class="icon-fonts icon-fonts--arrow-right"></i></span>
                          </div>
                        </a>
                    </li>

                  </ul>
                </div>
              </div>
            </template>
            <template v-if="item.type == 5 && item.images.length > 0">
              <div class="feature-service">
                  <div class="feature-service__top">
                    <h4 class="feature-service__sub">{{item.title}}</h4><a class="feature-service__link" :href="item.linkSeeMore">{{$t('home.noti.see_more')}}</a>
                  </div>
                  <div class="service-pack service-pack--style5">
                    <ul class="service-pack__list">
                      <li class="service-pack__item" v-for="(banner,index) in item.images" :key="index">
                        <a class="service-pack__link" :href="banner.link">
                          <div class="service-pack__images"><img :src="banner.url" alt="img"></div>
                          <div class="service-pack__detail">
                            <div class="service-pack__info">
                              <div class="service-pack__top">
                                <h5 class="service-pack__name">{{banner.title}}</h5>
                              </div>
                              <p class="service-pack__des">{{banner.des}}</p>
                            </div><span class="service-pack__action" @click="onClickFunction(banner.link)"><i class="icon-fonts icon-fonts--arrow-right"></i></span>
                          </div>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
            </template>
            <template v-if="item.type == 6 && item.images.length > 0">
              <div class="feature-service">
                <div class="feature-service__top">
                  <h4 class="feature-service__sub">{{item.title}}</h4><a class="feature-service__link" :href="item.linkSeeMore">{{$t('home.noti.see_more')}}</a>
                </div>
                <div class="service-pack service-pack--style6">
                  <ul class="service-pack__list">
                    <li class="service-pack__item" v-for="(banner,index) in item.images" :key="index">
                      <a class="service-pack__link" :href="banner.link">
                        <div class="service-pack__images"><img :src="banner.url" alt="img"></div>
                        <div class="service-pack__detail">
                          <div class="service-pack__info">
                            <div class="service-pack__top">
                              <h5 class="service-pack__name">{{banner.title}}</h5>
                            </div>
                          </div>
                        </div>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </template>
            <template v-if="item.type == 7 && item.images.length > 0">
              <div class="feature-service">
                <div class="feature-service__top">
                  <h4 class="feature-service__sub">{{item.title}}</h4><a class="feature-service__link" :href="item.linkSeeMore">{{$t('home.noti.see_more')}}</a>
                </div>
                <div class="service-pack">
                  <ul class="service-pack__list">
                    <li class="service-pack__item" v-for="(banner,index) in item.images" :key="index">
                      <a class="service-pack__link" :href="banner.link">
                        <div class="service-pack__images"><img :src="banner.url" alt="img"></div>
                        <div class="service-pack__detail">
                          <div class="service-pack__info">
                            <div class="service-pack__top">
                              <h5 class="service-pack__name">{{banner.title}}</h5>
                            </div>
                            <p class="service-pack__des"><span>$</span>{{banner.des}}</p>
                          </div><span class="service-pack__action" @click="onClickFunction(banner.link)"><i class="icon-fonts icon-fonts--arrow-right"></i></span>
                        </div>
                      </a></li>
                  </ul>
                </div>
              </div>
            </template>
            <template v-if="item.type == 8 && item.images.length > 0">
              <div class="feature-service">
                <div class="feature-service__top">
                  <h4 class="feature-service__sub">{{item.title}}</h4><a class="feature-service__link" :href="item.linkSeeMore">{{$t('home.noti.see_more')}}</a>
                </div>
                <div class="service-pack service-pack--style8">
                  <ul class="service-pack__list">
                    <li class="service-pack__item" v-for="(banner,index) in item.images" :key="index">
                      <a class="service-pack__link" :href="banner.link">
                        <div class="service-pack__images"><img :src="banner.url" alt="img"></div>
                        <div class="service-pack__hover">
                          <p class="service-pack__subdes">{{banner.title}}</p>
                          <h5 class="service-pack__subname">{{banner.des}}</h5>
                        </div>
                      </a></li>
                  </ul>
                </div>
              </div>
            </template>
            <template v-if="item.type == 9 && item.images.length > 0">
              <div class="feature-service">
                <div class="feature-service__top">
                  <h4 class="feature-service__sub">{{item.title}}</h4><a class="feature-service__link" :href="item.linkSeeMore">{{$t('home.noti.see_more')}}</a>
                </div>
                <div class="service-pack service-pack--style9">
                  <ul class="service-pack__list">
                    <li class="service-pack__item" v-for="(banner,index) in item.images" :key="index">
                      <a class="service-pack__link" :href="banner.link">
                        <div class="service-pack__images"><img :src="banner.url" alt="img"></div>
                        <div class="service-pack__detail">
                          <div class="service-pack__info">
                            <div class="service-pack__top">
                              <!-- <h5 class="service-pack__name">IR6</h5><span class="service-pack__data">3Mps</span> -->
                              <h5 class="service-pack__name">{{banner.title}}</h5><span class="service-pack__data">{{banner.speed}}</span>
                            </div>
                            <p class="service-pack__des">{{banner.des}}</p>
                          </div>
                        </div>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </template>
            <template v-if="item.type == 10 && item.images.length > 0">
              <div class="feature-service">
                <div class="feature-service__top">
                  <h4 class="feature-service__sub">{{item.title}}</h4><a class="feature-service__link" :href="item.linkSeeMore">{{$t('home.noti.see_more')}}</a>
                </div>
                <div class="service-pack service-pack--style10">
                  <ul class="service-pack__list">
                    <li class="service-pack__item" v-for="(banner,index) in item.images" :key="index">
                      <a class="service-pack__link" :href="banner.link">
                        <div class="service-pack__images"><img :src="banner.url" alt="img"></div>
                        <div class="service-pack__hover">
                          <p class="service-pack__subdes">{{banner.title}}</p>
                          <h5 class="service-pack__subname">{{banner.des}}</h5>
                        </div>
                      </a></li>
                  </ul>
                </div>
              </div>
            </template>
            <template v-if="item.type == 11 && item.images.length > 0">
              <div class="feature-service">
                <div class="feature-service__top">
                  <h4 class="feature-service__sub">{{item.title}}</h4><a class="feature-service__link" :href="item.linkSeeMore">{{$t('home.noti.see_more')}}</a>
                </div>
                <div class="service-pack service-pack--style11">
                  <ul class="service-pack__list">
                    <li class="service-pack__item" v-for="(banner,index) in item.images" :key="index">
                      <a class="service-pack__link" :href="banner.link">
                        <div class="service-pack__images"><img :src="banner.url" alt="img"></div>
                        <div class="service-pack__detail">
                          <div class="service-pack__info">
                            <div class="service-pack__top">
                              <h5 class="service-pack__name">{{banner.title}}</h5>
                            </div>
                            <p class="service-pack__des">{{banner.des}}</p>
                          </div><span class="service-pack__action" @click="onClickFunction(banner.link)"><i class="icon-fonts icon-fonts--arrow-right"></i></span>
                        </div>
                      </a></li>
                  </ul>
                </div>
              </div>
            </template>
          </div>
        </template>
       <register-pack-flow :data="selectedPack" />
      </div>
</template>
<script>
import BaseComponent from '@/mixins/BaseComponent'
import service from './../services/HomeService'
import { FavouriteList, AppList, Category } from '../constants/data'
// import ViewModal from '@/components/modal/ViewModal'
import LocalStorage from '@/ultils/local-storage'
import RegisterPackFlow from '../../mobile/components/common/RegisterPackFlow'
import $ from 'jquery'
import { callNative } from '@/ultils/call-native'

export default {
   name: 'HomeAuthenticated',
   mixins: [BaseComponent],
   components: {
      // ViewModal
      RegisterPackFlow
   },
   data() {
      return {
         banners: [],
         user: {
            name: '',
            rank: '',
            availabelUpoint: 0,
            expDate: '',
            avatarUrl: './images/ic-sim-mobile.svg',
            status: -1,
            phone: ''
         },
         favouriteList: [],
         appList: AppList,
         view: {
            name: '',
            url: ''
         },
         lstNoti:[],
         isCheckCH:false,
         isCheckData:false,
         isCheckBalance:false,
         accountInfo:{},
         lstCurrentUsedSubServices:[],
         lstServiceBestSeller:[],
         selectedPack:{},
         processStyle: 'width: 80%',
         lstslideShow:[],
         subType: 1
      }
   },
   async created() {
      this.setTitle(this.$i18n.t('home.logined.tab_title'))
      await this.loadData()
   },
   methods: {
    async onClickFunction(url) {
      this.$router.push(url)
    },
    selectPack(pack){
      this.selectedPack = Object.assign({}, pack)
    },
    borrowData(){
      if (this.subType == 1) {
            this.$router.push('/mobile/credit-service?tab=2')
         } else {
            this.$router.push('/mobile/data-package')
      }
    },
    redirectMobileRecharge(){
      this.$router.push('/mobile/recharge')
    },
    redirectMobileBorrow(){
      if (this.subType == 1) {
            this.$router.push('/mobile/credit-service')
         } else {
            this.$router.push('/mobile/data-package')
         }
    },
     deleteNoti(type){
        this.lstNoti.map(x=>{
          if(x.type == "DATA" && x.type == type){
            x.isDeleteData = false
          }
          if(x.type == "BALANCE" && x.type == type){
            x.isDeleteBalance = false
          }
          if(x.type == "3GRAB" && x.type == type){
            x.isDeleteCH = false
          }
        })
        console.log('this.lstNoti',this.lstNoti)
        this.$forceUpdate()
     },
      async loadData() {
        this.showLoading()
         this.favouriteList = JSON.parse(LocalStorage.getItem('favourite_list')) ?? FavouriteList
         let loadUserInfo = await service.getUserInfo().then((response) => {
            this.user.name = response.wsResponse.name
            this.user.status = (response.wsResponse.standardStatus === '1' && response.wsResponse.standardSystem==='3GRAB') ? response.wsResponse.standardStatus : 0
            this.user.avatarUrl = response.wsResponse.avatar_url?.trim()
               ? response.wsResponse.avatar_url
               : './images/ic-sim-mobile.svg'
            LocalStorage.setItem('subType', response.wsResponse.subType)
            let userInfo = JSON.parse(LocalStorage.getItem('user_info')) ?? {}
            this.user.phone = userInfo['isdn'] ?? ''
         })
         let loadRank = await service.getRank().then((response) => {
            this.user.rank = response.accountRankDTO?.rankName ?? ''
            this.user.expDate =
               this.user.rank === 'Loyal'
                  ? 'Unlimited'
                  : response.accountRankDTO?.endDate.substring(0, 10).split('-').reverse().join('/') ?? ''
         })
         let loadAvalableUpoint = await service.getAvailableUpoint().then((response) => {
            this.user.availabelUpoint = (response.listAccountPoint ?? []).reduce((total, accPoint) => {
               if (accPoint.pointType == 2) return total + accPoint.pointValue
               else return total
            }, 0)
         })
        //  await service.getListBanners({ limit: 5 }).then((response) => {
        //     this.banners = response.wsResponse
        //     if (this.banners.length > 0) {
        //        $(".js-slider-col1").css("display", "none")
        //           $.getScript('js/slider.js', () => {
        //               $(".js-slider-col1").css("display", "block")
        //           })
        //     }
        //  })
         let userDetail = JSON.parse(LocalStorage.getItem('user_info')) ?? {}
         let isdn = userDetail.isdn
         let getNoticeWebview = await service.GetNoticeWebview({"isdn":isdn}).then((response) => {
            this.lstNoti = response.object ?? []
         })
        // == 1 trả trước, ==2 trả sau
          this.subType = LocalStorage.getItem('subType')
         let getSubAccountInfo = await service.GetSubAccountInfo({"subType": this.subType}).then((response) => {
            // this.accountInfo = response.wsResponse ?? {}
            let data = response.wsResponse
            this.accountInfo = data ? {
              prePost: data.prePost ?? 0,
              debPost: data.debPost ?? 0,
              mainAcc: data.mainAcc ?? 0,
              proAcc: data.proAcc ?? 0,
              dataPkgName: data.name,
              dataVolume: data.dataVolume
            } : {}
         })
         Promise.allSettled([loadUserInfo, loadRank, loadAvalableUpoint,getNoticeWebview,getSubAccountInfo])
            .finally(() => {
         })
          this.isCheckData = false
          this.isCheckCH = false
          this.isCheckBalance = false
          // binhvt
          let isCurrentUsedSubServices = false 
          if(this.lstNoti && this.lstNoti.length != 0 && this.accountInfo){
            let dataVolume = (this.accountInfo.dataVolume) ?  parseInt(this.accountInfo.dataVolume): 0
            let mainAcc = (this.accountInfo.mainAcc) ?  parseInt(this.accountInfo.mainAcc): 0
            let debPost = (this.accountInfo.debPost) ?  parseInt(this.accountInfo.debPost): 0
            let _this = this
            this.lstNoti.map(x=>{
              // binhvt test default true, upcode default false
              x.isDeleteData = false,
              x.isDeleteBalance = false,
              x.isDeleteCH = false
            })
            this.lstNoti.map(x=>{
              if(x.type == "DATA" && (parseInt(x.value) > dataVolume)){
                x.isDeleteData = true
                isCurrentUsedSubServices = true
              }
              if(x.type == "BALANCE"){
                // == 1 trả trước, ==2 trả sau
                if(this.subType == 1 && parseInt(x.value) > mainAcc){
                  x.isDeleteBalance = true
                }
                if(this.subType == 2 && parseInt(x.value) > debPost){
                  x.isDeleteBalance = true
                }
              }
              if(x.type == "3GRAB" && parseInt(_this.user.status) != 1){
                x.isDeleteCH = true
              }
            })
            console.log('this.lstNoti',this.lstNoti)

            if(isCurrentUsedSubServices){
              await service.GetCurrentUsedSubServices({"isdn":isdn}).then((response) => {
                  this.lstCurrentUsedSubServices = response.wsResponse ?? []
              })

              await service.GetServiceBestSeller({"isdn":isdn}).then((response) => {
                  this.lstServiceBestSeller = response.object ?? []
              })
            }
          }
          console.log('lstServiceBestSeller',this.lstServiceBestSeller)

          try {
          await service.GetBannerWebview().then((response) => {
                  // this.lstslideShow = response.object.sort(function (a, b) {
                  //       return b.type - a.type;
                  //    }) ?? []
                   this.lstslideShow = response.object
              })
          } catch (error) {
            this.hideLoading()
          }

          console.log('this.lstslideShow',this.lstslideShow)
          this.$forceUpdate()
          if(this.lstslideShow && this.lstslideShow.length != 0){
            $(".js-slider-col2").css("display", "none")
                $.getScript('js/slider.js', () => {
                $(".js-slider-col2").css("display", "block")
            })
          }
          this.hideLoading()
      },
      setViewModal(name, url) {
         this.view = { name, url }
      },
      redirectToByVal(status){
        if(parseInt(status) == 1){
          this.$router.push("/user-info")
        }
        else{
          this.$router.push("/laokyc")
        }
      },
      redirectTo(url) {
         this.$router.push(url)
      },
      async redirectToApp(app) {
        let installLaoApp= false // debug
        // this.showLoading()
        // installLaoApp = await service.GetLaoAppStatus().then((response)=>{
        //   this.hideLoading()
        //   if (response.errorCode === '0') {
        //       let status = response.wsResponse.status
        //       if(status >= 2){
        //         return true;
        //       }
        //   }
        // })
        // .catch(() => {
        //        this.hideLoading()
        // })
        let appCode = LocalStorage.getItem('appCode')
        if(installLaoApp) {
            window.open(app.deepLink,'_blank')
          }else {
            if(app.url.includes('http') || app.url.includes('mocha:')){
              // window.open(app.url)
              callNative(app.callNative,'')
            }else {
              if ((app.name === this.$i18n.t('home.apps.internet_services')) && !appCode) {
                this.getListFtthAccountInfo()
              } else {
                if (appCode === 'LaoApp') {
                  window.open(app.deepLink)
                } else {
                  this.$router.push(app.url)
                }
              }
            }
        }
         // test deeplink
        //  if (app.isDeepLink) {
        //   // check install Lao app
        //   let installLaoApp= true
        //   if(installLaoApp){
        //     window.open(app.deepLink)
        //   }else {
        //     this.$router.push(app.url)
        //   }
        //  }
        //  else if (app.name === this.$i18n.t('home.apps.internet_services')) {
        //     this.getListFtthAccountInfo()
        //  } else {
        //     this.$router.push(app.url)
        //  }
      },
      async redirectToFunc(func) {
         let appCode = LocalStorage.getItem('appCode')
         if (func.category === Category.InternetService) {
            LocalStorage.setItem('ftth_redirect_url', func.url)
            this.$router.push('/ftth/login')
         } else if((func.url.includes('mocha:') || func.url.includes('laoapp.la') || func.url.includes('unitel.com.la')) && !appCode) {
            window.open(func.url)
         } else if(func.deepLink) {
            let installLaoApp= false // debug
            // this.showLoading()
            // installLaoApp = await service.GetLaoAppStatus().then((response)=>{
            //   this.hideLoading()
            //   if (response.errorCode === '0') {
            //       let status = response.wsResponse.status
            //       if(status >= 2){
            //         return true;
            //       }
            //   }
            // })
            // .catch(() => {
            //       this.hideLoading()
            // })
            if(installLaoApp) {
              window.open(func.deepLink)
            } else {
              let appCode = LocalStorage.getItem('appCode')
                if (appCode === 'LaoApp') {
                  window.open(func.deepLink)
                } else {
                  this.$router.push(func.url)
                }
            }
         }
          else {
            this.$router.push(func.url)
         }
      },
      getListFtthAccountInfo() {
         this.showLoading()
         service
            .getListFtthAccountInfo()
            .then((response) => {
               if (response.errorCode === '0') {
                  let result = response.wsResponse ?? []
                  if (result.length > 0) {
                     this.$router.push('/ftth/account-list')
                  } else {
                     this.$router.push('/ftth/login')
                  }
               } else {
                  this.$store.dispatch('setNotification', { content: response.message ?? response.userMsg })
                  this.hideLoading()
               }
            })
            .catch(() => {
               this.hideLoading()
            })
      }
   }
}
</script>
