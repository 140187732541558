<template>
   <div class="main-container">
      <div class="nav-bar js-nav-bar" @click="goBack">
         <div class="nav-bar__inner">
            <span class="nav-bar__icon"><i class="icon-fonts icon-fonts--arrow-left"></i></span>
            <h3 class="nav-bar__title">{{ $t('mobile.charge_history.other_vas_services') }}</h3>
         </div>
      </div>
      <div class="main-content">
         <div class="total-charges">
            <p class="time-charge">{{ startDate }} - {{ endDate }}</p>
            <div class="total-charges__detail">
               <div class="total-charges__row">
                  <div class="total-charges__top">
                     <h5 class="total-charges__top-name">{{ $t('mobile.charge_history.total_services') }}</h5>
                     <span class="total-charges__top-value">{{
                        Number(totalServices).toLocaleString('en-US', { maximumFractionDigits: 0 })
                     }}</span>
                  </div>
               </div>
               <div class="total-charges__row">
                  <div class="total-charges__top">
                     <h5 class="total-charges__top-name">{{ $t('mobile.charge_history.total_charges') }}</h5>
                     <span class="total-charges__top-value"
                        >{{ Number(totalCharges).toLocaleString('en-US', { maximumFractionDigits: 0 }) }}
                        {{ $t('unit.kip') }}</span
                     >
                  </div>
                  <ul class="total-charges__list">
                     <li class="total-charges__item" v-for="item in listData" :key="item.value">
                        <div class="total-charges__item-detail">
                           <span class="total-charges__item-icon"><i class="icon-fonts icon-fonts--wifi"></i></span>
                           <div class="total-charges__item-info">
                              <h5 class="total-charges__item-name">{{ item.value }}</h5>
                           </div>
                        </div>
                     </li>
                  </ul>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>
<script>
import BaseComponent from '@/mixins/BaseComponent'
import { DATE_FORMAT } from './../../constants/data'
import service from './../../services/MobileService'
import LocalStorage from '@/ultils/local-storage'

export default {
   name: 'VasServices',
   mixins: [BaseComponent],
   data() {
      return {
         startDate: this.getDateFromMiliseconds(this.$route.params.startTime, DATE_FORMAT),
         endDate: this.getDateFromMiliseconds(this.$route.params.endTime, DATE_FORMAT),
         totalServices: 0,
         totalCharges: 0,
         listData: []
      }
   },
   created() {
      this.setTitle(this.$i18n.t('mobile.charge_history.other_vas_services'))
      this.subType = parseInt(LocalStorage.getItem('subType'))
      this.loadData()
   },
   methods: {
      loadData() {
         this.showLoading()
         service
            .getPostageDetailInfo({
               postType: 2,
               pageSize: 10,
               subType: this.subType,
               sort: 0,
               pageNum: 1,
               startDate: this.$route.params.startTime,
               endDate: this.$route.params.endTime
            })
            .then((response) => {
               this.listData = response.wsResponse ?? []
               this.totalCharges = response.wsResponse.reduce(function (total, vas) {
                  return total + vas['total']
               }, 0)
               this.totalServices = this.listData.length
            })
            .finally(() => {
               this.hideLoading()
            })
      }
   }
}
</script>
