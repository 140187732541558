<template>
   <div class="main-container">
      <div class="nav-bar nav-bar--full" @click="goBack">
         <div class="nav-bar__inner">
            <span class="nav-bar__icon"><i class="icon-fonts icon-fonts--arrow-left"></i></span>
            <h3 class="nav-bar__title">{{ $t('ftth.ftth_package.title') }}</h3>
         </div>
      </div>
      <div class="main-content">
         <div class="ftth-pack">
            <ul class="ftth-pack__list">
               <li class="ftth-pack__item" v-for="item in data" :key="item.name">
                  <a class="ftth-pack__images"><img :src="item.iconUrl" alt="img" @click="onDetail(item)" /></a>
                  <div class="ftth-pack__detail">
                     <div class="ftth-pack__info" @click="onDetail(item)">
                        <div class="ftth-pack__top">
                           <h5 class="ftth-pack__name">
                              <a class="ftth-pack__link" href="javascript:void(0)">{{ item.name }}</a>
                           </h5>
                           <span class="ftth-pack__price"
                              ><i class="icon-fonts icon-fonts--price"></i
                              >{{ Number(item.subPrice).toLocaleString('en-US', { maximumFractionDigits: 0 }) }}
                              {{ $t('unit.upper_kip') }}</span
                           >
                        </div>
                        <span class="ftth-pack__data">{{ item.shortDes }}</span>
                        <p class="ftth-pack__des">{{ item.subShortDes }}</p>
                     </div>
                     <div class="ftth-pack__btn">
                        <button class="button button--primary button--small" @click="onOrder(item)">
                           {{ $t('ftth.ftth_package.order') }}
                        </button>
                     </div>
                  </div>
               </li>
            </ul>
         </div>
      </div>
   </div>
</template>
<script>
import BaseComponent from '@/mixins/BaseComponent'
import service from './../../services/FTTHService'
import store from '@/plugins/vuex/store'
import { callNative } from '@/ultils/call-native'

export default {
   name: 'FtthPackage',
   mixins: [BaseComponent],
   components: {},
   data() {
      return {
         data: []
      }
   },
   created() {
      this.setTitle(this.$i18n.t('ftth.ftth_package.title'))
      this.getData()
   },
   methods: {
      getData() {
         this.showLoading()
         var dataPost = {
            serviceGroupId: 'FTTH'
         }

         service
            .getListFtthServices(dataPost)
            .then((response) => {
               this.data = response.wsResponse
               this.hideLoading()
            })
            .catch(() => {
               this.hideLoading()
            })
      },
      onDetail(item) {
         store.commit('setFtthPackage', item.name)
         store.commit('setFtthPackageCode', item.code)
         this.$router.push('/ftth/ftth-package-detail')
      },
      onOrder(item) {
         callNative('open_shop',item.code)
         // store.commit('setFtthPackage', item.name)
         // store.commit('setFtthPackageCode', item.code)
         // this.$router.push('/ftth/ftth-order')
      }
   }
}
</script>
