import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
   state: {
      isLoading: false,
      notification: {
         title: '',
         content: '',
         closeButton: ''
      },
      scanText: '',
      qrMode: 'manual',
      phoneScratchCard: '',
      customerName: 'CUSTOMER',
      receiverIsdn: '',
      ftthPackage: '',
      ftthPackageCode: '',
      infoCusFtthPackage: {},
      urlImage: '',
      urlImageCrop: '',
      imageType: '',
      dataResInfo: {
         standard: 1,
         address: 'vn',
         Precinct: null,
         District: null,

         name: null,
         subType: 1,
         avatar_url: null,
         phone: null,

         phoneNumber: null,
         cusType: null,
         idType: null,
         idNo: null,
         fullName: '-',
         gender: null,
         doB: null,
         nationalities: null,
         Province: null,

         image1: './images/img-register-upload-1.png',
         image2: './images/img-register-upload-1.png',
         image3: './images/img-register-upload-2.png'
      },
      packageActiveTab: 1,
      change_to_prepaid: {
         cusId: '',
         contactPhone: '',
         cusName: '',
         birthOfDay: '',
         fullAddress: '',
         provinceCode: '01',
         districtCode: '01',
         precinctCode: '01',
         sex: 'M',
         appCode: 'APP_UNITEL'
      },
      step1: {
         newProductCode: '',
         newSubType: '',
         listPackage: [],
         listSubType: []
      },
      step2: {
         isdnUmoney: ''
      },
      step3: {
         transId: '',
         timestamp: '',
         transDate: '',
         serviceCode: '',
         account: ''
      },
      change_to_postpaid: {
         lstCusProfile: []
      },
      change_simcard: {
         step1: {
            lstChangeSim: []
         },
         step2: {
            phone: '',
            isdnContact: '',
            isdnContact1: '',
            isdnContact2: '',
            isdnContact3: '',
            portraitImg: '',
            idImg: '',
            newSerialImg: '',
            gender: 0,
            sex: 'M',
            date_of_birth: '',
            id_issued_date: '',
            issued_office: '',
            name: '',
            image1: './images/idtype.png',
            image2: './images/image_client1.png',
            image3: './images/img-register-upload-3.png',
            idType: null,
            idNum: '',
            serial_new_simcard: '',
            contact_number: ''
         },
         step3: {
            message: ''
         }
      },
      change_scartch_card: {
         step1: {
            lstChangeScartchCard: []
         },
         step2: {
            phone: '',
            idImg: '',
            name: '',
            image1: './images/idtype.png',
            image2: './images/img-register-upload-4.png',
            idType: null,
            idNum: '',
            serialCard: ''
         },
         step3: {
            message: ''
         }
      }
   },
   mutations: {
      SET_LOADING(state, payload) {
         state.isLoading = payload
      },
      SET_NOTIFICATION(state, payload) {
         state.notification = payload
      },
      setTextScan(state, payload) {
         state.scanText = payload
      },
      setPhoneScratchCard(state, payload) {
         state.phoneScratchCard = payload
      },
      setCustomerName(state, payload) {
         state.customerName = payload
      },
      setReceiverIsdn(state, payload) {
         state.receiverIsdn = payload
      },
      setFtthPackage(state, payload) {
         state.ftthPackage = payload
      },
      setFtthPackageCode(state, payload) {
         state.ftthPackageCode = payload
      },
      setInfoCusFtthPackage(state, payload) {
         state.infoCusFtthPackage = payload
      },
      setUrlImage(state, payload) {
         state.urlImage = payload
      },
      setUrlImageCrop(state, payload) {
         state.urlImageCrop = payload
      },
      setImageType(state, payload) {
         state.imageType = payload
      },
      setDataResInfo(state, payload) {
         state.dataResInfo = payload
      },
      setPackageActiveTab(state, payload) {
         state.packageActiveTab = payload
      },
      setChangeToPrepaid(state, payload) {
         let item = state.change_to_prepaid
         let objectK = Object.keys(payload)
         objectK.forEach((key) => {
            item[key] = payload[key]
         })
         state.change_to_prepaid = item
      },
      setStep1(state, payload) {
         let item = state.step1
         let objectK = Object.keys(payload)
         objectK.forEach((key) => {
            item[key] = payload[key]
         })
         state.step1 = item
      },
      setStep2(state, payload) {
         let item = state.step2
         let objectK = Object.keys(payload)
         objectK.forEach((key) => {
            item[key] = payload[key]
         })
         state.step2 = item
      },
      setStep3(state, payload) {
         let item = state.step3
         let objectK = Object.keys(payload)
         objectK.forEach((key) => {
            item[key] = payload[key]
         })
         state.step3 = item
      },
      setLstCusProfile(state, payload) {
         state.change_to_postpaid.lstCusProfile = payload
      },
      setChangeSimcard(state, payload) {
         const { step, value } = payload
         let item = state.change_simcard[step]
         let objectK = Object.keys(value)
         objectK.forEach((key) => {
            item[key] = value[key]
         })
         state.change_simcard[step] = item
      },
      setChangeScratchCard(state, payload) {
         const { step, value } = payload
         let item = state.change_scartch_card[step]
         let objectK = Object.keys(value)
         objectK.forEach((key) => {
            item[key] = value[key]
         })
         state.change_scartch_card[step] = item
      }
   },
   actions: {
      setLoading({ commit }, payload) {
         commit('SET_LOADING', payload)
      },
      setNotification({ commit }, payload) {
         commit('SET_NOTIFICATION', payload)
      }
   }
})

export default store
