import Api from '@/ultils/api-base'

export default {
   login(body) {
      return Api('POST', 'login', body)
   },
   forgotPass(body) {
      return Api('POST', 'forgotPass', body)
   },
   getListBanners(body) {
      return Api('POST', 'wsGetHotNews', body)
   },
   getListFtthAccountInfo(body) {
      return Api('POST', 'wsGetListFtthAccountInfo', body)
   },
   getFtthAccountInfo(body) {
      return Api('POST', 'wsGetFtthAccountInfo', body)
   },
   getFtthPrepaidInfo(body) {
      return Api('POST', 'wsGetFtthPrepaidInfo', body)
   },
   getViewFtthInvoiceStatement(body) {
      return Api('POST', 'wsViewFtthInvoiceStatement', body)
   },
   getListFtthServices(body) {
      return Api('POST', 'wsGetListFtthServices', body)
   },
   getListReceipt(body) {
      return Api('POST', 'wsGetListReceipt', body)
   },
   getViewReceiptInfo(body) {
      return Api('POST', 'wsViewReceiptInfo', body)
   },
   getListInvoice(body) {
      return Api('POST', 'wsGetListInvoice', body)
   },
   getViewInvoiceInfo(body) {
      return Api('POST', 'wsViewInvoiceInfo', body)
   },
   getListInvoiceStatement(body) {
      return Api('POST', 'wsGetListInvoiceStatement', body)
   },
   getViewInvoiceStatement(body) {
      return Api('POST', 'wsViewInvoiceStatement', body)
   },
   getEwalletInfo(body) {
      return Api('POST', 'wsGetEwalletInfo', body)
   },
   changePassword(body) {
      return Api('POST', 'UserChangePassword', body)
   },
   getFtthServiceDetailByServiceCode(body) {
      return Api('POST', 'wsGetFtthServiceDetailByServiceCode', body)
   },
   getProvinces(body) {
      return Api('POST', 'wsGetProvinces', body)
   },
   getDistricts(body) {
      return Api('POST', 'wsGetDistricts', body)
   },
   getPrecinct(body) {
      return Api('POST', 'wsGetPrecincts', body)
   },
   getInfoCust(body) {
      return Api('POST', 'wsGetInfoCust', body)
   },
   orderFtth(body) {
      return Api('POST', 'ptg_orderFtth', body)
   },
   getInforPromotionFtth100(body) {
      return Api('POST', 'wsInforPromotionFtth100', body)
   },
    getPromotionUseFTTH(body) {
        return Api('POST', 'wsPromotionUseFTTH', body)
   },
    getVerifyOtp(body) {
        return Api('POST', 'wsVerifyOtp', body)
   },
   prepareInfoPackage(body) {
      return Api('POST', 'wsPrepareInfoPackage', body,false)
   },
   getListSubType(body) {
      return Api('POST', 'wsGetListSubType', body,false)
   },
   getAmountInfo (body) {
      return Api('POST', 'wsGetAmountInfo', body,false)
   },
   confirmChangePackage (body) {
      return Api('POST', 'wsConfirmChangePackage', body,false)
   },
   getUmoneyOTP (body) {
      return Api('POST', 'wsGetUmoneyOTP', body,false)
   },
   changePackageAndSubType (body) {
      return Api('POST', 'wsChangePackageAndSubType', body,false)
   },
   historyComplain (body) {
      return Api('POST', 'wsHistoryComplain', body,false)
   },
   getComplainGroup (body) {
      return Api('POST', 'wsGetComplainGroup', body,true)
   },
   getComplainTypeByGroupId (body) {
      return Api('POST', 'wsGetComplainTypeByGroupId', body,true)
   },
   ftthAccountComplain (body) {
      return Api('POST', 'wsFtthAccountComplain', body,true)
   },
}
