export function callNative(code, message) {
    var data = {
        code: code,
        message: message
    }
    console.log('code ' + data.code + ' message ' + data.message)
        // ios
    try {
        window.webkit.messageHandlers.callNative.postMessage(data)
    } catch (err) {
        console.log('err ' + err)
    }
    // android
    try {
        if (code === 'ftthAccount') {
            Android.callNative(code, message)
        } else {
            Android.callNative(code)
        }
    } catch (err) {
        console.log('err ' + err)
    }
    // react native
    try {
        console.log('call  ReactNativeWebView')
        window.ReactNativeWebView.postMessage(JSON.stringify(data));
    } catch (err) {
        console.log('err ' + err)
    }
}