<template>
   <div class="main-container">
      <div class="nav-bar js-nav-bar" @click="back">
         <div class="nav-bar__inner">
            <span class="nav-bar__icon"><i class="icon-fonts icon-fonts--arrow-left"></i></span>
            <h3 class="nav-bar__title">{{ $t('mobile.map.title') }}</h3>
         </div>
      </div>
      <div class="main-content">
         <div class="find-store">
            <div class="find-store__form">
               <div class="form-2column">
                  <div class="form-customize">
                     <label class="label-title">{{ $t('mobile.map.province') }}</label>
                     <div class="select-box js-select-box">
                        <select
                           class="js-custom-select"
                           :data-placeholder="$t('mobile.map.select_province')"
                           v-model="provinceId"
                        >
                           <option value=""></option>
                           <option :value="province.id" v-for="province in provinces" :key="province.name">
                              {{ province.name }}
                           </option>
                        </select>
                     </div>
                  </div>
                  <div class="form-customize">
                     <label class="label-title">{{ $t('mobile.map.district') }}</label>
                     <div class="select-box js-select-box">
                        <select
                           class="js-custom-select"
                           :data-placeholder="$t('mobile.map.select_district')"
                           v-model="districtId"
                        >
                           <option value=""></option>
                           <option :value="district.id" v-for="district in districts" :key="district.name">
                              {{ district.name }}
                           </option>
                        </select>
                     </div>
                  </div>
               </div>
            </div>
            <div class="find-store__map" style="width: 100%; height: 300px" id="map"></div>
            <ul class="find-store__list">
               <li class="find-store__item" v-for="store in storesList" :key="store.name" @click="selectStore(store)">
                  <div class="find-store__detail">
                     <div class="find-store__images"><img src="@/assets/images/ic-store.svg" alt="img" /></div>
                     <div class="find-store__info">
                        <h5 class="find-store__name">{{ store.name }}</h5>
                        <p class="find-store__des">{{ store.addr }}</p>
                     </div>
                  </div>
                  <div class="find-store__location">
                     <span class="find-store__icon"><img src="@/assets/images/ic-location.svg" alt="img" /></span
                     ><span class="find-store__value"
                        >{{ Number(store.distance).toLocaleString('en-US', { maximumFractionDigits: 0 }) }}
                        {{ $t('unit.km') }}</span
                     >
                  </div>
               </li>
            </ul>
         </div>
      </div>
   </div>
</template>
<script>
import BaseComponent from '@/mixins/BaseComponent'
import service from '../../services/MobileService'
import globalStore from '@/plugins/vuex/store'
import LocalStorage from '@/ultils/local-storage'
import gmapsInit from '@/ultils/gmap'
import { callNative } from '@/ultils/call-native'

export default {
   name: 'FindStoreByProvince',
   mixins: [BaseComponent],
   data() {
      return {
         provinceId: null,
         districtId: null,
         provinces: [],
         districts: [],
         storesList: [],
         markers: [],
         google: null,
         map: null
      }
   },
   mounted() {
      this.setTitle(this.$i18n.t('mobile.map.title'))
      this.loadScript('js/scroll.js')
      this.init()
   },
   methods: {
      async init() {
         this.google = await gmapsInit()
         this.map = new this.google.maps.Map(document.getElementById('map'), {
            zoom: 1,
            center: { lat: 0, lng: 0 }
         })
         this.loadProvinces()
         this.loadStoresByArea()
      },
      loadStoresByArea() {
         this.showLoading()
         let currentLocation = localStorage.getItem('current_location')
         let lat = 999,
            lng = 999
         if (currentLocation) {
            currentLocation = JSON.parse(currentLocation)
            lat = currentLocation.lat
            lng = currentLocation.lng
         }
         service
            .findStoreByAddr({
               provinceId: this.provinceId !== null ? this.provinceId:'01',
               districtId: this.districtId,
               latitude: lat,
               longitude: lng
            })
            .then((response) => {
               if (response.errorCode === '0') {
                  this.markers.forEach((marker) => {
                     marker.setMap(null)
                  })
                  this.storesList = response.wsResponse ?? []
                  if (this.storesList.length > 0) {
                     this.map.setZoom(12)
                     this.map.setCenter({ lat: this.storesList[0].latitude, lng: this.storesList[0].longitude })
                  }
                  this.storesList.forEach((store) => {
                     this.markers.push(
                        new this.google.maps.Marker({
                           title: store.name,
                           position: { lat: store.latitude, lng: store.longitude },
                           icon: './images/ic-tracker.svg',
                           map: this.map
                        })
                     )
                  })
               } else {
                  globalStore.dispatch('setNotification', { content: response.message ?? response.userMsg })
               }
            })
            .finally(() => {
               this.hideLoading()
            })
      },
      loadProvinces() {
         this.showLoading()
         service
            .getProvinces()
            .then((response) => {
               if (response.errorCode === '0') {
                  this.provinces = response.wsResponse
                  this.loadScript('js/select2.js')
               } else {
                  globalStore.dispatch('setNotification', { content: response.message ?? response.userMsg })
               }
            })
            .finally(() => {
               this.hideLoading()
            })
      },
      loadDistrictsByProvinceId() {
         this.showLoading()
         service
            .getDistricts({ provinceId: this.provinceId })
            .then((response) => {
               if (response.errorCode === '0') {
                  this.districts = response.wsResponse
                  this.loadScript('js/select2.js')
               } else {
                  globalStore.dispatch('setNotification', { content: response.message ?? response.userMsg })
               }
            })
            .finally(() => {
               this.hideLoading()
            })
      },
      selectStore(store) {
         LocalStorage.setItem('store', JSON.stringify(store))
         this.$router.push('/mobile/map/detail')
      },
      back() {
         let skip = localStorage.getItem('skip_access_location') === 'true'
         if (skip) {
            this.$router.go(-2)
         } else {
            this.$router.go(-1)
         }
         callNative('close_webview', '')
      }
   },
   watch: {
      provinceId() {
         this.districtId = null
         this.loadDistrictsByProvinceId()
         this.loadStoresByArea()
      },
      districtId() {
         this.loadStoresByArea()
      }
   }
}
</script>
