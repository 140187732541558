import HomeUnauthenticatedComponent from '@/modules/home/components/HomeUnauthenticated'
import HomeAuthenticatedComponent from '@/modules/home/components/HomeAuthenticated'
import LaoKYCComponent from '@/modules/home/components/LaoKYC'
import DigitalServiceComponent from '@/modules/home/components/DigitalService'
import GameComponent from '@/modules/home/components/Game'
import FavouriteListComponent from '@/modules/home/components/FavouriteList'
import UserInformationComponent from '@/modules/home/components/UserInformation'
import RegistrationInformation from '@/modules/home/components/RegistrationInformation'
import ImageCrop from '@/modules/home/components/ImageCrop'
import CallBackLogin from '@/modules/home/components/CallBackLogin'

export default [
   {
      path: '/',
      redirect: '/home'
   },
   {
      path: '/login',
      component: HomeUnauthenticatedComponent
   },
   {
      path: '/callback',
      component: CallBackLogin
   },
   {
      path: '/home',
      component: HomeAuthenticatedComponent
   },
   {
      path: '/laokyc',
      component: LaoKYCComponent
   },
   {
      path: '/digital-service',
      component: DigitalServiceComponent
   },
   {
      path: '/game',
      component: GameComponent
   },
   {
      path: '/favourite-list',
      component: FavouriteListComponent
   },
   {
      path: '/user-info',
      component: UserInformationComponent
   },
   {
      path: '/register-info',
      component: RegistrationInformation
   },
   {
      path: '/image-crop',
      component: ImageCrop
   }
]
