<template>
    <div>
        <div class="main-container" v-show="showMain">
            <div class="nav-bar js-nav-bar" @click="goBack">
                <div class="nav-bar__inner">
                    <span class="nav-bar__icon"><i class="icon-fonts icon-fonts--arrow-left"></i></span>
                    <h3 class="nav-bar__title">{{ $t('mobile.change_scratch_card.Input_information') }}</h3>
                </div>
            </div>
            <div class="main-content">
                <ValidationObserver v-slot="{passes}">
                    <form @submit.prevent="passes(submit)">
                        <div class="register-info">
                            <div class="register-info__form">
                                <div class="form-customize">
                                    <Input type="number"
                                        :label="$t('home.registration_info.phone_number')"
                                        :placeholder="$t('home.registration_info.enter_phone_number')"
                                        rules="required|phone"
                                        :hasStar="true"
                                        disabled
                                        v-model="data.phone" />
                                </div>
                                <div class="form-customize">
                                    <Input type="text"
                                        :label="$t('mobile.change_scratch_card.Personal_information')"
                                        :placeholder="$t('mobile.change_scratch_card.Personal_information')"
                                        rules="required"
                                        :hasStar="true"
                                        v-model="data.name" />
                                </div>
                                <!-- <div class="form-customize">
                                    <Select :label="$t('home.registration_info.document_type')"
                                        :placeholder="$t('home.registration_info.enter_document_type')"
                                        rules="required"
                                        :dataSelect="lst_TypeId"
                                        :hasStar="true"
                                        v-model="data.idType" />
                                </div>-->
                                <div class="form-customize">
                                    <Input type="text"
                                        :label="$t('mobile.change_scratch_card.Document_number')"
                                        :placeholder="$t('mobile.change_scratch_card.Document_number')"
                                        rules="required"
                                        :hasStar="true"
                                        v-model="data.idNum" />
                                </div> 
                                <!-- <div class="form-customize">
                                    <div class="register-upload">
                                    <div class="register-upload__top">
                                        <label class="label-title">Image of document (*)</label>
                                        <button class="button button--upload" type="button"><i class="icon-fonts icon-fonts--camera"></i>
                                        </button>
                                        <button class="button button--upload" type="button">Browse</button>
                                    </div>
                                    <div class="register-upload__action"><span class="register-upload__delete">Delete</span></div>
                                    <p class="register-upload__noti">No image of document</p>
                                    <div class="register-upload__content"><img src="./images/img-register-upload-1.png" alt="img"></div>
                                    </div>
                                </div>
                                <div class="form-customize">
                                    <div class="register-upload">
                                    <div class="register-upload__top">
                                        <label class="label-title">Image of scratch card (*)</label>
                                        <button class="button button--upload" type="button"><i class="icon-fonts icon-fonts--camera"></i>
                                        </button>
                                        <button class="button button--upload" type="button">Browse</button>
                                    </div>
                                    <div class="register-upload__action"><span class="register-upload__delete">Delete</span></div>
                                    <div class="register-upload__content"><img src="./images/img-register-upload-4.png" alt="img"></div>
                                    </div>
                                </div> -->
                                <div class="form-customize">
                                    <div class="register-upload">
                                        <div class="register-upload__top">
                                            <label class="label-title">{{$t('home.registration_info.image_document')}} <span style="color:red">(*)</span></label>

                                            <button class="button button--upload" type="button" @click="chooseCamera('cDoc1')"  id="image1">
                                                <i class="icon-fonts icon-fonts--camera"></i>
                                            </button>

                                            <button class="button button--upload" @click="chooseFiles('fDoc1')" type="button">{{$t('home.registration_info.browse')}}</button>
                                        </div>
                                        <br v-if="data.image1 == './images/idtype.png'" />
                                        <div class="register-upload__action" v-if="data.image1 && data.image1 != './images/idtype.png'" @click="onDeleteImage1"><span class="register-upload__delete"><a href="javascript:;" style="color:red">{{$t('home.registration_info.delete')}}</a></span></div>
                                        <p class="register-upload__noti" v-if="errorImg1">{{$t('home.registration_info.no_image')}}</p>
                                        <div class="register-upload__content"><img id="container1" :src="image1" alt="img" width="345" height=""></div>
                                    </div>
                                </div>

                                <!--Cho nay la cho input file va camera-->
                                <input type="file" id="cameraUpload" accept="image/*" capture="camera" @change="onCameraChange" hidden />
                                <input id="fileUpload" type="file" accept="image/png, image/jpeg" @change="onFileChange" hidden>
                                <!-------------------------------------------------------------------------------------------------------------->

                                <div class="form-customize">
                                    <div class="register-upload">
                                        <div class="register-upload__top">
                                            <label class="label-title">{{$t('mobile.change_scratch_card.Image_of_scratch_card')}} <span style="color:red">(*)</span></label>
                                            <button class="button button--upload" type="button" @click="chooseCamera('cCus')">
                                                <i class="icon-fonts icon-fonts--camera"></i>
                                            </button>
                                        </div>
                                        <br v-if="data.image2 == './images/img-register-upload-4.png'" />
                                        <div class="register-upload__action" v-if="data.image2 && data.image2 != './images/img-register-upload-4.png'" @click="onDeleteImage2"><span class="register-upload__delete"><a href="javascript:;" style="color:red">{{$t('home.registration_info.delete')}}</a></span></div>
                                        <p class="register-upload__noti" v-if="errorImg2">{{$t('mobile.change_scratch_card.error_image_2')}}</p>
                                        <div class="register-upload__content"><img id="container2" :src="image2" alt="img" width="345" height="250"></div>
                                    </div>
                                </div>
                                <div class="form-customize">

                                    <Input type="number"
                                        :label="$t('mobile.change_scratch_card.Serial_scratch_card')"
                                        :placeholder="$t('mobile.change_scratch_card.Serial_scratch_card')"
                                        rules="required"
                                        :hasStar="true"
                                        v-model="data.serialCard" />
                                </div>
                            </div>
                            <div class="register-info__btn">
                                <button class="button button--primary button--full">Submit
                                </button>
                            </div>
                        </div>
                    </form>
                </ValidationObserver>
            </div>
             <!-- Modal-->
             <div class="text-center mt-3 pt-3" style="display: none;">
                <button class="button button--primary" data-bs-toggle="modal" data-bs-target="#register-confirm" id="registerConfirmOTP">{{$t('ftth.change_product.trigger_modal')}}
                </button>
            </div>
            <div class="modal fade modal-custom " data-bs-backdrop="static" data-bs-keyboard="false" id="register-confirm" tabindex="-1" role="dialog" aria-labelledby="register-confirm" aria-hidden="true">
                <div class="modal-dialog" role="document">
                <div class="modal-custom__content">
                    <div class="modal-custom__header"><span class="modal-custom__action" data-bs-dismiss="modal" id="hideOTP" @click="hideOTPClick()"><i class="icon-fonts icon-fonts--close"></i></span></div>
                    <div class="modal-custom__body">
                    <h3 class="modal-custom__title">{{$t('ftth.change_product.registration_confirmation')}}</h3>
                    <p class="modal-custom__des">{{$t('ftth.change_product.titlePopup')}}</p>
                    <div class="modal-custom__form">
                        <div class="form-customize">
                        <div class="form-input">
                            <input v-model="OTP_POPUP" pattern="[0-9]*" onkeypress="return event.keyCode === 8 || event.charCode >= 48 && event.charCode <= 57" class="form-control" type="text" :placeholder="$t('ftth.change_product.OTP')" maxlength="6" /><span class="input-time">00:{{seconds}}</span>
                        </div>
                        </div>
                    </div>
                    <template v-if="seconds == 0">
                        <p class="modal-custom__note">{{$t('ftth.change_product.not_received_yet')}}<a class="modal-custom__note-link" href="javascript:void(0)" @click="sendOTP">{{$t('ftth.change_product.resend_OTP')}}</a></p>
                    </template>
                    </div>
                </div>
                </div>
            </div>

            <button style="display: none" data-bs-toggle="modal" data-bs-target="#notification-confirm" id="notificationBtn-confirm" />
            <div
                class="modal fade modal-custom"
                id="notification-confirm"
                tabindex="-1"
                role="dialog"
                aria-labelledby="notification-confirm"
                aria-hidden="true"
            >
                <div class="modal-dialog" role="document">
                    <div class="modal-custom__content">
                    <div class="modal-custom__header">
                        <span class="modal-custom__action" data-bs-dismiss="modal" id="hide-confirm" @click="closeConfirm"
                            ><i class="icon-fonts icon-fonts--close"></i
                        ></span>
                    </div>
                    <div class="modal-custom__body">
                        <div class="modal-custom__images"><img :src="showedData.icon" alt="img" /></div>
                        <h3 class="modal-custom__title">{{showedData.title}}</h3>
                        <p class="modal-custom__des" v-if="showedData.content" v-html="showedData.content"></p>
                        <div class="modal-custom__btn modal-custom__btn--full">
                            <button class="button button--primary button--medium"  @click="onClose">
                                {{ showedData.closeButton }}
                            </button>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </div>
        <!--Man crop-->
        <div class="main-container" v-show="!showMain">
            <div class="nav-bar js-nav-bar">
                <div class="nav-bar__inner">
                    <span class="nav-bar__icon" @click="backMain"><i class="icon-fonts icon-fonts--arrow-left"></i></span>
                    <h3 class="nav-bar__title">{{$t('mobile.change_to_postpaid.title')}}</h3><a @click="rotateImage" class="nav-bar__link" style="margin-right:150px; display: none" href="javascript:;">{{$t('home.registration_info.rotate')}}</a> <a @click="plipImage(2)" class="nav-bar__link" style="margin-right:100px; display: none" href="javascript:;">{{$t('home.registration_info.plipV')}}</a><a @click="plipImage(1)" class="nav-bar__link" style="margin-right:50px; display: none" href="javascript:;">{{$t('home.registration_info.plipH')}}</a> <a @click="getCropImage()" class="nav-bar__link" href="javascript:;">{{$t('home.registration_info.crop')}}</a>
                </div>
            </div>
            <div class="main-content">
                <div class="fullscreenImage">
                    <cropper :transitions="true"
                                :auto-zoom="true"
                                ref="cropper"
                                default-boundaries="fill"
                                class="cropper"
                                image-class="cropper__image"
                                :src="url"></cropper>
                </div>
            </div>

        </div>
    </div>
</template>
<script>
import BaseComponent from '@/mixins/BaseComponent'
import service from './../../services/MobileService'
import store from '@/plugins/vuex/store'
import LocalStorage from '@/ultils/local-storage'
import { ValidationObserver } from 'vee-validate'
import Input from '@/components/form/Input'
import {Genders,TypeId} from './../../constants/data'
// import Select from '@/components/form/Select'
import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css';
import $ from 'jquery'

export default {
   name: 'CreateNewRequest',
   components: {
      ValidationObserver,
      Input,
    //   Select,
      Cropper
   },
   mixins: [BaseComponent],
   data() {
      return {
         data:{
            phone:'',
            idImg:'',
            name:'',
            image1: "./images/idtype.png",
            image2: "./images/img-register-upload-4.png",
            idType: null,
            idNum:'',
            serialCard:''
         },
         lst_Genders: Genders,
         lst_TypeId:TypeId,
         image1: "./images/idtype.png",
        image2: "./images/img-register-upload-4.png",
        errorImg1: false,
        errorImg2: false,
        imageType: null,
        showMain: true,
        url: null,
        totalTime:60,
        timer:null ,
        OTP_POPUP:'',
        showedData:{}
      }
   },
   computed:{
    seconds: function() {
        const seconds = this.totalTime;
        return this.padTime(seconds);
    },
   },
   async mounted() {
      this.setTitle(this.$i18n.t('mobile.change_scratch_card.change_scratch_card'))
      this.loadScript('js/date-picker.js')
      this.loadScript('js/select2.js')
      this.loadScript('js/scroll.js')
      var userInfo = JSON.parse(LocalStorage.getItem('user_info')) ?? {}
      this.data = {...store.state.change_scartch_card.step2}
      this.data.phone = userInfo['isdn']
      
    //   if(this.data && this.data.name == ''){
    //     this.loadData()
    //   }
        await this.loadData()
       this.showedData= {
            title: this.$i18n.t('notification.title'),
            icon: './images/ic-warning.svg',
            content: this.data.content,
            closeButton: this.$i18n.t('buttons.close')
       }
       this.image1 = this.data.image1 ? this.data.image1 : ""
        this.image2 = this.data.image2 ? this.data.image2 : ""
   },
   methods: {   
        onClose(){
            clearInterval(this.timer)
            this.totalTime = 60      
            this.timer = setInterval(() => this.countdown(), 1000);
            $("#hide-confirm").trigger('click')
            $('#registerConfirmOTP').trigger('click')
            
        }, 
        closeConfirm(){
            clearInterval(this.timer)
            this.totalTime = 60            
            this.timer = setInterval(() => this.countdown(), 1000);
            $("#hide-confirm").trigger('click')
            $('#registerConfirmOTP').trigger('click')
            
        },
        hideOTPClick(){
            clearInterval(this.timer)
            $("#hideOTP").trigger('click')
        },
        async sendOTP(){
            let dataPost = {
                "locate": LocalStorage.getItem('lang')
            }
            this.showLoading()
            this.OTP_POPUP = ""
            clearInterval(this.timer)
            service
                .bccsGetOTP(dataPost)
                .then((response) => {
                    if (response.result == 1) {
                        this.hideLoading()
                        this.totalTime = 60
                        this.timer = setInterval(() => this.countdown(), 1000);
                    }else {
                        store.dispatch('setNotification', { content: response.errorMessage ?? response.errorMessage })
                    }
                })
                .finally(() => {
                    this.hideLoading()
                })
        },
        padTime: function(time){
            return (time < 10 ? '0' : '') + time;
        },
        countdown(){
            this.totalTime--
            if(this.totalTime <=0){
                clearInterval(this.timer);
                this.timer = null;
            }
        },
        submit(){
            if (this.data.image1 == null || this.data.image1 == "./images/idtype.png") {
                this.errorImg1 = true;
            }

            if (this.data.image2 == null || this.data.image2 == "./images/img-register-upload-4.png") 
            {
                this.errorImg2 = true;
            }
            if (this.errorImg1 || this.errorImg2) {
                return
            }
            let dataPost = {
                "locate": LocalStorage.getItem('lang')
            }
            this.OTP_POPUP = ''
            
            service
                .bccsGetOTP(dataPost)
                .then((response) => {
                    if (response.result == 1) {
                        
                        this.hideLoading()
                        this.totalTime = 60
                        $('#registerConfirmOTP').trigger('click')
                        
                        this.timer = setInterval(() => this.countdown(), 1000);
                    }else {
                        store.dispatch('setNotification', { content: response.errorMessage ?? response.errorMessage })               
                    }
                })
                .finally(() => {
                    this.hideLoading()
                })
            // let payload = {
            //     step:"step2",
            //     value:this.data
            // }
            // store.commit('setChangeScratchCard', payload)
            // this.$router.push('/mobile/request-success-change-scartch-card')
            
        },
        async loadData() {
            this.showLoading()
            let dataPost ={
                "payType": 2
            }
            await service
                .getCustInfoTransfer(dataPost)
                .then((response) => {
                    if (response.errorCode == "0") {
                        let data = response.object.customerInfo ?? {}
                        this.data.idNum = data.idNo
                        this.data.name = data.name
                        let content2 = data.lstCusProfile.filter(x=>x.imageLabel =='Font side')
                        if (content2) {
                           if (content2[0].content) {
                              this.data.image1 = 'data:image/jpeg;base64,' + content2[0].content
                              this.image1 = 'data:image/jpeg;base64,' + content2[0].content
                           } else {
                              this.data.image1 = "./images/idtype.png"
                              this.image1 = "./images/idtype.png"
                           }
                        } 
                        // else {
                        //    this.data.image1 = "./images/idtype.png"
                        //    this.image1 = "./images/idtype.png"
                        // }
                    } 
                })
                .finally(() => {
                    this.hideLoading()
                })
        },
        redirectToDetail() {
            this.$router.push('/mobile/detail-view-invoice')
        },
        chooseFiles(type) {
            // if (type != "cCus") {
            //     if (!this.data.idType) {
            //         store.dispatch('setNotification', {
            //             title: this.$i18n.t('notification.title'),
            //             content: this.$i18n.t('home.registration_info.enter_document_type'),
            //             closeButton: this.$i18n.t('buttons.close')
            //         })
            //         return
            //     }
            // }

            document.getElementById("fileUpload").click()
            this.imageType = type;
        },
        onFileChange(e) {
            const file = e.target.files[0];
            this.url = URL.createObjectURL(file);
            this.showMain = false;
        },
        chooseCamera(type) {
            // if (type == "cCus") {
            //     if (this.data.image1 == null || this.data.image1 == "./images/idtype.png") {
            //         store.dispatch('setNotification', {
            //             title: this.$i18n.t('notification.title'),
            //             content: this.$i18n.t('home.registration_info.notiContent'),
            //             closeButton: this.$i18n.t('buttons.close')
            //         })
            //         return
            //     }
            // } else {
            //     if (!this.data.idType) {
            //         store.dispatch('setNotification', {
            //             title: this.$i18n.t('notification.title'),
            //             content: this.$i18n.t('home.registration_info.enter_document_type'),
            //             closeButton: this.$i18n.t('buttons.close')
            //         })
            //         return
            //     }
            // }
            document.getElementById("cameraUpload").click()
            this.imageType = type;
        },
        onCameraChange(e) {
            const file = e.target.files[0];
            this.url = URL.createObjectURL(file);
            this.showMain = false;
        },
        backMain() {
            this.showMain = true;
            if (this.imageType == "cDoc1" || this.imageType == "fDoc1") {
                this.image1 = "./images/idtype.png";
            }
            if (this.imageType == "cCus") {
                this.image2 = "./images/img-register-upload-4.png";
            }
            document.getElementById("fileUpload").value = null
            document.getElementById("cameraUpload").value = null
        },
        rotateImage() {
            this.$refs.cropper.rotate(90);
        },
        plipImage(number) {
            if (number == 1) {
                this.$refs.cropper.flip(true, false);
            } else {
                this.$refs.cropper.flip(false, true);
            }

        },
        closeCrop() {
            this.$emit('close')
        },
        getCropImage() {
            var urlResult = this.$refs.cropper.getResult().canvas.toDataURL("image/jpeg");
            if (this.imageType == "cDoc1" || this.imageType == "fDoc1") {
                this.errorImg1 = false;
                this.image1 = urlResult ? urlResult : "./images/idtype.png";
                //this.getInfoAI(this.data.image1)
            }
            if (this.imageType == "cCus") {
                this.errorImg2 = false;
                this.image2 = urlResult ? urlResult : "./images/img-register-upload-4.png";
                //this.compareFace(this.data.image2)
            }
           
            document.getElementById("fileUpload").value = null
            document.getElementById("cameraUpload").value = null
            this.showMain = true;

        },
        onDeleteImage1() {
            this.image1 = "./images/idtype.png";
            // if (this.data.idType == 3) {
            //     this.image2 = "./images/image_client2.png";
            // } else if (this.data.idType == 6) {
            //     this.image2 = "./images/image_client3.png";
            // } else {
            //     this.image2 = "./images/img-register-upload-4.png";
            // }
        },
        onDeleteImage2() {
            this.image2 = "./images/img-register-upload-4.png";
            // if (this.data.idType == 3) {
            //     this.image2 = "./images/image_client2.png";
            // } else if (this.data.idType == 6) {
            //     this.image2 = "./images/image_client3.png";
            // } else {
            //     this.image2 = "./images/img-register-upload-4.png";
            // }
        },
   },
   watch: {
        image1(val) {
            this.data.image1 = val;
        },
        image2(val) {
            this.data.image2 = val;
        },
        showMain(val) {
                var vm = this;
                if (val) {
                    if (vm.imageType == "cDoc1" || vm.imageType == "fDoc1") {
                        setTimeout(function () {
                            const el = vm.$el.querySelector("#container1");
                            if (el) {
                                el.scrollIntoView();
                            }
                        }, 50)
                    }
                    if (vm.imageType == "cCus") {
                        setTimeout(function () {
                            const el = vm.$el.querySelector("#container2");
                            if (el) {
                                el.scrollIntoView();
                            }
                        }, 50)
                    }
                }
        },
        async OTP_POPUP(val){
            let seft = this
            if(val && val.length == 6){
                this.showLoading()
                let dataPost={
                "otp": val,
                "locate": LocalStorage.getItem('lang'),
                "serialCard": this.data.serialCard,
                "idNum": this.data.idNum,
                "idImg": this.data.image1.replace("data:image/jpeg;base64,", "").replace("data:image/png;base64,", ""),
                "damagedCardImg": this.data.image2.replace("data:image/jpeg;base64,", "").replace("data:image/png;base64,", ""),
                }
                await service
                    .changeScratch(dataPost)
                    .then((response) => {
                    if (response.result == 1) {
                    // debugger; // eslint-disable-line no-debugger
                        $("#hideOTP").trigger('click')
                        this.hideLoading()
                        let payload = {
                            step:"step2",
                            value:seft.data
                        }
                        store.commit('setChangeScratchCard', payload)

                        let payloadSuccess = {
                            step:"step3",
                            value:{
                                "message":response.message
                            }
                        }
                        store.commit('setChangeScratchCard', payloadSuccess)
                        this.$router.push('/mobile/request-success-change-scartch-card')
                    }else{
                        $("#hideOTP").trigger('click')
                        $('#notificationBtn-confirm').trigger('click')
                        this.showedData.content = response.message ?? response.message
                        //store.dispatch('setNotification', { content: response.message ?? response.message })
                    }
                }).finally(()=>{
                    this.hideLoading()
                    // $('#notificationBtn-confirm').trigger('click')
                })

                
            }
        }
   }
}
</script>
