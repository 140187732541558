<template>
   <div class="main-container">
      <div class="nav-bar js-nav-bar" @click="goBack">
         <div class="nav-bar__inner">
            <span class="nav-bar__icon"><i class="icon-fonts icon-fonts--arrow-left"></i></span>
            <h3 class="nav-bar__title">{{ $t('mobile.credit_service.title') }}</h3>
         </div>
      </div>
      <div class="main-content">
         <div class="credit-service">
            <div class="tabs-list">
               <div class="nav tabs-list__nav" role="tablist">
                  <a class="tabs-list__link" :class="{active : activeTab == 1}" href="#airtime-credit" role="tab" data-bs-toggle="tab">{{
                     $t('mobile.credit_service.airtime_credit')
                  }}</a
                  ><a class="tabs-list__link" :class="{active : activeTab == 2}" href="#airtime-data" role="tab" data-bs-toggle="tab">{{
                     $t('mobile.credit_service.airtime_data')
                  }}</a>
               </div>
               <div class="tabs-list__content tab-content">
                  <div class="tab-pane fade" :class="{'show active' : activeTab == 1}" id="airtime-credit" role="tabpanel">
                     <div class="credit-service__content">
                        <div class="box-credit" v-if="!onShowNote">
                           <div class="box-credit__info">
                              <h4 class="box-credit__label">{{ $t('mobile.credit_service.notes') }}</h4>
                              <div class="box-credit__action">
                                 <div class="box-credit__btn">
                                    <button class="button button--primary button--small" v-on:click="onShowNote = true">
                                       {{ $t('mobile.credit_service.see_more') }}
                                    </button>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="box-credit active" v-if="onShowNote">
                           <div class="box-credit__info">
                              <h4 class="box-credit__label">{{ $t('mobile.credit_service.notes') }}</h4>
                              <div class="box-credit__action" @click="onShowNote = false">
                                 <span class="box-credit__icon"><i class="icon-fonts icon-fonts--arrow-up"></i></span>
                              </div>
                           </div>
                           <ul class="box-credit__menu" v-html="note"></ul>
                        </div>
                        <div class="credit-service__detail">
                           <div class="credit-service__row">
                              <h4 class="credit-service__sub">{{ $t('mobile.credit_service.basic_balance') }}</h4>
                              <ul class="credit-service__list">
                                 <li class="credit-service__item" v-for="item in basicBalance" :key="item.airtimeCode">
                                    <div class="credit-service__item-detail">
                                       <div class="credit-service__item-images">
                                          <img :src="item.iconUrl" alt="img" />
                                          <!--<span class="credit-service__item-tag crown"
                                             ><span>{{ item.airtimeCode }}</span></span>-->
                                       </div>
                                       <div class="credit-service__item-info">
                                          <div class="credit-service__item-top">
                                             <h5 class="credit-service__item-name">
                                                <a class="credit-service__item-link">{{ item.airtimeCode }}</a>
                                             </h5>
                                             <span class="credit-service__item-price">
                                                <i class="icon-fonts icon-fonts--price"></i
                                                >{{
                                                   Number(item.price).toLocaleString('en-US', {
                                                      maximumFractionDigits: 0
                                                   })
                                                }}
                                                {{ $t('unit.upper_kip') }}
                                             </span>
                                          </div>
                                          <p class="credit-service__item-des">{{ item.shortDes }}</p>
                                       </div>
                                    </div>
                                    <div class="credit-service__item-btn" @click="openConfirmAirTimeCredit(item)">
                                       <button class="button button--primary button--small">
                                          {{ $t('mobile.credit_service.borrow') }}
                                       </button>
                                    </div>
                                 </li>
                              </ul>
                           </div>
                           <div class="credit-service__row">
                              <h4 class="credit-service__sub">{{ $t('mobile.credit_service.promotion_balance') }}</h4>
                              <ul class="credit-service__list">
                                 <li
                                    class="credit-service__item"
                                    v-for="item in promotionBalance"
                                    :key="item.airtimeCode"
                                 >
                                    <div class="credit-service__item-detail">
                                       <div class="credit-service__item-images">
                                          <img :src="item.iconUrl" alt="img" />
                                          <!--<span  class="credit-service__item-tag crown"
                                             ><span>{{ item.airtimeCode }}</span></span>-->
                                       </div>
                                       <div class="credit-service__item-info">
                                          <div class="credit-service__item-top">
                                             <h5 class="credit-service__item-name">
                                                <a class="credit-service__item-link">{{ item.airtimeCode }}</a>
                                             </h5>
                                             <span class="credit-service__item-price"
                                                ><i class="icon-fonts icon-fonts--price"></i
                                                >{{
                                                   Number(item.price).toLocaleString('en-US', {
                                                      maximumFractionDigits: 0
                                                   })
                                                }}
                                                {{ $t('unit.upper_kip') }}</span
                                             >
                                          </div>
                                          <p class="credit-service__item-des">{{ item.shortDes }}</p>
                                       </div>
                                    </div>
                                    <div class="credit-service__item-btn" @click="openConfirmAirTimeCredit(item)">
                                       <button class="button button--primary button--small">
                                          {{ $t('mobile.credit_service.borrow') }}
                                       </button>
                                    </div>
                                 </li>
                              </ul>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="tab-pane fade"  :class="{'show active' : activeTab == 2}" id="airtime-data" role="tabpanel">
                     <div class="credit-service__content">
                        <div class="credit-service__detail">
                           <div class="credit-service__row">
                              <h4 class="credit-service__sub">{{ $t('mobile.credit_service.prepaid') }}</h4>
                              <ul class="credit-service__list">
                                 <li class="credit-service__item" v-for="item in prepaidData" :key="item.airtimeCode">
                                    <div class="credit-service__item-detail">
                                       <div class="credit-service__item-images">
                                          <img :src="item.iconUrl" alt="img" /><span
                                             class="credit-service__item-tag crown"
                                             ><span>{{ item.airtimeCode }}</span></span
                                          >
                                       </div>
                                       <div class="credit-service__item-info">
                                          <div class="credit-service__item-top">
                                             <h5 class="credit-service__item-name">
                                                <a class="credit-service__item-link">{{ item.airtimeCode }}</a>
                                             </h5>
                                             <span class="credit-service__item-price"
                                                ><i class="icon-fonts icon-fonts--price"></i
                                                >{{
                                                   Number(item.price).toLocaleString('en-US', {
                                                      maximumFractionDigits: 0
                                                   })
                                                }}
                                                {{ $t('unit.upper_kip') }}</span
                                             >
                                          </div>
                                          <p class="credit-service__item-des">{{ item.shortDes }}</p>
                                       </div>
                                    </div>
                                    <div class="credit-service__item-btn" @click="openConfirmAirTime(item)">
                                       <button class="button button--primary button--small">
                                          {{ $t('mobile.credit_service.register') }}
                                       </button>
                                    </div>
                                 </li>
                              </ul>
                           </div>
                           <div class="credit-service__row">
                              <h4 class="credit-service__sub">{{ $t('mobile.credit_service.net') }}</h4>
                              <ul class="credit-service__list">
                                 <li class="credit-service__item" v-for="item in netData" :key="item.airtimeCode">
                                    <div class="credit-service__item-detail">
                                       <div class="credit-service__item-images">
                                          <img :src="item.iconUrl" alt="img" /><span
                                             class="credit-service__item-tag heart"
                                             ><span>{{ item.airtimeCode }}</span></span
                                          >
                                       </div>
                                       <div class="credit-service__item-info">
                                          <div class="credit-service__item-top">
                                             <h5 class="credit-service__item-name">
                                                <a class="credit-service__item-link">{{ item.airtimeCode }}</a>
                                             </h5>
                                             <span class="credit-service__item-price">
                                                <i class="icon-fonts icon-fonts--price"></i
                                                >{{
                                                   Number(item.price).toLocaleString('en-US', {
                                                      maximumFractionDigits: 0
                                                   })
                                                }}
                                                {{ $t('unit.upper_kip') }}
                                             </span>
                                          </div>
                                          <p class="credit-service__item-des">{{ item.shortDes }}</p>
                                       </div>
                                    </div>
                                    <div class="credit-service__item-btn" @click="openConfirmAirTime(item)">
                                       <button class="button button--primary button--small">
                                          {{ $t('mobile.credit_service.register') }}
                                       </button>
                                    </div>
                                 </li>
                              </ul>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
      <confirmation-modal :data="dataPopupComfirm" :onConfirm="onconfirmAirTime" :onCancel="onCancel" />
   </div>
</template>
<script>
import BaseComponent from '@/mixins/BaseComponent'
import service from './../../services/MobileService'
import ConfirmationModal from '@/components/modal/ConfirmationModal'
import store from '@/plugins/vuex/store'
import { CreditServiceTab } from './../../constants/data'

export default {
   name: 'CreditService',
   mixins: [BaseComponent],
   components: {
      ConfirmationModal
   },
   data() {
      return {
         prepaidData: [],
         netData: [],
         basicBalance: [],
         promotionBalance: [],
         dataPopupComfirm: {
            title: '',
            content: '',
            cancelButton: '',
            confirmButton: '',
            confirmedData: {}
         },
         onShowNote: false,
         note: '',
         activeTab: this.$route.query.tab ?? CreditServiceTab.AirtimeCredit
      }
   },
   created() {
      this.setTitle(this.$i18n.t('mobile.credit_service.title'))
      this.getAirTimeCredit()
      this.getAirTimeData()
   },
   methods: {
      getAirTimeData() {
         this.showLoading()
         service
            .getAirTimeData()
            .then((response) => {
               var data = response.wsResponse
               //Nếu không có ảnh thì để ảnh mặc định
               if (data) {
                  for (var i = 0; i < data.length; i++) {
                     if (data[i].simType == 0) {
                        this.prepaidData = data[i].services
                     }
                     if (data[i].simType == 1) {
                        this.netData = data[i].services
                     }
                  }
               }
               this.hideLoading()
            })
            .catch(() => {
               this.hideLoading()
            })
      },
      getAirTimeCredit() {
         this.showLoading()
         service
            .getAirTimeCredit()
            .then((response) => {
               var data = response.wsResponse
               //Nếu không có ảnh thì để ảnh mặc định
               if (data) {
                  this.note = data[0].note
                  for (var i = 0; i < data.length; i++) {
                     if (data[i].balanceType == 0) {
                        this.basicBalance = data[i].services
                     }
                     if (data[i].balanceType == 1) {
                        this.promotionBalance = data[i].services
                     }
                  }
               }
               this.hideLoading()
            })
            .catch(() => {
               this.hideLoading()
            })
      },
      onCancel() {},
      openConfirmAirTime(data) {
         this.dataPopupComfirm = {
            title: this.$i18n.t('mobile.credit_service.airtime_data'),
            content:
               this.$i18n.t('mobile.credit_service.content1') +
               data.shortDes +
               this.$i18n.t('mobile.credit_service.content2'),
            cancelButton: this.$i18n.t('buttons.cancel'),
            confirmButton: this.$i18n.t('buttons.confirm'),
            confirmedData: {
               actionType: 1,
               serviceCode: data.airtimeCode
            }
         }
      },
      openConfirmAirTimeCredit(data) {
         this.dataPopupComfirm = {
            title: this.$i18n.t('mobile.credit_service.airtime_credit'),
            content: data.shortDes + this.$i18n.t('mobile.credit_service.content2'),
            cancelButton: this.$i18n.t('buttons.cancel'),
            confirmButton: this.$i18n.t('buttons.confirm'),
            confirmedData: {
               actionType: 1,
               serviceCode: data.airtimeCode
            }
         }
      },
      onconfirmAirTime(data) {
         this.showLoading()
         service
            .confirmAirTime(data)
            .then((response) => {
               store.dispatch('setNotification', {
                  title: this.$i18n.t('notification.title'),
                  content: response.message ?? response.userMsg,
                  closeButton: this.$i18n.t('buttons.close')
               })
            })
            .finally(() => {
               this.hideLoading()
            })
      }
   },
}
</script>
