import CustomerInfo from '@/modules/convert/components/CustomerInfo'
import RequestSuccess from '@/modules/convert/components/RequestSuccess'

export default [
   {
      path: '/convert/customer-info',
      component: CustomerInfo
   },
   {
    path: '/convert/request-success',
    component: RequestSuccess
 }
]
