<template>
    <div class="main-container">
        <div class="nav-bar js-nav-bar">
            <div class="nav-bar__inner">
                <span class="nav-bar__icon" @click="goBack"><i class="icon-fonts icon-fonts--arrow-left"></i></span>
                <h3 class="nav-bar__title">{{ $t('mobile.account_information.title') }}</h3>
            </div>
        </div>
        <div class="main-content">
            <div class="account-info">
                <div class="account-info__head">
                    <div class="account-info__head-detail">
                        <span class="account-info__head-icon"><i class="icon-fonts icon-fonts--sim"></i></span>
                        <h5 class="account-info__head-name">{{ isPre ? $t('mobile.account_information.title_pre') : $t('mobile.account_information.title_post') }}</h5>
                    </div><span class="account-info__head-value">{{phone}}</span>
                </div>
                <div class="account-info__content">
                    <div class="account-info__box">
                        <div class="account-info__top">
                            <h5 class="account-info__sub">{{ $t('mobile.account_information.general_information') }}</h5>
                            <div class="account-info__action" v-if="isPre"><span class="account-info__status">{{status}}</span></div>
                            <div class="account-info__action" v-if="!isPre"><span class="account-info__status">{{ $t('mobile.account_information.activated') }}</span></div>
                        </div>
                        <ul class="account-info__list">
                            <li class="account-info__item" v-for="(item, i) in genaralInfo"  :key="i">
                                <span class="account-info__name">{{item.title}}</span>
                                <div class="account-info__detail"><span class="account-info__value" :class="[i == 0 ? 'highlight':'']">{{item.value}}</span></div>
                            </li>
                        </ul>
                    </div>
                    <div class="account-info__box">
                        <div class="account-info__top">
                            <h5 class="account-info__sub">{{ $t('mobile.account_information.account_balance') }}</h5>
                            <div class="account-info__action-2btn">
                                <button class="button button--orange button--small" v-if="isPre" @click="onClickFunction('/mobile/recharge')">
                                    {{ $t('mobile.account_information.top_up') }}
                                </button>
                                <button class="button button--normal button--small" v-if="isPre" @click="onClickFunction('/mobile/credit-service?tab=1')">
                                    {{ $t('mobile.account_information.borrow_money') }}
                                </button>
                                <button class="button button--orange button--small" v-if="!isPre" @click="onClickFunction('/mobile/recharge')">
                                    {{ $t('mobile.account_information.pay_bill') }}
                                </button>
                            </div>
                        </div>
                        <ul class="account-info__list">
                            <li class="account-info__item"  v-for="(item, i) in accounts"  :key="i">
                                <span class="account-info__name">{{item.title}}</span>
                                <div class="account-info__detail">
                                    <span class="account-info__value" :class="[i == 0 ? 'large':'']">{{item.value}}</span>
                                    <p class="account-info__des">{{item.exp}}</p>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div class="account-info__box">
                        <div class="account-info__top">
                            <h5 class="account-info__sub">{{ $t('mobile.account_information.data_package') }}</h5>
                            <div class="account-info__action-2btn">
                                <button class="button button--orange button--small" v-if="isPre" @click="onClickFunction('/mobile/data-package')">
                                    {{ $t('mobile.account_information.buy') }}
                                </button>
                                <button class="button button--normal button--small" v-if="isPre" @click="onClickFunction('/mobile/credit-service?tab=2')">
                                    {{ $t('mobile.account_information.borrow_data') }}
                                </button>
                                <button class="button button--orange button--small" v-if="!isPre" @click="onClickFunction('/mobile/data-package')">
                                    {{ $t('mobile.account_information.buy_data') }}
                                </button>
                            </div>
                        </div>
                        <ul class="account-info__list">
                            <li class="account-info__item"  v-for="item in products"  :key="item.title">
                                <span class="account-info__name"><i class="icon-fonts icon-fonts--data-2"></i>{{item.title}}</span>
                                <div class="account-info__detail">
                                    <span class="account-info__value">{{item.value}}</span>
                                    <p class="account-info__des">{{item.exp}}</p>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div class="account-info__box">
                        <div class="account-info__top">
                            <h5 class="account-info__sub">{{ $t('mobile.account_information.history_usage') }}</h5>
                            <div class="account-info__action"><a class="account-info__more" href="/#/mobile/charge-history/bill-deals">{{ $t('mobile.account_information.more_detail') }}</a></div>
                        </div>
                        <div class="account-info__inner">
                            <div class="account-info__choose">
                                <div class="radio-custom">
                                    <input class="radio-custom__input" type="radio" name="radio" id="radio-1"  value="1" v-model="historyType"/>
                                    <label class="radio-custom__label" for="radio-1">{{ $t('mobile.account_information.data') }}</label>
                                </div>
                                <div class="radio-custom">
                                    <input class="radio-custom__input" type="radio" name="radio" id="radio-2" value="2" v-model="historyType" />
                                    <label class="radio-custom__label" for="radio-2">{{ $t('mobile.account_information.call') }}</label>
                                </div>
                                <div class="radio-custom">
                                    <input class="radio-custom__input" type="radio" name="radio" id="radio-3" value="3" v-model="historyType"/>
                                    <label class="radio-custom__label" for="radio-3">{{ $t('mobile.account_information.sms') }}</label>
                                </div>
                                <div class="radio-custom">
                                    <input class="radio-custom__input" type="radio" name="radio" id="radio-4" value="4" v-model="historyType" />
                                    <label class="radio-custom__label" for="radio-4">{{ $t('mobile.account_information.others') }}</label>
                                </div>
                            </div>
                            <div class="process-data" v-show="showData">
                                <div class="process-data__detail">
                                    <div class="process-data__top">
                                        <div class="process-data__info">
                                            <!-- <h4 class="process-data__name">{{ subAccount.dataPkgName }}</h4> -->
                                            <h4 class="process-data__name">{{ $t('mobile.account_information.data') }}</h4>
                                        </div>
                                        <div class="process-data__capacity"><span class="process-data__capacity-use">{{ Number(subAccount.dataVolume).toLocaleString('en-US', { maximumFractionDigits: 0 }) }} {{ $t('unit.mb') }}</span></div>
                                    </div>
                                    <div class="process-bar">
                                        <p class="process-bar__percent" :style="processStyle"></p>
                                    </div>
                                </div><span class="process-data__action" @click="onClickFunction('/mobile/credit-service?tab=2')"><i class="icon-fonts icon-fonts--add-2"></i></span>
                            </div>
                            <ul class="account-info__list" v-show="!showData">
                                <li class="account-info__item">
                                    <span class="account-info__name">{{ $t('mobile.account_information.total_call') }}</span>
                                    <div class="account-info__detail"><span class="account-info__value">{{ Number(totalCall).toLocaleString('en-US', { maximumFractionDigits: 0 }) }}</span></div>
                                </li>
                                <li class="account-info__item">
                                    <span class="account-info__name">{{ $t('mobile.account_information.total_charges') }}</span>
                                    <div class="account-info__detail"><span class="account-info__value">{{ Number(totalCharge).toLocaleString('en-US', { maximumFractionDigits: 0 }) }} KIP</span></div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import BaseComponent from '@/mixins/BaseComponent'
    import service from './../../services/MobileService'
    import LocalStorage from '@/ultils/local-storage'
    import moment from 'moment'
    import { DATE_FORMAT } from './../../constants/data'

    export default {
        name: 'AccountInformation',
        mixins: [BaseComponent],
        data() {
            return {
                phone: null,
                status: null,
                genaralInfo: [],
                accounts: [],
                products: [],
                processStyle: 'width: 80%',
                subAccount: {
                    prePost: 0,
                    debPost: 0,
                    mainAcc: 0,
                    proAcc: 0,
                    dataPkgName: '',
                    dataVolume: 0
                },
                historyType: 1,
                showData: true,
                callFee: 0,
                smsFee: 0,
                otherFee: 0,
                callRecords: 0,
                smsRecords: 0,
                otherRecords: 0,
                totalCall: 0,
                totalCharge: 0,
                isPre: true
            }
        },
        created() {
            this.subType = parseInt(LocalStorage.getItem('subType'))
            if (this.subType == 1) {
                this.isPre = true
            } else {
                this.isPre = false
            }
            var userInfo = JSON.parse(LocalStorage.getItem('user_info')) ?? {}
            this.phone = userInfo['isdn']
            this.setTitle(this.$i18n.t('mobile.account_information.title'))
            this.loadData()
        },
        watch: {
            historyType(val){
                if (val == 1) {
                    this.showData = true;
                } else {
                    this.showData = false;
                    if (val == 2) {
                        this.totalCall = this.callRecords;
                        this.totalCharge = this.callFee;
                    }
                    if (val == 3) {
                        this.totalCall = this.smsRecords;
                        this.totalCharge = this.smsFee;
                    }
                    if (val == 4) {
                        this.totalCall = this.otherRecords;
                        this.totalCharge = this.otherFee;
                    }
                }
            }
        },
        methods: {
            loadData() {
                this.showLoading()
                service
                    .getAccountsDetail({ subType: this.subType })
                    .then((response) => {
                        var data = response.wsResponse ?? []
                        if (data) {
                            var genaral_info = data[0]
                            if (genaral_info) {
                                this.status = genaral_info.values.pop().value
                                this.genaralInfo = genaral_info.values
                                // if(this.status == "Activated\t" || this.status == "Activated"){
                                //     this.genaralInfo = genaral_info[0].values
                                // } else {
                                //     this.genaralInfo = genaral_info[0].values
                                // }
                            }

                            var account = data[1]
                            if (account) {
                                this.accounts = account.values
                            }

                            var product = data[3]
                            if (product) {
                                this.products = product.values
                            }
                        }
                        this.hideLoading()
                    })
                    .catch(() => {
                        this.hideLoading()
                    })

                service
                    .getSubAccountInfo({ subType: this.subType })
                    .then((response) => {
                        let data = response.wsResponse
                        this.subAccount = {
                            prePost: data.prePost ?? 0,
                            debPost: data.debPost ?? 0,
                            mainAcc: data.mainAcc ?? 0,
                            proAcc: data.proAcc ?? 0,
                            dataPkgName: data.name,
                            dataVolume: data.dataVolume
                        }
                    })
                    .catch(() => {
                        this.hideLoading()
                    })

                var startDate = this.getMiliseconds(moment().startOf('month').format(DATE_FORMAT), DATE_FORMAT);

                var endDate = this.getMiliseconds(moment().format(DATE_FORMAT), DATE_FORMAT);
                service
                    .getPostageInfo({
                        startDate: startDate,
                        endDate: endDate,
                        type: '',
                        subType: this.subType
                    })
                    .then((response) => {
                        this.callFee = response.wsResponse.callFee
                        this.smsFee = response.wsResponse.smsFee
                        this.otherFee = response.wsResponse.otherFee
                        this.callRecords = response.wsResponse.callRc
                        this.smsRecords = response.wsResponse.smsRc
                        this.otherRecords = response.wsResponse.otherRc
                    })
                    .catch(() => {
                        this.hideLoading()
                    })
            },
            async onClickFunction(url) {
                this.$router.push(url)
            }
        }
    }
</script>
