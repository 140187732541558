<template>
    <div>
        <div class="main-container" v-show="showMain">
            <div class="nav-bar js-nav-bar">
                <div class="nav-bar__inner">
                    <span class="nav-bar__icon" @click="goBack"><i class="icon-fonts icon-fonts--arrow-left"></i></span>
                    <h3 class="nav-bar__title">{{ $t('mobile.change_to_postpaid.title') }}</h3>
                </div>
            </div>
            <div class="main-content">
                <div class="change-postpaid">
                    <div class="steps-bar">
                        <ul class="steps-bar__list">
                            <li class="steps-bar__item done">
                                <span class="steps-bar__value">1</span>
                                <h5 class="steps-bar__name">{{ $t('mobile.change_to_postpaid.select') }}<br>{{ $t('mobile.change_to_postpaid.package') }}</h5>
                            </li>
                            <li class="steps-bar__item active">
                                <span class="steps-bar__value">2</span>
                                <h5 class="steps-bar__name">{{ $t('mobile.change_to_postpaid.customer') }}<br>{{ $t('mobile.change_to_postpaid.information') }}</h5>
                            </li>
                            <li class="steps-bar__item">
                                <span class="steps-bar__value">3</span>
                                <h5 class="steps-bar__name">{{ $t('mobile.change_to_postpaid.contract') }}</h5>
                            </li>
                            <li class="steps-bar__item">
                                <span class="steps-bar__value">4</span>
                                <h5 class="steps-bar__name">{{ $t('mobile.change_to_postpaid.result') }}</h5>
                            </li>
                        </ul>
                    </div>
                    <h4 class="change-postpaid__title">{{ $t('mobile.change_to_postpaid.personal') }} {{ $t('mobile.change_to_postpaid.information') }}</h4>
                    <ValidationObserver v-slot="{passes}">
                        <form @submit.prevent="passes(onSubmit)">
                            <div class="register-info">
                                <div class="register-info__form">
                                    <div class="form-customize">
                                        <Input type="number"
                                               :label="$t('home.registration_info.phone_number')"
                                               :placeholder="$t('home.registration_info.enter_phone_number')"
                                               rules="required|phone"
                                               :hasStar="true"
                                               disabled
                                               v-model="data.phone" />
                                    </div>
                                    <div class="form-customize">
                                        <label class="label-title" for="per-info">
                                            {{$t('home.registration_info.personal_info')}} <span style="color:red">(*)</span>
                                        </label>
                                        <Input type="text"
                                               :label="$t('home.registration_info.full_name')"
                                               :placeholder="$t('home.registration_info.enter_full_name')"
                                               rules="required"
                                               :isLabel="false"
                                               :hasStar="true"
                                               v-model="data.name" />
                                    </div>
                                    <div class="form-customize">
                                        <Input type="date"
                                               :label="$t('home.registration_info.birth_day')"
                                               :placeholder="$t('home.registration_info.enter_birth_day')"
                                               rules="required|date"
                                               :isLabel="false"
                                               rightIcon="icon-fonts icon-fonts--calendar"
                                               v-model="dateOfBirth" />
                                    </div>
                                    <div class="form-customize">
                                        <Select :label="$t('home.registration_info.gender')"
                                                :placeholder="$t('home.registration_info.enter_gender')"
                                                rules="required"
                                                :dataSelect="Genders"
                                                :isLabel="false"
                                                v-model="data.sex" />
                                    </div>
                                    <div class="form-customize">
                                        <Select :label="$t('home.registration_info.document_type')"
                                                :placeholder="$t('home.registration_info.enter_document_type')"
                                                rules="required"
                                                :dataSelect="TypeId"
                                                :hasStar="true"
                                                v-model="idType" />
                                    </div>
                                    <div class="form-customize">
                                        <Input type="text"
                                               :label="$t('home.registration_info.document_number')"
                                               :placeholder="$t('home.registration_info.enter_document_number')"
                                               rules="required"
                                               :hasStar="true"
                                               v-model="data.idNo" />
                                    </div>
                                    <div class="form-customize">
                                        <Input type="date"
                                               :label="$t('mobile.change_to_postpaid.issued_date')"
                                               :placeholder="$t('mobile.change_to_postpaid.enter_issued_date')"
                                               rules="required|date"
                                               :isLabel="false"
                                               rightIcon="icon-fonts icon-fonts--calendar"
                                               v-model="idIssueDate" />
                                    </div>
                                    <div class="form-customize">
                                        <Input type="text"
                                               :label="$t('mobile.change_to_postpaid.issued_office')"
                                               :placeholder="$t('mobile.change_to_postpaid.enter_issued_office')"
                                               rules="required"
                                               :isLabel="false"
                                               v-model="data.idIssuePlace" />
                                    </div>
                                    <div class="form-customize">
                                        <Input type="text"
                                               :label="$t('mobile.change_to_postpaid.email')"
                                               rules="email"
                                               :placeholder="$t('mobile.change_to_postpaid.enter_email')"
                                               v-model="data.email" />
                                    </div>
                                    <div class="form-customize">
                        <ValidationProvider
                           tag="div"
                           rules="required"
                           :name="$t('ftth.ftth_order.province')"
                           v-slot="{ errors }"
                        >
                           <label class="label-title">{{ $t('ftth.ftth_order.province') }} <span style="color:red">(*)</span></label>
                           <div class="select-box js-select-box">
                              <select
                                 class="js-custom-select"
                                 :id="$t('ftth.ftth_order.province')"
                                 :data-placeholder="$t('ftth.ftth_order.select_province')"
                                 v-model="provinceId"
                              >
                                 <option value=""></option>
                                 <option :value="province.id" v-for="province in provinces" :key="province.name">
                                    {{ province.name }}
                                 </option>
                              </select>
                           </div>
                           <p class="error-text" v-if="errors[0]">{{ errors[0] }}</p>
                        </ValidationProvider>
                     </div>
                     <div class="form-customize">
                        <ValidationProvider
                           tag="div"
                           rules="required"
                           :name="$t('ftth.ftth_order.district')"
                           v-slot="{ errors }"
                        >
                           <label class="label-title">{{ $t('ftth.ftth_order.district') }} <span style="color:red">(*)</span></label>
                           <div class="select-box js-select-box">
                              <select
                                 class="js-custom-select"
                                 :id="$t('ftth.ftth_order.district')"
                                 :data-placeholder="$t('ftth.ftth_order.select_district')"
                                 v-model="districtId"
                              >
                                 <option value=""></option>
                                 <option :value="district.id" v-for="district in districts" :key="district.name">
                                    {{ district.name }}
                                 </option>
                              </select>
                           </div>
                           <p class="error-text" v-if="errors[0]">{{ errors[0] }}</p>
                        </ValidationProvider>
                     </div>
                     <div class="form-customize">
                        <ValidationProvider
                           tag="div"
                           rules="required"
                           :name="$t('ftth.ftth_order.precinct')"
                           v-slot="{ errors }"
                        >
                           <label class="label-title">{{ $t('ftth.ftth_order.precinct') }} <span style="color:red">(*)</span></label>
                           <div class="select-box js-select-box">
                              <select
                                 class="js-custom-select"
                                 :id="$t('ftth.ftth_order.precinct')"
                                 :data-placeholder="$t('ftth.ftth_order.select_precinct')"
                                 v-model="precinctId"
                              >
                                 <option value=""></option>
                                 <option :value="precinct.id" v-for="precinct in precincts" :key="precinct.name">
                                    {{ precinct.name }}
                                 </option>
                              </select>
                           </div>
                           <p class="error-text" v-if="errors[0]">{{ errors[0] }}</p>
                        </ValidationProvider>
                     </div>
                                    <div class="form-customize">
                                        <Input type="text"
                                               :label="$t('mobile.change_to_postpaid.address')"
                                               :placeholder="$t('mobile.change_to_postpaid.enter_address')"
                                               v-model="data.address" />
                                    </div>

                                    <div class="form-customize">
                                        <div class="register-upload">
                                            <div class="register-upload__top">
                                                <label class="label-title">{{$t('home.registration_info.image_document')}} <span style="color:red">(*)</span></label>

                                                <button class="button button--upload" type="button" @click="chooseCamera('cDoc1')"  id="image1">
                                                    <i class="icon-fonts icon-fonts--camera"></i>
                                                </button>

                                                <button class="button button--upload" @click="chooseFiles('fDoc1')" type="button">{{$t('home.registration_info.browse')}}</button>
                                            </div>
                                            <br v-if="data.image1 == './images/idtype.png'" />
                                            <div class="register-upload__action" v-if="data.image1 && data.image1 != './images/idtype.png'" @click="onDeleteImage1"><span class="register-upload__delete"><a href="javascript:;" style="color:red">{{$t('home.registration_info.delete')}}</a></span></div>
                                            <p class="register-upload__noti" v-if="errorImg1">{{$t('home.registration_info.no_image')}}</p>
                                            <div class="register-upload__content"><img id="container1" :src="image1" alt="img" width="345" height=""></div>
                                        </div>
                                    </div>

                                    <!--Cho nay la cho input file va camera-->
                                    <input type="file" id="cameraUpload" accept="image/*" capture="camera" @change="onCameraChange" hidden />
                                    <input id="fileUpload" type="file" accept="image/png, image/jpeg" @change="onFileChange" hidden>
                                    <!-------------------------------------------------------------------------------------------------------------->

                                    <div class="form-customize">
                                        <div class="register-upload">
                                            <div class="register-upload__top">
                                                <label class="label-title">{{$t('home.registration_info.image_customer')}} <span style="color:red">(*)</span></label>
                                                <button class="button button--upload" type="button" @click="chooseCamera('cCus')">
                                                    <i class="icon-fonts icon-fonts--camera"></i>
                                                </button>
                                            </div>
                                            <br v-if="data.image2 == './images/image_client1.png'" />
                                            <div class="register-upload__action" v-if="data.image2 && data.image2 != './images/image_client1.png'" @click="onDeleteImage3"><span class="register-upload__delete"><a href="javascript:;" style="color:red">{{$t('home.registration_info.delete')}}</a></span></div>
                                            <p class="register-upload__noti" v-if="errorImg3">{{$t('home.registration_info.no_image_customer')}}</p>
                                            <div class="register-upload__content"><img id="container2" :src="image2" alt="img" width="345" height="250"></div>
                                        </div>
                                    </div>
                                </div>
                                <div class="register-info__btn">
                                    <button class="button button--primary button--full">
                                        {{$t('buttons.submit')}}
                                    </button>
                                </div>
                            </div>
                        </form>
                    </ValidationObserver>
                </div>
            </div>
        </div>
        <!--Man crop-->
        <div class="main-container" v-show="!showMain">
            <div class="nav-bar js-nav-bar">
                <div class="nav-bar__inner">
                    <span class="nav-bar__icon" @click="backMain"><i class="icon-fonts icon-fonts--arrow-left"></i></span>
                    <h3 class="nav-bar__title">{{$t('mobile.change_to_postpaid.title')}}</h3><a @click="rotateImage" class="nav-bar__link" style="margin-right:150px; display: none" href="javascript:;">{{$t('home.registration_info.rotate')}}</a> <a @click="plipImage(2)" class="nav-bar__link" style="margin-right:100px; display: none" href="javascript:;">{{$t('home.registration_info.plipV')}}</a><a @click="plipImage(1)" class="nav-bar__link" style="margin-right:50px; display: none" href="javascript:;">{{$t('home.registration_info.plipH')}}</a> <a @click="getCropImage()" class="nav-bar__link" href="javascript:;">{{$t('home.registration_info.crop')}}</a>
                </div>
            </div>
            <div class="main-content">
                <div class="fullscreenImage">
                    <cropper :transitions="true"
                             :auto-zoom="true"
                             ref="cropper"
                             default-boundaries="fill"
                             class="cropper"
                             image-class="cropper__image"
                             :src="url"></cropper>
                </div>
            </div>

        </div>
    </div>
</template>
<script>
    import BaseComponent from '@/mixins/BaseComponent'
    import service from './../../services/MobileService'
    import store from '@/plugins/vuex/store'
    import { DATE_FORMAT } from './../../constants/data'
    import LocalStorage from '@/ultils/local-storage'
    import { TypeId, Genders } from './../../constants/data'
    import { ValidationObserver } from 'vee-validate'
    import Input from '@/components/form/Input'
    import Select from '@/components/form/Select'
    import { Cropper } from 'vue-advanced-cropper'
    import 'vue-advanced-cropper/dist/style.css';

    export default {
        name: 'CustomerInfo',
        mixins: [BaseComponent],
        components: {
            ValidationObserver,
            Input,
            Select,
            Cropper
        },
        data() {
            return {
                lstProductForTransfer: [],
                data: {
                    phone: null,
                    name: null,
                    dateOfBirth: null,
                    sex: null,
                    idType: null,
                    idNo: null,
                    idIssueDate: null,
                    idIssuePlace: null,
                    email: null,
                    address: null,
                    lstCusProfile: [
                        {
                            imageLabel: "Font side",
                            content: ""
                        },
                        {
                            imageLabel: "Avatar",
                            content: ""
                        }
                    ],
                    image1: "./images/idtype.png",
                    image2: "./images/image_client1.png"

                },
                image1: "./images/idtype.png",
                image2: "./images/image_client1.png",
                dataStore: JSON.parse(LocalStorage.getItem('change-to-postpaid')),
                url: null,
                idType: null,
                imageType: null,
                dateOfBirth: null,
                idIssueDate: null,
                showMain: true,
                TypeId: TypeId,
                Genders: Genders,
                errorImg1: false,
                errorImg3: false,
                provinceId: null,
                districtId: null,
                precinctId: null,
                provinces: [],
                districts: [],
                precincts: []
            }
        },
        created() {
            this.setTitle(this.$i18n.t('mobile.change_to_postpaid.title'))
            var userInfo = JSON.parse(LocalStorage.getItem('user_info')) ?? {}
            this.data.phone = userInfo['isdn']
            if (this.dataStore.step2) {
                this.data = this.dataStore.step2;
                this.idType = this.data.idType;
                this.image1 = this.data.image1
                this.image2 = this.data.image2
                this.dateOfBirth = this.data.dateOfBirth
                this.idIssueDate = this.data.idIssueDate
            } else {
                this.loadData()
            }
            delete this.dataStore.step3
            this.loadScript('js/select2.js')
            this.loadScript('js/date-picker.js')
            this.loadScript('js/date-month-picker.js')
            this.getProvinces()
        },
        watch: {
        provinceId() {
         this.districtId = null
         this.precinctId = null
         this.getDistricts()
         this.getPrecincts()
      },
      districtId() {
         this.precinctId = null
         this.getPrecincts()
      },
            idType(val, valOld) {
                if (valOld) {
                    this.data.idType = val;
                    if (this.data.idType == 3) {
                        this.image2 = "./images/image_client2.png";
                    } else if (this.data.idType == 6) {
                        this.image2 = "./images/image_client3.png";
                    } else {
                        this.image2 = "./images/image_client1.png";
                    }

                    this.image1 = "./images/idtype.png";
                } else {
                    this.data.idType = val;
                }
            },
            image1(val) {
                this.data.image1 = val;
            },
            image2(val) {
                this.data.image2 = val;
            },
            showMain(val) {
                var vm = this;
                if (val) {
                    if (vm.imageType == "cDoc1" || vm.imageType == "fDoc1") {
                        setTimeout(function () {
                            const el = vm.$el.querySelector("#container1");
                            if (el) {
                                el.scrollIntoView();
                            }
                        }, 50)
                    }
                    if (vm.imageType == "cCus") {
                        setTimeout(function () {
                            const el = vm.$el.querySelector("#container2");
                            if (el) {
                                el.scrollIntoView();
                            }
                        }, 50)
                    }
                }
            },
            dateOfBirth(newVal) {
                if(newVal && newVal.length == 10){
                    let current = this.getMiliseconds(new Date(), DATE_FORMAT)
                    let dateNum = this.getMiliseconds(newVal, DATE_FORMAT)
                    if (dateNum > 0) {
                        if (dateNum > current) {
                            this.dateOfBirth = null
                            store.dispatch('setNotification', {
                                content: this.$i18n.t('mobile.change_to_postpaid.error_birthday')
                            })
                        }
                        this.data.dateOfBirth = this.dateOfBirth;
                    }
                    
                    this.loadScript('js/date-picker.js')
                    this.loadScript('js/date-month-picker.js')
                }
            },
            idIssueDate(newVal) {
                // let current = this.getMiliseconds(new Date(), DATE_FORMAT)
                // let dateNum = this.getMiliseconds(newVal, DATE_FORMAT)
                // if (dateNum > current) {
                //     this.idIssueDate = null
                //     store.dispatch('setNotification', {
                //         content: this.$i18n.t('mobile.change_to_postpaid.error_idIssuePlace')
                //     })
                // }
                // this.data.idIssueDate = this.idIssueDate;
                // this.loadScript('js/date-picker.js')
                // this.loadScript('js/date-month-picker.js')
                if(newVal && newVal.length == 10){
                    let current = this.getMiliseconds(new Date(), DATE_FORMAT)
                    let dateNum = this.getMiliseconds(newVal, DATE_FORMAT)
                    if (dateNum > 0) {
                        if (dateNum > current) {
                            this.idIssueDate = null
                            store.dispatch('setNotification', {
                                content: this.$i18n.t('mobile.change_to_postpaid.error_birthday')
                            })
                        }
                        this.data.idIssueDate = this.idIssueDate;
                    }
                    
                    this.loadScript('js/date-picker.js')
                    this.loadScript('js/date-month-picker.js')
                }
            }
        },
        methods: {
            loadData() {
                this.showLoading()
                service
                    .getCustInfoTransfer()
                    .then((response) => {
                        if (response.errorCode == "0") {
                            var phone = this.data.phone;
                            this.data = response.object.customerInfo ?? []
                            this.data.phone = phone;
                            this.idType = this.data.idType;
                            this.provinceId = response.object.provinceCode ?? '00'
                            this.districtId = response.object.districtCode ?? '00'
                            this.precinctId = response.object.precinctCode ?? '00'
                            //date
                            if (this.data.birthDate) {
                                this.dateOfBirth = convertDatetoString(this.data.birthDate)
                            }
                            if (this.data.idIssueDate) {
                                this.idIssueDate = convertDatetoString(this.data.idIssueDate)
                            }

                            //anh
                            let content1 = this.data.lstCusProfile.filter(x => x.imageLabel == "Font side");
                            if (content1) {
                                if (content1[0].content) {
                                    this.image1 = 'data:image/jpeg;base64,' + content1[0].content;
                                } else {
                                    this.image1 = "./images/idtype.png"
                                }
                            } else {
                                this.image1  = "./images/idtype.png"
                            }
                            let content2 = this.data.lstCusProfile.filter(x => x.imageLabel == "Avatar");
                            if (content2) {
                                if (content2[0].content) {
                                    this.image2 = 'data:image/jpeg;base64,' + content2[0].content;
                                } else {
                                    this.image2 = "./images/image_client1.png"
                                }
                            } else {
                                this.image2 = "./images/image_client1.png"
                            }

                            this.hideLoading()
                            this.loadScript('js/select2.js')
                            this.loadScript('js/date-picker.js')
                            this.loadScript('js/date-month-picker.js')
                        } else {
                            this.hideLoading()
                            store.dispatch('setNotification', {
                                title: this.$i18n.t('notification.title'),
                                content: response.errorMessage,
                                closeButton: this.$i18n.t('buttons.close')
                            })
                        }
                    })
                    .catch(() => {
                        this.hideLoading()
                    })
            },
            chooseFiles(type) {
                if (type != "cCus") {
                    if (!this.data.idType) {
                        store.dispatch('setNotification', {
                            title: this.$i18n.t('notification.title'),
                            content: this.$i18n.t('home.registration_info.enter_document_type'),
                            closeButton: this.$i18n.t('buttons.close')
                        })
                        return
                    }
                }

                document.getElementById("fileUpload").click()
                this.imageType = type;
            },
            onFileChange(e) {
                const file = e.target.files[0];
                this.url = URL.createObjectURL(file);
                this.showMain = false;
            },
            chooseCamera(type) {
                if (type == "cCus") {
                    if (this.data.image1 == null || this.data.image1 == "./images/idtype.png") {
                        store.dispatch('setNotification', {
                            title: this.$i18n.t('notification.title'),
                            content: this.$i18n.t('home.registration_info.notiContent'),
                            closeButton: this.$i18n.t('buttons.close')
                        })
                        return
                    }
                } else {
                    if (!this.data.idType) {
                        store.dispatch('setNotification', {
                            title: this.$i18n.t('notification.title'),
                            content: this.$i18n.t('home.registration_info.enter_document_type'),
                            closeButton: this.$i18n.t('buttons.close')
                        })
                        return
                    }
                }
                document.getElementById("cameraUpload").click()
                this.imageType = type;
            },
            onCameraChange(e) {
                const file = e.target.files[0];
                this.url = URL.createObjectURL(file);
                this.showMain = false;
            },
            backMain() {
                this.showMain = true;
                if (this.imageType == "cDoc1" || this.imageType == "fDoc1") {
                    this.image1 = "./images/idtype.png";
                }
                if (this.imageType == "cCus") {
                    this.image2 = "./images/image_client1.png";
                }
                document.getElementById("fileUpload").value = null
                document.getElementById("cameraUpload").value = null
            },
            rotateImage() {
                this.$refs.cropper.rotate(90);
            },
            plipImage(number) {
                if (number == 1) {
                    this.$refs.cropper.flip(true, false);
                } else {
                    this.$refs.cropper.flip(false, true);
                }

            },
            closeCrop() {
                this.$emit('close')
            },
            getCropImage() {
                var urlResult = this.$refs.cropper.getResult().canvas.toDataURL("image/jpeg");
                if (this.imageType == "cDoc1" || this.imageType == "fDoc1") {
                    this.errorImg1 = false;
                    this.image1 = urlResult ? urlResult : "./images/idtype.png";
                    //this.getInfoAI(this.data.image1)
                }
                if (this.imageType == "cCus") {
                    this.errorImg3 = false;
                    this.image2 = urlResult ? urlResult : "./images/image_client1.png";
                    //this.compareFace(this.data.image2)
                }
                document.getElementById("fileUpload").value = null
                document.getElementById("cameraUpload").value = null
                this.showMain = true;

            },
            onDeleteImage1() {
                this.image1 = "./images/idtype.png";
                this.image2 = "./images/image_client1.png";
                if (this.data.idType == 3) {
                    this.image2 = "./images/image_client2.png";
                } else if (this.data.idType == 6) {
                    this.image2 = "./images/image_client3.png";
                } else {
                    this.image2 = "./images/image_client1.png";
                }
            },
            onDeleteImage3() {
                this.image2 = "./images/image_client1.png";
                if (this.data.idType == 3) {
                    this.image2 = "./images/image_client2.png";
                } else if (this.data.idType == 6) {
                    this.image2 = "./images/image_client3.png";
                } else {
                    this.image2 = "./images/image_client1.png";
                }
            },
            onSubmit() {
                if (this.data.image1 == null || this.data.image1 == "./images/idtype.png") {
                    this.errorImg1 = true;
                }

                if (this.data.image2 == null || this.data.image2 == "./images/image_client1.png" || this.data.image2 == "./images/image_client2.png" || this.data.image2 == "./images/image_client3.png") {
                    this.errorImg3 = true;
                }
                if (this.errorImg1 || this.errorImg3) {
                    return
                }
                const [day, month, year] = this.data.dateOfBirth.split('/');
                const date = new Date(+year, month - 1, +day);

                const [day1, month1, year1] = this.data.idIssueDate.split('/');
                const date1 = new Date(+year1, month1 - 1, +day1);
                if(this.data.lstCusProfile.length === 3 ){
                    this.data.lstCusProfile[0].content = this.data.image1.replace("data:image/jpeg;base64,", "").replace("data:image/png;base64,", "")
                    this.data.lstCusProfile[2].content = this.data.image2.replace("data:image/jpeg;base64,", "").replace("data:image/png;base64,", "")
                    if(this.data.lstCusProfile[1].content == '' || typeof this.data.lstCusProfile[1].content == 'undefined'){
                        this.data.lstCusProfile[1].content = this.data.image1.replace("data:image/jpeg;base64,", "").replace("data:image/png;base64,", "")
                    }
                }
                
                let param = {
                    phone: this.data.phone,
                    name: this.data.name,
                    dateOfBirth: this.data.dateOfBirth,
                    dateOfBirthConvert: Date.parse(date),
                    sex: this.data.sex,
                    idType: this.data.idType,
                    idNo: this.data.idNo,
                    idIssueDate: this.data.idIssueDate,
                    idIssueDateConvert: Date.parse(date1),
                    idIssuePlace: this.data.idIssuePlace,
                    email: this.data.email,
                    address: this.data.address,
                    // lstCusProfile: this.data.lstCusProfile,
                    // image1: this.data.image1,
                    // image2: this.data.image2,
                    provinceId: this.provinceId,
                    precinctId: this.precinctId,
                    districtId: this.districtId
                }

                this.dataStore["step2"] = param;
                LocalStorage.setItem('change-to-postpaid', JSON.stringify(this.dataStore))
                store.commit("setLstCusProfile", this.data.lstCusProfile)
                this.$router.push('/mobile/change_to_postpaid/contract-type')
               
            },
     getProvinces() {
        //  this.showLoading()
         this.provinces = []
         service
            .getProvinces()
            .then((response) => {
               if (response.errorCode === '0') {
                  this.provinces = response.wsResponse
                  this.loadScript('js/select2.js')
               } else {
                  this.$store.dispatch('setNotification', { content: response.message ?? response.userMsg })
               }
            })
            .finally(() => {
            //    this.hideLoading()
            })
      },
      getDistricts() {
        //  this.showLoading()
         this.districts = []
         if (this.provinceId) {
            service
               .getDistricts({ provinceId: this.provinceId })
               .then((response) => {
                  if (response.errorCode === '0') {
                     this.districts = response.wsResponse
                     this.loadScript('js/select2.js')
                  } else {
                     this.$store.dispatch('setNotification', { content: response.message ?? response.userMsg })
                  }
               })
               .finally(() => {
                //   this.hideLoading()
               })
         }
      },
      getPrecincts() {
        //  this.showLoading()
         this.precincts = []
         if (this.provinceId && this.districtId) {
            service
               .getPrecinct({ provinceId: this.provinceId, districtId: this.districtId })
               .then((response) => {
                  if (response.errorCode === '0') {
                     this.precincts = response.wsResponse
                     this.loadScript('js/select2.js')
                  } else {
                     this.$store.dispatch('setNotification', { content: response.message ?? response.userMsg })
                  }
               })
               .finally(() => {
                //   this.hideLoading()
               })
         }
      },
        }
    }


    function convertDatetoString(unix_timestamp) {
        var date = new Date(unix_timestamp);
        const yyyy = date.getFullYear();
        let mm = date.getMonth() + 1; // Months start at 0!
        let dd = date.getDate();

        if (dd < 10) dd = '0' + dd;
        if (mm < 10) mm = '0' + mm;

        return dd + '/' + mm + '/' + yyyy
    }
</script>
