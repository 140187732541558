<template>
   <div class="main-container">
      <div class="nav-bar js-nav-bar" @click="goBack">
         <div class="nav-bar__inner">
            <span class="nav-bar__icon"><i class="icon-fonts icon-fonts--arrow-left"></i></span>
            <h3 class="nav-bar__title">{{ data.name }}</h3>
         </div>
      </div>
      <div class="main-content">
         <div class="data-detail">
            <div class="data-price">
               <ul class="data-price__list">
                  <li class="data-price__item">
                     <h4 class="data-price__name">{{ $t('mobile.data_packages.price') }}</h4>
                     <div class="data-price__info">
                        <span class="data-price__value"
                           >{{ Number(packDetail.price).toLocaleString('en-US', { maximumFractionDigits: 0 })
                           }}<span>{{ $t('unit.Kip') }}</span></span
                        >
                     </div>
                  </li>
                  <li class="data-price__item">
                     <h4 class="data-price__name">{{ $t('mobile.data_packages.data') }}</h4>
                     <div class="data-price__info">
                        <span class="data-price__value"
                           >{{ Number(packDetail.volume).toLocaleString('en-US', { maximumFractionDigits: 0 })
                           }}{{ $t('unit.mb') }}</span
                        >
                     </div>
                  </li>
               </ul>
            </div>
            <div class="data-detail__content" v-html="packDetail.fullDes"></div>
            <div class="data-detail__btn">
               <button class="button button--primary button--full" @click="openRegisterModal">
                  {{ $t('buttons.register') }}
               </button>
            </div>
         </div>
      </div>
      <register-pack-flow :data="flowData" />
   </div>
</template>
<script>
import BaseComponent from '@/mixins/BaseComponent'
import LocalStorage from '@/ultils/local-storage'
import service from './../../services/MobileService'
import { PackageType } from '../../constants/data'
import RegisterPackFlow from './../common/RegisterPackFlow'

export default {
   name: 'DataPackageDetail',
   components: {
      RegisterPackFlow
   },
   mixins: [BaseComponent],
   data() {
      return {
         type: 0,
         data: {},
         packDetail: {},
         flowData: {},
         isUmoney: '0',
         isLaptop: '0',
         code: '',
         isXboxService: 0
      }
   },
   created() {
      this.setTitle(this.$i18n.t('mobile.data_packages.detail'))
      let pack = JSON.parse(LocalStorage.getItem('package'))
      this.data = pack ? pack.data : {}
      this.type = this.$route.query.type ?? pack.type
      this.code = this.$route.query.code ?? this.data.code
      this.isXboxService = this.$route.query.isXboxService ?? this.data.isXboxService
      this.getDataPackageDetailByCode(this.code)
      service
         .getUMoney()
         .then((response) => {
            if (response.errorCode === '0') {
               let uMoney = response.wsResponse ?? {}
               if (uMoney && Object.keys(uMoney).length != 0) {
                  this.isUmoney = '1'
               }
            }
         })
         .finally(() => {})
      service
         .xboxGetService({
            gameCode: 'XBOX_GAME',
            subType: LocalStorage.getItem('subType')
         })
         .then((response) => {
            if (response.errorCode == '0') {
               this.isLaptop = response.wsResponse.isLaptop
            }
         })
         .finally(() => {})
   },
   methods: {
      getDataPackageDetailByCode(code) {
         this.showLoading()
         if (this.type == PackageType.NormalPackage) {
            service
               .getDataPackageDetailByCode({ packageCode: code })
               .then((response) => {
                  this.packDetail = response.wsResponse
                  if(!response.wsResponse) {
                        service
                        .getDataPromotionDetail({ packageCode: code })
                        .then((response) => {
                           this.packDetail = response.wsResponse ?? {}
                        })
                        .finally(() => {
                           this.hideLoading()
                        })
                  }
               })
               .finally(() => {
                  this.hideLoading()
               })
         } else {
            service
               .getDataPromotionDetail({ packageCode: code })
               .then((response) => {
                  this.packDetail = response.wsResponse
               })
               .finally(() => {
                  this.hideLoading()
               })
         }
      },
      openRegisterModal() {
         if (this.isXboxService == 1) {
            this.xboxRegisterService()
         } else {
            this.data.code = this.code
            this.flowData = Object.assign({}, this.data)
         }
      },
      //xbox
      xboxRegisterService() {
         this.showLoading()
         service
            .xboxRegisterService({
               serviceCode: this.code,
               isLaptop: this.isLaptop,
               gameCode: 'XBOX_GAME'
            })
            .then((response) => {
               if (response.errorCode == '0') {
                  let result = response.wsResponse.moId
                  let maxRetryCheckMo = response.wsResponse.maxRetryCheckMo
                  let delayTime = response.wsResponse.delayTime
                  this.xboxGetGift(result, maxRetryCheckMo, delayTime)
               } else {
                  this.$store.dispatch('setNotification', { content: response.message ?? response.userMsg })
                  this.hideLoading()
               }
               // console.log(response)
            })
            .catch(() => {
               this.hideLoading()
            })
      },
      xboxGetGift(moId, maxRetryCheckMo, delayTime) {
         this.showLoading()
         var counter = 0
         var errorCode = 2
         var interval = setInterval(() => {
            if (counter < maxRetryCheckMo && errorCode == '2') {
               service
                  .xboxGetGift({
                     moId: moId,
                     gameCode: 'XBOX_GAME',
                     isUmoney: this.isUmoney
                  })
                  .then((response) => {
                     errorCode = response.errorCode
                     if (errorCode != '2') {
                        if (response.errorCode == '0') {
                           this.$store.dispatch('setNotification', {
                              content: response.message ?? response.userMsg,
                              icon: './images/ic-success.svg'
                           })
                        } else {
                           this.$store.dispatch('setNotification', { content: response.message ?? response.userMsg })
                        }
                     }
                  })
                  .catch(() => {
                     this.hideLoading()
                  })
                  .finally(() => {
                     this.hideLoading()
                     counter++
                  })
            } else {
               clearInterval(interval)
               this.hideLoading()
            }
         }, delayTime * 1000)
      }
   }
}
</script>
