<template>
   <div class="main-container">
      <div class="nav-bar js-nav-bar" @click="goBack">
         <div class="nav-bar__inner">
            <span class="nav-bar__icon"><i class="icon-fonts icon-fonts--arrow-left"></i></span>
            <h3 class="nav-bar__title">{{ $t('home.categories.game') }}</h3>
         </div>
      </div>
      <div class="main-content">
         <div class="category-list">
            <ul class="category-list__content">
               <li class="category-list__item" v-for="game in games" :key="game.name">
                  <a class="category-list__link" href="javascript:void(0)" @click="checkAndRedirect(game.urlGame)">
                     <div class="category-list__images"><img :src="game.urlImg" alt="img" /></div>
                     <div class="category-list__detail">
                        <div class="category-list__info">
                           <h5 class="category-list__name">{{ game.name }}</h5>
                           <p class="category-list__des">{{ game.urlGame }}</p>
                        </div>
                        <span class="category-list__action"><i class="icon-fonts icon-fonts--arrow-right"></i></span>
                     </div>
                  </a>
               </li>
            </ul>
         </div>
      </div>
   </div>
</template>
<script>
import BaseComponent from '@/mixins/BaseComponent'
import service from './../services/HomeService'
import LocalStorage from '@/ultils/local-storage'

export default {
   name: 'Game',
   mixins: [BaseComponent],
   data() {
      return {
         games: []
      }
   },
   created() {
      this.setTitle(this.$i18n.t('home.categories.game'))
      this.loadGames()
   },
   methods: {
      loadGames() {
         this.showLoading()
         service
            .getListWebGame()
            .then((response) => {
               this.games = response.wsResponse
            })
            .finally(() => {
               this.hideLoading()
            })
      },
      checkAndRedirect(link) {
         let userInfo = JSON.parse(LocalStorage.getItem('user_info'))
         let lang = LocalStorage.getItem('lang')
         if (userInfo) {
            window.location.href = `${link}?token=${userInfo['token']}&isdn=${userInfo['isdn']}}&language=${lang}&sessionId=${userInfo['sessionId']}`
         } else {
            this.$router.push('/home')
         }
      }
   }
}
</script>
