<template>
   <div class="main-container">
      <div class="nav-bar nav-bar--full">
         <div class="nav-bar__inner">
            <span class="nav-bar__icon"><i class="icon-fonts icon-fonts--arrow-left" @click="goBack"></i></span>
            <h3 class="nav-bar__title">{{ $t('home.categories.customer_service') }}</h3>
            <a class="nav-bar__images" href="#/ucare/customer-service/intro"
               ><img src="@/assets/images/ic-u-care.svg" alt="img"
            /></a>
         </div>
      </div>
      <div class="box-service" v-if="isAuthenticate">
         <div class="box-service__inner">
            <!-- <div class="box-service__top">
               <h4 class="box-service__sub">{{ $t('ucare.customer_service.welcome_member') }}</h4>
               <a class="box-service__link" href="#/ucare/member-info"
                  ><i class="icon-fonts icon-fonts--alert-circle"></i
               ></a>
            </div> -->
            <ul class="box-service__list">
               <li class="box-service__item">
                  <h5 class="box-service__name">{{ $t('ucare.customer_service.ranking_point') }}</h5>
                  <span class="box-service__value">{{
                     Number(rankPoint).toLocaleString('en-US', { maximumFractionDigits: 0 })
                  }}</span>
               </li>
               <li class="box-service__item">
                  <h5 class="box-service__name">{{ $t('ucare.exchange_point') }}</h5>
                  <span class="box-service__value">{{
                     Number(exchangePoint).toLocaleString('en-US', { maximumFractionDigits: 0 })
                  }}</span>
               </li>
               <li class="box-service__item">
                  <h5 class="box-service__name">{{ $t('ucare.customer_service.expired_data') }}</h5>
                  <span class="box-service__value">{{ expiredDate }}</span>
               </li>
            </ul>
         </div>
      </div>
      <div class="acc-auth" v-if="!isAuthenticate">
         <div class="box-auth">
            <div class="box-auth__logo">
               <a class="box-auth__logo-link" href="javascript:void(0)"
                  ><img src="@/assets/images/logo.svg" alt="logo"
               /></a>
            </div>
            <div class="box-auth__2btn">
               <button class="button button--normal" @click="signUp">{{ $t('buttons.signup') }}</button
               ><button class="button button--primary" @click="openSignInDialog">
                  {{ $t('buttons.signin') }}
               </button>
            </div>
         </div>
      </div>
      <div class="main-content">
         <div class="customer-service">
            <div class="customer-service__row">
               <div class="feature-favourite">
                  <ul class="feature-favourite__list">
                     <li class="feature-favourite__item" v-for="service in firstServices" :key="service.name">
                        <a
                           class="feature-favourite__link"
                           href="javascript:void(0)"
                           @click="onClickService(service)"
                           v-if="!service.openViewModal"
                           ><span class="feature-favourite__icon"><img :src="service.icon" alt="img" /></span>
                           <h5 class="feature-favourite__name">{{ service.name }}</h5>
                        </a>
                        <a
                           class="feature-favourite__link"
                           href="javascript:void(0)"
                           @click="setViewModal(service.name, service.url)"
                           v-if="service.openViewModal"
                           ><span class="feature-favourite__icon"><img :src="service.icon" alt="img" /></span>
                           <h5 class="feature-favourite__name">{{ service.name }}</h5>
                        </a>
                     </li>
                  </ul>
               </div>
            </div>
            <!-- <div class="customer-service__row">
               <div class="feature-favourite">
                  <ul class="feature-favourite__list">
                     <li class="feature-favourite__item" v-for="service in secondServices" :key="service.name">
                        <a class="feature-favourite__link" :href="service.href" @click="onClickService(service)" v-if="!service.openViewModal"
                           ><span class="feature-favourite__icon"><img :src="service.icon" alt="img" /></span>
                           <h5 class="feature-favourite__name">{{ service.name }}</h5>
                        </a>
                        <a class="feature-favourite__link" href="javascript:void(0)" @click="setViewModal(service.name, service.url)" v-if="service.openViewModal"
                           ><span class="feature-favourite__icon"><img :src="service.icon" alt="img" /></span>
                           <h5 class="feature-favourite__name">{{ service.name }}</h5>
                        </a>
                     </li>
                  </ul>
               </div>
            </div>
            <div class="customer-service__row">
               <div class="feature-favourite">
                  <ul class="feature-favourite__list">
                     <li class="feature-favourite__item" v-for="service in thirdServices" :key="service.name">
                        <a class="feature-favourite__link" href="javascript:void(0)" @click="onClickService(service)" v-if="!service.openViewModal"
                           ><span class="feature-favourite__icon"><img :src="service.icon" alt="img" /></span>
                           <h5 class="feature-favourite__name">{{ service.name }}</h5>
                        </a>
                        <a class="feature-favourite__link" href="javascript:void(0)" @click="setViewModal(service.name, service.url)" v-if="service.openViewModal"
                           ><span class="feature-favourite__icon"><img :src="service.icon" alt="img" /></span>
                           <h5 class="feature-favourite__name">{{ service.name }}</h5>
                        </a>
                     </li>
                  </ul>
               </div>
            </div> -->
            <div class="customer-service__row">
               <div class="member-infor">
                  <div class="member-proccess">
                     <h4 class="member-infor__sub">
                        {{ $t('ucare.member_info.content') }} {{ cardName }} {{ $t('ucare.member_info.member') }}
                     </h4>
                     <div class="member-proccess__info">
                        <span class="member-proccess__value"
                           >{{ Number(maxPoint).toLocaleString('en-US', { maximumFractionDigits: 0 }) }}
                           {{ $t('unit.upoint_low') }}</span
                        >
                     </div>
                     <!--welcome-->
                     <div class="member-proccess__detail" v-if="rankName == 'Loyal'">
                        <span class="member-proccess__icon"
                           ><img src="@/assets/images/ic-welcome-small.svg" alt="img"
                        /></span>
                        <div class="member-proccess__range">
                           <p class="member-proccess__percent" :style="typePoint">
                              <span class="member-proccess__data"
                                 >{{ Number(pointValue).toLocaleString('en-US', { maximumFractionDigits: 0 }) }}
                                 {{ $t('unit.upoint_low') }}</span
                              >
                           </p>
                        </div>
                        <span class="member-proccess__icon"
                           ><img src="@/assets/images/ic-bronze-small.svg" alt="img"
                        /></span>
                     </div>
                     <!--bronze-->
                     <div class="member-proccess__detail" v-if="rankName == 'Titan'">
                        <span class="member-proccess__icon"
                           ><img src="@/assets/images/ic-bronze-small.svg" alt="img"
                        /></span>
                        <div class="member-proccess__range">
                           <p class="member-proccess__percent" :style="typePoint">
                              <span class="member-proccess__data"
                                 >{{ Number(pointValue).toLocaleString('en-US', { maximumFractionDigits: 0 }) }}
                                 {{ $t('unit.upoint_low') }}</span
                              >
                           </p>
                        </div>
                        <span class="member-proccess__icon"
                           ><img src="@/assets/images/ic-sliver-small.svg" alt="img"
                        /></span>
                     </div>
                     <!--sliver-->
                     <div class="member-proccess__detail" v-if="rankName == 'Silver'">
                        <span class="member-proccess__icon"
                           ><img src="@/assets/images/ic-sliver-small.svg" alt="img"
                        /></span>
                        <div class="member-proccess__range">
                           <p class="member-proccess__percent" :style="typePoint">
                              <span class="member-proccess__data"
                                 >{{ Number(pointValue).toLocaleString('en-US', { maximumFractionDigits: 0 }) }}
                                 {{ $t('unit.upoint_low') }}</span
                              >
                           </p>
                        </div>
                        <span class="member-proccess__icon"
                           ><img src="@/assets/images/ic-gold-small.svg" alt="img"
                        /></span>
                     </div>
                     <!--sliver-->
                     <div class="member-proccess__detail" v-if="rankName == 'Gold'">
                        <span class="member-proccess__icon"
                           ><img src="@/assets/images/ic-gold-small.svg" alt="img"
                        /></span>
                        <div class="member-proccess__range">
                           <p class="member-proccess__percent" :style="typePoint">
                              <span class="member-proccess__data"
                                 >{{ Number(pointValue).toLocaleString('en-US', { maximumFractionDigits: 0 }) }}
                                 {{ $t('unit.upoint_low') }}</span
                              >
                           </p>
                        </div>
                        <span class="member-proccess__icon"
                           ><img src="@/assets/images/ic-platinum-small.svg" alt="img"
                        /></span>
                     </div>
                     <!--platinum-->
                     <div class="member-proccess__detail" v-if="rankName == 'Diamond'">
                        <span class="member-proccess__icon"
                           ><img src="@/assets/images/ic-platinum-small.svg" alt="img"
                        /></span>
                        <div class="member-proccess__range">
                           <p class="member-proccess__percent" :style="typePoint">
                              <span class="member-proccess__data"
                                 >{{ Number(pointValue).toLocaleString('en-US', { maximumFractionDigits: 0 }) }}
                                 {{ $t('unit.upoint_low') }}</span
                              >
                           </p>
                        </div>
                     </div>
                  </div>
                  <div class="member-level">
                     <h4 class="member-infor__sub">{{ $t('ucare.member_info.level_up') }}</h4>
                     <ul class="member-level__list">
                        <li class="member-level__item">
                           <span class="member-level__icon"><i class="icon-fonts icon-fonts--star"></i></span>
                           <div class="member-level__info">
                              <span class="member-level__name">{{ $t('ucare.member_info.gain') }}</span
                              ><span class="member-level__value"
                                 >{{ Number(gain).toLocaleString('en-US', { maximumFractionDigits: 0 }) }}
                                 {{ $t('unit.upoint_low') }}</span
                              >
                           </div>
                        </li>
                        <li class="member-level__item">
                           <span class="member-level__icon"><i class="icon-fonts icon-fonts--clock-2"></i></span>
                           <div class="member-level__info">
                              <span class="member-level__name">{{ $t('ucare.member_info.before') }}</span
                              ><span class="member-level__value">{{ pointExpireDate }}</span>
                           </div>
                        </li>
                     </ul>
                  </div>
                  <div class="ranking-member">
                     <ul class="ranking-member__list">
                        <li :class="classW">
                           <span class="ranking-member__icon"
                              ><img src="@/assets/images/ic-welcome.svg" alt="img"
                           /></span>
                           <div class="ranking-member__info">
                              <h5 class="ranking-member__name">
                                 <a class="ranking-member__name-link" href="javascript:void(0)">{{
                                    $t('ucare.member_info.welcome')
                                 }}</a>
                              </h5>
                           </div>
                        </li>
                        <li :class="classB">
                           <span class="ranking-member__icon"
                              ><img src="@/assets/images/ic-bronze.svg" alt="img"
                           /></span>
                           <div class="ranking-member__info">
                              <h5 class="ranking-member__name">
                                 <a class="ranking-member__name-link" href="javascript:void(0)">{{
                                    $t('ucare.member_info.bronze')
                                 }}</a>
                              </h5>
                           </div>
                        </li>
                        <li :class="classS">
                           <span class="ranking-member__icon"
                              ><img src="@/assets/images/ic-sliver.svg" alt="img"
                           /></span>
                           <div class="ranking-member__info">
                              <h5 class="ranking-member__name">
                                 <a class="ranking-member__name-link" href="javascript:void(0)">{{
                                    $t('ucare.member_info.sliver')
                                 }}</a>
                              </h5>
                           </div>
                        </li>
                        <li :class="classG">
                           <span class="ranking-member__icon"><img src="@/assets/images/ic-gold.svg" alt="img" /></span>
                           <div class="ranking-member__info">
                              <h5 class="ranking-member__name">
                                 <a class="ranking-member__name-link" href="javascript:void(0)">{{
                                    $t('ucare.member_info.gold')
                                 }}</a>
                              </h5>
                           </div>
                        </li>
                        <li :class="classP">
                           <span class="ranking-member__icon"
                              ><img src="@/assets/images/ic-platinum.svg" alt="img"
                           /></span>
                           <div class="ranking-member__info">
                              <h5 class="ranking-member__name">
                                 <a class="ranking-member__name-link" href="javascript:void(0)">{{
                                    $t('ucare.member_info.platinum')
                                 }}</a>
                              </h5>
                           </div>
                        </li>
                     </ul>
                  </div>
                  <div class="member-benefits">
                     <h4 class="member-infor__sub">{{ $t('ucare.member_info.benefits_of') }} {{ cardName }}</h4>
                     <div class="member-benefits__content">
                        <p style="white-space: pre-wrap">{{ description }}</p>
                     </div>
                  </div>
                  <div class="member-exchange">
                     <div class="tabs-list">
                        <div class="nav tabs-list__nav" role="tablist">
                           <a class="tabs-list__link active" href="#add-point" role="tab" data-bs-toggle="tab">
                              {{ $t('ucare.point_history.add_points') }}</a
                           ><a class="tabs-list__link" href="#exchange-point" role="tab" data-bs-toggle="tab">
                              {{ $t('ucare.point_history.exchange_point') }}</a
                           >
                           <!-- <a class="tabs-list__link" href="#exchange-gift" role="tab" data-bs-toggle="tab">{{
                              $t('ucare.member_info.history_exchange_gift')
                           }}</a>
                           <a class="tabs-list__link" href="#exchange-service" role="tab" data-bs-toggle="tab">{{
                              $t('ucare.member_info.history_exchange_service')
                           }}</a> -->
                        </div>
                        <div class="tabs-list__content tab-content">
                           <div class="tab-pane fade show active" id="add-point" role="tabpanel">
                              <div class="point-history__content">
                                 <ul class="point-history__list">
                                    <li class="point-history__item" v-for="item in listAddPoints" :key="item.hisId">
                                       <div class="point-history__images">
                                          <img src="@/assets/images/img-point-history.svg" alt="img" />
                                       </div>
                                       <div class="point-history__detail">
                                          <h5 class="point-history__name">
                                             <a class="point-history__name-link" href="javascript:void(0)">{{
                                                item.transferTypeName
                                             }}</a>
                                          </h5>
                                          <div class="point-history__info">
                                             <span class="point-history__time">{{
                                                item.createDate.substring(0, 10).split('-').reverse().join('/')
                                             }}</span
                                             ><span class="point-history__value add"
                                                >+{{
                                                   Number(item.amount).toLocaleString('en-US', {
                                                      maximumFractionDigits: 0
                                                   })
                                                }}
                                                {{ $t('unit.upoint') }}</span
                                             >
                                          </div>
                                       </div>
                                    </li>
                                 </ul>
                              </div>
                           </div>
                           <div class="tab-pane fade" id="exchange-point" role="tabpanel">
                              <div class="point-history__content">
                                 <ul class="point-history__list">
                                    <li
                                       class="point-history__item"
                                       v-for="item in listExchangePoints"
                                       :key="item.hisId"
                                    >
                                       <div class="point-history__images">
                                          <img src="@/assets/images/img-point-history.svg" alt="img" />
                                       </div>
                                       <div class="point-history__detail">
                                          <h5 class="point-history__name">
                                             <a class="point-history__name-link" href="javascript:void(0)">{{
                                                item.transferTypeName
                                             }}</a>
                                          </h5>
                                          <div class="point-history__info">
                                             <span class="point-history__time">{{
                                                item.createDate.substring(0, 10).split('-').reverse().join('/')
                                             }}</span
                                             ><span class="point-history__value exchange"
                                                >{{
                                                   Number(item.amount).toLocaleString('en-US', {
                                                      maximumFractionDigits: 0
                                                   })
                                                }}
                                                {{ $t('unit.upoint') }}</span
                                             >
                                          </div>
                                       </div>
                                    </li>
                                 </ul>
                              </div>
                           </div>
                           <!-- <div class="tab-pane fade" id="exchange-gift" role="tabpanel">
                              <div class="member-exchange__content">
                                 <ul class="member-exchange__list">
                                    <li
                                       class="member-exchange__item"
                                       v-for="(gift, index) in exchangeGiftHistory"
                                       :key="index"
                                       @click="selectGift(gift)"
                                       data-bs-toggle="modal"
                                       data-bs-target="#exchange"
                                    >
                                       <div class="member-exchange__images"><img :src="gift.imageUrl" alt="img" /></div>
                                       <div class="member-exchange__detail">
                                          <h5 class="member-exchange__name">
                                             <a class="member-exchange__name-link" href="javascript:void(0)">{{
                                                gift.giftName
                                             }}</a>
                                          </h5>
                                          <p class="member-exchange__des">
                                             {{ $t('ucare.member_info.redeem_upoint') }}:
                                             {{
                                                Number(gift.redeemPoint).toLocaleString('en-US', {
                                                   maximumFractionDigits: 0
                                                })
                                             }}
                                             {{ $t('unit.upoint') }}
                                          </p>
                                          <p class="member-exchange__des">
                                             {{ $t('ucare.member_info.exchange_date') }}: {{ gift.exchangDate }}
                                          </p>
                                       </div>
                                    </li>
                                 </ul>
                              </div>
                           </div>
                           <div class="tab-pane fade" id="exchange-service" role="tabpanel">
                              <div class="member-exchange__content">
                                 <ul class="member-exchange__list">
                                    <li
                                       class="member-exchange__item"
                                       v-for="(gift, index) in exchangeServiceHistory"
                                       :key="index"
                                    >
                                       <div class="member-exchange__images">
                                          <img :src="'data:image/jpeg;base64,' + gift.imageGift" alt="img" />
                                       </div>
                                       <div class="member-exchange__detail">
                                          <h5 class="member-exchange__name">
                                             <a class="member-exchange__name-link" href="javascript:void(0)">{{
                                                gift.giftName
                                             }}</a>
                                          </h5>
                                          <p class="member-exchange__des">
                                             {{ $t('ucare.member_info.redeem_upoint') }}:
                                             {{
                                                Number(gift.redeemPoint).toLocaleString('en-US', {
                                                   maximumFractionDigits: 0
                                                })
                                             }}
                                             {{ $t('unit.upoint') }}
                                          </p>
                                          <p class="member-exchange__des">
                                             {{ $t('ucare.member_info.exchange_date') }}: {{ gift.exchangeDate }}
                                          </p>
                                       </div>
                                    </li>
                                 </ul>
                              </div>
                           </div> -->
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
      <!-- Modal -->
      <div class="modal fade modal-custom" id="exchange" tabindex="-1" aria-labelledby="exchange" style="display: none;" aria-hidden="true">
         <div class="modal-dialog" role="document">
            <div class="modal-custom__content">
               <div class="modal-custom__header"><span class="modal-custom__action" data-bs-dismiss="modal"><i class="icon-fonts icon-fonts--close"></i></span></div>
               <div class="modal-custom__body">
               <h3 class="modal-custom__title">{{$t("ucare.member_info.expired_date")}}: {{selectedGift.expireDate}}</h3>
               <div class="modal-custom__code">{{selectedGift.exchangeCode}}</div>
               <div class="modal-custom__images"><img :src="'data:image/jpeg;base64,' + selectedGift.giftQrCode"></div>
               <p class="modal-custom__des">{{$t("ucare.member_info.qr_message")}}</p>
               <p class="modal-custom__highlight">{{selectedGift.giftName}}</p>
               </div>
            </div>
         </div>
      </div>
      <button data-bs-toggle="modal" data-bs-target="#signIn" id="signinBtn" style="display: none" />
      <div class="modal fade" data-backdrop="static" id="signIn" tabindex="-1" aria-labelledby="signInLabel">
         <div class="modal-dialog">
            <div class="modal-content">
               <div class="modal-header">
                  <h5 class="modal-title text-center" id="signInLabel">Sign In</h5>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
               </div>
               <div class="modal-body">
                  <div class="mb-3">
                     <label for="username" class="form-label">User name</label>
                     <input type="number" class="form-control" id="username" v-model="loginData.username" />
                  </div>
                  <div class="mb-3">
                     <label for="password" class="form-label">Password</label>
                     <input type="password" class="form-control" id="password" rows="3" v-model="loginData.password" />
                  </div>
               </div>
               <div class="modal-footer">
                  <button
                     type="button"
                     class="btn btn-primary"
                     data-bs-dismiss="modal"
                     :disabled="!hasValidData"
                     @click="signIn"
                  >
                     Login
                  </button>
               </div>
            </div>
         </div>
      </div>
      <view-modal :data="view" />
   </div>
</template>
<script>
import BaseComponent from '@/mixins/BaseComponent'
import service from './../../services/UCareService'
import loginService from '@/modules/home/services/HomeService'
import LocalStorage from '@/ultils/local-storage'
import { UCareServiceList, Area } from './../../constants/data'
import $ from 'jquery'
import { callNative } from '@/ultils/call-native'
import ViewModal from '@/components/modal/ViewModal'

export default {
   name: 'CustomerService',
   mixins: [BaseComponent],
   components: {
      ViewModal
   },
   data() {
      return {
         isAuthenticate: false,
         listServices: UCareServiceList,
         firstServices: [],
         secondServices: [],
         thirdServices: [],
         rankPoint: 0,
         exchangePoint: 0,
         expiredDate: '',
         loginData: {
            username: '',
            password: ''
         },
         selectedService: null,
         showIcon: true,
         view: {
            name: '',
            url: ''
         },
         rankName: '',
         cardClass: 'member-card member-card--welcome',
         name: '',
         phoneNumber: '',
         cardName: '',
         expCard: '00/00',
         minPoint: 0,
         maxPoint: 1000,
         pointValue: 845,
         typePoint: '',
         gain: 0,
         classW: 'ranking-member__item',
         classB: 'ranking-member__item',
         classS: 'ranking-member__item',
         classG: 'ranking-member__item',
         classP: 'ranking-member__item',
         description: '',
         pointExpireDate: '',
         exchangeGiftHistory: [],
         exchangeServiceHistory: [],
         selectedGift: {},
         listAddPoints: [],
         listExchangePoints: [],
         accountRankInfo: {},
         rankDefineInfo: [],
         accountPointInfo: []
      }
   },
   created() {
      this.init()
   },
   methods: {
      init() {
         let appCode = LocalStorage.getItem('appCode')
         let ucareFtth = LocalStorage.getItem('click_ucare_ftth')
         if (appCode === 'LaoApp' || ucareFtth) {
            this.secondServices = this.listServices.filter((s) => {
               return s.area === Area.Second && s.laoApp
            })
            this.thirdServices = this.listServices.filter((s) => {
               return s.area === Area.Third && s.laoApp
            })
         } else {
            this.secondServices = this.listServices.filter((s) => s.area === Area.Second)
            this.thirdServices = this.listServices.filter((s) => s.area === Area.Third)
         }
         this.setTitle(this.$i18n.t('home.categories.customer_service'))
         this.firstServices = this.listServices.filter((s) => s.area === Area.First)
         let userInfo = JSON.parse(LocalStorage.getItem('user_info'))
         let ftthUserInfo = JSON.parse(LocalStorage.getItem('ftth_user_info'))
         if (userInfo || ftthUserInfo) {
            this.isAuthenticate = true
            this.loadData()
            this.getDataMemberInfo()
            this.loadDataPointHistory()
         }
      },
      loadData() {
         let getAccountPointInfo = service.getAccountPointInfo().then((response) => {
            if (response.code === '000') {
               let result = response.listAccountPoint ?? []
               this.rankPoint = result.reduce((total, acc) => {
                  if (acc.pointType === 1) {
                     return total + acc.pointValue
                  } else {
                     return total
                  }
               }, 0)

               this.exchangePoint = result.reduce((total, acc) => {
                  if (acc.pointType === 2) {
                     return total + acc.pointValue
                  } else {
                     return total
                  }
               }, 0)
            } else {
               // this.$store.dispatch('setNotification', {
               //    content: response.message
               // })
            }
         })

         let getAccountRankInfo = service.getAccountRankInfo().then((response) => {
            if (response.code === '000') {
               let result = response.accountRankDTO
               if (result.rankName === 'Loyal') {
                  this.expiredDate = this.$i18n.t('ucare.member_info.unlimited')
               } else {
                  this.expiredDate = result.endDate.split('-').reverse().join('/')
               }
            } else {
               // if (response.code !== '005') {
               //    this.$store.dispatch('setNotification', {
               //       content: response.message
               //    })
               // }
            }
         })

         this.showLoading()
         Promise.allSettled([getAccountPointInfo, getAccountRankInfo]).finally(() => {
            this.hideLoading()
         })
      },
      getDataMemberInfo() {
         //Lấy rank của thẻ
         const getAccountRankInfo = service.getAccountRankInfo().then((response) => {
            if (response.accountRankDTO) {
               this.rankName = response.accountRankDTO.rankName
               this.pointExpireDate = response.accountRankDTO.pointExpireDate
               let classItem = 'ranking-member__item'
               let classCurrent = 'ranking-member__item current'
               let classDone = 'ranking-member__item done'
               if (this.rankName == 'Loyal') {
                  this.cardClass = 'member-card member-card--welcome'
                  this.cardName = 'Welcome'
                  this.classW = classCurrent
                  this.classB = classItem
                  this.classS = classItem
                  this.classG = classItem
                  this.classP = classItem
               }
               if (this.rankName == 'Titan') {
                  this.cardClass = 'member-card member-card--bronze'
                  this.cardName = 'Bronze'
                  this.classW = classDone
                  this.classB = classCurrent
                  this.classS = classItem
                  this.classG = classItem
                  this.classP = classItem
               }
               if (this.rankName == 'Silver') {
                  this.cardClass = 'member-card member-card--sliver'
                  this.cardName = 'Silver'
                  this.classW = classDone
                  this.classB = classDone
                  this.classS = classCurrent
                  this.classG = classItem
                  this.classP = classItem
               }
               if (this.rankName == 'Gold') {
                  this.cardClass = 'member-card member-card--gold'
                  this.cardName = 'Gold'
                  this.classW = classDone
                  this.classB = classDone
                  this.classS = classDone
                  this.classG = classCurrent
                  this.classP = classItem
               }
               if (this.rankName == 'Diamond') {
                  this.cardClass = 'member-card member-card--vip'
                  this.cardName = 'Vip'
                  this.classW = classDone
                  this.classB = classDone
                  this.classS = classDone
                  this.classG = classDone
                  this.classP = classCurrent
               }
               if (response.accountRankDTO.endDate) {
                  if (response.accountRankDTO.rankName === 'Loyal') {
                     this.expCard = this.$i18n.t('ucare.member_info.unlimited')
                  } else {
                     let date = new Date(response.accountRankDTO.endDate)
                     this.expCard =
                        (date.getMonth() > 8 ? date.getMonth() + 1 : '0' + (date.getMonth() + 1)) +
                        '/' +
                        date.getFullYear()
                  }
               }
            }

            //Lấy thông tin điểm thẻ
            service.getAccountPointInfo().then((response) => {
               if (response.listAccountPoint) {
                  //this.name = response.listAccountPoint.name;
                  this.pointValue = 0
                  for (var i = 0; i < response.listAccountPoint.length; i++) {
                     if (response.listAccountPoint[i].pointType == 1) {
                        this.pointValue = this.pointValue + response.listAccountPoint[i].pointValue
                     }
                  }

                  //Lấy thông tin tăng điểm thẻ
                  service.getRankDefineInfo().then((response) => {
                     if (response.listRankDefine) {
                        var rankDefine = response.listRankDefine.filter((x) => x.rankName == this.rankName)
                        if (rankDefine.length > 0) {
                           this.minPoint = rankDefine[0].minPoint
                           this.maxPoint = rankDefine[0].maxPoint
                           let width = Math.round(
                              ((this.pointValue - this.minPoint) * 100) / (this.maxPoint - this.minPoint)
                           )
                           this.typePoint = 'width: ' + width + '%'

                           this.gain = rankDefine[0].maxPoint - this.pointValue
                           this.description = rankDefine[0].description
                        }
                     }
                  })
               }
            })
         })

         //Lấy thông tin chủ thẻ
         const getSubMainInfo = service.getSubMainInfo().then((response) => {
            if (response.wsResponse) {
               this.name = response.wsResponse.name
            }
         })

         const getPartnerGiftRedeemHistory = service
            .getPartnerGiftRedeemHistory({ page: 1, limit: 1000, offset: 0 })
            .then((response) => {
               if (response.wsResponse.object && response.wsResponse.object.length > 0) {
                  this.exchangeGiftHistory = response.wsResponse.object
               }
            })

         const getTelecomRedeemHistory = service
            .getTelecomRedeemHistory({ page: 1, limit: 1000, offset: 0 })
            .then((response) => {
               if (response.listPointTransferHistoryDetail && response.listPointTransferHistoryDetail.length > 0) {
                  this.exchangeServiceHistory = response.listPointTransferHistoryDetail
               }
            })

         this.showLoading()
         Promise.allSettled([
            getAccountRankInfo,
            getSubMainInfo,
            getPartnerGiftRedeemHistory,
            getTelecomRedeemHistory
         ]).finally(() => {
            this.hideLoading()
         })
      },
      loadDataPointHistory() {
         let getListAddPoints = service.getPointTransferHistory({ type: 1, pointId: 1000001 }).then((response) => {
            this.listAddPoints = response.listPointTransferHistory ?? []
         })

         let getListExchangePoints = service.getPointTransferHistory({ type: 2, pointId: 1000001 }).then((response) => {
            this.listExchangePoints = response.listPointTransferHistory ?? []
         })

         let getAccountRankInfo = service.getAccountRankInfo().then((response) => {
            this.accountRankInfo = response.accountRankDTO ?? {}
         })

         let getRankDefineInfo = service.getRankDefineInfo().then((response) => {
            this.rankDefineInfo = response.listRankDefine ?? []
         })

         let getAccountPointInfo = service.getAccountPointInfo().then((response) => {
            this.accountPointInfo = response.listAccountPoint ?? []
         })

         this.showLoading()
         Promise.allSettled([
            getListAddPoints,
            getListExchangePoints,
            getAccountRankInfo,
            getRankDefineInfo,
            getAccountPointInfo
         ]).finally(() => {
            this.hideLoading()
         })
      },
      openSignInDialog() {
         callNative('login', '')
         $('#signinBtn').trigger('click')
         this.selectedService = null
         this.loginData = { username: '', password: '' }
      },
      hasValidData() {
         return this.loginData.username && this.loginData.password
      },
      signIn() {
         this.showLoading()
         loginService
            .login(this.loginData)
            .then((response) => {
               this.hideLoading()
               if (!response.errorMessage) {
                  let userInfo = {
                     token: response.token,
                     isdn: response.username,
                     sessionId: response.sessionId
                  }
                  LocalStorage.setItem('user_info', JSON.stringify(userInfo))
                  this.isAuthenticate = true
                  if (this.selectedService) {
                     this.$router.push(this.selectedService.url)
                  } else {
                     this.loadData()
                  }
               }
            })
            .catch(() => {
               this.hideLoading()
            })
      },
      signUp() {
         callNative('sign_up', '')
      },
      onClickService(service) {
         if (this.isAuthenticate) {
            if (service.url !== 'javascript:void(0)') {
               if (!service.url.includes('http')) {
                  this.$router.push(service.url)
               } else {
                  window.location.href = service.url
               }
            }
         } else {
            this.selectedService = service
            if (service.requireAuth) {
               callNative('login', '')
               this.loginData = { username: '', password: '' }
               $('#signinBtn').trigger('click')
            } else {
               window.location.href = service.url
            }
         }
      },
      setViewModal(name, url) {
         this.view = { name, url }
      },
      selectGift(gift){
         this.selectedGift = gift
      },
   }
}
</script>
