<template>
    <div>
        <div class="main-container" v-show="showMain">
            <div class="nav-bar js-nav-bar">
                <div class="nav-bar__inner">
                    <span class="nav-bar__icon" @click="goBack()"><i class="icon-fonts icon-fonts--arrow-left"></i></span>
                    <h3 class="nav-bar__title">{{$t('home.registration_info.title')}}</h3>
                </div>
            </div>
            <div class="main-content">
                <ValidationObserver v-slot="{ passes }">
                    <form @submit.prevent="passes(onRegister)">
                        <div class="register-info">
                            <div class="register-user">
                                <div class="register-user__avatar"><img :src="data.avatar_url" alt="img"></div>
                                <div class="register-user__info">
                                    <div class="register-user__top">
                                        <h5 class="register-user__name"><a class="register-user__name-link" href="#">{{data.name}}</a></h5><span class="register-user__action"><i class="icon-fonts icon-fonts--edit"></i></span>
                                    </div>
                                    <p class="register-user__id">{{data.phone}}</p>
                                </div>
                            </div>
                            <div class="register-info__form">
                                <div class="form-customize">
                                    <Input type="number"
                                           :label="$t('home.registration_info.phone_number')"
                                           :placeholder="$t('home.registration_info.enter_phone_number')"
                                           rules="required|phone"
                                           :hasStar="true"
                                           disabled
                                           v-model="data.phoneNumber" />
                                </div>
                                <div class="form-customize">
                                    <Select :label="$t('home.registration_info.customer_type')"
                                            :placeholder="$t('home.registration_info.enter_customer_type')"
                                            rules="required"
                                            :dataSelect="CustomerType"
                                            :hasStar="true"
                                            v-model="data.cusType" />
                                </div>
                                <div class="form-customize">
                                    <Select :label="$t('home.registration_info.document_type')"
                                            :placeholder="$t('home.registration_info.enter_document_type')"
                                            rules="required"
                                            :dataSelect="TypeId"
                                            :hasStar="true"
                                            v-model="idType" />
                                </div>

                                <!--Cho nay la cho input file va camera-->
                                <input type="file" id="cameraUpload" accept="image/*" capture="camera" @change="onCameraChange" hidden />
                                <input id="fileUpload" type="file" accept="image/png, image/jpeg" @change="onFileChange" hidden>
                                <!-------------------------------------------------------------------------------------------------------------->

                                <div class="form-customize">
                                    <div class="register-upload">
                                        <div class="register-upload__top">
                                            <label class="label-title">{{$t('home.registration_info.image_document')}} <span style="color:red">(*)</span></label>

                                            <button class="button button--upload" type="button" @click="chooseCamera('cDoc1')">
                                                <i class="icon-fonts icon-fonts--camera"></i>
                                            </button>

                                            <button class="button button--upload" @click="chooseFiles('fDoc1')" type="button">{{$t('home.registration_info.browse')}}</button>
                                        </div>
                                        <br v-if="data.image1 == './images/idtype.png'" />
                                        <div class="register-upload__action" v-if="data.image1 && data.image1 != './images/idtype.png'" @click="onDeleteImage1"><span class="register-upload__delete"><a href="javascript:;" style="color:red">{{$t('home.registration_info.delete')}}</a></span></div>
                                        <p class="register-upload__noti" v-if="errorImg1">{{$t('home.registration_info.no_image')}}</p>
                                        <div class="register-upload__content"><img :src="data.image1" alt="img" width="345" height=""></div>
                                    </div>
                                </div>
                                <div class="form-customize" v-if="data.idType==6">
                                    <div class="register-upload">
                                        <div class="register-upload__top">
                                            <label class="label-title">{{$t('home.registration_info.image_document')}} <span style="color:red">(*)</span></label>
                                            <button class="button button--upload" type="button" @click="chooseCamera('cDoc2')">
                                                <i class="icon-fonts icon-fonts--camera"></i>
                                            </button>
                                            <button class="button button--upload" @click="chooseFiles('fDoc2')" type="button">{{$t('home.registration_info.browse')}}</button>
                                        </div>
                                        <br v-if="data.image12 == './images/idtype.png'" />
                                        <div class="register-upload__action" v-if="data.image12 && data.image12 != './images/idtype.png'" @click="onDeleteImage2"><span class="register-upload__delete"><a href="javascript:;" style="color:red">{{$t('home.registration_info.delete')}}</a></span></div>
                                        <p class="register-upload__noti" v-if="errorImg2">{{$t('home.registration_info.no_image')}}</p>
                                        <div class="register-upload__content"><img :src="data.image12" alt="img" width="345" height="358"></div>
                                    </div>
                                </div>
                                <div class="form-customize">
                                    <Input type="text"
                                           :label="$t('home.registration_info.document_number')"
                                           :placeholder="$t('home.registration_info.enter_document_number')"
                                           rules="required"
                                           :hasStar="true"
                                           v-model="data.idNo" />
                                </div>
                                <div class="form-customize">
                                    <label class="label-title" for="per-info">
                                        {{$t('home.registration_info.personal_info')}} <span style="color:red">(*)</span>
                                    </label>
                                    <Input type="text"
                                           :label="$t('home.registration_info.full_name')"
                                           :placeholder="$t('home.registration_info.enter_full_name')"
                                           rules="required"
                                           :isLabel="false"
                                           :hasStar="true"
                                           v-model="data.fullName" />
                                </div>
                                <div class="form-customize">
                                    <Select :label="$t('home.registration_info.gender')"
                                            :placeholder="$t('home.registration_info.enter_gender')"
                                            rules="required"
                                            :dataSelect="Genders"
                                            :isLabel="false"
                                            v-model="data.gender" />
                                </div>
                                <div class="form-customize">
                                    <Input type="date"
                                           :label="$t('home.registration_info.birth_day')"
                                           :placeholder="$t('home.registration_info.enter_birth_day')"
                                           rules="required|date"
                                           :isLabel="false"
                                           rightIcon="icon-fonts icon-fonts--calendar"
                                           v-model="data.doB" />
                                </div>
                                <div class="form-customize">
                                    <Input type="text"
                                           :label="$t('home.registration_info.nationality')"
                                           :placeholder="$t('home.registration_info.enter_nationality')"
                                           rules="required"
                                           :hasStar="true"
                                           v-model="data.nationalities" />
                                </div>
                                <div class="form-customize">
                                    <Input type="text"
                                           :label="$t('home.registration_info.province')"
                                           :placeholder="$t('home.registration_info.enter_province')"
                                           rules="required"
                                           :hasStar="true"
                                           v-model="data.provinceName" />
                                </div>
                                <div class="form-customize">
                                    <div class="register-upload">
                                        <div class="register-upload__top">
                                            <label class="label-title">{{$t('home.registration_info.image_customer')}} <span style="color:red">(*)</span></label>
                                            <button class="button button--upload" type="button" @click="chooseCamera('cCus')">
                                                <i class="icon-fonts icon-fonts--camera"></i>
                                            </button>
                                        </div>
                                        <br v-if="data.image2 == './images/image_client1.png'" />
                                        <div class="register-upload__action" v-if="data.image2 && data.image2 != './images/image_client1.png'" @click="onDeleteImage3"><span class="register-upload__delete"><a href="javascript:;" style="color:red">{{$t('home.registration_info.delete')}}</a></span></div>
                                        <p class="register-upload__noti" v-if="errorImg3">{{$t('home.registration_info.no_image_customer')}}</p>
                                        <div class="register-upload__content"><img :src="data.image2" alt="img" width="345" height="250"></div>
                                    </div>
                                </div>
                                <div class="form-customize">
                                    <div class="register-upload">
                                        <label class="label-title">{{$t('home.registration_info.image_number')}} <span style="color:red">(*)</span></label>
                                        <div class="register-upload__images"><img :src="data.image3" alt="img"></div>
                                    </div>
                                </div>
                            </div>
                            <div class="register-info__btn">
                                <button class="button button--primary button--full">
                                    {{isEdit ? $t('home.registration_info.update') : $t('home.registration_info.register')}}
                                </button>
                            </div>
                        </div>
                    </form>
                </ValidationObserver>

            </div>
        </div>

        <!--Man crop-->
        <div class="main-container" v-show="!showMain">
            <div class="nav-bar js-nav-bar">
                <div class="nav-bar__inner">
                    <span class="nav-bar__icon" @click="backMain"><i class="icon-fonts icon-fonts--arrow-left"></i></span>
                    <h3 class="nav-bar__title">{{$t('home.registration_info.title')}}</h3><a @click="rotateImage" class="nav-bar__link" style="margin-right:150px; display: none" href="javascript:;">{{$t('home.registration_info.rotate')}}</a> <a @click="plipImage(2)" class="nav-bar__link" style="margin-right:100px; display: none" href="javascript:;">{{$t('home.registration_info.plipV')}}</a><a @click="plipImage(1)" class="nav-bar__link" style="margin-right:50px; display: none" href="javascript:;">{{$t('home.registration_info.plipH')}}</a> <a @click="getCropImage()" class="nav-bar__link" href="javascript:;">{{$t('home.registration_info.crop')}}</a>
                </div>
            </div>
            <div class="main-content">
                <div class="fullscreenImage">
                    <cropper :transitions="true"
                             :auto-zoom="true"
                             ref="cropper"
                             default-boundaries="fill"
                             class="cropper"
                             image-class="cropper__image"
                             :src="url"></cropper>
                    <!--:stencil-props="{aspectRatio: 12/12}"-->
                </div>
                <!--<button @click="rotateImage">Rotate image</button>
            <button @click="plipImage(1)">Flip image horizontal</button>
            <button @click="plipImage(2)">Flip image vertical</button>
            <button @click="getCropImage">Save</button>-->
            </div>

        </div>

        <button style="display: none" data-bs-toggle="modal" data-bs-target="#exchange" id="methodBtn" />
        <button style="display: none" data-bs-dismiss="modal" data-bs-target="#exchange" id="methodBtnClose" />
        <div class="modal fade modal-custom " id="exchange" tabindex="-1" role="dialog" aria-labelledby="exchange" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-custom__content">
                    <div class="modal-custom__header"><span class="modal-custom__action" data-bs-dismiss="modal" @click="cancelOrc"><i class="icon-fonts icon-fonts--close"></i></span></div>
                    <div class="modal-custom__body">
                        <h3 class="modal-custom__title">{{$t('home.registration_info.select_exchange')}}</h3>
                        <ul class="modal-exchange">
                            <li class="modal-exchange__item" v-for="item in orc" :key="item.id">
                                <div class="radio-custom">
                                    <input class="radio-custom__input" :id="item.id" v-model="itemChooseOrc" name="radio" type="radio" :value="item" />
                                    <label class="radio-custom__label" :for="item.id">
                                        <h5 class="radio-custom__name">{{item.name.value}}</h5>
                                        <p class="radio-custom__date">{{item.dob.value}}</p>
                                    </label>
                                </div>
                            </li>
                        </ul>
                        <p class="box-auth__name">{{$t('home.registration_info.warning_error')}}</p>
                        <div class="modal-custom__btn">
                            <button class="button button--normal button--medium" data-bs-dismiss="modal" @click="cancelOrc">
                                {{$t('home.registration_info.cancel')}}
                            </button>
                            <button class="button button--primary button--medium" @click="chooseOrc">
                                {{$t('home.registration_info.ok')}}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <button style="display: none" data-bs-toggle="modal" data-bs-target="#notificationP" id="notificationPBtn" />
        <div class="modal fade modal-custom"
             id="notificationP"
             tabindex="-1"
             role="dialog"
             aria-labelledby="notificationP"
             aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-custom__content">
                    <div class="modal-custom__header">
                        <span class="modal-custom__action" data-bs-dismiss="modal">
                            <i class="icon-fonts icon-fonts--close"></i>
                        </span>
                    </div>
                    <div class="modal-custom__body">
                        <div class="modal-custom__images"><img src="@/assets/images/ic-warning.svg" alt="img" /></div>
                        <h3 class="modal-custom__title">{{ $t('notification.title') }}</h3>
                        <p class="modal-custom__des">{{content}}</p>
                        <div class="modal-custom__btn modal-custom__btn--full">
                            <button class="button button--primary button--full" data-bs-dismiss="modal" @click="onClose">
                                {{ $t('buttons.close') }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>
<script>
    import BaseComponent from '@/mixins/BaseComponent'
    import service from './../services/HomeService'
    import store from '@/plugins/vuex/store'
    import { ValidationObserver } from 'vee-validate'
    import Input from '@/components/form/Input'
    import { CustomerTypePrepay, CustomerTypePostpaid, TypeId, Genders } from '../constants/data'
    import Select from '@/components/form/Select'
    import LocalStorage from '@/ultils/local-storage'
    import { callNative } from '@/ultils/call-native'
    import { Cropper } from 'vue-advanced-cropper'
    import 'vue-advanced-cropper/dist/style.css';
    import $ from 'jquery'

    import axios from 'axios'
    // dev
    // const host = "https://lao-dev.ekyc.dev";
    // const token_laos = "U2FsdGVkX1+55ChWRlgkR64T+WXrjPEgFYKEUo8VjEW6XjBdEA+Fg4fqEW/hkxawmdiMokLOe3R+ajn0W3Lm74vSPLluhjGY7jF+gES+ssU=";
    // production
    const host = "https://gateway.ekyc.dev";
    const token_laos = "U2FsdGVkX1/EguutIZrRZxrbzFBjisgdBWxJzQDE5RDV/L/KvSz3MvrZK9ng2f5fEC/ZzWrF2tRFppPsFs6Wt16kMAJaKAFE7DoDWFZLcfA=";

    export default {
        name: 'RegistrationInformation',
        mixins: [BaseComponent],
        components: {
            ValidationObserver,
            Input,
            Select,
            Cropper
        },
        data() {
            return {
                data: {
                    standard: 1,
                    address: "vn",
                    Precinct: "",
                    District: "",

                    name: "-",
                    subType: 1,
                    avatar_url: "./images/avatar-1.svg",
                    phone: null,

                    phoneNumber: null,
                    cusType: null,
                    idType: null,
                    idNo: null,
                    fullName: null,
                    gender: null,
                    doB: null,
                    nationalities: null,
                    provinceName: '',

                    image1: "./images/idtype.png",
                    image12: "./images/idtype.png",
                    image2: "./images/image_client1.png",
                    image3: null,

                },
                idType: null,

                orc: [],
                itemChooseOrc: {},

                imageType: null,
                isEdit: true,
                newDate: null,
                url: null,
                CustomerType: CustomerTypePrepay ?? CustomerTypePostpaid,
                TypeId: TypeId,
                Genders: Genders,
                showMain: true,
                accuracy: 75,
                errorImg1: false,
                errorImg2: false,
                errorImg3: false,
                content: "",
            }
        },
        created() {
            this.setTitle(this.$i18n.t('home.registration_info.title'))
            
            this.loadScript('js/select2.js')
            const today = new Date();
            const yyyy = today.getFullYear();
            let mm = today.getMonth() + 1; // Months start at 0!
            let dd = today.getDate();

            if (dd < 10) dd = '0' + dd;
            if (mm < 10) mm = '0' + mm;
            this.data.doB = dd + '/' + mm + '/' + yyyy;
            this.newDate = this.data.doB;
            //Xử lý ảnh
            this.loadScript('js/date-picker.js')
            this.loadScript('js/date-month-picker.js')

            var userInfo = JSON.parse(LocalStorage.getItem('user_info')) ?? {}
            this.data.phone = userInfo['isdn']
            this.data.phoneNumber = userInfo['isdn']


            
            //store.commit('setDataResInfo', this.data)
            this.createImageNumber()
            this.getData()
            // this.getParamConfig()
           
        },
        mounted() {
            this.loadScript('js/select2.js')
            this.loadScript('js/date-picker.js')
            this.loadScript('js/date-month-picker.js')
        },
        watch: {
            idType(val, valOld) {
                if (valOld) {
                    this.data.idType = val;
                    if (this.data.idType == 3) {
                        this.data.image2 = "./images/image_client2.png";
                    } else if (this.data.idType == 6) {
                        this.data.image2 = "./images/image_client3.png";
                    } else {
                        this.data.image2 = "./images/image_client1.png";
                    }

                    this.data.image1 = "./images/idtype.png";
                    this.data.image12 = "./images/idtype.png";

                    this.data.idNo = null;
                    this.data.fullName = null;
                    this.data.gender = null;
                    this.data.doB = this.newDate;
                    this.data.nationalities = null;
                    this.data.provinceName = null;
                } else {
                    this.data.idType = val;
                }
                this.loadScript('js/select2.js')
                this.loadScript('js/date-picker.js')
                this.loadScript('js/date-month-picker.js')
            },

        },
        methods: {
            createImageNumber() {
                var el = document.createElement("canvas");
                el.width = 345, el.height = 142;
                var cnt = el.getContext("2d");

                cnt.fillStyle = "rgba(181, 181, 183, 1)";
                cnt.fillRect(0, 0, 345, 142);

                cnt.fillStyle = "black";
                cnt.shadowColor = "yellow";
                cnt.font = "bold 40pt Arial";
                cnt.textAlign = "center"
                cnt.textBaseline = "center";

                cnt.fillText(this.data.phone, 172, 90);
                this.data.image3 = el.toDataURL();
            },
            backMain() {
                this.showMain = true;
                if (this.imageType == "cDoc1" || this.imageType == "fDoc1") {
                    this.data.image1 =  "./images/idtype.png";
                }
                if (this.imageType == "cDoc2" || this.imageType == "fDoc2") {
                    this.data.image12 = "./images/idtype.png";
                }
                if (this.imageType == "cCus") {
                    this.data.image2 =  "./images/image_client1.png";
                }
                document.getElementById("fileUpload").value = null
                document.getElementById("cameraUpload").value = null
            },
            rotateImage() {
                this.$refs.cropper.rotate(90);
            },
            plipImage(number) {
                if (number == 1) {
                    this.$refs.cropper.flip(true, false);
                } else {
                    this.$refs.cropper.flip(false, true);
                }

            },
            closeCrop() {
                this.$emit('close')
            },
            getCropImage() {
                var urlResult = this.$refs.cropper.getResult().canvas.toDataURL("image/jpeg");
                if (this.imageType == "cDoc1" || this.imageType == "fDoc1") {
                    this.errorImg1 = false;
                    this.data.image1 = urlResult ? urlResult : "./images/idtype.png";
                    this.getInfoAI(this.data.image1)
                }
                if (this.imageType == "cDoc2" || this.imageType == "fDoc2") {
                    this.errorImg2 = false;
                    this.data.image12 = urlResult ? urlResult : "./images/idtype.png";
                    this.getInfoAI(this.data.image12)
                }
                if (this.imageType == "cCus") {
                    this.errorImg3 = false;
                    this.data.image2 = urlResult ? urlResult : "./images/image_client1.png";
                    this.compareFace(this.data.image2)
                }
                document.getElementById("fileUpload").value = null
                document.getElementById("cameraUpload").value = null
                this.showMain = true;

            },
            getParamConfig() {
                var param = {
                    "configParam": "FACING_MATCH_PERCENT"
                }
                service
                    .getParamConfig(param)
                    .then((response) => {
                        if (response.errorCode === '0') {
                            this.accuracy = response.wsResponse.name
                        }

                    })
            },
            onRegister() {
                if (this.data.image1 == null || this.data.image1 == "./images/idtype.png") {
                    this.errorImg1 = true;
                }
                if (this.data.idType == 6 && (this.data.image12 == null || this.data.image12 == "./images/idtype.png")) {
                    this.errorImg2 = true;
                }

                if (this.data.image2 == null || this.data.image2 == "./images/image_client1.png" || this.data.image2 == "./images/image_client2.png" || this.data.image2 == "./images/image_client3.png") {
                    this.errorImg3 = true;
                }
                if (this.errorImg1 || this.errorImg2 || this.errorImg3) {
                    return
                }

                if (this.data.image12 == null || this.data.image12 == "./images/idtype.png") {
                    this.data.image12 = "no image"
                }
                this.showLoading()
                this.data.Nationalities = this.data.nationalities;

                let param = {
                    "standard": "1",
                    "image3": this.data.image3.replace("data:image/jpeg;base64,", "").replace("data:image/png;base64,", ""),
                    "idType": this.data.idType,
                    "address": this.data.address,
                    "gender": this.data.gender,
                    "fullName": this.data.fullName,
                    "image1": this.data.image1.replace("data:image/jpeg;base64,", "").replace("data:image/png;base64,", ""),
                    "idNo": this.data.idNo,
                    "image2": this.data.image2.replace("data:image/jpeg;base64,", "").replace("data:image/png;base64,", ""),
                    "provinceName": this.data.provinceName,
                    "Province": this.data.provinceName,
                    "Precinct": "001",
                    "Nationalities": this.data.Nationalities,
                    "doB": this.data.doB,
                    "cusType": this.data.cusType,
                    "District": "01"
                }
                service
                    .getRegInfoSub(param)
                    .then((response) => {
                        if (response.errorCode === '0') {
                            // store.dispatch('setNotification', {
                            //     title: this.$i18n.t('home.registration_info.register'),
                            //     content: this.$i18n.t('home.registration_info.request_was_receive'),
                            //     icon: './images/ic-success.svg',
                            //     closeButton: this.$i18n.t('buttons.close')
                            // })
                            this.content = this.$i18n.t('home.registration_info.request_was_receive')
                            this.data.name = this.data.fullName;
                            $('#notificationPBtn').trigger('click')
                        } else {
                            // this.content = response.userMsg
                            // this.data.name = this.data.fullName;
                            // $('#notificationPBtn').trigger('click')
                            store.dispatch('setNotification', {
                               title: this.$i18n.t('home.registration_info.register'),
                               content: response.userMsg,
                               closeButton: this.$i18n.t('buttons.close')
                            })
                        }
                        this.hideLoading()
                    })
                    .finally(() => {
                        this.hideLoading()
                    })
            },
            onClose() {
                callNative('close_webview', '')
                callNative('load_data', '')
            },
            getData() {
                this.showLoading()
                //Lấy thông tin name, avt, subtype
                service
                    .getUserInfo()
                    .then((response) => {
                        if (response.errorCode === '0') {
                            this.data.name = response.wsResponse.name ?? "-"
                            this.data.subType = response.wsResponse.subType
                            this.data.avatar_url = (response.wsResponse.avatar_url && response.wsResponse.avatar_url != " " )  ? response.wsResponse.avatar_url : "./images/avatar-1.svg";
                            if (this.data.subType == 1) {
                                this.CustomerType = CustomerTypePrepay;
                            } else {
                                this.CustomerType = CustomerTypePostpaid;
                            }
                        }

                        //Lấy thông tin thuê bao hiện tại
                        let body = {
                            showErr: false
                        }
                        service
                            .getInfoCust(body)
                            .then((response) => {

                                if (response.errorCode === '0') {
                                    if (response.wsResponse.responseCode == "60") {
                                        this.isEdit = false;
                                    } else {
                                        this.isEdit = true;
                                        this.data.fullName = response.wsResponse.fullName
                                        this.data.name = response.wsResponse.fullName
                                        this.data.gender = response.wsResponse.gender
                                        this.data.nationalities = (response.wsResponse.nationalities == "" || response.wsResponse.nationalities == "null") ? "" : response.wsResponse.nationalities
                                        this.data.provinceName = (response.wsResponse.provinceName == "" || response.wsResponse.provinceName == "null") ? "" : response.wsResponse.provinceName
            
                                        this.data.doB = response.wsResponse.doB
                                        this.data.cusType = response.wsResponse.cusType
                                        this.data.idType = response.wsResponse.idType
                                        this.idType = response.wsResponse.idType
                                        this.data.idNo = response.wsResponse.idNo

                                        this.data.image1 = (response.wsResponse.image1 == "no image" || response.wsResponse.image1 == '') ? "./images/idtype.png" : 'data:image/jpeg;base64,' + response.wsResponse.image1
                                        this.data.image2 = (response.wsResponse.image2 == "no image" || response.wsResponse.image2 == '') ? "./images/image_client1.png" : 'data:image/jpeg;base64,' + response.wsResponse.image2
                                        if (!(response.wsResponse.image3 == "no image" || response.wsResponse.image3 == '')) {
                                            this.data.image3 = 'data:image/jpeg;base64,' + response.wsResponse.image3
                                        }
                                        this.loadScript('js/select2.js')
                                        this.loadScript('js/date-picker.js')
                                        this.loadScript('js/date-month-picker.js')
                                    }


                                } else {
                                    this.isEdit = false;
                                }
                                this.hideLoading()
                            })
                            .finally(() => {
                                this.hideLoading()
                            })
                    })
                    .finally(() => {
                        //this.hideLoading()
                    })
            },
            chooseFiles(type) {
                if (type != "cCus") {
                    if (!this.data.idType) {
                        store.dispatch('setNotification', {
                            title: this.$i18n.t('notification.title'),
                            content: this.$i18n.t('home.registration_info.enter_document_type'),
                            closeButton: this.$i18n.t('buttons.close')
                        })
                        return
                    }
                }

                document.getElementById("fileUpload").click()
                this.imageType = type;
            },
            onFileChange(e) {
                const file = e.target.files[0];
                this.url = URL.createObjectURL(file);
                this.showMain = false;
            },
            chooseCamera(type) {
                if (type == "cCus") {
                    if (this.data.image1 == null || this.data.image1 == "./images/idtype.png" || (this.data.idType == 6 && (this.data.image12 == null || this.data.image12 == "./images/idtype.png"))) {
                        store.dispatch('setNotification', {
                            title: this.$i18n.t('notification.title'),
                            content: this.$i18n.t('home.registration_info.notiContent'),
                            closeButton: this.$i18n.t('buttons.close')
                        })
                        return
                    }
                } else {
                    if (!this.data.idType) {
                        store.dispatch('setNotification', {
                            title: this.$i18n.t('notification.title'),
                            content: this.$i18n.t('home.registration_info.enter_document_type'),
                            closeButton: this.$i18n.t('buttons.close')
                        })
                        return
                    }
                }
                document.getElementById("cameraUpload").click()
                this.imageType = type;
            },
            onCameraChange(e) {
                const file = e.target.files[0];
                this.url = URL.createObjectURL(file);
                this.showMain = false;
            },
            onChangeIdType() {
                
            },

            async getInfoAI(url) {
                this.showLoading()
                var data = new FormData();
                var response = null;
                var config = {};
                var res = {};
                if (this.data.idType == 1) {
                    if (url != null && url != "./images/idtype.png") {
                        const blob = await fetch(url).then(res => res.blob());
                        data.append('front_image', blob);
                        config = {
                            method: 'post',
                            url: host + '/laos/id-card/ocr',
                            headers: {
                                'Authorization': token_laos
                            },
                            data: data
                        };
                        response = await axios(config)
                            .catch(function () {
                                return { data: { response_code: 400 } };
                            }).finally(() => {
                                this.hideLoading()
                            })
                        if (response.data.response_code == 200) {

                            res = response.data.data;
                            this.data.fullName = res.name.value;
                            this.data.doB = res.birthday.value;
                            this.data.idNo = res.id_number.value;
                            this.data.nationalities = res.nationality.value;
                            this.data.provinceName = res.province.value;
                            this.loadScript('js/date-picker.js')
                            this.loadScript('js/date-month-picker.js')
                        } else {
                            store.dispatch('setNotification', {
                                title: this.$i18n.t('notification.title'),
                                content: this.$i18n.t('home.registration_info.notiImageError'),
                                closeButton: this.$i18n.t('buttons.close')
                            })
                            this.data.image1 = "./images/idtype.png";
                        }

                    }
                }

                if (this.data.idType == 3) {
                    if (url != null && url != "./images/idtype.png") {
                        const blob3 = await fetch(url).then(res => res.blob());
                        data.append('front_image', blob3);
                        config = {
                            method: 'post',
                            url: host + '/laos/passport/ocr',
                            headers: {
                                'Authorization': token_laos
                            },
                            data: data
                        };
                        response = await axios(config)
                            .catch(function () {
                                return { data: { response_code: 400 } };
                            }).finally(() => {
                                this.hideLoading()
                            })
                        if (response.data.response_code == 200) {
                            res = response.data.data;
                            this.data.fullName = res.sur_name.value + " " + res.given_name.value;
                            this.data.doB = res.dob.value.replace(/\s/g, '');
                            this.data.idNo = res.id.value;
                            this.data.nationalities = res.nationality.value;
                            this.data.provinceName = res.place_of_birth.value;
                            this.data.gender = res.gender.value;
                            this.loadScript('js/select2.js')
                            this.loadScript('js/date-picker.js')
                            this.loadScript('js/date-month-picker.js')

                        } else {
                            store.dispatch('setNotification', {
                                title: this.$i18n.t('notification.title'),
                                content: this.$i18n.t('home.registration_info.notiImageError'),
                                closeButton: this.$i18n.t('buttons.close')
                            })
                            this.data.image1 = "./images/idtype.png";
                        }

                    }
                }



                if (this.data.idType == 6) {
                    if (this.imageType == "cDoc1" || this.imageType == "fDoc1") {
                        if (url != null && url != "./images/idtype.png") {
                            const blob61 = await fetch(url).then(res => res.blob());
                            data.append('front_image', blob61);
                            config = {
                                method: 'post',
                                url: host + '/laos/household/ocr',
                                headers: {
                                    'Authorization': token_laos
                                },
                                data: data
                            };
                            response = await axios(config)
                                .catch(function () {
                                    return { data: { response_code: 400 } };
                                }).finally(() => {
                                    this.hideLoading()
                                })
                            if (response.data.response_code == 200) {
                                res = response.data.data;
                                this.data.idNo = res.number.value;
                                this.data.provinceName = res.province.value;

                            } else {
                                store.dispatch('setNotification', {
                                    title: this.$i18n.t('notification.title'),
                                    content: this.$i18n.t('home.registration_info.notiImageError'),
                                    closeButton: this.$i18n.t('buttons.close')
                                })
                                this.data.image1 = "./images/idtype.png";
                            }

                        }
                    }
                    if (this.imageType == "cDoc2" || this.imageType == "fDoc2") {
                        this.orc = [];
                        this.itemChooseOrc = {};
                        if (url != null && url != "./images/idtype.png") {
                            const blob62 = await fetch(url).then(res => res.blob());
                            data.append('front_image', blob62);
                            config = {
                                method: 'post',
                                url: host + '/laos/household-next/ocr',
                                headers: {
                                    'Authorization': token_laos
                                },
                                data: data
                            };
                            response = await axios(config)
                                .catch(function () {
                                    return { data: { response_code: 400 } };
                                }).finally(() => {
                                    this.hideLoading()
                                })
                            if (response.data.response_code == 200) {
                                this.orc = response.data.data.ocr;
                                var count = 1;
                                this.orc.map(function (e) {
                                    e.id = count;
                                    count++
                                })
                                $('#methodBtn').trigger('click')
                            } else {
                                store.dispatch('setNotification', {
                                    title: this.$i18n.t('notification.title'),
                                    content: this.$i18n.t('home.registration_info.notiImageError'),
                                    closeButton: this.$i18n.t('buttons.close')
                                })
                                this.data.image12 = "./images/idtype.png";
                            }

                        }
                    }

                }

            },
            async compareFace(url) {
                if (this.data.idType == 6) {
                    return
                }
                this.showLoading()
                var data = new FormData();
                var response = null;
                var config = {};
                var res = {};
                if (url != null && url != "./images/image_client1.png") {
                    const blob = await fetch(url).then(res => res.blob());
                    const blob2 = await fetch(this.data.image1).then(res => res.blob());
                    data.append('front_image', blob);
                    data.append('portrait_image', blob2);
                    config = {
                        method: 'post',
                        url: host + '/laos/compare-face',
                        headers: {
                            'Authorization': token_laos
                        },
                        data: data
                    };
                    response = await axios(config)
                        .catch(function () {
                            return { data: { response_code: 400 } };
                        }).finally(() => {
                            this.hideLoading()
                        })
                    if (response.data.response_code == 200) {
                        res = response.data.data;
                        if (res.compareFace.similarity < this.accuracy) {
                            store.dispatch('setNotification', {
                                title: this.$i18n.t('notification.title'),
                                content: this.$i18n.t('home.registration_info.notiImageCompare1') + Number(res.compareFace.similarity).toFixed(2) + '%' + this.$i18n.t('home.registration_info.notiImageCompare2') + this.accuracy + '%' + this.$i18n.t('home.registration_info.notiImageCompare3'),
                                closeButton: this.$i18n.t('buttons.close')
                            })
                            this.data.image2 = "./images/image_client1.png";
                        }
                    } else {
                        store.dispatch('setNotification', {
                            title: this.$i18n.t('notification.title'),
                            content: this.$i18n.t('home.registration_info.notiImageError'),
                            closeButton: this.$i18n.t('buttons.close')
                        })
                        this.data.image2 = "./images/image_client1.png";
                    }

                }
            },
            chooseOrc() {
                console.log(this.itemChooseOrc)
                if (this.itemChooseOrc) {
                    this.data.fullName = this.itemChooseOrc.name ? this.itemChooseOrc.name.value : "";
                    this.data.doB = this.itemChooseOrc.dob ?this.itemChooseOrc.dob.value.replace(/\s/g, '') : "";
                    //this.data.idNo = this.itemChooseOrc.id_number ? this.itemChooseOrc.id_number.value : "";
                    this.data.nationalities = this.itemChooseOrc.nationality ? this.itemChooseOrc.nationality.value : "";
                    this.data.gender = this.itemChooseOrc.gender.value == 'female' ? "F" : "M";
                    this.loadScript('js/select2.js')
                    this.loadScript('js/date-picker.js')
                    this.loadScript('js/date-month-picker.js')
                    $('#methodBtnClose').trigger('click')
                } else {
                    store.dispatch('setNotification', {
                        title: this.$i18n.t('notification.title'),
                        content: this.$i18n.t('home.registration_info.notiImageChooseUser'),
                        closeButton: this.$i18n.t('buttons.close')
                    })
                }
                
                
            },
            cancelOrc() {
                this.data.image12 = "./images/idtype.png";
            },
            onDeleteImage1() {
                this.data.image1 = "./images/idtype.png";
                this.data.image12 = "./images/idtype.png";
                this.data.image2 = "./images/image_client1.png";
                if (this.data.idType == 3) {
                    this.data.image2 = "./images/image_client2.png";
                } else if (this.data.idType == 6) {
                    this.data.image2 = "./images/image_client3.png";
                } else {
                    this.data.image2 = "./images/image_client1.png";
                }
                this.data.idNo = null;
                this.data.fullName = null;
                this.data.gender = null;
                this.data.doB = this.newDate;
                this.data.nationalities = null;
                this.data.provinceName = null;
                this.loadScript('js/select2.js')
                this.loadScript('js/date-picker.js')
                this.loadScript('js/date-month-picker.js')

            },
            onDeleteImage2() {
                this.data.image12 = "./images/idtype.png";
                this.data.image2 = "./images/image_client1.png";
                if (this.data.idType == 3) {
                    this.data.image2 = "./images/image_client2.png";
                } else if (this.data.idType == 6) {
                    this.data.image2 = "./images/image_client3.png";
                } else {
                    this.data.image2 = "./images/image_client1.png";
                }
                //this.data.idNo = null;
                this.data.fullName = null;
                this.data.gender = null;
                this.data.doB = this.newDate;
                this.data.nationalities = null;
                //this.data.Province = null;
                this.loadScript('js/select2.js')
                this.loadScript('js/date-picker.js')
                this.loadScript('js/date-month-picker.js')

            },
            onDeleteImage3() {
                this.data.image2 = "./images/image_client1.png";
                if (this.data.idType == 3) {
                    this.data.image2 = "./images/image_client2.png";
                } else if (this.data.idType == 6) {
                    this.data.image2 = "./images/image_client3.png";
                } else {
                    this.data.image2 = "./images/image_client1.png";
                }
            }
        }
    }
</script>
