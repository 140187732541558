<template>
   <div class="main-container">
      <div class="nav-bar js-nav-bar">
         <div class="nav-bar__inner">
            <span class="nav-bar__icon" @click="goBack"><i class="icon-fonts icon-fonts--arrow-left"></i></span>
            <h3 class="nav-bar__title">{{ $t('mobile.change_to_postpaid.title') }}</h3>
         </div>
      </div>
      <div class="main-content">
         <div class="change-postpaid">
            <div class="steps-bar">
               <ul class="steps-bar__list">
                  <li class="steps-bar__item active">
                     <span class="steps-bar__value">1</span>
                     <h5 class="steps-bar__name">
                        {{ $t('mobile.change_to_postpaid.select') }}<br />{{ $t('mobile.change_to_postpaid.package') }}
                     </h5>
                  </li>
                  <li class="steps-bar__item">
                     <span class="steps-bar__value">2</span>
                     <h5 class="steps-bar__name">
                        {{ $t('mobile.change_to_postpaid.customer') }}<br />{{
                           $t('mobile.change_to_postpaid.information')
                        }}
                     </h5>
                  </li>
                  <li class="steps-bar__item">
                     <span class="steps-bar__value">3</span>
                     <h5 class="steps-bar__name">{{ $t('mobile.change_to_postpaid.contract') }}</h5>
                  </li>
                  <li class="steps-bar__item">
                     <span class="steps-bar__value">4</span>
                     <h5 class="steps-bar__name">{{ $t('mobile.change_to_postpaid.result') }}</h5>
                  </li>
               </ul>
            </div>
            <h4 class="change-postpaid__title">
               {{ $t('mobile.change_to_postpaid.select') }} {{ $t('mobile.change_to_postpaid.package') }}
            </h4>
            <div class="package-deals">
               <ul class="package-deals__list">
                  <li class="package-deals__item" v-for="item in lstProductForTransfer" :key="item.productId">
                     <a class="package-deals__link" href="javascript:;">
                        <div class="package-deals__images">
                           <img src="@/assets/images/img-pack-deals.png" alt="img" />
                        </div>
                        <div class="package-deals__detail">
                           <div class="package-deals__info">
                              <h5 class="package-deals__name">{{ item.productCode }}</h5>
                              <p class="package-deals__value">{{ $t('mobile.change_to_postpaid.detail') }}</p>
                           </div>
                           <div class="package-deals__btn">
                              <button class="button button--primary button--small" @click="selectDetail(item)">
                                 {{ $t('mobile.change_to_postpaid.select') }}
                              </button>
                           </div>
                        </div>
                     </a>
                  </li>
               </ul>
            </div>
         </div>
      </div>
   </div>
</template>
<script>
import BaseComponent from '@/mixins/BaseComponent'
import service from './../../services/MobileService'
import LocalStorage from '@/ultils/local-storage'
// import store from '@/plugins/vuex/store'

export default {
   name: 'SelectPackage',
   mixins: [BaseComponent],
   components: {},
   data() {
      return {
         lstProductForTransfer: []
      }
   },
   created() {
      this.setTitle(this.$i18n.t('mobile.change_to_postpaid.title'))
      this.loadData()
   },
   methods: {
      loadData() {
         this.showLoading()
         service
            .getLstProductForTransfer()
            .then((response) => {
               if (response.errorCode == '0') {
                  this.lstProductForTransfer = response.object ?? []
               }
            })
            .finally(() => {
               this.hideLoading()
            })
      },
    //   selectDetail(detail) {
    //      LocalStorage.setItem('change-to-postpaid', JSON.stringify({ step1: detail }))
    //      this.$router.push('/mobile/change_to_postpaid/detail')
    //   },
         // call api wsGetCustInfoTransfer if success else push
      selectDetail(detail) {
         LocalStorage.setItem('change-to-postpaid', JSON.stringify({ step1: detail }))
         this.$router.push('/mobile/change_to_postpaid/detail')
         // this.showLoading()
         // service
         //    .getCustInfoTransfer()
         //    .then((response) => {
         //       if (response.errorCode != '0') {
         //          store.dispatch('setNotification', {
         //             title: this.$i18n.t('notification.title'),
         //             content: response.errorMessage,
         //             closeButton: this.$i18n.t('buttons.close')
         //          })
         //       } else {
         //        LocalStorage.setItem('change-to-postpaid', JSON.stringify({ step1: detail }))
         //        this.$router.push('/mobile/change_to_postpaid/detail')
         //       }
         //    })
         //    .finally(() => {
         //       this.hideLoading()
         //    })
      }
   }
}
</script>
