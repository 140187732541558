<template>
   <div class="main-container">
      <div class="nav-bar js-nav-bar" @click="goBack">
         <div class="nav-bar__inner">
            <span class="nav-bar__icon"><i class="icon-fonts icon-fonts--arrow-left"></i></span>
            <h3 class="nav-bar__title">{{ $t('ftth.change_password.title') }}</h3>
         </div>
      </div>
      <div class="main-content">
         <ValidationObserver v-slot="{ passes }">
            <form @submit.prevent="passes(onSubmit)">
               <div class="change-password">
                  <div class="change-password__top">
                     <h5 class="change-password__sub">{{ $t('ftth.change_password.isdn_account') }}</h5>
                     <span class="change-password__value">{{ account }}</span>
                  </div>
                  <div class="change-password__form">
                     <Input
                        type="password"
                        :name="$t('ftth.change_password.old_password')"
                        :label="$t('ftth.change_password.old_password') + ' (*)'"
                        :placeholder="$t('ftth.change_password.enter_old_password')"
                        rules="required"
                        v-model="currentPassword"
                     />
                     <Input
                        type="password"
                        :name="$t('ftth.change_password.new_password')"
                        :label="$t('ftth.change_password.new_password') + ' (*)'"
                        :placeholder="$t('ftth.change_password.enter_new_password')"
                        vid="pass"
                        rules="required|password"
                        v-model="newPassword"
                     />
                     <Input
                        type="password"
                        :name="$t('ftth.change_password.retype_new_password')"
                        :placeholder="$t('ftth.change_password.retype_new_password')"
                        rules="required|confirmedBy:@pass"
                        v-model="confirmNewPassword"
                     />
                  </div>
                  <div class="change-password__btn">
                     <button class="button button--primary button--full">
                        {{ $t('ftth.change_password.submit_to_change') }}
                     </button>
                  </div>
               </div>
            </form>
         </ValidationObserver>
      </div>
   </div>
</template>
<script>
import { ValidationObserver } from 'vee-validate'
import Input from '@/components/form/Input'
import BaseComponent from '@/mixins/BaseComponent'
import service from './../../services/FTTHService'
import LocalStorage from '@/ultils/local-storage'

export default {
   name: 'ChangePassword',
   mixins: [BaseComponent],
   components: {
      ValidationObserver,
      Input
   },
   data() {
      return {
         currentPassword: '',
         newPassword: '',
         confirmNewPassword: '',
         account: ''
      }
   },
   created() {
      this.setTitle(this.$i18n.t('ftth.change_password.title'))
      this.account = LocalStorage.getItem('ftth_account')
   },
   methods: {
      onSubmit() {
         this.showLoading()
         service
            .changePassword({
               isStrongPassword: 'true',
               passnew: this.newPassword,
               language: localStorage.getItem('lang'),
               passold: this.currentPassword,
               username: this.account,
               isEncrypt: '0'
            })
            .then((response) => {
               this.$store.dispatch('setNotification', { content: response.errorMessage ?? response.result })
            })
            .finally(() => {
               this.hideLoading()
            })
      }
   }
}
</script>
