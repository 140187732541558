<template>
    <div class="main-container">
        <div class="nav-bar js-nav-bar" @click="goBack">
            <div class="nav-bar__inner">
                <span class="nav-bar__icon"><i class="icon-fonts icon-fonts--arrow-left"></i></span>
                <h3 class="nav-bar__title">{{ $t('mobile.data_packages.title') }}</h3>
            </div>
        </div>
        <div class="main-content">
            <div class="credit-service">
                <div class="tabs-list">
                    <div class="nav tabs-list__nav" role="tablist">
                        <a class="tabs-list__link" :class="{active : activeTab == 1}" href="#recommend" @click="updateActiveTab(1)" role="tab" data-bs-toggle="tab">
                            {{$t('mobile.data_packages.recommend')}}
                        </a>
                        <template>
                            <a v-for="item in lstTab" :key="item.key" class="tabs-list__link" :class="{active : activeTab == item.key}" 
                                :href="'#'+item.key" @click="updateActiveTab(item.key);loadDataInTab(item.val)" role="tab" data-bs-toggle="tab">
                            {{$t('mobile.data_packages.'+item.val)}}
                            </a>
                        </template>
                    </div>
                    <div class="tabs-list__content tab-content">
                         <div class="tab-pane fade" :class="{'show active' : activeTab == 1}" id="recommend" role="tabpanel">
                            <div class="credit-service__content">

                                <div class="credit-service__detail">
                                    <div class="process-data process-data--acc">
                                        <div class="process-data__detail" v-if="isAuthenticated">
                                            <div class="process-data__top">
                                            <div class="process-data__info">
                                                <h4 class="process-data__name">{{ $t('mobile.account_information.data') }}</h4>
                                                <span class="process-data__tag">{{ $t('mobile.home.data') }}</span>
                                            </div>
                                            <div class="process-data__capacity">
                                                <span class="process-data__capacity-total"
                                                    >{{ Number(subAccount.dataVolume).toLocaleString('en-US', { maximumFractionDigits: 0 }) }}
                                                    {{ $t('unit.mb') }}</span
                                                >
                                            </div>
                                            </div>
                                            <div class="process-bar">
                                            <p class="process-bar__percent" :style="processStyle"></p>
                                            </div>
                                        </div>
                                        <span class="process-data__action" v-if="isAuthenticated" @click="onClickFunction('/mobile/credit-service?tab=2')"
                                            ><i class="icon-fonts icon-fonts--add-2"></i
                                        ></span>
                                    </div>
                                    <template v-if="listPackagesCurrentUsed && listPackagesCurrentUsed.length !=0">
                                        <div class="credit-service__row">
                                        <h4 class="credit-service__sub">{{ $t('mobile.data_packages.your_recent_package') }}</h4>
                                        <div class="credit-service credit-service--pack">
                                            <ul class="credit-service__list">
                                                <li class="credit-service__item">
                                                <div class="credit-service__item-detail" @click="redirectToDetailRecent(listPackagesCurrentUsed[0])">
                                                    <div class="credit-service__item-images"><img :src="listPackagesCurrentUsed[0].iconUrl" alt="img" /><span class="credit-service__item-tag heart"><span>{{listPackagesCurrentUsed[0].name}}</span></span></div>
                                                    <div class="credit-service__item-info">
                                                    <div class="credit-service__item-top">
                                                        <h5 class="credit-service__item-name"><a class="credit-service__item-link" href="javascript:void(0)">{{listPackagesCurrentUsed[0].name}}</a></h5>
                                                        <!-- <span class="credit-service__item-price">
                                                            <i class="icon-fonts icon-fonts--price"></i>
                                                            {{listPackagesCurrentUsed[0].code}}
                                                        </span> -->
                                                    </div>
                                                    <p class="credit-service__item-des">{{listPackagesCurrentUsed[0].des}}</p>
                                                    </div>
                                                </div>
                                                <div class="credit-service__item-btn">
                                                    <button class="button button--primary button--small" @click="registerPackSpecial(listPackagesCurrentUsed[0])">
                                                            {{ $t('buttons.register') }}
                                                    </button>
                                                </div>
                                                </li>
                                            </ul>
                                        </div>
                                        </div>
                                    </template>
                                    <div class="credit-service__row">
                                        <h4 class="credit-service__sub">{{ $t('mobile.data_packages.special1') }}</h4>
                                        <div class="credit-service credit-service--pack">
                                            <ul class="credit-service__list">
                                                <li class="credit-service__item" v-for="pack in listPackagesSpecial" :key="pack.code">
                                                    <div v-if="pack.isXboxService == 1" class="credit-service__item-promotion"><img src="@/assets/images/bg-promotion.svg" alt="img"></div>
                                                    <div class="credit-service__item-detail" @click="redirectToDetailSpecial(pack)">
                                                        <div class="credit-service__item-images">
                                                            <img :src="pack.iconUrl" alt="img" /><span class="credit-service__item-tag heart"><span>{{ pack.code }}</span></span>
                                                        </div>
                                                        <div class="credit-service__item-info">
                                                            <div class="credit-service__item-top">
                                                                <h5 class="credit-service__item-name">
                                                                    <a class="credit-service__item-link" href="javascript:void(0)">{{ pack.name }}</a>
                                                                </h5>
                                                                <!-- <span v-if="pack.isXboxService == 1" class="credit-service__item-icon"><i class="icon-fonts icon-fonts--sale"></i></span> -->
                                                            </div>
                                                            <p class="credit-service__item-des">{{ pack.shortDes }}</p>
                                                            <p v-if="pack.isXboxService == 1" class="credit-service__item-sale highlight">
                                                                <i class="icon-fonts icon-fonts--sale"></i>
                                                                {{$t('mobile.data_packages.get_random_lucky')}}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div class="credit-service__item-btn">
                                                        <button class="button button--primary button--small" @click="registerPackSpecial(pack)">
                                                            {{ $t('buttons.register') }}
                                                        </button>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <template>
                            <div v-for="item in lstTab" :key="item.key" class="tab-pane fade" 
                                :class="{'show active' : activeTab == item.key}" :id="item.key" role="tabpanel">
                                <div class="credit-service__content">
                                    <div class="credit-service__detail">
                                        <div class="credit-service credit-service--pack">
                                            <ul class="credit-service__list">
                                                <li class="credit-service__item" v-for="pack in listPackagesItem" :key="pack.code">
                                                    <div v-if="pack.isXboxService == 1" class="credit-service__item-promotion"><img src="@/assets/images/bg-promotion.svg" alt="img"></div>
                                                    <div class="credit-service__item-detail" @click="redirectToDetail(pack)">
                                                        <div class="credit-service__item-images">
                                                            <img :src="pack.iconUrl" alt="img" /><span class="credit-service__item-tag heart"><span>{{ pack.code }}</span></span>
                                                        </div>
                                                        <div class="credit-service__item-info">
                                                            <div class="credit-service__item-top">
                                                                <h5 class="credit-service__item-name">
                                                                    <a class="credit-service__item-link" href="javascript:void(0)">{{ pack.name }}</a>
                                                                </h5>
                                                                <!-- <span v-if="pack.isXboxService == 1" class="credit-service__item-icon"><i class="icon-fonts icon-fonts--sale"></i></span> -->
                                                            </div>
                                                            <p class="credit-service__item-des">{{ pack.shortDes }}</p>
                                                            <p v-if="pack.isXboxService == 1" class="credit-service__item-sale highlight">
                                                                <i class="icon-fonts icon-fonts--sale"></i>
                                                                Get random lucky gift
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div class="credit-service__item-btn">
                                                        <button class="button button--primary button--small" @click="registerPack(pack)">
                                                            {{ $t('buttons.register') }}
                                                        </button>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                        </template>
                        
                    </div>
                </div>
            </div>
        </div>
        <register-pack-flow :data="selectedPack" />
    </div>
</template>
<script>
    import BaseComponent from '@/mixins/BaseComponent'
    import service from '../../services/MobileService'
    import LocalStorage from '@/ultils/local-storage'
    import { PackageType } from '../../constants/data'
    import RegisterPackFlow from './../common/RegisterPackFlow'
    import store from '@/plugins/vuex/store'

    export default {
        name: 'DataPackageList',
        mixins: [BaseComponent],
        components: {
            RegisterPackFlow
        },
        data() {
            return {
                isAuthenticated:false,
                listPackagesSpecial: [],
                listPackages: {},
                selectedPack: {},
                activeTab: this.$route.query.tab ?? '1',
                lstTab:[],
                listPackagesItem:[],
                subAccount:{
                    prePost: 0,
                    debPost: 0,
                    mainAcc: 0,
                    proAcc: 0,
                    dataPkgName: '',
                    dataVolume: 0
                },
                processStyle: 'width: 80%',
                listPackagesCurrentUsed: [],
                isUmoney: '0',
                isLaptop: '0'
            }
        },
        created() {
            this.setTitle(this.$i18n.t('mobile.data_packages.list_of_packages'))
            this.loadData()
        },
        methods: {
            async loadData() {
                this.showLoading()
                await service.getUMoney()
                        .then((response)=>{
                            if (response.errorCode === '0') {
                                let uMoney = response.wsResponse ?? {}
                                if(uMoney && Object.keys(uMoney).length != 0){
                                    this.isUmoney = '1'
                                }
                            }
                        })
                        .finally(()=>{})
                await service.xboxGetService({
                    gameCode: 'XBOX_GAME',
                    subType: LocalStorage.getItem('subType')
                }).then((response)=>{
                    if(response.errorCode == '0'){
                        this.isLaptop =  response.wsResponse.isLaptop
                    }
                }).finally(() => {})

                await service
                    .getListDataPromotion()
                    .then((response) => {
                        this.listPackagesSpecial = response.wsResponse ?? []
                    })
                    .finally(() => {
                        //this.hideLoading()
                    })
                await service
                    .GetCurrentUsedSubServices()
                    .then((response) => {
                        this.listPackagesCurrentUsed = response.wsResponse ?? []
                    })
                    .finally(() => {
                        //this.hideLoading()
                    })
                    
                await service
                    .getListDataPackagesV2()
                    .then((response) => {
                        let tabLst = response.wsResponse ?? {}
                        this.listPackages = response.wsResponse ?? {}
                        let key = Object.keys(tabLst)
                        //debugger; // eslint-disable-line no-debugger
                        key.forEach(element => {
                            let _tab = [{
                                key:element,
                                val:element
                            }]
                            this.lstTab = [...this.lstTab, ..._tab]
                        });
                        if(this.listPackages != null && Object.keys(this.listPackages).length != 0){
                            this.listPackagesItem = this.listPackages[this.activeTab]
                        }
       

                    })
                    .finally(() => {
                       
                    })

                    // == 1 trả trước, ==2 trả sau
                    let userDetail = JSON.parse(LocalStorage.getItem('user_info')) ?? {}
                    let isdn = userDetail.isdn
                    let subType = LocalStorage.getItem('subType')
                    await service.getSubAccountInfo({"isdn":isdn,"subType": subType}).then((response) => {
                    // this.accountInfo = response.wsResponse ?? {}
                    let data = response.wsResponse
                    this.accountInfo = data ? {
                    prePost: data.prePost ?? 0,
                    debPost: data.debPost ?? 0,
                    mainAcc: data.mainAcc ?? 0,
                    proAcc: data.proAcc ?? 0,
                    dataPkgName: data.name,
                    dataVolume: data.dataVolume
                    } : {}

                    this.subAccount = data ? {
                        prePost: data.prePost ?? 0,
                        debPost: data.debPost ?? 0,
                        mainAcc: data.mainAcc ?? 0,
                        proAcc: data.proAcc ?? 0,
                        dataPkgName: data.name,
                        dataVolume: data.dataVolume
                    } : {}
                    })
                    
                    this.$forceUpdate()
                    this.isAuthenticated = true
                    this.hideLoading()
                    console.log('this.lstTab',this.lstTab)
            },
            updateActiveTab(number) {
                this.$router.push({ path: '/mobile/data-package?tab=' + number })
                this.activeTab = number
                store.commit('setPackageActiveTab', number)
            },
            registerPack(pack) {
                if(pack.isXboxService == 1){
                    this.xboxRegisterService(pack.code)
                } else {
                    this.selectedPack = this.flowData = Object.assign({}, pack)
                }
            },
            redirectToDetail(pack) {
                console.log(pack)
                LocalStorage.setItem('package', JSON.stringify({ type: PackageType.NormalPackage, data: pack }))
                let isXboxService = pack.isXboxService ? pack.isXboxService :'0'
                this.$router.push('/mobile/data-package/detail?type=' + PackageType.NormalPackage + '&code=' + pack.code + '&isXboxService=' + isXboxService)
            },

            redirectToDetailRecent(pack) {
                console.log(pack)
                LocalStorage.setItem('package', JSON.stringify({ type: PackageType.NormalPackage, data: pack }))
                this.$router.push('/mobile/data-package/detail?code=' + pack.code + '&type=' + PackageType.NormalPackage)
            },

            registerPackSpecial(pack) {
                if(pack.isXboxService == 1){
                    this.xboxRegisterService(pack.code)
                } else {
                    this.selectedPack = Object.assign({}, pack)
                }
            },
            redirectToDetailSpecial(pack) {
                console.log(pack)
                LocalStorage.setItem('package', JSON.stringify({ type: PackageType.SpecialPackage, data: pack }))
                let isXboxService = pack.isXboxService ? pack.isXboxService :'0'
                this.$router.push('/mobile/data-package/detail?type=' + PackageType.SpecialPackage + '&code=' + pack.code + '&isXboxService=' + isXboxService)
            },
            loadDataInTab(val){
                this.listPackagesItem =  this.listPackages[val]
            },
            async onClickFunction(url) {
                this.$router.push(url)
            },
            //xbox
            xboxRegisterService(code){
                this.showLoading()
                service.xboxRegisterService({
                        serviceCode: code,
                        isLaptop: this.isLaptop,
                        gameCode: 'XBOX_GAME'
                        }).then((response)=>{
                        if(response.errorCode == '0'){
                            let result =  response.wsResponse.moId
                            let maxRetryCheckMo = response.wsResponse.maxRetryCheckMo
                            let delayTime = response.wsResponse.delayTime
                            this.xboxGetGift(result, maxRetryCheckMo, delayTime)
                        } else {
                            this.$store.dispatch('setNotification', { content: response.message ?? response.userMsg })
                            this.hideLoading()
                        }
                        // console.log(response)
                        }).catch(()=>{
                        this.hideLoading()
                        })
            },
            xboxGetGift(moId, maxRetryCheckMo, delayTime){
                this.showLoading()
                var counter = 0
                var errorCode = 2
                var interval = setInterval(()=>{
                    if((counter < maxRetryCheckMo) && errorCode == '2'){
                        service.xboxGetGift({
                            moId: moId,
                            gameCode: 'XBOX_GAME',
                            isUmoney: this.isUmoney
                        }).then((response)=>{
                            errorCode = response.errorCode
                            if(errorCode != '2'){
                                if(response.errorCode == '0'){
                                    this.$store.dispatch('setNotification', { content: response.message ?? response.userMsg, icon:"./images/ic-success.svg" })
                                } else {
                                    this.$store.dispatch('setNotification', { content: response.message ?? response.userMsg })
                                }
                            }
                        }).catch(()=>{
                            this.hideLoading()
                        }).finally(() => {
                        this.hideLoading()
                        counter++
                        })
                    } else {
                        clearInterval(interval)
                        this.hideLoading()
                    }
                }, delayTime * 1000)
            }
        }
    }
</script>
