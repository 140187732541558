<template>
   <div class="main-container">
      <div class="nav-bar js-nav-bar" @click="goBack">
         <div class="nav-bar__inner">
            <span class="nav-bar__icon"><i class="icon-fonts icon-fonts--arrow-left"></i></span>
            <h3 class="nav-bar__title">{{ $t('mobile.charge_history.call') }}</h3>
         </div>
      </div>
      <div class="main-content">
         <div class="total-charges">
            <p class="time-charge">{{ startDate }} - {{ endDate }}</p>
            <div class="total-charges__detail">
               <div class="total-charges__row">
                  <div class="total-charges__top">
                     <h5 class="total-charges__top-name">{{ $t('mobile.charge_history.total_call') }}</h5>
                     <span class="total-charges__top-value">{{
                        Number(totalRecords).toLocaleString('en-US', { maximumFractionDigits: 0 })
                     }}</span>
                  </div>
               </div>
               <div class="total-charges__row">
                  <div class="total-charges__top">
                     <h5 class="total-charges__top-name">{{ $t('mobile.charge_history.total_charges') }}</h5>
                     <span class="total-charges__top-value"
                        >{{ Number(totalCharges).toLocaleString('en-US', { maximumFractionDigits: 0 }) }}
                        {{ $t('unit.kip') }}</span
                     >
                  </div>
                  <ul class="total-charges__list">
                     <li class="total-charges__item" v-for="call in listCalls" :key="call.start_time">
                        <div class="total-charges__item-detail">
                           <span class="total-charges__item-icon green" v-if="call.direction != 0"
                              ><i class="icon-fonts icon-fonts--call-in"></i
                           ></span>
                           <span class="total-charges__item-icon" v-if="call.direction == 0"
                              ><i class="icon-fonts icon-fonts--call-out"></i
                           ></span>
                           <div class="total-charges__item-info">
                              <h5 class="total-charges__item-name">{{ call.isdn }}</h5>
                              <span class="total-charges__item-date">{{ formatDateTime(call.start_time) }}</span>
                           </div>
                        </div>
                        <div class="total-charges__item-fee">
                           <span class="total-charges__item-kip"
                              >{{ Number(call.value).toLocaleString('en-US', { maximumFractionDigits: 0 }) }}
                              {{ $t('unit.kip') }}</span
                           ><span class="total-charges__item-time">{{ formatTime(call.duration) }}</span>
                        </div>
                     </li>
                  </ul>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>
<script>
import BaseComponent from '@/mixins/BaseComponent'
import { DATE_FORMAT } from './../../constants/data'
import service from './../../services/MobileService'
import moment from 'moment'
import LocalStorage from '@/ultils/local-storage'

export default {
   name: 'Calls',
   mixins: [BaseComponent],
   data() {
      return {
         startDate: this.getDateFromMiliseconds(this.$route.params.startTime, DATE_FORMAT),
         endDate: this.getDateFromMiliseconds(this.$route.params.endTime, DATE_FORMAT),
         totalCharges: 0,
         listCalls: [],
         subType: 1,
         phone: '',
         pageSize: 25,
         pageNum: 0,
         totalRecords: 0,
         isLoading: false
      }
   },
   created() {
      this.setTitle(this.$i18n.t('mobile.charge_history.call'))
      this.subType = LocalStorage.getItem('subType')
      let userInfo = JSON.parse(LocalStorage.getItem('user_info')) ?? {}
      this.phone = userInfo['isdn'] ?? ''
      this.totalRecords = this.$route.params.recordNumber
      this.loadData()
      this.addScrollDownEvent(this.loadData)
   },
   methods: {
      loadData() {
         this.pageNum += 1
         this.isLoading = true
         this.showLoading()
         service
            .getPostageDetailInfo({
               postType: 0,
               pageSize: this.pageSize,
               subType: this.subType,
               sort: 0,
               pageNum: this.pageNum,
               startDate: this.$route.params.startTime,
               endDate: this.$route.params.endTime
            })
            .then((response) => {
               this.listCalls = this.listCalls.concat(response.wsResponse)
               this.totalCharges = this.listCalls.reduce(function (total, call) {
                  return total + call['value']
               }, 0)
            })
            .finally(() => {
               this.isLoading = false
               this.hideLoading()
            })
      },
      formatDateTime(miliseconds) {
         return moment(miliseconds).format('HH:mm:ss | DD/MM/yyyy')
      },
      formatTime(seconds) {
         //check time > 59:59
         if (seconds > 3599) {
            return moment.utc(seconds * 1000).format('HH:mm:ss')
         } else {
            return moment.utc(seconds * 1000).format('mm:ss')
         }
      }
   }
}
</script>
