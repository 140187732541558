<template>
   <div class="main-container">
      <div class="nav-bar js-nav-bar" @click="goBack">
         <div class="nav-bar__inner">
            <span class="nav-bar__icon"><i class="icon-fonts icon-fonts--arrow-left"></i></span>
            <h3 class="nav-bar__title">{{ $t('ucare.point_history.title') }}</h3>
         </div>
      </div>
      <div class="main-content">
         <div class="point-history">
            <div class="tabs-list">
               <div class="nav tabs-list__nav" role="tablist">
                  <a class="tabs-list__link active" href="#add-point" role="tab" data-bs-toggle="tab">
                     {{ $t('ucare.point_history.add_points') }}</a
                  ><a class="tabs-list__link" href="#exchange-point" role="tab" data-bs-toggle="tab">
                     {{ $t('ucare.point_history.exchange_point') }}</a
                  >
                  <!-- <a class="tabs-list__link" href="#ranking" role="tab" data-bs-toggle="tab">
                     {{ $t('ucare.point_history.ranking') }}</a
                  > -->
               </div>
               <div class="tabs-list__content tab-content">
                  <div class="tab-pane fade show active" id="add-point" role="tabpanel">
                     <div class="point-history__content">
                        <ul class="point-history__list">
                           <li class="point-history__item" v-for="item in listAddPoints" :key="item.hisId">
                              <div class="point-history__images">
                                 <img src="@/assets/images/img-point-history.svg" alt="img" />
                              </div>
                              <div class="point-history__detail">
                                 <h5 class="point-history__name">
                                    <a class="point-history__name-link" href="javascript:void(0)">{{
                                       item.transferTypeName
                                    }}</a>
                                 </h5>
                                 <div class="point-history__info">
                                    <span class="point-history__time">{{
                                       item.createDate.substring(0, 10).split('-').reverse().join('/')
                                    }}</span
                                    ><span class="point-history__value add"
                                       >+{{
                                          Number(item.amount).toLocaleString('en-US', { maximumFractionDigits: 0 })
                                       }}
                                       {{ $t('unit.upoint') }}</span
                                    >
                                 </div>
                              </div>
                           </li>
                        </ul>
                     </div>
                  </div>
                  <div class="tab-pane fade" id="exchange-point" role="tabpanel">
                     <div class="point-history__content">
                        <ul class="point-history__list">
                           <li class="point-history__item" v-for="item in listExchangePoints" :key="item.hisId">
                              <div class="point-history__images">
                                 <img src="@/assets/images/img-point-history.svg" alt="img" />
                              </div>
                              <div class="point-history__detail">
                                 <h5 class="point-history__name">
                                    <a class="point-history__name-link" href="javascript:void(0)">{{
                                       item.transferTypeName
                                    }}</a>
                                 </h5>
                                 <div class="point-history__info">
                                    <span class="point-history__time">{{
                                       item.createDate.substring(0, 10).split('-').reverse().join('/')
                                    }}</span
                                    ><span class="point-history__value exchange"
                                       >{{ Number(item.amount).toLocaleString('en-US', { maximumFractionDigits: 0 }) }}
                                       {{ $t('unit.upoint') }}</span
                                    >
                                 </div>
                              </div>
                           </li>
                        </ul>
                     </div>
                  </div>
                  <!-- <div class="tab-pane fade" id="ranking" role="tabpanel">
                     <div class="point-history__content">
                        <div class="ranking-point">
                           <h4 class="ranking-point__title">{{ getRankMemberInfo() }}</h4>
                           <p class="ranking-point__des">
                              {{ getRankNotice() }}
                           </p>
                           <div class="ranking-member">
                              <ul class="ranking-member__list">
                                 <li class="ranking-member__item">
                                    <div class="ranking-member__icon">
                                       <img src="@/assets/images/ic-welcome.svg" alt="img" />
                                    </div>
                                    <div class="ranking-member__info">
                                       <h5 class="ranking-member__name">
                                          <a class="ranking-member__name-link" href="javascript:void(0)">{{
                                             $t('ucare.point_history.welcome')
                                          }}</a>
                                       </h5>
                                    </div>
                                 </li>
                                 <li class="ranking-member__item">
                                    <div class="ranking-member__icon">
                                       <img src="@/assets/images/ic-bronze.svg" alt="img" />
                                    </div>
                                    <div class="ranking-member__info">
                                       <h5 class="ranking-member__name">
                                          <a class="ranking-member__name-link active" href="javascript:void(0)">{{
                                             $t('ucare.point_history.bronze')
                                          }}</a>
                                       </h5>
                                    </div>
                                 </li>
                                 <li class="ranking-member__item">
                                    <div class="ranking-member__icon">
                                       <img src="@/assets/images/ic-sliver.svg" alt="img" />
                                    </div>
                                    <div class="ranking-member__info">
                                       <h5 class="ranking-member__name">
                                          <a class="ranking-member__name-link" href="javascript:void(0)">{{
                                             $t('ucare.point_history.silver')
                                          }}</a>
                                       </h5>
                                    </div>
                                 </li>
                                 <li class="ranking-member__item">
                                    <div class="ranking-member__icon">
                                       <img src="@/assets/images/ic-gold.svg" alt="img" />
                                    </div>
                                    <div class="ranking-member__info">
                                       <h5 class="ranking-member__name">
                                          <a class="ranking-member__name-link" href="javascript:void(0)">{{
                                             $t('ucare.point_history.gold')
                                          }}</a>
                                       </h5>
                                    </div>
                                 </li>
                                 <li class="ranking-member__item">
                                    <div class="ranking-member__icon">
                                       <img src="@/assets/images/ic-platinum.svg" alt="img" />
                                    </div>
                                    <div class="ranking-member__info">
                                       <h5 class="ranking-member__name">
                                          <a class="ranking-member__name-link" href="javascript:void(0)">{{
                                             $t('ucare.point_history.platium')
                                          }}</a>
                                       </h5>
                                    </div>
                                 </li>
                              </ul>
                           </div>
                           <div class="ranking-point__detail">
                              <div class="ranking-point__top">
                                 <h4 class="ranking-point__sub">{{ $t('ucare.point_history.title') }}</h4>
                                 <div class="ranking-point__select">
                                    <div class="select-box js-select-box">
                                       <select
                                          class="js-custom-select"
                                          :data-placeholder="$t('ucare.point_history.year')"
                                       >
                                          <option value=""></option>
                                          <option value="1">2019</option>
                                          <option value="2">2018</option>
                                          <option value="3">2017</option>
                                          <option value="4">2016</option>
                                          <option value="5">2015</option>
                                       </select>
                                    </div>
                                 </div>
                              </div>
                              <ul class="ranking-point__list">
                                 <ul class="ranking-point__list">
                                    <li class="ranking-point__item">
                                       <span class="ranking-point__icon"
                                          ><img src="@/assets/images/ic-bronze.svg" alt="img"
                                       /></span>
                                       <h5 class="ranking-point__name">Bronze</h5>
                                       <span class="ranking-point__value">388 point</span
                                       ><span class="ranking-point__time">15/07/2020</span>
                                    </li>
                                    <li class="ranking-point__item">
                                       <span class="ranking-point__icon"
                                          ><img src="@/assets/images/ic-gold.svg" alt="img"
                                       /></span>
                                       <h5 class="ranking-point__name">Gold</h5>
                                       <span class="ranking-point__value">388 point</span
                                       ><span class="ranking-point__time">15/07/2020</span>
                                    </li>
                                    <li class="ranking-point__item">
                                       <span class="ranking-point__icon"
                                          ><img src="@/assets/images/ic-welcome.svg" alt="img"
                                       /></span>
                                       <h5 class="ranking-point__name">Welcome</h5>
                                       <span class="ranking-point__value">388 point</span
                                       ><span class="ranking-point__time">15/07/2020</span>
                                    </li>
                                    <li class="ranking-point__item">
                                       <span class="ranking-point__icon"
                                          ><img src="@/assets/images/ic-gold.svg" alt="img"
                                       /></span>
                                       <h5 class="ranking-point__name">Gold</h5>
                                       <span class="ranking-point__value">388 point</span
                                       ><span class="ranking-point__time">15/07/2020</span>
                                    </li>
                                 </ul>
                              </ul>
                           </div>
                        </div>
                     </div>
                  </div> -->
               </div>
            </div>
         </div>
      </div>
   </div>
</template>
<script>
import BaseComponent from '@/mixins/BaseComponent'
import service from './../../services/UCareService'

export default {
   name: 'PointHistory',
   mixins: [BaseComponent],
   data() {
      return {
         listAddPoints: [],
         listExchangePoints: [],
         accountRankInfo: {},
         rankDefineInfo: [],
         accountPointInfo: []
      }
   },
   created() {
      this.setTitle(this.$i18n.t('ucare.point_history.title'))
      this.loadScript('js/select2.js')
      this.loadData()
   },
   methods: {
      loadData() {
         let getListAddPoints = service.getPointTransferHistory({ type: 1, pointId: 1000001 }).then((response) => {
            this.listAddPoints = response.listPointTransferHistory ?? []
         })

         let getListExchangePoints = service.getPointTransferHistory({ type: 2, pointId: 1000001 }).then((response) => {
            this.listExchangePoints = response.listPointTransferHistory ?? []
         })

         let getAccountRankInfo = service.getAccountRankInfo().then((response) => {
            this.accountRankInfo = response.accountRankDTO ?? {}
         })

         let getRankDefineInfo = service.getRankDefineInfo().then((response) => {
            this.rankDefineInfo = response.listRankDefine ?? []
         })

         let getAccountPointInfo = service.getAccountPointInfo().then((response) => {
            this.accountPointInfo = response.listAccountPoint ?? []
         })

         this.showLoading()
         Promise.allSettled([
            getListAddPoints,
            getListExchangePoints,
            getAccountRankInfo,
            getRankDefineInfo,
            getAccountPointInfo
         ]).finally(() => {
            this.hideLoading()
         })
      },
      getRankMemberInfo() {
         let name = ''
         switch (this.accountRankInfo.rankName) {
            case 'Loyal':
               name = this.$i18n.t('ucare.point_history.welcome')
               break
            case 'Titan':
               name = this.$i18n.t('ucare.point_history.bronze')
               break
            case 'Silver':
               name = this.$i18n.t('ucare.point_history.silver')
               break
            case 'Gold':
               name = this.$i18n.t('ucare.point_history.gold')
               break
            case 'Diamond':
               name = this.$i18n.t('ucare.point_history.vip')
               break
         }
         return this.$i18n.t('ucare.point_history.rank_member').replace('{1}', name)
      },
      getRankNotice() {
         let maxPoint =
            this.rankDefineInfo.filter((rank) => rank.rankName === this.accountRankInfo.rankName)[0]?.maxPoint ?? 0
         let exchangePoint = this.accountPointInfo.reduce((total, acc) => {
            if (acc.pointType === 2) {
               return total + acc.pointValue
            } else {
               return total
            }
         }, 0)
         let neededPoints = Number(maxPoint - exchangePoint).toLocaleString('en-US', { maximumFractionDigits: 0 })
         return this.$i18n
            .t('ucare.point_history.rank_notice')
            .replace('{1}', neededPoints)
            .replace('{2}', '')
            .replace('{3}', '')
      }
   }
}
</script>
