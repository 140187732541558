<template>
   <div class="main-container">
      <div class="nav-bar nav-bar--full" @click="back">
         <div class="nav-bar__inner">
            <span class="nav-bar__icon"><i class="icon-fonts icon-fonts--arrow-left"></i></span>
            <h3 class="nav-bar__title">{{ $t('ftth.account_list') }}</h3>
         </div>
      </div>
      <div class="main-content">
         <div class="account-list">
            <ul class="account-list__list">
               <li
                  class="account-list__item"
                  v-for="(account, index) in listAccounts"
                  :key="index"
                  @click="redirectTo(account)"
               >
                  <div class="account-list__detail">
                     <div class="account-list__images">
                        <span class="account-list__value">{{ account.productCode }}</span>
                     </div>
                     <div class="account-list__info">
                        <h5 class="account-list__name">
                           <a class="account-list__name-link" href="javascript:void(0)">{{ account.custName }}</a>
                        </h5>
                        <p class="account-list__des">{{ $t('ftth.account_name') }}: {{ account.accountNo }}</p>
                     </div>
                  </div>
               </li>
            </ul>
         </div>
      </div>
   </div>
</template>
<script>
import BaseComponent from '@/mixins/BaseComponent'
import service from './../../services/FTTHService'
import LocalStorage from '@/ultils/local-storage'
import { callNative } from '@/ultils/call-native'

export default {
   name: 'AccountList',
   mixins: [BaseComponent],
   data() {
      return {
         listAccounts: []
      }
   },
   created() {
      this.setTitle(this.$i18n.t('ftth.account_list'))
      let accounts = JSON.parse(LocalStorage.getItem('ftth_list_account'))
      if (accounts) {
         this.listAccounts = accounts
      } else {
         this.loadData()
      }
   },
   methods: {
      loadData() {
         this.showLoading()
         service
            .getListFtthAccountInfo()
            .then((response) => {
               this.listAccounts = response.wsResponse ?? []
               if (this.listAccounts.length === 1) {
                  this.redirectTo(this.listAccounts[0])
               }
            })
            .finally(() => {
               this.hideLoading()
            })
      },
      redirectTo(account) {
         let ftthUserInfo = {
            token: '',
            isdn: account.accountNo,
            sessionId: ''
         }
         LocalStorage.setItem('ftth_user_info', JSON.stringify(ftthUserInfo))
         LocalStorage.setItem('ftth_account', account.accountNo)
         callNative('ftthAccount', account.accountNo)
         this.$router.push('/ftth/service')
      },
      back() {
         LocalStorage.removeItem('ftth_list_account')
         LocalStorage.removeItem('ftth_account')
         LocalStorage.removeItem('ftth_redirect_url')
         LocalStorage.removeItem('ftth_user_info')
         callNative('logoutFtth', '')
         this.$router.push('/home')
      }
   }
}
</script>
