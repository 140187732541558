<template>
   <div class="main-container">
      <div class="nav-bar js-nav-bar" @click="goBack">
         <div class="nav-bar__inner">
            <span class="nav-bar__icon"><i class="icon-fonts icon-fonts--arrow-left"></i></span>
            <h3 class="nav-bar__title">{{ name }}</h3>
         </div>
      </div>
      <div class="main-content">
         <ValidationObserver v-slot="{ passes, invalid }">
            <form @submit.prevent="passes(onSubmit)">
               <div class="ftth-order">
                  <div class="ftth-order__form">
                     <div class="form-customize form-customize--disabled">
                        <input class="form-control" type="text" :value="fullName" disabled="disabled" />
                     </div>
                     <div class="form-customize">
                        <Input
                           type="number"
                           :label="$t('mobile.recharge_scratch_card.phone_number')"
                           :placeholder="$t('mobile.recharge_scratch_card.enter_phone_number')"
                           rules="required|phone"
                           v-model="phoneNumber"
                        />
                     </div>
                     <div class="form-customize">
                        <ValidationProvider
                           tag="div"
                           rules="required"
                           :name="$t('ftth.ftth_order.province')"
                           v-slot="{ errors }"
                        >
                           <label class="label-title">{{ $t('ftth.ftth_order.province') }}</label>
                           <div class="select-box js-select-box">
                              <select
                                 class="js-custom-select"
                                 :id="$t('ftth.ftth_order.province')"
                                 :data-placeholder="$t('ftth.ftth_order.select_province')"
                                 v-model="provinceId"
                              >
                                 <option value=""></option>
                                 <option :value="province.id" v-for="province in provinces" :key="province.name">
                                    {{ province.name }}
                                 </option>
                              </select>
                           </div>
                           <p class="error-text" v-if="errors[0]">{{ errors[0] }}</p>
                        </ValidationProvider>
                     </div>
                     <div class="form-customize">
                        <ValidationProvider
                           tag="div"
                           rules="required"
                           :name="$t('ftth.ftth_order.district')"
                           v-slot="{ errors }"
                        >
                           <label class="label-title">{{ $t('ftth.ftth_order.district') }}</label>
                           <div class="select-box js-select-box">
                              <select
                                 class="js-custom-select"
                                 :id="$t('ftth.ftth_order.district')"
                                 :data-placeholder="$t('ftth.ftth_order.select_district')"
                                 v-model="districtId"
                              >
                                 <option value=""></option>
                                 <option :value="district.id" v-for="district in districts" :key="district.name">
                                    {{ district.name }}
                                 </option>
                              </select>
                           </div>
                           <p class="error-text" v-if="errors[0]">{{ errors[0] }}</p>
                        </ValidationProvider>
                     </div>
                     <div class="form-customize">
                        <ValidationProvider
                           tag="div"
                           rules="required"
                           :name="$t('ftth.ftth_order.precinct')"
                           v-slot="{ errors }"
                        >
                           <label class="label-title">{{ $t('ftth.ftth_order.precinct') }}</label>
                           <div class="select-box js-select-box">
                              <select
                                 class="js-custom-select"
                                 :id="$t('ftth.ftth_order.precinct')"
                                 :data-placeholder="$t('ftth.ftth_order.select_precinct')"
                                 v-model="precinctId"
                              >
                                 <option value=""></option>
                                 <option :value="precinct.id" v-for="precinct in precincts" :key="precinct.name">
                                    {{ precinct.name }}
                                 </option>
                              </select>
                           </div>
                           <p class="error-text" v-if="errors[0]">{{ errors[0] }}</p>
                        </ValidationProvider>
                     </div>
                     <div class="form-customize">
                        <label class="label-title">
                           {{ $t('ftth.ftth_order.note') }}
                        </label>
                        <input class="form-control" type="text" :value="note" readonly />
                     </div>
                  </div>
                  <div class="ftth-order__btn">
                     <button class="button button--primary button--full" @click="onOrder(invalid)">
                        {{ $t('ftth.ftth_package.order') }}
                     </button>
                  </div>
               </div>
            </form>
         </ValidationObserver>
      </div>
   </div>
</template>
<script>
import BaseComponent from '@/mixins/BaseComponent'
import service from './../../services/FTTHService'
import store from '@/plugins/vuex/store'
import globalStore from '@/plugins/vuex/store'
import { ValidationObserver } from 'vee-validate'
import Input from '@/components/form/Input'
import LocalStorage from '@/ultils/local-storage'

export default {
   name: 'FtthOrder',
   mixins: [BaseComponent],
   components: {
      ValidationObserver,
      Input
   },
   data() {
      return {
         provinceId: null,
         districtId: null,
         precinctId: null,
         provinces: [],
         districts: [],
         precincts: [],
         name: '',
         fullName: '',
         phoneNumber: '',
         doB: '',
         note: ''
      }
   },
   created() {
      this.setTitle(this.$i18n.t('ftth.ftth_package.title'))
      this.name = store.state.ftthPackage
      let ftthAccountInfo = JSON.parse(LocalStorage.getItem('ftth_account_info'))
      this.fullName = ftthAccountInfo.custName
      this.phoneNumber = ftthAccountInfo.isdn
      this.getData()
      this.getProvinces()
   },
   watch: {
      provinceId() {
         this.districtId = null
         this.precinctId = null
         this.getDistricts()
         this.getPrecincts()
         this.setNote()
      },
      districtId() {
         this.precinctId = null
         this.getPrecincts()
         this.setNote()
      },
      precinctId() {
         this.setNote()
      }
   },
   methods: {
      getData() {
         this.showLoading()
         service
            .getInfoCust()
            .then((response) => {
               if (response.errorCode === '0') {
                  this.doB = response.wsResponse.doB
               }
            })
            .finally(() => {
               this.hideLoading()
            })
      },
      setNote() {
         this.note = ''
         if (this.provinceId) {
            var province = this.provinces.filter((x) => x.id == this.provinceId)[0]
            this.note = province.name
         }
         if (this.districtId) {
            var district = this.districts.filter((x) => x.id == this.districtId)[0]
            this.note = district.name + ', ' + this.note
         }
         if (this.precinctId) {
            var precinct = this.precincts.filter((x) => x.id == this.precinctId)[0]
            this.note = precinct.name + ', ' + this.note
         }
      },
      getProvinces() {
         this.showLoading()
         this.provinces = []
         service
            .getProvinces()
            .then((response) => {
               if (response.errorCode === '0') {
                  this.provinces = response.wsResponse
                  this.loadScript('js/select2.js')
               } else {
                  globalStore.dispatch('setNotification', { content: response.message ?? response.userMsg })
               }
            })
            .finally(() => {
               this.hideLoading()
            })
      },
      getDistricts() {
         this.showLoading()
         this.districts = []
         if (this.provinceId) {
            service
               .getDistricts({ provinceId: this.provinceId })
               .then((response) => {
                  if (response.errorCode === '0') {
                     this.districts = response.wsResponse
                     this.loadScript('js/select2.js')
                  } else {
                     globalStore.dispatch('setNotification', { content: response.message ?? response.userMsg })
                  }
               })
               .finally(() => {
                  this.hideLoading()
               })
         }
      },
      getPrecincts() {
         this.showLoading()
         this.precincts = []
         if (this.provinceId && this.districtId) {
            service
               .getPrecinct({ provinceId: this.provinceId, districtId: this.districtId })
               .then((response) => {
                  if (response.errorCode === '0') {
                     this.precincts = response.wsResponse
                     this.loadScript('js/select2.js')
                  } else {
                     globalStore.dispatch('setNotification', { content: response.message ?? response.userMsg })
                  }
               })
               .finally(() => {
                  this.hideLoading()
               })
         }
      },
      onOrder(invalid) {
         if (invalid) {
            return
         }
         this.showLoading()
         var dataPost = {
            phoneNumber: this.phoneNumber,
            districtCode: this.districtId,
            address: this.note,
            photo_address: '',
            photo_house: '',
            provinceCode: this.provinceId,
            idProduct: this.name,
            customName: this.fullName,
            time: '1',
            villageCode: this.precinctId
         }
         service
            .orderFtth(dataPost)
            .then((response) => {
               if (response.errorCode === '0') {
                  var infoCusFtthPackage = {
                     name: this.name,
                     fullName: this.fullName,
                     doB: this.doB,
                     phoneNumber: this.phoneNumber,
                     address: this.note
                  }
                  store.commit('setInfoCusFtthPackage', infoCusFtthPackage)
                  this.$router.push('/ftth/ftth-order-success')
               } else {
                  globalStore.dispatch('setNotification', { content: response.message ?? response.userMsg })
               }
            })
            .finally(() => {
               this.hideLoading()
            })
      }
   }
}
</script>
