<template>
   <div class="main-container">
      <div class="nav-bar js-nav-bar" @click="goBack">
         <div class="nav-bar__inner">
            <span class="nav-bar__icon"><i class="icon-fonts icon-fonts--arrow-left"></i></span>
            <h3 class="nav-bar__title">{{ $t('mobile.charge_history.bill_detail') }}</h3>
         </div>
      </div>
      <div class="main-content">
         <div class="bill-detail">
            <p class="time-charge">{{ startDate }} - {{ endDate }}</p>
            <div class="bill-chart" v-if="chartData.length > 1">
               <GChart type="LineChart" :data="chartData" :options="chartOptions" />
               <ul class="bill-chart__list">
                  <li class="bill-chart__item" @click="loadChart(1)">
                     <span class="bill-chart__dot call"></span
                     ><span class="bill-chart__value">{{ $t('mobile.charge_history.calls') }}</span>
                  </li>
                  <li class="bill-chart__item" @click="loadChart(2)">
                     <span class="bill-chart__dot data"></span
                     ><span class="bill-chart__value">{{ $t('mobile.charge_history.data') }}</span>
                  </li>
               </ul>
            </div>
            <ul class="bill-detail__list">
               <li class="bill-detail__item top" @click="redirectTo('total-charge')">
                  <div class="bill-detail__detail">
                     <span class="bill-detail__icon"><i class="icon-fonts icon-fonts--charge"></i></span>
                     <div class="bill-detail__info">
                        <h5 class="bill-detail__name">
                           <a class="bill-detail__name-link" href="javascript:void(0)">{{
                              $t('mobile.charge_history.debt_charges')
                           }}</a>
                        </h5>
                        <span class="bill-detail__kip"
                           >{{ Number(debtCharges).toLocaleString('en-US', { maximumFractionDigits: 0 }) }}
                           {{ $t('unit.kip') }}</span
                        >
                     </div>
                  </div>
                  <span class="bill-detail__action"><i class="icon-fonts icon-fonts--arrow-right"></i></span>
               </li>
               <li class="bill-detail__item" @click="redirectTo('calls')">
                  <div class="bill-detail__detail">
                     <span class="bill-detail__icon"><i class="icon-fonts icon-fonts--call"></i></span>
                     <div class="bill-detail__info">
                        <h5 class="bill-detail__name">
                           <a class="bill-detail__name-link" href="javascript:void(0)">{{
                              $t('mobile.charge_history.call')
                           }}</a>
                        </h5>
                        <span class="bill-detail__kip"
                           >{{ Number(callFee).toLocaleString('en-US', { maximumFractionDigits: 0 }) }}
                           {{ $t('unit.kip') }}</span
                        >
                     </div>
                  </div>
                  <span class="bill-detail__action"><i class="icon-fonts icon-fonts--arrow-right"></i></span>
               </li>
               <li class="bill-detail__item" @click="redirectTo('sms')">
                  <div class="bill-detail__detail">
                     <span class="bill-detail__icon"><i class="icon-fonts icon-fonts--sms"></i></span>
                     <div class="bill-detail__info">
                        <h5 class="bill-detail__name">
                           <a class="bill-detail__name-link" href="javascript:void(0)">{{
                              $t('mobile.charge_history.sms')
                           }}</a>
                        </h5>
                        <span class="bill-detail__kip"
                           >{{ Number(smsFee).toLocaleString('en-US', { maximumFractionDigits: 0 }) }}
                           {{ $t('unit.kip') }}</span
                        >
                     </div>
                  </div>
                  <span class="bill-detail__action"><i class="icon-fonts icon-fonts--arrow-right"></i></span>
               </li>
               <li class="bill-detail__item" @click="redirectTo('data')">
                  <div class="bill-detail__detail">
                     <span class="bill-detail__icon"><i class="icon-fonts icon-fonts--data"></i></span>
                     <div class="bill-detail__info">
                        <h5 class="bill-detail__name">
                           <a class="bill-detail__name-link" href="javascript:void(0)">{{
                              $t('mobile.charge_history.data')
                           }}</a>
                        </h5>
                        <span class="bill-detail__kip"
                           >{{ Number(dataFee).toLocaleString('en-US', { maximumFractionDigits: 0 }) }}
                           {{ $t('unit.kip') }}</span
                        >
                     </div>
                  </div>
                  <span class="bill-detail__action"><i class="icon-fonts icon-fonts--arrow-right"></i></span>
               </li>
               <li class="bill-detail__item" @click="redirectTo('vas-services')">
                  <div class="bill-detail__detail">
                     <span class="bill-detail__icon"><i class="icon-fonts icon-fonts--squares"></i></span>
                     <div class="bill-detail__info">
                        <h5 class="bill-detail__name">
                           <a class="bill-detail__name-link" href="javascript:void(0)">{{
                              $t('mobile.charge_history.other_vas_services')
                           }}</a>
                        </h5>
                        <span class="bill-detail__kip"
                           >{{ Number(vasFee).toLocaleString('en-US', { maximumFractionDigits: 0 }) }}
                           {{ $t('unit.kip') }}</span
                        >
                     </div>
                  </div>
                  <span class="bill-detail__action"><i class="icon-fonts icon-fonts--arrow-right"></i></span>
               </li>
            </ul>
         </div>
      </div>
   </div>
</template>
<script>
import { GChart } from 'vue-google-charts'
import BaseComponent from '@/mixins/BaseComponent'
import { DATE_FORMAT } from './../../constants/data'
import service from './../../services/MobileService'

export default {
   name: 'BillPostpaid',
   components: { GChart },
   mixins: [BaseComponent],
   data() {
      return {
         startDate: this.getDateFromMiliseconds(this.$route.params.startTime, DATE_FORMAT),
         endDate: this.getDateFromMiliseconds(this.$route.params.endTime, DATE_FORMAT),
         data: [],
         callData: [],
         debtCharges: 0,
         callFee: 0,
         smsFee: 0,
         dataFee: 0,
         vasFee: 0,
         callRecords: 0,
         smsRecords: 0,
         dataRecords: 0,
         chartData: [],
         chartOptions: {
            title: '(' + this.$i18n.t('unit.upper_kip') + ')',
            titleTextStyle: {
               bold: false,
               fontSize: 14,
               color: '#34425B'
            },
            colors: ['#00A2FF'],
            legend: {
               position: 'none'
            },
            chartArea: {
               bottom: 20,
               top: 30,
               left: 50,
               right: 50
            }
         }
      }
   },
   created() {
      this.setTitle(this.$i18n.t('mobile.charge_history.postpaid_information'))
      this.loadData()
   },
   methods: {
      loadData() {
         let loadData = service.getPosPostageForGraph({ postType: '3' }).then((response) => {
            this.data = response.wsResponse
         })

         let loadCallData = service.getPosPostageForGraph({ postType: '0' }).then((response) => {
            this.callData = response.wsResponse
         })

         let loadPostageInfo = service
            .getPostageInfo({
               startDate: this.$route.params.startTime,
               endDate: this.$route.params.endTime,
               type: '',
               subType: 2
            })
            .then((response) => {
               this.debtCharges =
                  response.wsResponse.callFee +
                  response.wsResponse.smsFee +
                  response.wsResponse.dataFee +
                  response.wsResponse.vasFee
               this.callFee = response.wsResponse.callFee
               this.smsFee = response.wsResponse.smsFee
               this.dataFee = response.wsResponse.dataFee
               this.vasFee = response.wsResponse.vasFee
               this.callRecords = response.wsResponse.callRc
               this.smsRecords = response.wsResponse.smsRc
               this.dataRecords = response.wsResponse.dataRc
            })

         this.showLoading()
         Promise.allSettled([loadData, loadCallData, loadPostageInfo]).finally(() => {
            this.loadChart(1)
            this.hideLoading()
         })
      },
      loadChart(chartType) {
         let dataArr = []
         let total = 0
         if (chartType == 1) {
            this.chartData = [['Month', 'Call']]
            this.chartOptions.colors = ['#71d875']
            this.chartOptions.title = `(${this.$i18n.t('unit.upper_kip')})`
            if (this.callData.length > 0) {
               for (let i = 0; i < this.data.length; i++) {
                  dataArr.push([
                     this.callData[i]['time'].split('-').reverse().join('/'),
                     this.callData[i]['totalCharge']
                  ])
               }
            }
         } else {
            this.chartData = [['Month', 'Data']]
            this.chartOptions.colors = ['#00a2ff']
            this.chartOptions.title = `(${this.$i18n.t('unit.mb')})`
            if (this.data.length > 0) {
               for (let i = 0; i < this.data.length; i++) {
                  dataArr.push([this.data[i]['time'].split('-').reverse().join('/'), this.data[i]['totalCharge']])
               }
            }
         }
         total = dataArr.reduce(function (total, item) {
            return total + item[1]
         }, 0)
         if (total === 0) {
            this.chartOptions['vAxis'] = {
               viewWindow: {
                  min: 0,
                  max: 1
               }
            }
         }

         this.chartData = this.chartData.concat(dataArr)
      },
      redirectTo(path) {
         if (path == 'total-charge') {
            this.$router.push(
               `/mobile/charge-history/${path}/${this.$route.params.startTime}/${this.$route.params.endTime}`
            )
         } else {
            let numberOfRecords = 0
            switch (path) {
               case 'calls':
                  numberOfRecords = this.callRecords
                  break
               case 'data':
                  numberOfRecords = this.dataRecords
                  break
               case 'sms':
                  numberOfRecords = this.smsRecords
                  break
            }
            this.$router.push(
               `/mobile/charge-history/${path}/${this.$route.params.startTime}/${this.$route.params.endTime}/${numberOfRecords}`
            )
         }
      }
   }
}
</script>
