<template>
   <div class="main-container">
      <div class="nav-bar" v-if="!isAuthenticated">
         <div class="nav-home none">
            <div class="nav-home__btn">
              <button class="button button--transparent"
                  @click="redirectToRoute('/ftth/login')">{{ $t('buttons.signin') }}
              </button>
            </div>
            <div class="nav-home__logo"><a class="nav-home__logo-link" href="#">
               <img src="@/assets/images/logo-new.svg" alt="img"></a>
            </div>
         </div>
      </div>
      <div class="nav-bar nav-bar--full" v-if="isAuthenticated">
         <div class="nav-bar__inner">
            <span class="nav-bar__icon"><i class="icon-fonts icon-fonts--arrow-left" @click="back"></i></span>
            <h3 class="nav-bar__title" @click="back">{{ $t('ftth.ftth_service') }}</h3>
            <a class="nav-bar__action" href="javascript:void(0)" @click="redirectTo"
               ><img src="@/assets/images/ic-avatar.svg" alt="img"
            /></a>
         </div>
      </div>
      <div class="box-ftth" v-if="isAuthenticated">
         <div class="box-ftth__inner">
            <div class="box-ftth__top">
               <h4 class="box-ftth__sub">{{ data.custName }}</h4>
               <p class="box-ftth__acc">{{ $t('ftth.account_name') }}: {{ data.accountNo }}</p>
            </div>
            <ul class="box-ftth__list">
               <li class="box-ftth__item">
                  <span class="box-ftth__name">{{ $t('ftth.contract_id') }}</span
                  ><span class="box-ftth__value">{{ data.contractNo }}</span>
               </li>
               <li class="box-ftth__item">
                  <span class="box-ftth__name">{{ $t('ftth.package') }}</span
                  ><span class="box-ftth__value">{{ data.productCode }}</span>
               </li>
               <li class="box-ftth__item">
                  <span class="box-ftth__name">{{ $t('ftth.package_fee') }}</span
                  ><span class="box-ftth__value">{{ data.monthlyFee }} {{ $t('unit.kip_month') }}</span>
               </li>
               <li class="box-ftth__item">
                  <span class="box-ftth__name">{{ $t('ftth.start_date') }}</span
                  ><span class="box-ftth__value">{{ getStartDateDate() }}</span>
               </li>
               <li class="box-ftth__item">
                  <span class="box-ftth__name">{{ $t('ftth.end_date') }}</span
                  ><span class="box-ftth__value">{{ getEndDate() }}</span>
               </li>
            </ul>
         </div>
      </div>
      <div class="main-content">
         <div class="ftth-service">
            <div class="feature-favourite">
               <ul class="feature-favourite__list">
                  <li
                     class="feature-favourite__item"
                     v-for="func in listFunctions"
                     @click="redirectToFunc(func)"
                     :key="func.name"
                  >
                     <a class="feature-favourite__link" href="javascript:void(0)"
                        ><span class="feature-favourite__icon"><img :src="func.icon" alt="img" /></span>
                        <h5 class="feature-favourite__name">{{ func.name }}</h5>
                     </a>
                  </li>
               </ul>
            </div>
         </div>
      </div>
      <confirmation-modal :data="confirmationData" :onConfirm="onConfirmLogout" />
   </div>
</template>
<script>
import BaseComponent from '@/mixins/BaseComponent'
import service from './../../services/FTTHService'
import { FTTHFuctionList, DATE_FORMAT, FTTHFuctionListLaoApp } from './../../constants/data'
import LocalStorage from '@/ultils/local-storage'
import { callNative } from '@/ultils/call-native'
import ConfirmationModal from '@/components/modal/ConfirmationModal'
import store from '@/plugins/vuex/store'

export default {
   name: 'Service',
   components: {
      ConfirmationModal
   },
   mixins: [BaseComponent],
   data() {
      return {
         data: {},
         listFunctions: FTTHFuctionList,
         confirmationData: {},
         isAuthenticated: true
      }
   },
   created() {
      this.setTitle(this.$i18n.t('ftth.ftth_service'))
      this.isAuthenticated = LocalStorage.getItem('ftth_account') ? true : false
      if(this.isAuthenticated){
         this.loadData()
      }
      LocalStorage.removeItem('click_ucare_ftth')
   },
   methods: {
      loadData() {
         let appCode = LocalStorage.getItem('appCode')
         if (appCode === 'LaoApp') {
            this.listFunctions = FTTHFuctionListLaoApp
         }
         this.showLoading()
         service
            .getFtthAccountInfo({ ftthAccount: LocalStorage.getItem('ftth_account') })
            .then((response) => {
               this.data = response.wsResponse
               LocalStorage.setItem('ftth_account_info', JSON.stringify(response.wsResponse))
            })
            .finally(() => {
               this.hideLoading()
            })
      },
      getStartDateDate() {
         if (this.data.startDate) {
            return this.getDateFromMiliseconds(this.data.startDate, DATE_FORMAT)
         } else {
            return ''
         }
      },
      getEndDate() {
         if (this.data.expireDate) {
            if (typeof this.data.expireDate === 'number') {
               return this.getDateFromMiliseconds(this.data.expireDate, DATE_FORMAT)
            } else {
               return this.data.expireDate
            }
         } else {
            return ''
         }
      },
      redirectToFunc(func) {
         if (func.name === this.$i18n.t('home.functions.pay_monthly_fee')) {
            callNative('open_pay_monthly_fee', LocalStorage.getItem('ftth_account'))
         } else if (func.name === this.$i18n.t('home.functions.speedtest')) {
            callNative('open_speed_test', '')
         // } else if (func.name === this.$i18n.t('home.functions.report_error')) {
         //    callNative('open_report_error', LocalStorage.getItem('ftth_account'))
            // this.$router.push(func.url)
         //} else if (func.name === this.$i18n.t('home.functions.add_100_call_minutes')) {
         //   callNative('open_add100', '')
         }else if (func.name === this.$i18n.t('home.functions.promotion_info')) {
            callNative('open_promotion_info', LocalStorage.getItem('ftth_account'))
         }else if (func.name === this.$i18n.t('ucare.title')) {
            LocalStorage.setItem('click_ucare_ftth', true)
            this.$router.push(func.url)
         }else if (func.name === this.$i18n.t('home.functions.change_product')) {
           let step1={
                  newProductCode:"",
                  newSubType:"",
                  listPackage:[],
                  listSubType:[]
            }
            let step3={
                  transId:"",
                  timestamp:"",
                  transDate:"",
                  serviceCode:"",
                  account:""
               }    
            store.commit('setStep1', step1)
            store.commit('setStep3', step3)
            this.$router.push(func.url)
         } else {
            this.$router.push(func.url)
         }
      },
      getEwalletInfo() {
         this.showLoading()
         let userInfo = JSON.parse(LocalStorage.getItem('user_info'))
         service
            .getEwalletInfo({ isdnEwallet: userInfo.isdn })
            .then((response) => {
               if (response.errorCode == '0') {
                  if (response.wsResponse.responseCode === '00000') {
                     window.location.href = `https://webview.unitel.com.la:9443/#/internet-bill/start?roleId=${
                        this.data.roleId
                     }&lang=${LocalStorage.getItem('lang')}&sender=${this.data.custName}&phoneNumber=${
                        this.data.isdn
                     }&token=`
                  } else if (response.wsResponse.responseCode === '10114') {
                     this.$store.dispatch('setNotification', {
                        content: this.$i18n.t('errors.locked_account')
                     })
                  } else if (
                     response.wsResponse.responseCode === '10116' ||
                     response.wsResponse.responseCode === '10117'
                  ) {
                     window.location.href = 'https://webview.unitel.com.la:9443/#/sign-up/startC'
                  } else {
                     this.$store.dispatch('setNotification', {
                        content: this.$i18n.t('ftth.view_invoice.cannot_access_wallet')
                     })
                  }
               } else {
                  this.$store.dispatch('setNotification', { content: response.message ?? response.userMsg })
               }
            })
            .finally(() => {
               this.hideLoading()
            })
      },
      redirectTo() {
         this.showLoading()
         service
            .getListFtthAccountInfo()
            .then((response) => {
               if (response.errorCode === '0') {
                  let result = response.wsResponse ?? []
                  if (result.length > 1) {
                     this.$router.push('/ftth/account-list')
                     this.hideLoading()
                  } else {
                     this.confirmationData = { content: this.$i18n.t('ftth.logout_confirm') }
                     this.hideLoading()
                  }
               } else {
                  this.$store.dispatch('setNotification', { content: response.message ?? response.userMsg })
                  this.hideLoading()
               }
            })
            .catch(() => {
               this.hideLoading()
            })
      },
      onConfirmLogout() {
         callNative('logoutFtth', '')
         LocalStorage.removeItem('ftth_user_info')
         LocalStorage.removeItem('ftth_account')
         LocalStorage.removeItem('ftth_account_login')
         LocalStorage.removeItem('click_ucare_ftth')
         LocalStorage.setItem('onConfirmLogout', true)
         this.$router.push('/ftth/login')
      },
      redirectToRoute(route){
         this.$router.push(route)
      },
      back() {
         // let appCode = LocalStorage.getItem('appCode')
         // if (appCode !== 'LaoApp') {
         //    callNative('close_webview', '')
         // }
         callNative('close_webview', '')
         LocalStorage.removeItem('click_ucare_ftth')
         this.$router.push('/home')
      }
   }
}
</script>
