<template>
   <div>
      <div class="main-container">
         <div class="main-content">
            <div class="fullscreen">
               <!--<StreamBarcodeReader @decode="onDecode"></StreamBarcodeReader>-->
               <qrcode-stream @decode="onDecode" @init="onInit" :track="paintOutline" :torch="torchActive">
                  <div class="nav-bar js-nav-bar" @click="goBack()">
                     <div class="nav-bar__inner">
                        <span class="nav-bar__icon nav-bar__icon_scan"
                           ><i class="icon-fonts icon-fonts--arrow-left"></i
                        ></span>
                        <h3 class="nav-bar__title">{{ $t('mobile.recharge_scratch_card.title') }}</h3>
                     </div>
                  </div>
                  <div class="qr-frame">
                     <img class="qr-frame-img" src="@/assets/images/qr-frame.png" />
                     <div class="qr-flash-instruction">Turn one Flash light</div>
                     <img
                        v-if="torchActive"
                        class="qr-flash-icon"
                        src="@/assets/images/bolt-icon.svg"
                        @click="torchActive = !torchActive"
                     />
                     <img
                        v-else
                        class="qr-flash-icon"
                        src="@/assets/images/bolt-slash.svg"
                        @click="torchActive = !torchActive"
                     />
                  </div>
                  <div class="qr-btn-group">
                     <div class="qr-btn qr-btn--active">Scan QR</div>
                     <div class="qr-btn" @click="redirectToScratchCard">Manual input</div>
                  </div>
                  <!-- <div class="loading-indicator" v-if="loading">Loading...</div> -->
               </qrcode-stream>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
import BaseComponent from '@/mixins/BaseComponent'
//import { StreamBarcodeReader } from 'vue-barcode-reader'
// import { makeCall } from '@/ultils/make-call'
import store from '@/plugins/vuex/store'
import LocalStorage from '@/ultils/local-storage'
import { QrcodeStream } from 'vue-qrcode-reader'

export default {
   name: 'Scan',
   mixins: [BaseComponent],
   components: { QrcodeStream },
   data() {
      return {
         camera: 'auto',
         loading: false,
         torchActive: false
      }
   },
   methods: {
      paintOutline(detectedCodes, ctx) {
         for (const detectedCode of detectedCodes) {
            const [firstPoint, ...otherPoints] = detectedCode.cornerPoints

            ctx.strokeStyle = '#ee4019'

            ctx.beginPath()
            ctx.moveTo(firstPoint.x, firstPoint.y)
            for (const { x, y } of otherPoints) {
               ctx.lineTo(x, y)
            }
            ctx.lineTo(firstPoint.x, firstPoint.y)
            ctx.closePath()
            ctx.stroke()
         }
      },

      async onDecode(result) {
         console.log(result)
         store.state.qrMode = 'scan'
         if (LocalStorage.getItem('monitor_redirect_scan') == 'RechargeScratchCard') {
            store.commit('setTextScan', result)
            this.$router.go(-1)
         } else {
            // makeCall(result)
            store.commit('setTextScan', result)
            this.redirectToScratchCard()
         }
         // this.pause()
         // await this.timeout(500)
         // this.unpause()
      },

      unpause() {
         this.camera = 'auto'
      },

      pause() {
         this.camera = 'off'
      },

      timeout(ms) {
         return new Promise((resolve) => {
            window.setTimeout(resolve, ms)
         })
      },
      async onInit(promise) {
         try {
            this.loading = true
            await promise
         } catch (error) {
            if (error.name === 'NotAllowedError') {
               this.error = 'ERROR: you need to grant camera access permission'
            } else if (error.name === 'NotFoundError') {
               this.error = 'ERROR: no camera on this device'
            } else if (error.name === 'NotSupportedError') {
               this.error = 'ERROR: secure context required (HTTPS, localhost)'
            } else if (error.name === 'NotReadableError') {
               this.error = 'ERROR: is the camera already in use?'
            } else if (error.name === 'OverconstrainedError') {
               this.error = 'ERROR: installed cameras are not suitable'
            } else if (error.name === 'StreamApiNotSupportedError') {
               this.error = 'ERROR: Stream API is not supported in this browser'
            } else if (error.name === 'InsecureContextError') {
               this.error =
                  'ERROR: Camera access is only permitted in secure context. Use HTTPS or localhost rather than HTTP.'
            } else {
               this.error = `ERROR: Camera error (${error.name})`
            }
         } finally {
            // this.camera === 'off'
            this.loading = false
         }
      },
      redirectToScratchCard() {
         store.state.qrMode = 'manual'
         this.$router.push('/mobile/recharge-scratch-card')
      }
   }
}
</script>
<style scoped>
.nav-bar__icon_scan {
   left: 30px !important;
}

.qr-frame {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   text-align: center;
   position: absolute;
   top: 30%;
}

.qr-frame-img {
   width: 70%;
}

.qr-flash-instruction {
   margin-top: 40px;
   font-size: 16px;
   color: white;
}

.qr-flash-icon {
   margin-top: 20px;
   width: 24px;
   height: 24px;
   object-fit: cover;
}

.qr-btn-group {
   display: flex;
   flex-direction: row;
   justify-content: space-evenly;
   position: absolute;
   bottom: 20px;
   width: 100%;
}
.qr-btn {
   height: 40px;
   width: 45%;
   border-radius: 16px;
   display: flex;
   justify-content: center;
   align-items: center;
   color: #fff;
}

.qr-btn--active {
   border: 1px solid #fff;
}
</style>
