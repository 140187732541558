import Vue from 'vue'
import Router from 'vue-router'
import { isAuthenticated, checkToSetAuthenticate } from '@/ultils/user'
import HomeRouters from '@/modules/home'
import MobileRouters from '@/modules/mobile'
import UcareRouters from '@/modules/ucare'
import FTTHRouters from '@/modules/ftth'
import Convert from '@/modules/convert'
import { callNative } from '@/ultils/call-native'

Vue.use(Router)

let passRoutes = [
    '/login',
    '/digital-service',
    '/mobile/home',
    // '/ucare/customer-service/home',
    // '/ucare/customer-service/intro',
    '/ftth/login',
    // '/ftth/service'
    '/callback',
    '/mobile/callback'
]

let routes = [].concat(HomeRouters).concat(MobileRouters).concat(UcareRouters).concat(FTTHRouters).concat(Convert)

const router = new Router({
    routes,
    scrollBehavior() {
        window.scroll({ top: 0, left: 0, behavior: 'smooth' })
    }
})

router.beforeEach(async(to, from, next) => {
    if (await checkToSetAuthenticate(to.path, to.query)) {
        console.log('checkToSetAuthenticate')
        next()
    } else {
        if (passRoutes.includes(to.path) || isAuthenticated(to.path)) {
            next()
        } else {
            if (!to.path.includes("/ftth")) {
                if(to.path !== '/home'){
                    callNative('login', '')
                }
                next({ path: '/login' })
            } else {
                next({ path: '/ftth/login' })
            }
        }
    }
})

export default router