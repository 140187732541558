<template>
   <div class="main-container">
      <div class="nav-bar js-nav-bar" @click="goBack">
         <div class="nav-bar__inner">
            <span class="nav-bar__icon"><i class="icon-fonts icon-fonts--arrow-left"></i></span>
            <h3 class="nav-bar__title">{{ $t('home.functions.exchange_to_service') }}</h3>
         </div>
      </div>
      <div class="main-content">
         <div class="credit-service credit-service--pack">
            <div class="exchange-point">
               <h4 class="exchange-point__name">{{ $t('ucare.exchange_point') }}</h4>
               <span class="exchange-point__value">{{
                  Number(exchangePoint).toLocaleString('en-US', { maximumFractionDigits: 0 })
               }}</span>
            </div>
            <!-- <ul class="credit-service__list">
               <li class="credit-service__item" v-for="gift in listGifts" :key="gift.giftId">
                  <div class="credit-service__item-detail" @click="redirectToDetail(gift)">
                     <div class="credit-service__item-images">
                        <img :src="'data:image/jpeg;base64,' + gift.imageBase64" alt="img" /><span
                           class="credit-service__item-tag heart"
                           ><span>{{ gift.giftCode }}</span></span
                        >
                     </div>
                     <div class="credit-service__item-info">
                        <div class="credit-service__item-top">
                           <h5 class="credit-service__item-name">
                              <a class="credit-service__item-link" href="javascript:void(0)">{{ gift.giftName }}</a>
                           </h5>
                        </div>
                        <p class="credit-service__item-des">
                           {{ Number(gift.point).toLocaleString('en-US', { maximumFractionDigits: 0 }) }}
                           {{ $t('unit.upoint') }}
                        </p>
                        <p class="credit-service__item-des">{{ gift.description }}</p>
                     </div>
                  </div>
                  <div class="credit-service__item-btn" @click="openConfirmExchange(gift)">
                     <button class="button button--primary button--small">
                        {{ $t('ucare.exchange') }}
                     </button>
                  </div>
               </li>
            </ul> -->
            <ul class="service-deal__list">
               <li class="service-deal__item" v-for="gift in listGifts" :key="gift.giftId">
                  <div class="service-deal__images" @click="redirectToDetail(gift)">
                     <!-- <h4 class="service-deal__sub">{{ gift.giftName }}</h4> -->
                     <img :src="'data:image/jpeg;base64,' + gift.imageBase64" alt="img" />
                     <div class="service-deal__bg">
                        <span class="service-deal__icon"><i class="icon-fonts icon-fonts--price"></i></span
                        ><span class="service-deal__value">
                           {{ Number(gift.point).toLocaleString('en-US', { maximumFractionDigits: 0 }) }}
                           {{ $t('unit.upoint') }}
                        </span>
                     </div>
                  </div>
                  <div class="service-deal__info">
                     <h5 class="service-deal__name">
                        <a class="service-deal__name-link" @click="redirectToDetail(gift)">{{ gift.giftName }}</a>
                     </h5>
                     <p class="service-deal__des">{{ gift.description }}</p>
                     <div class="service-deal__btn" @click="openConfirmExchange(gift)">
                        <button class="button button--primary button--small">{{ $t('ucare.exchange') }}</button>
                     </div>
                  </div>
               </li>
            </ul>
         </div>
      </div>
      <confirmation-modal :data="confirmationData" :onConfirm="onConfirm" />
   </div>
</template>
<script>
import BaseComponent from '@/mixins/BaseComponent'
import service from './../../services/UCareService'
import LocalStorage from '@/ultils/local-storage'
import ConfirmationModal from '@/components/modal/ConfirmationModal'

export default {
   name: 'DataDeals',
   components: {
      ConfirmationModal
   },
   mixins: [BaseComponent],
   data() {
      return {
         exchangePoint: 0,
         listGifts: [],
         limit: 50,
         page: 1,
         confirmationData: {},
         simType: '1'
      }
   },
   created() {
      this.setTitle(this.$i18n.t('ucare.data_deals.title'))
      // this.simType = LocalStorage.getItem('simType');
      this.loadData()
   },
   methods: {
      loadData() {
         service.getAccountPointInfo().then((response) => {
            if (response.code === '000') {
               let result = response.listAccountPoint ?? []
               this.exchangePoint = result.reduce((total, acc) => {
                  if (acc.pointType === 2) {
                     return total + acc.pointValue
                  } else {
                     return total
                  }
               }, 0)
            } else {
               // this.$store.dispatch('setNotification', {
               //    content: response.message
               // })
            }
         })
         let ucareFtth = LocalStorage.getItem('click_ucare_ftth')
         if (ucareFtth) {
            service
               .getTelecomGift({ giftType: '2', page: this.page, limit: this.limit })
               .then((response) => {
                  // debugger // eslint-disable-line no-debugger
                  if (response.code === '000') {
                     this.listGifts = (response.listGift ?? []).filter((gift) => {
                        return gift.giftCode !== 'DATA' && gift.giftCode !== 'SMS' && gift.giftCode !== 'VOICE'
                     })
                  } else {
                     this.$store.dispatch('setNotification', {
                        content: response.message
                     })
                  }
               })
               .finally(() => {
                  this.hideLoading()
               })
         } else {
            service.getSubType({ gameCode: 'LUCKYGAME_2018' }).then((response) => {
               if (response.errorCode === '0') {
                  this.simType = response.wsResponse.simType
                  service
                     .getTelecomGift({ giftType: this.simType, page: this.page, limit: this.limit })
                     .then((response) => {
                        // debugger // eslint-disable-line no-debugger
                        if (response.code === '000') {
                           this.listGifts = (response.listGift ?? []).filter((gift) => {
                              return gift.giftCode !== 'DATA' && gift.giftCode !== 'SMS' && gift.giftCode !== 'VOICE'
                           })
                        } else {
                           this.$store.dispatch('setNotification', {
                              content: response.message
                           })
                        }
                     })
                     .finally(() => {
                        this.hideLoading()
                     })
               }
            })
         }
         this.showLoading()
         Promise.allSettled([]).finally(() => {
            this.hideLoading()
         })
      },
      openConfirmExchange(gift) {
         let userFtth = LocalStorage.getItem('click_ucare_ftth')
         if (userFtth) {
            this.redirectToDetail(gift)
         } else {
            this.confirmationData = {
               content: this.$i18n
                  .t('ucare.data_deals.confirm')
                  .replace('{0}', gift.giftName)
                  .replace('{1}', Number(gift.point).toLocaleString('en-US', { maximumFractionDigits: 0 })),
               confirmedData: gift
            }
         }
      },
      onConfirm(gift) {
         let userInfo = JSON.parse(LocalStorage.getItem('user_info')) ?? {}
         let data = {
            giftId: gift.giftId.toString(),
            pointAmount: gift.point,
            pointId: gift.giftId.toString(),
            amountGift: gift.giftCost,
            datatype: gift.giftCode,
            transferType: 'POINT',
            staffUser: userInfo['isdn'],
            amount: 1
         }
         this.showLoading()
         service
            .redeemPoint(data)
            .then((response) => {
               if (response.errorCode === '0') {
                  this.$store.dispatch('setNotification', {
                     content: this.$i18n
                        .t('ucare.exchange_message')
                        .replace('{0}', gift.giftName)
                        .replace('{1}', Number(gift.point).toLocaleString('en-US', { maximumFractionDigits: 0 }))
                  })
               } else {
                  this.$store.dispatch('setNotification', { content: response.message ?? response.userMsg })
               }
            })
            .finally(() => {
               this.hideLoading()
            })
      },
      redirectToDetail(gift) {
         let data = {
            gift: gift,
            exchangePoint: this.exchangePoint
         }
         LocalStorage.setItem('data_deal', JSON.stringify(data))
         this.$router.push(`/ucare/data-deals/detail`)
      }
   }
}
</script>
