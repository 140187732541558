<template>
   <div class="main-container">
      <div class="nav-bar js-nav-bar" @click="goBack">
         <div class="nav-bar__inner">
            <span class="nav-bar__icon"><i class="icon-fonts icon-fonts--arrow-left"></i></span>
            <h3 class="nav-bar__title">{{ $t('ftth.precharge_history.title') }}</h3>
         </div>
      </div>
      <div class="main-content">
         <div class="precharge-history">
            <ul class="precharge-history__list">
               <li class="precharge-history__item">
                  <span class="precharge-history__name">{{ $t('ftth.precharge_history.id_precharge') }}</span
                  ><span class="precharge-history__value">{{ data.chargeCode }}</span>
               </li>
               <li class="precharge-history__item">
                  <span class="precharge-history__name">{{ $t('ftth.precharge_history.data_precharge') }}</span
                  ><span class="precharge-history__value">{{ data.lastUpdateDate }}</span>
               </li>
               <li class="precharge-history__item">
                  <span class="precharge-history__name">{{ $t('ftth.precharge_history.date_end') }}</span
                  ><span class="precharge-history__value">{{ data.expiredDate }}</span>
               </li>
               <li class="precharge-history__item">
                  <span class="precharge-history__name">{{ $t('ftth.precharge_history.total_money') }}</span
                  ><span class="precharge-history__value">{{ data.chargeTotal }}</span>
               </li>
               <li class="precharge-history__item">
                  <span class="precharge-history__name">{{ $t('ftth.precharge_history.promotion') }}</span
                  ><span class="precharge-history__value">{{ data.discount }}</span>
               </li>
               <li class="precharge-history__item">
                  <span class="precharge-history__name">{{ $t('ftth.precharge_history.program_end') }}</span
                  ><span class="precharge-history__value">{{ data.expDiscountDate }}</span>
               </li>
            </ul>
         </div>
      </div>
   </div>
</template>
<script>
import BaseComponent from '@/mixins/BaseComponent'
import service from './../../services/FTTHService'
import LocalStorage from '@/ultils/local-storage'

export default {
   name: 'PrechargeHistory',
   mixins: [BaseComponent],
   components: {},
   data() {
      return {
         data: {
            chargeCode: '',
            chargeMonth: 0,
            expiredDate: '',
            chargeTotal: '',
            discount: '',
            isdn: '',
            lastPaymentAmount: '',
            pricePackage: '',
            lastUpdateDate: '',
            subId: 0,
            contractId: 0,
            expDiscountDate: ''
         }
      }
   },
   created() {
      this.setTitle(this.$i18n.t('ftth.precharge_history.title'))
      this.getData()
   },
   methods: {
      getData() {
         this.showLoading()

         service
            .getFtthPrepaidInfo({ ftthAccount: LocalStorage.getItem('ftth_account') })
            .then((response) => {
               if (response.wsResponse) {
                  this.data = response.wsResponse
               }
               this.hideLoading()
            })
            .catch(() => {
               this.hideLoading()
            })
      }
   }
}
</script>
