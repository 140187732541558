<template>
   <div class="main-container">
      <div class="nav-bar js-nav-bar" @click="goBack">
         <div class="nav-bar__inner">
            <span class="nav-bar__icon"><i class="icon-fonts icon-fonts--arrow-left"></i></span>
            <h3 class="nav-bar__title">{{ $t('unit.date') }}: {{ dateStr }}</h3>
         </div>
      </div>
      <div class="main-content">
         <div class="history-detail">
            <div class="history-detail__box">
               <div class="history-detail__top">
                  <h5 class="history-detail__sub-small">{{ $t('ftth.receip_history.total_fee') }}</h5>
                  <p class="history-detail__price">{{ data.totalCharge }} {{ $t('unit.upper_kip') }}</p>
               </div>
               <ul class="history-detail__list">
                  <li class="history-detail__item">
                     <span class="history-detail__name">{{ $t('ftth.receip_history.invoice_no') }}</span
                     ><span class="history-detail__value">{{ data.no }}</span>
                  </li>
                  <li class="history-detail__item">
                     <span class="history-detail__name">{{ $t('ftth.receip_history.internet') }}</span
                     ><span class="history-detail__value">{{ data.totalCharge }} {{ $t('unit.upper_kip') }}</span>
                  </li>
                  <li class="history-detail__item">
                     <span class="history-detail__name">{{ $t('ftth.receip_history.adjustment') }}</span
                     ><span class="history-detail__value">{{ data.adjustment }} {{ $t('unit.upper_kip') }}</span>
                  </li>
                  <li class="history-detail__item">
                     <span class="history-detail__name">{{ $t('ftth.receip_history.debt') }}</span
                     ><span class="history-detail__value">{{ data.debt }} {{ $t('unit.upper_kip') }}</span>
                  </li>
                  <li class="history-detail__item">
                     <span class="history-detail__name">{{ $t('ftth.receip_history.discount') }}</span
                     ><span class="history-detail__value">{{ data.discount }} {{ $t('unit.upper_kip') }}</span>
                  </li>
               </ul>
            </div>
            <div class="history-detail__box">
               <div class="history-detail__top">
                  <h5 class="history-detail__sub">{{ $t('ftth.receip_history.calculation') }}</h5>
               </div>
               <ul class="history-detail__list">
                  <li class="history-detail__item">
                     <span class="history-detail__name">{{ $t('ftth.receip_history.charging') }}</span
                     ><span class="history-detail__value">{{ data.charging }} {{ $t('unit.upper_kip') }}</span>
                  </li>
                  <li class="history-detail__item">
                     <span class="history-detail__name">{{ $t('ftth.receip_history.discountb') }}</span
                     ><span class="history-detail__value">{{ data.discount }} {{ $t('unit.upper_kip') }}</span>
                  </li>
               </ul>
               <ul class="history-detail__list">
                  <li class="history-detail__item">
                     <span class="history-detail__name">{{ $t('ftth.receip_history.total_service') }}</span
                     ><span class="history-detail__value">{{ data.totalCharge }} {{ $t('unit.upper_kip') }}</span>
                  </li>
                  <li class="history-detail__item">
                     <span class="history-detail__name">{{ $t('ftth.receip_history.vat') }}</span
                     ><span class="history-detail__value">{{ data.vat }} {{ $t('unit.upper_kip') }}</span>
                  </li>
               </ul>
               <ul class="history-detail__list">
                  <li class="history-detail__item">
                     <span class="history-detail__name">{{ $t('ftth.receip_history.total') }}</span
                     ><span class="history-detail__value">{{ data.totalCharge }} {{ $t('unit.upper_kip') }}</span>
                  </li>
               </ul>
            </div>
         </div>
      </div>
   </div>
</template>
<script>
import BaseComponent from '@/mixins/BaseComponent'
import service from './../../services/FTTHService'
import LocalStorage from '@/ultils/local-storage'

export default {
   name: 'ReceiptHistory',
   mixins: [BaseComponent],
   components: {},
   data() {
      return {
         data: {
            totalCharge: '',
            no: '',
            adjustment: '',
            debt: '',
            discount: '',
            charging: '',
            vat: ''
         },
         dateStr: LocalStorage.getItem('billCycleDateStr')
      }
   },
   created() {
      this.setTitle(this.$i18n.t('ftth.receip_history.title'))
      this.getData()
   },
   methods: {
      getData() {
         this.showLoading()
         let ftthAccount = LocalStorage.getItem('ftth_account') //801119242
         var dataPost = {
            ftthAccount: ftthAccount
         }

         service
            .getFtthAccountInfo(dataPost)
            .then((response) => {
               if (response.wsResponse) {
                  let param = {
                     subId: response.wsResponse.subId, //544189
                     contractId: response.wsResponse.contractId, //544200
                     ftthAccount: ftthAccount,
                     billCycleDate: parseInt(LocalStorage.getItem('billCycleDate'))
                  }

                  service
                     .getViewFtthInvoiceStatement(param)
                     .then((response) => {
                        if (response.wsResponse) {
                           this.data.totalCharge = response.wsResponse.totCharge
                              ? Number(response.wsResponse.totCharge).toLocaleString('en-US', {
                                   maximumFractionDigits: 0
                                })
                              : 0
                           if (response.wsResponse.lstPrintext) {
                              var objNo = response.wsResponse.lstPrintext.filter((x) => x.textKey == 'NO')
                              if (objNo.length > 0) {
                                 this.data.no = objNo[0].text
                              }
                              var objAdjustment = response.wsResponse.lstPrintext.filter(
                                 (x) => x.textKey == 'ADJUSTMENT'
                              )
                              if (objAdjustment.length > 0) {
                                 this.data.adjustment = objAdjustment[0].text
                              }
                              var objDebt = response.wsResponse.lstPrintext.filter((x) => x.textKey == 'PRIOR_DEBT')
                              if (objDebt.length > 0) {
                                 this.data.debt = objDebt[0].text
                              }
                              var objDiscount = response.wsResponse.lstPrintext.filter((x) => x.textKey == 'DISCOUNT')
                              if (objDiscount.length > 0) {
                                 this.data.discount = objDiscount[0].text
                              }
                              var objCharging = response.wsResponse.lstPrintext.filter(
                                 (x) => x.textKey == 'TOTAL_AMOUNT_DUE'
                              )
                              if (objCharging.length > 0) {
                                 this.data.charging = objCharging[0].text
                              }
                              var objVat = response.wsResponse.lstPrintext.filter((x) => x.textKey == 'VAT')
                              if (objVat.length > 0) {
                                 this.data.vat = objVat[0].text
                              }
                           }
                        }
                        this.hideLoading()
                     })
                     .catch(() => {
                        this.hideLoading()
                     })
               }
               this.hideLoading()
            })
            .catch(() => {
               this.hideLoading()
            })
      }
   }
}
</script>
