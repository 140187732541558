<template>
   <div class="main-container">
    <!-- @click="goBack()" -->
      <div class="nav-bar js-nav-bar" >
          <div class="nav-bar__inner"><span class="nav-bar__icon" ><i class="icon-fonts icon-fonts--arrow-left" @click="goBack1()"></i></span>
            <h3 class="nav-bar__title">{{$t('mobile.change_to_prepaid.title')}}</h3>
          </div>
        </div>
        <div class="main-content">
          <div class="change-postpaid">
            <h4 class="change-postpaid__title">{{$t('mobile.change_to_prepaid.title1')}}</h4>
            <ValidationObserver v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(btnSubmit)">
              <div class="register-info">
              <div class="register-info__form">
                <div class="form-customize">
                  <Input 
                        :label="$t('mobile.change_to_prepaid.phone')"
                        :placeholder="$t('mobile.change_to_prepaid.phone')"
                        :isLabel="true"
                        :hasStar="true"
                        rules="required|phone"
                        type="number"
                        disabled
                        v-model="customer_info.contactPhone" />
                </div>
                <div class="form-customize">
                  <Input 
                        :label="$t('mobile.change_to_prepaid.personal_information')"
                        :placeholder="$t('mobile.change_to_prepaid.personal_information')"
                        :isLabel="true"
                        :hasStar="true"
                        rules="required"
                        type="text"
                        
                        v-model="customer_info.cusName" />
                </div>
                <div class="form-customize">
                  <div class="form-input">
                    <Input 
                        :label="$t('mobile.change_to_prepaid.birth_day')"
                        :placeholder="$t('mobile.change_to_prepaid.date_of_birth')"
                        :isLabel="false"
                        type="date"
                        rules="required|date"
                        rightIcon="icon-fonts icon-fonts--calendar"
                        v-model="birthOfDay" />
                  </div>
                </div>
                <div class="form-customize">
                  <Select :label="$t('mobile.registration_info.gender')"
                      :placeholder="$t('mobile.registration_info.enter_gender')"
                      :dataSelect="lst_Genders"
                      :isLabel="false"
                      v-model="customer_info.sex" />
                </div>
                <div class="form-customize">
                        <ValidationProvider
                           tag="div"
                           rules="required"
                           :name="$t('ftth.ftth_order.province')"
                           v-slot="{ errors }"
                        >
                           <label class="label-title">{{ $t('ftth.ftth_order.province') }} <span style="color:red">(*)</span></label>
                           <div class="select-box js-select-box">
                              <select
                                 class="js-custom-select"
                                 :id="$t('ftth.ftth_order.province')"
                                 :data-placeholder="$t('ftth.ftth_order.select_province')"
                                 v-model="provinceId"
                              >
                                 <option value=""></option>
                                 <option :value="province.id" v-for="province in provinces" :key="province.name">
                                    {{ province.name }}
                                 </option>
                              </select>
                           </div>
                           <p class="error-text" v-if="errors[0]">{{ errors[0] }}</p>
                        </ValidationProvider>
                     </div>
                     <div class="form-customize">
                        <ValidationProvider
                           tag="div"
                           rules="required"
                           :name="$t('ftth.ftth_order.district')"
                           v-slot="{ errors }"
                        >
                           <label class="label-title">{{ $t('ftth.ftth_order.district') }} <span style="color:red">(*)</span></label>
                           <div class="select-box js-select-box">
                              <select
                                 class="js-custom-select"
                                 :id="$t('ftth.ftth_order.district')"
                                 :data-placeholder="$t('ftth.ftth_order.select_district')"
                                 v-model="districtId"
                              >
                                 <option value=""></option>
                                 <option :value="district.id" v-for="district in districts" :key="district.name">
                                    {{ district.name }}
                                 </option>
                              </select>
                           </div>
                           <p class="error-text" v-if="errors[0]">{{ errors[0] }}</p>
                        </ValidationProvider>
                     </div>
                     <div class="form-customize">
                        <ValidationProvider
                           tag="div"
                           rules="required"
                           :name="$t('ftth.ftth_order.precinct')"
                           v-slot="{ errors }"
                        >
                           <label class="label-title">{{ $t('ftth.ftth_order.precinct') }} <span style="color:red">(*)</span></label>
                           <div class="select-box js-select-box">
                              <select
                                 class="js-custom-select"
                                 :id="$t('ftth.ftth_order.precinct')"
                                 :data-placeholder="$t('ftth.ftth_order.select_precinct')"
                                 v-model="precinctId"
                              >
                                 <option value=""></option>
                                 <option :value="precinct.id" v-for="precinct in precincts" :key="precinct.name">
                                    {{ precinct.name }}
                                 </option>
                              </select>
                           </div>
                           <p class="error-text" v-if="errors[0]">{{ errors[0] }}</p>
                        </ValidationProvider>
                     </div>
                <div class="form-customize">
                  <Input 
                        :label="$t('mobile.change_to_prepaid.address')"
                        :placeholder="$t('mobile.change_to_prepaid.address')"
                        :isLabel="true"
                        :hasStar="false"
                        type="text"
                        v-model="customer_info.fullAddress" />
                </div>
              </div>
              <div class="register-info__btn">
                <button class="button button--primary button--full">{{$t('mobile.change_to_prepaid.submit')}}
                </button>
              </div>
            </div>
            </form>
          </ValidationObserver>
            
          </div>
        </div>
      <confirmation-modal :data="dataPopupComfirm" :onConfirm="onconfirmAirTime" :onCancel="onCancel" />
   </div>
</template>
<script>
import BaseComponent from '@/mixins/BaseComponent'
import { DATE_FORMAT } from './../../constants/data'
import service from './../../services/MobileService'
import ConfirmationModal from '@/components/modal/ConfirmationModal'
import store from '@/plugins/vuex/store'
import { ValidationObserver } from 'vee-validate'
import Input from '@/components/form/Input'
import Select from '@/components/form/Select'
import LocalStorage from '@/ultils/local-storage'
import {Genders} from './../../constants/data'

export default {
   name: 'ConvertService',
   mixins: [BaseComponent],
   components: {
        ConfirmationModal,
        ValidationObserver,
        Input,
        Select
   },
   data() {
      return {
         customer_info:{
             cusId:this.getCustId(),
             contactPhone:this.getContractPhone(),
             cusName: (this.$store.state.change_to_prepaid.cusName && this.$store.state.change_to_prepaid.cusName != '') 
                      ? this.$store.state.change_to_prepaid.cusName 
                      : this.getCusName(),
             birthOfDay:this.$store.state.change_to_prepaid.birthOfDay,
             fullAddress:this.$store.state.change_to_prepaid.fullAddress,
             provinceCode:this.$store.state.change_to_prepaid.provinceCode,
             districtCode:this.$store.state.change_to_prepaid.districtCode,
             precinctCode:this.$store.state.change_to_prepaid.precinctCode,
             sex:this.$store.state.change_to_prepaid.sex,
             appCode: "APP_UNITEL",
             products:[
                 {
                     productId:"",
                     productName:"",
                     orderTypeName:"TRANSFER_PRE_TO_POS",
                     orderTypeCode:4
                 }
             ]
         },
         dataPopupComfirm: {
            title: '',
            content: '',
            cancelButton: '',
            confirmButton: '',
            confirmedData: {}
         },
         cusId:"",
         lst_Genders: Genders,
         birthOfDay: this.$store.state.change_to_prepaid.birthOfDay,
         provinceId: null,
         districtId: null,
         precinctId: null,
         provinces: [],
         districts: [],
         precincts: []
      }
   },
   async created() {
     // await this.getUserInfoById()
        this.loadScript('js/select2.js')
        this.loadScript('js/date-picker.js')
        this.loadScript('js/date-month-picker.js')
        this.getProvinces()
    },
    watch: {
      provinceId() {
         this.districtId = null
         this.precinctId = null
         this.getDistricts()
         this.getPrecincts()
      },
      districtId() {
         this.precinctId = null
         this.getPrecincts()
      },
        birthOfDay(newVal) {
            if(newVal && newVal.length == 10){
              let current = this.getMiliseconds(new Date(), DATE_FORMAT)
              let dateNum = this.getMiliseconds(newVal, DATE_FORMAT)
              if (dateNum > 0) {
                  if (dateNum > current) {
                      this.birthOfDay = null
                      store.dispatch('setNotification', {
                          content: this.$i18n.t('mobile.change_to_postpaid.error_birthday')
                      })
                  }
                  this.customer_info.birthOfDay = this.birthOfDay;
              }

              this.loadScript('js/date-picker.js')
              this.loadScript('js/date-month-picker.js')
            }
        }
    },
   methods: {
       btnSubmit(){
           this.dataPopupComfirm = {
                title: this.$i18n.t('mobile.change_to_prepaid.subscriber'),
                content:
                this.$i18n.t('mobile.change_to_prepaid.confirm'),
                cancelButton: this.$i18n.t('buttons.cancel'),
                confirmButton: this.$i18n.t('buttons.ok'),
                confirmedData: {

                }
            }
       },
       onCancel() {},
      async onconfirmAirTime() {
         this.showLoading()
          console.log('this.customer_info',this.customer_info)
          let userInfo = JSON.parse(LocalStorage.getItem('user_info')) ?? {}
          let dataPost = {...this.customer_info}
          dataPost.products[0].productId = userInfo['isdn']
          dataPost.products[0].productName = userInfo['isdn']
          dataPost['provinceCode'] = this.provinceId
          dataPost['districtCode'] = this.districtId
          dataPost['precinctCode'] = this.precinctId

          console.log({dataPost})
          store.commit('setChangeToPrepaid', dataPost)
          // this.$router.push('/mobile/change-to-prepaid/request-success')
          await service
              .CreateOrderPost2Pre(dataPost)
              .then((response) => {
                  this.hideLoading()
                  if(response.errorCode == 0){
                    this.$router.push('/mobile/change-to-prepaid/request-success')
                  }
                  else{
                    this.$store.dispatch('setNotification', { content: response.errorMessage })
                  }
                  this.loadScript('js/select2.js')
              })
              .finally(() => {
                this.hideLoading()
              })
          
      },
      getUserInfoById(){
        // let _this=this
        // let userInfo = JSON.parse(LocalStorage.getItem('user_info')) ?? {}
        // let isdn = userInfo['isdn']
        // service
        //     .getUserInfo({ "isdn": isdn})
        //     .then((response) => {
        //       _this.cusId = response.object.wsReponse.custId
        //     })
        //     .catch(() => {
        //        this.hideLoading()
        //     })
      },
      getCustId(){
        try {
          let userInfo = JSON.parse(LocalStorage.getItem('user_detail')) ?? {}
          let cusId = userInfo['custId']
          console.log({userInfo})
          return cusId
        } catch (error) {
          return ""
        }
      },
      getCusName(){
        try {
          let userInfo = JSON.parse(LocalStorage.getItem('user_detail')) ?? {}
          let cusName = userInfo['name']
          return cusName
        } catch (error) {
          return ""
        }
      },
      getContractPhone(){
        try {
          let userInfo = JSON.parse(LocalStorage.getItem('user_info')) ?? {}
          let isdn = userInfo['isdn']
          return isdn;
        } catch (error) {
          return ""
        }
      },
      getProvinces() {
         this.showLoading()
         this.provinces = []
         service
            .getProvinces()
            .then((response) => {
               if (response.errorCode === '0') {
                  this.provinces = response.wsResponse
                  this.loadScript('js/select2.js')
               } else {
                  this.$store.dispatch('setNotification', { content: response.message ?? response.userMsg })
               }
            })
            .finally(() => {
               this.hideLoading()
            })
      },
      getDistricts() {
         this.showLoading()
         this.districts = []
         if (this.provinceId) {
            service
               .getDistricts({ provinceId: this.provinceId })
               .then((response) => {
                  if (response.errorCode === '0') {
                     this.districts = response.wsResponse
                     this.loadScript('js/select2.js')
                  } else {
                     this.$store.dispatch('setNotification', { content: response.message ?? response.userMsg })
                  }
               })
               .finally(() => {
                  this.hideLoading()
               })
         }
      },
      getPrecincts() {
         this.showLoading()
         this.precincts = []
         if (this.provinceId && this.districtId) {
            service
               .getPrecinct({ provinceId: this.provinceId, districtId: this.districtId })
               .then((response) => {
                  if (response.errorCode === '0') {
                     this.precincts = response.wsResponse
                     this.loadScript('js/select2.js')
                  } else {
                     this.$store.dispatch('setNotification', { content: response.message ?? response.userMsg })
                  }
               })
               .finally(() => {
                  this.hideLoading()
               })
         }
      },
      goBack1(){
        this.$router.push('/mobile/home')
      }
   },
}
</script>
