<template>
    <div class="main-container">
        <div class="nav-bar js-nav-bar">
            <div class="nav-bar__inner">
                <span class="nav-bar__icon"  @click="goBack"><i class="icon-fonts icon-fonts--arrow-left"></i></span>
                <h3 class="nav-bar__title">{{ $t('mobile.change_to_postpaid.title') }}</h3>
            </div>
        </div>
        <div class="main-content">
            <div class="change-postpaid">
                <div class="steps-bar">
                    <ul class="steps-bar__list">
                        <li class="steps-bar__item done">
                            <span class="steps-bar__value">1</span>
                            <h5 class="steps-bar__name">{{ $t('mobile.change_to_postpaid.select') }}<br>{{ $t('mobile.change_to_postpaid.package') }}</h5>
                        </li>
                        <li class="steps-bar__item done">
                            <span class="steps-bar__value">2</span>
                            <h5 class="steps-bar__name">{{ $t('mobile.change_to_postpaid.customer') }}<br>{{ $t('mobile.change_to_postpaid.information') }}</h5>
                        </li>
                        <li class="steps-bar__item active">
                            <span class="steps-bar__value">3</span>
                            <h5 class="steps-bar__name">{{ $t('mobile.change_to_postpaid.contract') }}</h5>
                        </li>
                        <li class="steps-bar__item">
                            <span class="steps-bar__value">4</span>
                            <h5 class="steps-bar__name">{{ $t('mobile.change_to_postpaid.result') }}</h5>
                        </li>
                    </ul>
                </div>
                <h4 class="change-postpaid__title">{{ $t('mobile.change_to_postpaid.contract_detail') }}</h4>
                <div class="contract-detail">
                    <div class="contract-detail__images" v-for="i in numPages" :key="i">
                        <pdf  ref="pdf" :src="data.contractImage"  :page="i" @error="error"></pdf>
                    </div>
                    <div class="contract-detail__btn">
                        <button class="button button--primary button--full" @click="redirectStep">
                            {{$t('buttons.continue')}}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import BaseComponent from '@/mixins/BaseComponent'
    import service from './../../services/MobileService'
    import LocalStorage from '@/ultils/local-storage'
    import store from '@/plugins/vuex/store'
    import pdf from "vue-pdf";

    export default {
        name: 'ContractDetail',
        mixins: [BaseComponent],
        components: {
            pdf
        },
        data() {
            return {
                dataStore: JSON.parse(LocalStorage.getItem('change-to-postpaid')),
                data: {
                    contractNo: "",
                    contractImage: "",
                    urlFileView: ""
                },
                numPages: 1
            }
        },
        created() {
            this.setTitle(this.$i18n.t('mobile.change_to_postpaid.title'))
            if (this.dataStore.step3.detail) {
                this.data = this.dataStore.step3.detail
            } else {
                this.loadData()
            }
        },
        methods: {
            loadData() {
                this.showLoading()
                let param = {
                    "contractDTO": {
                        "quota": this.dataStore.step3.usageLimitation, // người dùng chọn
                        "contractImage": "",
                        "contractNo": ""
                    },
                    "customerDTO": {
                        "birthDate": this.dataStore.step2.dateOfBirthConvert,
                        "custName": this.dataStore.step2.name,
                        "fullAddress": this.dataStore.step2.address,
                        "email": this.dataStore.step2.email,
                        "signatureImage": this.dataStore.step3.signature.replace("data:image/jpeg;base64,", "").replace("data:image/png;base64,", ""),
                        "idIssuePlace": this.dataStore.step2.idIssuePlace,
                        "idIssueDate": this.dataStore.step2.idIssueDateConvert
                    },
                    "subDTO": {
                        "isdn": this.dataStore.step2.phone,
                        "productId": this.dataStore.step1.productId, // productId chọn từ bước 1
                        "telecomServiceId": 1 // fix cứng
                    },
                    username: this.dataStore.step2.phone
                }
               

                service
                    .getContractFileTransfer(param)
                    .then((response) => {
                        if (response.errorCode == "0") {
                            this.data = response.object ?? { contractNo: "", contractImage: "" }
                            this.data.contractImage = 'data:application/pdf;base64,' + this.data.contractImage;
                            var loadingTask = pdf.createLoadingTask(this.data.contractImage);
                            loadingTask.promise.then(pdf => {

                                this.numPages = pdf.numPages;
                            });
                        } else {
                            store.dispatch('setNotification', {
                            title: this.$i18n.t('notification.title'),
                            content: response.errorMessage,
                            closeButton: this.$i18n.t('buttons.close')
                        })
                        }
                    })
                    .finally(() => {
                        this.hideLoading()
                    })
            },
            redirectStep() {
                this.dataStore.step3["detail"] = this.data;
                LocalStorage.setItem('change-to-postpaid', JSON.stringify(this.dataStore))
                this.$router.push('/mobile/change_to_postpaid/contract-info')
            },
            error: function (err) {
                console.log(err)
            }
        }
    }

</script>
