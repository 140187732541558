<template>
   <div class="main-container">
      <div class="nav-bar js-nav-bar">
         <div class="nav-bar__inner">
            <span class="nav-bar__icon" @click="goBack"><i class="icon-fonts icon-fonts--arrow-left"></i></span>
            <h3 class="nav-bar__title">{{$t('ftth.change_product.change_product')}}</h3>
         </div>
      </div>
      <div class="main-content">
        <ValidationObserver v-slot="{handleSubmit }">
        <form @submit.prevent="handleSubmit(onSubmit)">
          <div class="change-product">
            <div class="change-product__content">
              <div class="change-product__box">
                <div class="change-product__top">
                  <h5 class="change-product__sub">{{$t('ftth.change_product.new_information')}}</h5>
                </div>
                <ul class="change-product__list">
                  <li class="change-product__item"><span class="change-product__name">{{$t('ftth.change_product.account_name')}}</span><span class="change-product__value">{{step1.account_name}}</span></li>
                  <li class="change-product__item"><span class="change-product__name">{{$t('ftth.change_product.package_name')}}</span><span class="change-product__value">{{step1.newProductCode}}</span></li>
                  <li class="change-product__item"><span class="change-product__name">{{$t('ftth.change_product.subtype')}}</span><span class="change-product__value">{{step1.newSubType}} {{$t('ftth.change_product.months')}}</span></li>
                  <li class="change-product__item"><span class="change-product__name">{{$t('ftth.change_product.package_fee')}}</span><span class="change-product__value">{{Number(step1.newPrice).toLocaleString('en-US', { maximumFractionDigits: 0 })}} {{$t('ftth.change_product.kip_month')}}</span></li>
                </ul>
              </div> 
              <div class="change-product__box">
                <div class="change-product__top">
                  <h5 class="change-product__sub">{{$t('ftth.change_product.payment_information')}}</h5>
                </div>
                <ul class="change-product__list">
                  <li class="change-product__item"><span class="change-product__name">{{$t('ftth.change_product.payment_method')}}</span><span class="change-product__value">{{$t('ftth.change_product.u_money')}}</span></li>
                  <li class="change-product__item"><span class="change-product__name">{{$t('ftth.change_product.amount')}}</span><span class="change-product__value highlight">{{Number(step1.paymentPrice).toLocaleString('en-US', { maximumFractionDigits: 0 })}}<span>{{$t('ftth.change_product.KIP')}}</span></span></li>
                </ul>
              </div>
              <div class="change-product__box">
                <div class="change-product__top">
                  <h5 class="change-product__sub">{{$t('ftth.change_product.u_money_number')}}</h5>
                </div>
                <div class="change-product__form">
                  <div class="form-customize">
                    <div class="form-customize">
                      <!-- <input class="form-control" type="number" value="2098887102">
                      <p class="error-text">This number hasn’t register u-money yet, please enter another number</p> -->
                        <Input
                           type="phone"
                           :label="$t('mobile.recharge_scratch_card.phone_number')"
                           :placeholder="$t('mobile.recharge_scratch_card.enter_phone_number')"
                           :isLabel="false"
                           rules="required|phone"
                           v-model="step2.isdnUmoney"
                           
                        />
                        <!-- disabled -->
                    </div>
                    <div class="form-customize">
                      <!-- <Input
                           type="number"
                           :label="$t('mobile.recharge_scratch_card.phone_number')"
                           :placeholder="$t('mobile.recharge_scratch_card.enter_phone_number')"
                           :isLabel="false"
                           v-model="step2.OTP"
                        /> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="change-product__btn">
              <!-- @click="onSubmit(invalid)" -->
              <button class="button button--primary button--full">{{$t('ftth.change_product.submit')}}
              </button>
            </div>
          </div>
          </form>
        </ValidationObserver>
      </div>

      <div class="text-center mt-3 pt-3" style="display: none;">
        <button class="button button--primary" data-bs-toggle="modal" data-bs-target="#register-confirm" id="registerConfirmOTP">{{$t('ftth.change_product.trigger_modal')}}
        </button>
      </div>
      <!-- Modal-->
      <div class="modal fade modal-custom " data-bs-backdrop="static" data-bs-keyboard="false" id="register-confirm" tabindex="-1" role="dialog" aria-labelledby="register-confirm" aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-custom__content">
            <div class="modal-custom__header"><span class="modal-custom__action" data-bs-dismiss="modal" id="hideOTP"><i class="icon-fonts icon-fonts--close"></i></span></div>
            <div class="modal-custom__body">
              <h3 class="modal-custom__title">{{$t('ftth.change_product.registration_confirmation')}}</h3>
              <p class="modal-custom__des">{{$t('ftth.change_product.titlePopup')}}</p>
              <div class="modal-custom__form">
                <div class="form-customize">
                  <div class="form-input">
                    <!-- <Input
                          type="number"
                          :label="$t('mobile.recharge_scratch_card.phone_number')"
                          :placeholder="$t('mobile.recharge_scratch_card.enter_phone_number')"
                          :isLabel="false"
                          v-model="step2.OTP_POPUP"
                      />
                      <span class="input-time">00:{{seconds}}</span> -->
                    <input v-model="step2.OTP_POPUP" pattern="[0-9]*" onkeypress="return event.keyCode === 8 || event.charCode >= 48 && event.charCode <= 57" class="form-control" type="text" :placeholder="$t('ftth.change_product.OTP')" maxlength="6" /><span class="input-time">00:{{seconds}}</span>
                  </div>
                </div>
              </div>
              <template v-if="seconds == 0">
                <p class="modal-custom__note">{{$t('ftth.change_product.not_received_yet')}}<a class="modal-custom__note-link" href="javascript:void(0)" @click="sendOTP">{{$t('ftth.change_product.resend_OTP')}}</a></p>
              </template>
            </div>
          </div>
        </div>
      </div>
   </div>
</template>
<script>
import BaseComponent from '@/mixins/BaseComponent'
import service from './../../services/FTTHService'
import store from '@/plugins/vuex/store'
import { ValidationObserver } from 'vee-validate'
import LocalStorage from '@/ultils/local-storage'
import Input from '@/components/form/Input'
import $ from 'jquery'

export default {
   name: 'FtthChangeProduct2',
   mixins: [BaseComponent],
   components: {
      ValidationObserver,
      Input
   },
   data() {
      return {
        step1:{
          newProductCode:store.state.step1.newProductCode,
          newSubType:store.state.step1.newSubType,
          newPrice:store.state.step1.newPrice,
          paymentPrice:store.state.step1.paymentPrice
        },
        step2:{
            isdnUmoney:store.state.step2.isdnUmoney,
            OTP_POPUP:""
        },
        totalTime:60,
        timer:null      
      }
   },
   computed:{
     seconds: function() {
      const seconds = this.totalTime;
      return this.padTime(seconds);
    },
   },
   created() {
      let ftthAccountInfo = JSON.parse(LocalStorage.getItem('ftth_account_info'))
      this.fullName = ftthAccountInfo.custName
      this.step1.account_name = ftthAccountInfo.accountNo
      let userInfo = JSON.parse(LocalStorage.getItem('user_info')) ?? {}
      this.step2.isdnUmoney = (store.state.step2.isdnUmoney && store.state.step2.isdnUmoney !='') ? store.state.step2.isdnUmoney :  userInfo['isdn']
   },
   watch: {
      async 'step2.OTP_POPUP'(val){
         if(val && val.length == 6){
            this.showLoading()
            let ftthAccountInfo = JSON.parse(LocalStorage.getItem('ftth_user_info'))
            let account = ftthAccountInfo.isdn 
            let dataPost={
              "isdnUmoney": this.step2.isdnUmoney,
              "newProductCode": this.step1.newProductCode,
              "newSubType": this.step1.newSubType,
              "account": account,
              "otp": this.step2.OTP_POPUP,

            }
            await service
                .changePackageAndSubType(dataPost)
                .then((response) => {
                if (response.errorCode === '0') {
                  // debugger; // eslint-disable-line no-debugger
                    $("#hideOTP").trigger('click')
                    this.hideLoading()
                    let payload = {...response.object}
                    store.commit('setStep3', payload)
                    this.$router.push('/ftth/change-product-3')
                }else{
                  $("#hideOTP").trigger('click')
                  store.dispatch('setNotification', { content: response.errorMessage ?? response.errorMessage })
                }
            })

            this.hideLoading()
         }
      },
      seconds(val){
        console.log('xxxx',val)
      }
   },
   methods: {
      padTime: function(time){
        return (time < 10 ? '0' : '') + time;
      },
      countdown(){
        this.totalTime--
        if(this.totalTime <=0){
          clearInterval(this.timer);
          this.timer = null;
        }
      },
      // invalid
      async onSubmit(){
         this.showLoading()
         //let ftthAccountInfo = JSON.parse(LocalStorage.getItem('ftth_user_info')) 
         let ftthAccountInfo = JSON.parse(LocalStorage.getItem('ftth_user_info'))
        let account = ftthAccountInfo.isdn 
        let dataPost = {
            account:account,
            isdnUmoney:this.step2.isdnUmoney,
            functionCode: "MYUNITEL_CHANGE_PACKAGE", // fix cứng
        }
        clearInterval(this.timer)
        let payload = this.step2
        store.commit('setStep2', payload)
        this.step2.OTP_POPUP = ""
         await service
            .getUmoneyOTP(dataPost)
            .then((response) => {
            if (response.errorCode === '0') {
              this.hideLoading()
                this.totalTime = 60
                $('#registerConfirmOTP').trigger('click')
                
                this.timer = setInterval(() => this.countdown(), 1000);
            }else {
                store.dispatch('setNotification', { content: response.errorMessage ?? response.errorMessage })
              
            }
        })
        this.hideLoading()
        
      },
      async sendOTP(){
        this.showLoading()
        this.step2.OTP_POPUP = ""
        clearInterval(this.timer)
        let ftthAccountInfo = JSON.parse(LocalStorage.getItem('ftth_user_info'))
        let account = ftthAccountInfo.isdn 
        let dataPost = {
            account:account,
            isdnUmoney:this.step2.isdnUmoney,
            functionCode: "MYUNITEL_CHANGE_PACKAGE", // fix cứng
        }
         await service
            .getUmoneyOTP(dataPost)
            .then((response) => {
            if (response.errorCode === '0') {
              this.hideLoading()
              this.totalTime = 60
              this.timer = setInterval(() => this.countdown(), 1000);
            }else {
                store.dispatch('setNotification', { content: response.errorMessage ?? response.errorMessage })
            }
        })
        this.hideLoading()
      }
   }
}
</script>
