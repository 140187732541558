import Api from '@/ultils/api-base'

export default {
   login(body) {
      return Api('POST', 'login', body)
   },
   getListWebGame(body) {
      return Api('POST', 'wsGetListWebGame', body)
   },
   getListBanners(body) {
      return Api('POST', 'wsGetHotNews', body)
   },
   getUserInfo(body) {
      return Api('POST', 'wsGetSubMainInfo', body)
   },
   getRank(body) {
      return Api('POST', 'wsLoyaltyGetAccountRankInfo', body)
   },
   getAvailableUpoint(body) {
      return Api('POST', 'wsLoyaltyGetAccountPointInfo', body)
   },
   getInfoCust(body) {
      return Api('POST', 'wsGetInfoCust', body)
   },
   getListFtthAccountInfo(body) {
      return Api('POST', 'wsGetListFtthAccountInfo', body)
   },
   getRegInfoSub(body) {
      return Api('POST', 'wsRegInfoSub', body)
   },
    getParamConfig(body) {
      return Api('POST', 'wsGetParamConfig', body)
   },
   GetNoticeWebview (body) {
      return Api('POST', 'wsGetNoticeWebview', body)
   },
   GetSubAccountInfo (body) {
      return Api('POST', 'wsGetSubAccountInfo', body)
   },
   GetCurrentUsedSubServices (body) {
      return Api('POST', 'wsGetCurrentUsedSubServices', body)
   },
   GetServiceBestSeller (body) {
      return Api('POST', 'wsGetServiceBestSeller', body)
   },
   GetBannerWebview (body) {
      return Api('POST', 'wsGetBannerWebview', body)
   },
   GetLaoAppStatus (body) {
      return Api('POST', 'wsGetLaoAppStatus', body)
   }
}
