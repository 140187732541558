<template>
   <div class="main-container">
      <div class="nav-bar js-nav-bar" @click="goBack">
         <div class="nav-bar__inner">
            <span class="nav-bar__icon"><i class="icon-fonts icon-fonts--arrow-left"></i></span>
            <h3 class="nav-bar__title">{{ $t('ftth.charge_history.title') }}</h3>
         </div>
      </div>
      <div class="main-content">
         <div class="charge-history">
            <div class="charge-box">
               <ul class="charge-box__list">
                  <li class="charge-box__item" @click="onRedirect">
                     <span class="charge-box__icon"><i class="icon-fonts icon-fonts--charge-history"></i></span
                     ><span class="charge-box__name">{{ $t('ftth.precharge_history.title') }}</span>
                  </li>
               </ul>
            </div>
            <div class="charge-box">
               <ul class="charge-box__list">
                  <li class="charge-box__item" @click="onRedirectReceip(date1, date11)">
                     <span class="charge-box__icon"><i class="icon-fonts icon-fonts--bill"></i></span
                     ><span class="charge-box__name">{{ $t('unit.date') }}: {{ date1 }}</span>
                  </li>
                  <li class="charge-box__item" @click="onRedirectReceip(date2, date22)">
                     <span class="charge-box__icon"><i class="icon-fonts icon-fonts--bill"></i></span
                     ><span class="charge-box__name">{{ $t('unit.date') }}: {{ date2 }}</span>
                  </li>
                  <li class="charge-box__item" @click="onRedirectReceip(date3, date33)">
                     <span class="charge-box__icon"><i class="icon-fonts icon-fonts--bill"></i></span
                     ><span class="charge-box__name">{{ $t('unit.date') }}: {{ date3 }}</span>
                  </li>
               </ul>
            </div>
         </div>
      </div>
   </div>
</template>
<script>
import BaseComponent from '@/mixins/BaseComponent'
import LocalStorage from '@/ultils/local-storage'

export default {
   name: 'ChargeHistory',
   mixins: [BaseComponent],
   components: {},
   data() {
      return {
         date1: '',
         date11: 0,
         date2: '',
         date22: 0,
         date3: '',
         date33: 0
      }
   },
   created() {
      this.setTitle(this.$i18n.t('ftth.charge_history.title'))
      this.getData()
   },
   methods: {
      getData() {
         var date = new Date()
         let dateCurrent = new Date(date.getFullYear(), date.getMonth(), 1)
         let dateNext = new Date()
         let dateNext2 = new Date()
         dateNext = new Date(dateNext.getFullYear(), dateNext.getMonth() - 1, 1)
         dateNext2 = new Date(dateNext2.getFullYear(), dateNext2.getMonth() - 2, 1)
         this.date11 = new Date(dateCurrent).getTime()
         this.date22 = new Date(dateNext).getTime()
         this.date33 = new Date(dateNext2).getTime()
         this.date1 =
            dateCurrent.getFullYear() + ' ' + dateCurrent.toLocaleString(this.$i18n.t('lang'), { month: 'long' })
         this.date2 = dateNext.getFullYear() + ' ' + dateNext.toLocaleString(this.$i18n.t('lang'), { month: 'long' })
         this.date3 = dateNext2.getFullYear() + ' ' + dateNext2.toLocaleString(this.$i18n.t('lang'), { month: 'long' })
      },
      onRedirect() {
         this.$router.push('/ftth/precharge-history')
      },
      onRedirectReceip(datestr, datelong) {
         LocalStorage.setItem('billCycleDateStr', datestr)
         LocalStorage.setItem('billCycleDate', datelong)
         this.$router.push('/ftth/receip-history')
      }
   }
}
</script>
