<template>
   <div class="main-container">
      <div class="nav-bar js-nav-bar">
         <div class="nav-bar__inner">
            <span class="nav-bar__icon" @click="goBack"><i class="icon-fonts icon-fonts--arrow-left"></i></span>
            <h3 class="nav-bar__title">{{ $t('home.functions.contact') }}</h3>
         </div>
      </div>
      <div class="main-content">
         <div class="recharge-search">
            <div class="recharge-search__form">
               <div class="form-customize">
                  <div class="form-input">
                     <input
                        v-model.trim="searchInput"
                        class="form-control"
                        type="text"
                        :maxlength="15"
                        :placeholder="$t('mobile.recharge_scratch_card.search_contacts')"
                     /><span class="input-addon"><i class="icon-fonts icon-fonts--search"></i></span>
                  </div>
               </div>
            </div>
            <ul class="recharge-search__list">
               <li
                  v-for="(user, index) in filterList"
                  :key="index"
                  @click="handleSelectCustomer(user)"
                  class="recharge-search__item"
               >
                  <div class="recharge-search__detail">
                     <span class="recharge-search__thubnail"
                        ><span>{{ getInitialName(user.name) }}</span></span
                     >
                     <div class="recharge-search__info">
                        <h5 class="recharge-search__name">
                           <a class="recharge-search__name-link" href="#">{{ user.name }}</a>
                        </h5>
                     </div>
                  </div>
                  <span class="recharge-search__value">{{ user.msisdn }}</span>
               </li>
            </ul>
         </div>
      </div>
   </div>
</template>

<script>
import BaseComponent from '@/mixins/BaseComponent'
import service from './../../services/MobileService'
import store from '@/plugins/vuex/store'
export default {
   name: 'ContactList',
   mixins: [BaseComponent],
   data() {
      return {
         searchInput: '',
         contactList: []
      }
   },
   created() {
      this.loadData()
   },
   methods: {
      goBack() {
         this.$router.replace('/mobile/recharge-scratch-card')
      },
      loadData() {
         this.showLoading()
         service
            .getListContact()
            .then((response) => {
               if (response.errorCode == '0') {
                  let result = JSON.parse(response.wsResponse) ?? []
                  this.contactList = result
                  this.contactList.forEach((item) => (item.msisdn = item.msisdn.replace('+856', '0')))
               }
            })
            .finally(() => {
               this.hideLoading()
            })
      },
      getInitialName(name) {
         return name
            .match(/(^\S\S?|\s\S)?/g)
            .map((v) => v.trim())
            .join('')
            .match(/(^\S|\S$)?/g)
            .join('')
      },
      handleSelectCustomer(customer) {
         store.commit('setCustomerName', customer.name)
         store.commit('setPhoneScratchCard', customer.msisdn.substring(1))
         this.$router.push('/mobile/recharge-scratch-card')
      }
   },
   computed: {
      filterList() {
         return this.contactList.filter((obj) =>
            Object.values(obj).some((val) => val.toString().toLowerCase().includes(this.searchInput.toLowerCase()))
         )
      }
   }
}
</script>

<style></style>
