<template>
   <div class="main-container">
      <div class="nav-bar js-nav-bar" @click="goBack">
         <div class="nav-bar__inner">
            <span class="nav-bar__icon"><i class="icon-fonts icon-fonts--arrow-left"></i></span>
            <h3 class="nav-bar__title">{{ $t('home.favourite_list.title') }}</h3>
         </div>
      </div>
      <div class="main-content">
         <div class="favourite-list">
            <div class="favourite-list__row current">
               <h4 class="favourite-list__sub">{{ $t('home.categories.favourite_function') }}</h4>
               <div class="feature-favourite">
                  <ul class="feature-favourite__list">
                     <li class="feature-favourite__item" v-for="item in favouriteList" :key="item.id">
                        <a class="feature-favourite__link" v-on:click="removeFunction(item)"
                           ><span class="feature-favourite__icon"
                              ><img :src="item.icon" alt="img" /><span class="feature-favourite__action remove"
                                 ><i class="icon-fonts icon-fonts--remove"></i></span
                           ></span>
                           <h5 class="feature-favourite__name">{{ item.name }}</h5>
                        </a>
                     </li>
                     <li class="feature-favourite__item">
                        <a class="feature-favourite__link" href="javascript:void(0)"
                           ><span class="feature-favourite__icon"
                              ><img src="@/assets/images/ic-notification.svg" alt="img" />
                              <!--<span class="feature-favourite__action remove"><i class="icon-fonts icon-fonts--remove"></i></span>-->
                           </span>
                           <h5 class="feature-favourite__name">Notification</h5>
                        </a>
                     </li>
                     <li class="feature-favourite__item">
                        <a class="feature-favourite__link" href="javascript:void(0)"
                           ><span class="feature-favourite__icon"
                              ><img src="@/assets/images/ic-more.svg" alt="img" />
                              <!--<span class="feature-favourite__action remove"><i class="icon-fonts icon-fonts--remove"></i></span>-->
                           </span>
                           <h5 class="feature-favourite__name">More</h5>
                        </a>
                     </li>
                  </ul>
               </div>
            </div>
            <div class="favourite-list__row">
               <h4 class="favourite-list__sub">{{ $t('home.categories.mobile_function') }}</h4>
               <div class="feature-favourite">
                  <ul class="feature-favourite__list">
                     <li class="feature-favourite__item" v-for="item in mobileFunction" :key="item.id">
                        <div>
                           <a class="feature-favourite__link" v-on:click="addFunction(item)">
                              <span class="feature-favourite__icon">
                                 <img :src="item.icon" alt="img" /><span class="feature-favourite__action add"
                                    ><i class="icon-fonts icon-fonts--add"></i
                                 ></span>
                              </span>
                              <h5 class="feature-favourite__name">{{ item.name }}</h5>
                           </a>
                        </div>
                     </li>
                  </ul>
               </div>
            </div>
            <div class="favourite-list__row">
               <h4 class="favourite-list__sub">{{ $t('home.categories.digital_service') }}</h4>
               <div class="feature-favourite">
                  <ul class="feature-favourite__list">
                     <li class="feature-favourite__item" v-for="item in digitalService" :key="item.id">
                        <a class="feature-favourite__link" v-on:click="addFunction(item)">
                           <span class="feature-favourite__icon">
                              <img :src="item.icon" alt="img" /><span class="feature-favourite__action add"
                                 ><i class="icon-fonts icon-fonts--add"></i
                              ></span>
                           </span>
                           <h5 class="feature-favourite__name">{{ item.name }}</h5>
                        </a>
                     </li>
                  </ul>
               </div>
            </div>
            <div class="favourite-list__row">
               <h4 class="favourite-list__sub">{{ $t('home.categories.internet_service') }}</h4>
               <div class="feature-favourite">
                  <ul class="feature-favourite__list">
                     <li class="feature-favourite__item" v-for="item in internetService" :key="item.id">
                        <a class="feature-favourite__link" v-on:click="addFunction(item)">
                           <span class="feature-favourite__icon">
                              <img :src="item.icon" alt="img" /><span class="feature-favourite__action add"
                                 ><i class="icon-fonts icon-fonts--add"></i
                              ></span>
                           </span>
                           <h5 class="feature-favourite__name">{{ item.name }}</h5>
                        </a>
                     </li>
                  </ul>
               </div>
            </div>
            <div class="favourite-list__row">
               <h4 class="favourite-list__sub">{{ $t('home.categories.game') }}</h4>
               <div class="feature-favourite">
                  <ul class="feature-favourite__list">
                     <li class="feature-favourite__item" v-for="item in game" :key="item.id">
                        <a class="feature-favourite__link" v-on:click="addFunction(item)">
                           <span class="feature-favourite__icon">
                              <img :src="item.icon" alt="img" /><span class="feature-favourite__action add"
                                 ><i class="icon-fonts icon-fonts--add"></i
                              ></span>
                           </span>
                           <h5 class="feature-favourite__name">{{ item.name }}</h5>
                        </a>
                     </li>
                  </ul>
               </div>
            </div>
            <div class="favourite-list__row">
               <h4 class="favourite-list__sub">{{ $t('home.categories.customer_service') }}</h4>
               <div class="feature-favourite">
                  <ul class="feature-favourite__list">
                     <li class="feature-favourite__item" v-for="item in customerService" :key="item.id">
                        <a class="feature-favourite__link" v-on:click="addFunction(item)">
                           <span class="feature-favourite__icon">
                              <img :src="item.icon" alt="img" /><span class="feature-favourite__action add"
                                 ><i class="icon-fonts icon-fonts--add"></i
                              ></span>
                           </span>
                           <h5 class="feature-favourite__name">{{ item.name }}</h5>
                        </a>
                     </li>
                  </ul>
               </div>
            </div>
            <div class="favourite-list__row">
               <h4 class="favourite-list__sub">{{ $t('home.categories.miscellaneous') }}</h4>
               <div class="feature-favourite">
                  <ul class="feature-favourite__list">
                     <li class="feature-favourite__item" v-for="item in miscellaneous" :key="item.id">
                        <a class="feature-favourite__link" v-on:click="addFunction(item)">
                           <span class="feature-favourite__icon">
                              <img :src="item.icon" alt="img" :href="item.url" /><span
                                 class="feature-favourite__action add"
                                 ><i class="icon-fonts icon-fonts--add"></i
                              ></span>
                           </span>
                           <h5 class="feature-favourite__name">{{ item.name }}</h5>
                        </a>
                     </li>
                  </ul>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>
<script>
import BaseComponent from '@/mixins/BaseComponent'
import { FavouriteList, FunctionList } from '../constants/data'
import store from '@/plugins/vuex/store'
import LocalStorage from '@/ultils/local-storage'

export default {
   name: 'FavouriteList',
   mixins: [BaseComponent],
   data() {
      return {
         favouriteList: [],
         functionList: [],
         mobileFunction: [],
         digitalService: [],
         internetService: [],
         game: [],
         customerService: [],
         miscellaneous: [],
         subType: LocalStorage.getItem('subType')
      }
   },
   created() {
      this.setTitle(this.$i18n.t('home.favourite_list.title'))
      this.favouriteList =
         JSON.parse(LocalStorage.getItem('favourite_list')) ?? JSON.parse(JSON.stringify(FavouriteList))
      this.functionList = JSON.parse(JSON.stringify(FunctionList))
      if (this.subType == 1) {
         var idx = this.functionList.findIndex((x) => x.id == 9)
         if (idx > -1) {
            this.functionList.splice(idx, 1)
         }
      } else {
         var idxs = this.functionList.findIndex((x) => x.id == 3)
         if (idxs > -1) {
            this.functionList.splice(idxs, 1)
         }
      }
      for (var i = 0; i < this.favouriteList.length; i++) {
         var index = this.functionList.findIndex((x) => x.id == this.favouriteList[i].id)
         if (index > -1) {
            this.functionList.splice(index, 1)
         }
      }
      this.addData(this.functionList)
   },
   methods: {
      addData(functionList) {
         this.mobileFunction = functionList
            .filter((x) => x.category == this.$i18n.t('home.categories.mobile_function'))
            .sort()
         this.digitalService = functionList
            .filter((x) => x.category == this.$i18n.t('home.categories.digital_service'))
            .sort()
         this.internetService = functionList
            .filter((x) => x.category == this.$i18n.t('home.categories.internet_service'))
            .sort()
         this.game = functionList.filter((x) => x.category == this.$i18n.t('home.categories.game')).sort()
         this.customerService = functionList
            .filter((x) => x.category == this.$i18n.t('home.categories.customer_service'))
            .sort()
         this.miscellaneous = functionList
            .filter((x) => x.category == this.$i18n.t('home.categories.miscellaneous'))
            .sort()
      },
      addFunction(item) {
         if (this.favouriteList.length < 6) {
            var checkExits = this.favouriteList.filter((x) => x.id == item.id)
            if (checkExits.length == 0) {
               this.favouriteList.unshift(item)
               removeElement(this.functionList, item)
               this.addData(this.functionList)
            } else {
               //Thông báo đã thêm chức năng
               store.dispatch('setNotification', {
                  title: this.$i18n.t('notification.title'),
                  content: this.$i18n.t('home.favourite_list.notification_add_exist'),
                  closeButton: this.$i18n.t('buttons.close')
               })
            }
         } else {
            //Thông báo đã hết quyền thêm chức năng
            store.dispatch('setNotification', {
               title: this.$i18n.t('notification.title'),
               content: this.$i18n.t('home.favourite_list.notification_over'),
               closeButton: this.$i18n.t('buttons.close')
            })
         }

         LocalStorage.setItem('favourite_list', JSON.stringify(this.favouriteList))
      },
      removeFunction(item) {
         this.functionList.push(item)
         removeElement(this.favouriteList, item)
         this.addData(this.functionList)
         LocalStorage.setItem('favourite_list', JSON.stringify(this.favouriteList))
      }
   }
}

function removeElement(array, elem) {
   var index = array.indexOf(elem)
   if (index > -1) {
      array.splice(index, 1)
   }
}
</script>
