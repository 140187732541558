import aesjs from 'aes-js'
// import base64url from "base64url";

var key = '69166c0aa5bb1a7c'
var iv = '151e7b7548668ea2'

var keyBytes = aesjs.utils.utf8.toBytes(key)
var ivBytes = aesjs.utils.utf8.toBytes(iv)

export function encrypt(msg) {
   var aesCbc = new aesjs.ModeOfOperation.cbc(keyBytes, ivBytes)
   var textBytes = aesjs.utils.utf8.toBytes(msg)
   var padded = aesjs.padding.pkcs7.pad(textBytes)
   var encryptedBytes = aesCbc.encrypt(padded)
   return toHexString(encryptedBytes)
}

// export function decrypt(encrypted) {
//     var aesCbc = new aesjs.ModeOfOperation.cbc(keyBytes, ivBytes);
//     var encryptedBytes = base64url.toBuffer(encrypted)
//     var decryptedBytes = aesCbc.decrypt(encryptedBytes);
//     return aesjs.utils.utf8.fromBytes(decryptedBytes);
// }

export function toHexString(byteArray) {
   return Array.from(byteArray, function (byte) {
      return ('0' + (byte & 0xff).toString(16)).slice(-2)
   }).join('')
}
