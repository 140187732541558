<template>
   <div class="main-container">
      <div class="nav-bar js-nav-bar" @click="goBack">
         <div class="nav-bar__inner">
            <span class="nav-bar__icon"><i class="icon-fonts icon-fonts--arrow-left"></i></span>
            <h3 class="nav-bar__title">{{ $t('ucare.title') }}</h3>
         </div>
      </div>
      <div class="main-content">
         <div class="intro-duction">
            <div class="intro-duction__row">
               <h4 class="intro-duction__sub">{{ $t('ucare.customer_service.ucare_question') }}</h4>
               <div class="intro-duction__info" v-html="data.introduction"></div>
            </div>
            <div class="intro-duction__row">
               <h4 class="intro-duction__sub">{{ $t('ucare.customer_service.program_rules') }}</h4>
               <div class="intro-duction__info" v-html="data.policy"></div>
            </div>
            <div class="intro-duction__row">
               <h4 class="intro-duction__sub">{{ $t('ucare.customer_service.benefits') }}</h4>
               <div class="intro-duction__info" v-html="data.benifit"></div>
            </div>
         </div>
      </div>
   </div>
</template>
<script>
import BaseComponent from '@/mixins/BaseComponent'
import service from './../../services/UCareService'

export default {
   name: 'Introduction',
   mixins: [BaseComponent],
   data() {
      return {
         data: {}
      }
   },
   created() {
      this.setTitle(this.$i18n.t('ucare.customer_service.introduction'))
      this.loadData()
   },
   methods: {
      loadData() {
         this.showLoading()
         service
            .getLoyaltyInfo()
            .then((response) => {
               this.data = response.wsResponse
            })
            .finally(() => {
               this.hideLoading()
            })
      }
   }
}
</script>
