<template>
   <div class="main-container">
      <div class="nav-bar js-nav-bar" @click="goBack">
         <div class="nav-bar__inner">
            <span class="nav-bar__icon"><i class="icon-fonts icon-fonts--arrow-left"></i></span>
            <h3 class="nav-bar__title">{{ $t('mobile.ishare.change_password') }}</h3>
         </div>
      </div>
      <div class="main-content">
         <ValidationObserver v-slot="{ passes }">
            <form @submit.prevent="passes(onSubmit)">
               <div class="recharge-basic">
                  <div class="recharge-form">
                     <Input
                        type="numPassword"
                        :label="$t('mobile.ishare.current_password')"
                        :placeholder="$t('mobile.ishare.enter_your_current_password')"
                        :hasResetIcon="true"
                        rules="required|equal:6"
                        v-model="currentPassword"
                     />
                     <Input
                        type="numPassword"
                        :label="$t('mobile.ishare.new_password')"
                        :placeholder="$t('mobile.ishare.enter_your_new_password')"
                        :hasResetIcon="true"
                        vid="pass"
                        rules="required|equal:6"
                        v-model="newPassword"
                     />
                     <Input
                        type="numPassword"
                        :label="$t('mobile.ishare.confirmed_password')"
                        :name="$t('mobile.ishare.confirmed_password')"
                        :placeholder="$t('mobile.ishare.confirm_your_new_password')"
                        :hasResetIcon="true"
                        rules="required|equal:6|confirmedBy:@pass"
                        v-model="confirmNewPassword"
                     />
                  </div>
                  <div class="recharge-btn">
                     <button class="button button--primary button--full">{{ $t('mobile.ishare.change') }}</button>
                  </div>
               </div>
            </form>
         </ValidationObserver>
      </div>
   </div>
</template>
<script>
import { ValidationObserver } from 'vee-validate'
import Input from '@/components/form/Input'
import BaseComponent from '@/mixins/BaseComponent'
import service from './../../services/MobileService'
import store from '@/plugins/vuex/store'
import { encrypt } from '@/ultils/aes'

export default {
   name: 'ChangePassword',
   mixins: [BaseComponent],
   components: {
      ValidationObserver,
      Input
   },
   data() {
      return {
         currentPassword: '',
         newPassword: '',
         confirmNewPassword: ''
      }
   },
   created() {
      this.setTitle(this.$i18n.t('mobile.ishare.change_password'))
   },
   methods: {
      onSubmit() {
         this.showLoading()
         service
            .changePasswordIshare({
               oldPassword: encrypt(this.currentPassword),
               newPassword: encrypt(this.newPassword),
               confirmNewPassword: encrypt(this.confirmNewPassword)
            })
            .then((response) => {
               store.dispatch('setNotification', { content: response.message ?? response.userMsg })
            })
            .finally(() => {
               this.hideLoading()
            })
      }
   }
}
</script>
