<template>
    <div class="main-container">
        <div class="nav-bar js-nav-bar">
            <div class="nav-bar__inner">
                <span class="nav-bar__icon" @click="goBack"><i class="icon-fonts icon-fonts--arrow-left"></i></span>
                <h3 class="nav-bar__title">{{ $t('mobile.change_to_postpaid.title') }}</h3>
            </div>
        </div>
        <div class="main-content">
            <div class="change-postpaid">
                <div class="steps-bar">
                    <ul class="steps-bar__list">
                        <li class="steps-bar__item done">
                            <span class="steps-bar__value">1</span>
                            <h5 class="steps-bar__name">{{ $t('mobile.change_to_postpaid.select') }}<br>{{ $t('mobile.change_to_postpaid.package') }}</h5>
                        </li>
                        <li class="steps-bar__item done">
                            <span class="steps-bar__value">2</span>
                            <h5 class="steps-bar__name">{{ $t('mobile.change_to_postpaid.customer') }}<br>{{ $t('mobile.change_to_postpaid.information') }}</h5>
                        </li>
                        <li class="steps-bar__item active">
                            <span class="steps-bar__value">3</span>
                            <h5 class="steps-bar__name">{{ $t('mobile.change_to_postpaid.contract') }}</h5>
                        </li>
                        <li class="steps-bar__item">
                            <span class="steps-bar__value">4</span>
                            <h5 class="steps-bar__name">{{ $t('mobile.change_to_postpaid.result') }}</h5>
                        </li>
                    </ul>
                </div>
                <h4 class="change-postpaid__title">{{$t('mobile.change_to_postpaid.contract_type')}}</h4>
                <div class="contract-type">
                    <div class="contract-type__form">
                        <div class="form-customize">
                            <label class="label-title">{{$t('mobile.change_to_postpaid.select_the_usage_limitation')}}</label>
                            <div class="form-radio">
                                <div class="radio-custom">
                                    <input class="radio-custom__input" type="radio" name="radio" id="radio-1" checked="checked" value="300000" v-model="usageLimitation"/>
                                    <label class="radio-custom__label" for="radio-1">300,000 {{$t('unit.kip_month')}}</label>
                                </div>
                                <div class="radio-custom">
                                    <input class="radio-custom__input" type="radio" name="radio" id="radio-2" value="500000" v-model="usageLimitation"/>
                                    <label class="radio-custom__label" for="radio-2">500,000 {{$t('unit.kip_month')}}</label>
                                </div>
                                <div class="radio-custom">
                                    <input class="radio-custom__input" type="radio" name="radio" id="radio-3" value="1000000" v-model="usageLimitation"/>
                                    <label class="radio-custom__label" for="radio-3">1,000,000 {{$t('unit.kip_month')}}</label>
                                </div>
                            </div>
                        </div>
                        <div class="form-customize">
                            <label class="label-title">{{$t('mobile.change_to_postpaid.signature')}}</label>
                            <div class="form-signature">
                                <div class="form-signature__images">
                                    <VueSignaturePad height="150px" ref="signaturePad" v-show="showSignature" />
                                    <img :src="signature" alt="img" v-if="!showSignature">
                                </div>
                                
                                <span class="form-signature__action"><i class="icon-fonts icon-fonts--trash" @click="removeSignature"></i></span>
                            </div>
                            <!--<button @click="removeSignature">Delete</button>-->
                        </div>
                    </div>
                    <div class="contract-type__btn">
                        <button class="button button--primary button--full" @click="redirectStep">
                            {{$t('buttons.continue')}}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import BaseComponent from '@/mixins/BaseComponent'
    import LocalStorage from '@/ultils/local-storage'
    import VueSignaturePad from 'vue-signature-pad';

    export default {
        name: 'ContractType',
        mixins: [BaseComponent],
        components: {
            VueSignaturePad
        },
        data() {
            return {
                dataStore: JSON.parse(LocalStorage.getItem('change-to-postpaid')),
                usageLimitation: 300000,
                signature: null,
                showSignature: true
            }
        },
        created() {
            this.setTitle(this.$i18n.t('mobile.change_to_postpaid.title'))
            
        },
        mounted() {
            this.showSignature = true;
            if (this.dataStore.step3) {
                this.usageLimitation = this.dataStore.step3.usageLimitation;
                if (this.dataStore.step3.signature) {
                    this.signature = this.dataStore.step3.signature
                    this.showSignature = false;
                } else {
                    this.showSignature = true;
                }
                delete this.dataStore.step3.detail
            }
        },
        methods: {
            removeSignature() {
                this.$refs.signaturePad.clearSignature();
                this.showSignature = true;
                this.styleSignature = "";
            },
            redirectStep() {
                const { isEmpty, data } = this.$refs.signaturePad.saveSignature()
                if (!isEmpty || this.signature) {
                    if (!isEmpty) {
                        this.signature = data;
                    }
                } else {
                    this.$store.dispatch('setNotification', { content: this.$i18n.t('mobile.change_to_postpaid.notification_signature') })
                    return
                }
                this.dataStore["step3"] = {
                    usageLimitation: this.usageLimitation,
                    signature: this.signature
                };
                this.showSignature = false;
                LocalStorage.setItem('change-to-postpaid', JSON.stringify(this.dataStore))
                this.$router.push('/mobile/change_to_postpaid/contract-detail')
            }
        }
    }

</script>
