<template>
   <div class="main-container">
      <div class="nav-bar js-nav-bar" @click="goBack">
         <div class="nav-bar__inner">
            <span class="nav-bar__icon"><i class="icon-fonts icon-fonts--arrow-left"></i></span>
            <h3 class="nav-bar__title">{{ $t('ucare.member_info.title') }}</h3>
         </div>
      </div>
      <div class="main-content">
         <div class="member-infor">
            <div :class="cardClass">
               <div class="member-card__content">
                  <div class="member-card__left">
                     <h4 class="member-card__name">{{ name }}</h4>
                     <span class="member-card__code">{{ phoneNumber }}</span>
                  </div>
                  <div class="member-card__right">
                     <h5 class="member-card__sub">{{ $t('ucare.member_info.title_exp') }}</h5>
                     <ul class="member-card__list">
                        <li class="member-card__item">
                           <span class="member-card__txt highlight">{{ $t('ucare.member_info.exp') }}</span
                           ><span class="member-card__value">{{ expCard }}</span>
                        </li>
                        <li class="member-card__item">
                           <span class="member-card__txt">{{ $t('ucare.member_info.call_center') }}:</span
                           ><span class="member-card__value highlight">109</span>
                        </li>
                        <li class="member-card__item"><span class="member-card__value">www.unitel.com.la</span></li>
                     </ul>
                  </div>
               </div>
            </div>
            <div class="member-point">
               <h4 class="member-infor__sub">{{ $t('ucare.member_info.point_history') }}</h4>
               <span class="member-point__action" @click="onHistory()"
                  ><i class="icon-fonts icon-fonts--arrow-right"></i
               ></span>
            </div>
            <div class="member-proccess">
               <h4 class="member-infor__sub">
                  {{ $t('ucare.member_info.content') }} {{ cardName }} {{ $t('ucare.member_info.member') }}
               </h4>
               <div class="member-proccess__info">
                  <span class="member-proccess__value"
                     >{{ Number(maxPoint).toLocaleString('en-US', { maximumFractionDigits: 0 }) }}
                     {{ $t('unit.upoint_low') }}</span
                  >
               </div>
               <!--welcome-->
               <div class="member-proccess__detail" v-if="rankName == 'Loyal'">
                  <span class="member-proccess__icon"
                     ><img src="@/assets/images/ic-welcome-small.svg" alt="img"
                  /></span>
                  <div class="member-proccess__range">
                     <p class="member-proccess__percent" :style="typePoint">
                        <span class="member-proccess__data"
                           >{{ Number(pointValue).toLocaleString('en-US', { maximumFractionDigits: 0 }) }}
                           {{ $t('unit.upoint_low') }}</span
                        >
                     </p>
                  </div>
                  <span class="member-proccess__icon"><img src="@/assets/images/ic-bronze-small.svg" alt="img" /></span>
               </div>
               <!--bronze-->
               <div class="member-proccess__detail" v-if="rankName == 'Titan'">
                  <span class="member-proccess__icon"><img src="@/assets/images/ic-bronze-small.svg" alt="img" /></span>
                  <div class="member-proccess__range">
                     <p class="member-proccess__percent" :style="typePoint">
                        <span class="member-proccess__data"
                           >{{ Number(pointValue).toLocaleString('en-US', { maximumFractionDigits: 0 }) }}
                           {{ $t('unit.upoint_low') }}</span
                        >
                     </p>
                  </div>
                  <span class="member-proccess__icon"><img src="@/assets/images/ic-sliver-small.svg" alt="img" /></span>
               </div>
               <!--sliver-->
               <div class="member-proccess__detail" v-if="rankName == 'Silver'">
                  <span class="member-proccess__icon"><img src="@/assets/images/ic-sliver-small.svg" alt="img" /></span>
                  <div class="member-proccess__range">
                     <p class="member-proccess__percent" :style="typePoint">
                        <span class="member-proccess__data"
                           >{{ Number(pointValue).toLocaleString('en-US', { maximumFractionDigits: 0 }) }}
                           {{ $t('unit.upoint_low') }}</span
                        >
                     </p>
                  </div>
                  <span class="member-proccess__icon"><img src="@/assets/images/ic-gold-small.svg" alt="img" /></span>
               </div>
               <!--sliver-->
               <div class="member-proccess__detail" v-if="rankName == 'Gold'">
                  <span class="member-proccess__icon"><img src="@/assets/images/ic-gold-small.svg" alt="img" /></span>
                  <div class="member-proccess__range">
                     <p class="member-proccess__percent" :style="typePoint">
                        <span class="member-proccess__data"
                           >{{ Number(pointValue).toLocaleString('en-US', { maximumFractionDigits: 0 }) }}
                           {{ $t('unit.upoint_low') }}</span
                        >
                     </p>
                  </div>
                  <span class="member-proccess__icon"
                     ><img src="@/assets/images/ic-platinum-small.svg" alt="img"
                  /></span>
               </div>
               <!--platinum-->
               <div class="member-proccess__detail" v-if="rankName == 'Diamond'">
                  <span class="member-proccess__icon"
                     ><img src="@/assets/images/ic-platinum-small.svg" alt="img"
                  /></span>
                  <div class="member-proccess__range">
                     <p class="member-proccess__percent" :style="typePoint">
                        <span class="member-proccess__data"
                           >{{ Number(pointValue).toLocaleString('en-US', { maximumFractionDigits: 0 }) }}
                           {{ $t('unit.upoint_low') }}</span
                        >
                     </p>
                  </div>
               </div>
            </div>
            <div class="member-level">
               <h4 class="member-infor__sub">{{ $t('ucare.member_info.level_up') }}</h4>
               <ul class="member-level__list">
                  <li class="member-level__item">
                     <span class="member-level__icon"><i class="icon-fonts icon-fonts--star"></i></span>
                     <div class="member-level__info">
                        <span class="member-level__name">{{ $t('ucare.member_info.gain') }}</span
                        ><span class="member-level__value"
                           >{{ Number(gain).toLocaleString('en-US', { maximumFractionDigits: 0 }) }}
                           {{ $t('unit.upoint_low') }}</span
                        >
                     </div>
                  </li>
                  <li class="member-level__item">
                     <span class="member-level__icon"><i class="icon-fonts icon-fonts--clock-2"></i></span>
                     <div class="member-level__info">
                        <span class="member-level__name">{{ $t('ucare.member_info.before') }}</span
                        ><span class="member-level__value">{{ pointExpireDate }}</span>
                     </div>
                  </li>
               </ul>
            </div>
            <div class="ranking-member">
               <ul class="ranking-member__list">
                  <li :class="classW">
                     <span class="ranking-member__icon"><img src="@/assets/images/ic-welcome.svg" alt="img" /></span>
                     <div class="ranking-member__info">
                        <h5 class="ranking-member__name">
                           <a class="ranking-member__name-link" href="javascript:void(0)">{{
                              $t('ucare.member_info.welcome')
                           }}</a>
                        </h5>
                     </div>
                  </li>
                  <li :class="classB">
                     <span class="ranking-member__icon"><img src="@/assets/images/ic-bronze.svg" alt="img" /></span>
                     <div class="ranking-member__info">
                        <h5 class="ranking-member__name">
                           <a class="ranking-member__name-link" href="javascript:void(0)">{{
                              $t('ucare.member_info.bronze')
                           }}</a>
                        </h5>
                     </div>
                  </li>
                  <li :class="classS">
                     <span class="ranking-member__icon"><img src="@/assets/images/ic-sliver.svg" alt="img" /></span>
                     <div class="ranking-member__info">
                        <h5 class="ranking-member__name">
                           <a class="ranking-member__name-link" href="javascript:void(0)">{{
                              $t('ucare.member_info.sliver')
                           }}</a>
                        </h5>
                     </div>
                  </li>
                  <li :class="classG">
                     <span class="ranking-member__icon"><img src="@/assets/images/ic-gold.svg" alt="img" /></span>
                     <div class="ranking-member__info">
                        <h5 class="ranking-member__name">
                           <a class="ranking-member__name-link" href="javascript:void(0)">{{
                              $t('ucare.member_info.gold')
                           }}</a>
                        </h5>
                     </div>
                  </li>
                  <li :class="classP">
                     <span class="ranking-member__icon"><img src="@/assets/images/ic-platinum.svg" alt="img" /></span>
                     <div class="ranking-member__info">
                        <h5 class="ranking-member__name">
                           <a class="ranking-member__name-link" href="javascript:void(0)">{{
                              $t('ucare.member_info.platinum')
                           }}</a>
                        </h5>
                     </div>
                  </li>
               </ul>
            </div>
            <div class="member-benefits">
               <h4 class="member-infor__sub">{{ $t('ucare.member_info.benefits_of') }} {{ cardName }}</h4>
               <div class="member-benefits__content">
                  <p style="white-space: pre-wrap">{{ description }}</p>
               </div>
            </div>
            <div class="member-exchange">
              <div class="tabs-list">
                <div class="nav tabs-list__nav" role="tablist">
                  <a class="tabs-list__link active" href="#exchange-gift" role="tab" data-bs-toggle="tab">{{$t("ucare.member_info.history_exchange_gift")}}</a>
                  <a class="tabs-list__link" href="#exchange-service" role="tab" data-bs-toggle="tab">{{$t("ucare.member_info.history_exchange_service")}}</a>
                </div>
                <div class="tabs-list__content tab-content">
                  <div class="tab-pane fade show active" id="exchange-gift" role="tabpanel">
                    <div class="member-exchange__content">
                     <ul class="member-exchange__list">
                        <li class="member-exchange__item" v-for="(gift, index) in exchangeGiftHistory" :key="index" @click="selectGift(gift)" data-bs-toggle="modal" data-bs-target="#exchange">
                          <div class="member-exchange__images"><img :src="gift.imageUrl" alt="img"></div>
                          <div class="member-exchange__detail">
                            <h5 class="member-exchange__name"><a class="member-exchange__name-link" href="javascript:void(0)">{{gift.giftName}}</a></h5>
                            <p class="member-exchange__des">{{$t("ucare.member_info.redeem_upoint")}}: {{ Number(gift.redeemPoint).toLocaleString('en-US', { maximumFractionDigits: 0 }) }} {{ $t('unit.upoint') }}</p>
                            <p class="member-exchange__des">{{$t("ucare.member_info.exchange_date")}}: {{gift.exchangDate }}</p>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="tab-pane fade" id="exchange-service" role="tabpanel">
                    <div class="member-exchange__content">
                      <ul class="member-exchange__list">
                        <li class="member-exchange__item" v-for="(gift, index) in exchangeServiceHistory" :key="index">
                          <div class="member-exchange__images"><img :src="'data:image/jpeg;base64,' + gift.imageGift" alt="img"></div>
                          <div class="member-exchange__detail">
                            <h5 class="member-exchange__name"><a class="member-exchange__name-link" href="javascript:void(0)">{{gift.giftName}}</a></h5>
                            <p class="member-exchange__des">{{$t("ucare.member_info.redeem_upoint")}}: {{ Number(gift.redeemPoint).toLocaleString('en-US', { maximumFractionDigits: 0 }) }} {{ $t('unit.upoint') }}</p>
                            <p class="member-exchange__des">{{$t("ucare.member_info.exchange_date")}}: {{gift.exchangeDate }}</p>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
         </div>
      </div>
      <div class="modal fade modal-custom" id="exchange" tabindex="-1" aria-labelledby="exchange" style="display: none;" aria-hidden="true">
         <div class="modal-dialog" role="document">
            <div class="modal-custom__content">
               <div class="modal-custom__header"><span class="modal-custom__action" data-bs-dismiss="modal"><i class="icon-fonts icon-fonts--close"></i></span></div>
               <div class="modal-custom__body">
               <h3 class="modal-custom__title">{{$t("ucare.member_info.expired_date")}}: {{selectedGift.expireDate}}</h3>
               <div class="modal-custom__code">{{selectedGift.exchangeCode}}</div>
               <div class="modal-custom__images"><img :src="'data:image/jpeg;base64,' + selectedGift.giftQrCode"></div>
               <p class="modal-custom__des">{{$t("ucare.member_info.qr_message")}}</p>
               <p class="modal-custom__highlight">{{selectedGift.giftName}}</p>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>
<script>
import BaseComponent from '@/mixins/BaseComponent'
import service from './../../services/UCareService'

export default {
   name: 'MemberInfo',
   mixins: [BaseComponent],
   components: {},
   data() {
      return {
         rankName: '',
         cardClass: 'member-card member-card--welcome',
         name: '',
         phoneNumber: '',
         cardName: '',
         expCard: '00/00',
         minPoint: 0,
         maxPoint: 1000,
         pointValue: 845,
         typePoint: '',
         gain: 0,
         classW: 'ranking-member__item',
         classB: 'ranking-member__item',
         classS: 'ranking-member__item',
         classG: 'ranking-member__item',
         classP: 'ranking-member__item',
         description: '',
         pointExpireDate: '',
         exchangeGiftHistory: [],
         exchangeServiceHistory: [],
         selectedGift: {}
      }
   },
   created() {
      this.setTitle(this.$i18n.t('ucare.member_info.title'))
      this.getData()
      let userInfoStr = localStorage.getItem('user_info')
      if (userInfoStr) {
         let userInfo = JSON.parse(userInfoStr)
         if (userInfo['isdn']) {
            this.phoneNumber = userInfo['isdn'].slice(0, 5) + 'XXXXX'
         }
      }
   },
   methods: {
      getData() {
         //Lấy rank của thẻ
         const getAccountRankInfo = service
            .getAccountRankInfo()
            .then((response) => {
               if (response.accountRankDTO) {
                  this.rankName = response.accountRankDTO.rankName
                  this.pointExpireDate = response.accountRankDTO.pointExpireDate
                  let classItem = 'ranking-member__item'
                  let classCurrent = 'ranking-member__item current'
                  let classDone = 'ranking-member__item done'
                  if (this.rankName == 'Loyal') {
                     this.cardClass = 'member-card member-card--welcome'
                     this.cardName = 'Welcome'
                     this.classW = classCurrent
                     this.classB = classItem
                     this.classS = classItem
                     this.classG = classItem
                     this.classP = classItem
                  }
                  if (this.rankName == 'Titan') {
                     this.cardClass = 'member-card member-card--bronze'
                     this.cardName = 'Bronze'
                     this.classW = classDone
                     this.classB = classCurrent
                     this.classS = classItem
                     this.classG = classItem
                     this.classP = classItem
                  }
                  if (this.rankName == 'Silver') {
                     this.cardClass = 'member-card member-card--sliver'
                     this.cardName = 'Silver'
                     this.classW = classDone
                     this.classB = classDone
                     this.classS = classCurrent
                     this.classG = classItem
                     this.classP = classItem
                  }
                  if (this.rankName == 'Gold') {
                     this.cardClass = 'member-card member-card--gold'
                     this.cardName = 'Gold'
                     this.classW = classDone
                     this.classB = classDone
                     this.classS = classDone
                     this.classG = classCurrent
                     this.classP = classItem
                  }
                  if (this.rankName == 'Diamond') {
                     this.cardClass = 'member-card member-card--vip'
                     this.cardName = 'Vip'
                     this.classW = classDone
                     this.classB = classDone
                     this.classS = classDone
                     this.classG = classDone
                     this.classP = classCurrent
                  }
                  if (response.accountRankDTO.endDate) {
                     if (response.accountRankDTO.rankName === 'Loyal') {
                        this.expCard = this.$i18n.t("ucare.member_info.unlimited")
                     } else {
                        let date = new Date(response.accountRankDTO.endDate)
                        this.expCard =
                           (date.getMonth() > 8 ? date.getMonth() + 1 : '0' + (date.getMonth() + 1)) +
                           '/' +
                           date.getFullYear()
                     }
                  }
               }

            //Lấy thông tin điểm thẻ
            service
               .getAccountPointInfo()
               .then((response) => {
                  if (response.listAccountPoint) {
                     //this.name = response.listAccountPoint.name;
                     this.pointValue = 0
                     for (var i = 0; i < response.listAccountPoint.length; i++) {
                        if (response.listAccountPoint[i].pointType == 1) {
                           this.pointValue = this.pointValue + response.listAccountPoint[i].pointValue
                        }
                     }

                     //Lấy thông tin tăng điểm thẻ
                     service
                        .getRankDefineInfo()
                        .then((response) => {
                           if (response.listRankDefine) {
                              var rankDefine = response.listRankDefine.filter((x) => x.rankName == this.rankName)
                              if (rankDefine.length > 0) {
                                 this.minPoint = rankDefine[0].minPoint
                                 this.maxPoint = rankDefine[0].maxPoint
                                 let width = Math.round(
                                    ((this.pointValue - this.minPoint) * 100) / (this.maxPoint - this.minPoint)
                                 )
                                 this.typePoint = 'width: ' + width + '%'

                                 this.gain = rankDefine[0].maxPoint - this.pointValue
                                 this.description = rankDefine[0].description
                              }
                           }
                        }) 
                  }
               })
            })
             
            //Lấy thông tin chủ thẻ
            const getSubMainInfo = service
               .getSubMainInfo()
               .then((response) => {
                  if (response.wsResponse) {
                     this.name = response.wsResponse.name
                  }
               })

            const getPartnerGiftRedeemHistory = service
               .getPartnerGiftRedeemHistory({page: 1, limit: 1000, offset: 0})
               .then((response) => {
                  if (response.wsResponse.object && response.wsResponse.object.length > 0) {
                     this.exchangeGiftHistory = response.wsResponse.object
                  }
               })

            const getTelecomRedeemHistory = service
               .getTelecomRedeemHistory({page: 1, limit: 1000, offset: 0})
               .then((response) => {
                  if (response.listPointTransferHistoryDetail && response.listPointTransferHistoryDetail.length > 0) {
                     this.exchangeServiceHistory = response.listPointTransferHistoryDetail
                  }
               })

            this.showLoading()
            Promise.allSettled([getAccountRankInfo, getSubMainInfo, getPartnerGiftRedeemHistory, getTelecomRedeemHistory]).finally(() => {
               this.hideLoading()
            })
      },
      selectGift(gift){
         this.selectedGift = gift
      },
      onHistory() {
         this.$router.push('/ucare/point-history')
      }
   }
}
</script>
