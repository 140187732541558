import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from '@/plugins/vuex/store'
import i18n from '@/plugins/i18n'
import './plugins/vee-validate'
const app = new Vue({
   router,
   store,
   i18n,
   render: (h) => h(App)
}).$mount('#app')

export default app
