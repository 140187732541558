<template>
   <div class="main-container">
      <div class="nav-bar js-nav-bar">
         <div class="nav-bar__inner">
            <span class="nav-bar__icon" @click="goBack"><i class="icon-fonts icon-fonts--arrow-left"></i></span>
            <h3 class="nav-bar__title">{{$t('ftth.change_product.change_product')}}</h3>
         </div>
      </div>
      <div class="main-content">
        <ValidationObserver v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(onSubmit)">
          <div class="change-product">
            <div class="change-product__content">
              <div class="change-product__box">
                <div class="change-product__top">
                  <h5 class="change-product__sub">{{$t('ftth.change_product.current_information')}}</h5>
                </div>
                <ul class="change-product__list">
                  <li class="change-product__item"><span class="change-product__name">{{$t('ftth.change_product.account_name')}}</span><span class="change-product__value">{{changeProduct1.account_name}}</span></li>
                  <li class="change-product__item"><span class="change-product__name">{{$t('ftth.change_product.package_name')}}</span><span class="change-product__value">{{changeProduct1.oldProductCode}}</span></li>
                  <li class="change-product__item"><span class="change-product__name">{{$t('ftth.change_product.subtype')}}</span><span class="change-product__value">{{changeProduct1.oldSubType}} {{$t('ftth.change_product.months')}}</span></li>
                  <li class="change-product__item"><span class="change-product__name">{{$t('ftth.change_product.package_fee')}}</span><span class="change-product__value">{{Number(changeProduct1.oldPrice).toLocaleString('en-US', { maximumFractionDigits: 0 })}} {{$t('ftth.change_product.kip_month')}}</span></li>
                </ul>
              </div>
              <div class="change-product__box">
                <div class="change-product__top">
                  <h5 class="change-product__sub">{{$t('ftth.change_product.select_new_package')}}</h5>
                </div>
                <div class="change-product__form">
                  <div class="form-customize">
                    <Select :label="$t('ftth.change_product.select_package')"
                        :placeholder="$t('ftth.change_product.select_package')"
                        rules="required"
                        :dataSelect="step1.listPackage"
                        :isLabel="false"
                        v-model="step1.newProductCode" />
                  </div>
                  <div class="form-customize">
                    <Select :label="$t('ftth.change_product.select_new_subtype')"
                        :placeholder="$t('ftth.change_product.select_new_subtype')"
                        rules="required"
                        :dataSelect="step1.listSubType"
                        :isLabel="false"
                        v-model="step1.newSubType" />
                  </div>
                </div>
              </div>
              <div class="change-product__box">
                <div class="change-product__top">
                  <h5 class="change-product__sub">{{$t('ftth.change_product.pre_calculation')}}</h5>
                </div>
                <ul class="change-product__list">
                  <li class="change-product__item"><span class="change-product__name">{{$t('ftth.change_product.new_price')}}</span><span class="change-product__value">{{Number(step1.newPrice).toLocaleString('en-US', { maximumFractionDigits: 0 })}} {{$t('ftth.change_product.KIP')}}</span></li>
                  <li class="change-product__item"><span class="change-product__name">{{$t('ftth.change_product.need_to_pay')}}</span><span class="change-product__value highlight">{{Number(step1.paymentPrice).toLocaleString('en-US', { maximumFractionDigits: 0 })}}<span>{{$t('ftth.change_product.KIP')}}</span></span></li>
                </ul>
              </div>
            </div>
            <div class="change-product__btn">
              <!-- @click="onSubmit(invalid)" -->
              <button class="button button--primary button--full">{{$t('ftth.change_product.submit')}}
              </button>
            </div>
          </div>
          </form>
        </ValidationObserver>
        </div>
   </div>
</template>
<script>
import BaseComponent from '@/mixins/BaseComponent'
import service from './../../services/FTTHService'
import store from '@/plugins/vuex/store'
import { ValidationObserver } from 'vee-validate'
import Select from '@/components/form/Select'
import LocalStorage from '@/ultils/local-storage'

export default {
   name: 'FtthChangeProduct1',
   mixins: [BaseComponent],
   components: {
    Select,
      ValidationObserver
   },
   data() {
      return {
         changeProduct1:{
            account_name:"",
            oldProductCode:"",
            oldSubType:"",
            oldPrice:"",
            
         },
         step1:{
            newProductCode:store.state.step1.newProductCode,
            newSubType:store.state.step1.newSubType,
            listPackage:store.state.step1.listPackage,
            listSubType:store.state.step1.listSubType,
            newPrice:store.state.step1.newPrice,
            paymentPrice:store.state.step1.paymentPrice
        }       
      }
   },
   created() {
    //   let ftthAccountInfo = JSON.parse(LocalStorage.getItem('ftth_user_info'))
    //   this.fullName = ftthAccountInfo.custName
    //   this.phoneNumber = ftthAccountInfo.isdn
      this.getData()
      this.loadScript('js/select2.js')
   },
   watch: {
      async 'step1.newProductCode'(val){
         this.showLoading()
            let ftthAccountInfo = JSON.parse(LocalStorage.getItem('ftth_user_info')) 
            let account = ftthAccountInfo.isdn 
            //account = '512261318'
            // let _this = this
            await service
                .getListSubType({"account": account,"newProductCode":val})
                .then((response) => {
                if (response.errorCode === '0') {
                    if(response.object.listSubType && response.object.listSubType.length != 0){
                        // debugger; // eslint-disable-line no-debugger
                        this.step1.listSubType = response.object.listSubType.map(x=>({ code: x, name: x })
                        )
                        this.step1.newSubType = response.object.listSubType[0]
                        // if(store.state.step1.newSubType == ''){
                          
                        // }
                        this.loadScript('js/select2.js')
                    }
                }
            })
            console.log('this.step1.newSubType',this.step1.newSubType)
            if(this.step1.newSubType && this.step1.newSubType != null){
              await service
                  .getAmountInfo({"account": account,"newProductCode":val,"newSubType":this.step1.newSubType})
                  .then((response) => {
                  if (response.errorCode === '0') {
                      this.step1.newPrice = response.object.newPrice
                      this.step1.paymentPrice = response.object.paymentPrice
                  }
              })
            }
            this.hideLoading()
      },
      async 'step1.newSubType'(val){
         this.showLoading()
            let ftthAccountInfo = JSON.parse(LocalStorage.getItem('ftth_user_info')) 
            let account = ftthAccountInfo.isdn 
            //account = '512261318'
            if(this.step1.newProductCode && this.step1.newProductCode != ""){
                await service
                    .getAmountInfo({"account": account,"newSubType":val,"newProductCode":this.step1.newProductCode})
                    .then((response) => {
                    if (response.errorCode === '0') {
                        this.step1.newPrice = response.object.newPrice
                        this.step1.paymentPrice = response.object.paymentPrice
                    }
                })
            }
             this.hideLoading()
      }
   },
   methods: {
      async getData() {
         this.showLoading()
          let ftthAccountInfo = JSON.parse(LocalStorage.getItem('ftth_user_info')) 
          let account = ftthAccountInfo.isdn 
          let ftthAccount = JSON.parse(LocalStorage.getItem('ftth_account_info')) 
          this.changeProduct1.account_name = ftthAccount.accountNo
          //account = '512261318'

         await service
            .prepareInfoPackage({"account": account})
            .then((response) => {
               if (response.errorCode === '0') {
                  this.changeProduct1.oldProductCode = response.object.oldProductCode
                  this.changeProduct1.oldSubType = response.object.oldSubType
                  this.changeProduct1.oldPrice = response.object.oldPrice
                  
                  if(response.object.listPackage && response.object.listPackage.length != 0){
                    this.step1.listPackage = response.object.listPackage.map(x=>({ code: x.productCode, name: x.productName }))
                    if(store.state.step1.newProductCode == ''){
                      this.step1.newProductCode = response.object.listPackage[0].productCode
                    }
                    
                    this.loadScript('js/select2.js')
                  }
               } else {
                store.dispatch('setNotification', { content: response.errorMessage ?? response.errorMessage })
                this.$router.push('/ftth/service')
               }
            }).finally(() => {
                  this.hideLoading()
            })

            this.hideLoading()
        
      },
      // invalid
      async onSubmit(){
        
        // if(invalid) {
        //     return
        //  }
         this.showLoading()
         try {
          let ftthAccountInfo = JSON.parse(LocalStorage.getItem('ftth_user_info')) 
            let account = ftthAccountInfo.isdn 
            //account = '512261318'
          let dataPost = {
              account:account,
              newProductCode:this.step1.newProductCode,
              newSubType:this.step1.newSubType
          }
          await service
              .confirmChangePackage(dataPost)
              .then((response) => {
              if (response.errorCode === '0') {
                  this.hideLoading()
                  let payload = this.step1
                  store.commit('setStep1', payload)
                  this.$router.push('/ftth/change-product-2')
                  
              }else {
                  store.dispatch('setNotification', { content: response.errorMessage ?? response.errorMessage })
              }
          })
         } catch (error) {
           this.hideLoading()
         }
      }
   }
}
</script>
