<template>
   <div class="main-container">
      <div class="nav-bar js-nav-bar">
         <div class="nav-bar__inner">
            <span class="nav-bar__icon" @click="goBack"><i class="icon-fonts icon-fonts--arrow-left"></i></span>
            <h3 class="nav-bar__title">{{$t('ftth.change_product.change_product')}}</h3>
         </div>
      </div>
      <div class="main-content">
          <div class="request-success">
            <h3 class="request-success__title">{{$t('ftth.change_product.payment_successfully')}}</h3>
            <p class="request-success__des">{{$t('ftth.change_product.title2')}}</p>
            <div class="request-success__content">
            <div class="request-success__box">
                <div class="request-success__top">
                <h5 class="request-success__sub">{{$t('ftth.change_product.payment_information')}}</h5>
                </div>
                <ul class="request-success__list">
                <li class="request-success__item"><span class="request-success__name">{{$t('ftth.change_product.transaction_id')}}</span><span class="request-success__value">{{step3.transId}}</span></li>
                <li class="request-success__item"><span class="request-success__name">{{$t('ftth.change_product.date')}}</span><span class="request-success__value">{{getUpdateDate(step3.transDate)}}</span></li>
                <li class="request-success__item"><span class="request-success__name">{{$t('ftth.change_product.amount')}}</span><span class="request-success__value highlight">{{Number(step1.paymentPrice).toLocaleString('en-US', { maximumFractionDigits: 0 })}}<span>{{$t('ftth.change_product.KIP')}}</span></span></li>
                <li class="request-success__item"><span class="request-success__name">{{$t('ftth.change_product.fee')}}</span><span class="request-success__value success">{{$t('ftth.change_product.free')}}</span></li>
                <li class="request-success__item"><span class="request-success__name">{{$t('ftth.change_product.description')}}</span><span class="request-success__value">{{$t('ftth.change_product.change_FTTH_product')}}</span></li>
                </ul>
            </div>
            </div>
            <div class="request-success__btn">
            <button class="button button--primary button--full" @click="backTohome">{{$t('ftth.change_product.back_to_homepage')}}
            </button>
            </div>
        </div>
      </div>
   </div>
</template>
<script>
import BaseComponent from '@/mixins/BaseComponent'
//import service from './../../services/FTTHService'
import store from '@/plugins/vuex/store'
// import LocalStorage from '@/ultils/local-storage'
import moment from 'moment'


export default {
   name: 'FtthChangeProduct3',
   mixins: [BaseComponent],
   components: {
   },
   data() {
      return {
         step3:{
            transId:store.state.step3.transId,
            timestamp:store.state.step3.timestamp,
            transDate:store.state.step3.transDate,
            serviceCode:store.state.step3.serviceCode,
            account:store.state.step3.account
        },
        step1:{
          newProductCode:store.state.step1.newProductCode,
          newSubType:store.state.step1.newSubType,
          newPrice:store.state.step1.newPrice,
          paymentPrice:store.state.step1.paymentPrice
        },    
      }
   },
   async created(){
        await this.getData()
   },
   methods: {
      async getData() {
        this.hideLoading()
      },
      backTohome(){
          this.$router.push("/ftth/service")
      },
      getUpdateDate(transDate){
        let timestamp  = parseInt(transDate)
        var date = new Date(timestamp)
        var _date = moment(date).format("hh:mm:ss DD/MM/YYYY");
        return _date        
    },
   }
}
</script>
