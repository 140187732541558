<script>
import BaseComponent from '@/mixins/BaseComponent'
import LocalStorage from '@/ultils/local-storage'
import axios from 'axios'

export default {
   name: 'CallBackLogin',
   mixins: [BaseComponent],
   data() {
      return {
         code: '',
         accessToken: '',
         refreshToken: '',
         phone: '',
         apiConfig: {
            method: 'post',
            url: '',
            data: null,
            headers: { 'Content-Type': 'application/json' },
            dataType: 'json',
            contentType: 'application/json'
         }
      }
   },
   created() {
      this.verify()
   },
   methods: {
      verify() {
         this.showLoading()
         let self = this
         this.apiConfig['method'] = 'post'
         this.apiConfig['url'] = process.env.VUE_APP_VERIFY_API
         this.apiConfig['data'] = {
            code: this.$route.query.code,
            secretKey: process.env.VUE_APP_SECRET_KEY
         }
         axios(this.apiConfig)
            .then(function (response) {
               if (response.data.status == '100') {
                  self.accessToken = response.data.data.accessToken
                  self.refreshToken = response.data.data.refreshToken
                  self.findPhone()
               } else {
                  self.$router.push('/login')
                  self.$store.dispatch('setNotification', { content: response.data.error })
                  self.hideLoading()
               }
            })
            .catch(function () {
               self.$router.push('/login')
               self.$store.dispatch('setNotification', { content: self.$i18n.t('errors.system') })
               self.hideLoading()
            })
      },
      findPhone() {
         this.showLoading()
         let self = this
         this.apiConfig['method'] = 'get'
         this.apiConfig['url'] = process.env.VUE_APP_FIND_PHONE_API
         this.apiConfig['headers']['Authorization'] = `Bearer ${this.accessToken}`

         axios(this.apiConfig)
            .then(function (response) {
               if (response.data.status == '100') {
                  self.phone = response.data.data.phone
                  self.addTokenLogin()
               } else {
                  self.$router.push('/login')
                  self.$store.dispatch('setNotification', { content: response.data.error })
                  self.hideLoading()
               }
            })
            .catch(function () {
               self.$router.push('/login')
               self.$store.dispatch('setNotification', { content: self.$i18n.t('errors.system') })
               self.hideLoading()
            })
      },
      addTokenLogin() {
         this.showLoading()
         let self = this
         this.apiConfig['method'] = 'post'
         this.apiConfig['url'] = process.env.VUE_APP_ADD_TOKEN_LOGIN_API
         this.apiConfig['headers']['Authorization'] = `Bearer ${this.accessToken}`
         this.apiConfig['data'] = {
            username: this.phone,
            appCode: 'MyVTG',
            language: LocalStorage.getItem('lang')
         }

         axios(this.apiConfig)
            .then(function (response) {
               if (!response.data.errorMessage) {
                  let userInfo = {
                     token: response.data.token,
                     isdn: response.data.username,
                     sessionId: response.data.sessionId
                  }
                  LocalStorage.setItem('user_info', JSON.stringify(userInfo))
                  self.$router.push('/mobile/home')
               } else {
                  self.$router.push('/login')
                  self.$store.dispatch('setNotification', { content: response.data.errorMessage })
               }
            })
            .catch(function () {
               self.$router.push('/login')
               self.$store.dispatch('setNotification', { content: self.$i18n.t('errors.system') })
            })
            .finally(() => {
               self.hideLoading()
            })
      }
   }
}
</script>
