<template>
   <div class="main-container">
      <div class="nav-bar js-nav-bar" @click="goBack">
         <div class="nav-bar__inner">
            <span class="nav-bar__icon"><i class="icon-fonts icon-fonts--arrow-left"></i></span>
            <h3 class="nav-bar__title">{{ store.name }}</h3>
         </div>
      </div>
      <div class="main-content">
         <div class="find-store find-store--detail">
            <div class="find-store__map" style="width: 100%; height: 300px" id="map"></div>
            <ul class="find-store__list">
               <li class="find-store__item">
                  <div class="find-store__info">
                     <h5 class="find-store__name">{{ store.name }}</h5>
                     <p class="find-store__des">{{ store.addr }}</p>
                     <p class="find-store__des">{{ $t('mobile.map.working_hour') }}: {{ store.openTime }}</p>
                  </div>
                  <div class="find-store__location">
                     <span class="find-store__icon"><img src="@/assets/images/ic-location.svg" alt="img" /></span
                     ><span class="find-store__value"
                        >{{ Number(store.distance).toLocaleString('en-US', { maximumFractionDigits: 0 }) }}
                        {{ $t('unit.km') }}</span
                     >
                  </div>
               </li>
            </ul>
            <div class="find-store__btn">
               <button class="button button--primary button--medium" @click="call">{{ $t('mobile.map.call') }}</button>
               <button
                  class="button button--primary button--medium"
                  @click="showDirection"
                  :disabled="skipAccessLocation"
               >
                  {{ $t('mobile.map.show_direction') }}
               </button>
            </div>
         </div>
      </div>
   </div>
</template>
<script>
import BaseComponent from '@/mixins/BaseComponent'
import LocalStorage from '@/ultils/local-storage'
import gmapsInit from '@/ultils/gmap'

export default {
   name: 'StoreDetail',
   mixins: [BaseComponent],
   data() {
      return {
         currentLocation: { lat: 0, lng: 0 },
         store: {},
         google: null,
         map: null,
         directionsService: null,
         directionsRenderer: null,
         skipAccessLocation: false
      }
   },
   mounted() {
      this.loadScript('js/scroll.js')
      this.setTitle(this.store.name)
      this.store = JSON.parse(LocalStorage.getItem('store'))
      this.skipAccessLocation = localStorage.getItem('skip_access_location') === 'true'
      this.loadMap()
   },
   methods: {
      async loadMap() {
         this.google = await gmapsInit()
         this.directionsService = new this.google.maps.DirectionsService()
         this.directionsRenderer = new this.google.maps.DirectionsRenderer()
         this.map = new this.google.maps.Map(document.getElementById('map'))
         this.directionsRenderer.setMap(this.map)

         // set maker for store
         this.storeLocation = { lat: this.store.latitude, lng: this.store.longitude }
         this.map.setZoom(15)
         this.map.setCenter(this.storeLocation)
         new this.google.maps.Marker({
            title: this.store.name,
            position: this.storeLocation,
            icon: './images/ic-tracker.svg',
            map: this.map
         })

         // get current location
         navigator.geolocation.getCurrentPosition((position) => {
            this.currentLocation = {
               lat: position.coords.latitude,
               lng: position.coords.longitude
            }
         })
      },
      showDirection() {
         this.directionsService
            .route({
               origin: this.currentLocation,
               destination: this.storeLocation,
               travelMode: this.google.maps.TravelMode.DRIVING
            })
            .then((response) => {
               this.directionsRenderer.setDirections(response)
            })
      },
      call() {
         window.open(`tel:${this.store.isdn}`)
      }
   }
}
</script>
