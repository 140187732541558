<template>
   <div class="main-container">
      <div class="nav-bar js-nav-bar" @click="goBack">
         <div class="nav-bar__inner">
            <span class="nav-bar__icon"><i class="icon-fonts icon-fonts--arrow-left"></i></span>
            <h3 class="nav-bar__title">{{ $t('ucare.gift_deals.gift_detail') }}</h3>
         </div>
      </div>
      <div class="main-content">
         <div class="gift-partner">
            <div class="exchange-point">
               <h4 class="exchange-point__name">{{ $t('ucare.gift_deals.available_upoint') }}</h4>
               <span class="exchange-point__value">{{
                  Number(exchangePoint).toLocaleString('en-US', { maximumFractionDigits: 0 })
               }}</span>
            </div>
            <div class="gift-partner__content">
               <div class="gift-partner__images"><img :src="banner" alt="img" /></div>
               <div class="gift-detail">
                  <div class="gift-detail__left">
                     <h5 class="gift-detail__name">
                        <a class="gift-detail__name-link" href="javascript:void(0)">{{ gift.giftName }}</a>
                     </h5>
                     <p class="gift-detail__point">
                        {{ Number(gift.giftPoint).toLocaleString('en-US', { maximumFractionDigits: 0 }) }}
                        {{ $t('unit.upoint') }}
                     </p>
                  </div>
                  <div class="gift-detail__right">
                     <span class="gift-detail__icon"><i class="icon-fonts icon-fonts--peer"></i></span>
                     <p class="gift-detail__quantity">
                        {{ $t('ucare.gift_deals.quantity') }}:
                        {{ Number(gift.quantity).toLocaleString('en-US', { maximumFractionDigits: 0 }) }}
                     </p>
                  </div>
               </div>
               <ul class="amount-total">
                  <li class="amount-total__item"><span class="amount-total__name">{{$t("ucare.amount")}}</span>
                     <div class="amount-total__info">
                        <button class="button-qty button-minus" title="Minus"><i class="icon-fonts icon-fonts--minus" @click="decreaseAmount"></i>
                        </button>
                        <input class="form-qty" type="number" step="1" min="1" :max="max" name="quantity" value="1" title="Qty" readonly v-model="amount">
                        <button class="button-qty button-plus" title="Plus"><i class="icon-fonts icon-fonts--add" @click="increaseAmount"></i>
                        </button>
                     </div>
                  </li>
                  <li class="amount-total__item"><span class="amount-total__name">{{$t("ucare.total_upoint")}}</span><span class="amount-total__value">
                     {{ Number(total).toLocaleString('en-US', { maximumFractionDigits: 0 }) }}
                     {{ $t('unit.upoint') }}</span>
                  </li>
               </ul>
               <p class="gift-partner__des" v-html="gift.description"></p>
            </div>
            <div class="gift-partner__btn">
               <button class="button button--primary button--full" :disabled="max === 0 || max < amount" @click="redirectToConfirm">
                  {{ $t('ucare.exchange') }}
               </button>
            </div>
         </div>
      </div>
   </div>
</template>
<script>
import BaseComponent from '@/mixins/BaseComponent'
import LocalStorage from '@/ultils/local-storage'

export default {
   name: 'Detail',
   mixins: [BaseComponent],
   data() {
      return {
         exchangePoint: 0,
         gift: {},
         amount: 1,
         total: 0,
         banner: '',
         max: 0,
      }
   },
   created() {
      this.setTitle(this.$i18n.t('ucare.gift_deals.gift_detail'))
      let data = JSON.parse(LocalStorage.getItem('gift_deal')) ?? {}
      this.gift = data.gift ?? {}
      this.banner = this.gift.banner ? this.gift.banner : this.gift.imageUrl
      this.exchangePoint = data.exchangePoint
      this.max = this.gift.giftPoint !== 0 ? Math.floor(this.exchangePoint/this.gift.giftPoint) : 0
      if(this.max > this.gift.quantity) this.max = this.gift.quantity
      if(this.max === 0) this.amount = 0
      this.total = this.amount*this.gift.giftPoint
   },
   methods: {
      decreaseAmount(){
         if(this.amount > 1){
            this.amount -= 1
            this.total = this.amount*this.gift.giftPoint
         }
      },
      increaseAmount(){
         if(this.amount < this.max){
            this.amount += 1
            this.total = this.amount*this.gift.giftPoint
         } else {
            this.$store.dispatch('setNotification', {
                  title: this.$i18n.t('notification.title'),
                  content: this.$i18n.t('ucare.data_deals.point_not_enough'),
                  closeButton: this.$i18n.t('buttons.close')
               })
         }
      },
      redirectToConfirm() {
         this.$router.push(`/ucare/gift-deals/confirm/1/${this.amount}`)
      }
   }
}
</script>
