import Login from '@/modules/ftth/components/home/Login'
import AccountList from '@/modules/ftth/components/home/AccountList'
import Service from '@/modules/ftth/components/home/Service'
import ChargeHistory from '@/modules/ftth/components/charge-history/ChargeHistory'
import PrechargeHistory from '@/modules/ftth/components/charge-history/PrechargeHistory'
import ReceiptHistory from '@/modules/ftth/components/charge-history/ReceiptHistory'
import FtthPackage from '@/modules/ftth/components/change-package/FtthPackage'
import FtthPackageDetail from '@/modules/ftth/components/change-package/FtthPackageDetail'
import FtthOrder from '@/modules/ftth/components/change-package/FtthOrder'
import FtthSuccess from '@/modules/ftth/components/change-package/FtthSuccess'
import ViewInvoice from '@/modules/ftth/components/view-invoice/ViewInvoice'
import DetailViewInvoice from '@/modules/ftth/components/view-invoice/Detail'
import ChangePassword from '@/modules/ftth/components/change-password/ChangePassword'
import NumberList from '@/modules/ftth/components/extra-100-minutes/NumberList'
import AddNewNumber from '@/modules/ftth/components/extra-100-minutes/AddNewNumber'
import ChangeProduct1 from '@/modules/ftth/components/change-product/ChangeProduct1'
import ChangeProduct2 from '@/modules/ftth/components/change-product/ChangeProduct2'
import ChangeProduct3 from '@/modules/ftth/components/change-product/ChangeProduct3'
import ErrorReport from '@/modules/ftth/components/report-error/ErrorReport'
import CreateNewErrorReport from '@/modules/ftth/components/report-error/CreateNewErrorReport'

export default [
   {
      path: '/ftth/login',
      component: Login
   },
   {
      path: '/ftth/account-list',
      component: AccountList
   },
   {
      path: '/ftth/service',
      component: Service
   },
   {
      path: '/ftth/charge-history',
      component: ChargeHistory
   },
   {
      path: '/ftth/precharge-history',
      component: PrechargeHistory
   },
   {
      path: '/ftth/receip-history',
      component: ReceiptHistory
   },
   {
      path: '/ftth/ftth-package',
      component: FtthPackage
   },
   {
      path: '/ftth/ftth-package-detail',
      component: FtthPackageDetail
   },
   {
      path: '/ftth/ftth-order',
      component: FtthOrder
   },
   {
      path: '/ftth/ftth-order-success',
      component: FtthSuccess
   },
   {
      path: '/ftth/view-invoice',
      component: ViewInvoice
   },
   {
      path: '/ftth/detail-view-invoice',
      component: DetailViewInvoice
   },
   {
      path: '/ftth/change-pass',
      component: ChangePassword
   },
   {
      path: '/ftth/number-list',
      component: NumberList
   },
   {
      path: '/ftth/add-number-list',
       component: AddNewNumber
   },
   {
      path: '/ftth/change-product-1',
       component: ChangeProduct1
   },
   {
      path: '/ftth/change-product-2',
       component: ChangeProduct2
   },
   {
      path: '/ftth/change-product-3',
       component: ChangeProduct3
   },
   {
      path: '/ftth/error-report',
       component: ErrorReport
   },
   {
      path: '/ftth/create-new-error-report',
       component: CreateNewErrorReport
   }
]
