<template>
   <div class="main-container">
        <div class="nav-bar js-nav-bar" @click="goBack()">
          <div class="nav-bar__inner"><span class="nav-bar__icon"><i class="icon-fonts icon-fonts--arrow-left"></i></span>
            <h3 class="nav-bar__title">{{$t('mobile.change_to_prepaid.title')}}</h3>
          </div>
        </div>
        <div class="main-content">
          <div class="change-postpaid">
            <div class="steps-bar">
              
            </div>
            <div class="request-success">
              <h3 class="request-success__title">{{$t('mobile.change_to_prepaid.title2')}}</h3>
              <p class="request-success__des">{{$t('mobile.change_to_prepaid.notification')}}</p>
              <div class="request-success__content">
                <div class="request-success__box">
                  <div class="request-success__top">
                    <h5 class="request-success__sub">{{$t('mobile.change_to_prepaid.title2')}}</h5>
                  </div>
                  <ul class="request-success__list">
                    <li class="request-success__item"><span class="request-success__name">{{$t('mobile.change_to_prepaid.order_code')}}</span><span class="request-success__value">{{getOrderCode()}}</span></li>
                    <li class="request-success__item"><span class="request-success__name">{{$t('mobile.change_to_prepaid.date_of_order')}}</span><span class="request-success__value">{{getUpdateDate()}}</span></li>
                    <li class="request-success__item"><span class="request-success__name">{{$t('mobile.change_to_prepaid.Status')}}</span><span class="request-success__value">{{getStatus()}}</span></li>
                  </ul>
                </div>
              </div>
              <div class="request-success__btn">
                <button class="button button--primary button--full" @click="backToHome">{{$t('mobile.change_to_prepaid.back_to_homepage')}}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
</template>
<script>
import BaseComponent from '@/mixins/BaseComponent'
//import LocalStorage from '@/ultils/local-storage'
import moment from 'moment'

export default {
   name: 'ConvertService',
   mixins: [BaseComponent],
   components: {
   },
   data() {
      return {
         
      }
   },
   created() {
        this.loadScript('js/select2.js')
   },
   methods: {
       backToHome(){
           this.$router.push('/mobile/home')
       },
       getOrderCode(){
         return localStorage.getItem("order_code")
       },
       getUpdateDate(){
         let timestamp  = parseInt(localStorage.getItem("updateDate"))
          if(timestamp){
         var date = new Date(timestamp)
        var _date = moment(date).format("DD/MM/YYYY");
         return _date 
         }       
        },
       getStatus(){
         return localStorage.getItem("statusName")
       }
   },
}
</script>
