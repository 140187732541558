<template>
   <div class="main-container">
      <div class="nav-bar js-nav-bar" @click="goBack">
         <div class="nav-bar__inner">
            <span class="nav-bar__icon"><i class="icon-fonts icon-fonts--arrow-left"></i></span>
            <h3 class="nav-bar__title">{{ $t('home.functions.view_invoice') }}</h3>
         </div>
      </div>
      <div class="main-content">
         <div class="view-invoice">
            <div class="view-invoice__form">
               <div class="form-2column">
                  <div class="form-customize">
                     <label class="label-title">{{ $t('ftth.view_invoice.time_start') }} </label>
                     <input
                        id="js-datepicker"
                        class="form-control js-datepicker"
                        type="text"
                        v-model="timeStart"
                        readonly
                        v-show="type != 2"
                     />
                     <input
                        id="js-month-year"
                        class="form-control js-month-year"
                        type="text"
                        v-model="timeStart"
                        readonly
                        v-show="type == 2"
                     />
                  </div>
                  <div class="form-customize">
                     <label class="label-title">{{ $t('ftth.view_invoice.time_end') }} </label>
                     <input
                        class="form-control js-datepicker"
                        type="text"
                        v-model="timeEnd"
                        :disabled="type == 2"
                        readonly
                     />
                  </div>
               </div>
               <div class="form-full">
                  <div class="form-customize">
                     <label class="label-title">{{ $t('ftth.view_invoice.type') }}</label>
                     <div class="select-box js-select-box">
                        <select class="js-custom-select" :data-placeholder="$t('ftth.view_invoice.receipt')" v-model="type">
                           <option value="0">{{ $t('ftth.view_invoice.receipt') }}</option>
                           <option value="1">{{ $t('ftth.view_invoice.invoice') }}</option>
                           <option value="2">{{ $t('ftth.view_invoice.invoice_statement') }}</option>
                        </select>
                     </div>
                  </div>
               </div>
            </div>
            <div class="charge-box">
               <ul class="charge-box__list">
                  <li
                     class="charge-box__item"
                     v-for="(item, index) in data"
                     :key="index"
                     @click="redirectToDetail(item)"
                  >
                     <span class="charge-box__icon"><i class="icon-fonts icon-fonts--bill"></i></span>
                     <div class="charge-box__info">
                        <h5 class="charge-box__name">{{ item.name }}: {{ item.no }}</h5>
                        <span class="charge-box__time">{{ item.date }}</span>
                     </div>
                  </li>
               </ul>
            </div>
            <div class="view-invoice__btn">
               <button class="button button--primary button--full" @click="search">{{ $t('buttons.search') }}</button>
            </div>
         </div>
      </div>
   </div>
</template>
<script>
import BaseComponent from '@/mixins/BaseComponent'
import service from './../../services/FTTHService'
import { InvoiceType, DATE_FORMAT, MONTH_FORMAT } from './../../constants/data'
import moment from 'moment'
import LocalStorage from '@/ultils/local-storage'

export default {
   name: 'ViewInvoice',
   mixins: [BaseComponent],
   data() {
      return {
         timeStart: '',
         timeEnd: '',
         type: InvoiceType.Receipt,
         data: []
      }
   },
   mounted() {
      this.setTitle(this.$i18n.t('home.functions.view_invoice'))
      this.loadScript('js/date-month-picker.js')
      this.loadScript('js/date-picker.js')
      this.loadScript('js/select2.js')
      this.loadScript('js/scroll.js')
      if (LocalStorage.getItem('invoice_detail')) {
         let params = JSON.parse(LocalStorage.getItem('invoice_detail')).searchParams
         this.timeStart = params.timeStart
         this.timeEnd = params.timeEnd
         this.type = params.type
         this.search()
      } else {
         this.timeStart = new moment().subtract(2, 'months').format(DATE_FORMAT)
         this.timeEnd = moment().format(DATE_FORMAT)
      }
   },
   methods: {
      search() {
         this.showLoading()
         if (this.type == InvoiceType.Receipt) {
            let data = {
               fromDate: this.getMiliseconds(this.timeStart, DATE_FORMAT),
               serviceType: 1,
               ftthAccount: LocalStorage.getItem('ftth_account'),
               toDate: this.getMiliseconds(this.timeEnd, DATE_FORMAT),
               accountType: '1',
               type: 0
            }
            service
               .getListReceipt(data)
               .then((response) => {
                  if (response.errorCode !== '1') {
                     let result = response.wsResponse ?? []
                     this.data = result.map((d) => {
                        return {
                           type: InvoiceType.Receipt,
                           name: this.$i18n.t('ftth.view_invoice.receipt_no'),
                           id: d.paymentId,
                           no: d.invoiceNo,
                           date: d.bankDate
                        }
                     })
                  } else {
                     this.$store.dispatch('setNotification', { content: response.message })
                  }
               })
               .finally(() => {
                  LocalStorage.removeItem('invoice_detail')
                  this.hideLoading()
               })
         } else if (this.type == InvoiceType.Invoice) {
            let data = {
               fromDate: this.getMiliseconds(this.timeStart, DATE_FORMAT),
               serviceType: 1,
               ftthAccount: LocalStorage.getItem('ftth_account'),
               toDate: this.getMiliseconds(this.timeEnd, DATE_FORMAT),
               accountType: '1',
               type: 1
            }
            service
               .getListInvoice(data)
               .then((response) => {
                  if (response.errorCode !== '1') {
                     let result = response.wsResponse ?? []
                     this.data = result.map((d) => {
                        return {
                           type: InvoiceType.Invoice,
                           name: this.$i18n.t('ftth.view_invoice.invoice_no'),
                           id: d.paymentId,
                           no: d.invoiceNo,
                           date: d.bankDate
                        }
                     })
                  } else {
                     this.$store.dispatch('setNotification', { content: response.message })
                  }
               })
               .finally(() => {
                  LocalStorage.removeItem('invoice_detail')
                  this.hideLoading()
               })
         } else {
            let timeStartSplit = this.timeStart.split('/')
            timeStartSplit.unshift('01')
            let data = {
               serviceType: 1,
               ftthAccount: LocalStorage.getItem('ftth_account'),
               accountType: '1',
               billCycleDate: this.getMiliseconds(timeStartSplit.join('/'), DATE_FORMAT)
            }
            service
               .getListInvoiceStatement(data)
               .then((response) => {
                  if (response.errorCode !== '1') {
                     let result = response.wsResponse ?? []
                     this.data = result.map((d) => {
                        return {
                           type: InvoiceType.InvoiceStatement,
                           name: this.$i18n.t('ftth.view_invoice.invoice_statement'),
                           id: d.chargeReportId,
                           subId: d.subId,
                           no: d.name,
                           date: d.billCycle
                        }
                     })
                  } else {
                     this.$store.dispatch('setNotification', { content: response.message })
                  }
               })
               .finally(() => {
                  LocalStorage.removeItem('invoice_detail')
                  this.hideLoading()
               })
         }
      },
      redirectToDetail(item) {
         let data = {
            searchParams: {
               timeStart: this.timeStart,
               timeEnd: this.timeEnd,
               type: this.type
            },
            detail: item
         }
         LocalStorage.setItem('invoice_detail', JSON.stringify(data))
         this.$router.push('/ftth/detail-view-invoice')
      }
   },
   watch: {
      type(newVal) {
         this.data = []
         if (LocalStorage.getItem('invoice_detail') === null) {
            if (newVal == InvoiceType.InvoiceStatement) {
               this.timeStart = new moment().subtract(2, 'months').format(MONTH_FORMAT)
               this.timeEnd = ''
            } else {
               this.timeStart = new moment().subtract(2, 'months').format(DATE_FORMAT)
               this.timeEnd = moment().format(DATE_FORMAT)
            }
         }
      },
      timeStart(newVal, oldVal) {
         if (this.type != InvoiceType.InvoiceStatement) {
            let startNum = this.getMiliseconds(newVal, DATE_FORMAT)
            let endNum = this.getMiliseconds(this.timeEnd, DATE_FORMAT)
            if (startNum > endNum) {
               this.timeStart = oldVal
               this.$store.dispatch('setNotification', {
                  content: this.$i18n.t('errors.time_start_greater_than_time_end')
               })
            }
         } else {
            let startNum = this.getMiliseconds(newVal, MONTH_FORMAT)
            let currentNum = new Date().getTime()
            if (startNum > currentNum) {
               this.timeStart = oldVal
               this.$store.dispatch('setNotification', {
                  content: this.$i18n.t('errors.time_start_greater_than_current_month')
               })
            }
         }
      },
      timeEnd(newVal, oldVal) {
         let current = moment()
         let startNum = this.getMiliseconds(this.timeStart, DATE_FORMAT)
         let endNum = this.getMiliseconds(newVal, DATE_FORMAT)
         if (startNum > endNum) {
            this.timeEnd = oldVal
            this.$store.dispatch('setNotification', {
               content: this.$i18n.t('errors.time_start_greater_than_time_end')
            })
         } else if (endNum > current) {
            this.timeEnd = oldVal
            this.$store.dispatch('setNotification', {
               content: this.$i18n.t('errors.time_end_greater_than_current_day')
            })
         }
      }
   }
}
</script>
