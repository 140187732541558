<template>
  <div class="main-container">
        <div class="nav-bar js-nav-bar">
          <div class="nav-bar__inner"><span class="nav-bar__icon" @click="goBack"><i class="icon-fonts icon-fonts--arrow-left"></i></span>
            <h3 class="nav-bar__title">{{$t('ftth.error_report.error_report')}}</h3>
          </div>
        </div>
        <div class="main-content">
            <ValidationObserver v-slot="{passes}">
                <form @submit.prevent="passes(onSubmit)">
                    <div class="change-product">
                        <div class="change-product__content">
                        <div class="change-product__time">
                            <div class="form-2column">
                                <InputDate 
                                        :label="$t('ftth.error_report.time_start')"
                                        :placeholder="$t('ftth.error_report.time_start')"
                                        :isLabel="true"
                                        id="from_date"
                                        type="date"
                                        rules="required|date"
                                        rightIcon="icon-fonts icon-fonts--calendar"
                                        v-model="error_model.fromDate"
                                        :errorNumberF="errorNumber"
                                        />

                                    <InputDate 
                                        :label="$t('ftth.error_report.time_end')"
                                        :placeholder="$t('ftth.error_report.time_end')"
                                        :isLabel="true"
                                        id="to_date"
                                        type="date"
                                        rules="required|date"
                                        rightIcon="icon-fonts icon-fonts--calendar"
                                        :errorNumberT="errorNumberT"
                                        v-model="error_model.toDate" />
                            </div>
                        </div>
                        <template v-if="lstError && lstError.length != 0">
                            <div :key="index" v-for="(item,index) in lstError" 
                                :class="parseInt(item.complainStatus)==0?['change-product__box','error']:(parseInt(item.complainStatus) == 1|| parseInt(item.complainStatus) ==2)?['change-product__box','process']:['change-product__box','success']">
                                <div class="change-product__top">
                                <h5  class="change-product__sub">{{item.complainGroup}}</h5>
                                </div>
                                <ul class="change-product__list" >
                                <li class="change-product__item"><span class="change-product__name">{{$t('ftth.error_report.type_of_complaint')}}</span><span class="change-product__value">{{item.complainType}}</span></li>
                                <li class="change-product__item"><span class="change-product__name">{{$t('ftth.error_report.date_of_request')}}</span><span class="change-product__value">{{item.complainTime}}</span></li>
                                <li class="change-product__item"><span class="change-product__name">{{$t('ftth.error_report.last_update_date')}}</span><span class="change-product__value">{{item.lastUpdateDate}}</span></li>
                                <li class="change-product__item"><span class="change-product__name">{{$t('ftth.error_report.status')}}</span><span :class="parseInt(item.complainStatus)==0?['change-product__value','error']:(parseInt(item.complainStatus) == 1|| parseInt(item.complainStatus) ==2)?['change-product__value','process']:['change-product__value','success']">{{getStatus(item)}}</span></li>
                                </ul>
                            </div>
                        </template>
                        <!-- <div class="change-product__box success">
                            <div class="change-product__top">
                            <h5 class="change-product__sub">FTTH-Technique Error</h5>
                            </div>
                            <ul class="change-product__list">
                            <li class="change-product__item"><span class="change-product__name">Type of complaint</span><span class="change-product__value">FTTH - Wifi problem</span></li>
                            <li class="change-product__item"><span class="change-product__name">Date of request</span><span class="change-product__value">20:30 30/06/2022</span></li>
                            <li class="change-product__item"><span class="change-product__name">Status</span><span class="change-product__value success">Successful</span></li>
                            </ul>
                        </div> -->
                        </div>
                        <div class="change-product__btn">
                        <button class="button button--primary button--full">{{$t('ftth.error_report.button1')}}
                        </button>
                        </div>
                    </div>
                </form>
            </ValidationObserver>
        </div>
      </div>
</template>
<script>
import BaseComponent from '@/mixins/BaseComponent'
import service from './../../services/FTTHService'
import store from '@/plugins/vuex/store'
import { ValidationObserver } from 'vee-validate'
// import Input from '@/components/form/Input'
import InputDate from '@/components/form/InputDate'
import LocalStorage from '@/ultils/local-storage'
import moment from 'moment'
import $ from "jquery";

export default {
   name: 'ErrorReport',
   mixins: [BaseComponent],
   components: {
    // Input,
    InputDate,
    ValidationObserver
   },
   data() {
      return {
            error_model:{
                fromDate: moment(new Date().setDate(new Date().getDate() - 30)).format("DD/MM/YYYY"),
                toDate:moment(new Date()).format("DD/MM/YYYY")
            },
            lstError:[],
            errorNumber:0,
            errorNumberT:0
      }
   },
   created() {
    //   let ftthAccountInfo = JSON.parse(LocalStorage.getItem('ftth_user_info'))
    //   this.fullName = ftthAccountInfo.custName
    //   this.phoneNumber = ftthAccountInfo.isdn
      this.getData()
      this.loadScript('js/select2.js')
      this.loadScript('js/date-picker.js')
      this.loadScript('js/date-month-picker.js')
   },
   watch: {
      'error_model.fromDate'(val){
        if(this.checkDate(val) && this.error_model.toDate && this.error_model.toDate != ''){

            let { isDateSuccess, isDate90,isfDateTrue,istDateTrue } = this.diffDate(val,this.error_model.toDate)
            if(!isfDateTrue){
                store.dispatch('setNotification', {
                    content: this.$i18n.t('ftth.error_report.errorDateNow')
                })
                this.error_model.fromDate = null
            }
            if(!istDateTrue){
                store.dispatch('setNotification', {
                    content: this.$i18n.t('ftth.error_report.errorDateNow')
                })
                this.error_model.fromDate = null
            }
            if(!isDateSuccess){
                store.dispatch('setNotification', {
                    content: this.$i18n.t('ftth.error_report.errorDateFromDate')
                })
                this.error_model.fromDate = null
            }
            if(!isDate90){
                store.dispatch('setNotification', {
                    content: this.$i18n.t('ftth.error_report.errorDateFromDate3Months')
                })
                debugger; // eslint-disable-line no-debugger 
                this.error_model.fromDate = null
                console.log('this.error_model.fromDate',this.error_model)

            }
            if(!isfDateTrue || !isDateSuccess || !isDate90){
                this.errorNumber++
               $('#from_date').val('')
                this.loadScript('js/date-picker.js')
                this.loadScript('js/date-month-picker.js')
                this.$forceUpdate()
                return
            }
            this.getData()
        }
        
      },
      'error_model.toDate'(val){
        if(this.checkDate(val) && this.error_model.fromDate && this.error_model.fromDate != ''){
            let { isDateSuccess, isDate90,isfDateTrue,istDateTrue } = this.diffDate(this.error_model.fromDate,val)
            if(!isfDateTrue){
                store.dispatch('setNotification', {
                    content: this.$i18n.t('ftth.error_report.errorDateNow')
                })
                this.error_model.toDate = null
            }
            if(!istDateTrue){
                store.dispatch('setNotification', {
                    content: this.$i18n.t('ftth.error_report.errorDateNow')
                })
                this.error_model.toDate = null
            }
            if(!isDateSuccess){
                console.log({isDateSuccess})
                console.log({isDate90})
                store.dispatch('setNotification', {
                    content: this.$i18n.t('ftth.error_report.errorDateFromDate')
                })
                this.error_model.toDate = null
            }
            if(!isDate90){
                store.dispatch('setNotification', {
                    content: this.$i18n.t('ftth.error_report.errorDateFromDate3Months')
                })
                this.error_model.toDate = null
            }
            if(!istDateTrue || !isDateSuccess || !isDate90){
                this.errorNumberT++
                $('#to_date').val('')
                this.loadScript('js/date-picker.js')
                this.loadScript('js/date-month-picker.js')
                this.$forceUpdate()
                return
            }
            this.getData()
        }
        
      }
   },
   methods: {
        checkDate(value){
            if(value && value != '' && value.length == 10){
                var date = moment(value, 'DD-MM-YYYY');
                if (!date.isValid()) {
                    return false;
                }
                return true
            }
            return false
        },
        
        getStatus(item){
           return parseInt(item.complainStatus)==0?'Waiting to assign for Staff'
                :(parseInt(item.complainStatus)==1 || parseInt(item.complainStatus)==2)?'Waiting to fix' 
                :'Successful'
        },
        diffDate(fromDate,toDate){
            console.log({fromDate})
            console.log({toDate})
            var _fDate = moment(fromDate,'DD/MM/YYYY'); //todays date
            var _tDate = moment(toDate,'DD/MM/YYYY'); // another date
            var _dateNow = moment(new Date(),'DD/MM/YYYY'); //todays date
            var duration = moment.duration(_tDate.diff(_fDate));
            var days = duration.asDays();
            var _fDate3Month = moment(_fDate).add(3, 'M');
            let isDateSuccess = true
            let isDate90 = true
            let isfDateTrue = true
             let istDateTrue = true
            if(_fDate > _dateNow){
                isfDateTrue = false
            }
            if(_tDate > _dateNow){
                istDateTrue = false
            }
            if(days<0){
                isDateSuccess = false
            }
            // if(days > 90){
            //     isDate90 = false
            // }
            console.log({_fDate3Month})
            console.log({_tDate})
            if(_fDate3Month < _tDate){
                isDate90 = false
            }
           
            return {isDateSuccess,isDate90,isfDateTrue,istDateTrue}
        },
        async getData() {
            this.showLoading()
            let ftthAccountInfo = JSON.parse(LocalStorage.getItem('ftth_user_info')) 
            let account = ftthAccountInfo.isdn 
            // let userInfo = JSON.parse(LocalStorage.getItem('user_info')) 

            let dataPost = {
                "fromDate": this.error_model.fromDate,
                "ftthAccount": account,
                // "isdn": userInfo ? userInfo.isdn : account,
                "toDate": this.error_model.toDate

            }
            
            await service
                .historyComplain(dataPost)
                .then((response) => {
                if (response.errorCode === '0') {
                    this.lstError = response.wsResponse
                }
                }).finally(() => {
                    this.hideLoading()
                })

                this.hideLoading()
                this.loadScript('js/select2.js')
                this.loadScript('js/date-picker.js')
                this.loadScript('js/date-month-picker.js')
        },
        onSubmit(){
            console.log('error_model',this.error_model)
            this.$router.push('/ftth/create-new-error-report')
        }
   }
}
</script>
