<template>
    <div class="main-container">
        <div class="nav-bar js-nav-bar">
            <div class="nav-bar__inner">
                <span class="nav-bar__icon" @click="goBack"><i class="icon-fonts icon-fonts--arrow-left"></i></span>
                <h3 class="nav-bar__title">{{ $t('ftth.add_new_number.title') }}</h3>
            </div>
        </div>
        <div class="main-content">
            <ValidationObserver v-slot="{ invalid, reset }" ref="observer" @reset.prevent="reset">
                <div class="number-list">
                    <div class="number-list__form">
                        <div class="form-customize">
                            <Input type="number"
                                   :label="$t('ftth.add_new_number.phone_number')"
                                   :placeholder="$t('ftth.add_new_number.enter_phone_number')"
                                   rules="required|phone"
                                   :hasStar="true"
                                   v-model="phoneNumber" />
                            <p class="notice-text">{{ $t('ftth.add_new_number.note') }}</p>
                        </div>
                        <div class="recharge-btn">
                            <button class="button button--primary button--full" v-on:click="addNewNumber()" :disabled="invalid || confirmDis">
                                {{$t('buttons.confirm')}}
                            </button>
                        </div>
                    </div>
                </div>
            </ValidationObserver>
                
        </div>

        <button style="display: none" data-bs-toggle="modal" data-bs-target="#register-confirm" id="methodBtn" />
        <div class="modal fade modal-custom " id="register-confirm" tabindex="-1" role="dialog" aria-labelledby="register-confirm" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-custom__content">
                    <div class="modal-custom__header"><span class="modal-custom__action" data-bs-dismiss="modal"><i class="icon-fonts icon-fonts--close"></i></span></div>
                    <div class="modal-custom__body">
                        <h3 class="modal-custom__title">{{ $t('ftth.add_new_number.title_modal') }}</h3>
                        <p class="modal-custom__des">{{ $t('ftth.add_new_number.body_modal') }}{{this.contactPhone}}</p>
                        <div class="modal-custom__form">
                            <div class="form-customize">
                                <div class="form-input">
                                    <input class="form-control" type="number" placeholder="OTP" 
                                           pattern="[0-9]*"
                                           min="1" 
                                           step="1"
                                           :disabled="hasDis"
                                           onkeypress="return event.keyCode === 8 || event.charCode >= 48 && event.charCode <= 57" 
                                           v-model="otp" /><span class="input-time">00:{{timeSecond}}</span>
                                </div>
                            </div>
                        </div>
                        <p class="modal-custom__note">{{ $t('ftth.add_new_number.not_received') }}<a :style="styleDis" class="modal-custom__note-link" href="javascript:;" @click="resendOtp()">{{ $t('ftth.add_new_number.resend_otp') }}</a></p>
                    </div>
                </div>
            </div>
        </div>
    </div>


</template>
<script>
import BaseComponent from '@/mixins/BaseComponent'
import service from './../../services/FTTHService'
import LocalStorage from '@/ultils/local-storage'
import store from '@/plugins/vuex/store'
import Input from '@/components/form/Input'
import $ from 'jquery'
import { ValidationObserver } from 'vee-validate'

export default {
   name: 'AddNewNumber',
   mixins: [BaseComponent],
   components: {
       Input,
       ValidationObserver
    },
   data() {
      return {
          phoneNumber: "",
          timeSecond: 60,
          otp: "",
          styleDis: "pointer-events: none",
          hasDis: false,
          confirmDis: true,
          contactPhone:""

      }
   },
   created() {
      this.setTitle(this.$i18n.t('ftth.add_new_number.title'))
      let ftthAccountInfo = JSON.parse(LocalStorage.getItem('ftth_account_info'))
      this.contactPhone = ftthAccountInfo.isdn ?? ''
   },
   watch: {
       otp(val) {
           if (val.length == 6) {
               this.hasDis = true
               this.verifyOtp()
           } else {
               this.hasDis = false
           }
       },
       phoneNumber(val) {
           if (val) {
               this.confirmDis = false;
           }
       }
   },
   methods: {
      addNewNumber() {
           
           this.showLoading()

           let ftthAccount = LocalStorage.getItem('ftth_account') 
           var dataPost = {
               ftthAccount: ftthAccount,
               paramCode: "1003",
               isdn: this.phoneNumber
           }

           service
               .getPromotionUseFTTH(dataPost)
               .then((response) => {
                   if (response.wsResponse.responseCode != 0 || response.errorCode != 0) {
                       store.dispatch('setNotification', {
                           title: this.$i18n.t('notification.title'),
                           content: response.userMsg,
                           closeButton: this.$i18n.t('buttons.close')
                       })
                   } else {
                       this.otp = "";
                       $('#methodBtn').trigger('click')
                       this.timeSecond = 60
                       this.styleDis = "pointer-events: none";
                       this.countDownTimer()
                   }

                   this.hideLoading()
               })
               .catch(() => {
                   this.hideLoading()
               })
       },
       resendOtp() {
           this.showLoading()

           let ftthAccount = LocalStorage.getItem('ftth_account')
           var dataPost = {
               ftthAccount: ftthAccount,
               paramCode: "1003",
               isdn: this.phoneNumber
           }

           service
               .getPromotionUseFTTH(dataPost)
               .then((response) => {
                   if (response.wsResponse.responseCode != 0 || response.errorCode != 0) {
                       store.dispatch('setNotification', {
                           title: this.$i18n.t('notification.title'),
                           content: response.userMsg,
                           closeButton: this.$i18n.t('buttons.close')
                       })
                   } else {
                       this.otp = "";
                       this.timeSecond = 60
                       this.styleDis = "pointer-events: none";
                       this.countDownTimer()
                   }
                   this.hideLoading()
               })
               .catch(() => {
                   this.hideLoading()
               })
       },
       countDownTimer() {
           if (this.timeSecond > 0) {
               setTimeout(() => {
                   this.timeSecond -= 1
                   if (this.timeSecond < 10) {
                       if (this.timeSecond != -1) {
                           this.timeSecond = "0" + this.timeSecond
                       } else {
                           this.timeSecond = "00"
                       }
                   }
                   this.countDownTimer()
               }, 1000)
           } else {
               this.styleDis = ""
           }
       },
       verifyOtp() {
           this.showLoading()
           let ftthAccountInfo = JSON.parse(LocalStorage.getItem('ftth_account_info'))
           let ftthAccount = LocalStorage.getItem('ftth_account')
           var dataPost = {
               otp: this.otp,
               isdn: ftthAccountInfo.isdn
           }

           service
               .getVerifyOtp(dataPost)
               .then((response) => {
                   if (response.errorCode != 0) {
                       this.otp = "";
                       store.dispatch('setNotification', {
                           title: this.$i18n.t('notification.title'),
                           content: response.userMsg,
                           closeButton: this.$i18n.t('buttons.close')
                       })
                       this.hideLoading()
                   } else {
                       var dataPost = {
                           ftthAccount: ftthAccount,
                           paramCode: "1004",
                           isdn: this.phoneNumber,
                           username: ftthAccount
                       }

                       service
                           .getPromotionUseFTTH(dataPost)
                           .then((response) => {
                               if (response.wsResponse.responseCode != 0) {
                                   store.dispatch('setNotification', {
                                       title: this.$i18n.t('notification.title'),
                                       content: response.userMsg,
                                       closeButton: this.$i18n.t('buttons.close')
                                   })
                               } else {
                                   store.dispatch('setNotification', {
                                       title: this.$i18n.t('notification.title'),
                                       content: response.userMsg,
                                       icon: './images/ic-success.svg',
                                       closeButton: this.$i18n.t('buttons.close')
                                   })
                                   this.phoneNumber = ""
                                   this.$refs.observer.reset();
                                   this.confirmDis = true;
                                   //this.$router.go(-1)
                               }
                               this.hideLoading()
                           })
                           .catch(() => {
                               this.hideLoading()
                           })
                   }
                   
               })
               .catch(() => {
                   this.hideLoading()
               })
       }
   }
}
</script>
