<template>
   <div>
      <button style="display: none" data-bs-toggle="modal" data-bs-target="#notification" id="notificationBtn" />
      <div
         class="modal fade modal-custom"
         id="notification"
         tabindex="-1"
         role="dialog"
         aria-labelledby="notification"
         aria-hidden="true"
      >
         <div class="modal-dialog" role="document">
            <div class="modal-custom__content">
               <div class="modal-custom__header">
                  <span class="modal-custom__action" data-bs-dismiss="modal"
                     ><i class="icon-fonts icon-fonts--close"></i
                  ></span>
               </div>
               <div class="modal-custom__body">
                  <div class="modal-custom__images"><img :src="showedData.icon" alt="img" /></div>
                  <h3 class="modal-custom__title">{{ showedData.title }}</h3>
                  <p class="modal-custom__des" v-if="showedData.content" v-html="showedData.content"></p>
                  <div class="modal-custom__btn modal-custom__btn--full">
                     <button class="button button--primary button--medium" data-bs-dismiss="modal" @click="onClose">
                        {{ showedData.closeButton }}
                     </button>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>
<script>
import $ from 'jquery'
import i18n from '@/plugins/i18n'

export default {
   name: 'notification-modal',
   props: {
      data: Object, // Type: {title: "", icon: "", content: "", closeButton: ""}
      onClose: {
         type: Function,
         default: () => {}
      }
   },
   data() {
      return {
         showedData: {}
      }
   },
   watch: {
      data() {
         this.showedData = {
            title: this.data.title ?? i18n.t('notification.title'),
            icon: this.data.icon ?? './images/ic-warning.svg',
            content: this.data.content,
            closeButton: this.data.cancelButton ?? i18n.t('buttons.close')
         }
         $('#notificationBtn').trigger('click')
      }
   }
}
</script>
