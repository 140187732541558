import Api from '@/ultils/api-base'

export default {
   getUserInfo(body) {
      return Api('POST', 'wsGetSubMainInfo', body)
   },
   getSubAccountInfo(body) {
      return Api('POST', 'wsGetSubAccountInfo', body)
   },
   getAccountsDetail(body) {
      return Api('POST', 'wsGetAccountsDetail', body)
   },
   getPostageInfo(body) {
      return Api('POST', 'wsGetPostageInfo', body)
   },
   getPrePostageForGraph(body) {
      return Api('POST', 'wsGetPrePostageForGraph', body)
   },
   getPosPostageForGraph(body) {
      return Api('POST', 'wsGetPosPostageForGraph', body)
   },
   getRefillReport(body) {
      return Api('POST', 'wsGetRefillReport', body)
   },
   getPostageDetailInfo(body) {
      return Api('POST', 'wsGetPostageDetailInfo', body)
   },
   getAirTimeData(body) {
      return Api('POST', 'wsGetAirtimeData', body)
   },
   confirmAirTime(body) {
      return Api('POST', 'wsDoActionService', body)
   },
   getAirTimeCredit(body) {
      return Api('POST', 'wsGetAirtimeCredit', body)
   },
   resetPasswordIshare(body) {
      return Api('POST', 'wsResetPasswordIshare', body)
   },
   changePasswordIshare(body) {
      return Api('POST', 'wsChangePasswordIshare', body)
   },
   share(body) {
      return Api('POST', 'wsIshare', body)
   },
   validateSerialCard(body) {
      return Api('POST', 'validateSerialCard', body)
   },
   topup(body) {
      return Api('POST', 'wsTopup', body)
   },
   getListDataPackages(body) {
      return Api('POST', 'wsGetListDataPackages', body)
   },
   getDataPackageDetailByCode(body) {
      return Api('POST', 'wsGetDataPackageDetailByCode', body)
   },
   doActionService(body) {
      return Api('POST', 'wsDoActionService', body)
   },
   getEwalletInfo(body) {
      return Api('POST', 'wsGetEwalletInfo', body)
   },
   getOtpWithoutPassword(body) {
      return Api('POST', 'wsGetOtpWithoutPassword', body)
   },
   getListDataPromotion(body) {
      return Api('POST', 'wsGetListDataPromotion', body)
   },
   getDataPromotionDetail(body) {
      return Api('POST', 'wsGetDataPromotionDetail', body)
   },
   findStoreByAddr(body) {
      return Api('POST', 'wsFindStoreByAddr', body)
   },
   getProvinces(body) {
      return Api('POST', 'wsGetProvinces', body)
   },
   getDistricts(body) {
      return Api('POST', 'wsGetDistricts', body)
   },
   getRangeYourself(body) {
      return Api('POST', 'wsGetRangeYourself', body)
   },
   doYourSelf(body) {
      return Api('POST', 'wsDoYourSelf', body)
   },
   getNearestStores(body) {
      return Api('POST', 'wsGetNearestStores', body)
   },
   getListDataAddOn(body) {
      return Api('POST', 'wsGetAllDataAddon', body)
   },
   getLstProductForTransfer(body) {
      return Api('POST', 'wsGetLstProductForTransfer', body, false)
   },
   getCustInfoTransfer(body) {
      return Api('POST', 'wsGetCustInfoTransfer', body)
   },

   //anhnt
   CreateOrderPost2Pre(body) {
      return Api('POST', 'wsCreateOrderPost2Pre', body, false)
   },
   // end anhnt
   getContractFileTransfer(body) {
      return Api('POST', 'wsGetContractFileTransfer', body, false)
   },
   getFeeTransfer(body) {
      return Api('POST', 'wsGetFeeTransfer', body, false)
   },
   createTransferOrderPrePos(body) {
      return Api('POST', 'wsCreateTransferOrderPrePos', body, false)
   },
   trackingOrder(body) {
      return Api('POST', 'wsTrackingOrder', body, false)
   },
   GetNoticeWebview(body) {
      return Api('POST', 'wsGetNoticeWebview', body)
   },
   GetCurrentUsedSubServices(body) {
      return Api('POST', 'wsGetCurrentUsedSubServices', body)
   },
   GetServiceBestSeller(body) {
      return Api('POST', 'wsGetServiceBestSeller', body)
   },
   GetBannerWebview(body) {
      return Api('POST', 'wsGetBannerWebview', body)
   },
   getListDataPackagesV2(body) {
      return Api('POST', 'wsGetListDataPackagesV2', body)
   },
   getUMoney(body) {
      return Api('POST', 'wsGetUMoney', body)
   },
   getPrecinct(body) {
      return Api('POST', 'wsGetPrecincts', body)
   },
   getListReceipt(body) {
      return Api('POST', 'wsGetListReceipt', body)
   },
   getListInvoice(body) {
      return Api('POST', 'wsGetListInvoice', body)
   },
   getListInvoiceStatement(body) {
      return Api('POST', 'wsGetListInvoiceStatement', body)
   },
   getViewInvoiceInfo(body) {
      return Api('POST', 'wsViewInvoiceInfo', body)
   },
   getViewReceiptInfo(body) {
      return Api('POST', 'wsViewReceiptInfo', body)
   },
   getViewInvoiceStatement(body) {
      return Api('POST', 'wsViewInvoiceStatement', body)
   },
   getListInvoicePrepaid(body) {
      return Api('POST', 'wsGetListInvoicePrepaid', body)
   },
   forgotPass(body) {
      return Api('POST', 'forgotPass', body)
   },
   viewInvoicePrepaidInfo(body) {
      return Api('POST', 'wsViewInvoicePrepaidInfo', body)
   },
   getInfoChangeSim(body) {
      return Api('POST', 'wsGetInfoChangeSim', body, true, true)
   },
   changeSim(body) {
      return Api('POST', 'wsChangeSim', body, true, true)
   },
   checkSerial(body) {
      return Api('POST', 'wsCheckSerial', body, true, true)
   },
   getInfoChangeScratch(body) {
      return Api('POST', 'wsGetInfoChangeScratch', body, true, true)
   },
   bccsGetOTP(body) {
      return Api('POST', 'wsBccsGetOTP', body, true, true)
   },
   changeScratch(body) {
      return Api('POST', 'wsChangeScratch', body, true, true)
   },
   xboxRegisterService(body) {
      return Api('POST', 'wsXboxRegisterService', body)
   },
   xboxGetGift(body) {
      return Api('POST', 'wsXboxGetGift', body)
   },
   xboxGetService(body) {
      return Api('POST', 'wsXboxGetService', body)
   },
   getListContact(body) {
      return Api('POST', 'wsGetListContact', body)
   }
}
