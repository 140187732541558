<template>
   <ValidationProvider :rules="rules" :name="name || label" v-slot="{ errors }" slim>
      <div
         :class="[
            { [className]: true },
            { 'form-customize--error': errors[0] && className === 'form-customize' },
            ,
            { 'form-custom--error': errors[0] && className === 'form-custom' }
         ]"
      >
         <label class="label-title" v-if="label && isLabel">{{ label }} <span style="color:red" v-if="hasStar">(*)</span></label>
         <div class="form-input">
             <input v-if="type === 'date'"
                    :id="id"
                    class="form-control js-datepicker"
                    type="text"
                    :placeholder="placeholder"
                    @input="onInput"
                    v-model="innerValue"
                    autocomplete="off" />
             <span class="input-addon" v-if="rightIcon" @click="onClickRightIcon"><i :class="rightIcon"></i></span>
             <span class="input-close" v-if="hasResetIcon && value != ''" @click="reset">
                 <i class="icon-fonts icon-fonts--close"></i>
             </span>
         </div>
         <p class="notice-text" v-if="!errors[0] && hint">{{ hint }}</p>
         <p class="error-text" v-if="errors[0]">{{ errors[0] }}</p>
      </div>
   </ValidationProvider>
</template>
<script>
import $ from 'jquery'
import { getDevice } from '@/ultils/device'

export default {
   name: 'InputDate',
   props: {
       errorNumberF:{
           type: Number,
            default: 0
       },
       errorNumberT:{
           type: Number,
            default: 0
       },
       id:{
            type: String,
            default: undefined
       },
      vid: {
         type: String,
         default: undefined
      },
      name: {
         type: String,
         default: ''
      },
      label: {
         type: String,
         default: ''
      },
      rules: {
         type: [Object, String],
         default: ''
      },
      placeholder: {
         type: String,
         default: ''
      },
      hint: {
         type: String,
         default: ''
      },
      type: {
         type: String, // text, number, password, phone, fmNumber, numPassword ...
         default: 'text'
      },
      className: {
         type: String,
         default: 'form-customize'
      },
      hasResetIcon: {
         type: Boolean,
         default: false
      },
       readonly: {
         type: Boolean,
         default: false
      },
       disabled: {
         type: Boolean,
         default: false
      },
      rightIcon: {
         type: String,
         default: 'icon-fonts icon-fonts--undefined'
      },
      onClickRightIcon: {
         type: Function,
         default: () => {}
      },
      value: {
         type: null,
         default: ''
      },
      hasStar: {
          type: Boolean,
          default: false
      },
      isLabel: {
          type: Boolean,
          default: true
      },
      maxlength:{
         type: Number, // text, number, password, phone, fmNumber, numPassword ...
         default: 500
      }
   },
   data: () => ({
      innerValue: '',
      oldInnerValue: ''
   }),
   methods: {
      numberWithCommas(n) {
         return n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      blur() {
         if (getDevice() === 'Android') {
            $(`#${this.name || this.label}`).blur()
         }
      },
      focus() {
         if (getDevice() === 'Android') {
            $(`#${this.name || this.label}`).focus()
         }
      },
      checkAndUpdateVal(newVal) {
         this.blur()
         if (this.type === 'fmNumber') {
            let result = newVal.replace(/,/g, '')
            if (/\D/.test(result)) {
               this.$emit('input', this.oldInnerValue)
               this.innerValue = this.oldInnerValue
            } else {
               if (/^0/.test(result) && result.length > 1) {
                  this.$emit('input', result.replace(/^0+/, ''))
                  this.innerValue = this.numberWithCommas(result.replace(/^0+/, ''))
               } else {
                  this.$emit('input', result)
                  this.innerValue = this.numberWithCommas(result)
               }
            }
         } else if (this.type === 'numPassword') {
            if (/\D/.test(newVal)) {
               this.$emit('input', this.oldInnerValue)
               this.innerValue = this.oldInnerValue
            } else {
               this.$emit('input', newVal)
               this.innerValue = newVal
            }
         } else {
            this.$emit('input', newVal)
            this.innerValue = newVal
         }
         this.oldInnerValue = this.innerValue
         this.focus()
      },
      onInput(evt) {
         this.checkAndUpdateVal(evt.target.value)
      },
      onPaste(evt) {
         this.checkAndUpdateVal(evt.clipboardData.getData('text'))
      },
      reset() {
         this.$emit('input', '')
         this.innerValue = ''
      }
   },
   watch: {
      value(val) {
         if (this.type === 'fmNumber') {
            this.innerValue = this.numberWithCommas(val)
         } else {
            this.innerValue = val
         }
         this.oldInnerValue = this.innerValue
      },
      errorNumberF(){
          if (this.type === 'date') {
            this.innerValue = ''
         } 
      },
      errorNumberT(){
          if (this.type === 'date') {
            this.innerValue = ''
         } 
      }
   },
   created() {
      if (this.type === 'fmNumber') {
         this.innerValue = this.numberWithCommas(this.value)
      } else {
         this.innerValue = this.value
      }
      this.oldInnerValue = this.innerValue
   }
}
</script>
