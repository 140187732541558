import Api from '@/ultils/api-base'

export default {
   getAccountPointInfo(body) {
      return Api('POST', 'wsLoyaltyGetAccountPointInfo', body)
   },
   getAccountRankInfo(body) {
      return Api('POST', 'wsLoyaltyGetAccountRankInfo', body)
   },
   getLoyaltyInfo(body) {
      return Api('POST', 'wsGetLoyaltyInfo', body)
   },
   getTelecomGift(body) {
      return Api('POST', 'wsLoyaltyGetTelecomGift', body)
   },
   redeemPoint(body) {
      return Api('POST', 'wsRedeemPoint', body)
   },
   getPointTransferHistory(body) {
      return Api('POST', 'wsLoyaltyGetPointTransferHistory', body)
   },
   getRankDefineInfo(body) {
      return Api('POST', 'wsLoyaltyGetRankDefineInfo', body)
   },
   getSubMainInfo(body) {
      return Api('POST', 'wsGetSubMainInfo', body)
   },
   getAllPartnerGift(body) {
      return Api('POST', 'wsGetAllPartnerGift', body)
   },
   getProvinces(body) {
      return Api('POST', 'wsGetProvinces', body)
   },
   getDistricts(body) {
      return Api('POST', 'wsGetDistricts', body)
   },
   findStoreByAddr(body) {
      return Api('POST', 'wsFindStoreByAddr', body)
   },
   redeemGiftAndReturnExchangeCode(body) {
      return Api('POST', 'wsRedeemGiftAndReturnExchangeCode', body)
   },
   getSubType(body) {
      return Api('POST', 'wsGetSubType', body)
   },
   getPartnerGiftRedeemHistory(body) {
      return Api('POST', 'wsGetPartnerGiftRedeemHistory', body)
   },
   getTelecomRedeemHistory(body) {
      return Api('POST', 'wsGetTelecomRedeemHistory', body)
   }
}
