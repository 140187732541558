import Home from '@/modules/mobile/components/home/Home'
import AccountInformation from '@/modules/mobile/components/home/AccountInformation'
import BillDeals from '@/modules/mobile/components/charge-history/BillDeals'
import BillPrepaid from '@/modules/mobile/components/charge-history/BillPrepaid'
import BillPostpaid from '@/modules/mobile/components/charge-history/BillPostpaid'
import TotalCharge from '@/modules/mobile/components/charge-history/TotalCharge'
import SMS from '@/modules/mobile/components/charge-history/SMS'
import Calls from '@/modules/mobile/components/charge-history/Calls'
import Data from '@/modules/mobile/components/charge-history/Data'
import VASServices from '@/modules/mobile/components/charge-history/VASServices'
import CreditService from '@/modules/mobile/components/credit-service/CreditService'
import Payment from '@/modules/mobile/components/recharge/Payment'
import Recharge from '@/modules/mobile/components/recharge/Recharge'
import RẹchargeWithUMoney from '@/modules/mobile/components/recharge/RẹchargeWithUMoney'
import RechargeScratchCard from '@/modules/mobile/components/recharge/RechargeScratchCard'
import RechargeDone from '@/modules/mobile/components/recharge/RechargeDone'
import IShare from '@/modules/mobile/components/ishare/IShare'
import Scan from '@/modules/mobile/components/recharge/Scan'
import ChangePassword from '@/modules/mobile/components/ishare/ChangePassword'
import DataPackageList from '@/modules/mobile/components/data-packages/DataPackageList'
import DataPackageDetail from '@/modules/mobile/components/data-packages/DataPackageDetail'
import SpecialPackage from '@/modules/mobile/components/special-package/SpecialPackage'
import FindStoreByCurrentLocation from '@/modules/mobile/components/map/FindStoreByCurrentLocation'
import FindStoreByProvince from '@/modules/mobile/components/map/FindStoreByProvince'
import StoreDetail from '@/modules/mobile/components/map/StoreDetail'
import DoItYourSelf from '@/modules/mobile/components/do-it-yourself/DoItYourSelf'
import SelectPackage from '@/modules/mobile/components/change-to-postpaid/SelectPackage'
import PakageDetail from '@/modules/mobile/components/change-to-postpaid/PakageDetail'
import CustomerInfo from '@/modules/mobile/components/change-to-postpaid/CustomerInfo'
import ContractType from '@/modules/mobile/components/change-to-postpaid/ContractType'
import ContractDetail from '@/modules/mobile/components/change-to-postpaid/ContractDetail'
import ContractInfo from '@/modules/mobile/components/change-to-postpaid/ContractInfo'
import RequestSuccess from '@/modules/mobile/components/change-to-postpaid/RequestSuccess'
import ChangeInfo from '@/modules/mobile/components/change-to-postpaid/ChangeInfo'

//anhnt
import CustomerInfoPrepaid from '@/modules/mobile/components/change-to-prepaid/CustomerInfo'
import RequestSuccessPrepaid from '@/modules/mobile/components/change-to-prepaid/RequestSuccess'
import RequestSuccessHome from '@/modules/mobile/components/home/RequestSuccess'
import ViewInvoice from '@/modules/mobile/components/view-invoice/ViewInvoice'
import DetailInvoice from '@/modules/mobile/components/view-invoice/Detail'
import ChangeSimCard from '@/modules/mobile/components/change-simcard/ChangeSimCard'
import CreateNewRequest from '@/modules/mobile/components/change-simcard/CreateNewRequest'
import RequestSuccessChangeSimcard from '@/modules/mobile/components/change-simcard/RequestSuccess'
import ChangeScartchCard from '@/modules/mobile/components/change-scartch-card/ChangeScartchCard'
import InputInformation from '@/modules/mobile/components/change-scartch-card/InputInformation'
import RequestSuccessChangeScartchCard from '@/modules/mobile/components/change-scartch-card/RequestSuccess'
import ContactList from '@/modules/mobile/components/contact/ContactList'

import CallBackLogin from '@/modules/mobile/components/home/CallBackLogin'

export default [
   {
      path: '/mobile/home',
      component: Home
   },
   {
      path: '/mobile/callback',
      component: CallBackLogin
   },
   {
      path: '/mobile/account-info',
      component: AccountInformation
   },
   {
      path: '/mobile/charge-history/bill-deals',
      component: BillDeals
   },
   {
      path: '/mobile/charge-history/bill-prepaid/:startTime/:endTime',
      component: BillPrepaid
   },
   {
      path: '/mobile/charge-history/bill-postpaid/:startTime/:endTime',
      component: BillPostpaid
   },
   {
      path: '/mobile/charge-history/total-charge/:startTime/:endTime',
      component: TotalCharge
   },
   {
      path: '/mobile/charge-history/calls/:startTime/:endTime/:recordNumber',
      component: Calls
   },
   {
      path: '/mobile/charge-history/sms/:startTime/:endTime/:recordNumber',
      component: SMS
   },
   {
      path: '/mobile/charge-history/data/:startTime/:endTime/:recordNumber',
      component: Data
   },
   {
      path: '/mobile/charge-history/vas-services/:startTime/:endTime/:recordNumber',
      component: VASServices
   },
   {
      path: '/mobile/credit-service',
      component: CreditService
   },
   {
      path: '/mobile/payment',
      component: Payment
   },
   {
      path: '/mobile/recharge',
      component: Recharge
   },
   {
      path: '/mobile/recharge-u-money',
      component: RẹchargeWithUMoney
   },
   {
      path: '/mobile/recharge-scratch-card',
      component: RechargeScratchCard
   },
   {
      path: '/mobile/recharge-done',
      component: RechargeDone
   },
   {
      path: '/mobile/scan',
      component: Scan
   },
   {
      path: '/mobile/ishare',
      component: IShare
   },
   {
      path: '/mobile/ishare/change-pass',
      component: ChangePassword
   },
   {
      path: '/mobile/data-package',
      component: DataPackageList
   },
   {
      path: '/mobile/data-package/detail',
      component: DataPackageDetail
   },
   {
      path: '/mobile/special-package',
      component: SpecialPackage
   },
   {
      path: '/mobile/map/by-current-location',
      component: FindStoreByCurrentLocation
   },
   {
      path: '/mobile/map/by-province',
      component: FindStoreByProvince
   },
   {
      path: '/mobile/map/detail',
      component: StoreDetail
   },
   {
      path: '/mobile/do-it-yourself',
      component: DoItYourSelf
   },
   {
      path: '/mobile/change_to_postpaid',
      component: SelectPackage
   },
   {
      path: '/mobile/change_to_postpaid/detail',
      component: PakageDetail
   },
   {
      path: '/mobile/change_to_postpaid/customer-info',
      component: CustomerInfo
   },
   {
      path: '/mobile/change_to_postpaid/contract-type',
      component: ContractType
   },
   //anhnt
   {
      path: '/mobile/change-to-prepaid/customer-info',
      component: CustomerInfoPrepaid
   },
   {
      path: '/mobile/change-to-prepaid/request-success',
      component: RequestSuccessPrepaid
   },
   {
      path: '/mobile/home/request-success',
      component: RequestSuccessHome
   },
   // end anhnt
   {
      path: '/mobile/change_to_postpaid/contract-detail',
      component: ContractDetail
   },
   {
      path: '/mobile/change_to_postpaid/contract-info',
      component: ContractInfo
   },
   {
      path: '/mobile/change_to_postpaid/request-success',
      component: RequestSuccess
   },
   {
      path: '/mobile/change-pre-post-info',
      component: ChangeInfo
   },
   {
      path: '/mobile/view_invoice',
      component: ViewInvoice
   },
   {
      path: '/mobile/detail-view-invoice',
      component: DetailInvoice
   },
   {
      path: '/mobile/change-simcard',
      component: ChangeSimCard
   },
   {
      path: '/mobile/create-new-request',
      component: CreateNewRequest
   },
   {
      path: '/mobile/request-success-change-simcard',
      component: RequestSuccessChangeSimcard
   },
   {
      path: '/mobile/change-scratch-card',
      component: ChangeScartchCard
   },
   {
      path: '/mobile/input-information',
      component: InputInformation
   },
   {
      path: '/mobile/request-success-change-scartch-card',
      component: RequestSuccessChangeScartchCard
   },
   {
      path: '/mobile/contact-list',
      component: ContactList
   }
]
