<template>
   <div class="main-container">
      <div class="nav-bar js-nav-bar">
         <div class="nav-bar__inner">
            <span class="nav-bar__icon"><i class="icon-fonts icon-fonts--arrow-left" @click="goBack"></i></span>
            <h3 class="nav-bar__title">{{ title }}</h3>
         </div>
      </div>
      <div class="main-content">
         <img v-for="(image, index) in images" :src="'data:image/jpeg;base64,' + image" alt="img" :key="index" />
      </div>
   </div>
</template>
<script>
import BaseComponent from '@/mixins/BaseComponent'
import service from './../../services/MobileService'
import { InvoiceType } from './../../constants/data'
import LocalStorage from '@/ultils/local-storage'

export default {
   name: 'ViewInvoiceDetail1',
   mixins: [BaseComponent],
   data() {
      return {
         invoice: {},
         images: [],
         title: ''
      }
   },
   created() {
      this.invoice = JSON.parse(LocalStorage.getItem('invoice_detail_home')).detail
      this.loadData()
   },
   methods: {
      loadData() {
         this.showLoading()
         if (this.invoice.type == InvoiceType.Receipt) {
            let data = {
               paymentId: this.invoice.id,
               viewFromType: 0
            }
            service
               .getViewReceiptInfo(data)
               .then((response) => {
                  if (response.errorCode !== '1') {
                     this.images = response.wsResponse ?? []
                     this.setTitle(this.$i18n.t('ftth.view_invoice.view_receipt_detail'))
                     this.title = this.$i18n.t('ftth.view_invoice.view_receipt_detail')
                  } else {
                     this.$store.dispatch('setNotification', { content: response.message })
                  }
               })
               .finally(() => {
                  this.hideLoading()
               })
         } else if (this.invoice.type == InvoiceType.Invoice) {
            let data = {
               paymentId: this.invoice.id,
               viewFromType: 0
            }
            service
               .getViewInvoiceInfo(data)
               .then((response) => {
                  if (response.errorCode !== '1') {
                     this.images = response.wsResponse ?? []
                     this.setTitle(this.$i18n.t('ftth.view_invoice.view_invoice_detail'))
                     this.title = this.$i18n.t('ftth.view_invoice.view_invoice_detail')
                  } else {
                     this.$store.dispatch('setNotification', { content: response.message })
                  }
               })
               .finally(() => {
                  this.hideLoading()
               })
         } else if (this.invoice.type == InvoiceType.InvoiceStatement) {
            let data = {
               subId: this.invoice.subId,
               chargeReportId: this.invoice.id
            }
            service
               .getViewInvoiceStatement(data)
               .then((response) => {
                  if (response.errorCode !== '1') {
                     this.images = response.wsResponse ?? []
                     this.setTitle(this.$i18n.t('mobile.view_invoice.view_invoice_statement_detail'))
                     this.title = this.$i18n.t('mobile.view_invoice.view_invoice_statement_detail')
                  } else {
                     this.$store.dispatch('setNotification', { content: response.message })
                  }
               })
               .finally(() => {
                  this.hideLoading()
               })
         } else if(this.invoice.type == 'viewInvoicePrepaidInfo'){
            let data = {
               invoiceUsedId: this.invoice.invoiceUsedId,
               isdn: LocalStorage.getItem('user_info').isdn,
               saleTransId:this.invoice.saleTransId,
               viewFromType:0
            }
            service
               .viewInvoicePrepaidInfo(data)
               .then((response) => {
                  if (response.errorCode !== '1') {
                     this.images = response.wsResponse ?? []
                     this.setTitle(this.$i18n.t('mobile.view_invoice.view_invoice_detail'))
                     this.title = this.$i18n.t('mobile.view_invoice.view_invoice_detail')
                  } else {
                     this.$store.dispatch('setNotification', { content: response.message })
                  }
               })
               .finally(() => {
                  this.hideLoading()
               })
         }
      }
   }
}
</script>
