<template>
   <div class="main-container">
      <div class="nav-bar js-nav-bar" @click="goBack">
         <div class="nav-bar__inner">
            <span class="nav-bar__icon"><i class="icon-fonts icon-fonts--arrow-left"></i></span>
            <h3 class="nav-bar__title">{{ $t('mobile.special_package.title') }}</h3>
         </div>
      </div>
      <div class="main-content">
         <div class="credit-service credit-service--pack">
            <ul class="credit-service__list">
               <li class="credit-service__item" v-for="pack in listPackages" :key="pack.code">
                  <div class="credit-service__item-detail" @click="redirectToDetail(pack)">
                     <div class="credit-service__item-images">
                        <img :src="pack.iconUrl" alt="img" /><span class="credit-service__item-tag heart"
                           ><span>{{ pack.code }}</span></span
                        >
                     </div>
                     <div class="credit-service__item-info">
                        <div class="credit-service__item-top">
                           <h5 class="credit-service__item-name">
                              <a class="credit-service__item-link" href="javascript:void(0)">{{ pack.name }}</a>
                           </h5>
                        </div>
                        <p class="credit-service__item-des">{{ pack.shortDes }}</p>
                     </div>
                  </div>
                  <div class="credit-service__item-btn">
                     <button class="button button--primary button--small" @click="registerPack(pack)">
                        {{ $t('buttons.register') }}
                     </button>
                  </div>
               </li>
            </ul>
         </div>
      </div>
      <register-pack-flow :data="selectedPack" />
   </div>
</template>
<script>
import BaseComponent from '@/mixins/BaseComponent'
import service from './../../services/MobileService'
import LocalStorage from '@/ultils/local-storage'
import { PackageType } from './../../constants/data'
import RegisterPackFlow from './../common/RegisterPackFlow'

export default {
   name: 'SpecialPackage',
   mixins: [BaseComponent],
   components: {
      RegisterPackFlow
   },
   data() {
      return {
         listPackages: [],
         selectedPack: {}
      }
   },
   created() {
      this.setTitle(this.$i18n.t('mobile.special_package.title'))
      this.loadData()
   },
   methods: {
      loadData() {
         this.showLoading()
         service
            .getListDataPromotion()
            .then((response) => {
               this.listPackages = response.wsResponse ?? []
            })
            .finally(() => {
               this.hideLoading()
            })
      },
      registerPack(pack) {
         this.selectedPack = Object.assign({}, pack)
      },
      redirectToDetail(pack) {
         LocalStorage.setItem('package', JSON.stringify({ type: PackageType.SpecialPackage, data: pack }))
         let isXboxService = pack.isXboxService ? pack.isXboxService :'0'
         this.$router.push('/mobile/data-package/detail?type=' + PackageType.SpecialPackage + '&code=' + pack.code + '&isXboxService=' + isXboxService)
      }
   }
}
</script>
