<template>
   <div class="main-container">
      <div class="nav-bar js-nav-bar" @click="goBack">
         <div class="nav-bar__inner">
            <span class="nav-bar__icon"><i class="icon-fonts icon-fonts--arrow-left"></i></span>
            <h3 class="nav-bar__title">{{ $t('mobile.recharge_scratch_card.title') }}</h3>
         </div>
      </div>
      <div class="main-content">
         <div class="recharge-prepaid">
            <div class="tabs-list">
               <div class="nav tabs-list__nav" role="tablist">
                  <a class="tabs-list__link active" href="#scratch-card" role="tab" data-bs-toggle="tab">{{
                     $t('mobile.recharge_scratch_card.scratch_card')
                  }}</a
                  ><a class="tabs-list__link" href="#other-methods" role="tab" data-bs-toggle="tab">{{
                     $t('mobile.recharge_scratch_card.other_methods')
                  }}</a>
               </div>
               <div class="tabs-list__content tab-content">
                  <div class="tab-pane fade show active" id="scratch-card" role="tabpanel">
                     <div class="recharge-prepaid__content">
                        <div class="recharge-form">
                           <div class="form-row">
                              <div
                                 :class="[
                                    { 'form-new': true },
                                    { 'form-new--error': !validPhone || !phoneNumber },
                                    { 'form-new--valid': validPhone }
                                 ]"
                              >
                                 <div class="form-new__info">
                                    <label v-if="validPhone" class="label-title">{{
                                       customerName.toUpperCase()
                                    }}</label>
                                    <input
                                       v-model="phoneNumber"
                                       class="form-control"
                                       inputmode="tel"
                                       type="text"
                                       @input="onChangeInputPhone"
                                       pattern="[0-9]*"
                                       onkeypress="return event.keyCode === 8 || event.charCode >= 48 && event.charCode <= 57"
                                    />
                                 </div>
                                 <span class="form-new__icon" @click="handleOpenContactList"
                                    ><img src="@/assets/images/ic-contact-book.svg" alt="ic"
                                 /></span>
                              </div>
                           </div>
                           <p v-if="!validPhone && phoneNumber" class="error-text">{{ $t('errors.phone') }}</p>
                           <p v-if="!phoneNumber" class="error-text">{{ $t('errors.phone_required') }}</p>
                           <div class="form-row">
                              <div :class="[{ 'form-new': true }]">
                                 <div class="form-new__info">
                                    <label class="label-title"
                                       >{{ $t('mobile.recharge_scratch_card.scartch_card_code') }} *</label
                                    >
                                    <input
                                       v-model="textScan"
                                       class="form-control"
                                       inputmode="numeric"
                                       type="text"
                                       :maxlength="16"
                                       @input="onChangeInputSerial"
                                       onkeypress="return event.keyCode === 8 || event.charCode >= 48 && event.charCode <= 57"
                                    />
                                 </div>
                                 <span v-show="textScan" class="form-new__action" @click="textScan = ''"
                                    ><i class="icon-fonts icon-fonts--close"></i
                                 ></span>
                              </div>
                              <div class="form-icon" @click="onScan">
                                 <img src="@/assets/images/ic-qr-scan.svg" alt="ic" />
                              </div>
                           </div>
                           <p v-if="!textScan && !isFirstEnterSerial" class="error-text">
                              {{ $t('errors.scratch_card_required') }}
                           </p>
                           <p v-if="!validSerialCard && textScan" class="error-text">
                              {{ $t('errors.scratch_card_invalid') }}
                           </p>
                        </div>
                        <div :class="[{ 'scratch-card': true }, { opened: showImg }]">
                           <div class="scratch-card__info" @click="onClickShowImg">
                              <h5 class="scratch-card__name">
                                 {{ $t('mobile.recharge_scratch_card.sample_scratch_card') }}
                              </h5>
                              <span class="scratch-card__action"
                                 ><i class="icon-fonts icon-fonts--arrow-down"></i
                              ></span>
                           </div>
                           <div v-if="showImg" class="scratch-card__images">
                              <img src="@/assets/images/img-scratch-card.svg" alt="img" />
                           </div>
                        </div>
                        <div class="recharge-footer">
                           <div class="recharge-footer__btn">
                              <button class="button button--primary button--full" @click="recharge()">
                                 {{ $t('mobile.recharge_scratch_card.recharge') }}
                              </button>
                           </div>
                        </div>
                     </div>
                  </div>
                  <!-- <div class="tab-pane fade" id="other-methods" role="tabpanel">
                     <div class="recharge-prepaid__content">
                        <div class="recharge-form">
                           <div class="form-row">
                              <div class="form-icon"><img src="@/assets/images/ic-unitel.svg" alt="ic" /></div>
                              <div
                                 :class="[
                                    { 'form-new': true },
                                    { 'form-new--error': !validPhone || !phoneNumber },
                                    { 'form-new--valid': validPhone }
                                 ]"
                              >
                                 <div class="form-new__info">
                                    <label class="label-title">{{
                                       $t('mobile.recharge_other_methods.phone_number')
                                    }}</label>
                                    <input
                                       v-model="phoneNumber"
                                       class="form-control"
                                       inputmode="tel"
                                       type="text"
                                       pattern="[0-9]*"
                                       onkeypress="return event.keyCode === 8 || event.charCode >= 48 && event.charCode <= 57"
                                    />
                                 </div>
                                 <span class="form-new__icon"
                                    ><img src="@/assets/images/ic-contact-book.svg" alt="ic"
                                 /></span>
                              </div>
                           </div>
                        </div>
                        <div class="phone-number">
                           <ul class="phone-number__list">
                              <li class="phone-number__item active">
                                 <span class="phone-number__name">Me</span>
                              </li>
                              <li class="phone-number__item">
                                 <span class="phone-number__name">02093256541</span>
                              </li>
                           </ul>
                        </div>
                        <div class="amount-price">
                           <h5 class="amount-price__sub">{{ $t('mobile.recharge_other_methods.enter_amount') }}</h5>
                           <div class="amount-price__head">
                              <p class="amount-price__value">{{ moneyFormatter(amountPrice) }}</p>
                              <span class="amount-price__kip">{{ $t('unit.Kip') }}</span>
                           </div>
                           <ul class="amount-price__list">
                              <li
                                 v-for="(item, index) in amountPriceList"
                                 :class="[{ 'amount-price__item': true }, { active: amountPrice === item }]"
                                 :key="index"
                              >
                                 <span class="amount-price__name" @click="setAmountPrice(item)">{{
                                    moneyFormatter(item)
                                 }}</span>
                              </li>
                           </ul>
                        </div>
                        <div class="recharge-footer">
                           <div class="recharge-footer__info">
                              <h5 class="recharge-footer__name">
                                 {{ $t('mobile.recharge_other_methods.total_amount') }}
                              </h5>
                              <span class="recharge-footer__value"
                                 >{{ moneyFormatter(amountPrice) }}<span>₭</span></span
                              >
                           </div>
                           <div class="recharge-footer__btn" @click="recharge()">
                              <button class="button button--primary button--full">
                                 {{ $t('mobile.recharge_other_methods.next_step') }}
                              </button>
                           </div>
                        </div>
                     </div>
                  </div> -->
               </div>
            </div>
         </div>
      </div>
   </div>
</template>
<script>
import BaseComponent from '@/mixins/BaseComponent'
import store from '@/plugins/vuex/store'
import service from './../../services/MobileService'
import LocalStorage from '@/ultils/local-storage'

export default {
   name: 'RechargeScratchCard',
   mixins: [BaseComponent],
   data() {
      return {
         customerName: 'CUSTOMER',
         phoneNumber: '2095144191',
         textScan: '',
         showImg: false,
         amountPriceList: [5000, 10000, 20000, 30000, 50000, 100000],
         amountPrice: 5000,
         isFirstEnterSerial: true
      }
   },
   created() {
      this.setTitle(this.$i18n.t('mobile.recharge_scratch_card.title'))
      this.textScan = store.state.scanText
      if (this.textScan) {
         this.textScan = this.textScan.replace('tel:', '')
         this.textScan = this.textScan.replace('*199*', '')
         this.textScan = this.textScan.replace('*121*', '')
         this.textScan = this.textScan.replace('%23', '')
         if (this.validPhone && this.validSerialCard && store.state.qrMode === 'scan') this.validateSerialCard()
      }
      let userInfoStr = localStorage.getItem('user_info')
      if (userInfoStr) {
         let userInfo = JSON.parse(userInfoStr)
         this.customerName = store.state.customerName
         this.phoneNumber = store.state.phoneScratchCard ? store.state.phoneScratchCard : userInfo['isdn']
      }
   },
   methods: {
      goBack() {
         store.commit('setCustomerName', 'CUSTOMER')
         this.$router.replace('/mobile/recharge')
      },
      onChangeInputPhone() {
         this.phoneNumber = this.phoneNumber.replace(/\D/g, '')
         store.commit('setCustomerName', 'CUSTOMER')
         store.commit('setPhoneScratchCard', this.phoneNumber)
         this.customerName = store.state.customerName
      },
      onChangeInputSerial() {
         this.isFirstEnterSerial = false
         this.textScan = this.textScan.replace(/\D/g, '')
         store.commit('setTextScan', this.textScan)
      },
      onScan() {
         // store.commit('setTextScan', '')
         store.state.qrMode = 'manual'
         store.commit('setPhoneScratchCard', this.phoneNumber)
         LocalStorage.setItem('monitor_redirect_scan', 'RechargeScratchCard')
         this.$router.push('/mobile/scan')
      },
      onClickShowImg() {
         this.showImg = !this.showImg
      },
      handleOpenContactList() {
         this.$router.push('/mobile/contact-list')
      },
      validateSerialCard() {
         this.isFirstEnterSerial = false
         this.showLoading()
         var bodyValidate = {
            serial: this.textScan
         }
         service
            .validateSerialCard(bodyValidate)
            .then((response) => {
               if (response.errorCode != 0) {
                  this.textScan = ''
                  store.dispatch('setNotification', {
                     title: this.$i18n.t('errors.system'),
                     content: response.message,
                     closeButton: this.$i18n.t('buttons.close')
                  })
               }
               this.hideLoading()
            })
            .catch(() => {
               this.hideLoading()
            })
      },
      recharge() {
         this.isFirstEnterSerial = false
         if (!this.validSerialCard || !this.validPhone) return
         this.showLoading()
         store.commit('setPhoneScratchCard', this.phoneNumber)
         //validate

         var bodyValidate = {
            serial: this.textScan.replace(/ +/g, '')
         }

         service
            .validateSerialCard(bodyValidate)
            .then((response) => {
               if (response.errorCode == 0) {
                  //Tiến hành nạp thẻ
                  var bodytopup = {
                     serial: this.textScan,
                     desIsdn: this.phoneNumber
                  }
                  service
                     .topup(bodytopup)
                     .then((response) => {
                        if (response.errorCode == 0) {
                           this.textScan = ''
                           store.dispatch('setNotification', {
                              title: this.$i18n.t('mobile.recharge_scratch_card.title'),
                              content: response.message,
                              closeButton: this.$i18n.t('buttons.close')
                           })
                           // this.$router.push('/mobile/recharge-done')
                        } else {
                           store.dispatch('setNotification', {
                              title: this.$i18n.t('errors.system'),
                              content: response.message,
                              closeButton: this.$i18n.t('buttons.close')
                           })
                           // this.$router.push('/mobile/recharge-done')
                        }
                        this.hideLoading()
                     })
                     .finally(() => {
                        this.hideLoading()
                     })
               } else {
                  store.dispatch('setNotification', {
                     title: this.$i18n.t('errors.system'),
                     content: response.message,
                     closeButton: this.$i18n.t('buttons.close')
                  })
                  this.hideLoading()
               }
            })
            .catch(() => {
               this.hideLoading()
            })
      },
      setAmountPrice(value) {
         this.amountPrice = value
      },
      moneyFormatter(money) {
         return this.formatMoney.format(money || 0)
      }
   },
   computed: {
      validPhone() {
         return (
            /^(9|0209|209|856209)+([0-9]{7})\b$/im.test(this.phoneNumber) ||
            /^(030|304|309)+([0-9]{6})\b$/im.test(this.phoneNumber)
         )
      },
      validSerialCard() {
         return /^[\d\s]+$/im.test(this.textScan)
      },
      formatMoney() {
         return new Intl.NumberFormat('vi', {
            style: 'decimal',
            currency: 'VND'
         })
      }
   },
   watch: {
      phoneNumber: {
         handler() {
            if (this.validPhone) console.log('call api')
         }
      }
   }
}
</script>

<style scoped>
.form-new--error {
   border-color: #e24b4b;
}

.form-new--valid {
   border-color: #3bc46a;
}

.scratch-card__images {
   display: block;
}
</style>
